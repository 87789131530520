import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import dayjs from 'dayjs';
import { 
  FormComponent, RadioInputGroup, FormInputGroup, SelectInputGroup 
} from '../../forms';
import { restingHRValidator } from '../../utilities/resting-hr-validator';
import BlackhawksStationaryBikeStage from './blackhawks-stationary-bike-stage';
import BlackhawksStationaryBikeRestStage from './blackhawks-stationary-bike-rest-stage';
import BlackhawksPlyometricsStage from './blackhawks-plyometrics-stage';
import RestingHeartRateInput from '../resting-heart-rate-input';
import ElapsedTimer from '../../timers/elapsed-timer';
import BlackhawksTimerDisplay from './blackhawks-timer-display';
import Sticky from '../sticky';
import Strings from './lang';
import {
  findFailPoint, 
  stageOptions,
  BLACKHAWKS_STATIONARY_BIKE_KEYS,
  BLACKHAWKS_PLYOMETRICS_KEYS,
  filterBlackhawksStageData,
  getBlackhawksTestState
} from './blackhawks-data';

const TIMER_INTERVAL = 40;

class BlackhawksTestForm extends Component {
  constructor(props) {
    super(props);

    /** ref variable for the sticky timer */
    this.stickyContainer = React.createRef();

    const blackhawksProps = getBlackhawksTestState(props);
    const validRestingHR = restingHRValidator(blackhawksProps.resting_heart_rate);

    this._onTimerTick = this._onTimerTick.bind(this);
    this.timer = new ElapsedTimer(TIMER_INTERVAL, this._onTimerTick);
    this.onSubmit = this.onSubmit.bind(this);
    this._toggleTimer = this._toggleTimer.bind(this);
    this._resetTimer = this._resetTimer.bind(this);
    this._onChangeCooldownResult = this._onChangeCooldownResult.bind(this);

    this.state = {
      ...blackhawksProps,
      running: false,
      submitted: false,
      validRestingHR
    };
  }

  componentWillUnmount() {
    if (this.timer.isRunning()) {
      this.timer.stop();
    }
  }

  render() {
    const {
      duration,
      notes,
      resting_heart_rate,
      full_plyometrics,
      stationary_bike_rest,
      cooldown_result,
      validRestingHR
    } = this.state;

    return (
      <FormComponent 
        className="blackhawks-test-form" 
        formProps={{ noValidate: true }} 
        onSubmit={this.onSubmit}
      >
        <RestingHeartRateInput
          value={resting_heart_rate}
          required
          onUpdate={(value, valid) => {
            this.setState({ resting_heart_rate: value, validRestingHR: valid });
          }}
          testName={Strings.testTitle}
          touched={this.state.submitted}
        />

        {validRestingHR && (
          <div ref={this.stickyContainer}>
            <div className="row">
              <If condition={!this.props.blackhawks.id}>
                <Then>
                  <Sticky container={() => this.stickyContainer.current} stickyClassName="blackhawks-affix-timer">
                    <div className="col-md-8">
                      <BlackhawksTimerDisplay 
                        duration={duration}
                        isRunning={this.state.running}
                        onToggle={this._toggleTimer}
                        onReset={this._resetTimer}
                      />
                    </div>
                  </Sticky>
                </Then>
                <Else>
                  <div className="col-md-8">
                    <strong>{Strings.testDurationLabelText}:</strong> {dayjs.duration(duration).format('H:mm:ss')} (h:m:s)
                  </div>
                </Else>
              </If>
            </div>
        
            <div className="row">
              <div className="col-md-8">

                <h2>
                  {Strings.stationaryBikeHeadingText} &mdash; {Strings.stationaryBikeSubheadingText}
                </h2>
                {BLACKHAWKS_STATIONARY_BIKE_KEYS.map((key, index) => {
                  const stageData = this.state[key] || [];
                  return (
                    <BlackhawksStationaryBikeStage
                      key={key}
                      identifier={key}
                      stageTitle={Strings.formatString(
                        Strings.stationaryBikeStageLabelText, 
                        index + 1
                      )}
                      recoveryTitle={Strings.formatString(
                        Strings.stationaryBikeRecoveryLabelText, 
                        index + 1
                      )}
                      stageData={stageData}
                      onChangeSegmentHeartRate={(stageIdentifier, rowIndex, e) => {
                        this._onChangeStationaryBikeSegmentInput('heart_rate', stageIdentifier, rowIndex, e);
                      }}
                      onChangeSegmentSymptoms={(stageIdentifier, rowIndex, e) => {
                        this._onChangeStationaryBikeSegmentInput('symptoms', stageIdentifier, rowIndex, e);
                      }}
                      touched={this.state.submitted}
                    />
                  );
                })}

                <BlackhawksStationaryBikeRestStage
                  heartRate={stationary_bike_rest.heart_rate}
                  symptoms={stationary_bike_rest.symptoms}
                  onChangeHeartRate={(e) => {
                    this._onChangeStationaryBikeRestInput('heart_rate', e);
                  }}
                  onChangeSymptoms={(e) => {
                    this._onChangeStationaryBikeRestInput('symptoms', e);
                  }}
                  touched={this.state.submitted}
                />

              </div>
              <div className="col-md-4">

                <h2>
                  {Strings.plyometricsHeadingText} &mdash; {Strings.plyometricsSubheadingText}
                </h2>
                {BLACKHAWKS_PLYOMETRICS_KEYS.map((key, index) => {
                  const stage = full_plyometrics[key];
                  return (
                    <BlackhawksPlyometricsStage
                      key={key}
                      identifier={key}
                      className={`stage-${index + 1}`}
                      stageTitle={stage.title}
                      heartRate={stage.heart_rate}
                      symptoms={stage.symptoms}
                      directions={stage.directions}
                      rest={stage.rest}
                      onChangeHeartRate={(stageIdentifier, e) => {
                        this._onChangePlyometricsSegmentInput('heart_rate', stageIdentifier, e);
                      }}
                      onChangeSymptoms={(stageIdentifier, e) => {
                        this._onChangePlyometricsSegmentInput('symptoms', stageIdentifier, e);
                      }}
                      touched={this.state.submitted}
                    />
                  );
                })}

                <div className="blackhawks-plyometrics-cooldown">
                  <h3 className="bold">{Strings.cooldownHeadingText}</h3>
                  <RadioInputGroup
                    className="form-group cooldown-input-group"
                    radioLabels={[Strings.cooldownPassText, Strings.cooldownFailText]}
                    radioValues={['pass', 'fail']}
                    initialValue={cooldown_result}
                    inputProps={{
                      className: 'form-control',
                      name: 'cooldown'
                    }}
                    inputValid={cooldown_result.length > 0}
                    messageText={Strings.cooldownErrorText}
                    messageClassName="alert alert-danger"
                    onUpdate={this._onChangeCooldownResult}
                    touched={this.state.submitted}
                  />
                  {cooldown_result === 'fail' && (
                    <div>
                      <FormInputGroup
                        className="form-group"
                        labelText={Strings.heartRateAtFailLabel} 
                        inputType="text" 
                        inputProps={{
                          className: 'form-control',
                          value: this.state.failPoint.heart_rate,
                          onBlur: (e) => this._onUpdateFailPointResult('heart_rate', e),
                          onChange: (e) => this._onUpdateFailPointResult('heart_rate', e)
                        }}
                        inputValid={this.state.failPoint.heart_rate !== '' 
                          && !isNaN(parseInt(this.state.failPoint.heart_rate, 10))}
                        messageClassName="alert alert-danger"
                        messageText="Please enter a valid number"
                        required
                        touched={this.state.submitted}
                      />
                      <FormInputGroup
                        className="form-group"
                        labelText={Strings.symptomsAtFailLabel} 
                        inputType="text"
                        inputProps={{
                          className: 'form-control',
                          value: this.state.failPoint.symptoms,
                          onBlur: (e) => this._onUpdateFailPointResult('symptoms', e),
                          onChange: (e) => this._onUpdateFailPointResult('symptoms', e)
                        }}
                        inputValid={this.state.failPoint.symptoms !== ''}
                        messageClassName="alert alert-danger"
                        messageText="Please enter the symptoms experienced at fail"
                        required
                        touched={this.state.submitted}
                      />
                      <SelectInputGroup
                        className="form-group"
                        labelText={Strings.stageAtFailLabel}
                        inputProps={{
                          className: 'form-control',
                          value: this.state.failPoint.stage,
                        }}
                        inputValid
                        required
                        onUpdate={(e) => {
                          this._onUpdateFailPointResult('stage', e);
                        }}
                        messageClassName="alert alert-danger"
                        touched={this.state.submitted}
                      >

                        {Object.keys(stageOptions).map(key => {
                          const stages = stageOptions[key];
                          return (
                            <optgroup key={key} label={Strings[`failStageOptGroup_${key}`]}>
                              {Object.keys(stages).map(stage => (
                                <option key={stage} value={stage}>{stages[stage]}</option>
                              ))}
                            </optgroup>
                          );
                        })}
                      </SelectInputGroup>
                    </div>
                  )}
                  
                  <FormInputGroup
                    className="form-group"
                    labelText={Strings.overallNotesLabelText}
                    inputType="textarea"
                    inputProps={{
                      rows: 10,
                      className: 'form-control',
                      value: notes,
                      onChange: this.onUpdateInput.bind(this, 'notes')
                    }}
                    required={false}
                    inputValid
                  />
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="form-footer">
                  <button type="submit" className="btn btn-primary">
                    {Strings.saveButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

      </FormComponent>
    );
  }

  _onChangeStationaryBikeSegmentInput(key, stageIdentifier, rowIndex, e) {
    const { [stageIdentifier]: stageData } = this.state;
    const rowData = stageData[rowIndex];
    if (rowData) {
      stageData[rowIndex] = { ...rowData, [key]: e.target.value };
    }

    this.setState({
      [stageIdentifier]: stageData
    });
  }

  _onChangeStationaryBikeRestInput(key, e) {
    const { stationary_bike_rest } = this.state;
    this.setState({
      stationary_bike_rest: { ...stationary_bike_rest, [key]: e.target.value }
    });
  }

  _onChangePlyometricsSegmentInput(key, stageIdentifier, e) {
    const { full_plyometrics } = this.state;
    const { [stageIdentifier]: stageData = {} } = full_plyometrics || {};

    this.setState({
      full_plyometrics: {
        ...full_plyometrics,
        [stageIdentifier]: { ...stageData, [key]: e.target.value }
      }
    });
  }

  _onChangeCooldownResult(value) {
    let { failPoint, cooldown_result } = this.state;
    cooldown_result = value;

    if (value === 'fail') {
      failPoint = findFailPoint({ ...this.state });
    } else {
      failPoint.symptoms = '';
      failPoint.heart_rate = '';
      failPoint.stage = '';
    }

    this.setState({
      cooldown_result,
      failPoint
    });
  }

  _onUpdateFailPointResult(key, e) {
    const { failPoint } = this.state;
    const value = e && e.target ? e.target.value : e;

    failPoint[key] = value;

    this.setState({
      failPoint
    });
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  _toggleTimer() {
    const { running = false } = this.state;

    if (!running) {
      this.timer.resume();
    } else {
      this.timer.stop();
    }

    this.setState({
      running: !running
    });
  }

  _resetTimer() {
    this.timer.reset();
    this.setState({
      duration: 0
    });
  }

  _onTimerTick(ms) {
    this.setState({
      duration: ms
    });
  }

  isValid() {
    return this.isStationaryBikeDataValid()
      && this.isStationaryBikeRestValid()
      && this.isPlyometricsDataValid()
      && this.isCooldownValid()
      && this.isRestingHeartRateValid()
      && this.isFailPointValid();
  }

  isStationaryBikeDataValid() {
    return BLACKHAWKS_STATIONARY_BIKE_KEYS.filter(key => {
      const segments = this.state[key] || [];
      const invalidSegments = segments.filter(segment => {
        return isNaN(parseInt(segment.heart_rate, 10)) && segment.heart_rate.length > 0;
      });

      return invalidSegments.length > 0;
    }).length === 0;
  }

  isStationaryBikeRestValid() {
    const { stationary_bike_rest } = this.state;
    return !isNaN(parseInt(stationary_bike_rest.heart_rate, 10)) 
      || stationary_bike_rest.heart_rate.length === 0;
  }

  isPlyometricsDataValid() {
    return BLACKHAWKS_PLYOMETRICS_KEYS.filter(key => {
      const stage = this.state.full_plyometrics[key] || {};
      return isNaN(parseInt(stage.heart_rate, 10)) && stage.heart_rate.length > 0;
    }).length === 0;
  }

  isCooldownValid() {
    const { cooldown_result } = this.state;
    return ['pass', 'fail'].indexOf(cooldown_result) >= 0;
  }

  isRestingHeartRateValid() {
    const { resting_heart_rate } = this.state;
    return !isNaN(parseInt(resting_heart_rate, 10));
  }

  isFailPointValid() {
    const { cooldown_result, failPoint } = this.state;
    const { symptoms, heart_rate } = failPoint;

    return cooldown_result === 'pass' 
      || (symptoms.length > 0 
        && (heart_rate.length > 0 && !isNaN(parseInt(heart_rate, 10)))
      );
  }

  onSubmit() {
    this.setState({
      submitted: true,
      running: false
    });

    if (this.timer.isRunning()) {
      this.timer.stop();
    }

    if (!this.isValid()) {
      return;
    }

    const {
      duration,
      full_test,
      bike_type,
      resting_heart_rate,
      max_tension_levels,
      stationary_bike_stage_1 = {},
      stationary_bike_stage_2 = {},
      stationary_bike_stage_3 = {},
      stationary_bike_rest = {},
      full_plyometrics = {},
      cooldown_result,
      failPoint = {},
      notes
    } = this.state;

    // Filter plyometrics stages:
    const plyometrics = Object.keys(full_plyometrics).reduce((stages, key) => {
      return { ...stages, [key]: filterBlackhawksStageData(full_plyometrics[key]) };
    }, {});

    const attributes = {
      full_test,
      bike_type,
      duration,
      resting_heart_rate,
      cooldown_result: cooldown_result === 'pass',
      test_data: {
        notes,
        max_tension_levels,
        stationary_bike_stage_1: stationary_bike_stage_1.map(filterBlackhawksStageData),
        stationary_bike_stage_2: stationary_bike_stage_2.map(filterBlackhawksStageData),
        stationary_bike_stage_3: stationary_bike_stage_3.map(filterBlackhawksStageData),
        stationary_bike_rest,
        full_plyometrics: plyometrics,
        fail_point: {
          symptoms: failPoint.symptoms,
          heart_rate: failPoint.heart_rate,
          stage: failPoint.stage
        }
      }
    };

    this.props.onSubmit(attributes);
  }
}

BlackhawksTestForm.propTypes = {
  blackhawks: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default BlackhawksTestForm;

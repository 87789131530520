import React, { forwardRef } from 'react';
import { ImmediateMemoryCard } from '../baseline-test-cards';
import { DEFAULT_USER_LANGUAGE } from '../../../utilities/localization';

const ImmediateMemoryStep = forwardRef((
  {
    user,
    baseline,
    onSubmit,
    ...rest
  },
  ref
) => {
  return (
    <ImmediateMemoryCard
      {...rest}
      ref={ref}
      user={user}
      language={user?.person?.language || DEFAULT_USER_LANGUAGE}
      immediateMemory={{ ...(baseline?.immediate_memory_test || {}) }}
      onSubmit={onSubmit}
    />
  );
});

ImmediateMemoryStep.hasChanged = (initial, current) => {
  const currentScores = current.scores || [];
  const initialScores = initial.scores;

  const scoresDiff = currentScores.filter(score => {
    if (!initialScores && score === '') {
      return false;
    }
    return (initialScores || []).indexOf(score) < 0;
  });

  return scoresDiff.length > 0
    || (initial.word_list || 1) !== current.word_list
    || (initial.notes || '') !== current.notes;
};

export default ImmediateMemoryStep;

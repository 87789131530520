import React from 'react';
import PropTypes from 'prop-types';
import { FormInput, FormInputMessage } from '../../../forms';
import Strings from '../lang';

const BuffaloTestFormRow = ({
  minute,
  incline,
  heartRate,
  onHeartRateChange,
  heartRateErrorMessage,
  heartRateValid,
  symptoms,
  onSymptomChange
}) => (
  <tr>
    <td>{minute}:00</td>
    <td>{incline}</td>
    <td className="col-md-2">
      <FormInput 
        type="text" 
        className="form-control" 
        inputProps={{
          placeholder: Strings.bpmPlaceHolderText,
          value: heartRate,
          onChange: onHeartRateChange
        }} 
      />
      <FormInputMessage
        className="alert alert-danger"
        text={heartRateErrorMessage}
        visible={!heartRateValid}
      />
    </td>
    <td>
      <FormInput 
        type="text" 
        className="form-control" 
        inputProps={{
          placeholder: Strings.symptomsLabel,
          value: symptoms,
          onChange: onSymptomChange
        }} 
      />
    </td>
  </tr>
);

BuffaloTestFormRow.propTypes = {
  minute: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  incline: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  heartRate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  symptoms: PropTypes.string,
  heartRateErrorMessage: PropTypes.string,
  onHeartRateChange: PropTypes.func
};

export default BuffaloTestFormRow;

import React, { useCallback, useState } from 'react';
import Modal from '../../modal';
import { FormComponent, FormInputGroup } from '../../../forms';
import Strings from './lang';

const ArchiveForm = ({
  onCancel,
  onSubmit = () => { }
}) => {
  const [reason, setReason] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const isValid = reason.length > 0;

  const onUpdateReason = useCallback((e) => {
    setReason(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!isValid) return;

    onSubmit(reason);
  }, [isValid, onSubmit, reason]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <p>{Strings.archiveModalMessage}</p>
      <FormInputGroup
        required
        className="form-group city-input-group"
        labelText={Strings.reasonLabel}
        inputType="textarea"
        inputProps={{
          className: 'form-control',
          value: reason,
          rows: 5,
          maxRows: 10,
          onChange: onUpdateReason
        }}
        messageText={Strings.reasonErrorMessage}
        messageClassName="alert alert-danger"
        inputValid={isValid}
        touched={submitted}
      />
      <div className="form-footer">
        <button type="button" className="btn btn-default" onClick={onCancel}>
          {Strings.cancelButton}
        </button>
        <button
          type="submit"
          className="btn btn-danger"
          disabled={!isValid}
        >
          {Strings.confirmArchiveButton}
        </button>
      </div>
    </FormComponent>
  );
};

const ArchiveBaselineModal = ({
  isOpen = false,
  onClose = () => { },
  onSubmit = () => { }
}) => {
  const handleSubmit = useCallback((reason) => {
    onClose();
    onSubmit(reason);
  }, [onClose, onSubmit]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <h1>{Strings.archiveModalTitle}</h1>
      </Modal.Header>
      <Modal.Body>
        <ArchiveForm
          onCancel={onClose}
          onSubmit={handleSubmit}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ArchiveBaselineModal;

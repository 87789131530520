import React from 'react';
import PropTypes from 'prop-types';
import { FormComponent } from '../../../forms';
import Card from '../../card';
import Strings from '../lang';

const SoapSection = ({
  title,
  headerButtons,
  onSubmitSection,
  children
}) => (
  <Card title={title} actionButtons={headerButtons}>
    <FormComponent onSubmit={onSubmitSection}>
      <div className="card card-form">
        {children}
        <div className="form-footer">
          <button type="submit" className="btn btn-primary">{Strings.saveSectionButtonText}</button>
        </div>
      </div>
    </FormComponent>
  </Card>
);

SoapSection.propTypes = {
  title: PropTypes.string,
  headerButtons: PropTypes.node,
  onSubmitSection: PropTypes.func
};

export default SoapSection;

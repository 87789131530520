import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { getAuthToken } from '../../../tokens';
import Modal from '../../modal';
import Icon from '../../icon';
import { setClaimReportCompleted } from '../../../redux/actions/idt-claims';

const getMessageData = (message) => {
  try {
    return JSON.parse(message);
  } catch (e) {
    return {};
  }
};

const ReportEditModal = ({
  isOpen,
  claimId,
  blockId,
  reportId,
  onClose
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [completed, setCompleted] = useState(false);

  const handleClose = useCallback(() => {
    if (completed) {
      dispatch(setClaimReportCompleted(claimId, blockId));
    }

    onClose();
  }, [blockId, claimId, completed, dispatch, onClose]);

  useEffect(() => {
    const listener = (event) => {
      if (event.origin === process.env.CCMI_AXIA_HOST) {
        const { type, data } = getMessageData(event.data) || {};
        if (type === 'idt_reports' && data?.complete === true) {
          setCompleted(true);
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);

  return (
    <Modal
      blur
      fullScreen
      isOpen={isOpen}
      className="idt-embed-modal"
      onClose={handleClose}
    >
      <Modal.Body>
        <iframe
          ref={ref}
          title="Edit IDT Report"
          src={`${process.env.CCMI_AXIA_HOST}/embed/idt-reports/v1/reports/${reportId}/edit#access_token=${getAuthToken()}`}
          width="100%"
          height="100%"
        />
        <button type="button" className="modal-close-button" onClick={handleClose}>
          <Icon name="xmark" />
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ReportEditModal;

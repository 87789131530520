import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Switch, Case, Default } from 'react-if';
import ElapsedTimer from '../../timers/elapsed-timer';
import TIMER_STATES from './timer-states';
import Strings from './lang';

const TIMER_INTERVAL = 10;
const DEFAULT_FORMATTER = (t) => dayjs(t).format('mm:ss.SSS');

class StopWatch extends Component {
  constructor(props) {
    super(props);
    this.timer = new ElapsedTimer(TIMER_INTERVAL, this.onTimerTick.bind(this));
    const { timerProps } = props;

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.reset = this.reset.bind(this);
    this.done = this.done.bind(this);

    this.state = {
      elapsedTime: timerProps?.elapsedTime ?? 0,
      timerState: timerProps?.timerState ?? TIMER_STATES.NOT_STARTED
    };
  }

  componentWillUnmount() {
    if (this.timer.isRunning()) {
      this.timer.stop();
    }
  }

  render() {
    const {
      disabled = false,
      formatter = DEFAULT_FORMATTER,
      completable = true,
      startBtnText = Strings.timerStartText,
      stopBtnText = Strings.timerStopText,
      resetBtnText = Strings.timerResetText,
      doneBtnText = Strings.timerDoneText,
      onDoneRenderableNode = null
    } = this.props;

    return (
      <div className="stop-watch-control">
        <div className="stop-watch-display">
          <p>{formatter(this.state.elapsedTime)}</p>
        </div>
        <div className="stop-watch-button-group">
          <Switch>
            <Case condition={this.state.timerState === TIMER_STATES.NOT_STARTED}>
              <button type="button" disabled={disabled} className="btn btn-primary" onClick={this.start}>
                {startBtnText}
              </button>
            </Case>
            <Case condition={this.state.timerState === TIMER_STATES.RUNNING}>
              <button type="button" disabled={disabled} className="btn btn-default" onClick={this.stop}>
                {stopBtnText}
              </button>
            </Case>
            <Case condition={this.state.timerState === TIMER_STATES.STOPPED}>
              <>
                <button type="button" disabled={disabled} className="btn btn-danger" onClick={this.reset}>
                  {resetBtnText}
                </button>
                {completable && (
                  <button type="button" disabled={disabled} className="btn btn-success" onClick={this.done}>
                    {doneBtnText}
                  </button>
                )}
              </>
            </Case>
            <Case condition={this.state.timerState === TIMER_STATES.COMPLETED}>
              {onDoneRenderableNode || null}
            </Case>
            <Default>
              {null}
            </Default>
          </Switch>
        </div>
      </div>
    );
  }

  reset() {
    let { elapsedTime, timerState } = this.state;
    const { onReset = () => {} } = this.props;
    elapsedTime = 0;
    timerState = TIMER_STATES.NOT_STARTED;

    this.setState({
      elapsedTime,
      timerState
    });

    onReset(timerState, elapsedTime);
  }

  start() {
    const { elapsedTime } = this.state;
    let { timerState } = this.state;
    const { onStart = () => {} } = this.props;
    this.timer.start();
    timerState = TIMER_STATES.RUNNING;

    this.setState({
      timerState
    });

    onStart(timerState, elapsedTime);
  }

  stop() {
    const { elapsedTime } = this.state;
    let { timerState } = this.state;
    const { onStop = () => {} } = this.props;
    this.timer.stop();
    timerState = TIMER_STATES.STOPPED;

    this.setState({
      timerState
    });

    onStop(timerState, elapsedTime);
  }

  done() {
    const { elapsedTime } = this.state;
    let { timerState } = this.state;
    const { onDone = () => {} } = this.props;
    timerState = TIMER_STATES.COMPLETED;

    this.setState({
      timerState
    });

    onDone(timerState, elapsedTime);
  }

  onTimerTick(msDuration) {
    let { elapsedTime } = this.state;
    elapsedTime = msDuration;
    this.setState({
      elapsedTime
    });
  }
}

StopWatch.propTypes = {
  onStart: PropTypes.func,
  onStop: PropTypes.func,
  onDone: PropTypes.func,
  onReset: PropTypes.func,
  timerProps: PropTypes.shape({
    elapsedTime: PropTypes.number,
    timerState: PropTypes.number
  }),
  onDoneRenderableNode: PropTypes.node
};

export default StopWatch;

import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import { CSVDownloadLink } from '../csv-exporter';
import { sortOnKeyPath } from '../../utilities/sort-keypath';
import { INPUT_DATE_FORMAT } from '../../dates/formats';
import { age } from '../../dates';
import Icon from '../icon';
import Strings from './lang';

const patientCSVHeader = ['first_name', 'last_name', 'age', 'email', 'phone_number', 'baseline_expiry', 'dana_baseline_count'];
/** 
 * Excel auto formats cells with numbers into a number format which
 * will convert some phone numbers into exponential values. Adding
 * the `=` sign in front of the double quotes makes Excel think its
 * an equation containing text and therefore will not auto format the
 * cell. (Also tested in Apple's Numbers application)
 */
/* eslint-disable no-useless-escape */
const patientCellFormatter = (cell, heading) => {
  return heading === 'phone_number' ? `=\"${cell}\"` : `\"${cell}\"`;
};

const patientCSVSerializer = (patient) => {
  const person = patient.person || {};
  const baselineExpiry = dayjs(patient.baseline_date).add(1, 'year');
  return [
    person.first_name,
    person.last_name,
    age(person.birthday, 'years'),
    patient.email,
    person.phone_number,
    baselineExpiry.isValid() ? baselineExpiry.format(INPUT_DATE_FORMAT) : '',
    patient.dana_baseline_count ?? 0
  ];
};

const ExportPatientsButton = ({
  patients = [],
  className
}) => {
  const onRequestCSV = useCallback(() => {
    return new Promise((resolve) => {
      const dataForExport = sortOnKeyPath(patients, 'person.first_name').map(patientCSVSerializer);

      resolve(dataForExport);
    });
  }, [patients]);

  return (
    <CSVDownloadLink
      onRequest={onRequestCSV}
      filename={`patients_${dayjs().unix().toString()}`}
      headers={patientCSVHeader}
      cellFormatter={patientCellFormatter}
      linkClassName={className}
    >
      <Icon name="file-excel" />&nbsp;&nbsp;
      {Strings.exportPatientText}
    </CSVDownloadLink>
  );
};

export default ExportPatientsButton;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AuthenticationPaths } from '../../paths';
import { withRouter } from '../../utilities/router-utils';
import logout from './logout';
import { IconButton } from '../buttons';
import Strings from './lang';

const LogoutButton = ({
  className,
  router
}) => (
  <IconButton 
    className={classNames('logout-button', className)}
    icon="right-from-bracket"
    text={Strings.logoutText}
    onClick={(e) => {
      e.preventDefault();
      logout();
      router.push(AuthenticationPaths.index.link);
    }}
  />
);

LogoutButton.propTypes = {
  className: PropTypes.string
};

export default withRouter(LogoutButton);

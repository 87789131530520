import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import Tabs from '../tabs';
import { getCurrentUserId } from '../../tokens';
import { getBatchesAsync, createBatchAsync } from '../../redux/thunks/batches';
import { sortOnKeyPath } from '../../utilities/sort-keypath';
import { withRouter } from '../../utilities/router-utils';
import BagTagsForm from './bag-tags-form';
import BagTagsTable from './bag-tags-table';
import Strings from './lang';

import {
  getBagTagsTableSortKeyPath,
  setBagTagsTableSortKeyPath
} from './bag-tags-table-sort-keypath';

const createBatchMap = (batches) => {
  return Object.keys(batches || {}).map(batchId => batches[batchId]);
};

class BagTags extends Component {
  constructor(props) {
    super(props);

    const {
      queryParams = {},
      batches = {}
    } = props;

    const { tab = 'manage' } = queryParams;
    const sortKeyPath = getBagTagsTableSortKeyPath();
    const batchMap = createBatchMap(batches);

    this._createBatch = this._createBatch.bind(this);
    this._updateSortKeyPath = this._updateSortKeyPath.bind(this);

    this.state = {
      batches,
      sortedBatches: sortOnKeyPath(batchMap, sortKeyPath),
      sortKeyPath,
      selectedTabIndex: tab,
      activity: false,
      error: null
    };
  }

  componentDidMount() {
    this._getBatches();
  }

  render() {
    return (
      <Page className="bag-tags">
        <div className="row">
          <div className="col-md-8">
            <h1 className="display">
              {Strings.title}
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <ErrorBanner error={this.state.error} />

            <Tabs
              items={[
                {
                  key: 'generate',
                  label: Strings.generateTabLabelText,
                  component: (
                    <Activity active={this.state.activity}>
                      <BagTagsForm
                        onSubmit={this._createBatch}
                      />
                    </Activity>
                  )
                },
                {
                  key: 'manage',
                  label: Strings.manageTabLabelText,
                  component: (
                    <Activity active={this.state.activity}>
                      <BagTagsTable
                        batches={this.state.sortedBatches}
                        sortKeyPath={this.state.sortKeyPath}
                        onUpdateSortKeyPath={this._updateSortKeyPath}
                      />
                    </Activity>
                  )
                }
              ]}
              selectedKey={this.state.selectedTabIndex}
              onSelectTab={tab => {
                this.setState({ selectedTabIndex: tab });
              }}
            />

          </div>
        </div>
      </Page>
    );
  }

  _updateSortKeyPath(sortKeyPath) {
    const { batches } = this.state;
    setBagTagsTableSortKeyPath(sortKeyPath);
    const sortedBatches = sortOnKeyPath(batches, sortKeyPath);
    this.setState({ sortKeyPath, sortedBatches });
  }

  _getBatches() {
    const { sortKeyPath } = this.state;
    this.setState({
      activity: true,
      error: null
    });

    this.props.getBatches().then(batches => {
      const sortedBatches = sortOnKeyPath(createBatchMap(batches), sortKeyPath);
      this.setState({
        activity: false,
        batches,
        sortedBatches
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  _createBatch(attributes) {
    const { sortKeyPath } = this.state;
    this.setState({
      activity: true,
      error: null
    });

    return this.props.createBatch(attributes).then((batch) => {
      const { batches } = this.state;
      batches[batch.id] = batch;
      const sortedBatches = sortOnKeyPath(createBatchMap(batches), sortKeyPath);

      this.setState({
        activity: false,
        batches,
        sortedBatches,
        selectedTabIndex: 'manage'
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }
}

const mapStateToProps = (state) => {
  const { batches = {}, users = {} } = state;
  const currentUser = users[getCurrentUserId()] || {};
  return { batches, currentUser };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBatches: () => {
      return dispatch(getBatchesAsync());
    },
    createBatch: (attributes) => {
      return dispatch(createBatchAsync(attributes));
    }
  };
};

const ConnectedBagTags = connect(
  mapStateToProps,
  mapDispatchToProps
)(BagTags);

const RoutableBagTags = withRouter(ConnectedBagTags);

export default RoutableBagTags;

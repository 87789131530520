import React from 'react';
import { languages } from './languages';
import { age } from '../../../dates';
import Icon from '../../icon';
import Strings from '../lang';

const WorkerDetails = ({ worker }) => {
  return (
    <section className="claim-section">
      <div className="claim-section-header">
        <Icon name="user-helmet-safety" />
        <div className="claim-section-title">{Strings.workerDetailsTitle}</div>
      </div>
      <div className="claim-section-content">
        <div className="row">
          <div className="col-md-6">
            <div className="claim-section-row">
              <label>{Strings.workerNameColumn}:</label>
              <span>{worker.first_name} {worker.last_name}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="claim-section-row">
              <label>{Strings.dobLabel}:</label>
              {worker.dob ? (
                <span>{worker.dob} ({age(worker.dob)})</span>
              ) : (
                <span><i>{Strings.notProvidedLabel}</i></span>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="claim-section-row">
              <label>{Strings.phoneNumberLabel}:</label>
              <span>{worker.phone_national}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="claim-section-row">
              <label>{Strings.interpreterLabel}:</label>
              <span>{languages[worker.interpreter_language] ?? languages.none}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkerDetails;

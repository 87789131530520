import { 
  jsonApiCreate, 
  jsonApiRead, 
  jsonApiUpdate, 
  jsonApiDelete 
} from '../../requests/jsonapi';
import { updateTaxItem, updateTaxes, removeTaxItem } from '../actions/taxes';

const createTaxRateAsync = (attributes) => {
  return dispatch => {
    return jsonApiCreate({ type: 'tax_rates', attributes }).then(tax => {
      dispatch(updateTaxItem(tax.id, tax));
      return Promise.resolve(tax);
    });
  };
};

const updateTaxItemAsync = (taxId, attributes) => {
  return dispatch => {
    return jsonApiUpdate({ type: 'tax_rates', id: taxId, attributes }).then(tax => {
      dispatch(updateTaxItem(taxId, tax));
      return Promise.resolve(tax);
    });
  };
};

const getTaxRatesAsync = () => {
  return dispatch => {
    return jsonApiRead({ type: 'tax_rates' }).then(taxes => {
      dispatch(updateTaxes(taxes));
      return Promise.resolve(taxes);
    });
  };
};

const deleteTaxItemAsync = (taxId) => {
  return dispatch => {
    return jsonApiDelete({ type: 'tax_rates', id: taxId }).then(() => {
      dispatch(removeTaxItem(taxId));
      return Promise.resolve();
    });
  };
};

export {
  createTaxRateAsync,
  updateTaxItemAsync,
  getTaxRatesAsync,
  deleteTaxItemAsync
};

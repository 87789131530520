import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import { daysSince } from '../../dates';
import DiagnosisModal from './diagnosis-modal';
import { filterAndGroupCheckedDiagnosisKeys } from './diagnosis-utilities';
import DiagnosisChecklistView from './diagnosis-checklist-view';
import Strings from './lang';
import Icon from '../icon';

const CHRONIC_INJURY_DAYS = 30;

const getInjuryType = (date = dayjs()) => {
  return daysSince(date, dayjs()) >= CHRONIC_INJURY_DAYS 
    ? 'chronic' 
    : 'acute';
};

const InjuryDiagnosis = ({
  diagnosis = {},
  injuryDate,
  className,
  editable = false,
  headingText = Strings.diagnosisHeadingText,
  onDiagnosisSave
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { 
    checkedKeys = [], 
    groupedKeys = [] 
  } = useMemo(() => filterAndGroupCheckedDiagnosisKeys(diagnosis), [diagnosis]);
  const type = useMemo(() => getInjuryType(injuryDate), [injuryDate]);
  const hasDiagnosis = diagnosis.none !== true && checkedKeys.length > 0;

  return (
    <div className={className}>
      <div className="row">
        <div className="col-md-12">
          <h3>
            {headingText}&nbsp;
            {!hasDiagnosis && Strings.defaultDiagnosisText}&nbsp;
            {editable === true && (
              <>
                <button 
                  type="button" 
                  className="edit-button" 
                  onClick={() => setModalOpen(true)}
                >
                  <Icon name="pen-to-square" />
                  {Strings.editButtonText}
                </button>
                <DiagnosisModal 
                  isOpen={modalOpen}
                  type={type}
                  diagnosis={diagnosis}
                  onSubmit={onDiagnosisSave}
                  onClose={() => setModalOpen(false)}
                />
              </>
            )}
          </h3>
        </div>
      </div>

      <If condition={hasDiagnosis}>
        <Then>
          <div className="row">
            {groupedKeys.map((group, index) => (
              <DiagnosisChecklistView
                key={index}
                className="col-md-6"
                list={group}
                diagnosis={diagnosis}
              />
            ))}
          </div>
        </Then>
      </If>

      <If condition={diagnosis.notes !== undefined && diagnosis.notes.length > 0}>
        <Then>
          <div className="row">
            <div className="col-md-12">
              <p><strong>{Strings.diagnosisNotesLabelText}</strong> {diagnosis.notes}</p>
            </div>
          </div>
        </Then>
      </If>

    </div>
  );
};

/* eslint-disable react/no-unused-prop-types */
InjuryDiagnosis.propTypes = {
  className: PropTypes.string,
  diagnosis: PropTypes.object,
  injuryDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  editable: PropTypes.bool,
  onDiagnosisSave: PropTypes.func
};

export default InjuryDiagnosis;

import React from 'react';
import { SelectInputGroup } from '../../forms';
import Strings from './lang';

const ClinicTypeCategorySelect = ({
  required,
  category,
  label = Strings.clinicTypeLabel,
  errorMessage = Strings.errorMessage,
  touched = false,
  onChange
}) => {
  return (
    <SelectInputGroup
      className="form-group"
      labelText={label}
      inputProps={{
        className: 'form-control',
        value: category
      }}
      required={required}
      messageClassName="alert alert-danger"
      messageText={errorMessage}
      valid={!required || category !== ''}
      touched={touched}
      onUpdate={onChange}
    >
      <option value="" disabled hidden>{Strings.selectOptionLabel}</option>
      <option value="primary">{Strings.primaryLabel}</option>
      <option value="medical">{Strings.medicalLabel}</option>
      <option value="baseline">{Strings.baselineLabel}</option>
      <option value="specialist">{Strings.specialistLabel}</option>
    </SelectInputGroup>
  );
};

export default ClinicTypeCategorySelect;

import { NumberValidator } from '../../../forms';

const MIN_HEART_RATE = 0;
const MAX_HEART_RATE = 240;

const heartRateValidator = (heartRate) => {
  const hr = parseInt(heartRate, 10);
  return NumberValidator.test(heartRate) && (hr > MIN_HEART_RATE && hr <= MAX_HEART_RATE);
};

const timeValidator = (time, maxTime) => {
  return NumberValidator.test(time) && (parseFloat(time) >= 0 && parseFloat(time) <= maxTime);
};

export {
  MIN_HEART_RATE,
  MAX_HEART_RATE,
  heartRateValidator,
  timeValidator
};

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then } from 'react-if';
import { useParams, useNavigate } from 'react-router-dom';
import InjuryReportHeader from './injury-report-header';
import Activity from '../activity';
import AccessControl from '../access-control';
import InjuryReportSection from './injury-report-section';
import { confirmation } from '../confirmation';
import { RecoveryProtocols } from '../recovery-stages';
import { getInjuryReportAsync } from '../../redux/thunks/injury-reports';
import { updateUser } from '../../redux/actions/users';
import { createInjuryAssessmentAsync } from '../../redux/thunks/injury-assessments';
import { UserSelector } from '../../redux/selectors';
import { getCurrentClinicId } from '../../tokens';
import { UserPaths } from '../../paths';
import { ErrorBanner } from '../errors';
import replaceLinkParams from '../../utilities/replace-link-params';
import { RoleDescriptions } from '../../utilities/user-roles';
import { DEFAULT_USER_LANGUAGE } from '../../utilities/localization';
import Strings from './lang';

const userSharesClinicWithPatient = (user, patient) => {
  const { current_clinic = {} } = patient;
  const { clinics = [] } = user;
  return clinics.filter(clinic => clinic.id === current_clinic.id).length > 0;
};

const injuryReportSelector = (userId, reportId) => state => {
  const userInjuryReport = state.injuryReports[userId] || {};
  return userInjuryReport[reportId] || {};
};

const InjuryReportView = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [creatingAssessment, setCreatingAssessment] = useState(false);
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injuryReport = useSelector(injuryReportSelector(params.userId, params.reportId));
  const language = user?.person?.language || DEFAULT_USER_LANGUAGE;

  const getInjuryReport = useCallback((userId, reportId) => {
    setActivity(true);
    setError(null);

    dispatch(getInjuryReportAsync(userId, reportId)).then(() => {
      setActivity(false);
    }).catch((error) => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch]);

  const createInjuryAssessment = useCallback(() => {
    const activeInjuryId = user.active_injury_id;
    const injuryDate = injuryReport.created_at;
    const userId = user.id;
    const clinicId = getCurrentClinicId();

    let attributes = {
      user_id: userId,
      clinic_id: clinicId,
      injured_at: injuryDate,
      injury_report_id: injuryReport.id,
      recovery_protocol_stage_attributes: {
        rts_stage: RecoveryProtocols.rts.stages[0].value,
        rtl_stage: RecoveryProtocols.rtl.stages[0].value,
        rtw_stage: RecoveryProtocols.rtw.stages[0].value
      }
    };

    if (activeInjuryId) {
      attributes = {
        user_id: userId,
        clinic_id: clinicId,
        injury_id: activeInjuryId,
        injury_report_id: injuryReport.id
      };
    }

    setCreatingAssessment(true);
    setError(null);

    dispatch(createInjuryAssessmentAsync(userId, attributes)).then((assessment) => {
      setCreatingAssessment(false);

      if (userSharesClinicWithPatient(currentUser, user)) {
        currentUser.unread_notification_count -= currentUser.unread_notification_count > 0 ? 1 : 0;
        dispatch(updateUser(currentUser));
      }

      const assessmentInjuryId = activeInjuryId || assessment.injury_id;
      const route = replaceLinkParams(UserPaths.injuries.injuryReportAssessment.link, {
        userId,
        injuryId: assessmentInjuryId,
        assessmentId: assessment.id,
        reportId: injuryReport.id
      });

      navigate(route);
    }).catch((error) => {
      setError(error.message);
      setCreatingAssessment(false);
    });
  }, [currentUser, dispatch, injuryReport.created_at, injuryReport.id, navigate, user]);

  const onStartAssessment = useCallback(() => {
    const message = user?.active_injury_id
      ? Strings.ongoingInjuryAssessmentMessage
      : Strings.startAssessmentConfirmMessage;

    confirmation(message, {
      title: Strings.startAssessmentTitle,
      onConfirm: createInjuryAssessment
    });
  }, [createInjuryAssessment, user?.active_injury_id]);

  useEffect(() => {
    if (params.userId && params.reportId) {
      getInjuryReport(params.userId, params.reportId);
    }
  }, [getInjuryReport, params.reportId, params.userId]);

  return (
    <Activity active={activity}>
      <div className="injury-report-display">
        <div className="row">
          <div className="col-md-12">
            <h1 className="display">
              {Strings.title}
              <AccessControl roles={[RoleDescriptions.Clinician, RoleDescriptions.Specialist]}>
                <If condition={!injuryReport?.assessed}>
                  <Then>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      disabled={creatingAssessment}
                      onClick={onStartAssessment}
                    >
                      {Strings.startAssessmentTitle}
                    </button>
                  </Then>
                </If>
              </AccessControl>
            </h1>
            <ErrorBanner error={error} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <InjuryReportHeader
              user={user}
              reporter={injuryReport?.reporter}
              injuryDate={injuryReport?.created_at}
            />
            <InjuryReportSection injuryReport={injuryReport} language={language} />
          </div>
        </div>

      </div>
    </Activity>
  );
};

export default InjuryReportView;

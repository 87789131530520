import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { jsonApiUpdate } from '../../requests/jsonapi';
import { AuthenticationPaths, RegistrationPaths, RedirectPaths } from '../../paths';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import PasswordResetForm from './password-reset-form';
import { userHasRoleMatchingOneOfDescriptions, AdminAndClinicRoleDescriptions } from '../../utilities/user-roles';
import { showAlert } from '../alert-notifications';
import Strings from './lang';

const reroute = (navigate, path, state = null, withSuccess = true) => {
  if (withSuccess) {
    showAlert('success', {
      autoDismiss: 3000,
      message: Strings.passwordResetSuccessful
    });
  }

  navigate(path, { state, replace: true });
};

const routeBasedOnRoles = (navigate, user) => {
  const isClinicUser = userHasRoleMatchingOneOfDescriptions(user, AdminAndClinicRoleDescriptions);
  const path = isClinicUser ? AuthenticationPaths.index.link : RedirectPaths.mobileApp.link;
  const state = isClinicUser ? null : { passwordReset: true };
  const success = !!isClinicUser;
  reroute(navigate, path, state, success);
};

const PasswordReset = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const next = useCallback((user) => {
    const windowPath = window.location.pathname;
    /** Strip off the token param */
    const pathname = windowPath.slice(0, windowPath.lastIndexOf('/') + 1);

    if (pathname === RegistrationPaths.passwordReset.link) {
      reroute(navigate, RegistrationPaths.index.link);
    } else {
      routeBasedOnRoles(navigate, user);
    }
  }, [navigate]);

  const resetPassword = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    jsonApiUpdate({ type: 'passwords', attributes }).then((user) => {
      setActivity(false);
      next(user);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [next]);

  return (
    <Page className="password-reset" hideNavigationBar showHeaderImage>
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <h1 className="display">{Strings.resetTitle}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <ErrorBanner error={error} />
          <Activity active={activity}>
            <PasswordResetForm
              token={params.token}
              onSubmit={resetPassword}
            />
          </Activity>
        </div>
      </div>
    </Page>
  );
};

export default PasswordReset;

import React from 'react';
import classnames from 'classnames';
import { If, Then, Else } from 'react-if';
import { FormComponent, FormInputGroup } from '../../forms';
import Icon from '../icon';
import Strings from './lang';

const ClinicSearchForm = ({
  compact = false,
  search,
  error = null,
  onUpdate,
  onBlur,
  className,
  searchLabelText = Strings.searchLabelText,
  searchButtonText = Strings.searchButtonText,
  placeholder = Strings.searchInputPlaceholder,
  onClear,
  onSubmit
}) => (
  <FormComponent
    className={classnames(
      'search-form',
      'clinic-search-form',
      { compact },
      className
    )}
    onSubmit={onSubmit}
  >
    <FormInputGroup
      className="form-group search-input-group"
      labelText={searchLabelText}
      inputType="text"
      messageClassName="alert alert-danger"
      messageText={error}
      inputProps={{
        className: 'form-control',
        placeholder,
        value: search,
        onBlur,
        onChange: onUpdate
      }}
      touched={!!error}
      inputValid={!error}
    />
    <button className="btn btn-primary" type="submit">
      <If condition={compact === true}>
        <Then>
          <Icon name="magnifying-glass" />
        </Then>
        <Else>
          <span>{searchButtonText}</span>
        </Else>
      </If>
    </button>

    <button className="btn btn-default" type="button" onClick={onClear}>
      <If condition={compact === true}>
        <Then>
          <Icon name="xmark" />
        </Then>
        <Else>
          <span>{Strings.clearButtonText}</span>
        </Else>
      </If>
    </button>

  </FormComponent>
);

export default ClinicSearchForm;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../icon';
import { ClinicServicesTags } from '../clinic-services';
import { ClinicPaths } from '../../paths';
import replaceLinkParams from '../../utilities/replace-link-params';
import Strings from './lang';

const DEFAULT_CLINIC_PHOTO_URL = 'https://s3.ca-central-1.amazonaws.com/ccmi-global/images/default_profile.png';
const getPhotoUrl = (url) => (DEFAULT_CLINIC_PHOTO_URL === url ? '/images/logo/color.svg' : url);

const ClinicsTable = ({
  clinics = []
}) => (
  <div className="table-responsive-md">
    <table className="table clinics-table">
      <thead>
        <tr>
          <th className="no-action">
            {Strings.nameHeadingText}
          </th>
          <th className="no-action">
            {Strings.balanceHeadingText}
          </th>
          <th className="no-action">
            {Strings.creditsHeadingText}
          </th>
          <th className="no-action">
            {Strings.servicesHeadingText}
          </th>
          <th className="no-action">
            {Strings.statusHeadingText}
          </th>
        </tr>
      </thead>
      <tbody>
        {clinics.map(clinic => {
          if (clinic) {
            const photoUrl = getPhotoUrl(clinic.photo_url);

            return (
              <tr key={clinic.id}>
                <td className="highlighted">
                  <div className="clinic-title-row">
                    <div className="clinic-title">
                      <Link
                        to={replaceLinkParams(ClinicPaths.profile.link, {
                          clinicId: clinic.id
                        })}
                      >
                        <img
                          alt={clinic.name}
                          src={photoUrl}
                          className={photoUrl.startsWith('/') ? 'default' : null}
                        />
                        {clinic.name}
                      </Link>
                    </div>
                    <div className="clinic-icons">
                      {clinic.idt_provider && (
                        <div title="Axia IDT Provider" className="clinic-icon">
                          <img
                            src="/images/logo/axia-logo-2x.png"
                            className="axia-idt-provider-icon"
                            alt=""
                          />
                        </div>
                      )}
                      {clinic.searchable && clinic.active && (
                        <div title="Visible on map" className="clinic-icon">
                          <Icon name="map-location-dot" />
                        </div>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <Link
                    to={replaceLinkParams(ClinicPaths.invoices.index.link, {
                      clinicId: clinic.id
                    })}
                  >
                    {clinic.balance_owing}
                  </Link>
                </td>
                <td>
                  {clinic.credits_available || 0}
                </td>
                <td>
                  <ClinicServicesTags services={clinic.services} />
                </td>
                <td>{clinic.active ? Strings.activeText : Strings.inactiveText }</td>
              </tr>
            );
          }
            
          return null;
        })}
      </tbody>
    </table>
  </div>
);

ClinicsTable.propTypes = {
  clinics: PropTypes.array.isRequired
};

export default ClinicsTable;

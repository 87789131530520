import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { useNavigate } from 'react-router-dom';
import checkUserTrainingExpiry from '../../utilities/user-training-expiry';
import checkUserActive from '../../utilities/user-active';
import replaceLinkParams from '../../utilities/replace-link-params';
import { 
  RoleDescriptions, 
  userHasRoleRequiringTraining, 
  userHasRoleMatchingDescription,
  userHasAdminRole
} from '../../utilities/user-roles';
import { getCurrentClinicId } from '../../tokens';
import { 
  UserPaths, 
  ClinicPaths, 
  PostInjuryAssessmentPaths, 
  BaselineTestPaths, 
  BagTagPaths, 
  TaxPaths,
  ResourcesPaths
} from '../../paths';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import { ClinicSearchControl } from '../clinic-search';
import AccessControl from '../access-control';
import UntrainedAlert from '../untrained/alert';
import AccountBalanceCard from './account-balance-card';
import DeactivatedAlert from '../deactivated/alert';
import DashboardCard from './dashboard-card';
import SearchStrings from '../clinic-search/lang';
import { UserSelector } from '../../redux/selectors';
import useDashboardPermissions from './permissions';
import Strings from './lang';
import Icon from '../icon';

const getCurrentClinic = (currentUser, clinicId) => { 
  return currentUser?.clinics?.find(clinic => String(clinic.id) === String(clinicId));
};

const Dashboard = () => {
  const navigate = useNavigate();
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const clinicId = useMemo(() => getCurrentClinicId() ?? 0, []);
  const currentClinic = useMemo(() => (
    getCurrentClinic(currentUser, clinicId)
  ), [currentUser, clinicId]);
  const hasClinicianRole = useMemo(() => (
    userHasRoleMatchingDescription(currentUser, RoleDescriptions.Clinician)
  ), [currentUser]);
  const isBaselineTester = useMemo(() => (
    userHasRoleMatchingDescription(currentUser, RoleDescriptions.BaselineTester)
  ), [currentUser]);
  const isAdmin = useMemo(() => userHasAdminRole(currentUser), [currentUser]);
  const [error, setError] = useState(null);
  const [searchResultsEmpty, setSearchResultsEmpty] = useState(false);
  const permissions = useDashboardPermissions();

  const onSearch = useCallback(response => {
    const { attributes = {}, results = [] } = response;
    const { clinic_id } = attributes;
    setError(null);
    setSearchResultsEmpty(results.length === 0);

    // If there are multiple results, redirect to the search results page.
    // If there is only one result, redirect to the user profile.
    if (results.length > 1) {
      navigate(
        replaceLinkParams(ClinicPaths.patients.link, { clinicId: clinic_id }),
        {
          state: { searched: true }
        }
      );
    } else if (results.length === 1) {
      const user = results[0];
      const path = replaceLinkParams(ClinicPaths.patientProfile.index.link, {
        userId: user.id,
        clinicId: clinic_id
      });
      navigate(path);
    }
  }, [navigate]);

  const onSearchError = useCallback(error => {
    setError(error.message);
    setSearchResultsEmpty(false);
  }, []);

  const onSearchClear = useCallback(() => {
    setError(null);
    setSearchResultsEmpty(false);
  }, []);

  return (
    <Page className="dashboard">
      <div className="dashboard-content">
        <If
          condition={userHasRoleRequiringTraining(currentUser)
            && !checkUserTrainingExpiry(currentUser)}
        >
          <Then>
            <div className="row">
              <div className="col-md-12">
                <UntrainedAlert />
              </div>
            </div>
          </Then>
        </If>
        <If condition={!checkUserActive(currentUser)}>
          <Then>
            <div className="row">
              <div className="col-md-12">
                <DeactivatedAlert />
              </div>
            </div>
          </Then>
        </If>

        <div className="row">
          <div className="col-md-12">
            <If condition={error != null}>
              <Then>
                <ErrorBanner error={error} />
              </Then>
              <Else>
                <If condition={searchResultsEmpty}>
                  <Then>
                    <p className="alert alert-info">
                      {SearchStrings.searchResultsEmptyText}
                    </p>
                  </Then>
                </If>
              </Else>
            </If>
          </div>
        </div>

        <div className="row">
          <div className="col-md-8" />
          <div className="col-md-4">
            <AccessControl roles={permissions.searchPatients}>
              <ClinicSearchControl
                compact
                clinicId={clinicId}
                onSearch={onSearch}
                onError={onSearchError}
                onClear={onSearchClear}
              />
            </AccessControl>
          </div>
        </div>

        <div className="row">

          <AccessControl roles={permissions.baselineTest}>
            <div className="col-md-4">
              <DashboardCard
                icon="chart-line"
                title={Strings.baselineTestCardTitleText}
                subtitle={
                  hasClinicianRole || isBaselineTester
                    ? Strings.baselineTestCardSubtitleText
                    : ''
                }
                buttonTitle={
                  hasClinicianRole || isBaselineTester
                    ? Strings.startCardButtonTitle
                    : Strings.frontDeskCheckInText
                }
                onClickButton={() => navigate(BaselineTestPaths.start.link)}
                rightBadge={(
                  <AccessControl roles={permissions.resumeBaselineBadge}>
                    <button
                      type="button"
                      className="edit-button"
                      onClick={() => {
                        navigate(BaselineTestPaths.continue.link);
                      }}
                    >
                      <Icon name="bookmark" /> {Strings.resumeTestButtonText}
                    </button>
                  </AccessControl>
                )}
              />
            </div>
          </AccessControl>

          <AccessControl roles={permissions.continueBaselineCard}>
            <div className="col-md-4">
              <DashboardCard
                icon="circle-arrow-right"
                title={Strings.continueBaselineCardTitle}
                buttonTitle={Strings.startCardButtonTitle}
                onClickButton={() => {
                  navigate(BaselineTestPaths.continue.link);
                }}
              />
            </div>
          </AccessControl>

          <AccessControl roles={permissions.postInjury}>
            <div className="col-md-4">
              <DashboardCard
                icon="bolt"
                title={Strings.postInjuryAssessmentCardTitleText}
                subtitle={Strings.postInjuryAssessmentCardSubtitleText}
                buttonTitle={hasClinicianRole
                  ? Strings.startCardButtonTitle
                  : Strings.frontDeskCheckInText}
                disabled={clinicId === 0}
                onClickButton={() => {
                  navigate(PostInjuryAssessmentPaths.startAssessment.link);
                }}
              />
            </div>
          </AccessControl>

          <AccessControl
            allowIfClinicDeactivated
            roles={permissions.manangePatients}
          >
            <div className="col-md-4">
              <DashboardCard
                icon="users-medical"
                title={Strings.clinicPatientsCardTitleText}
                subtitle=""
                buttonTitle={Strings.cardButtonTitle}
                disabled={clinicId === 0}
                onClickButton={() => {
                  navigate(
                    replaceLinkParams(ClinicPaths.patients.link, { clinicId })
                  );
                }}
              />
            </div>
          </AccessControl>

          <AccessControl roles={permissions.clinicsIndex}>
            <div className="col-md-4">
              <DashboardCard
                icon="house-medical"
                title={Strings.clinicsCardTitleText}
                subtitle=""
                buttonTitle={Strings.cardButtonTitle}
                onClickButton={() => {
                  navigate(ClinicPaths.index.link);
                }}
              />
            </div>
          </AccessControl>

          <AccessControl roles={permissions.teamLeaders}>
            <div className="col-md-4">
              <DashboardCard
                icon="futbol"
                title={Strings.teamLeadersCardTitleText}
                subtitle=""
                buttonTitle={Strings.cardButtonTitle}
                onClickButton={() => {
                  navigate(UserPaths.leaders.link);
                }}
              />
            </div>
          </AccessControl>

          <AccessControl roles={permissions.bagTags}>
            <div className="col-md-4">
              <DashboardCard
                icon="tags"
                title={Strings.manageBagTagsTitleText}
                subtitle=""
                buttonTitle={Strings.cardButtonTitle}
                onClickButton={() => {
                  navigate(BagTagPaths.index.link);
                }}
              />
            </div>
          </AccessControl>

          <AccessControl roles={permissions.clinicStats}>
            <div className="col-md-4">
              <DashboardCard
                icon="chart-mixed"
                title={Strings.clinicsStatsCardTitleText}
                subtitle=""
                buttonTitle={Strings.cardButtonTitle}
                onClickButton={() => {
                  navigate(ClinicPaths.stats.link);
                }}
              />
            </div>
          </AccessControl>

          <AccessControl roles={permissions.taxRates}>
            <div className="col-md-4">
              <DashboardCard
                icon="badge-percent"
                title={Strings.taxRatesCardTitle}
                subtitle=""
                buttonTitle={Strings.cardButtonTitle}
                onClickButton={() => {
                  navigate(TaxPaths.index.link);
                }}
              />
            </div>
          </AccessControl>

          <If condition={currentClinic?.idt_provider ?? false}>
            <Then>
              <AccessControl roles={permissions.axia}>
                <div className="col-md-4">
                  <DashboardCard
                    icon={(
                      <div className="axia-logo-container">
                        <img
                          src="/images/logo/axia-logo-2x.png"
                          alt="Axia Health"
                          className="axia-logo"
                        />
                      </div>
                    )}
                    className="axia-dashboard-card"
                    title="Axia Health"
                    subtitle="IDT Claims"
                    buttonTitle={Strings.cardButtonTitle}
                    onClickButton={() => {
                      navigate('/axia/claims');
                    }}
                  />
                </div>
              </AccessControl>
            </Then>
          </If>

          <If condition={isAdmin || (currentClinic?.resources_enabled ?? false)}>
            <Then>
              <AccessControl roles={permissions.resources}>
                <div className="col-md-4">
                  <DashboardCard
                    icon="folder-magnifying-glass"
                    title={Strings.resourcesCardTitleText}
                    subtitle=""
                    buttonTitle={Strings.cardButtonTitle}
                    onClickButton={() => {
                      navigate(ResourcesPaths.root.link);
                    }}
                  />
                </div>
              </AccessControl>
            </Then>
          </If>

          <AccessControl
            allowUntrained
            allowIfClinicDeactivated
            roles={permissions.accountBalance}
          >
            <div className="col-md-4">
              <AccountBalanceCard
                onClick={() => {
                  navigate(
                    replaceLinkParams(ClinicPaths.invoices.index.link, { clinicId })
                  );
                }}
              />
            </div>
          </AccessControl>

        </div>

      </div>
    </Page>
  );
};

export default Dashboard;

import React from 'react';
import { BagTag, BagTags } from '../../components/bag-tags';
import { Authorization } from '../authorization';
import { AdminRoleDescriptions } from '../../utilities/user-roles';
import PageNotFound from '../not-found-page';

const BagtagsRoutes = [
  {
    path: '*',
    element: (
      <Authorization
        allowRoles={AdminRoleDescriptions}
      />
    ),
    children: [
      {
        index: true,
        element: <BagTags />
      },
      {
        path: ':batchId',
        element: <BagTag />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
];

export default BagtagsRoutes;

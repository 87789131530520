import React from 'react';
import { connect } from 'react-redux';
import { UserSelector } from '../../redux/selectors';
import { RoleNames } from '../../utilities/user-roles';
import BookingLinksEmptyView from './booking-links-empty-view';
import BookingLinksList from './booking-links-list';

const BookingLinks = ({ 
  bookingLinks, 
  emptyMessage = null,
  editable = true,
  userId, 
  clinics 
}) => {
  if (!bookingLinks.length) {
    return (
      <BookingLinksEmptyView 
        clinics={clinics}
        editable={editable}
        userId={userId}
        message={emptyMessage}
      />
    );
  }

  return (
    <BookingLinksList 
      links={bookingLinks}
      editable={editable}
      clinics={clinics}
      userId={userId}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { userId } = ownProps;
  const bookingLinks = UserSelector.getUserBookingLinks(state, { userId });
  const clinics = UserSelector.getAvailableBookingLinkClinics(
    state, 
    { userId, roles: [RoleNames.Clinician, RoleNames.Specialist] }
  );
  
  return { clinics, bookingLinks };
};

export default connect(
  mapStateToProps
)(BookingLinks);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { createSessionAsync } from '../../redux/thunks/sessions';
import { UserActivity } from '../../user-activity';
import { ErrorStrings } from '../errors';
import HTTPStatusCodes from '../../utilities/http-status-codes';
import { LockoutProvider } from './context';
import LockModal from './lockout-modal';
import {
  getActivity,
  setActivity,
  removeAuthData,
  isAuthenticated,
  getAuthToken,
  getCurrentUserId,
  ACTIVITY_STORAGE_KEY,
  AUTH_TOKEN_KEY
} from '../../tokens';

// eslint-disable-next-line no-magic-numbers
const DEFAULT_MAX_DURATION = 1000 * 60 * 20;

const getAuthenticationErrorMessage = (error) => {
  let { message } = error;
  if (error.status === HTTPStatusCodes.Unauthorized) {
    message = ErrorStrings.error401Password;
  }

  return message;
};

class Lockout extends Component {
  static getDerivedStateFromProps(props) {
    const { currentUser } = props;
    if (currentUser.id) {
      return { currentUser };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { currentUser } = props;

    this.state = {
      currentUser,
      locked: false,
      manual: false,
      activity: false,
      error: null
    };

    this._localStorageListener = this._localStorageListener.bind(this);
    this._activity = new UserActivity(props.options);
    this._activityListener = this._activityListener.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this._lock = this._lock.bind(this);
  }

  componentDidMount() {
    this._startListening();
    window.addEventListener('storage', this._localStorageListener);
  }

  componentWillUnmount() {
    this._stopListening();
    window.removeEventListener('storage', this._localStorageListener);
  }

  render() {
    return (
      <LockoutProvider locked={this.state.locked} lock={this._lock}>
        {this.props.children}
        <LockModal 
          isOpen={this.state.locked}
          activity={this.state.activity}
          error={this.state.error}
          manual={this.state.manual}
          onSubmit={this.authenticate}
          user={this.state.currentUser}
          onSwitchAccounts={() => {
            window.location = '/';
          }}
        />
      </LockoutProvider>
    );
  }

  authenticate(attributes) {
    this.setState({
      activity: true,
      error: null
    });

    this.props.authenticate(attributes).then(() => {
      this.setState({ activity: false, locked: false });
      this._startListening();
    }).catch(error => {
      this.setState({
        activity: false,
        error: getAuthenticationErrorMessage(error)
      });
    });
  }

  get isEnabled() {
    // check if current page is /register, /login, / flow
    return this.props.exemptedPaths.findIndex(path => {
      if (path === '/' && window.location.pathname === path) {
        return true;
      }
        
      return window.location.pathname.indexOf(path) === 0 && path !== '/';
    }) < 0;
  }

  /**
   * Handle changes to the local storage. This function
   * should only trigger in response to another window/tab
   * manipulating local storage.
   * 
   * If the screen is locked, then we want to watch for the user activity key.
   * ~> if that key changes while the screen is locked, check to see if we have an auth token
   *    and that the time duration from when the activity key was changed until now is within 
   *    the timeout duration. Once the user logs in from another tab, the activity key is 
   *    automatically set to the current time so this duration should never be above a few 
   *    milliseconds.
   * 
   * If the screen is not locked but there was a change in the Auth token from having a value
   * to not having a value then the users has either, logged out, manually locked the screen, 
   * or the session was in another 
   * window timed out.
   */
  _localStorageListener(event) {
    const { timeout = DEFAULT_MAX_DURATION } = this.props;
    const now = dayjs().valueOf();
    if (this.state.locked 
      && event.key === ACTIVITY_STORAGE_KEY 
      && isAuthenticated() 
      && dayjs.duration(now - parseInt(event.newValue, 10)).asMilliseconds() < timeout
    ) {
      this._unlock();
    } else if (event.key === AUTH_TOKEN_KEY 
      && (event.oldValue !== null 
        && event.newValue == null)
      && this.isEnabled
    ) {
      this._lock();
    }
  }

  /**
   * This function checks the current duration of the activity.
   * The param value can be either null or an integer.
   * If the value is null, this means that no activity was received during
   * the given interval -> 1 second.
   *  ~> if this is the case then we need to check the duration from
   *     the value stored in local storage to the current time to see if
   *     it surpasses the maximum timeout duration.
   * If the param has a value then this value signifies dayjs().valueOf() of the 
   * most recent activity event during the 1 second interval. If we have a value 
   * then check to see if its more recent then the one in local storage before 
   * setting the value in local storage so that we always have the most 
   * recent activity time.
   */
  _activityListener(time) {
    const { timeout = DEFAULT_MAX_DURATION } = this.props;
    const storageActivity = getActivity() || 0;
    if (time !== null && time > storageActivity) {
      setActivity(time);
    } else if (time == null) {
      const now = dayjs().valueOf();
      const duration = dayjs.duration(now - storageActivity).asMilliseconds();

      if (duration > timeout) {
        // lock the screen
        this._prepareLock();
      }
    }
  }

  _prepareLock() {
    // Do not show the lockout overlay to unauthenticated users:
    const token = getAuthToken();

    if (this.isEnabled && token && token.length) {
      this._lock(false);
    }
  }

  _lock(manual = true) {
    this._stopListening();
    removeAuthData();
    this.setState({ locked: true, manual });
  }

  _unlock() {
    this._startListening();
    this.setState({ locked: false });
  }

  _startListening() {
    /** set the value to now each time the listener starts */
    setActivity(dayjs().valueOf());
    this._activity.addListener(this._activityListener);
    this._activity.startListening();
  }

  _stopListening() {
    this._activity.removeListener(this._activityListener);
    this._activity.stopListening();
  }
}

Lockout.propTypes = {
  options: PropTypes.object,
  exemptedPaths: PropTypes.array
};

const mapStateToProps = (state) => {
  const id = getCurrentUserId();
  const { users } = state;
  return {
    currentUser: users[id] || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (attributes) => {
      return dispatch(createSessionAsync(attributes));
    }
  };
};

const ConnectedLockout = connect(
  mapStateToProps,
  mapDispatchToProps
)(Lockout);

export default ConnectedLockout;

import { getAuthToken } from '../../tokens';

export const JSON_API_TYPE = 'application/vnd.api+json';

const defaultHeaders = {
  'Content-Type': JSON_API_TYPE,
  Accept: JSON_API_TYPE
};

const setHeaders = (headers = {}) => {
  const newHeaders = { ...defaultHeaders, ...(headers || {}) };

  if (!newHeaders.Authorization) {
    const token = getAuthToken();
    if (token && token.length) {
      newHeaders.Authorization = token;
    }
  }

  return newHeaders;
};

export default setHeaders;

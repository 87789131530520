import { jsonApiCreate } from '../../requests/jsonapi';
import { updateUser } from '../actions/users';
import { updateGuardianAuth } from '../actions/guardians-account';
import { setAuthDataFromUserData, updateCurrentClinicForUser } from '../../tokens';
import { setRegistrationSession } from '../../utilities/registration-session';
import { setRememberedUser, removeRememberedUser } from '../../utilities/email-storage';
import { getUserAsync } from './users';

const createSessionAsync = (attributes = {}, options = {}) => {
  return (dispatch) => {
    return jsonApiCreate({ type: 'sessions', attributes }, options).then(user => {
      const { rememberEmail } = options;
      const newOption = { ...options };
      
      if (rememberEmail !== undefined) {
        if (rememberEmail) {
          setRememberedUser(user);
        } else {
          removeRememberedUser(user);
        }
      }

      if (newOption.registrationAuth) {
        // if this is registration auth then, set the authorization header
        // as registration sessions are separated from standard sessions
        newOption.headers = { Authorization: user.auth_token };
        setRegistrationSession(user);
      } else {
        setAuthDataFromUserData(user);
        updateCurrentClinicForUser(user);
      }
      
      if (user.children) {
        dispatch(updateGuardianAuth(user));
      }

      dispatch(updateUser(user));

      return dispatch(getUserAsync(user.id, newOption));
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createSessionAsync
};

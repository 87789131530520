import { jsonApiRead, jsonApiCreate, jsonApiUpdate } from '../../requests/jsonapi';
import { getCurrentClinicId } from '../../tokens';
import { updateVOMSTest } from '../actions/voms';

const getVOMSTestAsync = (userId, injuryId, vomsId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/voms_tests/${vomsId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'voms_tests', id: vomsId }, options).then(results => {
      dispatch(updateVOMSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createVOMSTestAsync = (userId, injuryId, attributes) => {
  const queryParams = { current_clinic_id: getCurrentClinicId() };
  const options = { path: `users/${userId}/injuries/${injuryId}/voms_tests`, queryParams };
  return (dispatch) => {
    return jsonApiCreate({ type: 'voms_tests', attributes }, options).then(results => {
      dispatch(updateVOMSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateVOMSTestAsync = (userId, injuryId, vomsId, attributes) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/voms_tests/${vomsId}` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'voms_tests', id: vomsId, attributes }, options).then(results => {
      dispatch(updateVOMSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getVOMSTestAsync,
  createVOMSTestAsync,
  updateVOMSTestAsync
};

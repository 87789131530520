import React from 'react';
import { Link } from 'react-router-dom';
import QuickActions from '../quick-actions';
import AccessControl from '../access-control';
import { ClinicPaths } from '../../paths';
import { 
  RoleDescriptions,
  RoleNames,
  RoleResourceTypes,
  makeRoleDescription 
} from '../../utilities/user-roles';
import replaceLinkParams from '../../utilities/replace-link-params';
import Strings from './lang';

const makeManageUsersRoleDescriptions = (clinicId) => ([
  RoleDescriptions.SuperAdmin,
  RoleDescriptions.SalesAdmin,
  makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.IDTClinician, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.IDTFrontDesk, RoleResourceTypes.Clinic, clinicId)
]);

const makeViewClinicAccountRoles = (clinicId) => ([
  RoleDescriptions.SuperAdmin,
  RoleDescriptions.SalesAdmin,
  makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
]);

const makeManagePatientsRoleDescriptions = (clinicId) => ([
  RoleDescriptions.SuperAdmin,
  RoleDescriptions.SalesAdmin,
  makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
]);

const makeClinicActivationRoles = () => ([
  RoleDescriptions.SuperAdmin,
  RoleDescriptions.SalesAdmin
]);

const ClinicActions = ({
  clinic = {},
  toggleActivation
}) => {
  const active = clinic.active || false;

  return (
    <QuickActions>
      <AccessControl
        allowIfClinicDeactivated 
        roles={makeManageUsersRoleDescriptions(clinic.id)}
      >
        <li>
          <Link 
            className="btn btn-default" 
            to={replaceLinkParams(ClinicPaths.users.link, { clinicId: clinic.id })}
          >
            {Strings.usersActionHeadingText}
          </Link>
        </li>
      </AccessControl>

      <AccessControl 
        allowIfClinicDeactivated 
        roles={makeManagePatientsRoleDescriptions(clinic.id)}
      >
        <li>
          <Link 
            className="btn btn-default" 
            to={replaceLinkParams(ClinicPaths.patients.link, { clinicId: clinic.id })}
          >
            {Strings.patientsActionHeadingText}
          </Link>
        </li>
      </AccessControl>

      <AccessControl 
        allowIfClinicDeactivated 
        roles={makeViewClinicAccountRoles(clinic.id)}
      >
        <li>
          <Link 
            className="btn btn-default" 
            to={replaceLinkParams(ClinicPaths.invoices.index.link, { 
              clinicId: clinic.id
            })}
          >
            {Strings.viewAccountHeadingText}
          </Link>
        </li>
      </AccessControl>

      <AccessControl roles={makeClinicActivationRoles()}>
        <li>
          <button 
            type="button"
            className="btn btn-default" 
            onClick={toggleActivation}
          >
            {active 
              ? Strings.deactivateClinicButtonText 
              : Strings.activateClinicButtonText}
          </button>
        </li>
      </AccessControl>
    </QuickActions>
  );
};

export default ClinicActions;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { MobileLinks } from '../../utilities/mobile-detector';
import Strings from './lang';

const OPEN_DELAY = 500;

class MobileAppOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }

  componentDidMount() {
    if (this.props.isMobile) {
      setTimeout(() => {
        this.setState({
          opened: true
        });
      }, OPEN_DELAY);
    }
  }

  render() {
    return (
      <div 
        className={classnames('mobile-overlay', {
          opened: this.state.opened
        })}
      >
        <div className="mobile-app-inner">
          <div className="continue">
            <button type="button" className="edit-button" onClick={() => this.setState({ opened: false })}>
              {Strings.noThanksButtonText} &gt;&gt; 
            </button>
          </div>
          <div className="mobile-app-header">
            <div className="brand">
              <img 
                className="img-responsive" 
                src="/images/logo/color-full.svg" 
                alt="CCMI Brain Logo"
              />
            </div>
          </div>
          <div className="mobile-app-content text-center">
            {Strings.appInformationText}
          </div>
          <div className="app-download-links">
            {this.renderAppLink()}
          </div>
        </div>
      </div>
    );
  }

  renderAppLink() {
    const { platform } = this.props;
    const sources = MobileLinks[platform] || {};
    return (
      <a 
        target="_blank" 
        rel="noopener noreferrer"
        className={`app-link ${platform}`} 
        href={sources.link}
      >
        <img src={sources.img} alt="App Store Link" />
      </a>
    );
  }
}

MobileAppOverlay.propTypes = {
  isMobile: PropTypes.bool,
  platform: PropTypes.oneOf(Object.keys(MobileLinks))
};

export default MobileAppOverlay;

import React from 'react';
import dayjs from 'dayjs';
import Modal from '../../modal';
import { DISPLAY_DATE_FORMAT } from '../../../dates/formats';
import { TEST_TYPE_LABEL } from '../utils';
import Strings from '../lang';

const HistoryModal = ({
  title,
  isOpen = false,
  items = [],
  renderItem = () => null,
  onClose
}) => {
  return (
    <Modal
      isOpen={isOpen}
      className="history-modal"
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h1>{title}</h1>
      </Modal.Header>
      <Modal.Body>
        <div className="history-list">
          {items.map((item, index) => (
            <div key={item.id} className="history-item">
              <div className="history-item-header">
                <div className="item-title">
                  {dayjs(item.date).format(DISPLAY_DATE_FORMAT)} ({TEST_TYPE_LABEL(item.type)})
                </div>
                {index === 0 && (
                  <div className="label label-success rounded">
                    {Strings.currentLabel}
                  </div>
                )}
              </div>
              <div className="history-item-card">
                {renderItem(item)}
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onClose}>
          {Strings.closeButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default HistoryModal;

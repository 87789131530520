import React, { useState, useCallback } from 'react';
import { CheckboxInputGroup, FormInput } from '../../../forms';
import SoapSection from './soap-section';
import { buildNotes, buildSoapPlan } from '../soap-utilities';
import Strings from '../lang';

const PlanNoteInput = ({
  label = '',
  plan = {},
  onChange = () => {}
}) => {
  const onSelectPlan = useCallback(({ target }) => {
    onChange({ ...plan, selected: target.checked });
  }, [onChange, plan]);

  const onNotesChange = useCallback(({ target }) => {
    onChange({ ...plan, notes: target.value });
  }, [onChange, plan]);

  return (
    <div className="row">
      <div className="col-md-3">
        <CheckboxInputGroup
          className="form-group"
          labelText={`${label}:`}
          inputProps={{
            className: 'form-control',
            checked: plan.selected || false,
            onChange: onSelectPlan
          }}
          inputValid
        />
      </div>
      <div className="col-md-9">
        <FormInput
          className="form-group"
          type="textarea" 
          inputProps={{
            rows: 1,
            maxRows: 3,
            className: 'form-control',
            value: plan.notes || '',
            placeholder: Strings.enterNotesPlaceholderText,
            onChange: onNotesChange
          }}
        />
      </div>
    </div>
  );
};

const PlanNotesForm = ({
  notes = {},
  onSubmit = () => {}
}) => {
  const [plan, setPlan] = useState(() => buildSoapPlan(notes.p));

  const handleChange = useCallback((key, { selected, notes }) => {
    setPlan((prev) => ({
      ...prev,
      [key]: {
        selected: selected || !!notes,
        notes
      }
    }));
  }, []);

  const handleSubmit = useCallback(() => {
    const attributes = {
      notes: {
        ...buildNotes(notes),
        p: {
          ...plan
        }
      }
    };

    onSubmit(attributes);
  }, [notes, onSubmit, plan]);

  return (
    <SoapSection title={Strings.planLabel} onSubmitSection={handleSubmit}>
      <div className="soap-plan-section">
        <label className="bold">{Strings.planLabel}:</label>
        {Object.keys(plan).map((key) => (
          <PlanNoteInput 
            key={key}
            label={Strings[key]}
            plan={plan[key]}
            onChange={(value) => handleChange(key, value)}
          />
        ))}
      </div>
    </SoapSection>
  );
};

export default PlanNotesForm;

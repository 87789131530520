import React, { useCallback, useState, useMemo } from 'react';
import { FormComponent, RadioInputGroup, FormInputGroup } from '../../forms';
import { Money } from '../../utilities/currency';
import Strings from './lang';

const OptionLabel = ({
  label,
  subLabel
}) => (
  <div style={{ marginLeft: 10 }}> 
    <strong style={{ fontSize: 18 }}>
      {label}
    </strong>
    {subLabel && (
      <div><small className="caption">{subLabel}</small></div>
    )}
  </div>
);

const isAmountValid = (amount, min, max) => {
  return !isNaN(amount) 
    && parseFloat(amount) >= min 
    && parseFloat(amount) <= max;
};

const getInitialOption = (amount, min, max) => {
  if (amount === max) return 'full';
  if (amount === min) return 'minimum';
  return 'custom';
};

const SelectAmountForm = ({
  max,
  min,
  initialAmount,
  onSubmit = () => {},
  currency,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [optionValue, setOptionValue] = useState(() => getInitialOption(initialAmount, min, max));
  const [amount, setAmount] = useState(initialAmount);
  const maxFormatted = useMemo(() => Money.format(max, currency), [currency, max]);
  const minFormatted = useMemo(() => Money.format(min, currency), [currency, min]);

  const handleSubmit = useCallback(() => {
    let value = max;
    setSubmitted(true);

    if (optionValue === 'custom') {
      value = amount;
    } else if (optionValue === 'minimum') {
      value = min;
    }

    if (isAmountValid(value, min, max)) {
      onSubmit(parseFloat(value));
    }
  }, [amount, max, min, onSubmit, optionValue]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <RadioInputGroup 
        inputValid
        labelAfterInput
        touched={false}
        className="form-group"
        titleLabelText={Strings.selectPaymentOptionLabel}
        radioLabels={[
          (
            <OptionLabel  
              label={maxFormatted}
              subLabel={Strings.fullPaymentOption}
            />
          ),
          (
            <OptionLabel  
              label={minFormatted}
              subLabel={Strings.minimumPaymentOption}
            />
          ),
          (
            <OptionLabel 
              label={Strings.customPaymentOption}
              subLabel={Strings.formatString(
                Strings.customPaymentLabel,
                minFormatted,
                maxFormatted
              )}
            />
          )
        ]}
        radioValues={['full', 'minimum', 'custom']}
        initialValue={optionValue}
        inputProps={{
          className: 'form-control'
        }}
        onUpdate={(value) => setOptionValue(value)}
      />
      {optionValue === 'custom' && (
        <FormInputGroup 
          required
          touched={submitted}
          className="form-group"
          labelText={Strings.amountLabel}
          inputType="text"
          inputProps={{
            autoFocus: true,
            className: 'form-control',
            value: amount,
            onChange: (e) => setAmount(e.target.value) 
          }}
          inputValid={isAmountValid(amount, min, max)}
          messageClassName="alert alert-danger"
          messageText={Strings.formatString(
            Strings.customPaymentErrorMessage, 
            min, 
            max
          )}
        />
      )}
      <button 
        type="submit" 
        className="btn btn-primary"
      >
        {Strings.updateAmountButtonText}
      </button>
    </FormComponent>
  );
};

export default SelectAmountForm;

import React from 'react';
import { Link } from 'react-router-dom';
import AccessControl from '../access-control';
import Icon from '../icon';
import { ResourcesPaths } from '../../paths';
import { AdminRoleDescriptions, RoleDescriptions } from '../../utilities/user-roles';
import Strings from './lang';

const ResourcesHeader = ({
  searchValue,
  onSearchChange = () => {}
}) => {
  return (
    <div className="resources-header">
      <div className="resources-title">
        <h1 className="display">{Strings.resourcesTitle}</h1>
      </div>
      <div className="resources-search">
        <div className="drive-search-container">
          <Icon name="magnifying-glass" />
          <input 
            type="search" 
            value={searchValue} 
            onChange={({ target: { value } }) => {
              onSearchChange(value);
            }} 
            placeholder={Strings.searchPlaceholder}
          />
          {!!searchValue && (
            <button 
              type="button" 
              className="clear" 
              title={Strings.clearSearchTitle}
              // prevent focus from leaving focused element
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => onSearchChange('')}
            >
              <Icon name="xmark" />
            </button>
          )}
        </div>
      </div>
      <AccessControl
        roles={[
          ...AdminRoleDescriptions,
          RoleDescriptions.Clinician
        ]}
      >
        <div className="course-videos-link">
          <Link className="btn btn-sm btn-secondary" to={ResourcesPaths.courses.index.link}>
            {Strings.courseVideosLink}
          </Link>
        </div>
      </AccessControl>
    </div>      
  );
};

export default ResourcesHeader;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { FormComponent } from '../../../forms';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import Card from '../../card';
import { AddNoteButton } from '../../buttons';
import { GripStrength } from '../test-components';
import HeaderStrings from '../lang';
import Strings from './lang';

class GripStrengthCard extends Component {
  constructor(props) {
    super(props);

    const { gripStrength = {} } = props;
    const { right_hand = [], left_hand = [] } = gripStrength;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      notes: gripStrength.notes || '', 
      right_hand: [...(right_hand || [])],
      left_hand: [...(left_hand || [])],
      submitted: false
    };
  }

  render() {
    const { left_hand, right_hand, notes } = this.state;
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card 
          title={HeaderStrings.gripStrengthHeader}
          actionButtons={(
            <AddNoteButton 
              note={notes}
              title={
                Strings.formatString(Strings.abStractNotesTitle, HeaderStrings.gripStrengthHeader)
              }
              submitButtonTitle={Strings.addNoteButtonText}
              onSave={(notes) => {
                this.setState({ notes });
              }}
            />
          )}
        >
          <div className="card card-show">
            <p className="text-muted">{Strings.gripStrengthInfoText}</p>
          </div>
          <GripStrength
            initialState={{
              left_hand,
              right_hand
            }}
            onUpdate={this.onUpdate}
            touched={this.state.submitted}
          />
          <If condition={typeof this.props.onSubmit === 'function'}>
            <Then>
              <div className="card form-footer">
                <button type="submit" className="btn btn-primary">
                  {this.props.saveButtonText || Strings.saveButtonText}
                </button>
              </div>
            </Then>
          </If>
        </Card>
      </FormComponent>
    );
  }

  onUpdate(results) {
    const { left_hand, right_hand } = results;

    this.setState({
      left_hand,
      right_hand
    });
  }

  isValid() {
    const { right_hand, left_hand } = this.state;

    if (!Array.isArray(right_hand) || !Array.isArray(left_hand)) {
      return false;
    }

    for (let i = 0; i < right_hand.length; i += 1) {
      if (right_hand[i] === '' || left_hand[i] === '') {
        return false;
      }
    }

    return true;
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const { left_hand, right_hand, notes } = this.state;
    
    const attributes = {
      grip_strength_test: {
        left_hand,
        right_hand,
        notes
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }
}

GripStrengthCard.propTypes = {
  ...BaselineTestCardPropTypes,
  gripStrength: PropTypes.object
};

export default GripStrengthCard;

import React from 'react';
import dayjs from 'dayjs';
import Modal from '../../modal';
import { DISPLAY_DATE_FORMAT } from '../../../dates/formats';
import Strings from './lang';

const BaselineInProgressModal = ({
  isOpen = false,
  user = {},
  onClose,
  onStartNew,
  onContinue
}) => {
  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h2>{Strings.baselineInProgressModalTitle}</h2>
      </Modal.Header>
      <Modal.Body>
        <p>
          {
            Strings.formatString(
              Strings.baselineInProgressModalMessage, 
              dayjs(user?.baseline_date).format(DISPLAY_DATE_FORMAT)
            )
          }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={onStartNew}>
          {Strings.startNewTestButtonText}
        </button>
        <button type="button" className="btn btn-primary" onClick={onContinue}>
          {Strings.continueBaselineButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default BaselineInProgressModal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, EmailInputGroup } from '../../../forms';
import Strings from './lang';

class RegistrationConfirmRequestForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      email: '',
      emailValid: false
    };
  }

  render() {
    return (
      <FormComponent className="registration-confirm-request-form" onSubmit={this.onSubmit}>
        <EmailInputGroup
          className="form-group"
          labelText={Strings.emailLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.emailInputPlaceholder,
            value: this.state.emailValue,
            disabled: this.state.activity
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.confirmButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.emailValid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const { email } = this.state;
    const attributes = {
      email,
      confirmation_url: `${process.env.CCMI_UI_HOST}/register/confirm/`
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

RegistrationConfirmRequestForm.propTypes = {
  onSubmit: PropTypes.func
};

export default RegistrationConfirmRequestForm;

import React from 'react';
import { Link } from 'react-router-dom';
import { MarketplacePaths } from '../../../paths';
import replaceLinkParams from '../../../utilities/replace-link-params';
import Strings from '../lang';

const ProductsTable = ({ products }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th className="no-action">{Strings.nameTableColumnLabel}</th>
          <th className="no-action">{Strings.partnerTableColumnLabel}</th>
          <th className="no-action">{Strings.statusTableColumnLabel}</th>
        </tr>
      </thead>
      <tbody>
        {products.map(product => (
          <tr key={product.id}>
            <td>
              <Link
                to={
                  replaceLinkParams(
                    MarketplacePaths.products.product.index.link,
                    { productId: product.slug }
                  )
                }
              >
                {product.name}
              </Link>
            </td>
            <td>
              {product.mp_partner ? (
                <Link
                  to={
                    replaceLinkParams(
                      MarketplacePaths.partners.show.link,
                      { partnerId: product.mp_partner.slug }
                    )
                  }
                >
                  {product.mp_partner.name}
                </Link>
              ) : (
                <span>--</span>
              )}
            </td>
            <td>{product.published ? Strings.publishedLabel : Strings.notPublishedLabel}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProductsTable;

import { getLocalStorageItem, setLocalStorageItem } from '../../utilities/local-storage';

const CLINIC_USERS_TABLE_SORT_KEYPATH_KEY = 'com.completeconcussions.clinic.users.sort.key.path';

const getClinicUsersTableSortKeyPath = () => {
  return getLocalStorageItem(CLINIC_USERS_TABLE_SORT_KEYPATH_KEY) || '';
};

const setClinicUsersTableSortKeyPath = (keyPath = '') => {
  return setLocalStorageItem(CLINIC_USERS_TABLE_SORT_KEYPATH_KEY, keyPath);
};

export {
  getClinicUsersTableSortKeyPath,
  setClinicUsersTableSortKeyPath
};

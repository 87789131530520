/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import { mergeRepeatBaselines } from '../../../utilities/injury-helpers';
import VOMSTestView from '../../voms/voms-test-view';
import BuffaloTestView from '../../buffalo-test/buffalo-test-view';
import OrthostaticVitalSignsTestView from '../../ovs-tests/ovs-test-view';
import PostInjuryMedicalInfo from './post-injury-medical-info';
import PostInjuryCharacteristics from './post-injury-characteristics';
import PostInjuryRedFlags from './post-injury-red-flags';
import PostInjuryInitialSymptoms from './post-injury-initial-symptoms';
import PostInjurySymptoms from './post-injury-symptoms';
import PostInjuryNeurological from './post-injury-neurological';
import PostInjuryDiagnosis from './post-injury-diagnosis';
import PostInjuryReturn from './post-injury-return';
import PostInjuryFollowUp from './post-injury-follow-up';
import PostInjuryCommunication from './post-injury-communication';
import PostInjurySymptomExertion from './post-injury-symptom-exertion';
import PostInjuryInitialManagement from './post-injury-initial-management';
import { PostInjuryTestDisplay } from '../../baseline';

class PostInjuryAssessmentView extends Component {
  render() {
    const { 
      assessment = {},
      user = {},
      injury = {},
      baseline = {},
      type = '' 
    } = this.props;
    const {
      baselines = [],
      voms_tests = [],
      buffalo_tests = [],
      orthostatic_vital_signs_tests = []
    } = assessment;

    return (
      <div className={this.props.className}>
        <div className="row">
          <div className="col-md-12">
            <PostInjuryMedicalInfo
              user={user}
              medicalInfo={assessment.medical_info || {}}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <PostInjuryCharacteristics
              injury={injury}
              characteristics={assessment.characteristics || {}}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <PostInjuryRedFlags
              redFlags={assessment.red_flags || {}}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <PostInjuryInitialSymptoms
              symptoms={(assessment.characteristics || {}).initial_symptoms || {}}
            />
          </div>
        </div>

        <If condition={baselines.length > 0}>
          <Then>
            <div>
              <div className="row">
                <div className="col-md-12">
                  <PostInjurySymptomExertion 
                    exertion={(assessment.current_rated_symptoms || {}).worse_with_exertion} 
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <PostInjuryTestDisplay
                    user={user}
                    injury={injury}
                    test={baselines[0]}
                    baseline={baseline}
                    testNumber={this.getPostInjuryTestNumber(baselines[0])}
                    nestedDisplay
                    showHeader={false}
                    showSelector={false}
                  />
                </div>
              </div>
            </div>
          </Then>
          <Else>
            <div className="row">
              <div className="col-md-12">
                <PostInjurySymptoms
                  baseline={baseline}
                  currentSymptoms={assessment.current_rated_symptoms || {}}
                />
              </div>
              <div className="col-md-12">
                <PostInjurySymptomExertion 
                  exertion={(assessment.current_rated_symptoms || {}).worse_with_exertion} 
                />
              </div>
            </div>
          </Else>
        </If>

        <div className="row">
          <div className="col-md-12">
            <PostInjuryNeurological
              results={assessment.neurological_examination || {}}
            />
          </div>
        </div>

        <If condition={voms_tests.length > 0}>
          <Then>
            <div className="row">
              <div className="col-md-12">
                <VOMSTestView
                  showTestHeader={false}
                  showPrintButton={false}
                  user={user}
                  injury={injury}
                  currentUser={this.props.currentUser}
                  currentClinic={this.props.currentClinic}
                  test={voms_tests[0]}
                />
              </div>
            </div>
          </Then>
        </If>

        <If condition={buffalo_tests.length > 0}>
          <Then>
            <div className="row">
              <div className="col-md-12">
                <BuffaloTestView
                  showTestHeader={false}
                  showPrintButton={false}
                  user={user}
                  injury={injury}
                  currentUser={this.props.currentUser}
                  currentClinic={this.props.currentClinic}
                  test={buffalo_tests[0]}
                />
              </div>
            </div>
          </Then>
        </If>

        <If condition={orthostatic_vital_signs_tests.length > 0}>
          <Then>
            <div className="row">
              <div className="col-md-12">
                <OrthostaticVitalSignsTestView
                  showTestHeader={false}
                  showResultsCalculations={false}
                  user={user}
                  injury={injury}
                  currentUser={this.props.currentUser}
                  currentClinic={this.props.currentClinic}
                  test={orthostatic_vital_signs_tests[0]}
                />
              </div>
            </div>
          </Then>
        </If>

        <div className="row">
          <div className="col-md-12">
            <PostInjuryDiagnosis
              diagnosis={assessment.diagnosis || {}}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <PostInjuryFollowUp
              data={assessment.follow_up_action_plan || {}}
            />
          </div>
        </div>

        {type === 'chronic' && (
          <div className="row">
            <div className="col-md-12">
              <PostInjuryInitialManagement
                initialManagement={assessment.initial_management || {}}
              />
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <PostInjuryReturn
              stages={assessment.recovery_protocol_stage || {}}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <PostInjuryCommunication
              actions={assessment.actions || {}}
            />
          </div>
        </div>
      </div>
    );
  }

  getPostInjuryTestNumber(test = {}) {
    const { injury = {} } = this.props;
    const postInjuryTests = mergeRepeatBaselines(injury);

    let index = postInjuryTests.findIndex(piTest => piTest.id === test.id) + 1;

    if (index <= 0) {
      index = postInjuryTests.length + 1;
    } 

    return index;
  }
}

PostInjuryAssessmentView.propTypes = {
  user: PropTypes.object,
  injury: PropTypes.object,
  assessment: PropTypes.object,
  baseline: PropTypes.object
};

export default PostInjuryAssessmentView;

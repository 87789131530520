import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ShowPlayerMedical from '../../user-profile/show-player-medical';
import { Permissions } from '../patient-profile-utils';
import { showAlert } from '../../alert-notifications';
import Strings from './lang';
import { UserSelector } from '../../../redux/selectors';
import AccessControl from '../../access-control';

const onEditingError = () => {
  showAlert('error', {
    dismissable: true,
    autoDismiss: 3000,
    message: 'One or more fields are invalid. Please review the form.'
  });
};

const PatientMedicalInfo = () => {
  const params = useParams();
  const [editing, setEditing] = useState(false);
  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const medical = useSelector(state => state.medicalInfo[params.userId]);
  const editingRoles = useMemo(() => (
    Permissions.makePlayerMedicalEditorRoles(params.clinicId)
  ), [params.clinicId]);

  const onEditChange = useCallback(() => {
    setEditing(prev => !prev);
  }, []);

  if (!medical?.id && !editing) {
    return (
      <h2>
        {Strings.formatString(
          Strings.noMedicalHistoryInfoText,
          user?.person?.first_name || Strings.patientText
        )}&nbsp;&nbsp;
        <AccessControl roles={editingRoles}>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => setEditing(true)}
          >
            {Strings.createMedicalInfoButtonText}
          </button>
        </AccessControl>
      </h2>
    );
  }

  return (
    <ShowPlayerMedical
      editorRoles={editingRoles}
      user={user}
      medical={medical}
      editing={editing}
      onEditError={onEditingError}
      onClickEditButton={onEditChange}
      onUpdate={onEditChange}
    />
  );
};

export default PatientMedicalInfo;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then, Else } from 'react-if';
import { FormComponent, DateInputGroup, DateValidator } from '../../../../forms';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '../../../../dates/formats';
import Strings from './lang';
import DateStrings from '../../../../dates/lang';
import Icon from '../../../icon';

const getStateFromProps = (props) => {
  const { date = '', minDate, maxDate } = props;
  const dateTest = dayjs(date);
  const dateValid = DateValidator.test(dateTest, minDate, maxDate);

  return {
    date,
    dateValid
  };
};

class EditableDateLabel extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onDateUpdate = this.onDateUpdate.bind(this);

    this.state = {
      ...getStateFromProps(props),
      submitted: false
    };
  }

  componentDidMount() {
    this.setState({
      ...getStateFromProps(this.props)
    });
  }

  render() {
    const inputFormattedDate = dayjs(this.state.date).format(INPUT_DATE_FORMAT);
    const displayDate = dayjs(this.state.date).format(DISPLAY_DATE_FORMAT);

    return (
      <If condition={this.props.editing === true}>
        <Then>
          <FormComponent className={this.props.formClassName} onSubmit={this.onSubmit}>
            <DateInputGroup
              className="form-group"
              labelText={this.props.label}
              inputType="date"
              textInputProps={{
                className: 'form-control',
                placeholder: DateStrings.placeholder
              }}
              dateInputProps={{
                className: 'form-control'
              }}
              initialValue={inputFormattedDate}
              minDate={this.props.minDate}
              maxDate={this.props.maxDate}
              onUpdate={this.onDateUpdate}
              messageText={this.props.errorMessage}
              messageClassName="alert alert-danger"
              required
              touched={this.state.submitted}
            />
            <div className="form-button-group">
              <button type="submit" className="edit-button">
                <Icon name="check" />
                {Strings.saveButtonText}
              </button>
              <button type="button" className="edit-button" onClick={this.onCancel.bind(this)}>
                <Icon name="xmark" />
                {Strings.cancelButtonText}
              </button>
            </div>
          </FormComponent>
        </Then>
        <Else>
          <div className={this.props.labelClassName}>
            <label>{this.props.label}</label>
            <span>
              {displayDate}&nbsp;&nbsp;
              {this.props.editable && (
                <button type="button" className="edit-button" onClick={this.props.onEdit}>
                  <Icon name="pen-to-square" />
                  {Strings.editButtonText}
                </button>
              )}
            </span>
              
          </div>
        </Else>
      </If>
    );
  }

  onDateUpdate(date, dateValid) {
    this.setState({
      date, 
      dateValid
    });
  }

  onCancel() {
    this.setState({
      ...getStateFromProps(this.props)
    });

    this.props.onCancel();
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.state.dateValid) {
      return;
    }

    this.props.onSave(this.state.date);
  }
}

/* eslint-disable react/no-unused-prop-types */
EditableDateLabel.propTypes = {
  /* date string or dayjs object */
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /* min and max date should be dayjs objects */
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  formClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  editable: PropTypes.bool,
  editing: PropTypes.bool,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default EditableDateLabel;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Note from './note';
import { buildNotes } from '../soap-utilities';
import { FormComponent } from '../../../forms';
import Strings from '../lang';

class ObjectiveNotesForm extends Component {
  constructor(props) {
    super(props);

    const { notes = {} } = props;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateNotes = this.onUpdateNotes.bind(this);

    this.state = {
      oNotes: notes.o || ''
    };
  }

  componentDidMount() {
    const { notes = {} } = this.props;

    this.setState({
      oNotes: notes.o || ''
    });
  }

  render() {
    return (
      <div>
        {this.props.testButtons}
        <FormComponent onSubmit={this.onSubmit} className="soap-objective-form">
          <div className="row">
            <div className="col-md-12">
              <Note
                label={Strings.objectiveLabel}
                namespace="soap-objective-notes"
                note={this.state.oNotes}
                onUpdate={this.onUpdateNotes}
              />
            </div>
          </div>
          <div className="form-footer">
            <button type="submit" className="btn btn-primary">
              {Strings.saveSectionButtonText}
            </button>
          </div>
        </FormComponent>
      </div>
    );
  }

  onUpdateNotes(value) {
    this.setState({
      oNotes: value
    });
  }

  onSubmit() {
    const { oNotes } = this.state;
    const { notes = {} } = this.props;

    const attributes = {
      notes: {
        ...buildNotes(notes),
        o: oNotes
      }
    };

    this.props.onSubmit(attributes);
  }
}

ObjectiveNotesForm.propTypes = {
  notes: PropTypes.object,
  onSubmit: PropTypes.func
};

export default ObjectiveNotesForm;

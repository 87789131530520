import React, { useCallback, useMemo, useState } from 'react';
import { 
  FormComponent, 
  UrlInputGroup, 
  SelectInputGroup, 
  UrlValidator 
} from '../../forms';
import Strings from './lang';

const BookingLinkForm = ({
  clinics = [],
  clinic = {},
  url: propUrl = '',
  cancelable = true,
  onCancel = () => {},
  onSubmit = () => {}
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [clinicId, setClinicId] = useState(clinic.id || '');
  const [url, setUrl] = useState(propUrl);
  const isValid = useMemo(() => !!clinicId && UrlValidator.test(url), [clinicId, url]);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (isValid) {
      onSubmit({ clinicId, url });
    }
  }, [clinicId, isValid, onSubmit, url]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      {clinic.id ? (
        <h3>{clinic.name}</h3>
      ) : (
        <SelectInputGroup 
          required
          className="form-group"
          labelText={Strings.clinicInputText}
          inputProps={{
            className: 'form-control',
            value: clinicId
          }}
          messageClassName="alert alert-danger"
          messageText={Strings.clinicInputErrorText}
          valid={!!clinicId}
          touched={submitted}
          onUpdate={(value) => setClinicId(value)}
        >
          <option value="">-- {Strings.selectClinicOption} --</option>
          {clinics.map(clinic => (
            <option key={clinic.id} value={clinic.id}>{clinic.name}</option>
          ))}
        </SelectInputGroup>
      )}
      
      <UrlInputGroup 
        required
        className="form-group clinic-url-input-group"
        labelText={Strings.urlInputLabel}
        inputProps={{
          className: 'form-control',
          value: url
        }}
        onUpdate={(clinicUrl) => setUrl(clinicUrl)}
        messageClassName="alert alert-danger"
        touched={submitted}
      />
      <div className="form-footer">
        <button 
          type="button" 
          disabled={!cancelable}
          className="btn btn-default" 
          onClick={() => onCancel()}
        >
          {Strings.cancelButtonText}
        </button>
        <button 
          type="submit" 
          className="btn btn-primary" 
          disabled={!isValid || !cancelable}
        >
          {Strings.submitButtonText}
        </button>
      </div>
    </FormComponent>
  );
};

export default BookingLinkForm;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Page } from '../page';
import { MobileLinks } from '../../utilities/mobile-detector';
import { AppLinks, MobileDetector } from '../mobile';
import Strings from './lang';
import Icon from '../icon';

const OPEN_TIMEOUT = 1000;

const { iOS: IOSLink, Android: AndroidLink } = AppLinks;

const onOpenApp = (platform) => {
  const mobile = MobileLinks[platform];
  // If the user doesn't have the app, the timeout will fire
  // and direct user to the App store or play store.
  setTimeout(() => { window.location = mobile.link; }, OPEN_TIMEOUT);
  window.location = mobile.deep;
};

const MobileApp = () => {
  const location = useLocation();
  const { passwordReset } = location?.state || {};

  return (
    <Page className="untrained" hideNavigationContent>

      {passwordReset && (
        <p className="alert alert-success text-center" style={{ marginTop: '15px' }}>
          <Icon name="circle-check" />&nbsp; 
          <strong>
            {Strings.passwordResetMessage}
          </strong>
        </p>
      )}

      <div className="row">
        <div className="col-md-12">
          <h1 className="display">{Strings.title}</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <MobileDetector>
            {(isMobile, platform) => {
              if (isMobile) {
                return (
                  <button 
                    type="button" 
                    className="btn btn-primary" 
                    onClick={() => onOpenApp(platform)}
                  >
                    {Strings.appLinkText}
                  </button>
                );
              }

              return (
                <div className="app-download-links">
                  <IOSLink />
                  <AndroidLink />
                </div>
              );
            }}
          </MobileDetector>
        </div>
      </div>

    </Page>
  );
};

export default MobileApp;

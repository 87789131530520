import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClinicStats } from '../clinic-stats';
import { ClinicSelector } from '../../redux/selectors';
import { getClinicStatisticsAsync } from '../../redux/thunks/clinic-statistics';
import { useMount } from '../../hooks';
import { ErrorBanner } from '../errors';
import Activity from '../activity';

const ClinicStatistics = ({ clinicId }) => {
  const dispatch = useDispatch();
  const stats = useSelector(state => ClinicSelector.getStatistics(state, { id: clinicId }));
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(!stats);

  const loadStats = useCallback(() => {
    setError(null);
    setLoading(true);

    dispatch(getClinicStatisticsAsync(clinicId)).then(() => {
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error.message);
    });
  }, [dispatch, clinicId]);

  useMount(() => {
    if (!stats) {
      loadStats();
    }
  });

  if (error) {
    return (
      <ErrorBanner error={error} />
    );
  }

  return (
    <Activity active={loading} static>
      <ClinicStats stats={stats || {}} />
    </Activity>
  );
};

export default ClinicStatistics;

import React from 'react';
import { Link } from 'react-router-dom';
import { DashboardPaths } from '../../paths';
import Strings from './lang';

const ErrorPage = ({
  title = Strings.genericTitle,
  message = Strings.genericMessage
}) => (
  <div className="error">
    <div className="row">
      <div className="col-md-12">
        <h1 className="display">
          {title}
        </h1>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <p className="alert alert-danger">
          {message}
        </p>
        <ul className="list-unstyled">
          <li>
            <Link to={DashboardPaths.index.link}>
              {Strings.homeLinkText}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

const Error404Page = () => (
  <ErrorPage
    title={Strings.e404Title}
    message={Strings.e404Message}
  />
);

export {
  ErrorPage,
  Error404Page
};

import dayjs from 'dayjs';
import checkExpiry from './check-expiry';

const USER_TRAINING_EXPIRY_DURATION_VALUE = 2;
const USER_TRAINING_EXPIRY_DURATION_UNIT = 'year';
const IGNORE_TRAINING = process.env.CCMI_IGNORE_TRAINING === 'true';

// NOTE: this function returns false if training is expired
const checkUserTrainingExpiry = (user) => {
  return IGNORE_TRAINING || ((user.trained_at != null) && checkExpiry(
    user.trained_at,
    USER_TRAINING_EXPIRY_DURATION_VALUE,
    USER_TRAINING_EXPIRY_DURATION_UNIT
  ));
};

const convertToTrainingExpiryDate = (trainingDate) => {
  return dayjs(trainingDate).add(
    USER_TRAINING_EXPIRY_DURATION_VALUE, 
    USER_TRAINING_EXPIRY_DURATION_UNIT
  );
};

export default checkUserTrainingExpiry;
export {
  convertToTrainingExpiryDate
};

import React, { useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import Fraction from '../fraction';
import {
  OrderedSymptomKeys,
  calculateSymptomsTotals,
  MAX_SYMPTOM_SEVERITY
} from '../../symptom-data';
import { symptomLabels } from '../symptoms';
import Strings from './lang';

const SymptomsDisplay = ({
  symptoms = {}
}) => {
  const hasSymptoms = useMemo(() => Object.keys(symptoms || {}).length > 0, [symptoms]);
  const {
    count,
    score,
    symptoms: total
  } = useMemo(() => calculateSymptomsTotals(symptoms || {}), [symptoms]);

  return (
    <div className="symptom-scores">
      <If condition={hasSymptoms}>
        <Then>
          <>
            <div className="row">
              {OrderedSymptomKeys.map((key, index) => {
                return (
                  <div key={index} className="col-md-4">
                    <div className="symptom-score-row">
                      {symptomLabels[key]}:
                      <Fraction
                        className="symptom-score-display"
                        numerator={symptoms[key]}
                        denominator={MAX_SYMPTOM_SEVERITY}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="score-view">
              <label>{Strings.symptomsSeverityText}:</label>
              <span className="score-view-score-display">{score}</span>
              <small>/{MAX_SYMPTOM_SEVERITY * total}</small>
            </div>
            <div className="score-view">
              <label>{Strings.symptomsTotalText}: </label>
              <span className="score-view-score-display">{count}</span>
              <small>/{total}</small>
            </div>
          </>
        </Then>
        <Else>
          <div className="text-muted">
            {Strings.noSymptomsRecordedText}
          </div>
        </Else>
      </If>

    </div>
  );
};

export default SymptomsDisplay;

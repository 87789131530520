import React, {
  useEffect,
  useMemo,
  useState,
  useCallback
} from 'react';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';
import { ErrorBanner } from '../../errors';
import { Page } from '../../page';
import ProductsEmptyPage from './products-empty-page';
import ProductFilters from './product-filters';
import ProductsList from './products-list';
import ProductsListHeader from './products-list-header';
import { getQuery, formatParams, formatQuery } from './utils';
import { filterParams } from '../utils';
import { getProductsAsync } from '../../../redux/thunks/products';
import Activity from '../../activity';
import { jsonApiRead } from '../../../requests/jsonapi';
import Strings from '../lang';

const getProductFiltersAsync = () => {
  return jsonApiRead(
    { type: 'products' },
    { path: 'marketplace/products/filters', deserializeResponse: false }
  );
};

const MarketplaceProducts = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const query = useMemo(() => getQuery(Object.fromEntries(searchParams)), [searchParams]);
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);

  const onFilterChange = useCallback((filters) => {
    const newParams = { ...query, page: 1, ...filters };

    if (filters.category && filters.category !== query.category) {
      newParams.brands = [];
    }

    const newQuery = filterParams(formatQuery(newParams));

    setSearchParams(newQuery);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [query, setSearchParams]);

  const onPageChange = useCallback(({ selected }) => {
    const newPage = selected + 1;
    if (newPage !== query.page) {
      onFilterChange({ page: newPage });
    }
  }, [onFilterChange, query.page]);

  const getProducts = useCallback((params) => {
    setLoading(true);

    dispatch(getProductsAsync(params)).then(({ products, pagination }) => {
      setProducts(products);
      setLoaded(true);
      setPagination(pagination);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch]);

  const getProductFilters = useCallback(() => {
    getProductFiltersAsync().then(filters => {
      setFilters(filters);
    });
  }, []);

  useEffect(() => {
    getProducts(formatParams(query));
  }, [getProducts, query]);

  useEffect(() => {
    getProductFilters();
  }, [getProductFilters]);

  if (!query.category && loaded && !products?.length && !error) {
    return (
      <ProductsEmptyPage />
    );
  }

  return (
    <Page container="fluid" className="marketplace">
      <Activity active={!loaded} static>
        <h1>{Strings.marketplaceTitle}</h1>
        <ErrorBanner error={error} />
        <div className="marketplace-products-container">
          <div className="product-filter-sidebar">
            <ProductFilters
              category={query.category}
              brands={query.brands}
              filters={filters}
              onChange={onFilterChange}
            />
          </div>
          <div className="products-container">
            <ProductsListHeader
              category={query.category || null}
              brands={query.brands}
              filters={filters}
              productCount={pagination.total_count}
              onFilterChange={onFilterChange}
            />
            <Activity active={loading}>
              <ProductsList
                products={products}
                loading={loading}
              />
              {pagination.total_pages > 1 && (
                <div className="pagination-center">
                  <ReactPaginate
                    pageCount={pagination.total_pages}
                    forcePage={pagination.page - 1}
                    pageRangeDisplayed={4}
                    marginPagesDisplayed={2}
                    containerClassName="pagination"
                    activeClassName="active"
                    onPageChange={onPageChange}
                  />
                </div>
              )}
            </Activity>
          </div>
        </div>
      </Activity>
    </Page>
  );
};

export default MarketplaceProducts;

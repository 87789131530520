import { jsonApiRead } from '../../requests/jsonapi';
import { addClinicIdtClaims } from '../actions/clinic-idt-claims';

const getClinicIdtClaimsAsync = (clinicId, status) => {
  const options = { path: `clinics/${clinicId}/idt_claims`, queryParams: { status } };
  return (dispatch) => {
    return jsonApiRead({ type: 'idt_claims' }, options).then(results => {
      dispatch(addClinicIdtClaims(clinicId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicIdtClaimsAsync
};

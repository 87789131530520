import { RegistrationPaths } from '../../../paths';

const PlayerRegistrationRoutes = [{
  step: 'account',
  path: RegistrationPaths.player.index.link
}, {
  step: 'about',
  path: RegistrationPaths.player.about.link
}, {
  step: 'contact',
  path: RegistrationPaths.player.contact.link
}, {
  step: 'physician',
  path: RegistrationPaths.player.physician.link
}];

const GuardianRegistrationRoutes = [{
  step: 'account',
  path: RegistrationPaths.guardian.index.link
}, {
  step: 'about',
  path: RegistrationPaths.guardian.about.link
}, {
  step: 'children',
  path: RegistrationPaths.guardian.children.link
}];

const ChildRegistrationRoutes = [{
  step: 'about',
  path: RegistrationPaths.guardian.child.index.link
}, {
  step: 'contact',
  path: RegistrationPaths.guardian.child.contact.link
}, {
  step: 'physician',
  path: RegistrationPaths.guardian.child.physician.link
}];

const RegistrationSteps = {
  player: PlayerRegistrationRoutes,
  guardian: GuardianRegistrationRoutes,
  child: ChildRegistrationRoutes
};

const findCurrentStepIndex = (steps, currentStep) => {
  return steps.findIndex(step => step.step === currentStep);
};

export {
  RegistrationSteps,
  findCurrentStepIndex
};

import React from 'react';
import { Link } from 'react-router-dom';
import AccessControl from '../access-control';
import { RoleDescriptions } from '../../utilities/user-roles';
import Strings from './lang';

const MedicalTestEditAccessControl = ({ children }) => (
  <AccessControl roles={[RoleDescriptions.Clinician]}>
    {children}
  </AccessControl>
);

const MedicalTestEditButton = ({
  className,
  onClick
}) => (
  <MedicalTestEditAccessControl>
    <button type="button" className={className} onClick={onClick}>
      {Strings.editText}
    </button>
  </MedicalTestEditAccessControl>
);

const MedicalTestEditLink = ({
  to,
  className,
  onClick
}) => (
  <MedicalTestEditAccessControl>
    <Link to={to} className={className} onClick={onClick}>
      {Strings.editText}
    </Link>
  </MedicalTestEditAccessControl>
);

export {
  MedicalTestEditAccessControl,
  MedicalTestEditButton,
  MedicalTestEditLink
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { If, Then } from 'react-if';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../dates/formats';
import Strings from './lang';
import MedicalList from './medical-list';
import RemovedItemsTable from './removed-items-table';
import { splitMedicalList, joinMedicalList } from './medical-info-utilities';
import {
  FormComponent,
  FormInputGroup,
  RadioInputGroup,
  CheckboxInputGroup,
  SelectInputGroup,
  DateInputGroup
} from '../../forms';

class PlayerMedicalInfoForm extends Component {
  constructor(props) {
    super(props);

    const { user = {}, medicalInfo = {} } = props;

    const {
      medications = [],
      medical_conditions = [],
      concussion_ever_diagnosed = '',
      concussion_history = {},
      medical_history = {}
    } = medicalInfo;

    const otherCondition = medical_history.other_mental_health_diagnosed 
    && !!medical_history.other_mental_health_diagnosed.length;

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      user,
      medications: { ...splitMedicalList(medications) },
      medicalConditions: { ...splitMedicalList(medical_conditions) },
      concussionDiagnosed: String(concussion_ever_diagnosed),
      concussionCount: concussion_history.how_many || '',
      concussionDate: concussion_history.most_recent || '',
      concussionRecoveryDuration: concussion_history.recovery_time_count || '',
      concussionRecoveryDurationUnit: concussion_history.recovery_time_units || '',
      concussionSymptoms: concussion_history.still_have_symptoms || '',
      concussionSymptomsStrength: concussion_history.subsequent_concussions_easier || '',
      concussionNotes: concussion_history.notes || '',
      behaviouralDisorder: medical_history.behavioural_disorder || '',
      learningDisability: medical_history.learning_disability || '',
      headacheHx: medical_history.headache_history || '',
      headacheNotes: medical_history.headache_notes || '',
      anxiety: medical_history.anxiety_diagnosed || false,
      depression: medical_history.depression_diagnosed || false,
      sleepDisorder: medical_history.sleep_disorder_diagnosed || false,
      otherCondition,
      otherConditionNotes: medical_history.other_mental_health_diagnosed || ''
    };
  }

  componentDidMount() {
    this._callOnUpdate();
  }

  render() {
    const interpretedConcussion = dayjs(
      this.state.concussionDate, 
      INPUT_DATE_FORMAT
    ).format(DISPLAY_DATE_FORMAT);

    return (
      <FormComponent
        className={classNames('medical-info-form', 'player', this.props.className)}
        onSubmit={this.onSubmit}
      >
        <h2>{Strings.concussionHistoryHeadingText}</h2>

        <RadioInputGroup
          className="form-group concussion-diagnosed-input-group"
          titleLabelText={
            <span>{Strings.concussionDiagnosedLabelText} <span className="required">*</span></span>
          }
          radioLabels={[Strings.radioOptionNo, Strings.radioOptionYes]}
          radioValues={['false', 'true']}
          initialValue={this.state.concussionDiagnosed}
          inputProps={{
            className: 'form-control',
            name: 'concussion-diagnosed'
          }}
          required
          touched={this.state.submitted}
          inputValid={this.state.concussionDiagnosed.length > 0}
          messageClassName="alert alert-danger"
          messageText={Strings.yesNoOptionErrorMessage}
          onUpdate={(value) => this.onUpdateValue('concussionDiagnosed', value)}
        />

        <If condition={this.state.concussionDiagnosed === 'true'}>
          <Then>
            <div className="concussion-diagnosed-inputs">

              <FormInputGroup
                className="form-group concussion-count-input-group"
                labelText={Strings.concussionCountLabelText}
                inputType="number"
                messageText={Strings.concussionCountErrorText}
                inputProps={{
                  className: 'form-control',
                  min: 1,
                  required: true,
                  value: this.state.concussionCount,
                  onBlur: () => this.onUpdateInput.bind(this, 'concussionCount'),
                  onChange: this.onUpdateInput.bind(this, 'concussionCount')
                }}
                required
                messageClassName="alert alert-danger"
                inputValid={this.isValid('concussion')}
              />

              <DateInputGroup
                className="form-group concussion-date-input-group"
                labelText={Strings.concussionDateLabelText}
                inputType="date"
                textInputProps={{
                  className: 'form-control',
                  placeholder: 'January 1, 2000'
                }}
                dateInputProps={{
                  className: 'form-control'
                }}
                required
                initialValue={this.state.concussionDate}
                maxDate={dayjs()}
                messageText={Strings.concussionDateErrorText}
                onUpdate={(value) => this.onUpdateValue('concussionDate', value)}
                touched={this.state.submitted}
                messageClassName="alert alert-danger"
              />

              <If condition={dayjs(this.state.concussionDate).isValid()}>
                <Then>
                  <p className="alert alert-info">
                    {`${interpretedConcussion}`}
                  </p>
                </Then>
              </If>

              <FormInputGroup
                className="form-group concussion-count-input-group"
                labelText={Strings.concussionRecoveryLabelText}
                inputType="number"
                messageText={Strings.concussionRecoveryErrorText}
                inputProps={{
                  className: 'form-control',
                  min: 1,
                  required: true,
                  value: this.state.concussionRecoveryDuration,
                  onBlur: this.onUpdateInput.bind(this, 'concussionRecoveryDuration'),
                  onChange: this.onUpdateInput.bind(this, 'concussionRecoveryDuration')
                }}
                required
                messageClassName="alert alert-danger"
                inputValid={this.isValid('recoverCount')}
              />

              <SelectInputGroup
                className="form-group concussion-disorder-input-group"
                noLabel
                inputProps={{
                  className: 'form-control',
                  value: this.state.concussionRecoveryDurationUnit
                }}
                required
                onUpdate={(value) => this.onUpdateValue('concussionRecoveryDurationUnit', value)}
                messageClassName="alert alert-danger"
                messageText={Strings.selectOptionErrorMessage}
                inputValid={this.state.concussionRecoveryDurationUnit.length > 0}
                touched={this.state.submitted}
              >
                <option value="">{Strings.selectOneOptionText}</option>
                <option value="days">{Strings.durationOptionDays}</option>
                <option value="weeks">{Strings.durationOptionWeeks}</option>
                <option value="months">{Strings.durationOptionMonths}</option>
                <option value="years">{Strings.durationOptionYears}</option>
              </SelectInputGroup>

              <RadioInputGroup
                className="form-group concussion-symptoms-input-group"
                titleLabelText={
                  <span>{Strings.concussionSymptomsLabelText} <span className="required">*</span></span>
                }
                radioLabels={[Strings.radioOptionNo, Strings.radioOptionYes]}
                radioValues={['no', 'yes']}
                initialValue={this.state.concussionSymptoms}
                inputProps={{
                  className: 'form-control',
                  name: 'concussion-symptoms'
                }}
                inputValid={this.state.concussionSymptoms.length > 0}
                touched={this.state.submitted}
                messageClassName="alert alert-danger"
                messageText={Strings.yesNoOptionErrorMessage}
                onUpdate={(value) => this.onUpdateValue('concussionSymptoms', value)}
              />

              <RadioInputGroup
                className="form-group concussion-symptoms-strength-input-group"
                titleLabelText={
                  <span>{Strings.concussionSymptomsStrengthLabelText} <span className="required">*</span></span>
                }
                radioLabels={[Strings.radioOptionNo, Strings.radioOptionYes, Strings.radioOptionNA]}
                radioValues={['no', 'yes', 'n/a']}
                initialValue={this.state.concussionSymptomsStrength}
                inputProps={{
                  className: 'form-control',
                  name: 'concussion-symptoms-strength'
                }}
                touched={this.state.submitted}
                inputValid={this.state.concussionSymptomsStrength.length > 0}
                messageClassName="alert alert-danger"
                messageText={Strings.yesNoNaOptionErrorMessage}
                onUpdate={(value) => this.onUpdateValue('concussionSymptomsStrength', value)}
              />

              <FormInputGroup
                className="form-group concussion-notes-input-group"
                labelText={Strings.concussionNotesLabelText}
                inputType="textarea"
                inputProps={{
                  className: 'form-control',
                  value: this.state.concussionNotes,
                  onBlur: this.onUpdateInput.bind(this, 'concussionNotes'),
                  onChange: this.onUpdateInput.bind(this, 'concussionNotes')
                }}
                inputValid
              />

            </div>
          </Then>
        </If>

        <h2>{Strings.medicalPsychHistoryHeadingText}</h2>

        <h3>{Strings.medicalConditionsLabelText}</h3>
        <MedicalList
          label={Strings.currentMedicalConditionsListHeading}
          nameLabel={Strings.conditionsTableHeadingText}
          currentUser={this.props.currentUser}
          currentClinic={this.props.currentClinic}
          items={this.state.medicalConditions.current}
          placeholder={Strings.medicalConditionsInputPlaceholder}
          onChange={(list, removedItem) => this.onUpdateList('medicalConditions', list, removedItem)}
        />

        <RemovedItemsTable
          label={Strings.previousMedicalConditionsTableHeading}
          nameLabel={Strings.conditionsTableHeadingText}
          items={this.state.medicalConditions.removed}
          onUndo={(index) => this.undoRemoval('medicalConditions', index)}
        />

        <h3>{Strings.medicationsLabelText}</h3>
        <MedicalList
          label={Strings.currentMedicationsListHeading}
          nameLabel={Strings.medicationsLabelText}
          currentUser={this.props.currentUser}
          currentClinic={this.props.currentClinic}
          items={this.state.medications.current}
          placeholder={Strings.medicationsInputPlaceholder}
          onChange={(list, removedItem) => this.onUpdateList('medications', list, removedItem)}
        />

        <RemovedItemsTable
          label={Strings.previousMedicationsTableHeading}
          nameLabel={Strings.medicationsLabelText}
          items={this.state.medications.removed}
          onUndo={(index) => this.undoRemoval('medications', index)}
        />

        <SelectInputGroup
          className="form-group behavioural-disorder-input-group"
          labelText={Strings.behaviouralDisorderLabelText}
          inputProps={{
            className: 'form-control',
            value: this.state.behaviouralDisorder
          }}
          required
          touched={this.state.submitted}
          inputValid={this.state.behaviouralDisorder.length > 0}
          messageClassName="alert alert-danger"
          messageText={Strings.selectOptionErrorMessage}
          onUpdate={(value) => this.onUpdateValue('behaviouralDisorder', value)}
        >
          <option value="">{Strings.selectOneOptionText}</option>
          <option value="none">{Strings.noneText}</option>
          <option value="add">{Strings.behaviouralDisorderOptionADD}</option>
          <option value="adhd">{Strings.behaviouralDisorderOptionADHD}</option>
        </SelectInputGroup>

        <RadioInputGroup
          className="form-group learning-disability-input-group"
          titleLabelText={
            <span>{Strings.learningDisabilityLabelText} <span className="required">*</span></span>
          }
          radioLabels={[Strings.radioOptionNo, Strings.radioOptionYes]}
          radioValues={['no', 'yes']}
          initialValue={this.state.learningDisability}
          inputProps={{
            className: 'form-control',
            name: 'learning-disability'
          }}
          inputValid={this.state.learningDisability.length > 0}
          touched={this.state.submitted}
          messageClassName="alert alert-danger"
          messageText={Strings.yesNoOptionErrorMessage}
          onUpdate={(value) => this.onUpdateValue('learningDisability', value)}
        />

        <RadioInputGroup
          className="form-group headache-hx-input-group"
          titleLabelText={
            <span>{Strings.headacheHistoryLabelText} <span className="required">*</span></span>
          }
          radioLabels={[Strings.radioOptionNo, Strings.radioOptionYes]}
          radioValues={['no', 'yes']}
          initialValue={this.state.headacheHx}
          inputProps={{
            className: 'form-control',
            name: 'headache-hx'
          }}
          inputValid={this.state.headacheHx.length > 0}
          touched={this.state.submitted}
          messageClassName="alert alert-danger"
          messageText={Strings.yesNoOptionErrorMessage}
          onUpdate={(value) => this.onUpdateValue('headacheHx', value)}
        />

        <FormInputGroup
          className="form-group headache-notes-input-group"
          labelText={Strings.headacheNotesLabelText}
          inputType="textarea"
          inputProps={{
            className: 'form-control',
            value: this.state.headacheNotes,
            onBlur: this.onUpdateInput.bind(this, 'headacheNotes'),
            onChange: this.onUpdateInput.bind(this, 'headacheNotes')
          }}
          inputValid
        />
        
        <div className="checkbox-group-container">
          <label>{Strings.psychiatricHxLabelText}</label>

          <CheckboxInputGroup
            className="form-group anxiety-input-group"
            labelText={Strings.anxietyLabelText}
            inputProps={{
              className: 'form-control',
              checked: this.state.anxiety,
              onBlur: this.onUpdateCheckbox.bind(this, 'anxiety'),
              onChange: this.onUpdateCheckbox.bind(this, 'anxiety')
            }}
            inputValid
          />

          <CheckboxInputGroup
            className="form-group depression-input-group"
            labelText={Strings.depressionLabelText}
            inputProps={{
              className: 'form-control',
              checked: this.state.depression,
              onBlur: this.onUpdateCheckbox.bind(this, 'depression'),
              onChange: this.onUpdateCheckbox.bind(this, 'depression')
            }}
            inputValid
          />

          <CheckboxInputGroup
            className="form-group sleep-disorder-input-group"
            labelText={Strings.sleepDisorderLabelText}
            inputProps={{
              className: 'form-control',
              checked: this.state.sleepDisorder,
              onBlur: this.onUpdateCheckbox.bind(this, 'sleepDisorder'),
              onChange: this.onUpdateCheckbox.bind(this, 'sleepDisorder')
            }}
            inputValid
          />

          <CheckboxInputGroup
            className="form-group other-condition-input-group"
            labelText={Strings.otherConditionLabelText}
            inputProps={{
              className: 'form-control',
              checked: this.state.otherCondition,
              onBlur: this.onUpdateCheckbox.bind(this, 'otherCondition'),
              onChange: this.onUpdateCheckbox.bind(this, 'otherCondition')
            }}
            inputValid
          />
        </div>

        <If condition={!!this.state.otherCondition}>
          <Then>
            <FormInputGroup
              className="form-group other-condition-notes-input-group"
              labelText={Strings.otherConditionNotesLabelText}
              inputType="text"
              inputProps={{
                className: 'form-control',
                value: this.state.otherConditionNotes,
                onBlur: this.onUpdateInput.bind(this, 'otherConditionNotes'),
                onChange: this.onUpdateInput.bind(this, 'otherConditionNotes')
              }}
              inputValid={!this.state.otherCondition || this.state.otherConditionNotes.length > 0}
              messageText={Strings.otherConditionNotesErrorText}
              messageClassName="alert alert-danger"
              required
              touched={this.state.submitted}
            />
          </Then>
        </If>

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {this.props.submitButtonText || Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid(section = '') {
    if (section === 'concussion') {
      return this.state.concussionCount > 0;
    }
    
    if (section === 'recoverCount') {
      return this.state.concussionRecoveryDuration > 0;
    }

    if (this.state.otherCondition && this.state.otherConditionNotes.length === 0) {
      return false;
    }

    if (this.state.concussionDiagnosed === 'true' 
      && (this.state.concussionCount <= 0 
        || !dayjs(this.state.concussionDate).isValid() 
        || this.state.concussionSymptoms.length === 0 
        || this.state.concussionSymptomsStrength.length === 0 
        || this.state.concussionRecoveryDuration.length === 0 
        || this.state.concussionRecoveryDurationUnit.length === 0)
    ) {
      return false;
    }

    return (this.state.concussionDiagnosed.length !== 0) 
      && (this.state.headacheHx.length !== 0)          
      && (this.state.learningDisability.length !== 0)  
      && (this.state.behaviouralDisorder.length !== 0);
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    }, this._callOnUpdate.bind(this));
  }

  onUpdateValue(key, value) {
    this.setState({
      [key]: value
    }, this._callOnUpdate.bind(this));
  }

  onUpdateCheckbox(key, e) {
    const { checked } = e.target;
    this.setState({
      [key]: checked
    }, this._callOnUpdate.bind(this));
  }

  onUpdateList(key, list, removedItem) {
    const removed = { ...removedItem };
    const { [key]: stateItem } = this.state;

    if (removed && removed.id) {
      removed.removed_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
      removed.removal_clinician = this.props.currentUser;
      removed.removal_clinic = this.props.currentClinic;
      removed.new = true;
      stateItem.removed.push(removed);
    }

    this.setState({
      [key]: {
        current: list || [],
        removed: stateItem.removed
      }
    }, this._callOnUpdate.bind(this));
  }

  undoRemoval(key, index) {
    const { [key]: stateItem } = this.state;
    const { removed, current } = stateItem;
    const item = removed.splice(index, 1)[0];

    delete item.new;
    item.removed_at = null;
    item.removal_clinician = null;
    item.removal_clinic = null;
    current.push(item);

    this.setState({
      [key]: {
        current,
        removed
      }
    });
  }

  getAttributes() {
    const {
      user,
      medications,
      medicalConditions,
      concussionDiagnosed,
      concussionCount,
      concussionDate,
      concussionRecoveryDuration,
      concussionRecoveryDurationUnit,
      concussionSymptoms,
      concussionSymptomsStrength,
      concussionNotes,
      behaviouralDisorder,
      learningDisability,
      headacheHx,
      headacheNotes,
      anxiety,
      depression,
      sleepDisorder,
      otherCondition,
      otherConditionNotes
    } = this.state;

    return {
      user_id: user.id,
      concussion_ever_diagnosed: concussionDiagnosed,
      medications_attributes: joinMedicalList(medications),
      medical_conditions_attributes: joinMedicalList(medicalConditions),
      concussion_history: {
        how_many: (concussionDiagnosed === 'true') ? concussionCount : 0,
        most_recent: concussionDate,
        recovery_time_count: concussionRecoveryDuration,
        recovery_time_units: concussionRecoveryDurationUnit,
        still_have_symptoms: concussionSymptoms,
        subsequent_concussions_easier: concussionSymptomsStrength,
        notes: concussionNotes
      },
      medical_history: {
        behavioural_disorder: behaviouralDisorder,
        learning_disability: learningDisability,
        headache_history: headacheHx,
        headache_notes: headacheNotes,
        anxiety_diagnosed: anxiety,
        depression_diagnosed: depression,
        sleep_disorder_diagnosed: sleepDisorder,
        other_mental_health_diagnosed: (otherCondition) ? otherConditionNotes : ''
      }
    };
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      this.onError();
      return;
    }

    const attributes = {
      ...this.getAttributes()
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }

  _callOnUpdate() {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this.getAttributes(), this.isValid());
    }
  }

  onError() {
    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }
  }
}

PlayerMedicalInfoForm.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  currentClinic: PropTypes.object,
  medicalInfo: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node 
  ])
};

export default PlayerMedicalInfoForm;

import React, { useState, useCallback, useMemo } from 'react';
import Modal from '../modal';
import { FormComponent, PasswordInputGroup } from '../../forms';
import Strings from './lang';
import Activity from '../activity';
import Icon from '../icon';
import { ErrorBanner } from '../errors';

const BrandIcon = () => {
  return (
    <div className="logo">
      <img alt="CCMI Logo" src="/images/logo/color.svg" />
    </div>
  );
};

const AuthForm = ({ onSubmit = () => { } }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(() => {
    if (password.length > 0) {
      onSubmit(password);
      setPassword('');
    }
  }, [onSubmit, password]);

  return (
    <FormComponent className="auth-form" onSubmit={handleSubmit}>
      <div className="input-group">
        <PasswordInputGroup
          required={false}
          className="form-group"
          labelText={null}
          inputProps={{
            autoFocus: true,
            className: 'form-control',
            placeholder: Strings.passwordPlaceholder,
            value: password
          }}
          messageText={null}
          onUpdate={(password) => setPassword(password)}
          touched={false}
        />
        <div className="input-group-btn">
          <button
            type="submit"
            disabled={!password}
            className="btn btn-primary"
          >
            <Icon name="lock-open" fixedWidth />
          </button>
        </div>
      </div>
    </FormComponent>
  );
};

const Message = ({ error, manual }) => {
  if (error) {
    return (
      <ErrorBanner error={error} />
    );
  }

  return (
    <p className="alert alert-warning">
      {manual ? Strings.manualLockMessage : Strings.timeoutLockMessage}
    </p>
  );
};

const LockoutModal = ({
  isOpen = false,
  user = {},
  error = null,
  manual = false,
  activity = false,
  onSwitchAccounts,
  onSubmit = () => { }
}) => {
  const { email, person = {} } = user;
  const { photo_url, first_name = '', last_name = '' } = person || {};
  const name = useMemo(() => [first_name, last_name].filter(Boolean).join(' '), [first_name, last_name]);

  const handleSubmit = useCallback((password) => {
    onSubmit({ email, password, portal: 'clinician' });
  }, [email, onSubmit]);

  return (
    <Modal
      static
      blur
      fullScreen
      isOpen={isOpen}
      className="lock-screen"
    >
      <Modal.Body>
        <BrandIcon />
        <div className="lock-content">
          <div className="user-avatar">
            <img alt={`${name} avatar`} src={photo_url} />
            <div className="icon">
              <Icon name="lock" fixedWidth />
            </div>
          </div>
          <div className="user-details">
            <h1 className="text-center">{name}</h1>
            <p className="text-center text-muted">{email}</p>
          </div>
          <Message error={error} manual={manual} />
          <Activity active={activity}>
            <AuthForm onSubmit={handleSubmit} />
          </Activity>
          <div className="text-center">
            <i className="text-muted">
              {Strings.formatString(Strings.notUserText, first_name)}
            </i>&nbsp;&nbsp;
            <button
              type="button"
              className="edit-button"
              onClick={onSwitchAccounts}
            >
              {Strings.switchAccountsText}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LockoutModal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckboxInputGroup, FormInputMessage } from '../../../../forms';
import defaultRedFlags from './default-red-flags';
import AssessmentSectionForm from '../assessment-section-form';
import Strings from './lang';

class RedFlagsForm extends Component {
  constructor(props) {
    super(props);

    const { redFlags = {} } = props;

    this.onSubmit = this.onSubmit.bind(this);
    this._callOnUpdate = this._callOnUpdate.bind(this);

    this.state = {
      red_flags: { ...defaultRedFlags, ...redFlags },
      submitted: false
    };
  }

  render() {
    const { red_flags } = this.state;
    const submitted = (this.state.submitted || this.props.submitted);
    return (
      <AssessmentSectionForm 
        title={Strings.redFlagsHeader} 
        onSubmit={this.onSubmit}
      >
        <div className="card card-form">
          <FormInputMessage
            className="alert alert-danger"
            text={Strings.errorMessage}
            visible={submitted && !this.isValid()}
          />
          <p className="text-muted">{Strings.redFlagsInfoText}</p>
          <div className="column-checkbox-group">
            {Object.keys(red_flags).map((key, index) => {
              return (
                <CheckboxInputGroup
                  key={index}
                  className="form-group"
                  labelText={Strings[key]}
                  inputProps={{
                    className: 'form-control',
                    checked: red_flags[key],
                    onChange: this.onUpdateRedFlags.bind(this, key)
                  }}
                  inputValid
                />
              );
            })}
          </div>
        </div>
      </AssessmentSectionForm>
    );
  }

  onUpdateRedFlags(key, e) {
    const { checked } = e.target;
    const { red_flags } = this.state;

    red_flags[key] = checked;

    if (key !== 'none' && red_flags.none) {
      red_flags.none = false;
    } else if (key === 'none' && red_flags.none) {
      Object.keys(red_flags).forEach((key) => {
        red_flags[key] = key === 'none';
      });
    }

    this.setState({
      red_flags
    }, this._callOnUpdate);
  }

  isValid() {
    const { red_flags } = this.state;

    const valid = Object.keys(red_flags).some((key) => red_flags[key] === true);

    return valid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      this.onError();
      return;
    }

    const { red_flags } = this.state;

    const attributes = {
      red_flags
    };

    this.props.onSubmit(attributes);
  }

  _callOnUpdate() {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this.state.red_flags, this.isValid());
    }
  }

  onError() {
    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }
  }
}

RedFlagsForm.propTypes = {
  redFlags: PropTypes.object,
  onSubmit: PropTypes.func
};

export default RedFlagsForm;

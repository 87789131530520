import React, {
  useMemo, useState, useCallback, useRef
} from 'react';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import Modal from '../modal';
import INJURY_STATUSES from './injury-statuses';
import Strings from './lang';
import {
  FormComponent,
  DateInputGroup,
  CheckboxInputGroup,
  FormInputGroup
} from '../../forms';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';

const getStatusTitle = (status) => {
  const value = INJURY_STATUSES.find(item => item.status === status) || {};
  return value.title || '';
};

const InjuryDischargeForm = React.forwardRef(({
  status,
  statusTitle = '',
  injuryDate,
  onSubmit = () => { },
  onClose = () => {}
}, ref) => {
  const [date, setDate] = useState({ value: '', valid: false });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (date.value) {
      const attributes = {
        status,
        action_date: date.value
      };
      onSubmit(attributes);
    }
  }, [date.value, onSubmit, status]);

  return (
    <FormComponent
      ref={ref}
      className="injury-status-form"
      onSubmit={handleSubmit}
    >
      <p
        className="alert alert-info"
        dangerouslySetInnerHTML={{
          __html: Strings.formatString(Strings.dischargedMessageText, statusTitle)
        }}
      />

      <DateInputGroup
        required
        className="form-group injury-status-date-input-group"
        labelText={Strings.dischargedDateLabelText}
        inputType="date"
        textInputProps={{
          className: 'form-control',
          placeholder: `e.g. ${dayjs().format(DISPLAY_DATE_FORMAT)}`
        }}
        dateInputProps={{
          className: 'form-control'
        }}
        minDate={dayjs(injuryDate)}
        maxDate={dayjs()}
        initialValue={date.value}
        onUpdate={(value, valid) => setDate({ value, valid })}
        inputValid={date.valid}
        messageText={
          Strings.formatString(
            Strings.dischargedDateErrorText,
            dayjs(injuryDate).format(DISPLAY_DATE_FORMAT),
            dayjs().format(DISPLAY_DATE_FORMAT)
          )
        }
        messageClassName="alert alert-danger"
        touched={submitted}
      />

      <If condition={date.length > 0 && dayjs(date).isValid()}>
        <Then>
          <p className="alert alert-info">
            {
              Strings.formatString(
                Strings.dischargeDateConfirmText,
                dayjs(date).format(DISPLAY_DATE_FORMAT)
              )
            }
          </p>
        </Then>
      </If>
      <div className="form-footer">
        <button type="button" className="btn btn-default" onClick={onClose}>
          {Strings.cancelButtonTitle}
        </button>
        <button type="submit" className="btn btn-primary">
          {Strings.confirmButtonTitle}
        </button>
      </div>
    </FormComponent>
  );
});

const InjuryStatusChangeForm = React.forwardRef(({
  status,
  statusTitle = '',
  onSubmit = () => { },
  onClose = () => {}
}, ref) => {
  const [reason, setReason] = useState('');
  const [accept, setAccept] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (accept && reason.length > 0) {
      const attributes = { status, reason };
      if (status === 'lost_to_follow_up') {
        attributes.action_date = dayjs().format();
      }

      onSubmit(attributes);
    }
  }, [accept, onSubmit, reason, status]);

  return (
    <FormComponent
      ref={ref}
      className="injury-status-form"
      onSubmit={handleSubmit}
    >
      <p
        className="alert alert-info"
        dangerouslySetInnerHTML={{
          __html: Strings.formatString(Strings.ongoingLostMessageText, statusTitle)
        }}
      />
      <CheckboxInputGroup
        required
        className="form-group injury-status-accept-input-group"
        labelText={Strings.formatString(
          Strings.ongoingLostAcceptLabelText,
          statusTitle
        )}
        inputProps={{
          className: 'form-control',
          checked: accept,
          onChange: (e) => setAccept(e.target.checked)
        }}
        inputValid={accept}
        messageText={Strings.ongoingLostAcceptErrorText}
        messageClassName="alert alert-danger"
        touched={submitted}
      />

      <FormInputGroup
        required
        className="form-group injury-status-reason-input-group"
        labelText={Strings.ongoingLostReasonLabelText}
        inputType="textarea"
        inputProps={{
          className: 'form-control',
          value: reason,
          onChange: (e) => setReason(e.target.value)
        }}
        inputValid={reason.length > 0}
        messageText={Strings.ongoingLostReasonErrorText}
        messageClassName="alert alert-danger"
        touched={submitted}
      />
      <div className="form-footer">
        <button type="button" className="btn btn-default" onClick={onClose}>
          {Strings.cancelButtonTitle}
        </button>
        <button type="submit" className="btn btn-primary">
          {Strings.confirmButtonTitle}
        </button>
      </div>
    </FormComponent>
  );
});

const InjuryStatusForm = React.forwardRef(({
  status,
  injuryDate,
  statusTitle,
  onSubmit = () => { },
  onClose = () => {}
}, ref) => {
  if (status === 'discharged') {
    return (
      <InjuryDischargeForm
        ref={ref}
        status={status}
        statusTitle={statusTitle}
        injuryDate={injuryDate}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    );
  }

  if (status === 'ongoing' || status === 'lost_to_follow_up') {
    return (
      <InjuryStatusChangeForm
        ref={ref}
        status={status}
        statusTitle={statusTitle}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    );
  }

  return null;
});

const InjuryStatusModal = ({
  isOpen,
  status,
  injuryDate,
  onClose = () => { },
  onSubmit = () => { }
}) => {
  const formRef = useRef();
  const statusTitle = useMemo(() => getStatusTitle(status), [status]);

  const handleSubmit = useCallback((attributes) => {
    onSubmit(attributes);
    onClose();
  }, [onClose, onSubmit]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <h1>{Strings.formatString(Strings.modalTitle, statusTitle)}</h1>
      </Modal.Header>
      <Modal.Body>
        <InjuryStatusForm
          ref={formRef}
          status={status}
          statusTitle={statusTitle}
          injuryDate={injuryDate}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default InjuryStatusModal;

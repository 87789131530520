import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClinicSearchForm from './clinic-search-form';
import { searchClinicPlayersAsync } from '../../redux/thunks/clinic-players-search';
import { updateClinicPlayersSearch } from '../../redux/actions/clinic-players-search';
import Activity from '../activity';
import filterBagtagInput from '../../utilities/bagtag-filter';

class ClinicSearchControl extends Component {
  constructor(props) {
    super(props);

    // Restore the previous players search for this clinic:
    const { clinicId, clinicPlayersSearch = {} } = props;
    const { attributes = {} } = clinicPlayersSearch[clinicId] || {};
    const { search = '' } = attributes;

    this.onUpdateSearch = this.onUpdateSearch.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._clear = this._clear.bind(this);

    this.state = {
      clinicId,
      search,
      activity: false
    };
  }

  render() {
    return (
      <Activity active={this.state.activity}>
        <ClinicSearchForm 
          compact={this.props.compact}
          search={this.state.search}
          onUpdate={this.onUpdateSearch}
          onSubmit={this.onSubmit}
          onClear={this._clear}
          className={this.props.className}
          searchButtonText={this.props.searchButtonText}
        />
      </Activity>
    );
  }

  onUpdateSearch(e) {
    const search = e.target.value;
    const filteredSearch = filterBagtagInput(search);
    this.setState({ 
      search: filteredSearch
    });
  }

  onSubmit() {
    const { clinicId, search } = this.state;
    if (clinicId && search.length) {
      this._search({
        clinic_id: clinicId,
        search
      });
    } else {
      this._clear();
    }
  }

  _clear() {
    this.setState({ search: '' });
    this.props.clearClinicPlayersSearch(this.state.clinicId);

    if (typeof this.props.onClear === 'function') {
      this.props.onClear();
    }
  }

  _search(attributes) {
    this.setState({ activity: true });

    this.props.searchClinicPlayers(attributes).then(response => {
      this.setState({ activity: false });
      this.props.onSearch(response);
    }).catch(error => {
      this.setState({ activity: false });
      this.props.onError(error);
    });
  }
}

ClinicSearchControl.propTypes = {
  className: PropTypes.string,
  compact: PropTypes.bool,
  searchButtonText: PropTypes.string,
  clinicId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  onSearch: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onClear: PropTypes.func
};

const mapStateToProps = (state) => {
  const { clinicPlayersSearch = {} } = state;
  return { clinicPlayersSearch };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchClinicPlayers: attributes => {
      return dispatch(searchClinicPlayersAsync(attributes));
    },
    clearClinicPlayersSearch: id => {
      dispatch(updateClinicPlayersSearch({ search: '', clinic_id: id }, []));
    }
  };
};

const ConnectedClinicSearchControl = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicSearchControl);

export default ConnectedClinicSearchControl;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { FormInputGroup, RadioInputGroup, NumberValidator } from '../../../../forms';
import LocationOfImpactInputGroup from './location-of-impact-input-group';
import AmnesiaOfEvent from './amnesia-of-event';
import CauseOfInjury from './cause-of-injury';
import { SymptomCheckBoxes } from '../../../symptoms';
import { 
  getDefaultState, validateAmnesia, validateInjuryCause, validateLOC 
} from './utilities';
import AssessmentSectionForm from '../assessment-section-form';
import Strings from './lang';

class InjuryCharacteristicsForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.onUpdateBodyLocation = this.onUpdateLocationImpact.bind(this, 'body_impact_location');
    this.onUpdateHeadLocation = this.onUpdateLocationImpact.bind(this, 'head_impact_location');
    this.onUpdateInjuryCause = this.onUpdateInjuryCause.bind(this);
    this.onUpdateSymptoms = this.onUpdateSymptoms.bind(this);

    this.state = {
      ...getDefaultState(props),
      submitted: false
    };
  }

  componentDidMount() {
    this._callOnUpdate();
  }

  render() {
    const submitted = (this.props.submitted === true || this.state.submitted === true);
    return (
      <AssessmentSectionForm 
        title={Strings.injuryCharacteristicsHeader} 
        className="injury-characteristics-form" 
        onSubmit={this.onSubmit}
      >
        <div>
          
          {/* Injury decription text area */}
          <div>
            <FormInputGroup 
              className="form-group"
              labelText={Strings.injuryDescriptionLabelText} 
              labelProps={{
                className: 'bold'
              }}
              inputType="textarea"
              inputProps={{
                rows: 10,
                maxRows: 20,
                className: 'form-control',
                value: this.state.description,
                placeholder: Strings.injuryDescriptionPlaceHolderText,
                onBlur: this.onUpdateEvent.bind(this, 'description'),
                onChange: this.onUpdateEvent.bind(this, 'description')
              }}
              inputValid={this.state.description !== ''}
              messageClassName="alert alert-danger"
              messageText={Strings.descriptionErrorMessage}
              touched={submitted}
              required
            />
          </div>

          <div className="row">
            {/* Direct hit to head input */}
            <div className="col-md-4">
              <RadioInputGroup
                titleLabelText={
                  <span className="bold">{Strings.directHitHeadLabelText} <span className="required">*</span></span>
                }
                className="form-group"
                radioLabels={[Strings.yesLabel, Strings.noLabel, Strings.unknownLabel]}
                radioValues={['yes', 'no', 'unknown']}
                initialValue={this.state.direct_head_impact}
                inputProps={{
                  className: 'form-control'
                }}
                required
                touched={submitted}
                inputValid={this.state.direct_head_impact !== ''}
                messageClassName="alert alert-danger"
                messageText={Strings.noValueSelectedErrorMessage}
                onUpdate={(value) => this.onUpdateValue('direct_head_impact', value)}
              />
            </div>

            {/* Location of impact input (head, body) !! MAY NEED TO CHANGE !! */}
            <div className="col-md-5">
              <label className="bold uppercase">{Strings.locationOfImpactLabelText}</label>
              <LocationOfImpactInputGroup 
                labelText={Strings.loiBodyLabelText}
                location="body"
                locationValues={this.state.body_impact_location}
                onChange={this.onUpdateBodyLocation}
              />
              <LocationOfImpactInputGroup 
                labelText={Strings.loiHeadLabelText}
                location="head"
                locationValues={this.state.head_impact_location}
                onChange={this.onUpdateHeadLocation}
              />
            </div>
        
            {/* Loss of conciousness */}
            <div className="col-md-3">
              <RadioInputGroup
                titleLabelText={
                  <span className="bold">{Strings.lossOfConsciousnessLabelText} <span className="required">*</span></span>
                }
                className="form-group"
                radioLabels={[Strings.yesLabel, Strings.noLabel, Strings.unsureLabel]}
                radioValues={['yes', 'no', 'unsure']}
                initialValue={this.state.loss_of_consciousness}
                inputProps={{
                  className: 'form-control'
                }}
                required
                touched={submitted}
                inputValid={this.state.loss_of_consciousness !== ''}
                messageClassName="alert alert-danger"
                messageText={Strings.noValueSelectedErrorMessage}
                onUpdate={(value) => this.onUpdateValue('loss_of_consciousness', value)}
              />
              {this.state.loss_of_consciousness === 'yes' && (
                <FormInputGroup 
                  className="form-group"
                  labelText={Strings.timeInMinutesLabelText} 
                  labelProps={{
                    className: 'bold'
                  }}
                  inputType="text"
                  inputProps={{
                    className: 'form-control',
                    value: this.state.minutes_unconscious,
                    placeholder: Strings.timeInMinutesLabelText,
                    onBlur: this.onUpdateEvent.bind(this, 'minutes_unconscious'),
                    onChange: this.onUpdateEvent.bind(this, 'minutes_unconscious')
                  }}
                  touched={submitted}
                  inputValid={NumberValidator.test(this.state.minutes_unconscious)}
                  messageClassName="alert alert-danger"
                  messageText={Strings.invalidNumberErrorMessage}
                  required
                />
              )}
            </div>
          </div>

          <div className="row">
            {/* Cause of injury */}
            <div className="col-md-5">
              <CauseOfInjury
                injury_cause={{
                  cause: this.state.cause,
                  cause_sport: this.state.cause_sport,
                  cause_other: this.state.cause_other,
                  cause_sport_other: this.state.cause_sport_other
                }}
                onUpdate={this.onUpdateInjuryCause}
                touched={submitted}
              />
            </div>

            {/* Amnesia of event */}
            <div className="col-md-3">
              <AmnesiaOfEvent
                initialState={this.state.amnesia}
                onUpdate={(value) => this.onUpdateValue('amnesia', value)}
                touched={submitted}
              />
            </div>

            {/* Seizures section */}
            <div className="col-md-2">
              <RadioInputGroup
                titleLabelText={
                  <span className="bold">{Strings.seizuresLabelText} <span className="required">*</span></span>
                }
                className="form-group"
                radioLabels={[Strings.yesLabel, Strings.noLabel]}
                radioValues={['yes', 'no']}
                initialValue={this.state.seizures}
                inputProps={{
                  className: 'form-control'
                }}
                required
                touched={submitted}
                inputValid={this.state.seizures !== ''}
                messageClassName="alert alert-danger"
                messageText={Strings.noValueSelectedErrorMessage}
                onUpdate={(value) => this.onUpdateValue('seizures', value)}
              />
              <FormInputGroup 
                className="form-group"
                labelText={Strings.notesLabel} 
                labelProps={{
                  className: 'bold'
                }}
                inputType="text"
                inputProps={{
                  className: 'form-control',
                  value: this.state.seizures_notes || '',
                  onBlur: this.onUpdateEvent.bind(this, 'seizures_notes'),
                  onChange: this.onUpdateEvent.bind(this, 'seizures_notes')
                }}
                inputValid
              />
            </div>

            <If condition={this.props.type === 'chronic'}>
              <Then>
                <div className="col-md-2">
                  <RadioInputGroup
                    titleLabelText={
                      <span className="bold">{Strings.timelineLabelText} <span className="required">*</span></span>
                    }
                    className="form-group"
                    radioLabels={[Strings.yesLabel, Strings.noLabel]}
                    radioValues={['yes', 'no']}
                    initialValue={this.state.timeline.multiple_concussions}
                    inputProps={{
                      className: 'form-control'
                    }}
                    required
                    touched={submitted}
                    inputValid={this.state.timeline.multiple_concussions !== ''}
                    messageClassName="alert alert-danger"
                    messageText={Strings.noValueSelectedErrorMessage}
                    onUpdate={(e) => this.onUpdateTimeline('multiple_concussions', e)}
                  />
                  <FormInputGroup 
                    className="form-group"
                    labelText={Strings.notesLabel} 
                    labelProps={{
                      className: 'bold'
                    }}
                    inputType="text"
                    inputProps={{
                      className: 'form-control',
                      value: this.state.timeline.notes,
                      onBlur: this.onUpdateTimeline.bind(this, 'notes'),
                      onChange: this.onUpdateTimeline.bind(this, 'notes')
                    }}
                    inputValid
                  />
                </div>
              </Then>
            </If>
          </div>

          {/* Initial Symptoms section */}
          <div>
            <label className="bold uppercase">{Strings.initialSymptomsLabel}</label>
            <SymptomCheckBoxes
              symptomsChecked={this.state.initial_symptoms}
              onUpdate={this.onUpdateSymptoms}
            />
          </div>

        </div>

      </AssessmentSectionForm>
    );
  }

  onUpdateValue(key, value) {
    this.setState({
      [key]: value
    }, this._callOnUpdate.bind(this));
  }

  onUpdateEvent(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    }, this._callOnUpdate.bind(this));
  }

  onUpdateSymptoms(key, e) {
    const { checked, value } = e.target;
    const { initial_symptoms } = this.state;

    if (key === 'other') {
      initial_symptoms[key] = value;
    } else {
      initial_symptoms[key] = checked;
    }

    this.setState({
      initial_symptoms
    }, this._callOnUpdate.bind(this));
  }

  onUpdateLocationImpact(key, subKey, e) {
    const { checked } = e.target;
    const { [key]: location } = this.state;

    location[subKey] = checked;

    this.setState({
      [key]: location
    }, this._callOnUpdate.bind(this));
  }

  onUpdateInjuryCause(values) {
    this.setState({
      ...values
    }, this._callOnUpdate.bind(this));
  }

  onUpdateTimeline(key, e) {
    const { timeline } = this.state;
    const value = e.target ? e.target.value : e;

    timeline[key] = value;

    this.setState({
      timeline
    }, this._callOnUpdate.bind(this));
  }

  isValid() {
    const {
      description,
      direct_head_impact,
      loss_of_consciousness,
      minutes_unconscious,
      cause,
      cause_sport,
      cause_other,
      cause_sport_other,
      seizures,
      timeline,
      amnesia
    } = this.state;

    return description !== ''
      && direct_head_impact !== '' 
      && seizures !== '' 
      && (this.props.type === 'chronic' ? timeline.multiple_concussions !== '' : true) 
      && validateAmnesia(amnesia) 
      && validateInjuryCause({ 
        cause, cause_sport, cause_other, cause_sport_other 
      }) && validateLOC({ loss_of_consciousness, minutes_unconscious });
  }

  getAttributes() {
    const {
      description,
      direct_head_impact,
      loss_of_consciousness,
      minutes_unconscious,
      cause,
      cause_sport,
      cause_other,
      cause_sport_other,
      seizures,
      seizures_notes,
      timeline,
      body_impact_location,
      head_impact_location,
      amnesia,
      initial_symptoms
    } = this.state;

    return {
      description,
      direct_head_impact,
      loss_of_consciousness,
      minutes_unconscious,
      cause,
      cause_sport,
      cause_other,
      cause_sport_other,
      seizures,
      seizures_notes,
      timeline,
      body_impact_location,
      head_impact_location,
      amnesia,
      initial_symptoms
    };
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      this.onError();
      return;
    }

    const attributes = {
      characteristics: {
        ...this.getAttributes()
      }
    };

    this.props.onSubmit(attributes);
  }

  _callOnUpdate() {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this.getAttributes(), this.isValid());
    }
  }

  onError() {
    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }
  }
}

/* eslint-disable react/no-unused-prop-types */
InjuryCharacteristicsForm.propTypes = {
  characteristics: PropTypes.object,
  submitted: PropTypes.bool,
  onSubmit: PropTypes.func,
  onUpdate: PropTypes.func
};

export default InjuryCharacteristicsForm;

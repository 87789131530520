import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SelectInputGroup } from '../../../../forms';
import Strings from './lang';

const MAX_SELECT_NUMBER = 50;
const SELECT_NUMBER_STEP = 0.5;

class ReactionTimeTrial extends Component {
  render() {
    const { 
      value, reactionTime, onUpdate = () => {}, trialLabel 
    } = this.props;
    return (
      <div className="baseline-card-row">
        <label>{ trialLabel }</label>
        <SelectInputGroup
          labelText={Strings.unitLabel}
          className="form-group baseline-select-input"
          inputProps={{
            className: 'form-control',
            value
          }}
          required
          valid={value !== ''}
          messageClassName="alert alert-danger"
          touched={this.props.touched}
          onUpdate={onUpdate}
        >
          <option value="">{Strings.defaultOption}</option>
          { this.renderOptions() }
        </SelectInputGroup>
        <div className="reaction-time-number">
          {Strings.formatString(
            Strings.reactionTimeResultText,
            reactionTime
          )}
        </div>
      </div>
    );
  }

  renderOptions() {
    const { 
      maxSelectNumber = MAX_SELECT_NUMBER, selectNumberStep = SELECT_NUMBER_STEP 
    } = this.props;
    const options = [];
    let value = 0;

    while (value <= maxSelectNumber) {
      options.push(
        <option key={value} value={value}>{value}</option>
      );
      value += selectNumberStep;
    }

    return options;
  }
}

ReactionTimeTrial.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxSelectNumber: PropTypes.number,
  selectNumberStep: PropTypes.number,
  reactionTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdate: PropTypes.func,
  touched: PropTypes.bool
};

export {
  ReactionTimeTrial,
  SELECT_NUMBER_STEP,
  MAX_SELECT_NUMBER
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormInputGroup, CheckboxInputGroup } from '../../../../forms';
import Strings from './lang';

class FollowUpActionPlan extends Component {
  constructor(props) {
    super(props);

    const { followUpAction = {} } = props;

    const {
      none = false,
      clinician = false,
      referral = false,
      referral_to = ''
    } = followUpAction;

    this.state = {
      none,
      clinician,
      referral,
      referral_to
    };
  }

  componentDidMount() {
    this._callOnUpdate();
  }

  render() {
    return (
      <div className={this.props.className}>
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.none}
          inputProps={{
            className: 'form-control',
            checked: this.state.none || false,
            onChange: this.onFollowUpUpdate.bind(this, 'none')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.clinician}
          inputProps={{
            className: 'form-control',
            checked: this.state.clinician || false,
            onChange: this.onFollowUpUpdate.bind(this, 'clinician')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.referral}
          inputProps={{
            className: 'form-control',
            checked: this.state.referral || false,
            onChange: this.onFollowUpUpdate.bind(this, 'referral')
          }}
          inputValid
        />
        {this.state.referral && (
          <FormInputGroup 
            className="form-group"
            labelText={Strings.referralToLabel} 
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: this.state.referral_to,
              onBlur: this.onUpdateReferralTo.bind(this),
              onChange: this.onUpdateReferralTo.bind(this)
            }}
            inputValid={this.state.referral_to !== ''}
            messageClassName="alert alert-danger"
            messageText={Strings.invalidReferralToMessage}
            required
            touched={this.props.submitted}
          />
        )}
      </div>
    );
  }

  onFollowUpUpdate(key, e) {
    const { checked } = e.target;
    let { referral_to } = this.state;

    if (key === 'referral' && !checked) {
      referral_to = '';
    }

    this.setState({
      referral_to,
      [key]: checked
    }, this._callOnUpdate.bind(this));
  }

  onUpdateReferralTo(e) {
    const { value } = e.target;

    this.setState({
      referral_to: value
    }, this._callOnUpdate.bind(this));
  }

  getAttributes() {
    const {
      none,
      clinician,
      referral,
      referral_to
    } = this.state;

    return {
      none,
      clinician,
      referral,
      referral_to
    };
  }

  isValid() {
    if (this.state.referral && this.state.referral_to === '') {
      this.onError();
      return false;
    }

    return true;
  }

  _callOnUpdate() {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this.getAttributes(), this.isValid());
    }
  }

  onError() {
    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }
  }
}

FollowUpActionPlan.propTypes = {
  followUpAction: PropTypes.object,
  submitted: PropTypes.bool,
  onUpdate: PropTypes.func
};

export default FollowUpActionPlan;

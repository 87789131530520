import React from 'react';
import { Authorization } from '../authorization';
import { RoleDescriptions } from '../../utilities/user-roles';
import { CreateUserInjury } from '../../components/injuries';
import { SoapNotes } from '../../components/soap-notes';
import { SpecialistNotes } from '../../components/specialist-notes';
import { PostInjuryAssessment } from '../../components/post-injury-assessment';
import PageNotFound from '../not-found-page';

const UserInjuriesRoutes = [
  {
    path: 'new',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist
        ]}
      >
        <CreateUserInjury />
      </Authorization>
    )
  },
  {
    path: ':injuryId/*',
    children: [
      {
        path: 'assessment/:assessmentId/*',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist
            ]}
          />
        ),
        children: [
          {
            path: 'full',
            element: <PostInjuryAssessment />
          },
          {
            path: 'injury-report/:reportId/full',
            element: <PostInjuryAssessment />
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: 'soaps/:soapId/notes',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Clinician,
              RoleDescriptions.ClinicStaff
            ]}
          >
            <SoapNotes />
          </Authorization>
        )
      },
      {
        path: 'specialist-notes/*',
        element: (
          <Authorization
            allowRoles={[
              RoleDescriptions.Specialist
            ]}
          />
        ),
        children: [
          {
            path: 'new',
            element: <SpecialistNotes />
          },
          {
            path: ':noteId',
            element: <SpecialistNotes />
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
];

export default UserInjuriesRoutes;

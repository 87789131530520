import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import IdtClaimsLayout from './idt-claims-layout';
import { DischargedClaimsTable } from './claims-tables';
import { getClinicIdtClaimsAsync } from '../../redux/thunks/clinic-idt-claims';
import { ClinicIDTClaimsSelector } from '../../redux/selectors';
import { useMount } from '../../hooks';
import { getCurrentClinicId } from '../../tokens';
import Strings from './lang';

const IdtDischargedClaims = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const clinicId = useMemo(() => getCurrentClinicId(), []);
  const dischargedClaims = useSelector((state) => (
    ClinicIDTClaimsSelector.getClaimsByStatus(state, { clinicId, status: 'discharged' })
  )) ?? [];

  const getDischargedClaims = useCallback((clinicId) => {
    setLoading(true);
    setError(null);

    dispatch(getClinicIdtClaimsAsync(clinicId, 'discharged')).then(() => {
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      setError(error.message);
    });
  }, [dispatch]);

  useMount(() => {
    getDischargedClaims(clinicId);
  });

  return (
    <IdtClaimsLayout>
      <ErrorBanner error={error} />
      <Activity active={loading} static={!dischargedClaims.length}>
        <h2>{Strings.dischargedClaims}</h2>
        <If condition={!!dischargedClaims.length}>
          <Then>
            <div>
              <DischargedClaimsTable claims={dischargedClaims} />
            </div>
          </Then>
          <Else>
            <div>
              {Strings.noDischargedClaims}
            </div>
          </Else>
        </If>
      </Activity>
    </IdtClaimsLayout>
  );
};

export default IdtDischargedClaims;

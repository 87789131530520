import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReferralActions from './actions';
import AccessControl from '../../access-control';
import { usePatientLink } from './utils';
import { ProfessionStrings } from '../../../utilities/clinical-info';
import replaceLinkParams from '../../../utilities/replace-link-params';
import { ClinicPaths } from '../../../paths';
import { makeRoleDescription, RoleNames, RoleResourceTypes } from '../../../utilities/user-roles';
import { ClinicSelector } from '../../../redux/selectors';
import Strings from '../lang';
import Icon from '../../icon';

const makeReferralActionRoles = (clinicId) => ([
  makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId)
]);

const LinkWrapper = ({ to, enabled = true, children }) => {
  if (!enabled) return children;

  return (
    <Link to={to}>
      {children}
    </Link>
  );
};

const UserDetails = ({
  photo = null,
  name = null,
  label = null,
  children
}) => {
  return (
    <div className="user-details">
      <div className="profile-photo">
        {photo ? (
          <img src={photo} alt="profile" />
        ) : (
          <Icon name="circle-user" />
        )}
      </div>
      <div className="details">
        {label && <div><small>{label}</small></div>}
        <div className="name">{name}</div>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};

const PatientDetails = ({ patient, linkEnabled = true }) => {
  const patientLink = usePatientLink(patient);

  return (
    <UserDetails
      label={Strings.patientLabel}
      photo={patient.person?.photo_url}
      name={`${patient.person?.first_name} ${patient.person?.last_name}`}
    >
      {!!patientLink && linkEnabled && (
        <Link to={patientLink}>
          {Strings.viewProfileLinkText}
        </Link>
      )}
    </UserDetails>
  );
};

const ReferredToDetails = ({ user, clinic }) => {
  const photo = user ? user.person?.photo_url : clinic?.photo_url;

  return (
    <UserDetails
      photo={photo}
      name={user ? `${user.person?.first_name} ${user.person?.last_name}` : clinic?.name}
    >
      {!!user && (
        <div>{clinic.name}</div>
      )}
    </UserDetails>
  );
};

const ReferrerDetails = ({ user, clinic, linksEnabled = true }) => {
  const referrerLink = replaceLinkParams(ClinicPaths.userProfile.link, {
    clinicId: clinic.id,
    userId: user.id
  });

  const referrerClinicLink = replaceLinkParams(ClinicPaths.profile.link, {
    clinicId: clinic.id
  });

  return (
    <div className="referrer-details">
      {Strings.referredByText}&nbsp;
      <LinkWrapper to={referrerLink} enabled={linksEnabled}>
        <strong>{user.person?.first_name} {user.person?.last_name}</strong>
      </LinkWrapper>&nbsp;
      {Strings.fromSeparatorText}&nbsp;
      <LinkWrapper to={referrerClinicLink} enabled={linksEnabled}>
        <strong>{clinic.name}</strong>
      </LinkWrapper>
    </div>
  );
};

const getProfessionsStrings = (professions = []) => {
  const professionStrings = [...professions].map(p => ProfessionStrings[p]).filter(Boolean);

  if (professionStrings.length > 1) {
    const last = professionStrings.pop();
    return `${professionStrings.join(', ')} ${Strings.orSeparatorText} ${last}`;
  }

  return professionStrings[0];
};

const ReferralCardDetails = ({
  referral = {},
  linksEnabled = true,
  onAccept = () => {},
  onReject = () => { }
}) => {
  const {
    user,
    status,
    professions,
    referrer,
    referrer_clinic,
    referred_to,
    referred_to_clinic
  } = referral;

  const currentClinic = useSelector(ClinicSelector.getCurrentClinic);
  const isActionable = useMemo(() => {
    return status === 'pending' && referred_to_clinic?.id === currentClinic?.id;
  }, [currentClinic?.id, referred_to_clinic?.id, status]);

  return (
    <div className="referral-card-details">
      <div className="referral-view">
        <PatientDetails patient={user} linkEnabled={linksEnabled} />
        <div className="separator">
          <Icon name="arrow-right-long" />
        </div>
        <ReferredToDetails 
          user={referred_to} 
          clinic={referred_to_clinic} 
        />
      </div>
      {!referred_to && professions.length > 0 && (
        <div className="referral-professions">
          {Strings.formatString(Strings.professionRequestText, getProfessionsStrings(professions))}
        </div>
      )}
      <ReferrerDetails 
        user={referrer}
        clinic={referrer_clinic}
        linksEnabled={linksEnabled}
      />
      {isActionable && (
        <AccessControl roles={makeReferralActionRoles(referred_to_clinic.id)}>
          <ReferralActions 
            status={status}
            onAccept={(message) => onAccept(referral.id, message)}
            onReject={(message) => onReject(referral.id, message)}
          />
        </AccessControl>
      )}
    </div>
  );
};

export default ReferralCardDetails;

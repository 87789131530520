import React from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import Strings from './lang';

const LastEditedView = ({ 
  inline = false, 
  showPrint = true, 
  item = {}, 
  wrap = 'span' 
}) => {
  const { last_updated_by, updated_at } = item || {};

  if (!last_updated_by) {
    return null;
  }

  const str = inline ? Strings.inlineLastEditedText : Strings.documentLastEditedText;
  const message = Strings.formatString(
    str, 
    dayjs(updated_at).format(DISPLAY_DATE_FORMAT),
    last_updated_by
  );

  const WrapComponent = wrap || 'span';

  if (inline) {
    return (
      <WrapComponent>
        <small className={classnames({ 'no-print': !showPrint })}><i>{message}</i></small>
      </WrapComponent>
    );
  }

  return (
    <WrapComponent>
      <p className={classnames({ 'no-print': !showPrint })}><strong>{message}</strong></p>
    </WrapComponent>
  );
};

export default LastEditedView;

import { 
  UPDATE_CLINIC_INVOICE, 
  UPDATE_CLINIC_INVOICES,
  ADD_INVOICE_LINE_ITEM,
  UPDATE_INVOICE_LINE_ITEM,
  DELETE_INVOICE_LINE_ITEM, ADD_INVOICE_PAYMENT 
} from '../actions/clinic-invoices';
import { Money } from '../../utilities/currency';

const flattenLineItem = (lineItem = {}) => {
  const { item = {}, ...rest } = lineItem;
  return {
    ...item,
    ...rest,
    itemId: item.id
  };
};

const flattenLineItems = (lineItems = []) => lineItems.map(flattenLineItem);

const updateClinicInvoiceReducer = (state, action) => {
  const { clinicId, invoice } = action;
  const { id: invoiceId } = invoice || {};
  const clinicInvoices = state[clinicId] || {};

  return invoiceId ? {
    ...state,
    [clinicId]: {
      ...clinicInvoices,
      [invoiceId]: { 
        ...invoice,
        line_items: flattenLineItems(invoice.line_items)
      }
    }
  } : state;
};

const updateClinicInvoicesReducer = (state, action) => {
  const { clinicId, invoices = [] } = action;

  return invoices.reduce((acc, invoice) => {
    return updateClinicInvoiceReducer(acc, { clinicId, invoice });
  }, state);
};

const updateBalances = (type, invoice, lineItem) => {
  const {
    amount, total, owing, currency 
  } = invoice;
  const { amount: itemAmount, tax } = lineItem || {};
  const inclusive = Money.add(currency, itemAmount, tax);

  return {
    amount: Money[type](currency, amount, itemAmount),
    total: Money[type](currency, total, inclusive),
    owing: Money[type](currency, owing, inclusive)
  };
};

const updateTaxes = (type, taxes, item, currency) => {
  const { tax_description, tax } = item || {};
  return taxes.reduce((acc, taxItem) => {
    return taxItem.description === tax_description 
      ? [
        ...acc,
        {
          ...taxItem,
          formatted: Money[type](currency, taxItem.formatted, tax)
        }
      ] : [
        ...acc,
        taxItem
      ];
  }, []);
};

const addInvoiceLineItemReducer = (state, action) => {
  const { clinicId, invoiceId, lineItem } = action;
  const { [clinicId]: clinicInvoices = {} } = state;
  const { [invoiceId]: invoice = {} } = clinicInvoices;
  const { line_items = [], taxes = [], currency } = invoice;

  const newBalances = updateBalances('add', invoice, lineItem);
  const newTaxes = updateTaxes('add', taxes, lineItem, currency);

  return {
    ...state,
    [clinicId]: {
      ...clinicInvoices,
      [invoiceId]: {
        ...invoice,
        taxes: newTaxes,
        line_items: [
          flattenLineItem(lineItem),
          ...line_items
        ],
        ...newBalances
      }
    }
  };
};

const updateInvoiceLineItemReducer = (state, action) => {
  const { clinicId, invoiceId, lineItem } = action;
  const { [clinicId]: clinicInvoices = {} } = state;
  const { [invoiceId]: invoice = {} } = clinicInvoices;
  const { line_items = [] } = invoice;

  const lineItemIndex = line_items.findIndex(item => item.id === lineItem.id);
  const prevLineItem = line_items[lineItemIndex];

  if (!prevLineItem) return state;

  const newState = deleteLineItemReducer(state, { ...action, lineItem: prevLineItem });

  return addInvoiceLineItemReducer(newState, action);
};

const deleteLineItemReducer = (state, action) => {
  const { clinicId, invoiceId, lineItem } = action;
  const { [clinicId]: clinicInvoices = {} } = state;
  const { [invoiceId]: invoice = {} } = clinicInvoices;
  const { line_items = [], taxes = [], currency } = invoice;

  const newLineItems = line_items.filter(item => item.id !== lineItem.id);
  const newBalances = updateBalances('subtract', invoice, lineItem);
  const newTaxes = updateTaxes('subtract', taxes, lineItem, currency);

  return {
    ...state,
    [clinicId]: {
      ...clinicInvoices,
      [invoiceId]: {
        ...invoice,
        taxes: newTaxes,
        line_items: newLineItems,
        ...newBalances
      }
    }
  };
};  

const addInvoicePaymentReducer = (state = {}, action = {}) => {
  const { clinicId, invoiceId, payment } = action;
  const clinicInvoices = state[clinicId] || {};
  const invoice = clinicInvoices[invoiceId];

  if (!invoice) return state;

  const newPayments = [...invoice.payments, payment];
  const newBalance = newPayments.reduce((acc, payment) => {
    return (acc - payment.amount_cents);
  }, (Money.unformat(invoice.owing, invoice.currency) * 100));
  const newStatus = newBalance === 0 ? 'paid' : invoice.status;

  return {
    ...state,
    [clinicId]: {
      ...clinicInvoices,
      [invoiceId]: {
        ...invoice,
        status: newStatus,
        payments: newPayments,
        owing: Money.format(newBalance / 100.0, invoice.currency)
      }
    }
  };
};

const clinicInvoices = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_CLINIC_INVOICE:
      return updateClinicInvoiceReducer(state, action);
    case UPDATE_CLINIC_INVOICES:
      return updateClinicInvoicesReducer(state, action);
    case ADD_INVOICE_LINE_ITEM:
      return addInvoiceLineItemReducer(state, action);
    case UPDATE_INVOICE_LINE_ITEM:
      return updateInvoiceLineItemReducer(state, action);
    case DELETE_INVOICE_LINE_ITEM:
      return deleteLineItemReducer(state, action);
    case ADD_INVOICE_PAYMENT:
      return addInvoicePaymentReducer(state, action);
    default:
      return state;
  }
};

export default clinicInvoices;

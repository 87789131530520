import { jsonApiRead, jsonApiCreate, jsonApiUpdate } from '../../requests/jsonapi';
import { updateOVSTest } from '../actions/ovs-tests';

const getOVSTestAsync = (userId, injuryId, ovsId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/ovs_tests/${ovsId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'orthostatic_vital_signs_tests', id: ovsId }, options).then(results => {
      dispatch(updateOVSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createOVSTestAsync = (userId, injuryId, attributes) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/ovs_tests` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'orthostatic_vital_signs_tests', attributes }, options).then(results => {
      dispatch(updateOVSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateOVSTestAsync = (userId, injuryId, ovsId, attributes) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/ovs_tests/${ovsId}` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'orthostatic_vital_signs_tests', id: ovsId, attributes }, options).then(results => {
      dispatch(updateOVSTest(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getOVSTestAsync,
  createOVSTestAsync,
  updateOVSTestAsync
};

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { Page } from '../../page';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import { BaselineSelector, UserSelector } from '../../../redux/selectors';
import { getUserAsync } from '../../../redux/thunks/users';
import { getBaselineTestAsync } from '../../../redux/thunks/baseline-tests';
import { useMount } from '../../../hooks';
import Strings from './lang';

const BaselineRouteContainer = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const user = useSelector(state => UserSelector.getUser(state, params));
  const baseline = useSelector(state => BaselineSelector.getBaseline(state, params));
  const [activity, setActivity] = useState(true);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const userLoader = useCallback(() => {
    return user?.id
      ? Promise.resolve(user)
      : dispatch(getUserAsync(params.userId));
  }, [user, dispatch, params.userId]);

  const baselineLoader = useCallback(() => {
    return (baseline?.id || isNaN(params.baselineId))
      ? Promise.resolve(baseline || undefined)
      : dispatch(getBaselineTestAsync(params.userId, params.baselineId));
  }, [baseline, dispatch, params.baselineId, params.userId]);

  const loadData = useCallback(() => {
    setActivity(true);
    setError(null);

    Promise.all([
      userLoader(),
      baselineLoader()
    ]).then(() => {
      setActivity(false);
      setLoaded(true);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [baselineLoader, userLoader]);

  useMount(() => {
    loadData();
  });

  return (
    <Page container="fluid">
      <Activity
        active={activity}
        static={!loaded}
        titleComponent={<h1>{Strings.loadingBaselineText}</h1>}
      >
        {error ? <ErrorBanner error={error} /> : <Outlet />}
      </Activity>
    </Page>
  );
};

export default BaselineRouteContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_USER_LANGUAGE } from '../../utilities/localization';
import BaselineBESS from '../baseline/user-baseline-detail/baseline-bess';
import BaselineCognitiveAssessment from '../baseline/user-baseline-detail/baseline-cognitive-assessment';
import BaselineMemory from '../baseline/user-baseline-detail/baseline-memory';
import Strings from './lang';

const Test = ({
  test = {}, 
  testKey = '', 
  label = null, 
  component = null
}) => {
  if (testKey in test) {
    return (
      <div className="col-md-4">
        <span className="bold">{label}:</span> <span>{test[testKey] !== null ? test[testKey] : Strings.notCompletedTestText}</span>
      </div>
    );
  }

  return (
    <div className="col-md-12">
      {component}
    </div>
  );
};

const InjuryReportTestSection = ({
  injuryReport = {}, 
  language = DEFAULT_USER_LANGUAGE
}) => {
  const {
    balance_test = {},
    cognitive_assessment_test = {},
    immediate_memory_test = {}
  } = injuryReport;

  return (
    <div className="row injury-reports-test-section">
      <Test 
        test={balance_test}
        testKey="legacy_bess_score"
        label={Strings.bessScoreLabel}
        component={<BaselineBESS data={balance_test || {}} />}
      />

      <Test 
        test={cognitive_assessment_test}
        testKey="legacy_orientation_score"
        label={Strings.orientationScoreLabel}
        component={<BaselineCognitiveAssessment data={cognitive_assessment_test || {}} />}
      />
      
      <Test 
        test={immediate_memory_test}
        testKey="legacy_memory_score"
        label={Strings.memoryScoreLabel}
        component={<BaselineMemory data={immediate_memory_test || {}} language={language} />}
      />
    </div>
  );
};

InjuryReportTestSection.propTypes = {
  injuryReport: PropTypes.object,
  language: PropTypes.string
};

export default InjuryReportTestSection;

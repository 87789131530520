import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import PracticeSection from './practice-section';
import TrialsSection from './trials-section';
import { RadioInputGroup, CheckboxInputGroup } from '../../../../forms';
import ScoreView from '../../../score-view';
import { getAverageTrialResponseRate } from './utils';
import { confirmation } from '../../../confirmation';
import { sortAscending } from '../../../../utilities/sort';
import Strings from './lang';

const STEP_VALUES = ['7', '3'];

const getDurationStats = (trials) => {
  const completedTrials = trials.filter(t => !isNaN(t.duration) && t.duration > 0);
  const total = completedTrials.reduce((acc, trial) => (
    (trial.duration || 0) + acc
  ), 0);
  const avgTime = (total / (completedTrials.length || 1)) / 1000;
  const fastestTime = (completedTrials.sort(sortAscending.bind(null, 'duration'))[0]?.duration || 0) / 1000;

  return { avgTime, fastestTime };
};

const DualTaskTest = ({
  step,
  isChild = false,
  unableToComplete = false,
  showPractise = true,
  submitted = false,
  trials = [],
  onStepChange,
  onTrialChange,
  onUnableToCompleteChange
}) => {
  const stepStr = String(step);
  const passedTrials = useMemo(() => {
    return trials.filter(trial => trial.failed !== true);
  }, [trials]);

  const totalErrors = useMemo(() => (
    passedTrials.reduce((acc, trial) => (trial.errors || 0) + acc, 0)
  ), [passedTrials]);
  const totalResponses = useMemo(() => (
    passedTrials.reduce((acc, trial) => acc + (trial.responses?.length || 0), 0)
  ), [passedTrials]);
  const { avgTime, fastestTime } = useMemo(() => getDurationStats(passedTrials), [passedTrials]);
  const avgResponseRate = useMemo(() => getAverageTrialResponseRate(passedTrials), [passedTrials]);

  const handleStepChange = useCallback((value) => {
    const newStep = parseInt(value, 10);

    const hasAnyCompletedTrials = trials.some(trial => !!trial.responses);

    if (hasAnyCompletedTrials) {
      confirmation(Strings.confirmStepChangeMessage, {
        title: Strings.confirmStepChangeTitle,
        confirmButtonTitle: Strings.confirmStepChangeButton,
        onConfirm: () => onStepChange(newStep)
      });
    } else {
      onStepChange(newStep);
    }
  }, [onStepChange, trials]);

  return (
    <div className="dual-task-test-trials">
      <p>{Strings.instructions}</p>
      <div className="flex-row align_center justify_space-between flex-wrap">
        <RadioInputGroup
          className="form-group"
          titleLabelText={Strings.subtractionValueLabel}
          radioLabels={STEP_VALUES}
          radioValues={STEP_VALUES}
          initialValue={stepStr}
          inputProps={{
            value: stepStr,
            className: 'form-control'
          }}
          inputValid={STEP_VALUES.includes(stepStr)}
          onUpdate={handleStepChange}
          touched={submitted}
        />

        <div className={classNames('unable-to-complete-box', { checked: unableToComplete })}>
          <CheckboxInputGroup
            inputValid
            className="form-group"
            labelText={Strings.unableToCompleteLabel}
            inputProps={{
              className: 'form-control',
              checked: unableToComplete,
              onChange: (e) => onUnableToCompleteChange(e.target.checked)
            }}
          />
        </div>
      </div>
      {!unableToComplete && (
        <>
          {showPractise && (
            <PracticeSection isChild={isChild} step={step} />
          )}
          <TrialsSection
            step={step}
            trials={trials}
            onTrialChange={onTrialChange}
          />
          <div className="test-scores-container">
            <ScoreView
              label={Strings.totalErrors}
              score={totalErrors}
            />
            <ScoreView
              label={Strings.cognitiveAccuracyLabel}
              score={((totalResponses - totalErrors) / (totalResponses || 1)).toFixed(2)}
            />
            <ScoreView
              label={Strings.avgTime}
              score={avgTime.toFixed(2)}
            />
            <ScoreView
              label={Strings.fastestTime}
              score={fastestTime.toFixed(2)}
            />
            <ScoreView
              label={Strings.averageResponseRateLabel}
              score={(avgResponseRate ?? 0).toFixed(2)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DualTaskTest;
export { generateEmptyResponses, OMITTED_START_NUMBERS } from './utils';

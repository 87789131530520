import React from 'react';
import { ClinicInvoice, ClinicInvoices, InvoicePayment } from '../../components/clinic-invoices';
import { Authorization } from '../authorization';
import { RoleDescriptions } from '../../utilities/user-roles';
import PageNotFound from '../not-found-page';

const InvoiceAuthorization = ({ children }) => {
  return (
    <Authorization
      allowUntrained
      allowRoles={[
        RoleDescriptions.SuperAdmin,
        RoleDescriptions.SalesAdmin
      ]}
      allowRolesMatchingResourceId={[
        RoleDescriptions.ClinicOwner,
        RoleDescriptions.Clinician,
        RoleDescriptions.Specialist,
        RoleDescriptions.BaselineTester,
        RoleDescriptions.ClinicFrontDesk
      ]}
      resourceIdParam="clinicId"
    >
      {children}
    </Authorization>
  );
};

const ClinicInvoicesRoutes = [
  {
    index: true,
    element: (
      <InvoiceAuthorization>
        <ClinicInvoices />
      </InvoiceAuthorization>
    )
  },
  {
    path: ':invoiceId/*',
    element: <InvoiceAuthorization />,
    children: [
      {
        index: true,
        element: <ClinicInvoice />
      },
      {
        path: 'pay',
        element: (
          <Authorization
            allowUntrained
            allowRolesMatchingResourceId={[
              RoleDescriptions.ClinicOwner,
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.BaselineTester,
              RoleDescriptions.ClinicFrontDesk
            ]}
            resourceIdParam="clinicId"
          >
            <InvoicePayment />
          </Authorization>
        )
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: '*',
    element: <PageNotFound />
  }
];

export default ClinicInvoicesRoutes;

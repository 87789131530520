import dayjs from 'dayjs';
import { sortDescending, sortAscending } from '../../utilities/sort';
import isEmpty from '../../utilities/is-empty';
import Strings from './lang';

export const DanaTests = {
  SimpleReactionTime: 'SRT',
  ProceduralReactionTime: 'PRT',
  CodeSubstitution: 'CS',
  SpatialProcessing: 'SP',
  GoNoGo: 'GNG',
  MatchToSample: 'MTS',
  MemorySearch: 'MS',
  PatientHealthQuestionnaire8: 'PHQ8',
  PatientHealthQuestionnaire9: 'PHQ9',
  InsomniaSeverityIndex: 'ISI',
  GeneralizedAnxietyDisorder7: 'GAD7'
};

export const BatteryType = {
  Quick: 'quick',
  Full: 'full'
};

export const TEST_COLORS = {
  [DanaTests.SimpleReactionTime]: ['#D57389', '#0C1826'],
  [DanaTests.ProceduralReactionTime]: ['#85293D'],
  [DanaTests.CodeSubstitution]: ['#33BFA0'],
  [DanaTests.SpatialProcessing]: ['#0255A5'],
  [DanaTests.GoNoGo]: ['#70D4BD'],
  [DanaTests.MatchToSample]: ['#C03A59'],
  [DanaTests.MemorySearch]: ['#299A7F'],
  [DanaTests.PatientHealthQuestionnaire8]: ['#DB231F'],
  [DanaTests.PatientHealthQuestionnaire9]: ['#DB231F'],
  [DanaTests.InsomniaSeverityIndex]: ['#FD9F37'],
  [DanaTests.GeneralizedAnxietyDisorder7]: ['#0461A0']
};

export const COGNITIVE = [
  DanaTests.SimpleReactionTime,
  DanaTests.ProceduralReactionTime,
  DanaTests.CodeSubstitution,
  DanaTests.SpatialProcessing,
  DanaTests.GoNoGo,
  DanaTests.MatchToSample,
  DanaTests.MemorySearch
];

export const PSYCH = [
  DanaTests.PatientHealthQuestionnaire8,
  DanaTests.PatientHealthQuestionnaire9,
  DanaTests.InsomniaSeverityIndex,
  DanaTests.GeneralizedAnxietyDisorder7
];

export const TEST_OPTIONS = [
  {
    label: Strings.allCognitiveTestsLabel,
    value: 'cognitive'
  },
  {
    label: Strings.srtLabel,
    value: DanaTests.SimpleReactionTime
  },
  {
    label: Strings.prtLabel,
    value: DanaTests.ProceduralReactionTime
  },
  {
    label: Strings.csLabel,
    value: DanaTests.CodeSubstitution
  },
  {
    label: Strings.spLabel,
    value: DanaTests.SpatialProcessing
  },
  {
    label: Strings.gngLabel,
    value: DanaTests.GoNoGo
  },
  {
    label: Strings.mtsLabel,
    value: DanaTests.MatchToSample
  },
  {
    label: Strings.msLabel,
    value: DanaTests.MemorySearch
  },
  {
    label: Strings.phq8Label,
    value: DanaTests.PatientHealthQuestionnaire8
  },
  {
    label: Strings.isiLabel,
    value: DanaTests.InsomniaSeverityIndex
  },
  {
    label: Strings.gad7Label,
    value: DanaTests.GeneralizedAnxietyDisorder7
  }
];

export const Metric = {
  ReactionTime: 'mean_rt',
  ReactionTimeDiff: 'rt_diff',
  CognitiveEfficiency: 'mean_thru',
  PercentCorrect: 'percent_correct',
  SurveyScore: 'survey_score'
};

export const COGNITIVE_METRICS_OPTIONS = [
  {
    value: Metric.ReactionTime,
    label: Strings.reactionTimeLabel
  },
  {
    value: Metric.CognitiveEfficiency,
    label: Strings.cognitiveEfficiencyLabel
  },
  {
    value: Metric.PercentCorrect,
    label: Strings.percentCorrectLabel
  }
];

const RT_DIFF_METRIC_OPTION = {
  value: Metric.ReactionTimeDiff,
  label: Strings.rtDifferenceMetricLabel
};

export const PSYCH_METRIC_OPTIONS = [
  {
    value: Metric.SurveyScore,
    label: Strings.surveyScoreLabel
  }
];

export const COGNITIVE_METRICS = COGNITIVE_METRICS_OPTIONS.map(metric => metric.value);
export const PSYCH_METRICS = PSYCH_METRIC_OPTIONS.map(metric => metric.value);

export const groupTestsByKey = (tests, isPostInjury = null) => {
  const obj = tests
    .sort(sortAscending.bind(null, 'created_at'))
    .reduce((acc, {
      test,
      battery_is_valid,
      post_injury = false,
      created_at,
      iteration = 1,
      ...rest
    }) => {
      if (
        (isPostInjury === null || post_injury === isPostInjury) 
        && battery_is_valid !== false
      ) {
        const values = acc[test] || [];
        return {
          ...acc,
          [test]: {
            ...values,
            [iteration]: [
              ...(values[iteration] || []),
              {
                ...rest,
                test,
                post_injury,
                iteration,
                created_at: dayjs(created_at).format('YYYY-MM-DD')
              }
            ]
          }
        };
      }

      return acc;
    }, {});

  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [key]: Object.values(obj[key])
    };
  }, {});
};

export const groupBaselineTestByKey = (baselineTests) => {
  if (isEmpty(baselineTests)) return {};

  const obj = baselineTests.reduce((acc, {
    test,
    created_at,
    iteration = 1,
    ...rest
  }) => {
    const values = acc[test] || [];
    return {
      ...acc,
      [test]: {
        ...values,
        [iteration]: {
          ...rest,
          iteration,
          created_at: dayjs(created_at).format('YYYY-MM-DD')
        }
      }
    };
  }, {});

  return Object.keys(obj).reduce((acc, key) => {
    return {
      ...acc,
      [key]: Object.values(obj[key])
    };
  }, {});
};

const RT_DIFF_TEST_TYPES = [DanaTests.SimpleReactionTime];

export const getTestMetrics = (key) => {
  if (key === 'cognitive' || COGNITIVE.includes(key)) {
    return RT_DIFF_TEST_TYPES.includes(key)
      ? [RT_DIFF_METRIC_OPTION, ...COGNITIVE_METRICS_OPTIONS]
      : COGNITIVE_METRICS_OPTIONS;
  }

  return PSYCH_METRIC_OPTIONS;
};

export const groupTestsByBattery = (tests = []) => {
  const batteries = tests.reduce((acc, danaTest) => {
    const {
      dana_battery_id,
      created_at,
      post_injury,
      testable_type,
      testable_id,
      battery_is_valid,
      battery_type,
      ...rest
    } = danaTest;

    return {
      ...acc,
      [dana_battery_id]: {
        id: dana_battery_id,
        post_injury,
        created_at,
        testable_type,
        testable_id,
        is_valid: battery_is_valid,
        battery_type,
        tests: [
          ...((acc[dana_battery_id] || {}).tests || []),
          rest
        ]
      }
    };
  }, {});

  return Object.values(batteries).sort(sortDescending.bind(null, 'created_at'));
};

export const filterBatteries = (batteries = [], isPostInjury = false) => {
  if (isPostInjury === null) {
    return batteries;
  }

  return batteries.filter(battery => battery.post_injury === isPostInjury);
};

/* eslint-disable no-magic-numbers */
const CognitiveTestPercentiles = {
  [DanaTests.SimpleReactionTime]: [95.0, 92.5],
  [DanaTests.ProceduralReactionTime]: [93.8],
  [DanaTests.GoNoGo]: [93.3],
  [DanaTests.CodeSubstitution]: [87.5],
  [DanaTests.MemorySearch]: [80.0],
  [DanaTests.MatchToSample]: [73.3],
  [DanaTests.SpatialProcessing]: [80.0]
};
/* eslint-enable no-magic-numbers */

const PERCENTILE_MIN = 66.0;

export const getPercentCorrectIconProps = (type, percent, iteration = 1) => {
  const max = CognitiveTestPercentiles[type]?.[iteration - 1] || 0;

  if (percent >= max) {
    return { name: 'check', className: 'success' };
  }

  if (percent < max && percent >= PERCENTILE_MIN) {
    return { name: 'minus', className: 'light' };
  }

  return { name: 'xmark', className: 'error' };
};

export const getTestValidityIconProps = (type, isValid, percent, iteration = 1) => {
  if (isValid === false) {
    return { name: 'xmark', className: 'error' };
  }

  return getPercentCorrectIconProps(type, percent, iteration);
};

export const MAX_PHQ9_SCORE = 27;
export const MAX_PHQ8_SCORE = 24;
export const MAX_ISI_SCORE = 28;
export const MAX_GAD7_SCORE = 21;

const withinThreshold = (value, threshold = {}) => {
  return value >= threshold.min && value <= threshold.max;
};

export const PHQ8Thresholds = {
  none: { min: 0, max: 4 },
  mild: { min: 5, max: 9 },
  moderate: { min: 10, max: 14 },
  moderatelySevere: { min: 15, max: 19 },
  severe: { min: 20, max: MAX_PHQ8_SCORE }
};

export const PHQ9Thresholds = {
  none: { min: 0, max: 4 },
  mild: { min: 5, max: 9 },
  moderate: { min: 10, max: 14 },
  moderatelySevere: { min: 15, max: 19 },
  severe: { min: 20, max: MAX_PHQ9_SCORE }
};

export const getPHQDescription = (value, thresholds) => {
  if (withinThreshold(value, thresholds.none)) {
    return Strings.phqStage1Description;
  }

  if (withinThreshold(value, thresholds.mild)) {
    return Strings.phqStage2Description;
  }

  if (withinThreshold(value, thresholds.moderate)) {
    return Strings.phqStage3Description;
  }

  if (withinThreshold(value, thresholds.moderatelySevere)) {
    return Strings.phqStage4Description;
  }

  if (withinThreshold(value, thresholds.severe)) {
    return Strings.phqStage5Description;
  }

  return '';
};

export const ISIThresholds = {
  none: { min: 0, max: 7 },
  subthreshold: { min: 8, max: 14 },
  moderatelySevere: { min: 15, max: 21 },
  severe: { min: 22, max: MAX_ISI_SCORE }
};

export const getISIDescription = (value) => {
  if (withinThreshold(value, ISIThresholds.none)) {
    return Strings.isiStage1Description;
  }

  if (withinThreshold(value, ISIThresholds.subthreshold)) {
    return Strings.isiStage2Description;
  }

  if (withinThreshold(value, ISIThresholds.moderatelySevere)) {
    return Strings.isiStage3Description;
  }

  if (withinThreshold(value, ISIThresholds.severe)) {
    return Strings.isiStage4Description;
  }

  return '';
};

export const GAD7Thresholds = {
  none: { min: 0, max: 4 },
  mild: { min: 5, max: 9 },
  moderate: { min: 10, max: 14 },
  severe: { min: 15, max: MAX_GAD7_SCORE }
};

export const getGAD7Description = (value) => {
  if (withinThreshold(value, GAD7Thresholds.none)) {
    return Strings.gad7Stage1Description;
  }

  if (withinThreshold(value, GAD7Thresholds.mild)) {
    return Strings.gad7Stage2Description;
  }

  if (withinThreshold(value, GAD7Thresholds.moderate)) {
    return Strings.gad7Stage3Description;
  }

  if (withinThreshold(value, GAD7Thresholds.severe)) {
    return Strings.gad7Stage4Description;
  }

  return '';
};

export const getPsychOutcomeDescription = (type, score) => {
  switch (type) {
    case DanaTests.PatientHealthQuestionnaire8:
      return getPHQDescription(score, PHQ8Thresholds);
    case DanaTests.PatientHealthQuestionnaire9:
      return getPHQDescription(score, PHQ9Thresholds);
    case DanaTests.InsomniaSeverityIndex:
      return getISIDescription(score);
    case DanaTests.GeneralizedAnxietyDisorder7:
      return getGAD7Description(score);
    default:
      return null;
  }
};

import React, { useCallback, useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import { FormInputGroup, CheckboxInputGroup, FormInputMessage } from '../../forms';
import Strings from './lang';

const acuteDiagnosisKeys = [
  'concussion_with_unconsciousness',
  'concussion_without_unconsciousness',
  'whiplash',
  'none',
  'other'
];

const chronicDiagnosisKeys = [
  'concussion_with_unconsciousness',
  'concussion_without_unconsciousness',
  'none',
  'other',
  'chronic_whiplash',
  'post_concussion_syndrome',
];

const getStateFromDiagnosis = (diagnosis) => {
  const {
    concussion_with_unconsciousness = false,
    concussion_without_unconsciousness = false,
    chronic_whiplash = false,
    post_concussion_syndrome = false,
    diagnosis_pcs = {},
    whiplash = false,
    none = false,
    other = false,
    notes = ''
  } = diagnosis;

  const {
    physiologic = false,
    cervicogenic = false,
    vestibulo_ocular = false,
    unknown_origin = false
  } = diagnosis_pcs;

  return {
    diagnosis: {
      concussion_with_unconsciousness,
      concussion_without_unconsciousness,
      post_concussion_syndrome,
      diagnosis_pcs: {
        physiologic,
        cervicogenic,
        vestibulo_ocular,
        unknown_origin
      },
      chronic_whiplash,
      whiplash,
      none,
      other
    },
    notes,
  };
};

export const isDiagnosisValid = (type, diagnosis) => {
  const keys = type === 'acute' ? acuteDiagnosisKeys : chronicDiagnosisKeys;
  return keys.some((key) => diagnosis[key] === true);
};

const Diagnosis = ({
  type = 'acute',
  diagnosis: propsDiagnosis = {},
  submitted = false,
  onUpdate = () => {},
  className
}) => {
  const { diagnosis, notes } = useMemo(() => (
    getStateFromDiagnosis(propsDiagnosis)
  ), [propsDiagnosis]);
  const isValid = useMemo(() => (
    isDiagnosisValid(type, diagnosis)
  ), [diagnosis, type]);

  const onDiagnosisUpdate = useCallback((key, subKey, e) => {
    const { checked } = e.target;
    const newDiagnosis = { ...diagnosis };

    if (subKey) {
      newDiagnosis[key][subKey] = checked;
    } else {
      newDiagnosis[key] = checked;
    }

    if (key !== 'none' && newDiagnosis.none) {
      newDiagnosis.none = false;
    } else if (key === 'none' && checked) {
      /** reset all diagnosis keys to false and leave the 'none' key checked */
      Object.keys(newDiagnosis).forEach(key => {
        if (key !== 'notes') {
          if (typeof newDiagnosis[key] === 'object') {
            Object.keys(newDiagnosis[key]).forEach(k => {
              newDiagnosis[key][k] = false;
            });
          } else {
            newDiagnosis[key] = key === 'none';
          }
        }
      });
    } else if (key === 'post_concussion_syndrome' && !checked) {
      Object.keys(newDiagnosis.diagnosis_pcs).forEach(key => {
        newDiagnosis.diagnosis_pcs[key] = false;
      });
    }

    onUpdate(({
      ...newDiagnosis,
      notes
    }), isDiagnosisValid(type, newDiagnosis));
  }, [diagnosis, notes, onUpdate, type]);

  const onUpdateNotes = useCallback((e) => {
    const { value } = e.target;
    onUpdate({
      ...diagnosis,
      notes: value
    }, isValid);
  }, [diagnosis, isValid, onUpdate]);

  return (
    <div className={className}>
      <FormInputMessage
        visible={submitted && !isValid}
        text={Strings.diagnosisErrorMessage}
        className="alert alert-danger"
      />
      <div className="row">
        <div className="col-md-12">

          <CheckboxInputGroup
            className="form-group"
            labelText={Strings.concussion_with_unconsciousness}
            inputProps={{
              className: 'form-control',
              checked: diagnosis.concussion_with_unconsciousness || false,
              onChange: (e) => onDiagnosisUpdate('concussion_with_unconsciousness', null, e)
            }}
            inputValid
          />

          <CheckboxInputGroup
            className="form-group"
            labelText={Strings.concussion_without_unconsciousness}
            inputProps={{
              className: 'form-control',
              checked: diagnosis.concussion_without_unconsciousness || false,
              onChange: (e) => onDiagnosisUpdate('concussion_without_unconsciousness', null, e)
            }}
            inputValid
          />

          <If condition={type === 'acute'}>
            <Then>
              <div>

                <CheckboxInputGroup
                  className="form-group"
                  labelText={Strings.whiplash}
                  inputProps={{
                    className: 'form-control',
                    checked: diagnosis.whiplash || false,
                    onChange: (e) => onDiagnosisUpdate('whiplash', null, e)
                  }}
                  inputValid
                />

              </div>

            </Then>
            <Else>
              <div>
                <CheckboxInputGroup
                  className="form-group"
                  labelText={Strings.post_concussion_syndrome}
                  inputProps={{
                    className: 'form-control',
                    checked: diagnosis.post_concussion_syndrome || false,
                    onChange: (e) => onDiagnosisUpdate('post_concussion_syndrome', null, e)
                  }}
                  inputValid
                />

                {diagnosis.post_concussion_syndrome && (
                  <div className="col-sm-offset-1">
                    <CheckboxInputGroup
                      className="form-group checkbox-inline"
                      labelText={Strings.physiologic}
                      inputProps={{
                        className: 'form-control',
                        checked: diagnosis.diagnosis_pcs.physiologic || false,
                        onChange: (e) => onDiagnosisUpdate('diagnosis_pcs', 'physiologic', e)
                      }}
                      inputValid
                    />

                    <CheckboxInputGroup
                      className="form-group checkbox-inline"
                      labelText={Strings.cervicogenic}
                      inputProps={{
                        className: 'form-control',
                        checked: diagnosis.diagnosis_pcs.cervicogenic || false,
                        onChange: (e) => onDiagnosisUpdate('diagnosis_pcs', 'cervicogenic', e)
                      }}
                      inputValid
                    />

                    <CheckboxInputGroup
                      className="form-group checkbox-inline"
                      labelText={Strings.vestibulo_ocular}
                      inputProps={{
                        className: 'form-control',
                        checked: diagnosis.diagnosis_pcs.vestibulo_ocular || false,
                        onChange: (e) => onDiagnosisUpdate('diagnosis_pcs', 'vestibulo_ocular', e)
                      }}
                      inputValid
                    />

                    <CheckboxInputGroup
                      className="form-group checkbox-inline"
                      labelText={Strings.unknown_origin}
                      inputProps={{
                        className: 'form-control',
                        checked: diagnosis.diagnosis_pcs.unknown_origin || false,
                        onChange: (e) => onDiagnosisUpdate('diagnosis_pcs', 'unknown_origin', e)
                      }}
                      inputValid
                    />
                  </div>
                )}

                <CheckboxInputGroup
                  className="form-group"
                  labelText={Strings.chronic_whiplash}
                  inputProps={{
                    className: 'form-control',
                    checked: diagnosis.chronic_whiplash || false,
                    onChange: (e) => onDiagnosisUpdate('chronic_whiplash', null, e)
                  }}
                  inputValid
                />
              </div>
            </Else>
          </If>

          <CheckboxInputGroup
            className="form-group"
            labelText={Strings.none}
            inputProps={{
              className: 'form-control',
              checked: diagnosis.none || false,
              onChange: (e) => onDiagnosisUpdate('none', null, e)
            }}
            inputValid
          />

          <CheckboxInputGroup
            className="form-group"
            labelText={Strings.other}
            inputProps={{
              className: 'form-control',
              checked: diagnosis.other || false,
              onChange: (e) => onDiagnosisUpdate('other', null, e)
            }}
            inputValid
          />
        </div>

      </div>
      <div>
        <FormInputGroup
          className="form-group"
          labelText={Strings.notes}
          inputType="textarea"
          inputProps={{
            rows: 5,
            className: 'form-control',
            value: notes,
            onChange: onUpdateNotes
          }}
          inputValid
        />
      </div>
    </div>
  );
};

export default Diagnosis;

import React, { useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import groupItems from '../../../utilities/group-items';
import ChecklistItem from '../../helpers/checklist-item';
import RedFlagStrings from '../sections/red-flags/lang';
import Strings from './lang';

const NUM_RED_FLAG_GROUPS = 2; // Should be a factor of 12 to fit the grid
const MAX_COLS = 12;

const groupRedFlagKeys = (redFlags = {}) => {
  const sortedKeys = Object.keys(redFlags).filter(key => { return key !== 'none'; }).sort();
  return groupItems(sortedKeys, NUM_RED_FLAG_GROUPS);
};

const filterCheckedFlags = (redFlags = {}) => {
  return Object.keys(redFlags).reduce((filtered, key) => {
    if (redFlags[key] === true) {
      return { ...filtered, [key]: redFlags[key] };
    }
      
    return filtered;
  }, {});
};

const PostInjuryRedFlags = ({
  redFlags = {}
}) => {
  const groupedKeys = useMemo(() => groupRedFlagKeys(filterCheckedFlags(redFlags)), [redFlags]);

  return (
    <div className="post-injury-section post-injury-red-flags">

      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.redFlagsTitle}</h2>
        </div>
      </div>

      <div className="post-injury-group">
        <If condition={redFlags.none !== true}>
          <Then>
            <div className="row">
              {groupedKeys.map((group, index) => (
                <div key={index} className={`col-md-${MAX_COLS / NUM_RED_FLAG_GROUPS}`}>
                  <ul className="list-unstyled">
                    {group.map(key => (
                      <ChecklistItem key={key} checked>
                        {RedFlagStrings[key]}
                      </ChecklistItem>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </Then>
          <Else>
            <div className="row">
              <div className="col-md-12">
                {Strings.defaultRedFlagsText}
              </div>
            </div>
          </Else>
        </If>
      </div>
    </div>
  );
};

export default PostInjuryRedFlags;

import React, { useEffect, useImperativeHandle } from 'react';
import { createPortal } from 'react-dom';
import { useUnmount } from '../../hooks';
import useNode from './useNode';

const Portal = React.forwardRef(({
  visible = false,
  className,
  node: parent = document.body,
  onMount = () => undefined,
  onUnmount = () => undefined,
  children
}, ref) => {
  const [node, { insert, destroy }, mounted] = useNode(parent, className);

  useImperativeHandle(ref, () => node, [node]);

  useEffect(() => {
    if (visible && !mounted) {
      insert(onMount);
    } else if (!visible && mounted) {
      destroy(onUnmount);
    }
  }, [visible, onMount, onUnmount, insert, destroy, mounted]);

  useUnmount(() => {
    destroy(onUnmount);
  });

  return visible && node ? createPortal(
    (
      <>
        {children}
      </>
    ),
    node
  ) : null;
});

export default Portal;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SelectInputGroup } from '../../forms';
import { withRouter } from '../../utilities/router-utils';
import {
  getCurrentUserId,
  getCurrentClinicId,
  setCurrentClinicId
} from '../../tokens';
import Strings from './lang';

class ClinicSelect extends Component {
  constructor(props) {
    super(props);

    const { currentUser = {} } = props;
    const clinicId = getCurrentClinicId() || 0;

    this.onSelectClinic = this.onSelectClinic.bind(this);

    this.state = {
      clinics: currentUser.clinics || [],
      clinicId
    };
  }

  render() {
    const { clinics } = this.state;
    if (clinics.length > 1) {
      return (
        <SelectInputGroup
          className="form-group clinic-select"
          inputProps={{
            className: 'form-control',
            value: this.state.clinicId
          }}
          onUpdate={this.onSelectClinic}
          inputValue
        >
          {
            clinics.map(clinic => {
              return (
                <option key={clinic.id} value={clinic.id}>
                  {clinic.name} {clinic.active === false && `(${Strings.deactivatedText})`}
                </option>
              );
            })
          }
        </SelectInputGroup>
      );
    }
      
    return null;
  }

  onSelectClinic(clinicId) {
    this.setState({ clinicId });
    setCurrentClinicId(clinicId);
    if (typeof this.props.onSelectClinic === 'function') {
      this.props.onSelectClinic(clinicId);
    }
  }
}

ClinicSelect.propTypes = {
  onSelectClinic: PropTypes.func
};

const mapStateToProps = (state) => {
  const id = getCurrentUserId();
  const { users } = state;
  return {
    currentUser: users[id] || {}
  };
};

const ConnectedClinicSelect = connect(
  mapStateToProps
)(ClinicSelect);

const RoutableClinicSelect = withRouter(ConnectedClinicSelect);

export default RoutableClinicSelect;

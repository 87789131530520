import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { PasswordsPaths, AuthenticationPaths } from '../../paths';
import Strings from './lang';

import {
  FormComponent,
  EmailInputGroup,
  PasswordInputGroup,
  CheckboxInputGroup,
  EmailValidator
} from '../../forms';

class AuthenticationForm extends Component {
  constructor(props) {
    super(props);

    const { email = '', portal = 'clinician' } = props;

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      email,
      portal,
      emailValid: EmailValidator.test(email),
      password: '',
      passwordValid: false,
      rememberEmail: false
    };
  }

  render() {
    return (
      <FormComponent className="authentication-form" onSubmit={this.onSubmit}>

        <If condition={this.props.displayCurrentEmail === true}>
          <Then>
            {/*
            Intentionally using the <a> tag instead of <Link> to redirect to authentication 
            because this link will be displayed outside of the router context when the 
            authentication form is displayed in the Lockout component. This will also 
            force a page refresh and clear out any remaining state before switching accounts.
            */}
            <p className="switch-accounts">
              <span>
                {Strings.formatString(Strings.switchAccountMessageText, this.state.email)}
              </span>
              <a href={AuthenticationPaths.index.link}>{Strings.switchAccountLinkText}</a>
            </p>
          </Then>
          <Else>
            <EmailInputGroup
              required
              className="form-group"
              labelText={Strings.emailLabelText}
              inputProps={{
                className: 'form-control',
                placeholder: Strings.emailInputPlaceholder,
                value: this.state.email
              }}
              onUpdate={(email, emailValid) => {
                this.setState({ email, emailValid });
              }}
              messageClassName="alert alert-danger"
              touched={this.state.submitted}
            />
          </Else>
        </If>

        <PasswordInputGroup
          className="form-group"
          labelText={Strings.passwordLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.passwordInputPlaceholder,
            value: this.state.password,
            disabled: this.state.activity
          }}
          onUpdate={(password, passwordValid) => {
            this.setState({ password, passwordValid });
          }}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <If condition={!!this.props.displayRememberMe && this.props.displayCurrentEmail !== true}>
          <Then>
            <CheckboxInputGroup
              inputValid
              className="form-group remember-email-input-group"
              labelText={Strings.rememberEmailLabelText}
              inputProps={{
                className: 'form-control',
                checked: this.state.rememberEmail,
                onChange: e => {
                  this.setState({
                    rememberEmail: e.target.checked
                  });
                }
              }}
            />
          </Then>
        </If>

        <div className="form-footer">
          <Link 
            to={PasswordsPaths.reset.index.link}
            state={this.props.resetPasswordState || {}}
          >
            {Strings.resetLinkText}
          </Link>
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.emailValid
      && this.state.passwordValid;
  }

  onSubmit(e) {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const { 
      email, password, portal, rememberEmail = false 
    } = this.state;
    const { onSubmit } = this.props;

    // Force blur the password input in case the user submitted by pressing enter.
    // We need to do this because we will clear the password field, which could in
    // turn trigger validation if the field is still focused.
    e.target.querySelector('input[type="password"]').blur();

    // Clear the password field and reset validation:
    this.setState({
      password: '',
      passwordValid: false,
      submitted: false
    });

    // Submit the user's credentials:
    if (typeof onSubmit === 'function') {
      onSubmit({ email, password, portal }, rememberEmail);
    }
  }
}

AuthenticationForm.propTypes = {
  email: PropTypes.string,
  displayCurrentEmail: PropTypes.bool,
  displayRememberMe: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default AuthenticationForm;

import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import withBaselineStep from './with-baseline-step';
import { PlayerInfoFullFormCard } from '../../user-profile';
import { updateUserAsync } from '../../../redux/thunks/users';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import Strings from './lang';

const BaselinePersonalStep = forwardRef((
  {
    user,
    next
  },
  ref
) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateUserAsync(user.id, attributes)).then(() => {
      setActivity(false);
      next();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, next, user.id]);

  return (
    <div>
      <ErrorBanner error={error} />
      <Activity active={activity}>
        <PlayerInfoFullFormCard
          ref={ref}
          cardTitle={Strings.confirmPatientInformationHeader}
          user={user}
          submitButtonText={Strings.confirmAndContinueText}
          onSubmit={onSubmit}
        />
      </Activity>
    </div>
  );
});

export default withBaselineStep(BaselinePersonalStep);

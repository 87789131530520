import groupItems from '../../utilities/group-items';
import Strings from './lang';

const OTHER_KEY = 'other';

const filterAndGroupCheckedDiagnosisKeys = (diagnosis = {}) => {
  // Filter all checked keys, omitting the 'other' key:
  const checkedKeys = Object.keys(diagnosis).filter(key => {
    return key !== OTHER_KEY && diagnosis[key] === true;
  });

  // Add the 'other' key to the end of the array if it is checked:
  if (diagnosis[OTHER_KEY] === true) {
    checkedKeys.push(OTHER_KEY);
  }

  const groupedKeys = groupItems(checkedKeys, 2);

  return { checkedKeys, groupedKeys };
};

const buildDiagnosisString = (diagnosis = {}) => {
  if (Object.keys(diagnosis).length === 0 || diagnosis.none === true) {
    return Strings.noDiagnosisFound;
  }

  const { checkedKeys } = filterAndGroupCheckedDiagnosisKeys(diagnosis);

  return checkedKeys.map(key => {
    let str = Strings[key];

    if (key === 'post_concussion_syndrome') {
      const pcs = diagnosis.diagnosis_pcs || {};
      const checkedPCSKeys = Object.keys(pcs).reduce((acc, pcsKey) => {
        return pcs[pcsKey] === true ? [
          ...acc,
          Strings[pcsKey]
        ] : acc;
      }, []);

      str = checkedPCSKeys.length > 0 ? `${str} (${checkedPCSKeys.join(', ')})` : str;
    }

    return str;
  }).join(', ');
};

export {
  filterAndGroupCheckedDiagnosisKeys,
  buildDiagnosisString
};

import { jsonApiRead } from '../../requests/jsonapi';
import { updateSymptomsUpdates } from '../actions/symptoms-updates';

const getSymptomUpdatesAsync = (userId, injuryId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/symptom_updates` };
  return (dispatch) => {
    return jsonApiRead({ type: 'symptom_updates' }, options).then(results => {
      dispatch(updateSymptomsUpdates(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getSymptomUpdatesAsync
};

import Favico from 'favico.js';
import Store from '../redux/store';
import { getCurrentUserId } from '../tokens';

const favicon = new Favico({
  position: 'up'
});

let previousNotificationsCount = 0;

const notificationWatcher = () => {
  const { users = {} } = Store.getState();
  const userId = getCurrentUserId();
  const user = users[userId];

  if (user && user.unread_notification_count !== previousNotificationsCount) {
    favicon.badge(user.unread_notification_count || 0);
    previousNotificationsCount = user.unread_notification_count;
  }
};

const registerFaviconNotificationWatcher = () => {
  Store.subscribe(notificationWatcher);
};

export default registerFaviconNotificationWatcher;

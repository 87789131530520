const FR = {
  resetTitle: 'Réinitialiser votre mot de passe',
  updateTitle: 'Mettre à jour votre mot de passe',
  authLinkText: 'Connexion',
  emailLabelText: 'Courriel :',
  emailInputPlaceholder: 'nom@exemple.com',
  passwordLabelText: 'Mot de passe',
  passwordInputPlaceholder: 'motdepasse123',
  confirmPasswordLabelText: 'Confirmer votre mot de passe',
  confirmPasswordInputPlaceholder: 'motdepasse123',
  newPasswordLabelText: 'Nouveau mot de passe',
  newPasswordErrorText: 'Les mots de passe doivent avoir au moins 6 caractères.',
  currentPasswordLabelText: 'Mot de passe actuel',
  currentPasswordErrorText: 'Veuillez écrire votre mot de passe actuel',
  resetButtonText: 'Réinitialiser le mot de passe',
  updateButtonText: 'Mettre à jour le mot de passe',
  updateDoneButtonText: 'Terminé',
  updatePasswordSuccessMessage: 'Votre mot de passe a été mis à jour',
  resetPasswordRequestSuccessMessage: 'Veuillez consulter vos courriels pour trouver le lien de réinitialisation de votre mot de passe',
  passwordResetSuccessful: 'Mot de passe réinitialisé avec succès'
};
 
export default FR;

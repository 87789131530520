import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ResourcesPaths } from '../../paths';
import { UserSelector } from '../../redux/selectors';
import { userHasAdminRole } from '../../utilities/user-roles';
import replaceLinkParams from '../../utilities/replace-link-params';
import { Page } from '../page';
import Breadcrumbs from './breadcrumbs';
import CourseVideoConfig from './videos-config';
import Strings from './lang';

const VideoIds = Object.keys(CourseVideoConfig);

const breadcrumbConfig = [
  {
    key: 'resources',
    link: ResourcesPaths.root.link,
    active: false,
    label: Strings.resourcesLabel
  },
  {
    key: 'courses',
    active: true,
    label: Strings.coursesLabel
  }
];

const getPermittedVideos = (user) => {
  const isAdmin = userHasAdminRole(user);

  if (isAdmin) return VideoIds;

  return VideoIds.reduce((acc, id) => (
    CourseVideoConfig[id].permitted(user) ? [...acc, id] : acc
  ), []);
};

const CourseListItem = ({
  id
}) => {
  const course = CourseVideoConfig[id];

  return (
    <div className="courses-list-item">
      <Link to={replaceLinkParams(ResourcesPaths.courses.course.link, { courseId: id })}>
        <div className="course-img">
          <img 
            src={`/images/courses/${course.img}`} 
            alt={course.name} 
          />
        </div>
        <div className="course-details">
          <div className="title"><strong>{course.name}</strong></div>
          <div>
            <small>{Strings.formatString(Strings.videoCount, course.videosCount)}</small>
          </div>
        </div>
      </Link>
    </div>
  );
};

const CoursesList = () => {
  const user = useSelector(UserSelector.getCurrentUser);
  const permittedIds = getPermittedVideos(user);

  return (
    <Page>
      <Breadcrumbs items={breadcrumbConfig} />
      <h1 className="display">
        {Strings.coursesTitle}
      </h1>
      <div className="courses-list">
        {permittedIds.map(id => (
          <CourseListItem key={id} id={id} />
        ))}
      </div>
    </Page>
  );
};

export default CoursesList;

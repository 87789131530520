import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { FormComponent, FormInputGroup } from '../../forms';
import AccessControl from '../access-control';
import { updateInvoiceAsync } from '../../redux/thunks/clinic-invoices';
import ErrorBanner from '../errors/error-banner';
import Activity from '../activity';
import { userHasAdminRole, AdminRoleDescriptions } from '../../utilities/user-roles';
import EditButton from '../buttons/edit-button';
import Strings from './lang';

const InvoiceNoteForm = ({
  note: invoiceNote = '',
  onSubmit
}) => {
  const [note, setNote] = useState(invoiceNote);

  const handleSubmit = useCallback(() => {
    onSubmit(note || null);
  }, [onSubmit, note]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <FormInputGroup 
        inputValid
        inputType="textarea"
        className="form-group"
        labelText={Strings.noteLabel}
        inputProps={{
          className: 'form-control',
          value: note || '',
          rows: 5,
          autoFocus: true,
          onChange: (e) => setNote(e.target.value)
        }}
      />
      <button 
        type="submit" 
        className="btn btn-primary"
      >
        {Strings.submitButtonText}
      </button>
    </FormComponent>
  );
};

const InvoiceNoteDisplay = ({
  note,
  editing,
  isAdmin,
  onToggleEdit,
  children
}) => {
  if (!note && !isAdmin) {
    return null;
  }

  return (
    <div className="invoice-note">
      <h3>
        <span>{Strings.invoiceMemoTitle}</span>
        <AccessControl roles={AdminRoleDescriptions}>
          <EditButton editing={editing} onClick={onToggleEdit} />
        </AccessControl>
      </h3>
      {children}
    </div>
  );
};

const InvoiceNote = ({ 
  clinicId,
  currentUser,
  invoice = {},
  updateNote
}) => {
  const [editing, setEditing] = useState(false);
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback(note => {
    setActivity(true);
    setError(null);

    updateNote(clinicId, invoice.id, note).then(() => {
      setActivity(false);
      setEditing(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [clinicId, invoice.id, updateNote]);

  return (
    <InvoiceNoteDisplay 
      editing={editing}
      isAdmin={userHasAdminRole(currentUser)}
      onToggleEdit={() => setEditing(!editing)}
    >
      {editing ? (
        <AccessControl roles={AdminRoleDescriptions}>
          <Activity active={activity}>
            <>
              <ErrorBanner error={error} />
              <InvoiceNoteForm 
                note={invoice.note}
                onSubmit={handleSubmit}
              />
              <span className="clearfix" />
            </>
          </Activity>
        </AccessControl>
      ) : (
        <p className="note">
          {invoice.note || (<i>{Strings.emptyMemoText}</i>)}
        </p>
      )}
    </InvoiceNoteDisplay>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateNote: (clinicId, invoiceId, note) => {
      return dispatch(updateInvoiceAsync(clinicId, invoiceId, { note }));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(InvoiceNote);

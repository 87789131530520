import React from 'react';
import { useSelector } from 'react-redux';
import Icon from '../icon';
import { getCurrentUserId, getCurrentClinicId } from '../../tokens';
import Strings from './lang';

const getIsClinicDeactivated = (state) => {
  const { users = {} } = state;
  const userId = getCurrentUserId();
  const currentUser = users[userId] || {};

  const clinicId = getCurrentClinicId();
  const userClinic = currentUser.clinics || [];
  const currentClinic = userClinic.find(clinic => clinic.id === clinicId);

  return currentClinic ? !currentClinic.active : false;
};

const ClinicDeactivatedBanner = ({ showIfDeactivated = true }) => {
  const deactivated = useSelector(getIsClinicDeactivated);

  if (!showIfDeactivated) {
    return null;
  }

  return deactivated ? (
    <div className="clinic-deactivated-banner-container">
      <div className="clinic-deactivated-banner">
        <div className="banner-content text-center">
          <h2>
            <Icon name="circle-exclamation" />&nbsp;&nbsp;{Strings.clinicDeactivatedInfoText}
          </h2>
        </div>
      </div>
    </div>
  ) : null;
};

export default ClinicDeactivatedBanner;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import roundToPrecision from '../../utilities/round-to-precision';
import Strings from './lang';

const formatDaysValue = (value) => {
  return Strings.formatString(
    (value !== 1) 
      ? Strings.daysValueText 
      : Strings.dayValueText, 
    value
  );
};

const ClinicStats = ({
  stats = {}, 
  showTitle = true
}) => {
  const { 
    baselines = {}, 
    current_open_injuries = 0, 
    average_discharge_time = {}, 
    injuries = {} 
  } = stats;
  const this_year = dayjs().format('YYYY');
  const last_year = dayjs().subtract(1, 'year').format('YYYY');

  return (
    <div className="clinic-patients-section clinic-patients-stats">
      {showTitle && <h2>{Strings.title}</h2>}

      <div className="row">
        <div className="col-md-6">
          <ul className="list-unstyled">
            <li><strong>{Strings.totalPatientsLabelText}</strong> {stats.total_patients || 0}</li>
            <li><strong>{Strings.totalInjuriesText}</strong> {injuries.total || 0}</li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled">
            <li><strong>{Strings.totalBaselineText}</strong> {baselines.total || 0}</li>
            <li><strong>{Strings.expiredBaselinesLabelText}</strong> {baselines.expired || 0}</li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <h3>{Strings.currentOpenInjuriesHeadingText} ({current_open_injuries.total || 0})</h3>
          <ul className="list-unstyled">
            <li>
              <strong>{Strings.acuteLabelText}</strong> {current_open_injuries.acute || 0}
            </li>
            <li>
              <strong>{Strings.subacuteLabelText}</strong> {current_open_injuries.subacute || 0}
            </li>
            <li>
              <strong>{Strings.chronicLabelText}</strong> {current_open_injuries.chronic || 0}
            </li>
          </ul>
        </div>
        <div className="col-md-6">
          <h3>{Strings.averageRecoveryTimeHeadingText}</h3>
          <ul className="list-unstyled">
            <li>
              <strong>{Strings.acuteLabelText}</strong>&nbsp;
              {formatDaysValue(roundToPrecision(average_discharge_time.acute || 0, 1))}
            </li>
            <li>
              <strong>{Strings.subacuteLabelText}</strong>&nbsp;
              {formatDaysValue(roundToPrecision(average_discharge_time.subacute || 0, 1))}
            </li>
            <li>
              <strong>{Strings.chronicLabelText}</strong>&nbsp;
              {formatDaysValue(roundToPrecision(average_discharge_time.chronic || 0, 1))}
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h3>{Strings.injuriesHeadingText}</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <ul className="list-unstyled">
            <li><strong>{this_year}:</strong> {injuries.this_calendar_year || 0}</li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled">
            <li><strong>{last_year}:</strong> {injuries.last_calendar_year || 0}</li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h3>{Strings.baselinesHeadingText}</h3>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <ul className="list-unstyled">
            <li><strong>{this_year}:</strong> {baselines.this_calendar_year || 0}</li>
          </ul>
        </div>
        <div className="col-md-6">
          <ul className="list-unstyled">
            <li><strong>{last_year}:</strong> {baselines.last_calendar_year || 0}</li>
          </ul>
        </div>
      </div>

    </div>
  );
};

ClinicStats.propTypes = {
  stats: PropTypes.object.isRequired,
  showTitle: PropTypes.bool
};

export default ClinicStats;

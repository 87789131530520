import React from 'react';
import dayjs from 'dayjs';
import { getInvoiceStatusClassName } from './utilities';
import { INPUT_DATE_FORMAT } from '../../dates/formats';
import Strings from './lang';

const getDate = (date) => {
  const momentDate = dayjs(date);
  return momentDate.isValid() 
    ? momentDate.format(INPUT_DATE_FORMAT)
    : null;
};

const InvoiceStatus = ({ status }) => {
  return (
    <small className={getInvoiceStatusClassName(status)}>
      <strong>{status.toUpperCase()}</strong>
    </small>
  );
};

const InvoicesHeader = ({ paid = false }) => (
  <tr>
    <th className="no-action">{Strings.invoiceDateLabel}</th>
    <th className="no-action">{Strings.titleLabel}</th>
    <th className="no-action">{Strings.invoiceStatusLabel}</th>
    <th className="no-action">{Strings.totalLabel}</th>
    <th className="no-action">{Strings.amountPaidLabel}</th>
    {paid ? null : (
      <th className="no-action">{Strings.balanceLabel}</th>
    )}
    <th className="no-action">
      {paid ? Strings.datePaidLabel : Strings.dueDateLabel}
    </th>
  </tr>
);

const InvoiceRow = ({
  paid = false,
  invoice = {},
  onClick = () => {}
}) => (
  <tr 
    key={invoice.id} 
    onClick={() => onClick(invoice)}
  >
    <td>{getDate(invoice.close_date || invoice.created_at) || '--'}</td>
    <td>{invoice.title}</td>
    <td><InvoiceStatus status={invoice.status} /></td>
    <td>{invoice.total}</td>
    <td>{invoice.amount_paid}</td>
    {paid ? null : (
      <td>{invoice.owing}</td>
    )}  
    <td>{getDate(paid ? invoice.date_paid : invoice.due_date) || '--'}</td>
  </tr>
);

const InvoicesTable = ({ 
  paid = false,
  invoices = [],
  onInvoiceClick = () => {}
}) => {
  return (
    <table className="table invoices-table">
      <thead>
        <InvoicesHeader paid={paid} />
      </thead>
      <tbody>
        {invoices.map(invoice => (
          <InvoiceRow 
            key={invoice.id}
            paid={paid}
            invoice={invoice}
            onClick={onInvoiceClick}
          />
        ))}
      </tbody>
    </table>
  );
};

export default InvoicesTable;

import { getLocalStorageItem, setLocalStorageItem } from '../../../utilities/local-storage';

const LINE_ITEMS_TABLE_SORT_KEYPATH_KEY = 'com.completeconcussions.lineitems.sort.key';

const getLineItemsSortKey = () => {
  return getLocalStorageItem(LINE_ITEMS_TABLE_SORT_KEYPATH_KEY) || '';
};

const setLineItemsTableSortKey = (keyPath = '') => {
  return setLocalStorageItem(LINE_ITEMS_TABLE_SORT_KEYPATH_KEY, keyPath);
};

export {
  getLineItemsSortKey,
  setLineItemsTableSortKey
};

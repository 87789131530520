import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';
import { EmailValidator } from '../../forms/validators/email-validator';
import { FormComponent, FormInputGroup, EmailInputGroup } from '../../forms';

class EditClinicianContactInfoCardForm extends Component {
  constructor(props) {
    super(props);

    const person = props.user.person || {};
    const phone = person.phone_number || '';
    const email = props.user.email || '';
    const emailValid = email.length === 0 || EmailValidator.test(email);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      phone,
      email,
      emailValid,
    };
  }

  render() {
    return (
      <FormComponent
        autoComplete="off"
        className="card card-form"
        onSubmit={this.onSubmit}
      >
        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.phoneNumberLabelText}
          type="tel"
          inputProps={{
            name: 'phone_number',
            className: 'form-control',
            value: this.state.phone,
            onBlur: this.onUpdateInput.bind(this, 'phone'),
            onChange: this.onUpdateInput.bind(this, 'phone')
          }}
          inputValid={this.state.phone.length > 0}
          messageText={Strings.phoneNumberValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <EmailInputGroup
          className="form-group person-email-input-group"
          labelText={Strings.emailLabelText}
          inputProps={{
            name: 'email',
            value: this.state.email,
            className: 'form-control',
            placeholder: Strings.emailInputPlaceholder
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  isValid() {
    return this.state.phone.length
      && this.state.emailValid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      phone,
      email,
      emailValid,
    } = this.state;

    const attributes = {
      email: (emailValid) ? email : '',
      person_attributes: {
        phone_number: phone,
      },
    };

    this.props.onSubmit(attributes);
  }
}

EditClinicianContactInfoCardForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EditClinicianContactInfoCardForm;

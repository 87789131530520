import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { updateClinicAsync } from '../../redux/thunks/clinics';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import Modal from '../modal';
import ClinicHoursForm from './clinic-hours-form';
import Strings from './lang';

const ClinicHoursEditModal = ({ 
  isOpen = false,
  hours,
  clinicId,
  onClose = () => {},
  updateClinic
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback((hours) => {
    setActivity(true);
    setError(null);

    const attributes = {
      business_hours: { regular: hours }
    };

    updateClinic(clinicId, attributes).then(() => {
      setActivity(false);
      onClose();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [clinicId, onClose, updateClinic]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <h1>{Strings.hoursModalTitle}</h1>
      </Modal.Header>
      <Modal.Body>
        <ErrorBanner error={error} />
        <Activity active={activity}>
          <ClinicHoursForm 
            hours={hours}
            onSubmit={handleSubmit}
          />
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClinic: (clinicId, attributes) => {
      return dispatch(updateClinicAsync(clinicId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ClinicHoursEditModal);

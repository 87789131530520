import React, { useMemo } from 'react';
import { getPaidAmount } from '../utilities';
import { Money } from '../../../utilities/currency';
import Strings from '../lang';

const LineItemsFooter = ({
  taxes = [],
  subTotal,
  total,
  payments,
  currency,
  balance
}) => {
  const totalPaid = useMemo(() => getPaidAmount(payments), [payments]);

  return (
    <>
      <tr className="line-items-table-footer"> 
        <td colSpan={5}>
          <strong>{Strings.subtotalLabel}</strong>
        </td>
        <td>{subTotal}</td>
      </tr>
      {taxes.map((tax, index) => (
        <tr key={index} className="line-items-table-footer">
          <td colSpan={5}>{tax.description}</td>
          <td>{tax.formatted}</td>
        </tr>
      ))}
      <tr className="line-items-table-footer">
        <td colSpan={5}>
          <strong>{Strings.totalLabel}</strong>
        </td>
        <td>{total}</td>
      </tr>
      <tr className="line-items-table-footer">
        <td colSpan={5}>
          <strong>{Strings.amountPaidLabel}</strong>
        </td>
        <td>{Money.format(totalPaid, currency)}</td>
      </tr>
      <tr className="line-items-table-footer balance-row">
        <td colSpan={5}>
          <strong>{Strings.balanceLabel}</strong>
        </td>
        <td><strong>{balance} {currency}</strong></td>
      </tr>
    </>
  );
};

export default LineItemsFooter;

import React, { useState } from 'react';
import { FormInputGroup } from '../../../forms';
import Dropdown, { MenuItem } from '../../dropdown';
import Icon from '../../icon';
import Strings from '../lang';

const CURRENCIES = ['USD', 'CAD', 'GBP', 'AUD', 'EUR'];

const PriceInput = ({
  value,
  currency = CURRENCIES[0],
  touched = false,
  onCurrencyChange = () => { },
  onChange = () => { }
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="input-group">
      <FormInputGroup
        required
        className="form-group"
        labelText={Strings.retailPriceLabel}
        inputType="number"
        inputProps={{
          className: 'form-control',
          value,
          onChange: (e) => onChange(e.target.value ? e.target.valueAsNumber : '')
        }}
        messageText={Strings.retailPriceErrorMessage}
        messageClassName="alert alert-danger"
        inputValid={value !== '' && value >= 0}
        touched={touched}
      />
      <div className="input-group-btn">
        <Dropdown
          portal
          isOpen={dropdownOpen}
          trigger={(
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setDropdownOpen(prev => !prev)}
            >
              <strong>{currency.toUpperCase()}</strong>&nbsp;&nbsp;
              <Icon name="chevron-down" />
            </button>
          )}
          onClose={() => setDropdownOpen(false)}
        >
          {CURRENCIES.map(key => (
            <MenuItem
              key={key}
              selected={key === currency}
              onSelect={() => onCurrencyChange(key)}
            >
              {key}
            </MenuItem>
          ))}
        </Dropdown>
      </div>
    </div>
  );
};

export default PriceInput;

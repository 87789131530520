import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { If, Then } from 'react-if';
import { FormComponent } from '../../../forms';
import Card from '../../card';
import { AddNoteButton } from '../../buttons';
import { TandemGaitTest } from '../test-components';
import HeaderStrings from '../lang';
import Strings from './lang';

const initialTrials = [
  { duration: '', failed: false },
  { duration: '', failed: false },
  { duration: '', failed: false },
];

const TandemGaitCard = forwardRef((
  {
    tandemGait = {},
    saveButtonText,
    onSubmit
  },
  ref
) => {
  const [trials, setTrials] = useState(() => (
    JSON.parse(JSON.stringify(tandemGait?.trials ?? initialTrials))
  ));
  const [notes, setNotes] = useState(() => tandemGait?.notes ?? '');
  const [submitted, setSubmitted] = useState(false);
  const submittable = typeof onSubmit === 'function';
  const isValid = useMemo(() => {
    return trials.every(trial => (
      trial.duration !== ''
        || trial.failed
    ));
  }, [trials]);

  const getAttributes = useCallback((validate = true) => {
    if (validate) {
      setSubmitted(true);

      if (!isValid) {
        return false;
      }
    }

    const attributes = {
      tandem_gait_test: {
        trials,
        notes
      }
    };

    return attributes;
  }, [isValid, notes, trials]);

  const handleSubmit = useCallback(() => {
    if (submittable) {
      setSubmitted(true);

      if (!isValid) {
        return;
      }

      onSubmit(getAttributes(false));
    }
  }, [getAttributes, isValid, onSubmit, submittable]);

  useImperativeHandle(ref, () => ({
    getAttributes
  }), [getAttributes]);

  return (
    <FormComponent formProps={{ noValidate: true }} onSubmit={handleSubmit}>
      <Card
        title={HeaderStrings.tandemGaitHeader}
        actionButtons={(
          <AddNoteButton
            note={notes}
            title={
              Strings.formatString(
                Strings.abStractNotesTitle,
                HeaderStrings.tandemGaitHeader
              )
            }
            submitButtonTitle={Strings.addNoteButtonText}
            onSave={setNotes}
          />
        )}
      >
        <div className="card card-form">
          <p>{Strings.timedTandemGaitInfo}</p>
          <p className="alert alert-info" dangerouslySetInnerHTML={{ __html: Strings.timedTandemGaitInstructions }} />
          <TandemGaitTest
            trials={trials}
            touched={submitted}
            onUpdate={setTrials}
          />
          {submitted && !isValid && (
            <p className="alert alert-danger">
              {Strings.tandemGaitSubmitError}
            </p>
          )}
          <If condition={submittable}>
            <Then>
              <div className="form-footer">
                <button type="submit" className="btn btn-primary">
                  {saveButtonText || Strings.saveButtonText}
                </button>
              </div>
            </Then>
          </If>
        </div>
      </Card>
    </FormComponent>
  );
});

export default TandemGaitCard;

import { 
  jsonApiRead, 
  jsonApiCreate, 
  jsonApiDelete, 
  jsonApiUpdate 
} from '../../requests/jsonapi';
import {
  updateClinicInvoices, 
  updateClinicInvoice, 
  deleteInvoiceLineItem, 
  addInvoiceLineItem,
  updateInvoiceLineItem
} from '../actions/clinic-invoices';
import { updateClinic } from '../actions/clinics';

const getInvoicesAsync = (clinicId, status) => {
  const queryParams = { status };
  const options = { path: `clinics/${clinicId}/invoices`, queryParams };

  return (dispatch) => {
    return jsonApiRead({ type: 'invoices' }, options).then(results => {
      dispatch(updateClinicInvoices(clinicId, results));
      return Promise.resolve(results);
    });
  };
};

const getInvoiceAsync = (clinicId, invoiceId) => {
  const options = { path: `clinics/${clinicId}/invoices/${invoiceId}` };

  return (dispatch) => {
    return jsonApiRead({ type: 'invoices' }, options).then(invoice => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createInvoiceAsync = (clinicId, attributes) => {
  const options = { path: `clinics/${clinicId}/invoices` };
  return dispatch => {
    return jsonApiCreate({ type: 'invoices', attributes }, options).then(invoice => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const updateInvoiceAsync = (clinicId, invoiceId, attributes) => {
  const options = { path: `clinics/${clinicId}/invoices/${invoiceId}` };
  return dispatch => {
    return jsonApiUpdate({ type: 'invoices', id: invoiceId, attributes }, options).then(invoice => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const createBulkCreditsAsync = (clinicId, attrs) => {
  const { invoiceId, ...rest } = attrs;
  const attributes = { type: 'Token', ...rest };
  const options = { 
    path: invoiceId 
      ? `clinics/${clinicId}/invoices/${invoiceId}/bulk_purchase_tokens` 
      : `clinics/${clinicId}/bulk_purchase_tokens` 
  };
  
  return (dispatch, getState) => {
    return jsonApiCreate({ type: 'invoices', attributes }, options).then(invoice => {
      const { clinics } = getState();
      const clinic = clinics[clinicId];
      
      if (clinic) {
        const clinicUpdate = {
          ...clinic,
          credits_available: (clinic.credits_available || 0) + attrs.quantity
        };

        dispatch(updateClinic(clinicUpdate));
      }

      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const createBulkFreeCreditsAsync = (clinicId, attributes) => {
  const options = { path: `clinics/${clinicId}/bulk_free_tokens` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'clinics', attributes }, options).then(invoice => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const createBulkImpactBaselinesAsync = (clinicId, attributes) => {
  const options = { path: `clinics/${clinicId}/bulk_impact_baselines` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'invoices', attributes }, options).then(invoice => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      return Promise.resolve(invoice);
    });
  };
};

const createPaymentAdjustmentAsync = (clinicId, invoiceId, attributes) => {
  const options = { path: `clinics/${clinicId}/invoices/${invoiceId}/adjustment` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'clinics', attributes }, options).then(invoice => {
      dispatch(updateClinicInvoice(clinicId, invoice));
      
      return Promise.resolve(invoice);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createInvoiceLineItemAsync = (clinicId, invoiceId, attributes) => {
  const options = { path: `clinics/${clinicId}/invoices/${invoiceId}/line_items` };
  return dispatch => {
    return jsonApiCreate({ type: 'line_items', attributes }, options).then(lineItem => {
      dispatch(addInvoiceLineItem(clinicId, invoiceId, lineItem));
      return Promise.resolve(lineItem);
    });
  };
};

const updateInvoiceLineItemAsync = (clinicId, invoiceId, lineItemId, attributes) => {
  const options = { path: `clinics/${clinicId}/invoices/${invoiceId}/line_items/${lineItemId}` };
  return dispatch => {
    return jsonApiUpdate({ type: 'line_items', attributes, id: lineItemId }, options).then(lineItem => {
      dispatch(updateInvoiceLineItem(clinicId, invoiceId, lineItem));
      return Promise.resolve(lineItem);
    });
  };
};

const deleteInvoiceLineItemAsync = (clinicId, invoiceId, lineItem) => {
  const options = { path: `clinics/${clinicId}/invoices/${invoiceId}/line_items/${lineItem.id}` };
  return dispatch => {
    return jsonApiDelete({ type: 'line_items' }, options).then(() => {
      dispatch(deleteInvoiceLineItem(clinicId, invoiceId, lineItem));
      return Promise.resolve();
    });
  };
};

export {
  getInvoicesAsync,
  getInvoiceAsync,
  createInvoiceAsync,
  updateInvoiceAsync,
  createBulkCreditsAsync,
  createBulkFreeCreditsAsync,
  createBulkImpactBaselinesAsync,
  createPaymentAdjustmentAsync,
  createInvoiceLineItemAsync,
  updateInvoiceLineItemAsync,
  deleteInvoiceLineItemAsync
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { FormComponent, FormInputGroup, SelectInputGroup } from '../../forms';
import { Sports, validateSport } from '../../sports';
import Strings from './lang';

const defaultState = {
  submitted: false,
  selectedSport: '',
  otherSport: '',
  country: '',
  region: '',
  city: ''
};

class SportsTeamSearchForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      ...defaultState
    };
  }

  render() {
    const {
      selectedSport,
      otherSport,
      country,
      region,
      city,
      submitted
    } = this.state;

    return (
      <FormComponent className="sports-team-search-form" onSubmit={this.onSubmit}>
        <h2>{Strings.teamSearchFormTitle}</h2>

        <SelectInputGroup
          className="form-group sport-input-group"
          labelText={Strings.sportLabelText}
          inputProps={{
            className: 'form-control',
            value: selectedSport
          }}
          onUpdate={(value) => this.onUpdateSelect('selectedSport', value)}
          messageText={Strings.sportErrorText}
          messageClassName="alert alert-danger"
          inputValid={selectedSport.length > 0}
          required
          touched={submitted}
        >
          <option value="">{Strings.sportSelectOptionText}</option>
          {Sports.map(sport => (
            <option key={sport.key} value={sport.key}>
              {sport.name}
            </option>
          ))}
          <option value="other">{Strings.otherOptionText}</option>
        </SelectInputGroup>

        <If condition={selectedSport === 'other'}>
          <Then>
            <FormInputGroup
              className="form-group other-sport-input-group"
              labelText={Strings.otherSportLabelText}
              inputType="text"
              inputProps={{
                className: 'form-control',
                value: otherSport,
                onChange: this.onUpdateField.bind(this, 'otherSport')
              }}
              messageText={Strings.otherSportErrorText}
              messageClassName="alert alert-danger"
              inputValid={validateSport(otherSport)}
              required
              touched={submitted}
            />

          </Then>
        </If>

        <FormInputGroup
          className="form-group country-input-group"
          labelText={Strings.countryLabelText}
          inputType="country"
          inputProps={{
            className: 'form-control',
            value: country,
            onChange: this.onUpdateSelect.bind(this, 'country')
          }}
          messageText={Strings.countryErrorText}
          messageClassName="alert alert-danger"
          inputValid={country.length > 0}
          required
          touched={submitted}
        />

        <FormInputGroup
          className="form-group region-input-group"
          labelText={Strings.regionLabelText}
          inputType="region"
          inputProps={{
            className: 'form-control',
            country,
            value: region,
            onChange: this.onUpdateSelect.bind(this, 'region'),
            disableWhenEmpty: true
          }}
          messageText={Strings.regionErrorText}
          messageClassName="alert alert-danger"
          inputValid={region.length > 0}
          required
          touched={submitted}
        />

        <FormInputGroup
          className="form-group city-input-group"
          labelText={Strings.cityLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: city,
            onChange: this.onUpdateField.bind(this, 'city'),
            disabled: country.length === 0 || region.length === 0
          }}
          messageText={Strings.cityErrorText}
          messageClassName="alert alert-danger"
          inputValid={city.length > 0}
          required
          touched={submitted}
        />

        <div className="form-footer">
          <button type="submit" className="btn btn-primary">
            {Strings.searchButtonText}
          </button>
        </div>

      </FormComponent>
    );
  }

  onUpdateField(field, e) {
    const { value } = e.target;
    this.setState({ [field]: value });
  }

  onUpdateSelect(field, value) {
    this.setState({ [field]: value });
  }

  isValid() {
    const {
      selectedSport,
      otherSport,
      country,
      region,
      city
    } = this.state;

    const sport = (selectedSport === 'other') ? otherSport : selectedSport;

    return sport.length > 0
      && country.length > 0
      && region.length > 0
      && city.length > 0;
  }

  onSubmit() {
    this.setState({
      submitted: true
    });

    if (!this.isValid()) {
      return;
    }

    const { 
      selectedSport, otherSport, country, region, city 
    } = this.state;
    const sport = (selectedSport === 'other') ? otherSport : selectedSport;
    const attributes = { 
      sport, country, state: region, city 
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

SportsTeamSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SportsTeamSearchForm;

import React from 'react';
import classNames from 'classnames';
import Languages from '../../languages';
import SelectInputGroup from './select-input-group';
import Strings from './lang';

const LanguageInputGroup = (props) => (
  <SelectInputGroup
    {...props}
    className={classNames('language-input-group', props.className)}
    labelText={props.labelText || Strings.languageLabelText}
    messageText={props.messageText || Strings.languageErrorText}
  >
    <option value="">{Strings.languageSelectValueText}</option>
    {
      Strings.getAvailableLanguages().map(lang => {
        return (
          <option key={lang} value={lang}>{Languages[lang]}</option>
        );
      })
    }
  </SelectInputGroup>
);

LanguageInputGroup.propTypes = SelectInputGroup.propTypes;

export default LanguageInputGroup;

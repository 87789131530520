import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { FormComponent } from '../../../forms';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import { ReactionTime } from '../test-components';
import Card from '../../card';
import { AddNoteButton } from '../../buttons';
import HeaderStrings from '../lang';
import Strings from './lang';

class ReactionTimeCard extends Component {
  constructor(props) {
    super(props);

    const { reaction = {} } = props;
    const { dominant_hand = '', test_results = [] } = reaction;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      dominant_hand,
      notes: reaction.notes || '',
      test_results: [...(test_results || [])], 
      submitted: false
    };
  }

  render() {
    const { dominant_hand, test_results, notes } = this.state;
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card 
          title={HeaderStrings.reactionTimeHeader} 
          actionButtons={(
            <AddNoteButton 
              note={notes}
              title={
                Strings.formatString(Strings.abStractNotesTitle, HeaderStrings.reactionTimeHeader)
              }
              submitButtonTitle={Strings.addNoteButtonText}
              onSave={(notes) => {
                this.setState({ notes });
              }}
            />
          )}
        >
          <div className="card card-show">
            <p className="text-muted">{Strings.reactionTimeInfoText}</p>
          </div>
          <ReactionTime 
            initialState={{
              dominant_hand,
              trials: test_results
            }}
            onUpdate={this.onUpdate}
            touched={this.state.submitted}
          />
          <If condition={typeof this.props.onSubmit === 'function'}>
            <Then>
              <div className="card form-footer">
                <button type="submit" className="btn btn-primary">
                  {this.props.saveButtonText || Strings.saveButtonText}
                </button>
              </div>
            </Then>
          </If>
        </Card>
      </FormComponent>
    );
  }

  onUpdate(results) {
    const { dominant_hand, trials } = results;

    this.setState({
      dominant_hand,
      test_results: trials,
      submitted: false
    });
  }

  isValid() {
    const { test_results, dominant_hand } = this.state;

    if (test_results === undefined) {
      return false;
    }

    if (dominant_hand === '') {
      return false;
    }

    for (let i = 0; i < test_results.length; i += 1) {
      if (test_results[i] === '') {
        return false;
      }
    }

    return true;
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const { dominant_hand, test_results, notes } = this.state;

    const attributes = {
      reaction_time_test: {
        dominant_hand,
        test_results,
        notes
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }
}

ReactionTimeCard.propTypes = {
  ...BaselineTestCardPropTypes,
  reaction: PropTypes.object
};

export default ReactionTimeCard;

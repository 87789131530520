import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from '../react-select';
import { getCurrentUserId } from '../../tokens';

const getClinicOptions = (clinics, currentClinic) => {
  const clinicOptions = clinics.map(clinic => ({ 
    value: clinic.id, 
    label: clinic.name
  }));

  if (currentClinic?.id && !clinicOptions.find(option => option.value === currentClinic.id)) {
    clinicOptions.push({ value: currentClinic.id, label: currentClinic.name });
  }

  return clinicOptions.sort((a, b) => {
    if (a.label !== b.label) {
      return (a.label > b.label) ? 1 : -1;
    }

    return 0;
  });
};

const ActiveClinicSelect = ({
  user = {},
  clinics = [],
  onSelectClinic
}) => {
  const { current_clinic: currentClinic = {} } = user || {};
  const options = useMemo(() => (
    getClinicOptions(clinics, currentClinic)
  ), [clinics, currentClinic]);

  const currentValue = useMemo(() => (
    options.find(option => option.value === currentClinic?.id)
  ), [currentClinic?.id, options]);

  return (
    <Select
      value={currentValue}
      options={options}
      onChange={onSelectClinic}
    />
  );
};

ActiveClinicSelect.propTypes = {
  user: PropTypes.object.isRequired,
  onSelectClinic: PropTypes.func
};

const mapStateToProps = (state) => {
  const { users = {} } = state;
  const user = users[getCurrentUserId()] || {};
  const clinics = user.clinics || [];

  return { clinics };
};

export default connect(
  mapStateToProps
)(ActiveClinicSelect);

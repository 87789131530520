import React from 'react';
import { SelectInputGroup } from '../../forms';
import symptomInputPropTypes from './symptom-input-proptypes';
import getFunctionOrDefault from '../../utilities/get-function-or-default';
import Strings from './lang';

/*!
 * Component is used with SOAP notes SymptomLevel component.
 * Renders a SelectInputGroup as the symptom severity selector.
 * The lowest level component in the symptoms hierarchy. 
 * Stateless component: all state gets passed up to parents and then
 * passed back in as props.
 */
const SymptomSelectInput = ({
  label,
  name,
  value,
  values,
  required,
  touched,
  className,
  onUpdate
}) => {
  const updateCallback = getFunctionOrDefault(onUpdate);

  return (
    <div className={className}>
      <SelectInputGroup
        className="symptom-select-input"
        labelText={label}
        labelProps={{
          className: 'bold'
        }}
        inputProps={{
          className: 'form-control',
          value: value.toString()
        }}
        onUpdate={(value) => updateCallback(name, value)}
        required={required}
        messageClassName="alert alert-danger"
        inputValid={value !== ''}
        touched={touched}
      >
        {required && <option value="">{Strings.selectScoreText}</option>}
        {values.map((value, i) => {
          return (<option key={i} value={value}>{value}</option>);
        })}
      </SelectInputGroup>
    </div>
  );
};

SymptomSelectInput.propTypes = symptomInputPropTypes;

export default SymptomSelectInput;

import { sortOnKeyPath } from '../../utilities/sort-keypath';
import {
  ADD_IDT_CLAIM_APPOINTMENT,
  ADD_IDT_CLAIM_BLOCK_REPORT,
  ADD_IDT_CLAIM_INITIAL_REPORT,
  SET_CLAIM_REPORT_APPROVED,
  SET_CLAIM_REPORT_COMPLETED,
  UPDATE_IDT_CLAIM,
  UPDATE_IDT_CLAIM_APPOINTMENT,
  ADD_CLAIM_DOCUMENT
} from '../actions/idt-claims';

const updateIdtClaimReducer = (state = {}, action = {}) => {
  const { claimId, claim = {} } = action;
  const { [claimId]: prevClaim = {} } = state;

  return {
    ...state,
    [claimId]: {
      ...prevClaim,
      ...claim
    }
  };
};

const addIdtClaimAppointmentReducer = (state = {}, action = {}) => {
  const { claimId, blockId, appointment } = action;
  const { [claimId]: claim = {} } = state;
  const { treatment_blocks = [] } = claim || {};
  const newBlocks = [...(treatment_blocks || [])];

  const blockIndex = newBlocks.findIndex((block) => String(block.id) === String(blockId));

  if (blockIndex >= 0) {
    newBlocks[blockIndex].appointments = sortOnKeyPath([
      ...newBlocks[blockIndex].appointments,
      appointment
    ], 'appt_at');
  }

  return claim?.id ? {
    ...state,
    [claimId]: {
      ...claim,
      treatment_blocks: sortOnKeyPath(newBlocks, 'order')
    }
  } : state;
};

const updateIdtClaimAppointmentReducer = (state = {}, action = {}) => {
  const { claimId, blockId, appointment } = action;
  const { [claimId]: claim = {} } = state;
  const { treatment_blocks = [] } = claim || {};
  const newBlocks = [...(treatment_blocks || [])];

  const blockIndex = newBlocks.findIndex((block) => String(block.id) === String(blockId));

  if (blockIndex >= 0) {
    const newAppts = [...newBlocks[blockIndex].appointments];

    const apptIndex = newAppts.findIndex((appt) => String(appt.id) === String(appointment.id));

    if (apptIndex >= 0) {
      newAppts[apptIndex] = {
        ...newAppts[apptIndex],
        ...appointment
      };

      newBlocks[blockIndex].appointments = sortOnKeyPath(newAppts, 'appt_at');
    }
  }

  return claim?.id ? {
    ...state,
    [claimId]: {
      ...claim,
      treatment_blocks: sortOnKeyPath(newBlocks, 'order')
    }
  } : state;
};

const addIdtInitialReportReducer = (state = {}, action = {}) => {
  const { claimId, report } = action;
  const { [claimId]: claim = {} } = state;

  return claim ? {
    ...state,
    [claimId]: {
      ...claim,
      initial_report: report
    }
  } : state;
};

const addIdtBlockReportReducer = (state = {}, action = {}) => {
  const { claimId, blockId, report } = action;
  const { [claimId]: claim = {} } = state;
  const { treatment_blocks = [] } = claim || {};
  const newBlocks = [...(treatment_blocks || [])];

  const blockIndex = newBlocks.findIndex((block) => String(block.id) === String(blockId));

  if (blockIndex >= 0) {
    newBlocks[blockIndex].report = report;
  }

  return claim ? {
    ...state,
    [claimId]: {
      ...claim,
      treatment_blocks: newBlocks
    }
  } : state;
};

const setClaimReportStatusReducer = (status, state = {}, action = {}) => {
  const { claimId, blockId } = action;
  const { [claimId]: claim = {} } = state;

  if (blockId) {
    const { treatment_blocks = [] } = claim || {};
    const newBlocks = [...(treatment_blocks || [])];
    const blockIndex = newBlocks.findIndex((block) => String(block.id) === String(blockId));

    if (blockIndex >= 0 && newBlocks[blockIndex].report) {
      newBlocks[blockIndex].report = {
        ...newBlocks[blockIndex]?.report,
        status
      };
    }

    return claim ? {
      ...state,
      [claimId]: {
        ...claim,
        treatment_blocks: newBlocks
      }
    } : state;
  }

  return claim?.initial_report ? {
    ...state,
    [claimId]: {
      ...claim,
      initial_report: {
        ...claim.initial_report,
        status
      }
    }
  } : state;
};

const setClaimReportCompletedReducer = (state = {}, action = {}) => {
  return setClaimReportStatusReducer('completed', state, action);
};

const setClaimReportApprovedReducer = (state = {}, action = {}) => {
  return setClaimReportStatusReducer('approved', state, action);
};

const addClaimDocumentReducer = (state = {}, action = {}) => {
  const { claimId, document } = action;
  const { [claimId]: claim } = state;

  return claim ? {
    ...state,
    [claimId]: {
      ...claim,
      documents: [
        ...(claim.documents ?? []),
        document
      ]
    }
  } : state;
};

const idtClaims = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_IDT_CLAIM:
      return updateIdtClaimReducer(state, action);
    case ADD_IDT_CLAIM_APPOINTMENT:
      return addIdtClaimAppointmentReducer(state, action);
    case UPDATE_IDT_CLAIM_APPOINTMENT:
      return updateIdtClaimAppointmentReducer(state, action);
    case ADD_IDT_CLAIM_INITIAL_REPORT:
      return addIdtInitialReportReducer(state, action);
    case ADD_IDT_CLAIM_BLOCK_REPORT:
      return addIdtBlockReportReducer(state, action);
    case SET_CLAIM_REPORT_COMPLETED:
      return setClaimReportCompletedReducer(state, action);
    case SET_CLAIM_REPORT_APPROVED:
      return setClaimReportApprovedReducer(state, action);
    case ADD_CLAIM_DOCUMENT:
      return addClaimDocumentReducer(state, action);
    default:
      return state;
  }
};

export default idtClaims;

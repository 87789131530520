import React from 'react';
import { If, Then, Else } from 'react-if';
import { useNavigate } from 'react-router-dom';
import InjuryHistoryListItem from './injury-history-list-item';
import QuickActions from '../quick-actions';
import ConcussionCountDisplay from './concussion-count-display';
import AccessControl from '../access-control';
import { UserPaths } from '../../paths';
import { RoleDescriptions } from '../../utilities/user-roles';
import replaceLinkParams from '../../utilities/replace-link-params';
import Strings from './lang';
import Icon from '../icon';

const ConcussionCount = ({
  count = null
}) => {
  if (!count) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <ConcussionCountDisplay
          previous={count.previous}
          withCCMI={count.withCCMI}
          total={count.total}
        />
      </div>
    </div>
  );
};

const InjuryHistory = ({
  injuries = [],
  user = {},
  enableTestLinks = false,
  enableAssessmentLinks = false,
  clinic,
  concussionCount,
  currentUser,
  onUpdateInjury
}) => {
  const navigate = useNavigate();

  return (
    <div className="user-injury-history">
      <div className="row">
        <div className="col-md-9">
          <h2>
            {Strings.title}
          </h2>
          <AccessControl 
            allowIfClinicDeactivated
            roles={[
              RoleDescriptions.Clinician
            ]}
          >
            <ConcussionCount count={concussionCount} />
          </AccessControl>
          <If condition={injuries.length > 0}>
            <Then>
              <ul className="list-unstyled injury-history">
                {
                  injuries.map((injury, index) => {
                    return (
                      <InjuryHistoryListItem
                        key={injury.id}
                        injuryNumber={injuries.length - index}
                        injury={injury}
                        user={user}
                        clinic={clinic}
                        currentUser={currentUser}
                        enableTestLinks={enableTestLinks}
                        enableAssessmentLinks={enableAssessmentLinks}
                        onUpdateInjury={onUpdateInjury}
                      />
                    );
                  })
                }
              </ul>
            </Then>
            <Else>
              <p>{Strings.emptyMessageText}</p>
            </Else>
          </If>
        </div>
        <div className="col-md-3 no-print">
          {user.active_injury_id === null && (
            <AccessControl 
              roles={[
                RoleDescriptions.Clinician,
                RoleDescriptions.Specialist
              ]}
            >
              <QuickActions>
                <li>
                  <button 
                    type="button"
                    className="btn btn-default" 
                    onClick={() => {
                      navigate(replaceLinkParams(UserPaths.injuries.new.link, {
                        userId: user.id
                      }));
                    }}
                  >
                    <Icon name="plus" />&nbsp;&nbsp;
                    {Strings.newInjuryLinkText}
                  </button>
                </li>
              </QuickActions>
            </AccessControl>
          )}
        </div>
      </div>
    </div>
  );
};

export default InjuryHistory;

import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import { Page } from '../page';
import ReferralTabs from './referral-tabs';
import { ClinicSelector, ReferralSelector } from '../../redux/selectors';
import { getClinicReferralsAsync } from '../../redux/thunks/referrals';
import { getClinicAsync } from '../../redux/thunks/clinics';
import { useMount } from '../../hooks';
import Strings from './lang';

const ClinicReferrals = ({ params = {} }) => {
  const dispatch = useDispatch();
  const clinic = useSelector(state => ClinicSelector.getClinic(state, { id: params.clinicId }));
  const referrals = useSelector(state => (
    ReferralSelector.getReferredToClinicReferrals(state, { referredToClinicId: params.clinicId })
  ));

  const [loading, setLoading] = useState(!referrals.length);
  const [error, setError] = useState(null);

  const getClinicReferrals = useCallback(() => {
    setLoading(true);
    setError(null);

    Promise.all([
      dispatch(getClinicReferralsAsync(params.clinicId)),
      !clinic?.id ? dispatch(getClinicAsync(params.clinicId)) : Promise.resolve({})
    ]).then(() => {
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [clinic?.id, params.clinicId, dispatch]);

  useMount(() => {
    if (params.clinicId) {
      getClinicReferrals();
    }
  });

  return (
    <Page className="clinic-referrals">
      <h1>{Strings.clinicReferralsTitle}</h1>
      <h2>{clinic?.name}</h2>
      <Activity active={loading} static={!referrals.length}>
        <ErrorBanner error={error} />
        <ReferralTabs
          referrals={referrals}
          linksEnabled={false}
        />
      </Activity>
    </Page>
  );
};

export default ClinicReferrals;

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Modal from '../../modal';
import { InitialAppointmentForm } from '../appointments';
import { ErrorBanner } from '../../errors';
import { showAlert } from '../../alert-notifications';
import { activateIdtClaimAsync } from '../../../redux/thunks/idt-claims';
import Strings from '../lang';

const ModalContent = ({ claimId, minApptDate, onClose }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = useCallback((data) => {
    setError(null);
    setSubmitting(true);

    const attributes = {
      appointment_attributes: {
        virtual: data.virtual,
        appt_at: dayjs(data.appt).format(),
        providers: data.providers
      }
    };

    dispatch(activateIdtClaimAsync(claimId, attributes)).then(() => {
      setSubmitting(false);
      onClose();
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3000,
        message: Strings.addInitialApptSuccessMessage
      });
    }).catch(error => {
      setError(error.message);
      setSubmitting(false);
    });
  }, [claimId, dispatch, onClose]);

  return (
    <>
      <Modal.Header onClose={submitting ? undefined : onClose}>
        <h2>{Strings.addInitialApptHeader}</h2>
      </Modal.Header>
      <Modal.Body>
        <ErrorBanner error={error} />
        <InitialAppointmentForm
          submitting={submitting}
          minApptDate={minApptDate}
          onSubmit={onSubmit}
        />
      </Modal.Body>
    </>
  );
};

const ActiveClaimModal = ({
  isOpen = false,
  claimId,
  minDate,
  onClose
}) => {
  return (
    <Modal
      blur
      className="claim-appointment-modal"
      isOpen={isOpen}
    >
      <ModalContent
        claimId={claimId}
        minApptDate={minDate}
        onClose={onClose}
      />
    </Modal>
  );
};

export default ActiveClaimModal;

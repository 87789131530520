import LineItemTypes from '../../../utilities/line-item-types';
import Strings from '../lang';

const getBulkItemDescription = (type, fallback = null) => {
  switch (type) {
    case LineItemTypes.Impact:
      return Strings.groupedImpactCodesLabel;
    case LineItemTypes.Credit:
      return Strings.groupedCreditsLabel;
    case LineItemTypes.MapleConsult:
      return Strings.groupedMapleConsultsLabel;
    case LineItemTypes.BulkImpact:
      return Strings.bulkImpactBaselinesLabel;
    case LineItemTypes.OverduePaymentFee:
      return Strings.latePaymentFeeBulkLabel;
    case LineItemTypes.PostInjuryImpact:
      return Strings.groupedPostInjuryImpactLabel;
    default:
      return fallback || type;
  }
};

export { 
  LineItemTypes, 
  getBulkItemDescription 
};

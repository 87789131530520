import React, { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import { Page } from '../page';
import ReferralsList from './referrals-list';
import { ClinicSelector, ReferralSelector, UserSelector } from '../../redux/selectors';
import { getReferralAsync } from '../../redux/thunks/referrals';
import { getCurrentClinicId, setCurrentClinicId } from '../../tokens';
import { getClinicAsync } from '../../redux/thunks/clinics';
import { useMount } from '../../hooks';
import { 
  makeRoleDescription, 
  RoleNames, 
  RoleResourceTypes, 
  userHasRoleMatchingOneOfDescriptions 
} from '../../utilities/user-roles';
import Strings from './lang';

const referralRoleDescriptions = (clinicId = 0) => ([
  makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
]);

const getShouldSwitchClinics = (clinic, referral, currentUser) => {
  return referral?.status === 'pending' 
    && clinic?.id !== referral?.referred_to_clinic?.id
    && userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      referralRoleDescriptions(referral?.referred_to_clinic?.id)
    );
};

const SwitchClinicAlert = ({ currentClinic, referral }) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const shouldSwitchClinics = useMemo(() => (
    getShouldSwitchClinics(currentClinic, referral, currentUser)
  ), [currentUser, referral, currentClinic]);

  const switchClinics = useCallback(() => {
    setCurrentClinicId(referral.referred_to_clinic.id);
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
  }, [referral?.referred_to_clinic?.id]);

  if (!referral?.id || !shouldSwitchClinics) {
    return null;
  }

  return (
    <p className="alert alert-info text-center">
      {Strings.formatString(Strings.switchClinicsMessage, referral?.referred_to_clinic?.name)}
      &nbsp;
      <button type="button" className="btn-link" onClick={switchClinics}>
        {Strings.switchClinicsButton}
      </button>
    </p>
  );
};

const Referral = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const clinic = useSelector(ClinicSelector.getCurrentClinic);
  const referral = useSelector(state => (
    ReferralSelector.getReferral(state, { id: params.referralId })
  ));
  const [loading, setLoading] = useState(!referral);
  const [error, setError] = useState(null);
  
  const getReferral = useCallback(() => {
    setLoading(true);
    setError(null);

    Promise.all([
      dispatch(getReferralAsync(params.referralId)),
      !clinic?.id ? dispatch(getClinicAsync(getCurrentClinicId())) : Promise.resolve({})
    ]).then(() => {
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [clinic?.id, dispatch, params.referralId]);

  useMount(() => {
    getReferral();
  });

  return (
    <Page className="clinic-referral">
      <h1>{Strings.referralTitle}</h1>
      <Activity active={loading} static={!referral}>
        <ErrorBanner error={error} />
        <SwitchClinicAlert 
          currentClinic={clinic}
          referral={referral}
        />
        {!!referral?.id && (
          <ReferralsList 
            referrals={[referral]}
            colums={1}
          />
        )}
      </Activity>
    </Page>
  );
};

export default Referral;

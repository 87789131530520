import React, { useCallback, useMemo, useState } from 'react';
import Modal from '../modal';
import { FormComponent, CheckboxInputGroup, FormInputGroup } from '../../forms';
import { Money } from '../../utilities/currency';
import Strings from './lang';
import Icon from '../icon';

const convertToCostCents = (cost) => {
  return parseInt(Math.round(parseFloat(cost) * 100), 10);
};

const getCostCents = (isDefault, cost) => {
  return isDefault ? null : convertToCostCents(cost);
};

const isValid = (costCents) => {
  return costCents === null || (!isNaN(costCents) && costCents >= 0);
};

const EditTokenCostForm = ({ 
  tokenCost,
  onSubmit = () => {} 
}) => {
  const [isDefault, setIsDefault] = useState(() => tokenCost === null);
  const [cost, setCost] = useState(() => (tokenCost === null ? '' : tokenCost));
  const [submitted, setSubmitted] = useState(false);
  const costCents = useMemo(() => getCostCents(isDefault, cost), [cost, isDefault]);
  const valid = useMemo(() => isValid(costCents), [costCents]);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!valid) {
      return;
    }

    const attributes = {
      token_cost_cents: costCents
    };

    onSubmit(attributes);
  }, [costCents, onSubmit, valid]);
  
  return (
    <FormComponent onSubmit={handleSubmit}>
      <CheckboxInputGroup
        inputValid
        className="form-group"
        labelText={Strings.defaultPriceCheckboxLabel}
        inputProps={{
          className: 'form-control',
          checked: isDefault,
          onChange: e => setIsDefault(e.target.checked)
        }}
      />
      {!isDefault && (
        <FormInputGroup 
          required
          inputType="text"
          className="form-group"
          labelText={Strings.creditPriceLabel}
          inputProps={{
            className: 'form-control',
            value: cost,
            onChange: (e) => setCost(e.target.value)
          }}
          messageText={Strings.creditPriceErrorMessage}
          messageClassName="alert alert-danger"
          inputValid={valid}
          touched={submitted}
        />
      )}
      <button 
        type="submit" 
        disabled={!valid}
        className="btn btn-primary"
      >
        {Strings.submitButtonText}
      </button>
    </FormComponent>
  );
};

const EditTokenCostModal = ({
  isOpen = false,
  tokenCost = null,
  currency,
  onClose = () => {},
  onSubmit = () => {}
}) => {
  const cost = useMemo(() => (
    tokenCost !== null ? Money.unformat(tokenCost, currency) : null
  ), [currency, tokenCost]);
  const handleSubmit = useCallback((attributes) => {
    onSubmit(attributes);
    onClose();
  }, [onClose, onSubmit]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <h1>{Strings.editCreditPriceLabel}</h1>
      </Modal.Header>
      <Modal.Body>
        <EditTokenCostForm 
          tokenCost={cost}
          onSubmit={handleSubmit} 
        />
      </Modal.Body>
    </Modal>
  );
};

const EditTokenCostButton = ({ tokenCost, onSubmit }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" className="edit-button" onClick={() => setOpen(true)}>
        <Icon name="pen-to-square" />
        {Strings.editButtonText}
      </button>
      <EditTokenCostModal 
        isOpen={open}
        tokenCost={tokenCost}
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default EditTokenCostButton;

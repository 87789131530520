import { getLocalStorageItem, setLocalStorageItem } from '../../utilities/local-storage';

const TEAM_LEADERS_TABLE_SORT_KEYPATH_KEY = 'com.completeconcussions.team.leaders.sort.key.path';

const getTeamLeadersTableSortKeyPath = () => {
  return getLocalStorageItem(TEAM_LEADERS_TABLE_SORT_KEYPATH_KEY) || '';
};

const setTeamLeadersTableSortKeyPath = (keyPath = '') => {
  return setLocalStorageItem(TEAM_LEADERS_TABLE_SORT_KEYPATH_KEY, keyPath);
};

export {
  getTeamLeadersTableSortKeyPath,
  setTeamLeadersTableSortKeyPath
};

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { getBuffaloTestAsync } from '../../redux/thunks/buffalo';
import { InjurySelector, UserSelector } from '../../redux/selectors';
import Activity from '../activity';
import BuffaloTestView from './buffalo-test-view';
import BuffaloTest from './buffalo-test';

const buffaloTestSelector = (userId, testId) => (state) => {
  const userTests = state.buffalo[userId] || {};
  return userTests[testId] || {};
};

const BuffaloTestDisplay = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);

  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));
  const test = useSelector(buffaloTestSelector(params.userId, params.buffaloId));

  const onEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const onTestSaved = useCallback(() => {
    setEditing(false);
    window.scrollTo(0, 0);
  }, []);

  const getBuffaloTest = useCallback((userId, injuryId, buffaloId) => {
    setActivity(true);
    setError(null);

    dispatch(getBuffaloTestAsync(userId, injuryId, buffaloId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!test?.id && params.userId && params.injuryId && params.buffaloId) {
      getBuffaloTest(params.userId, params.injuryId, params.buffaloId);
    }
  }, [getBuffaloTest, params.buffaloId, params.injuryId, params.userId, test?.id]);

  return (
    <div className="buffalo-test-display">
      <Activity active={activity}>
        <If condition={editing}>
          <Then>
            <BuffaloTest
              user={user}
              injury={injury}
              buffaloId={test.id}
              onSave={onTestSaved}
            />
          </Then>
          <Else>
            <BuffaloTestView
              user={user}
              injury={injury}
              test={test}
              error={error}
              onEditClick={onEdit}
            />
          </Else>
        </If>
      </Activity>
    </div>
  );
};

export default BuffaloTestDisplay;

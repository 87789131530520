import React, { useMemo } from 'react';
import classnames from 'classnames';
import { SelectInputGroup } from '../../forms';
import StageDescriptionLink from './stage-description-link';
import Strings from './lang';

const StageDetails = ({
  protocol,
  stage = ''
}) => {
  const { stages = [] } = protocol;
  const currentIndex = useMemo(() => (
    stages.findIndex(s => s.value === stage) 
  ), [stages, stage]);
  const { 
    bgClass, 
    label, 
    description, 
    document,
    selector,
    readMoreLabel = Strings.readMoreButtonText
  } = stages[currentIndex] || {};

  return (
    <div className="recovery-stage-details">
      <div className={classnames('stage-number', bgClass)}>
        {currentIndex + 1}
      </div>
      <div className="stage-details">
        <div className="caption primary">{label}</div>
        <div className="stage-description">
          <span>{description}</span>&nbsp;&nbsp;
          {document && (
            <StageDescriptionLink
              className="btn-read-more semi-bold"
              documentName={document}
              sportSelection={selector}
            >
              <i>{readMoreLabel}</i>
            </StageDescriptionLink>
          )}
        </div>
      </div>
    </div>
  );
};

const StageSelector = ({
  protocol,
  value,
  touched,
  finalStageEnabled,
  onChange
}) => {
  const stages = protocol.stages || [];

  return (
    <div className="recovery-stage-selector">
      <SelectInputGroup
        required={value !== null}
        className="form-group"
        labelText={Strings.stageSelectLabel}
        inputProps={{
          className: 'form-control',
          value: value || ''
        }}
        messageClassName="alert alert-danger"
        messageText={Strings.stageSelectErrorMessage}
        valid={value !== ''}
        touched={touched}
        onUpdate={onChange}
      >
        {value === '' && (
          <option value="">{Strings.selectStageDefaultOption}</option>
        )}
        {stages.map((stage, index) => (
          <option 
            key={stage.value} 
            value={stage.value}
            disabled={(index + 1) === stages.length && !finalStageEnabled}
          >
            {index + 1}. {stage.label}
          </option>
        ))}
      </SelectInputGroup>
      {value && (
        <StageDetails 
          stage={value} 
          protocol={protocol}
        />
      )}
    </div>
  );
};

export default StageSelector;

import React from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import { Link } from 'react-router-dom';
import { getCurrentClinicId } from '../../tokens';
import replaceLinkParams from '../../utilities/replace-link-params';
import { ClinicPaths, ReferralsPath } from '../../paths';
import Strings from './lang';

const pathForNotification = (notification = {}) => {
  const {
    link_object_type = '',
    link_object_id = 0,
    requisite_ids = {}
  } = notification;

  const {
    user_id = 0
  } = requisite_ids;

  switch (link_object_type) {
    case 'InjuryReport':
      return replaceLinkParams(ClinicPaths.patientProfile.reported.show.link, {
        clinicId: getCurrentClinicId(),
        userId: user_id,
        reportId: link_object_id
      });
    case 'Referral':
      return replaceLinkParams(ReferralsPath.show.link, {
        referralId: link_object_id
      });
    default:
      return '';
  }
};

const NotificationItem = ({
  notification = {},
  onNavigate,
  onDismiss
}) => {
  const path = pathForNotification(notification);
  return (
    <li className="notification-item alert alert-info">

      <If condition={path.length > 0}>
        <Then>
          <Link to={path} onClick={onNavigate}>
            {notification.message}
          </Link>
        </Then>
        <Else>
          <span>{notification.message}</span>
        </Else>
      </If>

      <button 
        type="button"
        className="edit-button" 
        style={{ marginLeft: 10 }}
        onClick={() => {
          onDismiss(notification.id);
        }}
      >
        {Strings.markAsReadButtonText}
      </button>

    </li>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onNavigate: PropTypes.func
};

export default NotificationItem;

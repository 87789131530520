import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { DISPLAY_DATE_TIME_FORMAT } from '../../dates/formats';
import Card from '../card';
import Strings from './lang';

const getReporter = (role, reporter) => {
  const { person = {} } = reporter;

  switch (role) {
    case 'player':
      return Strings.selfReportedText;
    case 'leader': /** intentional fall-through.  */
    default:
      return Strings.formatString(
        Strings.notSelfReportedText,
        `${person.first_name} ${person.last_name}`,
        reporter.email
      );
  }
};

const ProgressReport = ({
  report = {}, 
  user = {}
}) => {
  const person = user.person || {};
  
  return (
    <Card 
      title={Strings.formatString(
        Strings.progressReportCardTitle, 
        dayjs(report.created_at).format(DISPLAY_DATE_TIME_FORMAT)
      )}
    >
      <div className="card card-show">
        <div className="row">
          <div className="col-md-4">
            <label>{Strings.patientLabel}:&nbsp;</label>
            <span>{person.first_name} {person.last_name}</span>
          </div>
          <div className="col-md-4">
            <label>{Strings.reportedDurationLabel}:&nbsp;</label>
            <span>{dayjs(report.created_at).fromNow()}</span>
          </div>
          <div className="col-md-4">
            <label>{Strings.reportedByLabel}:&nbsp;</label>
            <span>{getReporter(report.reporter_role, report.reporter)}</span>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-12">
            <label>{Strings.descriptionLabel}:&nbsp;</label>
            <span>{report.status}</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

ProgressReport.propTypes = {
  user: PropTypes.object,
  report: PropTypes.object
};

export default ProgressReport;

import { jsonApiRead } from '../../requests/jsonapi';
import { updateAllClinicsStatistics } from '../actions/all-clinics-statistics';

const getAllClinicsStatisticsAsync = () => {
  return (dispatch) => {
    return jsonApiRead({ type: 'all_statistics' }, { path: 'clinics/statistics' }).then(stats => {
      dispatch(updateAllClinicsStatistics(stats));
      return Promise.resolve(stats);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getAllClinicsStatisticsAsync
};

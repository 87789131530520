import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AuthenticationPaths } from '../../paths';
import logout from './logout';
import Strings from './lang';
import Icon from '../icon';

const LogoutLink = ({
  className
}) => {
  const navigate = useNavigate();
  const handleClick = useCallback(() => {
    logout();
    navigate(AuthenticationPaths.index.link);
  }, [navigate]);

  return (
    <button 
      type="button"
      className={classNames('logout-link', className)}
      onClick={handleClick}
    >
      <Icon name="right-from-bracket" fixedWidth />
      {Strings.logoutText}
    </button>
  );
};

LogoutLink.propTypes = {
  className: PropTypes.string
};

export default LogoutLink;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import { BaselineConcentration } from '../test-components';
import { FormComponent } from '../../../forms';
import { AddNoteButton } from '../../buttons';
import Card from '../../card';
import HeaderStrings from '../lang';
import Strings from './lang';

class ConcentrationCard extends Component {
  constructor(props) {
    super(props);

    const { concentration = {} } = props;
    const {
      number_list = '',
      score = 0,
      months_score = '',
      months_duration
    } = concentration;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      number_list,
      score,
      months_score,
      months_duration,
      notes: concentration.notes || '',
      numbersCompleted: score > 0,
      submitted: false
    };
  }

  render() {
    const { 
      score,
      months_score,
      months_duration,
      notes,
      number_list 
    } = this.state;
    
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card 
          title={HeaderStrings.concentrationHeader}
          actionButtons={(
            <AddNoteButton 
              note={notes}
              title={
                Strings.formatString(Strings.abStractNotesTitle, HeaderStrings.concentrationHeader)
              }
              submitButtonTitle={Strings.addNoteButtonText}
              onSave={(notes) => {
                this.setState({ notes });
              }}
            />
          )}
        >
          <div className="baseline-card">
            <BaselineConcentration
              timedMonthsInReverse={this.props.timedMonthsInReverse}
              initialState={{
                numbersScore: score,
                monthsDuration: months_duration,
                monthsScore: months_score,
                numberList: number_list
              }}
              onUpdate={this.onUpdate}
              touched={this.state.submitted}
            />
          </div>
          <If condition={typeof this.props.onSubmit === 'function'}>
            <Then>
              <div className="card form-footer">
                <button type="submit" className="btn btn-primary">
                  {this.props.saveButtonText || Strings.saveButtonText}
                </button>
              </div>
            </Then>
          </If>
        </Card>
      </FormComponent>
    );
  }

  onUpdate(state) {
    const { 
      numbersScore,
      monthsScore,
      monthsDuration,
      numbersCompleted,
      numberList 
    } = state;

    this.setState({
      score: numbersScore,
      months_score: monthsScore,
      months_duration: monthsDuration,
      numbersCompleted,
      submitted: false,
      number_list: numberList
    });
  }

  isValid() {
    return this.state.months_score !== '' && this.state.numbersCompleted;
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const { 
      score,
      months_score,
      notes,
      number_list,
      months_duration
    } = this.state;

    const attributes = {
      concentration_test: {
        score,
        months_score,
        notes,
        number_list
      }
    };

    if (this.props.timedMonthsInReverse) {
      attributes.concentration_test.months_duration = months_duration;
    }

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    } 
  }
}

ConcentrationCard.propTypes = {
  ...BaselineTestCardPropTypes,
  concentration: PropTypes.object,
  timedMonthsInReverse: PropTypes.bool
};

export default ConcentrationCard;

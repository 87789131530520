import React, { Component } from 'react';
import classNames from 'classnames';
import prependUrlScheme from '../../utilities/prepend-url-scheme';
import { UrlValidator } from '../validators/url-validator';
import FormInputGroup from './form-input-group';
import Strings from './lang';

const validateUrl = (url, required) => (url.length === 0 && !required) || UrlValidator.test(url);

class UrlInputGroup extends Component {
  constructor(props) {
    super(props);

    const { required = false } = props;
    const value = this.mergeInputProps().value || '';
    const valid = validateUrl(value, required);

    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      valid
    };
  }

  render() {
    return (
      <FormInputGroup
        className={classNames('url-input-group', this.props.className)}
        labelText={this.props.labelText || Strings.urlLabelText}
        labelProps={this.props.labelProps}
        inputType="url"
        inputProps={this.mergeInputProps()}
        inputValid={this.state.valid}
        messageText={this.props.messageText || Strings.urlErrorText}
        messageClassName={this.props.messageClassName}
        required={this.props.required}
        touched={this.props.touched}
      />
    );
  }

  mergeInputProps() {
    return {
      ...(this.props.inputProps || {}),
      onBlur: this.onUpdate,
      onChange: this.onUpdate
    };
  }

  onUpdate(event) {
    const { required = false, onUpdate } = this.props;
    const { value } = event.target;
    const valid = validateUrl(value, required);

    this.setState({
      valid
    });

    // Prepend the URL scheme if the value is a valid URL.
    // Otherwise, pass the raw value to the parent.
    const url = (valid) ? prependUrlScheme(value) : value;

    if (typeof onUpdate === 'function') {
      onUpdate(url, valid);
    }
  }
}

UrlInputGroup.propTypes = FormInputGroup.propTypes;

export default UrlInputGroup;

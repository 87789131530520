import { jsonApiRead, jsonApiCreate } from '../../requests/jsonapi';
import { addBatch, updateBatch, updateBatches } from '../actions/batches';

const createBatchAsync = (attributes) => {
  const options = { path: 'batches' };
  return (dispatch) => {
    return jsonApiCreate({ type: 'batches', attributes }, options).then(result => {
      dispatch(addBatch(result));
      return Promise.resolve(result);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getBatchAsync = (batchId) => {
  const options = { path: `batches/${batchId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'batches' }, options).then(result => {
      dispatch(updateBatch(result));
      return Promise.resolve(result);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getBatchesAsync = () => {
  const options = { path: 'batches' };
  return (dispatch) => {
    return jsonApiRead({ type: 'batches' }, options).then(results => {
      dispatch(updateBatches(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createBatchAsync,
  getBatchAsync,
  getBatchesAsync
};

import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PanelControls from '../specialist-notes/panel-controls';
import SpecialistNotePanels from '../specialist-notes/specialist-notes-panels';
import { ErrorView, EmptyView } from './status-views';
import Activity from '../activity';
import { SpecialistNoteSelector, UserSelector } from '../../redux/selectors';
import { getSpecialistNotesAsync } from '../../redux/thunks/specialist-notes';
import { useMount } from '../../hooks';
import Strings from './lang';

const SpecialistNotesView = ({
  user,
  injury,
  noteId,
  clinicId
}) => {
  const dispatch = useDispatch();
  const panelRef = useRef();
  const notes = useSelector((state) => (
    SpecialistNoteSelector.getPreviousSpecialistNotes(state, {
      userId: user.id,
      injuryId: injury.id,
      noteId
    })
  ));
  const currentUser = useSelector(UserSelector.getCurrentUser);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(!notes.length);

  const loadNotes = useCallback(() => {
    setLoading(true);

    dispatch(getSpecialistNotesAsync(user.id, injury.id)).then(() => {
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch, injury.id, user.id]);

  useMount(() => {
    if (!notes.length) {
      loadNotes();
    }
  });

  if (error) {
    return (
      <ErrorView
        title={Strings.specialistListErrorMessage}
        error={error}
      />
    );
  }

  if (!loading && !notes.length) {
    return (
      <EmptyView
        message={Strings.noPreviousSpecialistNotesMessage}
      />
    );
  }

  return (
    <Activity static active={loading}>
      {notes?.length > 0 && (
        <div className="panel-controls">
          <PanelControls panelRef={panelRef} />
        </div>
      )}
      <SpecialistNotePanels
        ref={panelRef}
        sticky={60}
        user={user}
        injury={injury}
        notes={notes}
        clinicId={clinicId}
        actionable={false}
        currentUser={currentUser}
      />
    </Activity>
  );
};

export default SpecialistNotesView;

import React, { 
  useCallback, 
  useEffect, 
  useMemo, 
  useState 
} from 'react';
import classnames from 'classnames';
import Select from '../../components/react-select';
import FormInputMessage from './form-input-message';
import { useUniqueId } from '../../hooks';

const transformSelectValueIn = (value, options) => {
  return options.reduce((acc, option) => {
    return value.indexOf(option.value) >= 0
      ? [...acc, option]
      : acc;
  }, []);
};

const transformSelectValueOut = (values) => values.map(v => v.value);

const MultiSelectInputGroup = ({
  id: propsId = null,
  label = '',
  name,
  disabled,
  value = [],
  options = [],
  placeholder,
  className,
  required = false,
  closeOnSelect = false,
  messageClassName = 'alert alert-danger',
  messageText = '',
  menuPosition,
  touched: propsTouched = false,
  valid = true,
  onChange = () => {},
  isLoading = false,
  onMenuOpen,
  onMenuClose
}) => {
  const id = useUniqueId(propsId);
  const [touched, setTouched] = useState(propsTouched);
  const selectValue = useMemo(() => transformSelectValueIn(value, options), [value, options]);

  const handleChange = useCallback((values) => {
    const v = transformSelectValueOut(values);
    onChange(v);
  }, [onChange]);

  useEffect(() => {
    if (!touched && propsTouched !== touched) {
      setTouched(propsTouched);
    }
  }, [propsTouched, touched]);

  return (
    <div 
      className={classnames(
        'form-input-group form-group multi-select', 
        className, 
        { invalid: touched && !valid }
      )}
    >
      <label htmlFor={id}>
        {label}
        {required && (
          <span className="required">*</span>
        )}
      </label>
      <Select 
        isMulti
        id={id}
        name={name}
        disabled={disabled}
        value={selectValue}
        options={options}
        placeholder={placeholder}
        closeMenuOnSelect={closeOnSelect}
        onBlur={() => setTouched(true)}
        onChange={handleChange}
        isLoading={isLoading}
        menuPosition={menuPosition}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
      <FormInputMessage 
        className={messageClassName}
        visible={touched && !valid}
        text={messageText}
      />
    </div>
  );
};

export default MultiSelectInputGroup;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  getBuffaloTestAsync,
  createBuffaloTestAsync,
  updateBuffaloTestAsync
} from '../../redux/thunks/buffalo';
import { BuffaloTestV1Form } from './v1';
import { BuffaloTestV2Form } from './v2';
import { TestHeader } from '../test-header';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import Strings from './lang';

const DEFAULT_NEW_TEST_VERSION = 2;

const BuffaloForm = ({ test = {}, onSubmit }) => {
  const { buffalo_version = DEFAULT_NEW_TEST_VERSION } = test;
  let BuffaloTestForm = BuffaloTestV1Form;

  if (buffalo_version && buffalo_version > 1) {
    BuffaloTestForm = BuffaloTestV2Form;
  }

  return (
    <BuffaloTestForm
      buffalo={test}
      onSubmit={onSubmit}
    />
  );
};

const buffaloSelector = (userId, buffaloId) => state => {
  const userTests = state.buffalo[userId] || {};
  return userTests[buffaloId] || {};
};

const BuffaloTest = ({
  user = {},
  injury = {},
  buffaloId = null,
  assessmentId = null,
  soapId = null,
  currentUser,
  currentClinic,
  onSave
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const test = useSelector(buffaloSelector(user?.id, buffaloId));

  const updateBuffaloTest = useCallback((userId, injuryId, buffaloId, attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateBuffaloTestAsync(userId, injuryId, buffaloId, attributes)).then(test => {
      setActivity(false);

      if (onSave) {
        onSave('buffalo', test);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, onSave]);

  const createBuffaloTest = useCallback((userId, injuryId, attributes) => {
    const attrs = { ...attributes };

    setActivity(true);
    setError(null);

    if (soapId) {
      attrs.soap_note_id = soapId;
    } else if (assessmentId) {
      attrs.injury_assessment_id = assessmentId;
    } else {
      attrs.injury_id = injuryId;
    }

    dispatch(createBuffaloTestAsync(userId, injuryId, attrs)).then(test => {
      setActivity(false);
      if (onSave) {
        onSave('buffalo', test);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [assessmentId, dispatch, onSave, soapId]);

  const saveBuffaloTest = useCallback((attributes) => {
    if (buffaloId) {
      updateBuffaloTest(user?.id, injury?.id, buffaloId, attributes);
    } else {
      createBuffaloTest(user?.id, injury?.id, attributes);
    }
  }, [buffaloId, createBuffaloTest, injury?.id, updateBuffaloTest, user?.id]);

  const getBuffaloTest = useCallback((userId, injuryId, buffaloId) => {
    setLoading(true);
    setActivity(true);
    setError(null);

    dispatch(getBuffaloTestAsync(userId, injuryId, buffaloId)).then(() => {
      setActivity(false);
      setLoading(false);
    }).catch(error => {
      setActivity(false);
      setLoading(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!test?.id && user?.id && injury?.id && buffaloId) {
      getBuffaloTest(user?.id, injury?.id, buffaloId);
    }
  }, [buffaloId, getBuffaloTest, injury?.id, test?.id, user?.id]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h1 className="display">{Strings.title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <TestHeader
            user={user}
            injury={injury}
            className="buffalo-test-header"
            testDate={test.created_at || dayjs()}
            tester={test.clinic_user || currentUser}
            clinic={test.clinic || currentClinic}
          />
        </div>
      </div>
      <div className="row buffalo-test-container">
        <div className="col-md-12">
          <ErrorBanner error={error} />
          <Activity active={activity} static={loading}>
            <BuffaloForm
              test={test}
              onSubmit={saveBuffaloTest}
            />
          </Activity>
        </div>
      </div>
    </div>
  );
};

export default BuffaloTest;

import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import OrthostaticVitalSignsTestForm from './ovs-test-form';
import { TestHeader } from '../test-header';
import Strings from './lang';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import { getOVSTestAsync, createOVSTestAsync, updateOVSTestAsync } from '../../redux/thunks/ovs-tests';

const ovsTestSelector = (userId, ovsId) => state => {
  const userTests = state.ovsTests[userId] || {};
  return userTests[ovsId] || {};
};

const OrthostaticVitalSignsTest = ({
  user,
  injury,
  testId,
  assessmentId,
  soapId,
  currentClinic,
  currentUser,
  onSave
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const test = useSelector(ovsTestSelector(user?.id, testId));

  const getOVSTest = useCallback((userId, injuryId, ovsId) => {
    setLoading(true);
    setActivity(true);
    setError(null);

    dispatch(getOVSTestAsync(userId, injuryId, ovsId)).then(() => {
      setActivity(false);
      setLoading(false);
    }).catch(error => {
      setActivity(false);
      setLoading(false);
      setError(error.message);
    });
  }, [dispatch]);

  const updateOVSTest = useCallback((userId, injuryId, ovsId, attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateOVSTestAsync(userId, injuryId, ovsId, attributes)).then(test => {
      setActivity(false);

      if (onSave) {
        onSave('ovs', test);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, onSave]);

  const createOVSTest = useCallback((userId, injuryId, attributes) => {
    const attrs = { ...attributes };

    setActivity(true);
    setError(null);

    attrs.clinic_id = currentClinic?.id;

    if (soapId) {
      attrs.soap_note_id = soapId;
    } else if (assessmentId) {
      attrs.injury_assessment_id = assessmentId;
    }

    dispatch(createOVSTestAsync(userId, injuryId, attrs)).then(test => {
      setActivity(false);

      if (onSave) {
        onSave('ovs', test);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [assessmentId, currentClinic?.id, dispatch, onSave, soapId]);

  const onSubmit = useCallback(attributes => {
    if (test?.id) {
      updateOVSTest(user?.id, injury?.id, test.id, attributes);
    } else {
      createOVSTest(user?.id, injury?.id, attributes);
    }
  }, [createOVSTest, injury?.id, test.id, updateOVSTest, user?.id]);

  useEffect(() => {
    if (!test?.id && user?.id && injury?.id && testId) {
      getOVSTest(user?.id, injury?.id, testId);
    }
  }, [getOVSTest, injury?.id, test?.id, user?.id, testId]);

  return (
    <div className="ovs-test">
      <div className="ovs-test-header">
        <h1 className="display">{Strings.title}</h1>
        <h6><i>{Strings.subTitle}</i></h6>
      </div>
      <Activity active={activity} static={loading}>
        <TestHeader
          user={user}
          injury={injury}
          className="ovs-test-header"
          testDate={test?.created_at || dayjs()}
          tester={test?.clinic_user || currentUser}
          clinic={test?.clinic || currentClinic}
        />
        <ErrorBanner error={error} />
        <div className="ovs-test-description">
          <div className="title">{Strings.testInstructionsTitle}</div>
          <div>{Strings.testInstructions}</div>
        </div>
        <OrthostaticVitalSignsTestForm
          test={test}
          onSubmit={onSubmit}
        />
      </Activity>
    </div>
  );
};

export default OrthostaticVitalSignsTest;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getSoapNoteAsync } from '../../redux/thunks/soap-notes';
import { 
  InjurySelector, 
  SoapNoteSelector, 
  UserSelector 
} from '../../redux/selectors';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import SoapNotesFormDisplay from './soap-notes-form-display';
import { TestHeader } from '../test-header';
import Strings from './lang';

const SoapNoteDisplay = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector(state => UserSelector.getUser(state, params));
  const injury = useSelector(state => InjurySelector.getUserInjury(state, params));
  const soap = useSelector(state => SoapNoteSelector.getUserSoapNote(state, params));
  
  const getSoapNote = useCallback((userId, injuryId, soapId) => {
    setActivity(true);
    setError(null);

    dispatch(getSoapNoteAsync(userId, injuryId, soapId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!soap?.id && params.userId && params.injuryId && params.soapId) {
      getSoapNote(params.userId, params.injuryId, params.soapId);
    }
  }, [getSoapNote, params.injuryId, params.soapId, params.userId, soap?.id]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h1 className="display">
            {Strings.soapNotesTitle}
          </h1>
        </div>
      </div>

      <Activity active={activity} static>
        <div className="row soap-notes-display">
          <div className="col-md-12">
            <ErrorBanner error={error} />

            <div className="soap-content">

              <div className="row">
                <div className="col-md-12">
                  <TestHeader
                    user={user}
                    injury={injury}
                    className="soap-notes-header"
                    testDate={soap?.created_at || dayjs()}
                    tester={soap?.clinic_user}
                    clinic={soap?.clinic}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <SoapNotesFormDisplay
                    soap={soap}
                    userId={user?.id}
                    clinicId={params.clinicId}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </Activity>
    </div>
  );
};

export default SoapNoteDisplay;

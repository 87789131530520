import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { getProgressReportsAsync } from '../../redux/thunks/progress-reports';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '../../dates/formats';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import ProgressReport from './progress-report';
import Strings from './lang';
import { InjurySelector, UserSelector } from '../../redux/selectors';

const reportsSelector = (userId, injuryId) => state => {
  const userProgressReports = state.progressReports[userId] || {};
  return userProgressReports[injuryId] || [];
};

const ProgressReports = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const progressReports = useSelector(reportsSelector(params.userId, params.injuryId));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));

  const getProgressReports = useCallback((userId, injuryId) => {
    setActivity(true);
    setError(null);

    dispatch(getProgressReportsAsync(userId, injuryId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!progressReports?.length && params.userId && params.injuryId) {
      getProgressReports(params.userId, params.injuryId);
    }
  }, [getProgressReports, params.injuryId, params.userId, progressReports?.length]);

  return (
    <Activity active={activity}>
      <div className="progress-reports">
        <h2>
          {Strings.formatString(
            Strings.progressReportListSubtitle,
            dayjs(injury.injured_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)
          )}
        </h2>
        <ErrorBanner error={error} />
        <div>
          {progressReports.map(report => (
            <ProgressReport key={report.id} user={user} report={report} />
          ))}
        </div>
      </div>
    </Activity>
  );
};

export default ProgressReports;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import AccessControl from '../access-control';
import InviteUserButton from './invite-user-button';
import { getClinicAsync } from '../../redux/thunks/clinics';
import { getClinicUsersAsync } from '../../redux/thunks/clinic-users';
import { ClinicUsersTable, getClinicUsersTableSortKeyPath, setClinicUsersTableSortKeyPath } from '../clinic-users-table';
import { sortOnKeyPath } from '../../utilities/sort-keypath';
import { withRouter } from '../../utilities/router-utils';
import { 
  AdminRoleDescriptions, 
  makeRoleDescription,  
  RoleNames, 
  RoleResourceTypes 
} from '../../utilities/user-roles';
import Strings from './lang';

const makeInvitationRoles = (clinic) => {
  if (!clinic?.id) return [];
  return [
    ...AdminRoleDescriptions,
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinic.id),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinic.id),
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinic.id),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinic.id),
    ...(clinic.idt_provider ? [
      makeRoleDescription(RoleNames.IDTClinician, RoleResourceTypes.Clinic, clinic.id),
      makeRoleDescription(RoleNames.IDTFrontDesk, RoleResourceTypes.Clinic, clinic.id)
    ] : [])
  ];
};

class ClinicUsers extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.users.length !== state.sortedUsers.length) {
      return {
        sortedUsers: sortOnKeyPath(props.users, state.sortKeyPath)
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    const { users } = props;
    const sortKeyPath = getClinicUsersTableSortKeyPath();

    this.updateSortKeyPath = this.updateSortKeyPath.bind(this);

    this.state = {
      sortKeyPath,
      sortedUsers: sortOnKeyPath(users, sortKeyPath),
      activity: false,
      error: null
    };
  }

  componentDidMount() {
    this.getClinicAndUsers();
  }

  render() {
    const { clinic = {} } = this.props;
    const invitationRoles = makeInvitationRoles(clinic);

    return (
      <Page className="clinic-users">
        <div className="row">
          <div className="col-md-12">
            <h1 className="display">{clinic.name}</h1>
            <div className="subheading">
              <h2>{Strings.title}</h2>
              <AccessControl roles={invitationRoles}>
                <InviteUserButton clinic={clinic} />
              </AccessControl>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ErrorBanner error={this.state.error} />
            <Activity active={this.state.activity} static>
              <ClinicUsersTable
                clinic={clinic}
                users={this.state.sortedUsers}
                sortKeyPath={this.state.sortKeyPath}
                onUpdateSortKeyPath={this.updateSortKeyPath}
              />
            </Activity>
          </div>
        </div>
      </Page>
    );
  }

  updateSortKeyPath(sortKeyPath) {
    const { users } = this.props;
    setClinicUsersTableSortKeyPath(sortKeyPath);
    const sortedUsers = sortOnKeyPath(users, sortKeyPath);
    this.setState({ sortKeyPath, sortedUsers });
  }

  getClinicAndUsers() {
    const { 
      clinic = {}, getClinic, clinicId, getClinicUsers 
    } = this.props;
    const { sortedUsers = [] } = this.state;

    this.setState({
      activity: (sortedUsers.length === 0),
      error: null
    });

    const requests = [
      clinic.id ? Promise.resolve(clinic) : getClinic(clinicId),
      getClinicUsers(clinicId)
    ];

    Promise.all(requests).then(([, users]) => {
      const { sortKeyPath } = this.state;
      const sortedUsers = sortOnKeyPath(users, sortKeyPath);
      this.setState({
        activity: false,
        sortedUsers
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { params = {} } = ownProps;
  const { clinicId = 0 } = params;
  const { clinics = {}, clinicUsers = {} } = state;
  const clinic = clinics[clinicId] || {};
  const users = clinicUsers[clinicId] || [];
  
  return { clinicId, clinic, users };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClinic: id => {
      return dispatch(getClinicAsync(id));
    },
    getClinicUsers: id => {
      return dispatch(getClinicUsersAsync(id));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClinicUsers)
);

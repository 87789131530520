import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import Languages from '../../languages';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import Strings from './lang';
import { userHasRoleRequiringTraining, RoleDescriptions, userHasRoleMatchingDescription } from '../../utilities/user-roles';
import AccessControl from '../access-control';

const formatTrainingDate = (date) => {
  const trainingDate = dayjs(date);
  return trainingDate.isValid() ? trainingDate.format(DISPLAY_DATE_FORMAT) : 'No Training Date';
};

const ClinicianPersonalInfoCard = ({
  user = {}
}) => {
  const person = user.person || {};
  const credentials = person.credentials || {};
  const credentialsString = (credentials.items || []).join(', ');
  const showTrainingDate = useMemo(() => {
    return userHasRoleRequiringTraining(user) 
      || userHasRoleMatchingDescription(user, RoleDescriptions.BaselineTester);
  }, [user]);

  return (
    <div className="card card-show">
      <div className="card-row">
        <label>{Strings.nameLabelText}:</label>
        <span>{person.first_name} {person.last_name}</span>
      </div>

      <div className="card-row">
        <label>{Strings.languageLabelText}:</label>
        <span>{Languages[person.language]}</span>
      </div>

      <div className="card-row">
        <label>{Strings.bioLabelText}:</label>
        <span>{person.bio}</span>
      </div>

      <div className="card-row">
        <label>{Strings.credentialsLabelText}:</label>
        <span>{credentialsString}</span>
      </div>

      <If condition={showTrainingDate}>
        <Then>
          <AccessControl roles={[RoleDescriptions.SalesAdmin, RoleDescriptions.SuperAdmin]}>
            <div className="card-row">
              <label>{Strings.trainingDateLabelText}:</label>
              <span>{formatTrainingDate(user.trained_at)}</span>
            </div>
          </AccessControl>
        </Then>
      </If>
    </div>
  );
};

ClinicianPersonalInfoCard.propTypes = {
  user: PropTypes.object.isRequired
};

export default ClinicianPersonalInfoCard;

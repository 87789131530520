import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { getVOMSTestAsync } from '../../redux/thunks/voms';
import { InjurySelector, UserSelector } from '../../redux/selectors';
import VOMSTestView from './voms-test-view';
import VOMSTest from './voms-test';
import Activity from '../activity';

const vomsTestSelector = (userId, testId) => (state) => {
  const userTests = state.voms[userId] || {};
  return userTests[testId] || {};
};

const VOMSTestDisplay = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);

  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));
  const test = useSelector(vomsTestSelector(params.userId, params.vomsId));
  
  const onEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const onTestSaved = useCallback(() => {
    setEditing(false);
    window.scrollTo(0, 0);
  }, []);

  const getVomsTest = useCallback((userId, injuryId, vomsId) => {
    setActivity(true);
    setError(null);

    dispatch(getVOMSTestAsync(userId, injuryId, vomsId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!test?.id && params.userId && params.injuryId && params.vomsId) {
      getVomsTest(params.userId, params.injuryId, params.vomsId);
    }
  }, [getVomsTest, params.vomsId, params.injuryId, params.userId, test?.id]);

  return (
    <div className="voms-test-display">
      <Activity active={activity}>
        <If condition={editing}>
          <Then>
            <VOMSTest
              user={user}
              injury={injury}
              vomsId={test.id}
              onSave={onTestSaved}
            />
          </Then>
          <Else>
            <VOMSTestView
              user={user}
              injury={injury}
              test={test}
              error={error}
              onEditClick={onEdit}
            />
          </Else>
        </If>
      </Activity>
    </div>
  );
};

export default VOMSTestDisplay;

import React, { useState, useCallback, useMemo } from 'react';
import { FormComponent, PasswordInputGroup, FormInputGroup } from '../../../forms';
import Strings from './lang';

const isValid = (password, account) => {
  return password.length > 0
    && account.length > 0
    && !isNaN(Number(account));
};

const AddExistingChildForm = ({ onSubmit = () => {} }) => {
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const valid = useMemo(() => isValid(password, account), [account, password]);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);
    if (valid) {
      onSubmit(account, password);
    }
  }, [account, onSubmit, password, valid]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <FormInputGroup 
        required
        touched={submitted}
        className="form-group"
        labelText={Strings.accountNumberLabel}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: account,
          onChange: (e) => setAccount(e.target.value)
        }}
        inputValid={account.length > 0 && !isNaN(Number(account))}
        messageText={Strings.accountNumberErrorMessage}
        messageClassName="alert alert-danger"
      />

      <PasswordInputGroup
        required
        touched={submitted}
        className="form-group"
        labelText={Strings.accountPasswordLabel}
        inputProps={{
          className: 'form-control',
          placeholder: 'password',
          value: password
        }}
        onUpdate={(password) => setPassword(password)}
        messageClassName="alert alert-danger"
      /> 

      <button type="submit" disabled={!valid} className="btn btn-primary">
        {Strings.addExisitingAccountModalAddButton}
      </button>
    </FormComponent>
  );
};

export default AddExistingChildForm;

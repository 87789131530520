import { jsonApiRead, jsonApiUpdate } from '../../requests/jsonapi';
import { updateInjuryReports, updateInjuryReport } from '../actions/injury-reports';

const getInjuryReportsAsync = userId => {
  const options = { path: `users/${userId}/injury_reports` };
  return (dispatch) => {
    return jsonApiRead({ type: 'injury_reports' }, options).then(results => {
      dispatch(updateInjuryReports(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getInjuryReportAsync = (userId, reportId) => {
  const options = { path: `users/${userId}/injury_reports/${reportId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'injury_reports' }, options).then(results => {
      dispatch(updateInjuryReport(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateInjuryReportAsync = (userId, reportId, attributes) => {
  const options = { path: `users/${userId}/injury_reports/${reportId}` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'injury_reports', attributes }, options).then(results => {
      dispatch(updateInjuryReport(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getInjuryReportsAsync,
  getInjuryReportAsync,
  updateInjuryReportAsync
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { If, Then, Else } from 'react-if';
import Activity from '../../activity';
import { getClinicAsync } from '../../../redux/thunks/clinics';
import { getUserAsync } from '../../../redux/thunks/users';
import { 
  removeAllRegistrationSessionData, 
  getRegistrationCurrentUser, 
  getRegistrationReturning, 
  getCurrentRegistrationSession 
} from '../../../utilities/registration-session';
import { parseMarkdown } from '../../../utilities/parse-markdown';
import Strings from './lang';
import { Page } from '../../page';

//const CCMI_EMAIL = 'mailto:info@completeconcussions.com';
const CCMI_CLINIC_LINK = 'https://completeconcussions.com/find-a-clinic/';

const renderClinicInfo = (clinic = {}) => {
  const address = clinic.address || {};
  return (
    <ul className="list-unstyled">
      <li><strong>{clinic.name}</strong></li>
      {renderAddressLine(address.street_1)}
      {renderAddressLine(address.street_2)}
      {renderMultiAttributeAddressLine(address.city, address.state, address.country)}
      {renderAddressLine(address.postal_code)}
      <li><a href={`tel:${clinic.phone}`}>{clinic.phone}</a></li>
      <li><a href={`mailto:${clinic.email}`}>{clinic.email}</a></li>
    </ul>
  );
};

const renderAddressLine = (line) => {
  return (line && line.length) ? (<li>{line}</li>) : null;
};

const renderMultiAttributeAddressLine = (...args) => {
  return (<li>{args.filter(arg => typeof arg === 'string').join(', ')}</li>);
};

class RegistrationFinish extends Component {
  constructor(props) {
    super(props);

    const { currentUser = {} } = this.props;

    const currentClinic = currentUser.current_clinic || {};

    const clinicId = currentClinic.id || 0;
    const clinicLoaded = !currentClinic.address;
    const returning = getRegistrationReturning();

    this.state = {
      headerContent: '',
      footerContent: '',
      clinicId,
      clinic: currentClinic,
      activity: clinicLoaded && !!currentUser.id,
      returning
    };
  }

  componentDidMount() {
    this.setState({
      activity: true
    });

    this.getUserAndClinic().then(([currentUser = {}, clinic = {}]) => {
      const { returning } = this.state;
      const person = currentUser.person || {};
  
      const headerMarkdown = (returning) 
        ? Strings.returningHeaderMarkdown 
        : Strings.registerHeaderMarkdown;
      const headerContent = parseMarkdown(
        Strings.formatString(headerMarkdown, person?.first_name, person?.last_name)
      );
      const footerContent = parseMarkdown(Strings.footerMarkdown);

      this.setState({ 
        clinic,
        headerContent, 
        footerContent,
        activity: false
      });
    }).catch(() => {
      this.setState({ 
        activity: false 
      });
    });
  }

  componentWillUnmount() {
    // This prevents an issue where multiple users register at the clinic
    // but instead of each user going to /register, they hit the back button until
    // they reach "Personal Information" and override all the data from the previous
    // persons registration. This will place them back at the register screen if they 
    // navigate back (using browser back button) and wipe all the session
    // data from the previous registration.

    removeAllRegistrationSessionData();
    window.location.href = `${process.env.CCMI_UI_HOST}/register`;
  }

  render() {
    const { currentUser = {} } = this.props;
    const { children } = currentUser;
    const { returning, clinic } = this.state;
    let childNeedsClinic = false;

    return (
      <Page className="registration-finish" hideNavigationContent hideBannerIfClinicDeactivated>
        <div className="row">
          <div className="col-md-12">
            <h1 className="display">{(returning) ? Strings.returningTitle : Strings.registerTitle}</h1>
          </div>
        </div>
        <Activity active={this.state.activity} static>
          <div className="row">
            <div className="col-md-12">

              {/* Header content */}
              <div dangerouslySetInnerHTML={{ __html: this.state.headerContent }} />
              <If condition={children === undefined}>
                <Then>              
                  <div className="row">
                    <div className="col-md-3 col-md-offset-3">
                      <h2>{Strings.accountNumberLabelText}</h2>
                      <p><strong className="account-number">{currentUser.account}</strong></p>  
                    </div>
                    <div className="col-md-4">
                      <h2>{Strings.clinicAssignedLabelText}</h2>
                      <If condition={clinic.id !== undefined}>
                        <Then>
                          <div className="clinic-info">
                            {renderClinicInfo(clinic)}
                          </div>
                        </Then>
                        <Else>
                          <div className="clinic-info">
                            <h2>{Strings.clinicUnassignedLabelText}</h2>
                            <p>
                              <strong>
                                <a 
                                  target="_blank" 
                                  rel="noopener noreferrer" 
                                  href={CCMI_CLINIC_LINK}
                                >
                                  {Strings.contactLinkText}
                                </a>
                              </strong>
                            </p>
                          </div>
                        </Else>
                      </If>
                    </div>
                  </div>            
                </Then>
                <Else>
                  <div className="guardian-children">
                    {children && children.map((child, index) => {
                      const childClinics = child.clinics || [];
                      const childClinic = childClinics[0] || {};
                    
                      if (child.active === false) {
                        return null;
                      }

                      if (!childClinic.id) {
                        childNeedsClinic = true;
                      }

                      return (
                        <div key={index} className="guardian-child">
                          <h2>{`${child.person?.first_name ?? ''} ${child.person?.last_name}` ?? ''}</h2>
                          <hr />
                          <p>{Strings.childAccountNumberLabelText} <span><strong className="account-number">{child.account}</strong></span></p>
                          <h3>{Strings.childClinicAssignedLabelText}</h3>
                          <If condition={childClinic.id !== undefined}>
                            <Then>
                              <div className="clinic-info">
                                {renderClinicInfo(childClinic)}
                              </div>
                            </Then>
                            <Else>
                              <div className="clinic-info">
                                <p className="text-muted">
                                  <strong><i>{Strings.childClinicUnassignedLabelText}</i></strong>
                                </p>
                              </div>
                            </Else>
                          </If>
                        </div>
                      );
                    })}
                  </div>
                </Else>
              </If>

              <If condition={childNeedsClinic}>
                <Then>
                  <h3>One or more of your children do not currently have a clinic,&nbsp;
                    <strong>
                      <a 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        href={CCMI_CLINIC_LINK}
                      >
                        Find a clinic near you
                      </a>
                    </strong>
                  </h3>
                </Then>
              </If>

              {/* Footer content */}
              <div dangerouslySetInnerHTML={{ __html: this.state.footerContent }} />

              <p>
                <a 
                  className="btn btn-primary" 
                  href="https://completeconcussions.com" 
                  rel="noopener noreferrer" 
                  target="_blank"
                >
                  {Strings.footerLinkText}
                </a>
              </p>

            </div>
          </div>
        </Activity>
      </Page>
    );
  }

  getUserAndClinic() {
    const { clinicId = 0, clinic = {} } = this.state;
    const requests = [
      this.getCurrentUser()
    ];

    if (!clinic.address && clinicId) {
      requests.push(this.props.getClinic(clinicId));
    } else {
      requests.push(Promise.resolve(clinic));
    }

    return Promise.all(requests);
  }

  getCurrentUser() {
    const { currentUser = {}, currentUserId } = this.props;

    if (!currentUser.id) {
      return this.props.getUser(currentUserId);
    }

    return Promise.resolve(currentUser);
  }
}

const mapStateToProps = (state) => {
  const { users = {}, clinics = {} } = state;
  const currentUserId = getRegistrationCurrentUser() || 0;
  const currentUser = users[currentUserId] || {};
  const children = currentUser.children || [];

  return { 
    currentUser, clinics, children, currentUserId 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClinic: id => {
      return dispatch(getClinicAsync(id));
    },
    getUser: id => {
      const options = { headers: { Authorization: getCurrentRegistrationSession().token } };
      return dispatch(getUserAsync(id, options));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationFinish);

import React from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import DanaLabels from '../dana-labels';
import Icon from '../../icon';
import { DISPLAY_DATE_FORMAT } from '../../../dates/formats';
import Strings from '../lang';

const BatteryListItem = ({
  battery,
  selected = false,
  onClick
}) => {
  return (
    <li
      tabIndex={0}
      className={classnames('dana-test-list-row', { selected })}
      onKeyPress={(e) => e.key === 'Enter' && onClick()}
      onClick={onClick}
    >
      <div className="dana-test-details">
        <div className="dana-test-details-header">
          <strong>{dayjs(battery.created_at).format(DISPLAY_DATE_FORMAT)}</strong>
          {battery.is_valid === false && (
            <span className="label label-danger inverted">
              {Strings.invalidText}
            </span>
          )}
        </div>
        <DanaLabels battery={battery} />
      </div>
      <Icon name="chevron-right" />
    </li>
  );
};

export default BatteryListItem;

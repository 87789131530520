import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ClinicPaths } from '../../paths';
import replaceLinkParams from '../../utilities/replace-link-params';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import Strings from './lang';

const InjuryReportListTable = ({
  injuryReports = [], 
  userId, 
  clinicId
}) => (
  <table className="table">
    <tbody>
      {injuryReports.map(report => (
        <tr key={report.id}>
          <td>
            <Link 
              to={replaceLinkParams(ClinicPaths.patientProfile.reported.show.link, {
                clinicId,
                userId,
                reportId: report.id
              })}
            >
              {dayjs(report.created_at).format(DISPLAY_DATE_FORMAT)}
            </Link>
          
            {report.assessed && <span className="caption success">{Strings.assessedText}</span>}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

InjuryReportListTable.propTypes = {
  injuryReports: PropTypes.array,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clinicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InjuryReportListTable;

import React, { 
  useCallback, 
  useState, 
  useMemo, 
  useRef 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import dayjs from 'dayjs';
import { InjurySelector, UserSelector, SpecialistNoteSelector } from '../../redux/selectors';
import QuickActions from '../quick-actions';
import Activity from '../activity';
import PanelControls from './panel-controls';
import SpecialistNotesPanels from './specialist-notes-panels';
import { ErrorBanner } from '../errors';
import AddNoteButton from './add-note-button';
import { getSpecialistNotesAsync } from '../../redux/thunks/specialist-notes';
import replaceLinkParams from '../../utilities/replace-link-params';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../dates/formats';
import { useMount } from '../../hooks';
import Strings from './lang';
import { UserPaths } from '../../paths';

const SpecialistNotesList = () => {
  const panelRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useSelector(UserSelector.getCurrentUser);

  const notes = useSelector((state) => (
    SpecialistNoteSelector.getInjurySpecialistNotes(state, params)
  ));

  const hasNotes = notes.length > 0;

  const user = useSelector((state) => (
    UserSelector.getUser(state, params)
  ));

  const injury = useSelector((state) => (
    InjurySelector.getUserInjury(state, params)
  ));

  const injuryDate = useMemo(() => (
    dayjs(injury.injured_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)
  ), [injury.injured_at]);

  const loadSpecialistNotes = useCallback(() => {
    setLoading(true);

    dispatch(getSpecialistNotesAsync(user.id, injury.id)).then(() => {
      setLoading(false);
    }).catch(e => {
      setLoading(false);
      setError(e.message);
    });
  }, [dispatch, injury.id, user.id]);

  useMount(() => {
    loadSpecialistNotes();
  });

  return (
    <Activity active={loading} static={!hasNotes}>
      <div className="row">
        <div className="col-md-9">
          <div className="specialist-notes-list">
            <div className="specialist-notes-list-header">
              <h2>{Strings.formatString(Strings.specialistNotesListSubtitle, injuryDate)}</h2>
              {hasNotes && (
                <PanelControls panelRef={panelRef} />
              )}
            </div>
            <ErrorBanner error={error} />
            <div className="specialist-notes-list-content">
              <If condition={hasNotes}>
                <Then>
                  <SpecialistNotesPanels 
                    ref={panelRef}
                    notes={notes}
                    user={user}
                    injury={injury}
                    actionable={injury.status === 'ongoing'}
                    currentUser={currentUser}
                    onEdit={(noteId) => {
                      navigate(replaceLinkParams(UserPaths.injuries.specialistNote.edit.link, {
                        userId: user.id,
                        injuryId: injury.id,
                        noteId
                      }));
                    }}
                  />
                </Then>
                <Else>
                  {!error && (
                    <p className="alert alert-info">
                      {Strings.specialistNotesListEmptyMessage}
                    </p>
                  )}
                </Else>
              </If>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <QuickActions>
            <li>
              <AddNoteButton 
                visible={injury.status === 'ongoing'}
                onClick={() => {
                  navigate(replaceLinkParams(UserPaths.injuries.specialistNote.new.link, {
                    userId: user.id,
                    injuryId: injury.id
                  }));
                }}
              />
            </li>
          </QuickActions>
        </div>
      </div>
    </Activity>
  );
};

export default SpecialistNotesList;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserSelector } from '../../../redux/selectors';
import { RoleDescriptions, userHasRoleMatchingDescription, userHasRoleMatchingOneOfDescriptions } from '../../../utilities/user-roles';
import Modal from '../../modal';
import Strings from './lang';
import Icon from '../../icon';

const ProfileItems = {
  Photo: 'photo',
  Name: 'name',
  Profession: 'profession',
  Credentials: 'credentials',
  Bio: 'bio',
  BookingLinks: 'booking'
};

const ProfileItemStrings = {
  [ProfileItems.Name]: `${Strings.firstNameLabelText} & ${Strings.lastNameLabelText}`,
  [ProfileItems.Bio]: Strings.bioLabelText,
  [ProfileItems.Credentials]: Strings.credentialsText,
  [ProfileItems.Profession]: Strings.professionLabelText,
  [ProfileItems.BookingLinks]: Strings.bookingTitle,
  [ProfileItems.Photo]: Strings.profilePhotoLabel
};

const DEFAULT_PHOTO_URL = 'https://s3.ca-central-1.amazonaws.com/ccmi-global/images/default_profile.png';

const getIncompleteItems = (user) => {
  const { person, clinical_info, booking_links } = user || {};
  const isClinician = userHasRoleMatchingOneOfDescriptions(
    user,
    [RoleDescriptions.Clinician, RoleDescriptions.Specialist]
  );
  const isIDTClinician = userHasRoleMatchingDescription(
    user,
    RoleDescriptions.IDTClinician
  );
  const items = [];

  if (!person?.first_name || !person?.last_name) {
    items.push(ProfileItems.Name);
  }

  if (isClinician || isIDTClinician) {
    if (!person?.bio && isClinician) {
      items.push(ProfileItems.Bio);
    }
  
    if (!person?.credentials) {
      items.push(ProfileItems.Credentials);
    }
    
    if (person?.photo_url === DEFAULT_PHOTO_URL && isClinician) {
      items.push(ProfileItems.Photo);
    }
  
    if (!clinical_info || !clinical_info?.profession) {
      items.push(ProfileItems.Profession);
    }

    if (!booking_links?.length && isClinician) {
      items.push(ProfileItems.BookingLinks);
    }
  }

  return items;
};

const CompletionModalContent = () => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const incomplete = useMemo(() => getIncompleteItems(currentUser), [currentUser]);

  return (
    <Modal.Body>
      <div>
        {Strings.completionModalMessage} 
      </div>
      <ul className="list-unstyled completion-items">
        {incomplete.map(key => (
          <li key={key}>
            <Icon name="circle-xmark" />
            <span className="item-label">{ProfileItemStrings[key]}</span>
          </li>
        ))}
      </ul>
    </Modal.Body>
  );
};

const CompletionModal = ({ 
  isOpen = false, 
  onClose 
}) => {
  return (
    <Modal
      blur
      className="profile-completion-modal"
      isOpen={isOpen}
    >
      <Modal.Header>
        <h1>{Strings.completeTitle}</h1>
      </Modal.Header>
      <CompletionModalContent />
      <Modal.Footer>
        <button 
          type="button" 
          className="btn btn-primary btn-sm"
          onClick={onClose}
        >
          {Strings.submitButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompletionModal;

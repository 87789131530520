import { createSelector } from 'reselect';
import { sortDescending, sortDateDescending } from '../../utilities/sort';
import { Money } from '../../utilities/currency';
import LineItemTypes from '../../utilities/line-item-types';

const getClinicInvoices = (state, props) => state.clinicInvoices[props.clinicId] || {};
const getClinicInvoice = (state, props) => (
  getClinicInvoices(state, props)[props.invoiceId] || {}
);
const getSortKey = (_, props) => props.sortKey;

const getOutstandingInvoices = createSelector(
  [getClinicInvoices],
  (clinicInvoices) => {
    return Object.values(clinicInvoices)
      .filter((invoice => invoice.status !== 'paid'));
  }
);

const getPaidInvoices = createSelector(
  [getClinicInvoices],
  (clinicInvoices) => {
    return Object.values(clinicInvoices)
      .filter((invoice => invoice.status === 'paid'));
  }
);

const getTotalBalanceOwing = createSelector(
  [getOutstandingInvoices],
  (invoices) => {
    let invoiceCurrency = 'CAD';

    const total = invoices.reduce((acc, invoice) => {
      invoiceCurrency = invoice.currency;
      return acc + Money.unformat(invoice.owing, invoiceCurrency);
    }, 0.0);

    return Money.format(total, invoiceCurrency);
  }
);

const getSortedLineItems = createSelector(
  [getClinicInvoice, getSortKey],
  (invoice, sortKey) => {
    const { line_items = [] } = invoice;

    if (!sortKey) return line_items;

    const isDateType = ['created_at', 'used_at'].indexOf(sortKey) >= 0;
    const sortFn = isDateType 
      ? sortDateDescending.bind(null, sortKey) 
      : sortDescending.bind(null, sortKey);

    return line_items.sort(sortFn);
  }
);

const createGroupAmount = (current, amount, currency) => {
  return (current || 0) + Money.unformat(amount, currency);
};

const getGroupedLineItems = createSelector(
  [getSortedLineItems, getClinicInvoice, getSortKey],
  (lineItems, invoice) => {
    const groupedItems = lineItems.reduce((acc, lineItem) => {
      const { 
        type, bulk_code, id, created_at, amount, buyer 
      } = lineItem;
      const groupId = bulk_code || id;
      const groupIndex = acc.findIndex(g => g.id === groupId);
      const group = acc[groupIndex];

      if (group) {
        acc[groupIndex] = {
          id: bulk_code,
          type: group.type || type,
          isBulk: true,
          buyer: group.buyer || buyer,
          created_at: group.created_at || created_at,
          used_at: null,
          patient_account: null,
          amount: createGroupAmount(group.amount, amount, invoice.currency),
          items: [
            ...(group.items || []),
            lineItem
          ]
        };

        return acc;
      }

      return [
        ...acc,
        bulk_code ? {
          id: bulk_code,
          type,
          buyer,
          created_at,
          isBulk: true,
          used_at: null,
          patient_account: null,
          amount: createGroupAmount(0, amount, invoice.currency),
          items: [
            lineItem
          ]
        } : lineItem
      ];
    }, []);

    return groupedItems;
  }
);

const defaultItem = (currency) => ({
  qty: 0,
  amount: Money.format(0, currency)
});

const createItemType = (item) => {
  if (item.type === LineItemTypes.Custom) {
    return item.description; 
  }

  if (item.type === LineItemTypes.Impact && item.post_injury) {
    return LineItemTypes.PostInjuryImpact;
  }

  return item.type;
};

const createItemKey = (item) => {
  const type = createItemType(item);
  return `${type}_${item.amount}`;
};

const getLineItemSummary = createSelector(
  [getClinicInvoice],
  (invoice) => {
    const { line_items = [], currency } = invoice;
    const items = line_items.reduce((acc, item) => {
      const key = createItemKey(item);
      const currentItem = acc[key] || defaultItem(currency);
      return {
        ...acc,
        [key]: {
          type: createItemType(item),
          qty: currentItem.qty + 1,
          rate: item.amount,
          amount: Money.add(currency, currentItem.amount, item.amount)
        }
      };
    }, {});
    
    return Object.values(items);
  }
);

export default {
  getClinicInvoice,
  getOutstandingInvoices,
  getPaidInvoices,
  getTotalBalanceOwing,
  getSortedLineItems,
  getGroupedLineItems,
  getLineItemSummary
};

import React, { useEffect, useMemo } from 'react';
import { Link as ScrollLink, scrollSpy } from 'react-scroll';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Error404Page } from '../errors';
import { Page } from '../page';
import VimeoVideo from './vimeo-video';
import CourseVideosConfig from './videos-config';
import Breadcrumbs from './breadcrumbs';
import { ResourcesPaths } from '../../paths';
import Strings from './lang';
import { UserSelector } from '../../redux/selectors';
import { userHasAdminRole } from '../../utilities/user-roles';

const getModuleSectionId = (module) => {
  return `module-${module}`;
};

const breadcrumbConfig = [
  {
    key: 'resources', 
    link: ResourcesPaths.root.link, 
    active: false, 
    label: Strings.resourcesLabel
  },
  {
    key: 'courses', 
    link: ResourcesPaths.courses.index.link, 
    active: false, 
    label: Strings.coursesLabel
  },
  { 
    key: 'videos', 
    active: true, 
    label: Strings.videosLabel
  },
];

const TableOfContents = ({ modules }) => {
  return (
    <ul className="list-unstyled">
      {modules.map(module => {
        const id = getModuleSectionId(module.module);
        return (
          <li key={module.module}>
            <ScrollLink 
              spy
              smooth
              offset={-200} 
              duration={500} 
              to={id}
              href={`#${id}`}
              activeClass="active"
            >
              {Strings.moduleLabel} {module.module} - {module.name}
            </ScrollLink>
          </li>
        );
      })}
    </ul>
  );
};

const Section = ({ name, module, videos }) => {
  const id = getModuleSectionId(module);

  return (
    <div id={id} className="module-section">
      <div className="title">
        {Strings.moduleLabel} {module} - {name}
      </div>
      
      <div className="videos-list">
        {videos.map(video => (
          <div key={video.id} className="module-video">
            <VimeoVideo {...video} />
          </div>
        ))}
      </div>
    </div>
  );
};

const CourseVideos = () => {
  const params = useParams();
  const currentUser = useSelector(UserSelector.getCurrentUser);
  let course = CourseVideosConfig[params.courseId];
  const permitted = useMemo(() => (
    userHasAdminRole(currentUser) || course?.permitted(currentUser)
  ), [course, currentUser]);

  useEffect(() => {
    setTimeout(() => {
      scrollSpy.update();
    }, 100);
  }, []);

  if (!permitted) {
    course = null;
  }

  if (!course) {
    return (
      <Page>
        <Error404Page />
      </Page>
    );
  }

  return (
    <Page>
      <Breadcrumbs items={breadcrumbConfig} />
      <h1>{course.name}</h1>
      <div className="course-videos">
        <div className="course-module-sections">
          {course.modules.map(module => (
            <Section key={module.module} {...module} />
          ))}
        </div>
        <div className="module-scroll-spy">
          <TableOfContents modules={course.modules} />
        </div>
      </div>
    </Page>
  );
};

export default CourseVideos;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { FormComponent, FormInputGroup, SelectInputGroup } from '../../forms';
import { getSport } from '../../sports';
import { getDefaultPositionForSport, resolvePositionValue, OrganizationValidator } from './sports-utilities';
import SportsTeamPositionInputs from './sports-team-position-inputs';
import Strings from './lang';

const getDefaultState = (props) => {
  const sport = getSport(props.sport);
  const location = props.location || {};
  const defaultPosition = '';

  return {
    submitted: false,
    teamId: '',
    newTeamName: '',
    newTeamOrganization: '',
    newTeamLevel: '',
    primaryPosition: defaultPosition,
    otherPrimaryPosition: '',
    secondaryPosition: defaultPosition,
    otherSecondaryPosition: '',
    sport,
    location
  };
};

class SportsSelectTeamForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateSelect = this.onUpdateSelect.bind(this);
    this.onUpdateField = this.onUpdateField.bind(this);

    this.state = {
      ...getDefaultState(props)
    };
  }

  componentDidUpdate(prevProps) {
    const stateUpdates = {};

    if (prevProps.sport !== this.props.sport) {
      const sport = getSport(this.props.sport);
      const defaultPosition = getDefaultPositionForSport(sport);
      stateUpdates.sport = sport;
      stateUpdates.primaryPosition = defaultPosition;
      stateUpdates.otherPrimaryPosition = '';
      stateUpdates.secondaryPosition = defaultPosition;
      stateUpdates.otherSecondaryPosition = '';
    }

    if (prevProps.location !== this.props.location) {
      stateUpdates.location = this.props.location;
    }

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState(stateUpdates);
  }

  render() {
    const { teams = {} } = this.props;
    const {
      sport,
      teamId,
      newTeamName,
      newTeamOrganization,
      newTeamLevel,
      primaryPosition,
      otherPrimaryPosition,
      secondaryPosition,
      otherSecondaryPosition,
      submitted
    } = this.state;

    return (
      <FormComponent className="sports-select-team-form" onSubmit={this.onSubmit}>
        <h2>{Strings.selectTeamFormTitle}</h2>

        <SelectInputGroup
          className="form-group team-input-group"
          labelText={Strings.teamLabelText}
          inputProps={{
            className: 'form-control',
            value: teamId
          }}
          onUpdate={(value) => this.onUpdateSelect('teamId', value)}
          messageText={Strings.teamErrorText}
          messageClassName="alert alert-danger"
          inputValid={teamId.length > 0}
          required
          touched={submitted}
        >
          <option value="">{Strings.teamSelectOptionText}</option>
          {Object.keys(teams || {}).map(key => (
            <optgroup label={teams[key].name}>
              {teams[key].teams.map(team => (
                <option key={team.id} value={team.id}>
                  {team.name} &mdash; {team.level}
                </option>
              ))}
            </optgroup>
          ))}
          <option value="other">{Strings.addTeamOptionText}</option>
        </SelectInputGroup>

        <If condition={teamId === 'other'}>
          <Then>
            <div className="new-team-inputs">

              <FormInputGroup
                className="form-group new-team-organization-input-group"
                labelText={Strings.newTeamOrganizationLabelText}
                inputType="text"
                inputProps={{
                  className: 'form-control',
                  placeholder: Strings.organizationPlaceholder,
                  value: newTeamOrganization,
                  onChange: this.onUpdateField.bind(this, 'newTeamOrganization')
                }}
                required
                inputValid={OrganizationValidator.test(newTeamOrganization)}
                messageClassName="alert alert-danger"
                messageText={Strings.invalidOrganizationMessage}
                touched={submitted}
              />

              <FormInputGroup
                className="form-group new-team-name-input-group"
                labelText={Strings.newTeamNameLabelText}
                inputType="text"
                inputProps={{
                  className: 'form-control',
                  value: newTeamName,
                  onChange: this.onUpdateField.bind(this, 'newTeamName')
                }}
                messageText={Strings.newTeamNameErrorText}
                messageClassName="alert alert-danger"
                inputValid={newTeamName.length > 0}
                required
                touched={submitted}
              />

              <FormInputGroup
                className="form-group new-team-level-input-group"
                labelText={Strings.newTeamLevelLabelText}
                inputType="text"
                inputProps={{
                  className: 'form-control',
                  value: newTeamLevel,
                  onChange: this.onUpdateField.bind(this, 'newTeamLevel')
                }}
                messageText={Strings.newTeamLevelErrorText}
                messageClassName="alert alert-danger"
                inputValid={newTeamLevel.length > 0}
                required
                touched={submitted}
              />

            </div>
          </Then>
        </If>

        <SportsTeamPositionInputs
          sport={sport}
          primaryPosition={primaryPosition}
          otherPrimaryPosition={otherPrimaryPosition}
          secondaryPosition={secondaryPosition}
          otherSecondaryPosition={otherSecondaryPosition}
          onUpdateSelect={this.onUpdateSelect}
          onUpdateField={this.onUpdateField}
          submitted={submitted}
        />

        <div className="form-footer">
          <button type="submit" className="btn btn-primary">
            {Strings.saveButtonText}
          </button>
        </div>

      </FormComponent>
    );
  }

  onUpdateField(field, e) {
    const { value } = e.target;
    this.setState({ [field]: value });
  }

  onUpdateSelect(field, value) {
    this.setState({ [field]: value });
  }

  isValid() {
    const {
      teamId,
      newTeamName,
      newTeamLevel,
      newTeamOrganization,
      primaryPosition,
      otherPrimaryPosition,
      secondaryPosition,
      otherSecondaryPosition
    } = this.state;

    let teamValid = false;
    let primaryPositionValid = true;
    let secondaryPositionValid = true;

    if (teamId === 'other') {
      teamValid = newTeamName.length > 0 && newTeamLevel.length > 0;
    } else {
      teamValid = teamId.length > 0;
    }

    if (this.state.sport.positions.length > 0) {
      primaryPositionValid = resolvePositionValue(
        primaryPosition,
        otherPrimaryPosition
      ).length > 0;

      secondaryPositionValid = secondaryPosition === 'other' ? otherSecondaryPosition.length > 0 : true;
    }
    
    const organizationValid = teamId === 'other' ? OrganizationValidator.test(newTeamOrganization) : true;

    return teamValid
      && organizationValid
      && primaryPositionValid
      && secondaryPositionValid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      teamId,
      newTeamName,
      newTeamOrganization,
      newTeamLevel,
      primaryPosition,
      otherPrimaryPosition,
      secondaryPosition,
      otherSecondaryPosition
    } = this.state;

    const { sport, location, userId } = this.props;

    const resolvedPrimaryPosition = resolvePositionValue(
      primaryPosition,
      otherPrimaryPosition
    );

    const resolvedSecondaryPosition = resolvePositionValue(
      secondaryPosition,
      otherSecondaryPosition
    );

    if (teamId === 'other') {
      // Add a new team:
      const attributes = {
        user_id: parseInt(userId, 10),
        name: newTeamName,
        level: newTeamLevel,
        primary_position: resolvedPrimaryPosition,
        secondary_position: resolvedSecondaryPosition,
        sport: {
          name: sport
        },
        organization: {
          name: newTeamOrganization
        },
        ...location
      };

      if (typeof this.props.onAddTeam === 'function') {
        this.props.onAddTeam(attributes);
      }
    } else {
      // Select an existing team:
      const attributes = {
        id: parseInt(teamId, 10),
        user_id: parseInt(userId, 10),
        primary_position: resolvedPrimaryPosition,
        secondary_position: resolvedSecondaryPosition
      };

      if (typeof this.props.onSelectTeam === 'function') {
        this.props.onSelectTeam(attributes);
      }
    }
  }
}

SportsSelectTeamForm.propTypes = {
  userId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  sport: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  teams: PropTypes.object.isRequired,
  onAddTeam: PropTypes.func.isRequired,
  onSelectTeam: PropTypes.func.isRequired
};

export default SportsSelectTeamForm;

import Strings from './lang';
import { DEFAULT_USER_LANGUAGE } from '../../utilities/localization';

const getInjuryReportFromProps = (props) => {
  const { 
    user = {},
    currentUser = {},
    params = {},
    injuryReports = {},
    clinic = {}
  } = props;
  const { userId = 0, reportId = 0 } = params;
  const person = user.person || {};
  const language = person.language || DEFAULT_USER_LANGUAGE;
  const userInjuryReport = injuryReports[userId] || {};
  const injuryReport = userInjuryReport[reportId] || {};

  return { 
    user, currentUser, currentClinic: clinic, injuryReport, language 
  };
};

const getInjuryInformationFromProps = (props) => {
  const {
    isDisplay,
    injuryInformationLabel,
    injuryInformationText
  } = props;

  return { injuryInformationLabel, injuryInformationText, isDisplay };
};

const getInjuryInformationSectionData = () => {
  return {
    association: Strings.associationLabel,
    at_school: Strings.schoolNameQuestionLabel,
    loss_of_consciousness: Strings.lossConsciousnessLabel,
    loss_of_consciousness_duration: Strings.lossConsciousnessDurationLabel,
    loss_of_consciousness_units: '',
    school_name: Strings.schoolNameLabel,
    sport: Strings.sportLabel,
    sports_related: Strings.sportRelatedLabel,
    injury_description: Strings.injuryDescription,
    hit_to_head: Strings.hitToHeadLabelText,
    head_hit_location: Strings.hitToHeadLocationLabel
  };
};

const getRedFlagsSectionData = () => {
  return {
    difficulty_swallowing: Strings.swallowLabel,
    extreme_confusion: Strings.confusionLabel,
    extreme_neck_pain: Strings.neckPainLabel,
    loss_of_consciousness_one_minute: Strings.lostConsciousnessLabel,
    seizures: Strings.seizuresLable,
    severe_headache: Strings.headacheLabel,
    slurred_speech: Strings.slurredSpeedLabel,
    vomiting: Strings.vomitingLabel,
    weakness: Strings.armsOrLegsWeaknessLabel
  };
};

const INJURY_REPORT_INFORMATION_KEYS = Object.keys(getInjuryInformationSectionData());
const INJURY_REPORT_REGFLAGS_KEYS = Object.keys(getRedFlagsSectionData());

export {
  getInjuryReportFromProps,
  getInjuryInformationFromProps,
  getInjuryInformationSectionData,
  getRedFlagsSectionData,
  INJURY_REPORT_INFORMATION_KEYS,
  INJURY_REPORT_REGFLAGS_KEYS
};

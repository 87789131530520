import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import classnames from 'classnames';
import { AuthenticationPaths, RegistrationPaths } from '../../paths';
import { isAuthenticated, getCurrentClinicId } from '../../tokens';
import { setInterfaceLanguage } from '../../utilities/localization';
import Icon from '../icon';
import NavigationContentLoggedIn from './navigation-content-logged-in';
import NavigationLoggedInDropdown from './navigation-logged-in-dropdown';
import AccessControl from '../access-control';
import LanguageSelector from './language-selector';
import DashboardDropdown from './dashboard-dropdown';
import { UserSelector } from '../../redux/selectors';
import { AdminAndClinicRoleDescriptions } from '../../utilities/user-roles';
import Strings from './lang';

const setLanguage = (lang) => {
  setInterfaceLanguage(lang);
  // Refresh the page to reload the language:
  // eslint-disable-next-line no-self-assign
  window.location = window.location;
};

const Navigation = ({
  showLanguageSelector = false,
  hideContent = false
}) => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const authenticated = useMemo(() => isAuthenticated(), []);
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const currentOrganization = useMemo(() => {
    const clinicId = getCurrentClinicId();
    return currentUser?.clinics?.find(clinic => (
      String(clinic.id) === String(clinicId)
    )) || {};
  }, [currentUser?.clinics]);

  const toggleExpanded = useCallback(() => {
    setExpanded(prev => !prev);
  }, []);

  useEffect(() => {
    if (expanded) {
      const onBodyClick = (e) => {
        // Hide the navigation if any element other than the dropdown header was clicked.
        // This ensures that the navigation dropdown closes when the user clicks elsewhere
        // and in situations where the clicked item does not trigger a route change.
        const { classList } = e.target;
        if (!classList.contains('navigation-dropdown-header')
          && !classList.contains('navigation-bar-toggle')
          && !classList.contains('dropdown-toggle')
          && expanded
        ) {
          setExpanded(false);
        }
      };

      document.body.addEventListener('click', onBodyClick);

      return () => {
        document.body.removeEventListener('click', onBodyClick);
      };
    }

    return undefined;
  }, [expanded]);

  return (
    <nav
      className={classnames('navigation-bar', {
        collapsable: hideContent !== true && authenticated
      })}
    >
      <div className="container">
        <div className="navigation-header">
          <div className="navigation-content-left">
            <div className="navigation-brand">
              <img src="/images/logo/color.svg" alt="CCMI Logo" />
            </div>
            {authenticated && hideContent !== true && (
              <AccessControl roles={AdminAndClinicRoleDescriptions}>
                <DashboardDropdown />
              </AccessControl>
            )}
          </div>

          <button type="button" className="navigation-bar-toggle" onClick={toggleExpanded}>
            <Icon name={expanded ? 'xmark' : 'bars'} />
          </button>
        </div>

        <div
          className={classnames('navigation-content', {
            visible: expanded
          })}
        >
          <If condition={hideContent !== true}>
            <Then>
              <If condition={authenticated}>
                <Then>
                  <NavigationContentLoggedIn currentOrganization={currentOrganization} />
                </Then>
                <Else>
                  <ul className="navbar-items">
                    <li>
                      <Link to={AuthenticationPaths.index.link}>
                        {Strings.authLinkText}
                      </Link>
                    </li>

                    <li>
                      <Link to={RegistrationPaths.index.link}>
                        {Strings.registerLinkText}
                      </Link>
                    </li>

                  </ul>
                </Else>
              </If>
            </Then>
          </If>
          <ul className="navbar-items right">
            <If condition={hideContent !== true}>
              <Then>
                <If condition={authenticated && !showLanguageSelector}>
                  <Then>
                    <NavigationLoggedInDropdown
                      currentOrganization={currentOrganization}
                      currentUser={currentUser}
                      location={location}
                    />
                  </Then>
                  <Else>
                    <LanguageSelector
                      onSelectLanguage={setLanguage}
                    />
                  </Else>
                </If>
              </Then>
              <Else>
                {() => {
                  return showLanguageSelector
                    ? <LanguageSelector onSelectLanguage={setLanguage} />
                    : null;
                }}
              </Else>
            </If>
          </ul>
        </div>

      </div>
    </nav>
  );
};

export default Navigation;

import React from 'react';
import BaselinePersonalStep from './personal-step';
import BaselineMedicalStep from './medical-step';
import StepComponents from '../baseline-steps';
import { RoleDescriptions } from '../../../utilities/user-roles';
import withBaselineStep from './with-baseline-step';

const BaselineSymptomsStep = withBaselineStep(StepComponents.pre_existing_symptoms);
const BaselineOrientationStep = withBaselineStep(StepComponents.cognitive_assessment_test);
const BaselineImmediateMemoryStep = withBaselineStep(StepComponents.immediate_memory_test);
const BaselineConcentrationStep = withBaselineStep(StepComponents.concentration_test);
const BaselineKingDevickStep = withBaselineStep(StepComponents.king_devick_test);
const BaselineBalanceStep = withBaselineStep(StepComponents.balance_test);
const BaselineForcePlateStep = withBaselineStep(StepComponents.force_plate_test);
const BaselineTandemGaitStep = withBaselineStep(StepComponents.tandem_gait_test);
const BaselineDualTaskTestStep = withBaselineStep(StepComponents.dual_task_test);
const BaselineReactionTimeStep = withBaselineStep(StepComponents.reaction_time_test);
const BaselineGripStrengthStep = withBaselineStep(StepComponents.grip_strength_test);
const BaselineDelayedMemoryStep = withBaselineStep(StepComponents.delayed_memory_recall_test);

export const StepRoutesConfig = {
  personal: {
    key: 'personal',
    path: 'personal',
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester
    ],
    element: <BaselinePersonalStep />
  },
  medical: {
    key: 'medical',
    path: 'medical',
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester
    ],
    element: <BaselineMedicalStep />
  },
  pre_existing_symptoms: {
    key: 'pre_existing_symptoms',
    path: 'symptoms',
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester
    ],
    element: <BaselineSymptomsStep />
  },
  cognitive_assessment_test: {
    key: 'cognitive_assessment_test',
    path: 'orientation',
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester
    ],
    element: <BaselineOrientationStep />
  },
  immediate_memory_test: {
    key: 'immediate_memory_test',
    path: 'memory',
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester
    ],
    element: <BaselineImmediateMemoryStep />
  },
  concentration_test: {
    key: 'concentration_test',
    path: 'concentration',
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester
    ],
    element: <BaselineConcentrationStep />
  },
  king_devick_test: {
    key: 'king_devick_test',
    path: 'king-devick',
    skippable: true,
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester,
      RoleDescriptions.ClinicStaff
    ],
    element: <BaselineKingDevickStep />
  },
  balance_test: {
    key: 'balance_test',
    path: 'balance',
    skippable: true,
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester,
      RoleDescriptions.ClinicStaff
    ],
    element: <BaselineBalanceStep />
  },
  force_plate_test: {
    key: 'force_plate_test',
    path: 'force-plate',
    skippable: true,
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester,
      RoleDescriptions.ClinicStaff
    ],
    element: <BaselineForcePlateStep />
  },
  tandem_gait_test: {
    key: 'tandem_gait_test',
    path: 'tandem-gait',
    skippable: true,
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester,
      RoleDescriptions.ClinicStaff
    ],
    element: <BaselineTandemGaitStep />
  },
  dual_task_test: {
    key: 'dual_task_test',
    path: 'dual-task',
    skippable: true,
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester,
      RoleDescriptions.ClinicStaff
    ],
    element: <BaselineDualTaskTestStep />
  },
  reaction_time_test: {
    key: 'reaction_time_test',
    path: 'reaction',
    skippable: true,
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester,
      RoleDescriptions.ClinicStaff
    ],
    element: <BaselineReactionTimeStep />
  },
  grip_strength_test: {
    key: 'grip_strength_test',
    path: 'grip-strength',
    skippable: true,
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester,
      RoleDescriptions.ClinicStaff
    ],
    element: <BaselineGripStrengthStep />
  },
  delayed_memory_recall_test: {
    key: 'delayed_memory_recall_test',
    path: 'delayed-memory',
    permissions: [
      RoleDescriptions.Clinician,
      RoleDescriptions.BaselineTester,
      RoleDescriptions.ClinicStaff
    ],
    element: <BaselineDelayedMemoryStep />
  },
};

const StepRoutes = Object.values(StepRoutesConfig);

export default StepRoutes;

import { RoleNames, RoleResourceTypes, makeRoleDescription } from '../../../utilities/user-roles';

const makeInvoicePaymentRoles = (clinicId) => {
  return [
    makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId)
  ];
};  

export {
  makeInvoicePaymentRoles
};

import React from 'react';
import classNames from 'classnames';
import { FormInput } from '../../../../../forms';
import Icon from '../../../../icon';
import Strings from '../lang';

const getIconName = (correct) => {
  if (correct === null || correct === undefined) {
    return 'minus';
  }

  return correct ? 'check' : 'xmark';
};

const getDurationValue = (duration) => {
  return duration === '' ? '' : ((duration || 0) / 1000);
};

const NumbersInputTable = ({
  numbers,
  responses,
  duration,
  errors,
  onAddResponse,
  onResponseChange = () => { },
  onDurationChange = () => { }
}) => {
  return (
    <table className="table dual-task-numbers-input-table">
      <thead>
        <tr>
          <th className="no-action">
            {Strings.correctNumberColumnLabel}
          </th>
          <th className="no-action">
            {Strings.responseNumberColumnLabel}
          </th>
        </tr>
      </thead>
      <tbody>
        {responses.map((r, i) => (
          <tr key={i}>
            <td>{numbers[i]}</td>
            <td className="number-input">
              <div className="flex-row align_center">
                <FormInput
                  className="form-control"
                  type="number"
                  inputProps={{
                    value: responses[i]?.value ?? '',
                    disabled: i !== 0 && (!responses[i - 1] || responses[i - 1]?.value === ''),
                    step: 1,
                    onChange: (e) => onResponseChange(i, e.target.valueAsNumber),
                    // onBlur: onResponseBlur
                  }}
                />
                <Icon
                  fixedWidth
                  name={getIconName(responses[i]?.correct)}
                  className={classNames({
                    light: responses[i]?.correct === null,
                    error: responses[i]?.correct === false,
                    success: responses[i]?.correct === true
                  })}
                />
              </div>
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={2} className="add-response">
            <button type="button" disabled={responses[responses.length - 1]?.value === ''} onClick={onAddResponse}>
              <Icon name="plus" />&nbsp; Add Response
            </button>
          </td>
        </tr>
        <tr className="text-right">
          <td><strong>{Strings.tableColumnErrors}</strong></td>
          <td>{errors}</td>
        </tr>
        <tr className="text-right">
          <td><strong>{Strings.tableColumnTime}</strong></td>
          <td className="number-input">
            <FormInput
              className="form-control"
              type="number"
              inputProps={{
                value: getDurationValue(duration),
                min: 0,
                onChange: (e) => onDurationChange(e.target.valueAsNumber)
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default NumbersInputTable;

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateUserAsync } from '../../../redux/thunks/users';
import ShowPlayerProfile from '../../user-profile/show-player-profile';
import { Permissions } from '../patient-profile-utils';
import { confirmation } from '../../confirmation';
import { resolvePatientName } from '../../patient-meta';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import Strings from './lang';
import { UserSelector } from '../../../redux/selectors';

const EditingKey = {
  Personal: 'personal',
  Contact: 'contact',
  Physician: 'physician'
};

const initialEditingState = {
  [EditingKey.Personal]: false,
  [EditingKey.Contact]: false,
  [EditingKey.Physician]: false
};

const PatientProfileInfo = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(initialEditingState);
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));

  const resetEditing = useCallback(() => {
    setEditing(initialEditingState);
  }, []);

  const updateEditing = useCallback((key) => {
    setEditing(prev => ({
      ...prev,
      [key]: !prev[key]
    }));
  }, []);

  const updateUser = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateUserAsync(params.userId, attributes)).then(() => {
      setActivity(false);
      resetEditing();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, params.userId, resetEditing]);

  const updateCurrentClinic = useCallback((clinicItem = {}) => {
    const newClinicId = clinicItem.value || 0;
    const newClinicName = clinicItem.label || '';

    const message = Strings.formatString(
      Strings.assignClinicModalMessage,
      newClinicName,
      resolvePatientName({
        user: currentUser,
        patient: user,
        clinicId: params.clinicId,
        redactedText: Strings.modalNameRedactedText
      })
    );

    confirmation(message, {
      title: Strings.assignClinicModalTitle,
      confirmButtonTitle: Strings.assignClinicModalConfirmButtonTitle,
      onConfirm: () => {
        updateUser({
          current_clinic_id: newClinicId
        });
      }
    });
  }, [currentUser, params.clinicId, updateUser, user]);

  return (
    <Activity active={activity}>
      <div className="patient-info">
        <ErrorBanner error={error} />
        <ShowPlayerProfile
          editorRoles={(
            Permissions.makePlayerProfileEditorRoles(params.clinicId)
          )}
          currentUser={currentUser}
          user={user}
          clinicId={params.clinicId}
          editPersonal={editing.personal}
          editContact={editing.contact}
          editPhysician={editing.physician}
          onClickPersonalEditButton={() => updateEditing(EditingKey.Personal)}
          onClickContactEditButton={() => updateEditing(EditingKey.Contact)}
          onClickPhysicianEditButton={() => updateEditing(EditingKey.Physician)}
          onSubmit={updateUser}
          onSelectCurrentClinic={updateCurrentClinic}
        />
      </div>
    </Activity>
  );
};

export default PatientProfileInfo;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { RoleNames, RoleResourceTypes, makeRoleDescription } from '../../utilities/user-roles';
import AccessControl from '../access-control';
import Strings from './lang';

const TestStartButtonDetails = {
  buffalo: {
    label: Strings.buffalo,
    makeCreatableRoles: (clinicId) => {
      return [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
      ];
    }
  },
  blackhawks: {
    label: Strings.blackhawks,
    makeCreatableRoles: (clinicId) => {
      return [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
      ];
    }
  },
  voms: {
    label: Strings.voms,
    makeCreatableRoles: (clinicId) => {
      return [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
      ];
    }
  },
  ovs: {
    label: Strings.ovs,
    makeCreatableRoles: (clinicId) => {
      return [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
      ];
    }
  },
  postInjury: {
    label: Strings.postInjury,
    makeCreatableRoles: (clinicId) => {
      return [
        makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
        makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId)
      ];
    }
  }
};

const TestButton = ({
  type = 'buffalo', 
  clinicId = 0, 
  className = 'btn-primary', 
  disabled = false, 
  onClick = () => {}
}) => {
  const btnDetails = TestStartButtonDetails[type];
  return (
    <AccessControl roles={btnDetails.makeCreatableRoles(clinicId)}>
      <button 
        type="button" 
        disabled={disabled} 
        className={classnames('btn', className)} 
        onClick={onClick}
      >
        {btnDetails.label}
      </button>
    </AccessControl>
  );
};

TestButton.propTypes = {
  type: PropTypes.oneOf([
    'buffalo',
    'blackhawks',
    'voms',
    'ovs',
    'postInjury'
  ]),
  clinicId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
};

export { TestStartButtonDetails, TestButton };

import React from 'react';
import { TeamLeaderProfile, TeamLeaders } from '../../components/team-leaders';
import { Authorization } from '../authorization';
import { AdminRoleDescriptions } from '../../utilities/user-roles';
import PageNotFound from '../not-found-page';

const TeamLeadersRoutes = [
  {
    path: '*',
    element: <Authorization allowRoles={AdminRoleDescriptions} />,
    children: [
      {
        index: true,
        element: <TeamLeaders />
      },
      {
        path: ':leaderId',
        element: <TeamLeaderProfile />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
];

export default TeamLeadersRoutes;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, EmailInputGroup, EmailValidator } from '../../forms';
import Strings from './lang';

class EditTeamLeaderContactCardForm extends Component {
  constructor(props) {
    super(props);

    const { user = {} } = props;

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: user.email,
      emailValid: EmailValidator.test(user.email)
    };
  }

  render() {
    return (
      <FormComponent className="card card-form" onSubmit={this.onSubmit}>
        <EmailInputGroup
          className="form-group person-email-input-group"
          labelText={Strings.emailLabelText}
          inputProps={{
            value: this.state.email,
            className: 'form-control',
            placeholder: Strings.emailInputPlaceholder
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.emailValid;
  }

  onSubmit() {
    if (!this.isValid()) {
      return;
    }

    const attributes = {
      email: this.state.email
    };

    this.props.onSubmit(attributes);
  }
}

EditTeamLeaderContactCardForm.propTypes = {
  user: PropTypes.object
};

export default EditTeamLeaderContactCardForm;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import TaxesTable from './taxes-table';
import TaxItemDrawer from './tax-item-drawer';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import { 
  getTaxRatesAsync, 
  deleteTaxItemAsync, 
  createTaxRateAsync, 
  updateTaxItemAsync 
} from '../../redux/thunks/taxes';
import { TaxRateSelector } from '../../redux/selectors';
import { confirmation } from '../confirmation';
import Countries from '../../utilities/country-regions';
import Strings from './lang';
import Icon from '../icon';

class Taxes extends Component {
  constructor(props) {
    super(props);

    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.createTaxItem = this.createTaxItem.bind(this);
    this.updateTaxItem = this.updateTaxItem.bind(this);

    this.state = {
      drawerOpen: false,
      selectedItemId: null,
      submitting: false,
      submitError: null,
      loadError: null
    };
  }

  componentDidMount() {
    this.getTaxes();
  }

  render() {
    const { taxes } = this.props;

    return (
      <Page className="tax-rates">
        <h1 className="display">{Strings.taxRateTitle}</h1>
        <div className="add-tax-rate">
          <button 
            type="button" 
            className="btn btn-sm btn-primary"
            onClick={() => this.setState({ drawerOpen: true })}
          >
            <Icon name="plus" />&nbsp;
            {Strings.addTaxRateButton}
          </button>
        </div>
        <ErrorBanner error={this.state.loadError} />
        <TaxesTable 
          taxes={taxes}
          onEditItem={(item) => this.setState({
            selectedItemId: item.id,
            drawerOpen: true
          })}
          onDeleteItem={this.onDeleteItem}
        />
        <TaxItemDrawer 
          selectedItemId={this.state.selectedItemId}
          isOpen={this.state.drawerOpen} 
          error={this.state.submitError}
          submitting={this.state.submitting}
          onAddItem={this.createTaxItem}
          onUpdateItem={this.updateTaxItem}
          onClose={() => {
            this.setState({ 
              selectedItemId: null, 
              drawerOpen: false 
            });
          }}
        />
      </Page>
    );
  }

  getTaxes() {
    this.props.getTaxes().catch(error => {
      this.setState({
        loadError: error.mesage
      });
    });
  } 

  onDeleteItem(tax) {
    const countryData = Countries[tax.country];
    const message = Strings.formatString(
      Strings.confirmDeleteTaxRateMessage,
      countryData.regions[tax.state].name,
      countryData.name
    );

    confirmation(message, {
      title: Strings.confirmDeleteTaxRateTitle,
      type: 'danger',
      onConfirm: () => this.onConfirmDeleteItem(tax)
    });
  }

  onConfirmDeleteItem(tax) {
    this.props.deleteTaxItem(tax.id).catch(error => {
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
  }

  createTaxItem(attributes) {
    this.setState({ submitting: true, submitError: null });

    this.props.createTaxItem(attributes).then(() => {
      this.setState({ submitting: false, drawerOpen: false, selectedItemId: null });
    }).catch(error => {
      this.setState({ submitting: false, submitError: error.message });
    });
  }

  updateTaxItem(attributes) {
    this.setState({ submitting: true, submitError: null });

    this.props.updateTaxItem(attributes.id, attributes).then(() => {
      this.setState({ submitting: false, drawerOpen: false, selectedItemId: null });
    }).catch(error => {
      this.setState({ submitting: false, submitError: error.message });
    });
  }
}

const mapStateToProps = (state) => {
  const taxes = TaxRateSelector.getGroupedTaxRates(state);

  return { taxes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTaxes: () => {
      return dispatch(getTaxRatesAsync());
    },
    createTaxItem: (attributes) => {
      return dispatch(createTaxRateAsync(attributes));
    },
    updateTaxItem: (id, attributes) => {
      return dispatch(updateTaxItemAsync(id, attributes));
    },
    deleteTaxItem: (taxId) => {
      return dispatch(deleteTaxItemAsync(taxId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Taxes);

import { getPage } from '../utils';
import wrapArray from '../../../utilities/wrap-array';

export const getQuery = (query = {}) => {
  return {
    name: query.name || '',
    page: getPage(query.page),
    category: query.category,
    brands: wrapArray(query['brands[]'])
  };
};

export const formatQuery = (query = {}) => {
  return {
    name: query.name || '',
    page: getPage(query.page),
    category: query.category,
    'brands[]': query.brands
  };
};

export const formatParams = (query = {}) => {
  return {
    name: query.name || '',
    page: query.page,
    categories: wrapArray(query.category),
    partners: query.brands
  };
};

import React, { useCallback, useRef, useState } from 'react';
import { useClickOutside } from '../../hooks';
import Popper from '../popper';
import Icon from '../icon';
import DateRangeMenu from './menu';
import RangeView from './range-view';

const DateRangeSelector = ({
  range = {},
  btnClassName = 'btn',
  onChange = () => {}
}) => {
  const targetRef = useRef();
  const menuRef = useRef();
  const [open, setOpen] = useState(false);

  useClickOutside(menuRef, (e) => {
    if (targetRef.current && !targetRef.current.contains(e.target)) {
      setOpen(false);
    }
  }, open);

  const onSelectRange = useCallback((range) => {
    setOpen(false);
    onChange(range);
  }, [onChange]);

  return (
    <Popper
      portal
      isOpen={open}
      placement="bottom-end"
      onClose={() => setOpen(false)}
      content={(
        <DateRangeMenu
          ref={menuRef}
          placement="bottom-end"
          range={{ ...range, key: 'selection' }}
          onSelectRange={onSelectRange}
        />
      )}
    >
      <button
        ref={targetRef}
        type="button"
        className={btnClassName}
        onClick={() => setOpen(prev => !prev)}
      >
        <Icon name="calendar-range" />&nbsp;&nbsp;
        <RangeView range={range} />
        <Icon name="chevron-down" />
      </button>
    </Popper>
  );
};

export default DateRangeSelector;
export { initialRange } from './ranges';

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DEFAULT_USER_LANGUAGE } from '../../../utilities/localization';
import { getWordList } from '../test-components/word-lists';
import BaselineSection from './baseline-section';
import Fraction from '../../fraction';
import Strings from './lang';

const numberOfTrials = 3;
const DEFAULT_WORD_COUNT = 5;

const BaselineMemory = ({
  data = {}, 
  language = DEFAULT_USER_LANGUAGE
}) => {
  const { 
    word_list = 0, scores = [], notes = '', word_count = DEFAULT_WORD_COUNT, not_done = false 
  } = data;
  
  const totalScore = useMemo(() => scores.reduce((acc, cur) => {
    const score = parseInt(cur, 10);
    return acc + (isNaN(score) ? 0 : score);
  }, 0), [scores]);

  return (
    <BaselineSection
      className="baseline-memory"
      title={Strings.memoryTitle}
      notes={notes}
      sectionCompleted={Object.keys(data || {}).length > 0 && !not_done}
    >
      <p>
        <strong>
          {Strings.formatString(Strings.wordListLabelText, word_list)}
        </strong> {getWordList(language, word_list, word_count)}
      </p>
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.formatString(Strings.trialLabelText, 1)}</th>
            <th className="no-action">{Strings.formatString(Strings.trialLabelText, 2)}</th>
            <th className="no-action">{/* eslint-disable no-magic-numbers */ Strings.formatString(Strings.trialLabelText, 3)}</th>
            <th className="no-action"><strong>{Strings.totalLabelText}</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><Fraction numerator={scores[0]} denominator={word_count} /></td>
            <td><Fraction numerator={scores[1]} denominator={word_count} /></td>
            <td><Fraction numerator={scores[2]} denominator={word_count} /></td>
            <td className="total"><Fraction numerator={totalScore} denominator={word_count * numberOfTrials} /></td>
          </tr>
        </tbody>
      </table>
    </BaselineSection>
  );
};

BaselineMemory.propTypes = {
  data: PropTypes.object.isRequired,
  language: PropTypes.string
};

export default BaselineMemory;

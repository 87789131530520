import React from 'react';
import { Navigate } from 'react-router-dom';
import { Authorization } from '../authorization';
import { AdminRoleDescriptions, RoleDescriptions } from '../../utilities/user-roles';
import PageNotFound from '../not-found-page';
import {
  BaselineRouteContainer,
  BaselineContinueProgress,
  BaselineStepRoutes,
  BaselineTestCompleteStep
} from '../../components/baseline/baseline-step-routes';
import EditBaseline from '../../components/baseline/edit-baseline';

const BaselineRoutes = [
  {
    path: '*',
    element: (
      <Authorization
        allowRoles={[
          ...AdminRoleDescriptions,
          RoleDescriptions.Clinician,
          RoleDescriptions.BaselineTester,
          RoleDescriptions.ClinicFrontDesk,
          RoleDescriptions.ClinicStaff
        ]}
      />
    ),
    children: [
      {
        path: ':baselineId/*',
        children: [
          {
            path: 'edit',
            element: <EditBaseline />
          },
          {
            path: 'steps/*',
            element: <BaselineRouteContainer />,
            children: [
              {
                index: true,
                element: <Navigate to="continue" replace />
              },
              {
                path: 'continue',
                element: <BaselineContinueProgress />
              },
              {
                path: 'complete',
                element: <BaselineTestCompleteStep />
              },
              {
                path: '*',
                element: <BaselineStepRoutes />
              }
            ]
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  }
];

export default BaselineRoutes;

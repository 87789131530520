import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckboxInputGroup } from '../../../../forms';
import Strings from './lang';

class CommunicationActions extends Component {
  constructor(props) {
    super(props);

    const { communication = {} } = props;

    const {
      diagnosis_provided = true,
      finding_provided = false,
      education_provided = false,
      app_link_provided = true
    } = communication;

    this.state = {
      diagnosis_provided,
      finding_provided,
      education_provided,
      app_link_provided
    };
  }

  componentDidMount() {
    this._callOnUpdate();
  }

  render() {
    return (
      <div className={this.props.className}>
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.diagnosis_provided}
          inputProps={{
            className: 'form-control',
            checked: this.state.diagnosis_provided || false,
            onChange: this.onCommunicationUpdate.bind(this, 'diagnosis_provided')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.finding_provided}
          inputProps={{
            className: 'form-control',
            checked: this.state.finding_provided || false,
            onChange: this.onCommunicationUpdate.bind(this, 'finding_provided')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.education_provided}
          inputProps={{
            className: 'form-control',
            checked: this.state.education_provided || false,
            onChange: this.onCommunicationUpdate.bind(this, 'education_provided')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.app_link_provided}
          inputProps={{
            className: 'form-control',
            checked: this.state.app_link_provided || false,
            onChange: this.onCommunicationUpdate.bind(this, 'app_link_provided')
          }}
          inputValid
        />
      </div>
    );
  }

  onCommunicationUpdate(key, e) {
    const { checked } = e.target;
    this.setState({
      [key]: checked
    }, this._callOnUpdate.bind(this));
  }

  _callOnUpdate() {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this.state, true);
    }
  }
}

CommunicationActions.propTypes = {
  communication: PropTypes.object,
  onUpdate: PropTypes.func
};

export default CommunicationActions;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, FormInputGroup } from '../../../forms';
import { AddressFormInputs } from '../../address-form-inputs';
import convertAddressKeys from '../../../utilities/convert-address-keys';
import sanitizeAddress from '../../../utilities/sanitize-address';
import validateAddress from '../../../utilities/validate-address';
import Strings from './lang';

class RegistrationPhysicianForm extends Component {
  constructor(props) {
    super(props);

    const { physician } = this.props;
    const name = physician.name || '';
    const phone = physician.phone || '';
    const fax = physician.fax || '';
    const address = sanitizeAddress(physician.address || {});

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);

    this.state = {
      submitted: false,
      name,
      phone,
      fax,
      address,
      addressValid: validateAddress(address)
    };
  }

  render() {
    return (
      <FormComponent className="registration-physician-form" onSubmit={this.onSubmit}>

        <FormInputGroup
          className="form-group name-input-group"
          labelText={Strings.physicianNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.name,
            onBlur: this.onUpdateInput.bind(this, 'name'),
            onChange: this.onUpdateInput.bind(this, 'name')
          }}
          inputValid={this.state.name.length > 0}
          messageText={Strings.physicianNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.phoneLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.phone,
            onBlur: this.onUpdateInput.bind(this, 'phone'),
            onChange: this.onUpdateInput.bind(this, 'phone')
          }}
          inputValid={this.state.phone.length > 0}
          messageText={Strings.phoneErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group fax-input-group"
          labelText={Strings.faxLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.fax,
            onBlur: this.onUpdateInput.bind(this, 'fax'),
            onChange: this.onUpdateInput.bind(this, 'fax')
          }}
          inputValid
          required={false}
          touched={this.state.submitted}
        />

        <AddressFormInputs
          address={this.state.address}
          onUpdate={this.onUpdateAddress}
          required
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.name.length
      && this.state.phone.length
      && this.state.addressValid;
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onUpdateAddress(address, addressValid) {
    this.setState({
      address,
      addressValid
    });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      name,
      phone,
      fax,
      address
    } = this.state;

    const attributes = {
      physician_contact_attributes: {
        name,
        phone,
        fax,
        address_attributes: convertAddressKeys(address)
      }
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

RegistrationPhysicianForm.propTypes = {
  physician: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

export default RegistrationPhysicianForm;

import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { INPUT_DATE_FORMAT } from '../../dates/formats';
import { getLocalStorageItem, setLocalStorageItem } from '../../utilities/local-storage';
import Strings from './lang';
import Icon from '../icon';

const COOKIE_STORAGE_KEY = 'com.ccmi.cookies.notice';
const VISIBILITY_TIMEOUT = 300;
const MAX_STORAGE_DURATION = 7;

const useCookieNotice = () => {
  const [active, setActive] = useState(false);

  const setHidden = useCallback(() => {
    setLocalStorageItem(COOKIE_STORAGE_KEY, dayjs().format(INPUT_DATE_FORMAT));
    setActive(false);
  }, []);

  useEffect(() => {
    const cookieNotice = getLocalStorageItem(COOKIE_STORAGE_KEY);

    if (!cookieNotice 
      || dayjs(cookieNotice).add(MAX_STORAGE_DURATION, 'days').isBefore(dayjs())) {
      setActive(true);
    }
  }, []);

  return [active, setHidden];
};

const CookieBanner = () => {
  const [active, setHidden] = useCookieNotice();
  const [visible, setVisible] = useState(active);

  useEffect(() => {
    if (!active && visible) {
      const timeout = setTimeout(() => {
        setVisible(false);
      }, VISIBILITY_TIMEOUT);

      return () => {
        clearTimeout(timeout);
      };
    }
    
    if (active && !visible) {
      setVisible(true);
    }

    return undefined;
  }, [active, visible]);

  return visible ? (
    <div className={classNames('cookie-banner-container', { active })}>
      <div className="cookie-banner">
        <div className="message">
          {Strings.message}&nbsp;
          <a 
            href="https://completeconcussions.com/privacy-policy/" 
            target="_blank"
            rel="noopener"
          >
            {Strings.learnMoreLink}
          </a>
        </div>
        <button 
          type="button" 
          className="btn-close" 
          title={Strings.closeBannerTitle}
          aria-label={Strings.closeBannerTitle}
          onClick={setHidden}
        >
          <Icon name="xmark" />
        </button>
      </div>
    </div>
  ) : null;
};

export default CookieBanner;

import React, { useState, useMemo } from 'react';
import { FormComponent, SelectInputGroup } from '../../forms';
import { getCurrentUserLanguage } from '../../utilities/localization';
import Markdown from '../markdown';

const getPath = (root, sport) => {
  const lang = getCurrentUserLanguage();
  const time = Date.now();
  const rootPath = `/documents/help/${root}/sports/${sport}/${lang}`;

  return `${rootPath}/index.markdown?${time}`;
};

const SportContent = ({ rootPath, sport = null }) => {
  const path = useMemo(() => getPath(rootPath, sport), [rootPath, sport]);

  return (
    <Markdown.Remote path={path} />
  );
};

const SportSelector = ({ 
  rootPath, 
  label, 
  placeholder, 
  options 
}) => {
  const [selected, setSelected] = useState('');

  return (
    <div>
      <div className="bold">{label}</div>
      <FormComponent>
        <SelectInputGroup 
          inputValid
          labelText=" "
          className="form-group"
          inputProps={{
            className: 'form-control',
            value: selected,
          }}
          onUpdate={setSelected}
          touched={false}
        >
          <option value="">{placeholder}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </SelectInputGroup>
      </FormComponent>
      {selected && (
        <SportContent 
          rootPath={rootPath}
          sport={selected} 
        />
      )}
    </div>
  );
};

export default SportSelector;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Outlet, Link } from 'react-router-dom';
import { If, Then } from 'react-if';
import classnames from 'classnames';
import { getUserAsync } from '../../redux/thunks/users';
import { getClinicAsync } from '../../redux/thunks/clinics';
import { updateClinicPlayersSearch } from '../../redux/actions/clinic-players-search';
import { getMedicalInfoAsync } from '../../redux/thunks/medical-info';
import { getInjuriesAsync, getActiveInjuryAsync } from '../../redux/thunks/injuries';
import { getUserBaselineTestsAsync } from '../../redux/thunks/user-baseline-tests';
import { ClinicPaths, DashboardPaths } from '../../paths';
import replaceLinkParams from '../../utilities/replace-link-params';
import { Page } from '../page';
import Tabs from '../tabs';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import { PatientName } from '../patient-meta';
import { buildTabsFromRoles, Permissions, makeTabMeta } from './patient-profile-utils';
import { userHasAdminRole, userHasRoleMatchingOneOfDescriptions } from '../../utilities/user-roles';
import { withRouter } from '../../utilities/router-utils';
import ScrollTop from '../scroll-top';
import PatientProfileHeader from './patient-profile-header';
import Strings from './lang';
import { ClinicSelector, UserSelector } from '../../redux/selectors';
import Icon from '../icon';

const Breadcrumbs = ({
  user,
  clinicId
}) => {
  return (
    <ol className="breadcrumb">
      <li>
        <Link
          to={DashboardPaths.index.link}
        >
          {Strings.dashboardTitleText}
        </Link>
      </li>
      <li>
        <Link
          to={replaceLinkParams(ClinicPaths.patients.link, {
            clinicId
          })}
        >
          {Strings.managePatientsTitleText}
        </Link>
      </li>
      <li className="active">
        <PatientName
          patient={user || {}}
          clinicId={clinicId}
          redactedText={user?.account}
        />
      </li>
    </ol>
  );
};

class PatientProfileNavigation extends Component {
  constructor(props) {
    super(props);

    const { params = {}, currentUser } = props;
    const { userId, clinicId } = params;
    const tabs = buildTabsFromRoles(userId, clinicId, currentUser);

    this.state = {
      tabs,
      activity: true,
      error: null
    };
  }

  componentDidMount() {
    const { params = {}, router, location } = this.props;
    const { userId, clinicId } = params;

    const baseRoute = replaceLinkParams(ClinicPaths.patientProfile.index.link, params);

    if (baseRoute === location.pathname && this.state.tabs[0]) {
      setTimeout(() => {
        router.replace(this.state.tabs[0].route);
      }, 1);
    }

    this.getPatientData(clinicId, userId);
  }

  componentDidUpdate(prevProps) {
    const { currentUser = {} } = prevProps;
    const prevParams = prevProps.params || {};
    const curParams = this.props.params || {};

    if (curParams.userId && prevParams.userId !== curParams.userId) {
      this.setState({
        tabs: buildTabsFromRoles(curParams.userId, curParams.clinicId, currentUser)
      });

      this.getPatientData(curParams.clinicId, curParams.userId);
    }
  }

  render() {
    const { user, clinic, params } = this.props;
    const { tabs } = this.state;

    return (
      <Page className={classnames('user-profile', { 'inactive-profile': user?.active === false })}>
        <If condition={user?.active === false}>
          <Then>
            <div className="account-deactivated-banner no-print">
              <div className="banner-content">
                <Icon name="circle-info" />&nbsp;&nbsp;{Strings.accountDeactivatedBannerMessage}
              </div>
            </div>
          </Then>
        </If>

        <Activity
          static
          active={this.state.activity}
          titleComponent={<h1>{Strings.loadingPatientProfileText}</h1>}
        >
          <Breadcrumbs
            user={user}
            clinicId={params.clinicId}
          />

          <PatientProfileHeader
            user={user}
            clinic={clinic}
          />

          <ErrorBanner error={this.state.error} />

          <Tabs.Routable
            className="patient-profile-tabs"
            disableContextMenu
            tabs={tabs}
          >
            <div className="component-view">
              <Outlet />
            </div>
            <ScrollTop />
          </Tabs.Routable>
        </Activity>
      </Page>
    );
  }

  getPatientData(clinicId, userId) {
    this.setState({
      activity: true,
      error: null
    });

    const requests = this.getPatientFileRequests(clinicId, userId);

    Promise.all(requests).then(responses => {
      const [, user = {}] = responses;
      const { tabs } = this.state;

      if (user.unassessed_injury_report) {
        /* add one to account for index 0 being marked as false */
        const reportedTab = tabs.findIndex(tab => tab.key === 'reported');

        if (reportedTab && reportedTab >= 0) {
          tabs[reportedTab].tabClassName = 'alert error';
        }
      }

      this.setState({
        tabs,
        activity: false
      });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  getPatientFileRequests(clinicId, userId) {
    const { tabs } = this.state;
    const { currentUser } = this.props;
    const tabMeta = makeTabMeta(userId, clinicId);
    const tabKeys = Object.keys(tabMeta);
    const requests = [
      this.props.getClinic(clinicId)
    ];

    if (userHasAdminRole(currentUser)) {
      requests.push(Promise.resolve({}));
    } else {
      requests.push(this.props.getUser(userId));
    }

    const ignoredTabs = ['profile', 'sports', 'baselines', 'danaTests', 'reported'];

    tabKeys.forEach(tab => {
      if (!ignoredTabs.includes(tab)) {
        if (tabs.find(tabItem => tabItem.key === tab)) {
          switch (tab) {
            case 'medical':
              requests.push(this.props.getMedicalInfo(userId));
              break;
            case 'injuries':
              /**
               * If has permission to view all then fetch all otherwise fetch active,
               * Users will no permission -> this tab is never called
               */
              if (userHasRoleMatchingOneOfDescriptions(
                currentUser,
                Permissions.makePlayerInjuriesViewerRoles(clinicId)
              )) {
                requests.push(this.props.getInjuries(userId));
              } else {
                requests.push(this.props.getActiveInjury(userId));
              }
              break;
            default:
              requests.push(Promise.resolve({}));
              break;
          }
        } else {
          /* no item added this iteration. To keep the order, push an empty object */
          requests.push(Promise.resolve({}));
        }
      }
    });

    return requests;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { params } = ownProps;
  const currentUser = UserSelector.getCurrentUser(state);
  const clinic = ClinicSelector.getClinic(state, { id: params.clinicId });
  const user = UserSelector.getUser(state, { userId: params.userId });

  return { 
    user, 
    clinic,
    currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (userId) => {
      return dispatch(getUserAsync(userId));
    },
    getClinic: (clinicId) => {
      return dispatch(getClinicAsync(clinicId));
    },
    getMedicalInfo: (userId) => {
      return dispatch(getMedicalInfoAsync(userId));
    },
    getUserBaselines: (userId) => {
      return dispatch(getUserBaselineTestsAsync(userId));
    },
    getInjuries: (userId) => {
      return dispatch(getInjuriesAsync(userId));
    },
    getActiveInjury: (userId) => {
      return dispatch(getActiveInjuryAsync(userId));
    },
    updateClinicPlayersSearch: (attributes, results) => {
      dispatch(updateClinicPlayersSearch(attributes, results));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PatientProfileNavigation)
);

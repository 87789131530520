import React, { Component } from 'react';
import { If, Then } from 'react-if';
import { SymptomLevels, makeDefaultSymptoms, mergeSymptoms } from '../../symptoms';
import Card from '../../card';
import HeaderStrings from '../lang';
import Strings from './lang';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import { FormComponent } from '../../../forms';

class SymptomLevelsCard extends Component {
  constructor(props) {
    super(props);

    const { symptomLevels = {}, defaultSymptomsValue = '' } = props;

    /* merge the symptoms and copy the values to a new object */
    const symptoms = JSON.parse(
      JSON.stringify(
        mergeSymptoms(
          makeDefaultSymptoms(defaultSymptomsValue), 
          symptomLevels
        )
      )
    );

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      symptoms,
      submitted: false,
    };
  }

  render() {
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card title={HeaderStrings.symptomLevelsHeader}>
          <div className="card card-show">
            <p className="text-muted">{Strings.symptomLevelsInfo}</p>
          </div>
          <SymptomLevels
            type="radio" 
            className="baseline-card"
            symptoms={this.state.symptoms} 
            onUpdate={this.onUpdate}
            touched={this.state.submitted}
          />
          <If condition={typeof this.props.onSubmit === 'function'}>
            <Then>
              <div className="card form-footer">
                <button type="submit" className="btn btn-primary">
                  {this.props.saveButtonText || Strings.saveButtonText}
                </button>
              </div>
            </Then>
          </If>
        </Card>
      </FormComponent>
    );
  }

  onUpdate(symptoms) {
    this.setState({
      symptoms
    });
  }

  isValid() {
    const { symptoms } = this.state;
    return Object.values(symptoms).every(symptom => symptom.level !== '');
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const { symptoms } = this.state;

    const attributes = {
      pre_existing_symptoms: {
        ...symptoms
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }
}

SymptomLevelsCard.propTypes = {
  ...BaselineTestCardPropTypes
};

export default SymptomLevelsCard;

import React from 'react';
import PropTypes from 'prop-types';
import MedicalListInput from './medical-list-input';
import { FormInputMessage } from '../../forms';

const MedicalListGroup = ({
  label,
  children,
  inputProps,
  onItemAdded,
  error,
  message
}) => (
  <div className="form-list-input-group">
    <label>{label}</label>
    <div className="form-list-input">
      {children}
      <MedicalListInput
        inputProps={{
          className: 'form-control',
          ...inputProps
        }}
        onAdd={onItemAdded}
      />
    </div>
    <FormInputMessage
      className="alert alert-danger"
      visible={!!error}
      text={message}
    />
  </div>
);

MedicalListGroup.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  onItemAdded: PropTypes.func
};

export default MedicalListGroup;

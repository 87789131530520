import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { SelectInputGroup, FormInputGroup } from '../../forms';
import Strings from './lang';

const SportsTeamPositionInputs = ({
  sport,
  primaryPosition,
  otherPrimaryPosition,
  secondaryPosition,
  otherSecondaryPosition,
  onUpdateField,
  onUpdateSelect,
  submitted
}) => (
  <div className="row">
    <div className="col-md-6">

      <If condition={sport.positions.length > 0}>
        <Then>
          <SelectInputGroup
            className="form-group primary-position-input-group"
            labelText={Strings.primaryPositionLabelText}
            inputProps={{
              className: 'form-control',
              value: primaryPosition
            }}
            onUpdate={(value) => onUpdateSelect('primaryPosition', value)}
            messageText={Strings.positionErrorText}
            messageClassName="alert alert-danger"
            inputValid={primaryPosition.length > 0}
            required
            touched={submitted}
          >
            <option value="">{Strings.positionSelectOptionText}</option>
            {sport.positions.map(position => (
              <option key={position.key} value={position.key}>
                {position.name}
              </option>
            ))}
            <option value="other">{Strings.otherOptionText}</option>
          </SelectInputGroup>
        </Then>
      </If>

      <If condition={primaryPosition === 'other'}>
        <Then>
          <FormInputGroup
            className="form-group other-primary-position-input-group"
            labelText={
              (sport.positions.length > 0)
                ? Strings.otherPrimaryPositionLabelText
                : Strings.primaryPositionLabelText
            }
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: otherPrimaryPosition,
              onChange: onUpdateField.bind(this, 'otherPrimaryPosition')
            }}
            messageText={Strings.otherPositionErrorText}
            messageClassName="alert alert-danger"
            inputValid={primaryPosition !== 'other' || otherPrimaryPosition.length > 0}
            required={primaryPosition === 'other'}
            touched={submitted}
          />
        </Then>
      </If>

    </div>
    <div className="col-md-6">

      <If condition={sport.positions.length > 0}>
        <Then>
          <SelectInputGroup
            className="form-group secondary-position-input-group"
            labelText={Strings.secondaryPositionLabelText}
            inputProps={{
              className: 'form-control',
              value: secondaryPosition
            }}
            onUpdate={(value) => onUpdateSelect('secondaryPosition', value)}
            inputValid
            required={false}
            touched={submitted}
          >
            <option value="">{Strings.positionSelectOptionText}</option>
            {sport.positions.map(position => (
              <option key={position.key} value={position.key}>
                {position.name}
              </option>
            ))}
            <option value="other">{Strings.otherOptionText}</option>
          </SelectInputGroup>
        </Then>
      </If>

      <If condition={secondaryPosition === 'other'}>
        <Then>
          <FormInputGroup
            className="form-group other-secondary-position-input-group"
            labelText={
              (sport.positions.length > 0)
                ? Strings.otherSecondaryPositionLabelText
                : Strings.secondaryPositionLabelText
            }
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: otherSecondaryPosition,
              onChange: onUpdateField.bind(this, 'otherSecondaryPosition')
            }}
            messageText={Strings.otherPositionErrorText}
            messageClassName="alert alert-danger"
            inputValid={secondaryPosition !== 'other' || sport.positions.length === 0 || otherSecondaryPosition.length > 0}
            required={secondaryPosition === 'other' && sport.positions.length > 0}
            touched={submitted}
          />
        </Then>
      </If>
    </div>
  </div>
);

SportsTeamPositionInputs.propTypes = {
  sport: PropTypes.object.isRequired,
  primaryPosition: PropTypes.string.isRequired,
  otherPrimaryPosition: PropTypes.string.isRequired,
  secondaryPosition: PropTypes.string.isRequired,
  otherSecondaryPosition: PropTypes.string.isRequired,
  onUpdateSelect: PropTypes.func.isRequired,
  onUpdateField: PropTypes.func.isRequired,
  submitted: PropTypes.bool
};

export default SportsTeamPositionInputs;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Strings from './lang';
import { EmailValidator } from '../../forms/validators/email-validator';
import { UrlValidator, SocialUrlValidator } from '../../forms/validators/url-validator';
import {
  FormComponent,
  FormInputGroup,
  EmailInputGroup,
  UrlInputGroup
} from '../../forms';

const isSocialUrlValid = (url, type) => {
  return url ? SocialUrlValidator[type].test(url) : true;
};

class EditContactInfoCardForm extends Component {
  constructor(props) {
    super(props);

    const phone = props.clinic.phone || '';
    const fax = props.clinic.fax || '';
    const clinicUrl = props.clinic.website || '';
    const clinicUrlValid = clinicUrl.length === 0 || UrlValidator.test(clinicUrl);
    const email = props.clinic.email || '';
    const emailValid = email.length === 0 || EmailValidator.test(email);
    const facebookID = props.clinic.facebook_id || '';
    const twitterID = props.clinic.twitter_id || '';
    const instagramID = props.clinic.instagram_id || '';

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdatePhone = this.onUpdateInput.bind(this, 'phone');
    this.onUpdateFax = this.onUpdateInput.bind(this, 'fax');
    this.onUpdateFacebookId = this.onUpdateInput.bind(this, 'facebookID');
    this.onUpdateTwitterId = this.onUpdateInput.bind(this, 'twitterID');
    this.onUpdateInstagramId = this.onUpdateInput.bind(this, 'instagramID');

    this.state = {
      submitted: false,
      phone,
      fax,
      clinicUrl,
      clinicUrlValid,
      email,
      emailValid,
      facebookID,
      twitterID,
      instagramID
    };
  }

  render() {
    return (
      <FormComponent className="card card-form contact-card-form" onSubmit={this.onSubmit}>

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.phoneLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.phone,
            onBlur: this.onUpdatePhone,
            onChange: this.onUpdatePhone
          }}
          inputValid={this.state.phone.length > 0}
          messageText={Strings.phoneValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group fax-input-group"
          labelText={Strings.faxLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.fax,
            onBlur: this.onUpdateFax,
            onChange: this.onUpdateFax
          }}
          inputValid
          messageText={Strings.faxValidationMessage}
          messageClassName="alert alert-danger"
          required={false}
          touched={this.state.submitted}
        />

        <UrlInputGroup
          className="form-group clinic-url-input-group"
          labelText={Strings.clinicUrlLabelText}
          inputProps={{
            className: 'form-control',
            value: this.state.clinicUrl
          }}
          onUpdate={(clinicUrl, clinicUrlValid) => {
            this.setState({ clinicUrl, clinicUrlValid });
          }}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <EmailInputGroup
          className="form-group"
          labelText={Strings.emailLabelText}
          inputProps={{
            className: 'form-control',
            value: this.state.email
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group facebook-input-group"
          labelText={Strings.facebookLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.facebookID,
            onBlur: this.onUpdateFacebookId,
            onChange: this.onUpdateFacebookId
          }}
          messageClassName="alert alert-danger"
          messageText={Strings.formatString(
            Strings.socialUrlErrorMessage,
            'Facebook',
            'https://www.facebook.com/myclinicprofile'
          )}
          inputValid={isSocialUrlValid(this.state.facebookID, 'facebook')}
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group twitter-input-group"
          labelText={Strings.twitterLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.twitterID,
            onBlur: this.onUpdateTwitterId,
            onChange: this.onUpdateTwitterId
          }}
          messageClassName="alert alert-danger"
          messageText={Strings.formatString(
            Strings.socialUrlErrorMessage,
            'Twitter',
            'https://twitter.com/myclinicprofile'
          )}
          inputValid={isSocialUrlValid(this.state.twitterID, 'twitter')}
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group instagram-input-group"
          labelText={Strings.instagramLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.instagramID,
            onBlur: this.onUpdateInstagramId,
            onChange: this.onUpdateInstagramId
          }}
          messageClassName="alert alert-danger"
          messageText={Strings.formatString(
            Strings.socialUrlErrorMessage,
            'Instagram',
            'https://www.instagram.com/myclinicprofile'
          )}
          inputValid={isSocialUrlValid(this.state.instagramID, 'instagram')}
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  onUpdateInput(field, e) {
    const { value } = e.target;
    this.setState({
      [field]: value
    });
  }

  isValid() {
    return this.state.emailValid
      && this.state.phone.length
      && (this.state.clinicUrlValid || this.state.clinicUrl.length === 0)
      && isSocialUrlValid(this.state.facebookID, 'facebook')
      && isSocialUrlValid(this.state.twitterID, 'twitter')
      && isSocialUrlValid(this.state.instagramID, 'instagram');
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      phone,
      fax,
      clinicUrl,
      clinicUrlValid,
      email,
      emailValid,
      facebookID,
      twitterID,
      instagramID
    } = this.state;

    const attributes = {
      website: (clinicUrlValid) ? clinicUrl : '',
      email: (emailValid) ? email : '',
      phone,
      fax,
      facebook_id: facebookID,
      twitter_id: twitterID,
      instagram_id: instagramID
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

EditContactInfoCardForm.propTypes = {
  clinic: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EditContactInfoCardForm;

import React from 'react';
import PropTypes from 'prop-types';
import NavigationDropdown from './navigation-dropdown';
import Languages from '../../languages';
import Strings from './lang';
import Icon from '../icon';

const LanguageSelector = ({
  onSelectLanguage = () => {}
}) => (
  <NavigationDropdown
    header={(
      <div className="navigation-languages-header">
        <Icon name="language" />
        <span>{Strings.languageLinkText}</span>
      </div>
    )}
  >
    {Strings.getAvailableLanguages().map(lang => {
      return (
        <li key={lang}>
          <a 
            href="#" 
            onClick={(e) => {
              e.preventDefault();
              onSelectLanguage(lang);
            }}
          >
            <Icon name="earth-america" className="primary" />
            <span>{Languages[lang]}</span>
          </a>
        </li>
      );
    })}
  </NavigationDropdown>
);

LanguageSelector.propTypes = {
  onSelectLanguage: PropTypes.func.isRequired
};

export default LanguageSelector;

import React, { useMemo } from 'react';
import { Else, If, Then } from 'react-if';
import PropTypes from 'prop-types';
import BaselineSection from './baseline-section';
import Strings from './lang';
import {
  FAILURE_REASON_STRINGS,
  getAverageTrialResponseRate,
  getTrialResponseRate
} from '../test-components/dual-task/utils';
import { sortAscending } from '../../../utilities/sort';
import Icon from '../../icon';

const getDurationStats = (trials) => {
  const mappedTrials = trials.map((t, i) => ({ ...t, i }));
  const completedTrials = mappedTrials.filter(t => !isNaN(t.duration) && t.duration > 0);
  const total = completedTrials.reduce((acc, trial) => (
    (trial.duration || 0) + acc
  ), 0);
  const avgTime = (total / (completedTrials.length || 1)) / 1000;
  const fastestIndex = completedTrials.sort(sortAscending.bind(null, 'duration'))[0]?.i;

  return { avgTime, fastestIndex };
};

const TrialRow = ({
  trial,
  index,
  isFastest
}) => {
  return (
    <tr>
      <td>{index}</td>
      <td>{trial.start}</td>
      <td>
        <Icon name={trial.failed ? 'xmark' : 'check'} className={trial.failed ? 'error' : 'success'} />&nbsp;
        <span>
          {!trial.failed
            ? Strings.passedText
            : trial.failed_reasons?.map(key => FAILURE_REASON_STRINGS[key]).join(', ')}
        </span>
      </td>
      <td>{trial.responses.length}</td>
      <td>{trial.responses.length - trial.errors}</td>
      <td>{trial.errors}</td>
      <td>
        {((trial.responses.length - trial.errors) / (trial.responses.length || 1)).toFixed(2)}
      </td>
      <td className={isFastest ? 'success' : null}>
        {(trial.duration / 1000).toFixed(2)}{isFastest && ` (${Strings.fastestTimeLabel})`}
      </td>
      <td>{getTrialResponseRate(trial).toFixed(2)}</td>
    </tr>
  );
};

const TrialsTable = ({ trials = [] }) => {
  const passedTrials = useMemo(() => trials.filter(trial => trial.failed !== true), [trials]);
  const totalAttempted = useMemo(() => (
    passedTrials.reduce((acc, trial) => acc + (trial.responses?.length ?? 0), 0)
  ), [passedTrials]);
  const totalErrors = useMemo(() => (
    passedTrials.reduce((acc, trial) => acc + (trial.errors || 0), 0)
  ), [passedTrials]);
  const { avgTime, fastestIndex } = useMemo(() => getDurationStats(passedTrials), [passedTrials]);

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="no-action">{Strings.trialColumnLabel}</th>
          <th className="no-action">{Strings.startIntegerLabel}</th>
          <th className="no-action">{Strings.passFailColumnLabel} ({Strings.reasonLabel})</th>
          <th className="no-action">{Strings.attemptedLabel}</th>
          <th className="no-action">{Strings.correctLabel}</th>
          <th className="no-action">{Strings.errorsLabel}</th>
          <th className="no-action">{Strings.dualTaskCognitiveAccuracyLabel}</th>
          <th className="no-action">{Strings.durationSecondsColumnLabel}</th>
          <th className="no-action">{Strings.dualTaskResponseRateLabel}</th>
        </tr>
      </thead>
      <tbody>
        {trials.map((trial, i) => (
          <TrialRow
            key={i}
            trial={trial}
            index={i + 1}
            isFastest={fastestIndex === i}
          />
        ))}
        <tr className="totals">
          <td colSpan={3}><strong>{Strings.totalLabelText}</strong></td>
          <td><strong>{totalAttempted}</strong></td>
          <td><strong>{totalAttempted - totalErrors}</strong></td>
          <td><strong>{totalErrors}</strong></td>
          <td>
            <strong>
              {Strings.averageShortLabelText}&nbsp;
              {((totalAttempted - totalErrors) / (totalAttempted || 1)).toFixed(2)}
            </strong>
          </td>
          <td>
            <strong>
              {Strings.averageShortLabelText} {avgTime?.toFixed(2)} {Strings.secondsLabel}
            </strong>
          </td>
          <td>
            <strong>
              {Strings.averageShortLabelText} {getAverageTrialResponseRate(trials).toFixed(2)}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const BaselineDualTask = ({
  data = {},
}) => {
  const {
    notes,
    not_done = false,
    step,
    unable_to_complete = false,
    trials = []
  } = data || {};

  return (
    <BaselineSection
      className="baseline-dual-task"
      title={Strings.dualTaskTitle}
      notes={notes}
      sectionCompleted={Object.keys(data || {}).length > 0 && !not_done}
    >
      <If condition={unable_to_complete}>
        <Then>
          <p className="text-center">{Strings.unableToCompleteLabel}</p>
        </Then>
        <Else>
          <p>{Strings.subtractionValueLabel}: {step}</p>
          <TrialsTable trials={trials} />
        </Else>
      </If>
    </BaselineSection>
  );
};

BaselineDualTask.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineDualTask;

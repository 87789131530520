import React, { useCallback, useMemo, useState } from 'react';
import {
  FormComponent,
  FormInputGroup,
  UrlInputGroup,
  CheckboxInputGroup,
  MultiSelectInputGroup
} from '../../../forms';
import PriceInput from './price-input';
import PartnerSelector from './partner-selector';
import VideoUrlInput from './video-url-input';
import ProductImages from './product-images';
import { getProductState, isValid, convertAttributes } from './utils';
import { ProductCategories } from '../utils';
import { useObjectState } from '../../../hooks';
import Editor from '../../../editor';
import Strings from '../lang';

const CategoryKeys = Object.keys(ProductCategories);
const CategoryOptions = CategoryKeys.map(key => ({ value: key, label: ProductCategories[key] }));

const ProductForm = ({ product, onSubmit = () => { } }) => {
  const [submitted, setSubmitted] = useState(false);
  const [attributes, setAttributes] = useObjectState(() => getProductState(product));
  const hasImages = useMemo(() => (
    attributes.images.filter(img => !img._destroy).length > 0
  ), [attributes.images]);
  const valid = useMemo(() => isValid(attributes, hasImages), [attributes, hasImages]);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!valid) {
      return;
    }

    onSubmit(convertAttributes(attributes));
  }, [attributes, valid, onSubmit]);

  return (
    <FormComponent className="product-form" onSubmit={handleSubmit}>
      <h2>{Strings.productInformationSectionTitle}</h2>
      <FormInputGroup
        required
        className="form-group"
        labelText={Strings.productNameLabel}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: attributes.name,
          onChange: (e) => setAttributes({ name: e.target.value })
        }}
        messageText={Strings.productNameErrorMessage}
        messageClassName="alert alert-danger"
        inputValid={attributes.name.length > 0}
        touched={submitted}
      />
      <PartnerSelector 
        value={attributes.partner}
        onChange={partner => setAttributes({ partner })}
      />
      <MultiSelectInputGroup 
        required
        label={Strings.productCategoriesLabel}
        value={attributes.categories}
        options={CategoryOptions}
        touched={submitted}
        placeholder={Strings.productCategoriesPlaceholder}
        onChange={(categories) => setAttributes({ categories })}
      />
      <UrlInputGroup
        required
        className="form-group"
        labelText={Strings.productUrlLabel}
        inputProps={{
          className: 'form-control',
          value: attributes.link
        }}
        onUpdate={(link, linkValid) => {
          setAttributes({ link, linkValid });
        }}
        messageClassName="alert alert-danger"
        touched={submitted}
      />
      <div className="form-group">
        <label>{Strings.productDescriptionLabel} <span className="required">*</span></label>
        <Editor
          value={attributes.description}
          namespace="product-description"
          placeholder={Strings.descriptionPlaceholder}
          onChange={(description) => setAttributes({ description })}
        />
      </div>
      <h2>{Strings.pricingSectionTitle}</h2>
      <div className="row">
        <div className="col-md-4">
          <PriceInput
            value={attributes.price}
            touched={submitted}
            currency={attributes.currency}
            onChange={(price) => setAttributes({ price })}
            onCurrencyChange={(currency) => setAttributes({ currency })}
          />
        </div>
        <div className="col-md-4">
          <FormInputGroup
            required={false}
            className="form-group"
            labelText={Strings.discountLabel}
            inputType="number"
            inputProps={{
              className: 'form-control',
              value: attributes.discount,
              placeholder: Strings.discountPlaceholder,
              onChange: (e) => (
                setAttributes({ discount: e.target.value ? e.target.valueAsNumber : '' })
              )
            }}
            inputValid
            touched={submitted}
          />
        </div>
        <div className="col-md-4">
          <FormInputGroup
            required={false}
            className="form-group"
            labelText={Strings.couponCodeLabel}
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: attributes.coupon,
              onChange: (e) => (
                setAttributes({ coupon: e.target.value })
              )
            }}
            inputValid
            touched={submitted}
          />
        </div>
      </div>
      <h2>{Strings.productVideoSectionTitle}</h2>
      <VideoUrlInput 
        url={attributes.videoUrl}
        valid={attributes.videoValid}
        touched={submitted}
        onChange={(videoUrl, videoValid) => setAttributes({ videoUrl, videoValid })}
      />
      <h2>{Strings.productImagesSectionTitle}</h2>
      {!hasImages && (
        <small className="error"><i>{Strings.productImagesHintMessage}</i></small>
      )}
      <ProductImages 
        images={attributes.images}
        onAddImages={images => setAttributes(prev => ({ images: [...prev.images, ...images] }))}
        onDeleteImage={(image, index) => {
          setAttributes(prev => {
            const newImages = [...prev.images];
            if (image.id) {
              newImages[index]._destroy = true;
            } else {
              newImages.splice(index, 1);
            }

            return { images: newImages };
          });
        }}
      />
      <div className="form-footer-banner">
        <div className="container">
          <div className="flex-row align_center justify_end">
            {!product?.id && (
              <CheckboxInputGroup
                inputValid
                className="form-group"
                labelText={Strings.publishProductLabel}
                inputProps={{
                  className: 'form-control',
                  checked: attributes.published,
                  onChange: e => {
                    setAttributes({
                      published: e.target.checked
                    });
                  }
                }}
              />
            )}
            <button type="submit" className="btn btn-primary" disabled={!valid}>
              {Strings.submitButtonLabel}
            </button>
          </div>
        </div>
      </div>
    </FormComponent>
  );
};

export default ProductForm;

import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Activity from '../activity';
import { showAlert } from '../alert-notifications';
import ReferralCard from './referral-card';
import { ReferralsPath } from '../../paths';
import {
  acceptReferralAsync,
  rejectReferralAsync,
  cancelReferralAsync
} from '../../redux/thunks/referrals';
import Strings from './lang';

const MAX_COLUMNS = 12;
const DEFAULT_COLUMNS = 2;

const ReferralsList = ({ 
  colums = DEFAULT_COLUMNS, 
  linksEnabled = true,
  referrals 
}) => {
  const gridSize = Math.floor(MAX_COLUMNS / colums);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onAccept = useCallback((referralId, message) => {
    setLoading(true);

    const attributes = {
      message,
      referral_url: `${process.env.CCMI_UI_HOST}${ReferralsPath.email.link}`
    };

    dispatch(acceptReferralAsync(referralId, attributes)).then(() => {
      setLoading(false);
      showAlert('success', {
        dismissable: true,
        autoDismiss: 4000,
        message: Strings.referralAcceptedAlert
      });
    }).catch(error => {
      setLoading(false);
      showAlert('error', {
        dismissable: true,
        autoDismiss: 6000,
        message: error.message
      });
    });
  }, [dispatch]);

  const onReject = useCallback((referralId, message) => {
    setLoading(true);

    const attributes = {
      message,
      referral_url: `${process.env.CCMI_UI_HOST}${ReferralsPath.email.link}`
    };

    dispatch(rejectReferralAsync(referralId, attributes)).then(() => {
      setLoading(false);
      showAlert('success', {
        dismissable: true,
        autoDismiss: 4000,
        message: Strings.referralRejectedAlert
      });
    }).catch(error => {
      setLoading(false);
      showAlert('error', {
        dismissable: true,
        autoDismiss: 6000,
        message: error.message
      });
    });
  }, [dispatch]);

  const onCancel = useCallback((referralId) => {
    setLoading(true);

    dispatch(cancelReferralAsync(referralId)).then(() => {
      setLoading(false);
      showAlert('success', {
        dismissable: true,
        autoDismiss: 4000,
        message: Strings.referralCancelledAlert
      });
    }).catch(error => {
      setLoading(false);
      showAlert('error', {
        dismissable: true,
        autoDismiss: 6000,
        message: error.message
      });
    });
  }, [dispatch]);

  return (
    <Activity active={loading}>
      <div className="row referrals-list">
        {referrals.map(referral => (
          <div 
            key={referral.id} 
            className={`col-md-${gridSize}`}
          >
            <ReferralCard 
              referral={referral} 
              linksEnabled={linksEnabled}
              onAccept={onAccept}
              onReject={onReject}
              onCancel={onCancel}
            />
          </div>
        ))}
      </div>
    </Activity>
  );
};

export default ReferralsList;

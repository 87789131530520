import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Confirmation from '../confirmation';
import ClinicDeactivatedBanner from '../clinic-deactivated-banner';
import Header from './header';
import Footer from './footer';
import HTTPStatusCodes from '../../utilities/http-status-codes';
import { Error404Page } from '../errors';

const Page = ({
  hideBannerIfClinicDeactivated = false,
  className,
  hideNavigationBar,
  showHeaderImage,
  hideNavigationContent,
  showLanguageSelector,
  container = true,
  status = null,
  children
}) => (
  <div 
    className={classNames('page', className, {
      'nav-visible': !hideNavigationBar
    })}
  >
    <Confirmation />
    <Header
      showHeaderImage={showHeaderImage}
      hideNavigationBar={hideNavigationBar}
      hideNavigationContent={hideNavigationContent}
      showLanguageSelector={showLanguageSelector}
    />
    <ClinicDeactivatedBanner showIfDeactivated={!hideBannerIfClinicDeactivated} />
    <main 
      className={classNames({
        container: container === true,
        'container-fluid': container === 'fluid'
      })}
    >
      {status === HTTPStatusCodes.NotFound ? <Error404Page /> : children}
    </main>
    <Footer />
  </div>
);

Page.propTypes = {
  className: PropTypes.string,
  showHeaderImage: PropTypes.bool,
  hideNavigationBar: PropTypes.bool,
  hideNavigationContent: PropTypes.bool
};

export default Page;

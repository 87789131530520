import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUserId } from '../../tokens';
import { resolvePatientName } from './resolve-patient-name';
import Strings from './lang';

const DEFAULT_TAG_NAME = 'span';

const PatientName = ({
  currentUser = {},
  patient = {},
  clinicId = 0,
  redactedText = Strings.redactedText,
  lastNameFirst = false,
  tagName = DEFAULT_TAG_NAME,
  tagProps = {}
}) => {
  // Assign `tagName` to an uppercase variable to stop
  // React from interpreting it as an HTML tag:
  const Tag = tagName;

  return (
    <Tag {...tagProps}>
      {resolvePatientName({
        user: currentUser,
        patient,
        clinicId,
        redactedText,
        lastNameFirst
      })}
    </Tag>
  );
};

PatientName.propTypes = {
  patient: PropTypes.object.isRequired,
  clinicId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  tagName: PropTypes.string,
  tagProps: PropTypes.object,
  redactedText: PropTypes.string,
  lastNameFirst: PropTypes.bool
};

const mapStateToProps = (state) => {
  const { users = {} } = state;
  const currentUser = users[getCurrentUserId()] || {};
  return { currentUser };
};

const ConnectedPatientName = connect(
  mapStateToProps
)(PatientName);

export default ConnectedPatientName;

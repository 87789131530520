import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../modal';
import { decoupleChildAccountAsync, getUserAsync } from '../../../redux/thunks/users';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import { REGISTRATION_PASSWORD_RESET_URL } from '../../../utilities/urls';
import { EmailInputGroup, FormComponent } from '../../../forms';
import Strings from './lang';
import HTTPStatusCodes from '../../../utilities/http-status-codes';

const DecoupleChildForm = ({ onSubmit = () => { } }) => {
  const [email, setEmail] = useState({ value: '', valid: false });
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (email.valid) {
      onSubmit(email.value);
    }
  }, [email.valid, email.value, onSubmit]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <EmailInputGroup
        required
        className="form-group"
        labelText={Strings.emailLabelText}
        inputProps={{
          className: 'form-control',
          placeholder: 'user@email.com',
          value: email.value
        }}
        onUpdate={(email, valid) => {
          setEmail({ value: email, valid });
        }}
        messageClassName="alert alert-danger"
        touched={submitted}
      />
      <div className="form-footer">
        <button type="submit" className="btn btn-primary" disabled={!email.valid}>
          {Strings.decoupleSubmitButtonText}
        </button>
      </div>
    </FormComponent>
  );
};

const DecoupleChildAccountModal = ({
  isOpen,
  child = {},
  parentId,
  resetUrl = REGISTRATION_PASSWORD_RESET_URL,
  decoupleChild,
  getUser,
  onClose = () => { }
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleClose = useCallback(() => {
    setError(null);
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback((email) => {
    const attributes = {
      email,
      reset_url: resetUrl
    };

    setActivity(true);
    setError(null);

    decoupleChild(child?.id, attributes).then(() => {
      return parentId ? getUser(parentId) : Promise.resolve({});
    }).then(() => {
      setActivity(false);
      onClose();
    }).catch(error => {
      setActivity(false);

      if (error.status === HTTPStatusCodes.UnprocessableEntity) {
        setError(Strings.emailTakenErrorMessage);
      } else {
        setError(error.message);
      }
    });
  }, [child?.id, decoupleChild, getUser, onClose, parentId, resetUrl]);

  return (
    <Modal
      blur
      static={activity}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Modal.Header onClose={activity ? null : handleClose}>
        <h1>{Strings.decoupleChildModalTitle}</h1>
      </Modal.Header>
      <Modal.Body>
        <Activity active={activity}>
          <div>
            <p>
              {Strings.formatString(
                Strings.decoupleChildModalDescription,
                child?.person?.first_name || ''
              )}
            </p>
            <ErrorBanner error={error} />
            <DecoupleChildForm onSubmit={handleSubmit} />
          </div>
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

DecoupleChildAccountModal.propTypes = {
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resetUrl: PropTypes.string
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    decoupleChild: (childId, attributes) => {
      return dispatch(decoupleChildAccountAsync(childId, attributes, ownProps.requestOptions));
    },
    getUser: (id) => {
      return dispatch(getUserAsync(id, ownProps.requestOptions));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DecoupleChildAccountModal);

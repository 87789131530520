import React from 'react';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import Countries from '../../utilities/country-regions';
import Strings from './lang';
import Icon from '../icon';

const TaxesTable = ({ 
  taxes = [],
  onEditItem = () => {},
  onDeleteItem = () => {} 
}) => {
  return (
    <table className="table tax-table">
      <thead>
        <tr>
          <th>{Strings.regionLabel}</th>
          <th>{Strings.taxRateLabel}</th>
          <th>{Strings.taxCadeLabel}</th>
          <th>{Strings.lastUpdatedLabel}</th>
          <th>{Strings.actionsLabel}</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(taxes).map(country => {
          const countryData = Countries[country];
          const { regions, name } = countryData;

          return (
            <React.Fragment key={country}>
              <tr className="tax-country">
                <td colSpan={5}>
                  <h3>
                    <strong>{name}</strong>
                  </h3>
                </td>
              </tr>
              {taxes[country].map(tax => (
                <tr key={tax.state}>
                  <td>{regions[tax.state].name}</td>
                  <td>{tax.rate}</td>
                  <td>{tax.code}</td>
                  <td>{dayjs(tax.updated_at).format(DISPLAY_DATE_FORMAT)}</td>
                  <td className="tax-actions">
                    <button type="button" className="edit-button" onClick={() => onEditItem(tax)}>
                      <Icon name="pen-to-sauare" />
                    </button>
                    <button type="button" className="edit-button" onClick={() => onDeleteItem(tax)}>
                      <Icon name="trash-can" className="error" />
                    </button>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default TaxesTable;

const EN = {
  profileTabLabel: 'Profile',
  sportsTabLabel: 'Sports',
  medicalInfoTabLabel: 'Medical Info',
  baselineHistoryTabLabel: 'Baseline History',
  injuryHistoryTabLabel: 'Injury History',
  injuryReportsTabLabel: 'Injury Reports',
  managePatientsTitleText: 'Manage Patients',
  dashboardTitleText: 'Dashboard',
  accountDeactivatedBannerMessage: 'This account has been deactivated',
  loadingPatientProfileText: 'Loading Patient File',
  assignBagTagButtonText: 'Assign ID Card',
  sendImpactTestButtonText: 'Send Baseline ImPACT Test',
  danaTestTabLabel: 'DANA Tests'
};

export default EN;

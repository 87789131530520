import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { updateRegistrationRole } from '../../../redux/actions/registration';
import { completeRegistrationAsync } from '../../../redux/thunks/users';
import { RegistrationPaths } from '../../../paths';
import { RegistrationSteps, findCurrentStepIndex } from './helpers';
import ConvertToFamilyAccountHandler from './convert-to-family-account-handler';
import ProgressDots from '../../progress-dots';
import Activity from '../../activity';
import { 
  setRegistrationEmailSent, 
  getRegistrationEmailSent,
  getRegistrationCurrentUser,
  getRegistrationReturning,
  getCurrentRegistrationSession
} from '../../../utilities/registration-session';
import { RegistrationProvider } from './context';
import { useMount } from '../../../hooks';

const STEPS = RegistrationSteps.player;

const PlayerRegistrationRouteHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { step } = useSelector(state => state.registration || {});
  const user = useSelector(state => state.users[getRegistrationCurrentUser()] || {});
  const stepIndex = useMemo(() => findCurrentStepIndex(STEPS, step), [step]);
  const [activity, setActivity] = useState(false);

  const sendRegistrationCompletion = useCallback((email) => {
    const options = { headers: { Authorization: getCurrentRegistrationSession()?.token } };
    return dispatch(completeRegistrationAsync(getRegistrationReturning(), email, options));
  }, [dispatch]);

  const completeRegistration = useCallback((email) => {
    const emailSent = getRegistrationEmailSent();

    if (emailSent) {
      return Promise.resolve();
    }

    return sendRegistrationCompletion(email);
  }, [sendRegistrationCompletion]);

  const onRegistrationFinished = useCallback(() => {
    setActivity(true);

    completeRegistration(user.email).then(() => {
      setRegistrationEmailSent(true);
      setActivity(false);
      navigate(RegistrationPaths.finish.link);
    }).catch(() => {
      setActivity(false);
      navigate(RegistrationPaths.finish.link);
    });
  }, [completeRegistration, navigate, user.email]);

  const onNext = useCallback(() => {
    const step = STEPS[stepIndex + 1];
    if (!step) {
      onRegistrationFinished();
    } else {
      navigate(step.path);
    }
  }, [navigate, onRegistrationFinished, stepIndex]);

  useMount(() => {
    dispatch(updateRegistrationRole('player'));
  });

  return (
    <Activity active={activity}>
      <div className="row">
        <div className="col-md-12">
          <ProgressDots step={stepIndex} total={RegistrationSteps.player.length - 1} />
        </div>
        <div className="col-md-12">
          <ConvertToFamilyAccountHandler user={user}>
            <RegistrationProvider
              next={onNext}
              role="player"
            >
              <Outlet />
            </RegistrationProvider>
          </ConvertToFamilyAccountHandler>
        </div>
      </div>
    </Activity>
  );
};

export default PlayerRegistrationRouteHandler;

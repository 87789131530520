import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { daysSince, age } from '../../../../dates';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../../dates/formats';
import EditableDateLabel from './editable-date-label';
import Strings from './lang';
import GenderStrings from '../../../../gender';

class InjuryAssessmentHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      injuryDateEditing: false,
      assessmentDateEditing: false
    };
  }

  render() {
    const { 
      user = {}, injury = {}, assessment = {}, tester = {}, clinic = {} 
    } = this.props;
    const { person = {} } = user || {};
    const birthdate = dayjs(person.birthday, INPUT_DATE_FORMAT);
    const injuryDate = dayjs(injury.injured_at, INPUT_DATE_FORMAT);
    const testDate = dayjs(assessment.created_at, INPUT_DATE_FORMAT);
    const testPerson = tester?.person || {};
    const clinicAddress = clinic.address || {};

    return (
      <section className="test-header injury-assessment-header">

        <div className="row">
          <div className="col-md-6">
            <div className="test-header-row">
              <label>{Strings.practitionerLabel}:</label>
              <span>{testPerson.first_name} {testPerson.last_name}</span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="test-header-row">
              <label>{Strings.clinicLabelText}:</label>
              <div className="header-clinic-address">
                <span>{clinic.name}</span>
                <span>{clinicAddress.street_1}</span>
                <span>{clinicAddress.street_2}</span>
                <span>{clinicAddress.city}, {clinicAddress.state} {clinicAddress.postal_code}</span>
                <span>{clinicAddress.country}</span>
                <span><span className="bold">{Strings.phoneLabelText}</span> {clinic.phone}</span>
                <span><span className="bold">{Strings.faxLabelText}</span> {clinic.fax}</span>
              </div>
            </div>
          </div>
        </div>

        <h3>{Strings.patientInformationHeader}</h3>
        <div className="row">
          <div className="col-md-4">
            <div className="test-header-row">
              <label>{Strings.patientLabel}: </label>
              <span>{person.first_name} {person.last_name}</span>
            </div>
          </div>

          <div className="col-md-4">
            <div className="test-header-row">
              <label>{Strings.accountLabel}:</label>
              <span>{user?.account}</span>
            </div>
          </div>

          <div className="col-md-4">
            <div className="test-header-row">
              <label>{Strings.bagTagLabel}:</label>
              <span>{user?.bag_tag}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="test-header-row">
              <label>{Strings.birthdateLabel}:</label>
              <span>{birthdate.format(DISPLAY_DATE_FORMAT)}</span>
            </div>
          </div>

          <div className="col-md-4">
            <div className="test-header-row">
              <label>{Strings.ageLabel}:</label>
              <span>{age(birthdate) || null}</span>
            </div>
          </div>

          <div className="col-md-4">
            <div className="test-header-row">
              <label>{Strings.sexLabel}:</label>
              <span>{GenderStrings[person.gender]}</span>
            </div>
          </div>
        </div>

        <h3>{Strings.injuryInformationHeader}</h3>
        <div className="row">
          <div className="col-md-6">
            <EditableDateLabel
              labelClassName="test-header-row"
              formClassName="test-header-form-row"
              editing={this.state.injuryDateEditing}
              editable={!this.state.assessmentDateEditing}
              label={`${Strings.injuryDateLabel}:`}
              date={injuryDate.format(INPUT_DATE_FORMAT)}
              maxDate={testDate}
              errorMessage={
                Strings.formatString(
                  Strings.injuryDateErrorMessage,
                  testDate.format(DISPLAY_DATE_FORMAT)
                )
              }
              onEdit={() => this.setState({ injuryDateEditing: true })}
              onCancel={() => this.setState({ injuryDateEditing: false })}
              onSave={(date) => this.onSaveDate('injured_at', 'injuryDateEditing', date)}
            />
          </div>

          <div className="col-md-6">
            <EditableDateLabel
              labelClassName="test-header-row"
              formClassName="test-header-form-row"
              editing={this.state.assessmentDateEditing}
              editable={!this.state.injuryDateEditing}
              label={`${Strings.assessmentDateLabel}:`}
              date={testDate.format(INPUT_DATE_FORMAT)}
              minDate={injuryDate}
              maxDate={dayjs()}
              errorMessage={
                Strings.formatString(
                  Strings.assessmentDateErrorMessage,
                  injuryDate.format(DISPLAY_DATE_FORMAT),
                  dayjs().format(DISPLAY_DATE_FORMAT)
                )
              }
              onEdit={() => this.setState({ assessmentDateEditing: true })}
              onCancel={() => this.setState({ assessmentDateEditing: false })}
              onSave={(date) => this.onSaveDate('created_at', 'assessmentDateEditing', date)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="test-header-row">
              <label>{Strings.daysSinceInjuryLabel}:</label>
              <span>{daysSince(injuryDate, testDate) || null}</span>
            </div>
          </div>
        </div>

      </section>
    );
  }

  onSaveDate(attributeKey, editKey, date) {
    const attributes = {
      [attributeKey]: date
    };

    this.props.onSaveDate(attributes);

    this.setState({
      [editKey]: false
    });
  }
}

InjuryAssessmentHeader.propTypes = {
  user: PropTypes.object,
  tester: PropTypes.object,
  clinic: PropTypes.object,
  injury: PropTypes.object,
  assessment: PropTypes.object,
  onSaveDate: PropTypes.func
};

export default InjuryAssessmentHeader;

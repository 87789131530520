import React, { useState } from 'react';
import classNames from 'classnames';
import Markdown from '../markdown';
import { ovsResultTypeString } from './ovs-result-types';
import { getCurrentUserLanguage } from '../../utilities/localization';
import Strings from './lang';
import Icon from '../icon';

const getPath = () => {
  const lang = getCurrentUserLanguage();
  const time = Date.now();
  const rootPath = `/documents/help/ovs-test-results/${lang}`;

  return `${rootPath}/index.markdown?${time}`;
};

const ResultsCalculationView = ({ result }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className="ovs-calculations no-print">
      <button type="button" className="edit-button" onClick={() => setVisible(prev => !prev)}>
        <Icon fixedWidth name={`chevron-${visible ? 'down' : 'right'}`} />
        <span>
          {visible ? Strings.hideResultsCalculationLabel : Strings.showResultsCalculationLabel}
        </span>
      </button>
      {visible && (
        <div className={classNames('ovs-results-description', result)}>
          <Markdown.Remote path={getPath()} />
        </div>
      )}
    </div>
  );
};

const OrthostaticVitalSignsTestResultView = ({
  test,
  showCalculations = true
}) => {
  return (
    <div className="ovs-test-result">
      <strong>{Strings.resultLabel}:</strong>&nbsp;<span>{ovsResultTypeString[test.result]}</span>
      {showCalculations && (
        <ResultsCalculationView result={test.result} />
      )}
    </div>
  );
};

export default OrthostaticVitalSignsTestResultView;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
  CheckboxInputGroup, 
  FormInputGroup, 
  FormInputMessage, 
  NumberValidator 
} from '../../../../forms';
import Strings from './lang';

class AmnesiaOfEvent extends Component {
  constructor(props) {
    super(props);

    const { initialState = {} } = props;

    this.state = {
      ...initialState
    };
  }

  render() {
    return (
      <div>
        <label className="bold uppercase">{Strings.amnesiaOfEventLabel} <span className="required">*</span></label>
        <FormInputMessage
          className="alert alert-danger"
          visible={this.props.touched 
            && (!this.state.none && !this.state.retrograde && !this.state.anterograde)}
          text={Strings.noValueSelectedErrorMessage}
        />
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.noneLabel}
          inputProps={{
            className: 'form-control',
            checked: this.state.none || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'none')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.retrogradeAmnesiaLabel}
          inputProps={{
            className: 'form-control',
            checked: this.state.retrograde || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'retrograde')
          }}
          inputValid
        />
        {this.state.retrograde === true && (
          <FormInputGroup 
            className="form-group"
            labelText={Strings.timeInMinutesLabelText} 
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: this.state.minutes_retrograde,
              onBlur: this.onUpdateText.bind(this, 'minutes_retrograde'),
              onChange: this.onUpdateText.bind(this, 'minutes_retrograde')
            }}
            touched={this.props.touched}
            inputValid={NumberValidator.test(this.state.minutes_retrograde)}
            messageClassName="alert alert-danger"
            messageText={Strings.invalidNumberErrorMessage}
            required
          />
        )}
        <CheckboxInputGroup
          className="form-group"
          labelText={Strings.anterogradeAmnesiaLabel}
          inputProps={{
            className: 'form-control',
            checked: this.state.anterograde || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'anterograde')
          }}
          inputValid
        />
        {this.state.anterograde === true && (
          <FormInputGroup 
            className="form-group"
            labelText={Strings.timeInMinutesLabelText} 
            inputType="text"
            inputProps={{
              className: 'form-control',
              value: this.state.minutes_anterograde,
              onBlur: this.onUpdateText.bind(this, 'minutes_anterograde'),
              onChange: this.onUpdateText.bind(this, 'minutes_anterograde')
            }}
            touched={this.props.touched}
            inputValid={NumberValidator.test(this.state.minutes_anterograde)}
            messageClassName="alert alert-danger"
            messageText={Strings.invalidNumberErrorMessage}
            required
          />
        )}
      </div>
    );
  }

  onCheckBoxUpdate(key, e) {
    const { checked } = e.target;
    let { 
      none, anterograde, retrograde, minutes_anterograde, minutes_retrograde 
    } = this.state;

    switch (key) {
      case 'none':
        none = checked;
        /* reset all other values */
        if (none) {
          anterograde = false;
          retrograde = false;
          minutes_anterograde = '';
          minutes_retrograde = '';
        }
        break;
      case 'anterograde':
        none = false;
        anterograde = checked;
        break;
      case 'retrograde':
        none = false;
        retrograde = checked;
        break;
      default:
        break;
    }

    this.setState({
      none, anterograde, retrograde, minutes_anterograde, minutes_retrograde
    });

    this.props.onUpdate({ 
      none, anterograde, retrograde, minutes_anterograde, minutes_retrograde 
    });
  }

  onUpdateText(key, e) {
    const { value } = e.target;
    // eslint-disable-next-line prefer-const
    let { minutes_anterograde, minutes_retrograde, ...rest } = this.state;

    if (key === 'minutes_anterograde') {
      minutes_anterograde = value;
    } else if (key === 'minutes_retrograde') {
      minutes_retrograde = value;
    }

    this.setState({
      minutes_anterograde,
      minutes_retrograde
    });

    this.props.onUpdate({
      minutes_anterograde,
      minutes_retrograde,
      ...rest
    });
  }
}

AmnesiaOfEvent.propTypes = {
  initialState: PropTypes.shape({
    none: PropTypes.bool,
    retrograde: PropTypes.bool,
    anterograde: PropTypes.bool,
    minutes_retrograde: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minutes_anterograde: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  onUpdate: PropTypes.func
};

export default AmnesiaOfEvent;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserAsync, updateUserAsync } from '../../redux/thunks/users';
import { getCurrentUserId, getCurrentClinicId } from '../../tokens';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import { PlayerInfoFullFormCard } from '../user-profile';
import { showAlert } from '../alert-notifications';
import replaceLinkParams from '../../utilities/replace-link-params';
import { resolvePatientName } from '../patient-meta';
import { UserPaths, BaselineTestPaths, DashboardPaths } from '../../paths';
import { withRouter } from '../../utilities/router-utils';
import { userHasRoleMatchingDescription, RoleDescriptions } from '../../utilities/user-roles';
import Strings from './lang';

const onError = () => {
  showAlert('error', {
    autoDismiss: 3000,
    dismissable: true,
    message: Strings.checkInErrorMessageText
  });
};

class PatientCheckIn extends Component {
  constructor(props) {
    super(props);

    const { users = {}, params = {}, context } = props;
    const { userId = 0 } = params;
    const user = users[userId] || {};
    const currentUser = users[getCurrentUserId()] || {};

    this.state = {
      user,
      currentUser,
      context,
      activity: true,
      loading: true,
      error: null
    };
  }

  componentDidMount() {
    this.getUser();
  }

  render() {
    const { user, currentUser } = this.state;
    const isClinician = userHasRoleMatchingDescription(currentUser, RoleDescriptions.Clinician);

    return (
      <Page>
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            <h1 className="display">
              {isClinician 
                ? Strings.confirmInformationTitle 
                : Strings.formatString(
                  Strings.patientCheckInTitle, 
                  this.getContextString()
                )}
            </h1>
          </div>
        </div>

        <ErrorBanner error={this.state.error} />
        <Activity active={this.state.activity} static={this.state.loading}>
          <div>
            <div className="row">
              <div className="col-md-8 col-md-offset-2 patient-account-info">
                <div className="account-info-row">
                  <label>{Strings.accountNumberLabel}:</label>
                  <span>{user.account}</span>
                </div>
                {user.bag_tag ? (
                  <div className="account-info-row">
                    <label>{Strings.bagtagNumberLabel}:</label>
                    <span>{user.bag_tag}</span>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="row">
              <div className="col-md-8 col-md-offset-2">
                <PlayerInfoFullFormCard
                  cardTitle={Strings.patientInformationCardHeader}
                  user={user}
                  submitButtonText={isClinician 
                    ? Strings.confirmAndContinueButtonText 
                    : Strings.checkInPatientButtonText}
                  onSubmit={(attributes) => this.onSubmit(isClinician, attributes)}
                  onError={onError}
                />
              </div>
            </div>
          </div>
        </Activity>
      </Page>
    );
  }

  getContextString() {
    const { context = 'baseline' } = this.state;

    if (context === 'baseline') {
      return Strings.patientCheckInBaselineTitle;
    } 
    
    if (context === 'injury') {
      return Strings.patientCheckInInjuryTitle;
    }

    return null;
  }

  getUser() {
    const { userId = 0 } = this.props.params;

    this.setState({
      activity: true,
      loading: true,
      error: null
    });

    this.props.getUser(userId).then(user => {
      this.setState({
        user,
        activity: false,
        loading: false
      });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false,
        loading: false
      });
    });
  }

  onSubmit(isClinician, attrs) {
    const { user } = this.state;

    this.setState({
      activity: true,
      error: null
    });

    const attributes = { ...attrs };
    const clinicId = getCurrentClinicId();

    if (clinicId) {
      attributes.current_clinic_id = clinicId;
    }

    this.props.updateUser(user.id, attributes).then((user) => {
      this.showSuccess(user);
      this.resolveNextStep(user, isClinician);
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  showSuccess(user) {
    const { currentUser } = this.state;

    showAlert('success', {
      autoDismiss: 6000, /** 6 seconds */
      dismissable: true,
      message: Strings.formatString(
        Strings.patientInfoConfirmedMessage,
        resolvePatientName({
          user: currentUser, 
          patient: user, 
          clinicId: getCurrentClinicId(), 
          redactedText: Strings.redactedReplacementText
        })
      )
    });
  }

  resolveNextStep(user, isClinician) {
    const { context } = this.state;
    const { router = {} } = this.props;

    if (context === 'baseline') {
      /* clinicians will never reach this point as they are routed to baselines/new/personal
        * so route front desk back to start baseline
        */
      router.push(BaselineTestPaths.start.link);
    } else if (context === 'injury' && isClinician) {
      /* if user is clinician direct to new injury page */
      router.push(replaceLinkParams(UserPaths.injuries.new.link, {
        userId: user.id
      }));
    } else {
      /* context is not baseline and is injury but not a clinician so link back to dashboard */
      router.push(DashboardPaths.index.link);
    }
  }
}

const mapStateToProps = (state) => {
  const { users = {} } = state;
  return { users };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (userId) => {
      return dispatch(getUserAsync(userId));
    },
    updateUser: (userId, attributes) => {
      return dispatch(updateUserAsync(userId, attributes));
    }
  };
};

const ConnectedPatientCheckIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientCheckIn);

const RoutablePatientCheckIn = withRouter(ConnectedPatientCheckIn);

export default RoutablePatientCheckIn;

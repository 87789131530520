const loadGoogleMapsApi = (apiKey, libraries = []) => {
  return new Promise((resolve) => {
    // The API has already loaded, so resolve immediately:
    if (window._googleMapsApiLoaded) {
      resolve();
      return;
    }

    // Create an array to store promise resolvers:
    if (!Array.isArray(window._googleMapsApiPendingResolvers)) {
      window._googleMapsApiPendingResolvers = [];
    }

    // Store the current promise resolver:
    window._googleMapsApiPendingResolvers.push(resolve);

    // If the API is already loading, return and wait for
    // the API callback to fire the resolver:
    if (window._googleMapsApiLoading) {
      return;
    }

    // Flag that the API is loading:
    window._googleMapsApiLoading = true;

    // Callback to fire when the API has loaded:
    window._onGoogleMapsApiLoaded = () => {
      // Remove the callback:
      window._onGoogleMapsApiLoaded = null;
      delete window._onGoogleMapsApiLoaded;

      // Resolve all pending promises:
      window._googleMapsApiPendingResolvers.forEach(resolver => {
        resolver();
      });

      // Reset the pending resolvers array and flag that the API has loaded:
      window._googleMapsApiPendingResolvers = [];
      window._googleMapsApiLoading = false;
      window._googleMapsApiLoaded = true;
    };

    // Load the API:
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(',')}&callback=window._onGoogleMapsApiLoaded`;
    document.querySelector('head').appendChild(script);
  });
};

export default loadGoogleMapsApi;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { 
  FormComponent, FormInputGroup, EmailInputGroup, EmailValidator 
} from '../../../forms';
import Strings from './lang';

class RegistrationEmergencyContactForm extends Component {
  constructor(props) {
    super(props);

    const { contact } = this.props;
    const name = contact.name || '';
    const phone = contact.phone_number || '';
    const relationship = contact.relationship || '';
    const email = contact.email || '';

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      name,
      phone,
      relationship,
      email,
      emailValid: email.length === 0 || EmailValidator.test(email)
    };
  }

  render() {
    return (
      <FormComponent className="registration-emergency-contact-form" onSubmit={this.onSubmit}>
        <FormInputGroup
          className="form-group name-input-group"
          labelText={Strings.nameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.name,
            onBlur: this.onUpdateInput.bind(this, 'name'),
            onChange: this.onUpdateInput.bind(this, 'name')
          }}
          inputValid={this.state.name.length > 0}
          messageText={Strings.nameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.phoneLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.phone,
            onBlur: this.onUpdateInput.bind(this, 'phone'),
            onChange: this.onUpdateInput.bind(this, 'phone')
          }}
          inputValid={this.state.phone.length > 0}
          messageText={Strings.phoneErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <EmailInputGroup
          className="form-group emergency-contact-email-input-group"
          labelText={Strings.emergencyContactEmailLabelText}
          inputProps={{
            value: this.state.email,
            className: 'form-control',
            placeholder: Strings.emailInputPlaceholder
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required={false}
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group relationship-input-group"
          labelText={Strings.relationshipLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.relationship,
            onBlur: this.onUpdateInput.bind(this, 'relationship'),
            onChange: this.onUpdateInput.bind(this, 'relationship')
          }}
          inputValid={this.state.relationship.length > 0}
          messageText={Strings.relationshipErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.name.length
      && this.state.relationship.length
      && (this.state.emailValid || this.state.email.length === 0)
      && this.state.phone.length;
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      name,
      phone,
      relationship,
      email,
      emailValid
    } = this.state;

    const attributes = {
      emergency_contact_attributes: {
        name,
        phone_number: phone,
        relationship,
        email: emailValid ? email : ''
      }
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

RegistrationEmergencyContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

export default RegistrationEmergencyContactForm;

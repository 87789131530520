import React from 'react';
import Strings from '../lang';
import Editor from '../../../editor';

const Note = ({
  note = '',
  namespace,
  className,
  onUpdate
}) => {
  return (
    <div className={className}>
      <Editor 
        value={note}
        namespace={namespace}
        placeholder={Strings.enterNotesPlaceholderText}
        onChange={onUpdate}
      />
    </div>
  );
};

export default Note;

import { UrlValidator } from '../../../forms';

const PRICE_TO_CENTS = 100.0;

export const VideoValidator = {
  youtube: (url) => {
    return /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))\/(watch|embed)(\?v=.+|\/.+)$/.test(url);
  },
  vimeo: (url) => {
    return /^(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)$/.test(url);
  },
  facebook: (url) => {
    return /^https?:\/\/www\.facebook\.com\/.+\/videos\/\d+\/?$/.test(url);
  },
  test: function test(url) {
    return !!url && (this.youtube(url) || this.vimeo(url) || this.facebook(url));
  }
};

export const getProductState = (product) => {
  const {
    name = '',
    description = '',
    price = {},
    discount = '',
    categories = [],
    coupon_code = '',
    link = '',
    video_url = '',
    published = true,
    mp_partner,
    images: productImages = []
  } = product || {};

  const { value = '', currency = 'USD' } = price || {};
  const { id, name: partnerName, slug } = mp_partner || {};
  const images = productImages.map(image => ({
    id: image.id,
    url: image.small
  }));

  return {
    name,
    description,
    link,
    linkValid: UrlValidator.test(link),
    price: value !== null || value !== undefined ? value / PRICE_TO_CENTS : '',
    discount: discount ?? '',
    currency,
    categories,
    videoUrl: video_url || '',
    videoValid: VideoValidator.test(video_url),
    coupon: coupon_code ?? '',
    partner: mp_partner ? { id, label: partnerName, value: slug } : null,
    published,
    images
  };
};

export const isValid = (attributes, hasImages) => {
  return attributes.name.length > 0
    && attributes.description.length > 0
    && attributes.price >= 0
    && attributes.linkValid
    && attributes.categories.length > 0
    && hasImages
    && (attributes.videoUrl.length <= 0 || attributes.videoValid);
};

const convertImageAttributes = (images) => {
  return images.reduce((acc, img) => {
    if (img.id && !img._destroy) {
      return acc;
    }

    if (img.id && img._destroy) {
      return [...acc, { id: img.id, _destroy: img._destroy }];
    }

    return [...acc, { image_filename: img.filename, image_base64: img.url }];
  }, []);
};

export const convertAttributes = (attrs) => {
  const attributes = {
    name: attrs.name,
    description: attrs.description,
    price_cents: Math.round(attrs.price * PRICE_TO_CENTS),
    currency: attrs.currency,
    categories: attrs.categories || [],
    discount: attrs.discount || 0,
    link: attrs.link,
    video_url: attrs.videoUrl || null,
    coupon_code: attrs.coupon || null,
    published: attrs.published,
    images_attributes: convertImageAttributes(attrs.images)
  };

  if (attrs.partner) {
    if (attrs.partner.id) {
      attributes.mp_partner_id = attrs.partner.id;
    } else {
      attributes.mp_partner_attributes = {
        name: attrs.partner.label,
        description: attrs.partner.description,
        website_url: attrs.partner.website,
        logo_filename: attrs.partner.logoFileName,
        logo_base64: attrs.partner.logo
      };
    }
  } else {
    attributes.mp_partner_id = null;
  }

  return attributes;
};

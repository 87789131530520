import React, {
  useMemo, 
  useRef, 
  useState, 
  useEffect, 
  useCallback
} from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import AccessControl from '../access-control';
import QuickActions from '../quick-actions';
import SoapPanelControls from './soap-panel-controls';
import SoapPanels from './soap-panels';
import Icon from '../icon';
import Modal from '../modal';
import { 
  RoleDescriptions, 
  makeRoleDescription, 
  RoleNames, 
  RoleResourceTypes, 
  userHasRoleMatchingOneOfDescriptions 
} from '../../utilities/user-roles';
import replaceLinkParams from '../../utilities/replace-link-params';
import { UserPaths, ClinicPaths } from '../../paths';
import { getCurrentClinicId } from '../../tokens';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '../../dates/formats';
import { SoapNoteSelector, InjurySelector, UserSelector } from '../../redux/selectors';
import { createSoapNoteAsync, getFullSoapNotesAsync, archiveSoapNoteAsync } from '../../redux/thunks/soap-notes';
import { getClinicianOpenNotes } from './soap-utilities';
import { confirmation } from '../confirmation';
import { showAlert } from '../alert-notifications';
import Strings from './lang';

const makeSoapNotesViewerRoles = (clinicId) => {
  return [
    RoleDescriptions.Clinician,
    RoleDescriptions.Specialist,
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ];
};

const showSuccessMessage = () => {
  showAlert('success', {
    dismissable: true,
    autoDismiss: 2000,
    message: Strings.archivedSuccessMessage
  });
};

const showErrorMessage = (error) => {
  showAlert('error', {
    dismissable: true,
    autoDismiss: 4000,
    message: Strings.formatString(
      Strings.archivedErrorMessage,
      error.message
    )
  });
};

const showArchiveConfirmation = (cb) => {
  confirmation(Strings.confirmArchiveSoapNoteMessage, {
    title: Strings.confirmArchiveSoapNoteTitle,
    onConfirm: cb 
  });
};

const buildAttributes = (injuryId) => {
  const attributes = {
    injury_id: injuryId,
    closed: false
  };

  return attributes;
};

const StartSoapNoteButton = ({
  injuryStatus,
  disabled = false,
  loading = false,
  onClick,
}) => {
  return (
    <AccessControl 
      roles={[
        RoleDescriptions.Clinician,
        RoleDescriptions.ClinicStaff
      ]}
    >
      <If condition={injuryStatus === 'ongoing'}>
        <Then>
          <Activity active={loading}>
            <button 
              type="button" 
              className="btn btn-default no-print" 
              onClick={onClick}
              disabled={disabled}
            >
              <Icon name="plus" />&nbsp;&nbsp;
              {Strings.startSoapNotesLinkText}
            </button>
          </Activity>
        </Then>
      </If>
    </AccessControl>
  );
};

const OpenNoteAlertModal = ({ openNotes, opened = false, onClose }) => {
  return (
    <Modal
      isOpen={opened}
      className="start-note-alert"
      onClose={onClose}
    >
      <Modal.Header>
        <h2>
          {Strings.formatString(Strings.openNotesAlertTitle, openNotes.length)}
        </h2>
      </Modal.Header>
      <Modal.Body>
        <div className="start-note-alert-message">
          <div>{Strings.openNotesAlertMessage}</div>
          <div className="start-note-open-notes">
            {openNotes.map(data => (
              <div key={data.soap.id} className="note-list-item">
                {Strings.formatString(
                  Strings.soapPanelHeaderText,
                  data.index + 1,
                  dayjs(data.soap.created_at).format(DISPLAY_DATE_FORMAT)
                )}
              </div>
            ))}
          </div>
          <div>{Strings.completeOpenNotesMessage}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-sm btn-default" onClick={onClose}>
          {Strings.closeButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const ListActions = ({
  injuryStatus,
  archiveLink,
  soapNotes = [],
  currentUser,
  activity = false,
  onStart
}) => {
  const [alertOpened, setAlertOpened] = useState(false);
  const openNotes = useMemo(() => (
    getClinicianOpenNotes(soapNotes, currentUser.id)
  ), [currentUser.id, soapNotes]);

  const onBeforeStart = useCallback(() => {
    if (openNotes.length > 0) {
      setAlertOpened(true);
    } else {
      onStart(); 
    }
  }, [onStart, openNotes]);
  
  return (
    <>
      <QuickActions>
        <li>
          <StartSoapNoteButton 
            injuryStatus={injuryStatus}
            disabled={activity}
            loading={activity}
            onClick={onBeforeStart}
          />
        </li>
        <li>
          <AccessControl roles={[RoleDescriptions.Clinician]}>
            <Link 
              to={archiveLink}
              className="btn btn-default" 
            >
              <Icon name="box-archive" />&nbsp;&nbsp;
              {Strings.archivedActionText}
            </Link>
          </AccessControl>
        </li>
      </QuickActions>
      <OpenNoteAlertModal
        opened={alertOpened}
        openNotes={openNotes}
        onClose={() => setAlertOpened(false)}
      />
    </>
  );
};

const SoapNotesList = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const panelRef = useRef();
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [activity, setActivity] = useState(false);
  const [starting, setStarting] = useState(false);

  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));
  const soapNotes = useSelector(state => (
    SoapNoteSelector.getInjurySoapNotes(
      state, 
      { userId: params.userId, injuryId: params.injuryId }
    )
  ));
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const currentClinicId = useMemo(getCurrentClinicId, []);

  const hasNotes = soapNotes.length > 0;

  const injuryDate = useMemo(() => (
    dayjs(injury.injured_at, INPUT_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)
  ), [injury.injured_at]);

  const disablePanels = useMemo(() => (
    !userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      makeSoapNotesViewerRoles(currentClinicId)
    )
  ), [currentClinicId, currentUser]);

  const skipLoading = useMemo(() => (
    loaded || activity || (injury.soap_notes.length === soapNotes.length)
  ), [activity, injury.soap_notes.length, loaded, soapNotes.length]);

  const startNewSoapNote = useCallback(() => {
    setStarting(true);

    const attributes = buildAttributes(injury.id);
    
    dispatch(createSoapNoteAsync(user.id, injury.id, attributes)).then(soap => {
      setStarting(false);
      navigate(replaceLinkParams(UserPaths.injuries.soapNote.link, {
        userId: user.id,
        injuryId: injury.id,
        soapId: soap.id
      }));
    }).catch(error => {
      setStarting(false);
      setError(error.message);
    });
  }, [dispatch, injury.id, navigate, user.id]);

  const handleArchive = useCallback((soap) => {
    setActivity(true);

    dispatch(archiveSoapNoteAsync(user.id, injury.id, soap.id)).then(() => {
      setActivity(false);
      showSuccessMessage();
    }).catch(error => {
      setActivity(false);
      showErrorMessage(error);
    });
  }, [dispatch, injury.id, user.id]);

  const loadSoapNotes = useCallback(() => {
    setActivity(true);
    setLoaded(true);

    dispatch(getFullSoapNotesAsync(user.id, injury.id)).then(() => {
      setActivity(false);
    }).catch(e => {
      setActivity(false);
      setError(e.message);
    });
  }, [dispatch, injury.id, user.id]);

  useEffect(() => {
    if (skipLoading) return;

    loadSoapNotes();
  }, [skipLoading, loadSoapNotes]);

  return (
    <div className="soap-notes-list-view">
      <Activity active={activity} static={!hasNotes}>
        <div className="row" style={{ height: '100%' }}>
          <div className="col-md-9 soap-notes-list-section">
            <div className="soap-notes-list">
              <div className="soap-notes-list-header">
                <h2>
                  {injury.id && Strings.formatString(
                    Strings.soapNotesListSubtitle,
                    injuryDate
                  )}
                </h2>
                <SoapPanelControls 
                  visible={hasNotes && !disablePanels}
                  panelRef={panelRef}
                />
              </div>

              <ErrorBanner error={error} />
              <div className="soap-notes-list-content">
                <If condition={hasNotes}>
                  <Then>
                    <SoapPanels
                      ref={panelRef}
                      sticky={60}
                      user={user}
                      injury={injury}
                      soapNotes={soapNotes}
                      actionable={injury.status === 'ongoing'}
                      clinicId={currentClinicId}
                      disablePanels={disablePanels}
                      onArchive={(soap) => {
                        showArchiveConfirmation(() => handleArchive(soap));
                      }}
                    />
                  </Then>
                  <Else>
                    {!error && (
                      <p className="alert alert-info">
                        {Strings.soapNotesListEmptyMessage}
                      </p>
                    )}
                  </Else>
                </If>
              </div>
            </div>
          </div>
          <div className="col-md-3 no-print soap-note-list-actions">
            <ListActions 
              userId={user.id}
              injuryId={injury.id}
              injuryStatus={injury.status}
              archiveLink={replaceLinkParams(
                ClinicPaths.patientProfile.injuries.soapNotes.archived.link, 
                {
                  clinicId: currentClinicId,
                  userId: user.id,
                  injuryId: injury.id
                }
              )}
              activity={starting}
              soapNotes={soapNotes}
              currentUser={currentUser}
              onStart={startNewSoapNote}
            />
          </div>
        </div>
      </Activity>
    </div>
  );
};

export default SoapNotesList;

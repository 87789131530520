import { 
  jsonApiUpdate, 
  jsonApiCreate, 
  jsonApiDelete, 
  jsonApiRead 
} from '../../requests/jsonapi';
import { updateProduct, updateProducts, removeProduct } from '../actions/products';

const getProductAsync = (productId, opts = {}) => {
  const options = { ...opts, path: `marketplace/products/${productId}` };

  return (dispatch) => {
    return jsonApiRead({ type: 'products' }, options).then(product => {
      dispatch(updateProduct(product));
      return Promise.resolve(product);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getProductsAsync = (query = {}, opts = {}) => {
  const options = { ...opts, path: 'marketplace/products', queryParams: query };

  return (dispatch) => {
    return jsonApiRead({ type: 'products' }, options).then(({ data, meta }) => {
      dispatch(updateProducts(data));
      return Promise.resolve({
        products: data,
        pagination: meta
      });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateProductAsync = (productId, attributes, opts = {}) => {
  const options = { ...opts, path: `marketplace/products/${productId}` };

  return (dispatch) => {
    return jsonApiUpdate({ type: 'products', id: productId, attributes }, options).then(product => {
      dispatch(updateProduct(product));
      return Promise.resolve(product);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createProductAsync = (attributes, opts = {}) => {
  const options = { ...opts, path: 'marketplace/products' };

  return (dispatch) => {
    return jsonApiCreate({ type: 'products', attributes }, options).then(product => {
      dispatch(updateProduct(product));
      return Promise.resolve(product);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const deleteProductAsync = (productId, opts = {}) => {
  const options = { ...opts, path: `marketplace/products/${productId}` };

  return (dispatch) => {
    return jsonApiDelete({ type: 'products' }, options).then(() => {
      dispatch(removeProduct(productId));
      return Promise.resolve();
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  updateProductAsync,
  getProductAsync,
  getProductsAsync,
  createProductAsync,
  deleteProductAsync
};

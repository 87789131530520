import React from 'react';
import { Clinics } from '../../components/clinics';
import { ClinicProfile } from '../../components/clinic-profile';
import { AllClinicsStats } from '../../components/clinic-stats';
import { ClinicRegistration } from '../../components/clinic-registration';
import { ClinicPatients } from '../../components/clinic-patients';
import { ClinicReferrals } from '../../components/referrals';
import { Authorization } from '../authorization';
import { AdminAndClinicRoleDescriptions, IDTRoleDescriptions, RoleDescriptions } from '../../utilities/user-roles';
import { PatientProfileNavigation } from '../../components/patient-profile';
import ClinicUsersRoutes from './users';
import ClinicInvoicesRoutes from './invoices';
import PatientProfileRoutes from '../patient-profile';
import PageNotFound from '../not-found-page';

const ClinicRoutes = [
  {
    index: true,
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.SuperAdmin,
          RoleDescriptions.SalesAdmin
        ]}
      >
        <Clinics />
      </Authorization>
    )
  },
  {
    path: 'stats',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.SuperAdmin,
          RoleDescriptions.SalesAdmin
        ]}
      >
        <AllClinicsStats />
      </Authorization>
    )
  },
  {
    path: 'register',
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.SuperAdmin,
          RoleDescriptions.SalesAdmin
        ]}
      >
        <ClinicRegistration />
      </Authorization>
    )
  },
  {
    path: ':clinicId/*',
    children: [
      {
        index: true,
        element: (
          <Authorization
            allowRoles={[
              ...AdminAndClinicRoleDescriptions,
              ...IDTRoleDescriptions
            ]}
          >
            <ClinicProfile />
          </Authorization>
        )
      },
      {
        path: 'users/*',
        children: ClinicUsersRoutes
      },
      {
        path: 'referrals',
        element: (
          <Authorization
            allowRoles={[]}
            allowRolesMatchingResourceId={[
              RoleDescriptions.Clinician,
              RoleDescriptions.Specialist,
              RoleDescriptions.ClinicFrontDesk
            ]}
            resourceIdParam="clinicId"
          >
            <ClinicReferrals />
          </Authorization>
        )
      },
      {
        path: 'invoices/*',
        children: ClinicInvoicesRoutes
      },
      {
        path: 'patients/*',
        children: [
          {
            index: true,
            element: (
              <Authorization
                allowRoles={[
                  RoleDescriptions.SuperAdmin,
                  RoleDescriptions.SalesAdmin
                ]}
                allowRolesMatchingResourceId={[
                  RoleDescriptions.ClinicOwner,
                  RoleDescriptions.Clinician,
                  RoleDescriptions.Specialist,
                  RoleDescriptions.ClinicFrontDesk,
                  RoleDescriptions.ClinicStaff,
                  RoleDescriptions.BaselineTester
                ]}
                resourceIdParam="clinicId"
              >
                <ClinicPatients />
              </Authorization>
            )
          },
          {
            path: ':userId/*',
            element: (
              <Authorization
                allowRoles={[
                  RoleDescriptions.SuperAdmin,
                  RoleDescriptions.SalesAdmin
                ]}
                allowRolesMatchingResourceId={[
                  RoleDescriptions.ClinicOwner,
                  RoleDescriptions.Clinician,
                  RoleDescriptions.Specialist,
                  RoleDescriptions.BaselineTester,
                  RoleDescriptions.ClinicFrontDesk,
                  RoleDescriptions.ClinicStaff
                ]}
                resourceIdParam="clinicId"
              >
                <PatientProfileNavigation />
              </Authorization>
            ),
            children: PatientProfileRoutes
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: '*',
    element: <PageNotFound />
  }
];

export default ClinicRoutes;

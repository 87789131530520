import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import { DISPLAY_DATE_FORMAT } from '../../../dates/formats';
import Strings from './lang';

const PostInjuryMedicalInfo = ({
  medicalInfo = {}
}) => {
  const medicalHistory = medicalInfo.medical_history || {};
  const concussionHistory = medicalInfo.concussion_history || {};

  return (
    <div className="post-injury-section post-injury-medical-info">
      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.medicalInfoTitle}</h2>
        </div>
      </div>
      <div className="post-injury-group">
        <div className="row">
          <div className="col-md-6">
            <strong>{Strings.learningDisabilityLabelText}</strong>&nbsp;
            <span className="capitalize">
              {medicalHistory.learning_disability}
            </span>
          </div>
          <div className="col-md-6">
            <strong>{Strings.behaviouralDisorderLabelText}</strong>&nbsp;
            <span className="uppercase">
              {medicalHistory.behavioural_disorder || 'none'}
            </span>
          </div>
        </div>
      </div>
      <If condition={medicalInfo.concussion_ever_diagnosed === true}>
        <Then>
          <div className="post-injury-group">
            <div className="row">
              <div className="col-md-6">
                <strong>{Strings.concussionCountLabelText}</strong> {
                  concussionHistory.how_many || 0
                }
              </div>
              <div className="col-md-6">
                <strong>{Strings.concussionDateLabelText}</strong> {
                  dayjs(concussionHistory.most_recent).format(DISPLAY_DATE_FORMAT)
                }
              </div>
            </div>
          </div>
        </Then>
      </If>
    </div>
  );
};

PostInjuryMedicalInfo.propTypes = {
  medicalInfo: PropTypes.object.isRequired
};

export default PostInjuryMedicalInfo;

import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { ProductCategories } from '../utils';
import { useUniqueId } from '../../../hooks';
import Strings from '../lang';

const Checkbox = ({ label, checked = false, onChange = () => {} }) => {
  const id = useUniqueId();

  const handleChange = useCallback((e) => {
    onChange(e.target.checked);
  }, [onChange]);

  return (
    <div className="filter-checkbox">
      <input id={id} type="checkbox" checked={checked} onChange={handleChange} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const Selectable = ({ 
  label, 
  selected = false,
  onChange
}) => {
  return (
    <li 
      className={classNames('filter-selectable', { active: selected })} 
      onClick={() => onChange(!selected)}
    >
      {label}
    </li>
  );
};

const PartnerFilterItem = ({
  item,
  category,
  checked,
  onChange
}) => {
  const count = useMemo(() => item.categories[category || 'all'] ?? 0, [category, item.categories]);

  if (count <= 0) return null;

  return (
    <Checkbox
      label={`${item.name} (${count || 0})`}
      checked={checked}
      onChange={onChange}
    />
  );
};

const ProductFilters = ({
  category = null,
  brands = [],
  filters = {},
  onChange = () => {}
}) => {
  const categories = useMemo(() => {
    return filters.categories?.reduce((acc, item) => (
      ProductCategories[item.category] ? [...acc, item] : acc
    ), []);
  }, [filters.categories]);

  function handleBrandsChange(checked, item) {
    const newBrands = [...brands];

    if (checked) {
      newBrands.push(item.slug);
    } else {
      const index = newBrands.findIndex(b => b === item.slug);

      if (index >= 0) {
        newBrands.splice(index, 1);
      }
    }

    onChange({ brands: newBrands });
  }

  return (
    <div className="product-filters">
      {categories?.length > 0 && (
        <div className="filter-section">
          <h2>{Strings.categoriesFilterLabel}</h2>
          <ul className="list-unstyled categories">
            <Selectable
              label={`${Strings.allCategoryLabel} (${filters.total_products || 0})`}
              selected={category === null}
              onChange={() => {
                if (category !== null) {
                  onChange({ category: null });
                }
              }}
            />
            {categories.map(item => (
              <Selectable 
                key={item.category}
                label={`${ProductCategories[item.category]} (${item.count || 0})`} 
                value={item.category}
                selected={item.category === category}
                onChange={() => {
                  if (category !== item.category) {
                    onChange({ category: item.category });
                  }
                }}
              />
            ))}
          </ul>
        </div>
      )}
      {filters.partners?.length > 0 && (
        <div className="filter-section">
          <h2>{Strings.brandsFilterLabel}</h2>
          {filters.partners.map(item => (
            <PartnerFilterItem 
              key={item.slug}
              item={item}
              category={category}
              checked={brands.includes(item.slug)}
              onChange={(checked) => {
                handleBrandsChange(checked, item);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductFilters;

import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SoapPanelControls from '../soap-notes/soap-panel-controls';
import SoapPanels from '../soap-notes/soap-panels';
import { ErrorView, EmptyView } from './status-views';
import Activity from '../activity';
import { SoapNoteSelector } from '../../redux/selectors';
import { getFullSoapNotesAsync } from '../../redux/thunks/soap-notes';
import { useMount } from '../../hooks';
import Strings from './lang';

const SoapNotesView = ({
  user,
  injury,
  noteId,
  clinicId
}) => {
  const dispatch = useDispatch();
  const panelRef = useRef();
  const notes = useSelector((state) => (
    SoapNoteSelector.getPreviousSoapNotes(state, {
      userId: user.id,
      injuryId: injury.id,
      soapId: noteId
    })
  ));

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(!notes.length);

  const loadNotes = useCallback(() => {
    setLoading(true);

    dispatch(getFullSoapNotesAsync(user.id, injury.id)).then(() => {
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch, injury.id, user.id]);

  useMount(() => {
    if (!notes.length) {
      loadNotes();
    }
  });

  if (error) {
    return (
      <ErrorView 
        title={Strings.soapListErrorMessage}
        error={error} 
      />
    );
  }

  if (!loading && !notes.length) {
    return (
      <EmptyView 
        message={Strings.noPreviousSoapNotesMessage}
      />
    );
  }

  return (
    <Activity static active={loading}>
      {notes?.length > 0 && (
        <div className="panel-controls">
          <SoapPanelControls panelRef={panelRef} />
        </div>
      )}
      <SoapPanels
        ref={panelRef}
        sticky={60}
        actionable={false}
        user={user}
        injury={injury}
        soapNotes={notes}
        clinicId={clinicId}
      />
    </Activity>
  );
};

export default SoapNotesView;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import Strings from './lang';
import Icon from '../icon';

const MedicalListTable = ({
  items = [], 
  nameLabel = '', 
  onRemove = () => {}, 
  removable = false
}) => {
  return items.length === 0 ? null : (
    <table className="table medical-list-table">
      <thead>
        <tr>
          <th className="no-action">{nameLabel}</th>
          <th className="no-action">{Strings.addedByTableHeading}</th>
          <th className="no-action">{Strings.dateTableHeading}</th>
          <th className="no-action">{Strings.clinicTableHeading}</th>
          {removable && <th className="no-action">{Strings.actionTableHeading}</th>}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          const { 
            name, added_at, clinician = {}, clinic = {} 
          } = item;
          const date = dayjs(added_at).format(DISPLAY_DATE_FORMAT);
          const clinicianPerson = clinician.person || {};
          const clinicianName = `${clinicianPerson.first_name || ''} ${clinicianPerson.last_name || ''}`;

          return (
            <tr key={index}>
              <td>{name}</td>
              <td>{clinicianName}</td>
              <td>{date}</td>
              <td>{clinic.name || ''}</td>
              {removable && (
                <td>
                  <button type="button" className="edit-button" onClick={onRemove.bind(null, index)}>
                    <Icon name="trash-can" />
                    {Strings.removeButtonText}
                  </button>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

MedicalListTable.propTypes = {
  items: PropTypes.array,
  nameLabel: PropTypes.string,
  removable: PropTypes.bool,
  onRemove: PropTypes.func
};  

export default MedicalListTable;

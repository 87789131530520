import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { createBaselineTestAsync, updateBaselineTestAsync } from '../../../redux/thunks/baseline-tests';
import StepComponents from '../baseline-steps';
import BaselineTestLabels from '../baseline-utilities/baseline-test-labels';
import { getBaselineProfile } from '../baseline-utilities/baseline-profile';
import { BaselineStepWizard } from '../baseline-step-wizard';
import TestComponentSelection from './test-component-selection';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import PlayerBadge from '../../player-badge';
import Strings from './lang';

const TOP_SCROLL_OFFSET = 60;

const getPostInjuryTestProfile = (version = 2) => {
  const profile = getBaselineProfile(version);
  return [...profile.filter(item => {
    return item !== 'personal' && item !== 'medical';
  })];
};

const getPostInjuryTestLabels = (profile) => {
  return profile.reduce((acc, cur) => {
    acc[cur] = BaselineTestLabels[cur];
    return acc;
  }, {});
};

const getInitialSteps = (profile, baseline) => {
  if (!baseline?.id) return profile;
  return profile.filter(key => (
    Object.keys(baseline[key] ?? {}).length > 0 && !baseline[key]?.not_done
  ));
};

/**
 * This component handles the Repeat Baseline Test (Post Injury Test). The test starts with
 * the user selecting the individual tests they wish to perform. By default all tests are 
 * selected (with checkboxes), with the ability to uncheck tests the user doesn't want to 
 * do. The post injury test is not saved until the last test has been completed.
 */
class PostInjuryTest extends Component {
  constructor(props) {
    super(props);

    const { importableSymptoms, userBaseline } = props;
    const baseline = {
      test_version: userBaseline?.test_version ?? 2
    };

    if (importableSymptoms) {
      baseline.pre_existing_symptoms = typeof importableSymptoms === 'function' 
        ? importableSymptoms() 
        : importableSymptoms;
    }

    this.testTop = React.createRef();
    this.onConfirmComponents = this.onConfirmComponents.bind(this);
    this.onStepAllowedToContinue = this.onStepAllowedToContinue.bind(this);
    this.onPreviousStep = this.onPreviousStep.bind(this);
    this.onCompleted = this.onCompleted.bind(this);

    this.state = {
      baseline,
      userBaseline,
      testStarted: false,
      profile: getPostInjuryTestProfile(baseline.test_version),
      currentStep: null,
      activity: false,
      error: null
    };
  }

  render() {
    return (
      <div ref={this.testTop}>
        <h1 className="display">{Strings.title}</h1>
        <Activity active={this.state.activity}>
          <div>
            <PlayerBadge user={this.props.user} clinicId={this.props.currentClinic.id} />
            <ErrorBanner error={this.state.error} />
            <If condition={!this.state.testStarted}>
              <Then>
                <div>
                  <p className="text-muted">{Strings.selectTestsInfoText}</p>
                  <TestComponentSelection
                    tests={getPostInjuryTestLabels(this.state.profile)}
                    initialSteps={getInitialSteps(this.state.profile, this.state.userBaseline)}
                    linkedTests={[
                      ['immediate_memory_test', 'delayed_memory_recall_test']
                    ]}
                    manditoryTests={['pre_existing_symptoms']}
                    onConfirmTests={this.onConfirmComponents}
                  />
                </div>
              </Then>
              <Else>
                <div>
                  <BaselineStepWizard
                    injectProps={{
                      baseline: this.state.baseline,
                      userBaseline: this.state.userBaseline,
                      postInjury: true,
                      user: this.props.user
                    }}
                    steps={this.state.profile.map(key => {
                      return {
                        step: key,
                        component: StepComponents[key]
                      };
                    })}
                    initialStep={this.state.profile.indexOf(this.state.currentStep)}
                    onStepAllowedToContinuePromise={this.onStepAllowedToContinue}
                    onPrevious={this.onPreviousStep}
                    onStepsCompleted={this.onCompleted}
                  />
                </div>
              </Else>
            </If>
          </div>
        </Activity>
      </div>
    );
  }

  onConfirmComponents(components) {
    this.setState(prev => {
      const newProfile = prev.profile.filter(item => {
        return components.indexOf(item) >= 0;
      });

      return {
        currentStep: newProfile[0],
        testStarted: true,
        profile: [...newProfile]
      };
    });
  }

  onStepAllowedToContinue(curStep, attributes, nextStep) {
    const { baseline } = this.state;
    let { currentStep } = this.state;
    baseline[curStep] = attributes[curStep];
    currentStep = nextStep; /** keep track of current step */
    this.setState({
      baseline,
      currentStep
    });

    this.scrollToTop();

    return Promise.resolve();
  }

  onPreviousStep(curStep, prevStep) {
    this.setState({
      currentStep: prevStep
    });

    this.scrollToTop();
  }

  scrollToTop() {
    const rect = this.testTop.current.getBoundingClientRect();

    if (rect.top < 0) {
      const scroll = rect.top + window.pageYOffset;
      window.scrollTo(0, scroll - TOP_SCROLL_OFFSET);
    }
  }

  onCompleted() {
    this.setState({
      error: null,
      activity: true
    });

    const { baseline } = this.state;
    const { 
      user = {},
      currentClinic = {},
      assessmentId = 0,
      soapId = 0
    } = this.props;

    const attributes = {
      clinic_id: currentClinic.id,
      ...baseline
    };

    if (baseline.id) {
      this.props.updateBaseline(user.id, baseline.id, attributes).then((baseline) => {
        this.setState({ activity: false, baseline });
      }).catch(error => {
        this.setState({
          error: error.message,
          activity: false
        });
      });
    } else {
      if (soapId > 0) {
        attributes.soap_note_id = soapId;
      } else if (assessmentId > 0) {
        attributes.injury_assessment_id = assessmentId;
      }

      this.props.createBaseline(user.id, attributes).then((baseline) => {
        this.setState({ activity: false, baseline });
        if (typeof this.props.onSave === 'function') {
          this.props.onSave('postInjuryTest', baseline);
        }
      }).catch(error => {
        this.setState({
          error: error.message,
          activity: false
        });
      });
    }
  }
}

PostInjuryTest.propTypes = {
  user: PropTypes.object,
  currentClinic: PropTypes.object,
  soapId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assessmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  importableSymptoms: PropTypes.oneOfType([PropTypes.object, PropTypes.bool, PropTypes.func])
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBaseline: (userId, baselineId, attributes) => {
      return dispatch(updateBaselineTestAsync(userId, baselineId, attributes));
    },
    createBaseline: (userId, attributes) => {
      return dispatch(createBaselineTestAsync(userId, attributes));
    }
  };
};

const ConnectedPostInjuryTest = connect(
  null,
  mapDispatchToProps
)(PostInjuryTest);

export default ConnectedPostInjuryTest;

import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSymptomKeys, calculateSymptomsTotals } from '../../symptom-data';

const SymptomsUpdateColumn = ({
  symptoms = {}, 
  header
}) => {
  const totals = calculateSymptomsTotals(symptoms);

  return (
    <div className="symptoms-update-column">
      <div className="symptoms-column-header">{header}</div>
      <div className="symptoms-update-scores">
        {OrderedSymptomKeys.map(key => (
          <div key={key}>
            {symptoms[key].level}
          </div>
        ))}
        <div className="symptoms-update-total">
          {totals.score}
        </div>
        <div className="symptoms-update-total">
          {totals.count}
        </div>
      </div>
    </div>
  );
};

SymptomsUpdateColumn.propTypes = {
  header: PropTypes.string,
  symptoms: PropTypes.object
};

export default SymptomsUpdateColumn;

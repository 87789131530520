import React from 'react';
import PropTypes from 'prop-types';
import { SelectInputGroup } from '../../../../forms';
import Strings from './lang';

const BESSTest = ({
  maxErrors, 
  value, 
  onUpdate = () => {}, 
  required = false, 
  testLabel, 
  touched
}) => (
  <div className="baseline-card-row">
    <label className="baseline-row-label">{testLabel}</label>
    <SelectInputGroup
      className="form-group"
      labelText={Strings.errorsInputLabel}
      inputProps={{
        className: 'form-control',
        value
      }}
      required={required}
      valid={value !== ''}
      messageClassName="alert alert-danger"
      touched={touched}
      onUpdate={onUpdate}
    >
      <option value="">{required ? Strings.defaultSelect : Strings.notDoneSelect}</option>
      {[...new Array(maxErrors + 1)].map((_, index) => (
        <option key={index} value={index}>{index}</option>
      ))}
    </SelectInputGroup>
  </div>
);

BESSTest.propTypes = {
  testLabel: PropTypes.string,
  maxErrors: PropTypes.number,
  touched: PropTypes.bool,
  onUpdate: PropTypes.func,
  required: PropTypes.bool
};

export default BESSTest;

import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DashboardPaths } from '../../paths';
import { FormComponent } from '../../forms';
import { Page } from '../page';
import { ClinicSelect } from '../clinic-select';
import Strings from './lang';

const AuthenticationClinics = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = useCallback(() => {
    const { nextPathname } = location?.state || {};

    if (nextPathname) {
      navigate(nextPathname);
    } else {
      navigate(DashboardPaths.index.link);
    }
  }, [location, navigate]);

  return (
    <Page hideBannerIfClinicDeactivated className="authentication">
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <h1 className="display">{Strings.clinicsTitle}</h1>
          <p>{Strings.clinicsMessage}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <FormComponent onSubmit={onSubmit}>
            <ClinicSelect />
            <div className="form-footer">
              <button className="btn btn-primary" type="submit">
                {Strings.clinicsButtonText}
              </button>
            </div>
          </FormComponent>
        </div>
      </div>
    </Page>
  );
};

export default AuthenticationClinics;

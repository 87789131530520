import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import Dropdown, { MenuItem } from '../../dropdown';
import AppointmentStatusModal from './appointment-status-modal';
import Icon from '../../icon';
import { AppointmentStatusStrings } from '../utils';
import { updateIdtClaimAppointmentAsync } from '../../../redux/thunks/idt-claims';
import { showAlert } from '../../alert-notifications';
import AppointmentNotePopover from './appointment-note-popover';
import Strings from '../lang';

const AppointmentStatusIcons = {
  booked: 'calendar-day',
  arrived: 'check-circle',
  rescheduled: 'calendar-lines-pen',
  cancelled: 'ban',
  no_showed: 'circle-question'
};

const AppointmentStatusColors = {
  booked: 'primary',
  arrived: 'success',
  rescheduled: 'warning',
  cancelled: 'error',
  no_showed: 'error'
};

const AppointmentStatusKeys = Object.keys(AppointmentStatusStrings);

const AppointmentStatus = ({
  claimId,
  blockId,
  apptId,
  status,
  notes
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [shownStatus, setShownStatus] = useState(null);
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [activity, setActivity] = useState(false);

  const onSubmit = useCallback((attributes) => {
    setStatusModalOpen(false);
    setSelectedStatus(null);
    setShownStatus(attributes.status);
    setActivity(true);

    dispatch(updateIdtClaimAppointmentAsync(claimId, blockId, apptId, attributes)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      showAlert('error', {
        dismissable: true,
        autoDismiss: 8000,
        message: Strings.formatString(Strings.updateApptStatusErrorMessage, error.message)
      });
    }).finally(() => {
      setShownStatus(null);
    });
  }, [apptId, blockId, claimId, dispatch]);

  return (
    <div className="idt-appt-status-row">
      <Dropdown
        isOpen={open}
        placement="bottom-end"
        trigger={(
          <button
            type="button"
            disabled={activity}
            className={classNames('idt-appt-status-button', { selected: open })}
            onClick={() => setOpen(prev => !prev)}
          >
            <span className="status">
              <Icon
                fixedWidth
                name={AppointmentStatusIcons[shownStatus || status]}
                className={AppointmentStatusColors[shownStatus || status]}
              />
              <span className="status-label">
                {AppointmentStatusStrings[shownStatus || status]}
              </span>
            </span>
            {activity
              ? <Icon prefix="fad" name="loader" spinPulse />
              : <Icon prefix="fas" name="caret-down" />}
          </button>
        )}
        onClose={() => setOpen(false)}
      >
        {AppointmentStatusKeys.map(key => (
          status !== key ? (
            <MenuItem
              key={key}
              icon={AppointmentStatusIcons[key]}
              type={AppointmentStatusColors[key]}
              onSelect={() => {
                if (key === 'arrived' || key === 'booked') {
                  onSubmit({ status: key, notes: null });
                } else {
                  setSelectedStatus(key);
                  setStatusModalOpen(true);
                }
              }}
            >
              {AppointmentStatusStrings[key]}
            </MenuItem>
          ) : null)
        )}
      </Dropdown>
      {!!notes && status !== 'booked' && status !== 'arrived' && (
        <AppointmentNotePopover notes={notes} />
      )}
      <AppointmentStatusModal
        status={selectedStatus}
        open={statusModalOpen}
        onSubmit={onSubmit}
        onClose={() => {
          setStatusModalOpen(false);
        }}
      />
    </div>
  );
};

export default AppointmentStatus;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { getBlackhawksTestAsync } from '../../redux/thunks/blackhawks';
import Activity from '../activity';
import BlackhawksTestView from './blackhawks-test-view';
import BlackhawksTest from './blackhawks-test';
import { InjurySelector, UserSelector } from '../../redux/selectors';

const blackhawksTestSelector = (userId, testId) => (state) => {
  const userTests = state.blackhawks[userId] || {};
  return userTests[testId] || {};
};

const BlackhawksTestDisplay = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);

  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const injury = useSelector(state => (
    InjurySelector.getUserInjury(state, { userId: params.userId, injuryId: params.injuryId })
  ));
  const test = useSelector(blackhawksTestSelector(params.userId, params.blackhawksId));

  const onEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const onTestSaved = useCallback(() => {
    setEditing(false);
    window.scrollTo(0, 0);
  }, []);

  const getBlackhawksTest = useCallback((userId, injuryId, blackhawksId) => {
    setActivity(true);
    setError(null);

    dispatch(getBlackhawksTestAsync(userId, injuryId, blackhawksId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!test?.id && params.userId && params.injuryId && params.blackhawksId) {
      getBlackhawksTest(params.userId, params.injuryId, params.blackhawksId);
    }
  }, [getBlackhawksTest, params.blackhawksId, params.injuryId, params.userId, test?.id]);

  return (
    <div className="blackhawks-test-display">
      <Activity active={activity} static={!test?.id}>
        <If condition={editing}>
          <Then>
            <BlackhawksTest
              className="blackhawks-test"
              user={user}
              injury={injury}
              blackhawksId={test?.id}
              onSave={onTestSaved}
            />
          </Then>
          <Else>
            <BlackhawksTestView
              user={user}
              injury={injury}
              test={test}
              error={error}
              onEditClick={onEdit}
            />
          </Else>
        </If>
      </Activity>
    </div>
  );
};

export default BlackhawksTestDisplay;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormInput, NumberValidator } from '../../forms';
import Strings from './lang';

const BlackhawksStationaryBikeRestStage = ({
  touched,
  heartRate,
  symptoms,
  onChangeHeartRate,
  onChangeSymptoms
}) => (
  <div className="blackhawks-stationary-bike-rest-stage">
    <div className="row">
      <div className="col-md-12">
        <h3 className="bold">{Strings.stationaryBikeRestHeadingText}</h3>

        <div className="row">
          <div className="col-md-2">
            <label>
              <span>{Strings.heartRateHeaderText}</span>
              <FormInput
                className={classNames('form-control', {
                  invalid: touched && heartRate.length > 0 && !NumberValidator.test(heartRate)
                })}
                type="number"
                inputProps={{
                  min: 0,
                  max: 300,
                  value: heartRate,
                  onChange: onChangeHeartRate
                }}
              />
            </label>
          </div>
          <div className="col-md-10">
            <label>
              <span>{Strings.symptomsHeaderText}</span>
              <FormInput
                className="form-control"
                type="textarea"
                inputProps={{
                  value: symptoms,
                  onChange: onChangeSymptoms
                }}
              />
            </label>
          </div>
        </div>

      </div>
    </div>
  </div>
);

BlackhawksStationaryBikeRestStage.propTypes = {
  heartRate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  symptoms: PropTypes.string.isRequired,
  onChangeHeartRate: PropTypes.func,
  onChangeSymptoms: PropTypes.func,
  touched: PropTypes.bool
};

export default BlackhawksStationaryBikeRestStage;

import React from 'react';
import { SelectInputGroup, FormInputGroup, CheckboxInputGroup } from '../../../forms';
import { LineItemTypes } from '../utilities';
import Strings from '../lang';

const AmountField = ({
  amount,
  submitted,
  currency,
  onUpdate
}) => {
  const floatAmount = parseFloat(amount);
  return (
    <FormInputGroup 
      required
      touched={submitted}
      className="form-group"
      labelText={`${Strings.amountHeaderLabel} (${currency})`}
      inputType="text"
      inputProps={{
        className: 'form-control',
        value: amount,
        onChange: (e) => onUpdate(e.target.value) 
      }}
      inputValid={!isNaN(floatAmount) && floatAmount >= 0}
      messageClassName="alert alert-danger"
      messageText={Strings.amountErrorMessage}
    />
  );
};

const PatientAccountField = ({
  account,
  onUpdate
}) => {
  return (
    <FormInputGroup 
      inputValid
      className="form-group"
      labelText={Strings.patientAccountLabel}
      inputType="text"
      inputProps={{
        className: 'form-control',
        value: account || '',
        onChange: (e) => onUpdate(e.target.value) 
      }}
      messageClassName="alert alert-danger"
    />
  );
};

const DefaultFormFields = ({ 
  amount,
  submitted,
  onUpdateAmount,
  currency 
}) => {
  return (
    <AmountField 
      amount={amount}
      submitted={submitted}
      onUpdate={onUpdateAmount}
      currency={currency} 
    />
  );
};

const ImpactFormFields = ({ 
  amount,
  attributes,
  submitted,
  onUpdateAmount,
  onUpdateAttributes,
  currency 
}) => {
  return (
    <>
      <CheckboxInputGroup
        inputValid
        className="form-group"
        labelText={Strings.postInjuryImpactFieldLabel}
        inputProps={{
          className: 'form-control',
          checked: attributes.post_injury || false,
          onChange: (e) => onUpdateAttributes({ ...attributes, post_injury: e.target.checked })
        }}
      />
      <AmountField 
        amount={amount}
        submitted={submitted}
        onUpdate={onUpdateAmount}
        currency={currency} 
      />
      <PatientAccountField 
        account={attributes.account}
        onUpdate={(value) => onUpdateAttributes({ ...attributes, account: value })}
      />
    </>
  );
};

const MapleConsultPrices = {
  weekday: 49,
  weekend: 79,
  holiday: 79,
  overnight: 99
};

const MapleConsultFields = ({ 
  amount,
  attributes,
  submitted,
  onUpdateAmount,
  onUpdateAttributes,
  currency 
}) => {
  return (
    <>
      <SelectInputGroup
        required
        touched={submitted}
        labelText={Strings.categoryLabel}
        inputProps={{
          className: 'form-control',
          value: attributes.category || ''
        }}
        onUpdate={(value) => {
          onUpdateAttributes({
            ...attributes,
            category: value
          });
          onUpdateAmount(MapleConsultPrices[value] || 0);
        }}
        messageClassName="alert alert-danger"
        messageText={Strings.categoryErrorMessage}
        inputValid={!!attributes.category}
      >
        <option value="">{Strings.selectCategoryOptionLabel}</option>
        <option value="weekday">Weekday ($49)</option>
        <option value="weekend">Weekend ($79)</option>
        <option value="holiday">Holiday ($79)</option>
        <option value="overnight">Overnight ($99)</option>
      </SelectInputGroup>
      <AmountField 
        amount={amount}
        onUpdate={onUpdateAmount}
        currency={currency} 
      />
      <PatientAccountField 
        account={attributes.account}
        onUpdate={(value) => onUpdateAttributes({ ...attributes, account: value })}
      />
    </>
  );
};

const CustomChargeFields = ({
  amount,
  attributes,
  submitted,
  onUpdateAmount,
  onUpdateAttributes,
  currency,
}) => {
  const { description = '' } = attributes || {};
  return (
    <>
      <AmountField 
        amount={amount}
        submitted={submitted}
        onUpdate={onUpdateAmount}
        currency={currency} 
      />
      <FormInputGroup 
        required
        className="form-group"
        labelText={Strings.descriptionLabel}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: description || '',
          onChange: (e) => onUpdateAttributes({ ...attributes, description: e.target.value }) 
        }}
        inputValid={description.length > 0}
        messageClassName="alert alert-danger"
        messageText={Strings.descriptionErrorMessage}
        touched={submitted}
      />
    </>
  );
};

const getFormFieldsComponent = (type) => {
  switch (type) {
    case LineItemTypes.Impact:
      return ImpactFormFields;
    case LineItemTypes.MapleConsult:
      return MapleConsultFields;
    case LineItemTypes.Custom:
      return CustomChargeFields;
    case LineItemTypes.Credit:
    default:
      return DefaultFormFields;
  }
};

const LineItemFormFields = ({
  type,
  amount,
  submitted,
  currency,
  attributes,
  onUpdateAmount,
  onUpdateAttributes
}) => {
  const FormFields = getFormFieldsComponent(type);

  return (
    <FormFields 
      currency={currency}
      amount={amount}
      submitted={submitted}
      attributes={attributes}
      onUpdateAmount={onUpdateAmount}
      onUpdateAttributes={onUpdateAttributes}
    />
  );
};

export default LineItemFormFields;

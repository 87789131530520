import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import TIMER_STATES from './timer-states';
import ElapsedTimer from '../../timers/elapsed-timer';

const TIMER_INTERVAL = 10;
const S_TO_MS = 1000;

class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    const { seconds, initialState, initialTimeRemaining } = props;

    this.onTick = this.onTick.bind(this);
    this.timer = new ElapsedTimer(TIMER_INTERVAL, this.onTick);
    this.onStartClick = this.onActionBtnClick.bind(this, 'start');
    this.onStopClick = this.onActionBtnClick.bind(this, 'stop');
    this.onResetClick = this.onActionBtnClick.bind(this, 'reset');

    this.state = {
      timeRemaining: initialTimeRemaining ?? seconds * S_TO_MS,
      timerState: initialState || TIMER_STATES.NOT_STARTED
    };
  }

  render() {
    const { timeRemaining, timerState } = this.state;
    const { className, btnClassName, precision = 2 } = this.props;

    return (
      <div className={className}>
        <p>{((timeRemaining ?? 0) / 1000).toFixed(precision)}</p>
        <If condition={timerState === TIMER_STATES.NOT_STARTED}>
          <Then>
            <button 
              type="button" 
              className={classNames('btn btn-success', btnClassName)}
              onClick={this.onStartClick}
            >
              Start
            </button>
          </Then>
        </If>
        <If condition={timerState === TIMER_STATES.RUNNING}>
          <Then>
            <button 
              type="button" 
              className={classNames('btn btn-default', btnClassName)}
              onClick={this.onStopClick}
            >
              Stop
            </button>
          </Then>
        </If>
        <If condition={timerState === TIMER_STATES.STOPPED}>
          <Then>
            <button 
              type="button" 
              className={classNames('btn btn-warning', btnClassName)}
              onClick={this.onResetClick}
            >
              Reset
            </button>
          </Then>
        </If>
      </div>
    );
  }

  onTick(msElapsed) {
    let { timeRemaining, timerState } = this.state;
    const { seconds, onCompleted = () => {} } = this.props;
    timeRemaining = (seconds * S_TO_MS) - msElapsed;

    if (timeRemaining <= 0) {
      timeRemaining = 0;
      this.timer.stop();
      timerState = TIMER_STATES.STOPPED;
      onCompleted();
    }

    this.setState({
      timeRemaining,
      timerState
    });
  }

  onActionBtnClick(type) {
    let { timerState, timeRemaining } = this.state;
    const { seconds, onStop, onReset } = this.props;

    switch (type) {
      case 'start':
        this.timer.start();
        timerState = TIMER_STATES.RUNNING;
        break;
      case 'stop':
        this.timer.stop();
        timerState = TIMER_STATES.STOPPED;
        if (onStop) {
          onStop(timeRemaining);
        }
        break;
      case 'reset':
        timeRemaining = (seconds * S_TO_MS);
        timerState = TIMER_STATES.NOT_STARTED;
        if (onReset) {
          onReset();
        }
        break;
      default:
        break;
    }

    this.setState({
      timerState,
      timeRemaining
    });
  }
}

CountdownTimer.propTypes = {
  className: PropTypes.string,
  seconds: PropTypes.number.isRequired,
  onCompleted: PropTypes.func
};

CountdownTimer.STATES = TIMER_STATES;

export default CountdownTimer;

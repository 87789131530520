import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Sugar from 'sugar-date';
import dayjs from 'dayjs';
import classNames from 'classnames';
import FormInputGroup from './form-input-group';
import Strings from './lang';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT, INPUT_DATE_TIME_FORMAT } from '../../dates/formats';
import DateValidator from '../validators/date-validator';

const getStateFromProps = (props) => {
  const { 
    initialValue, 
    minDate, 
    maxDate, 
    languageDateFormat = DISPLAY_DATE_FORMAT 
  } = props;
  const date = dayjs(initialValue, INPUT_DATE_FORMAT);
  const languageValue = date.isValid() ? date.format(languageDateFormat) : '';
  const valid = DateValidator.test(date, minDate, maxDate) 
    || (initialValue.length === 0 && !props.required);

  return {
    formattedValue: initialValue,
    languageValue,
    valid
  };
};

class DateInputGroup extends Component {
  constructor(props) {
    super(props);

    this.onUpdateDate = this.onUpdateDate.bind(this);
    this.onUpdateText = this.onUpdateText.bind(this);

    this.state = {
      ...getStateFromProps(props)
    };
  }

  render() {
    return (
      <div className="date-input-group-container">
        <FormInputGroup
          className={classNames('date-input-group', this.props.className)}
          labelText={this.props.labelText || Strings.dateLabelText}
          labelProps={this.props.labelProps}
          inputType={this.props.time ? 'datetime' : 'date'}
          inputProps={this.mergeDateInputProps()}
          inputValid={this.state.valid}
          messageText={this.props.messageText || Strings.dateErrorText}
          messageClassName={this.props.messageClassName}
          required={this.props.required}
          touched={this.props.touched}
        />
        <FormInputGroup
          className={classNames('date-text-input-group', this.props.className)}
          labelText={this.props.labelText || Strings.dateLabelText}
          labelProps={this.props.labelProps}
          inputType="text"
          inputProps={this.mergeTextInputProps()}
          inputValid={this.state.valid}
          messageText={this.props.messageText || Strings.dateErrorText}
          messageClassName={this.props.messageClassName}
          required={this.props.required}
          touched={this.props.touched}
        />
      </div>
    );
  }

  mergeDateInputProps() {
    return {
      ...(this.props.dateInputProps || {}),
      value: this.state.formattedValue,
      onBlur: this.onUpdateDate,
      onChange: this.onUpdateDate
    };
  }

  mergeTextInputProps() {
    return {
      ...(this.props.textInputProps || {}),
      value: this.state.languageValue,
      onBlur: this.onUpdateText,
      onChange: this.onUpdateText
    };
  }

  onUpdateDate(event) {
    const { 
      required, 
      minDate, 
      maxDate, 
      onUpdate, 
      languageDateFormat = DISPLAY_DATE_FORMAT 
    } = this.props;
    const formattedValue = event.target.value;
    const date = dayjs(
      formattedValue,
      this.props.time ? INPUT_DATE_TIME_FORMAT : INPUT_DATE_FORMAT
    );
    const valid = DateValidator.test(date, minDate, maxDate) 
      || (formattedValue.length === 0 && !required);
    const languageValue = date.isValid() ? date.format(languageDateFormat) : '';

    this.setState({
      formattedValue,
      languageValue,
      valid
    });

    if (typeof onUpdate === 'function') {
      onUpdate(formattedValue, valid);
    }
  }

  onUpdateText(event) {
    const { 
      required, 
      locale = 'en', 
      minDate, 
      maxDate, 
      onUpdate 
    } = this.props;
    const languageValue = event.target.value;
    const date = dayjs(Sugar.Date.create(languageValue, { locale }));
    const valid = DateValidator.test(date, minDate, maxDate) 
      || (languageValue.length === 0 && !required);
    const formattedValue = date.isValid() ? date.format(this.props.time ? INPUT_DATE_TIME_FORMAT : INPUT_DATE_FORMAT) : '';

    this.setState({
      formattedValue,
      languageValue,
      valid
    });

    if (typeof onUpdate === 'function') {
      onUpdate(formattedValue, valid);
    }
  }
}

DateInputGroup.propTypes = {
  labelText: PropTypes.string,
  labelProps: PropTypes.object,
  dateInputProps: PropTypes.object,
  textInputProps: PropTypes.object,
  messageText: PropTypes.string,
  messageClassName: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  initialValue: PropTypes.string,
  languageDateFormat: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  locale: PropTypes.string,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  onUpdate: PropTypes.func
};

export default DateInputGroup;

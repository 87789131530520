import { OrderedSymptomKeys, SymptomStrings } from '../../../symptom-data';
import Strings from '../lang';

export const METRIC_KEYS = {
  Severity: 'severity',
  Totals: 'totals',
  Individual: 'individual'
};

export const MetricOptions = () => ([
  { label: Strings.symptomSeverityLabel, value: METRIC_KEYS.Severity },
  { label: Strings.symptomTotalLabel, value: METRIC_KEYS.Totals },
  { label: Strings.individualSymptomsLabel, value: METRIC_KEYS.Individual }
]);

export const SymptomOptions = OrderedSymptomKeys.map(key => ({
  label: SymptomStrings[key],
  value: key
}));

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import { PatientName } from '../patient-meta';

const formatAddressLine = (street1, street2) => {
  return (street2 && street2.length) ? `${street1} ${street2}, ` : `${street1}, `;
};

const formatLine = (label, values) => {
  if (values.length) {
    return (
      <span>
        <strong>{label}</strong> {
        values.reduce((str, val) => {
          return (val && val.length) ? `${str}${val}` : str;
        }, '')
}
      </span>
    );
  }
    
  return null;
};

const PrintMeta = ({
  patient = {}, 
  clinic = {}, 
  className
}) => {
  const person = patient.person || {};
  const address = clinic.address || {};

  return (
    <div className={classNames('print-meta', className)}>
      <div className="row">
        <div className="col-md-12">
          <ul className="list-unstyled">
            <li>
              <strong>Patient:</strong> <PatientName patient={patient} clinicId={clinic.id || 0} tagName="span" />&nbsp;
              {formatLine('Date of Birth:', [dayjs(person.birthday).format(DISPLAY_DATE_FORMAT)])}
            </li>
            {(clinic.id !== undefined) && (
              <li>
                {
                  formatLine('Clinic:', [
                    `${clinic.name}, `,
                    formatAddressLine(address.street_1, address.street_2),
                    `${address.city}, `,
                    `${address.state} `,
                    `${address.postal_code} `,
                    `${address.country} `
                  ])
                }
                {formatLine('Phone', (clinic.phone && clinic.phone.length) ? [`${clinic.phone} `] : [])}
                {formatLine('Fax', (clinic.fax && clinic.fax.length) ? [`${clinic.fax} `] : [])}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

PrintMeta.propTypes = {
  patient: PropTypes.object.isRequired,
  clinic: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default PrintMeta;

import React from 'react';
import { SymptomStrings, OrderedSymptomKeys, MAX_SYMPTOM_SEVERITY } from '../../symptom-data';
import Strings from './lang';

const SymptomsColumn = () => (
  <div className="symptoms-update-column">
    <div className="symptoms-column-header">Symptoms</div>
    <div className="symptom-keys-column">
      {OrderedSymptomKeys.map((key, index) => (
        <div key={index}>{SymptomStrings[key]}</div>
      ))}
      <div className="symptoms-update-total">
        {Strings.severityTotalLavel}&nbsp;
        ({Strings.formatString(Strings.maxLabel, MAX_SYMPTOM_SEVERITY * OrderedSymptomKeys.length)})
      </div>
      <div className="symptoms-update-total">
        {Strings.totalLabel} ({Strings.formatString(Strings.maxLabel, OrderedSymptomKeys.length)})
      </div>
    </div>
  </div>
  
);

export default SymptomsColumn;

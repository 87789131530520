import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { parseMarkdown } from '../../utilities/parse-markdown';
import { Page } from '../page';

class Policy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
      error: null
    };
  }

  componentDidMount() {
    this.fetchPolicy();
  }

  render() {
    const __html = (this.state.error) ? this.state.error.message : this.state.content;
    return (
      <Page className={classNames('policy', this.props.className)}>
        <div className="policy-content" dangerouslySetInnerHTML={{ __html }} />
      </Page>
    );
  }

  fetchPolicy() {
    let { policyUrl } = this.props;

    // Append a cache busting query param:
    if (this.props.preventCache) {
      const timestamp = Date.now();
      policyUrl = `${policyUrl}?t=${timestamp}`;
    }

    fetch(policyUrl).then(response => {
      return response.text();
    }).then(text => {
      const content = parseMarkdown(text);
      this.setState({ content, error: null });
    }).catch(error => {
      this.setState({ error });
    });
  }
}

Policy.propTypes = {
  policyUrl: PropTypes.string.isRequired,
  preventCache: PropTypes.bool,
  className: PropTypes.string
};

export default Policy;

import React from 'react';
import dayjs from 'dayjs';
import TestTypeLabel from './test-type-label';
import { TestKeyLabels } from '../utils';
import { DISPLAY_DATE_TIME_FORMAT } from '../../../dates/formats';

const TestValue = ({
  name,
  color,
  dataKey,
  payload
}) => {
  const { [dataKey]: value } = payload;
  return (
    <div key={name} style={{ color }}>
      {TestKeyLabels[name]}: {value?.toFixed(1)}%
    </div>
  );
};

const TestTooltip = ({
  active,
  payload: payloads,
  label
}) => {
  if (active && payloads && payloads.length) {
    const test = payloads[0].payload;

    return (
      <div className="balance-chart-tooltip">
        <div className="bold">{dayjs(label).format(DISPLAY_DATE_TIME_FORMAT)}</div>
        <TestTypeLabel postInjury={test.post_injury} />
        {payloads.map((payload, index) => (
          <TestValue key={`${payload.name}:${index}`} {...payload} />
        ))}
      </div>
    );
  }

  return null;
};

export default TestTooltip;

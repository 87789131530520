import { getAuthData } from '../../tokens';
import { getCurrentRegistrationSession } from '../../utilities/registration-session';
import checkUserTrainingExpiry from '../../utilities/user-training-expiry';
import checkUserActive from '../../utilities/user-active';
import {
  userHasRoleMatchingOneOfDescriptions,
  userHasAdminOrClinicRole,
  userHasRoleRequiringTraining,
  resolveRoleDescriptionsWithResourceIdParam,
  RoleDescriptions,
  AllRoleDescriptions,
  NoResourceTypeClinicUserRoles,
  userHasIDTRole
} from '../../utilities/user-roles';

import { AuthenticationPaths, RedirectPaths, RegistrationPaths } from '../../paths';

const getRedirectRoute = (
  user,
  {
    location,
    params
  },
  {
    allowDeactivated = false,
    allowUnconfirmed = false,
    allowUntrained = false,
    allowNonClinicUser = false,
    allowRoles = AllRoleDescriptions,
    allowRolesForDeactivatedUser = [],
    allowRolesMatchingResourceId = [],
    resourceIdParam = ''
  }
) => {
  // Get the current user's auth data from local storage:
  const { id, authToken } = getAuthData();

  // Redirect unauthenticated users to authentication:
  if (!user || !id || !authToken || !authToken.length) {
    return {
      path: AuthenticationPaths.index.link,
      state: {
        nextPathname: location.pathname
      }
    };
  }

  // Redirect unconfirmed users:
  if (!user.confirmed && !allowUnconfirmed) {
    return {
      path: RedirectPaths.unconfirmed.link
    };
  }

  // Resolve roles that require a matching resource id with the id param.
  // If the specified param is not contained in `nextState.params`, the role
  // will be rejected and the user will be refused access unless they match
  // another allowed role.
  const resourceRoles = resolveRoleDescriptionsWithResourceIdParam(
    allowRolesMatchingResourceId,
    params,
    resourceIdParam
  );

  // Concat an array of all allowed roles:
  const allAllowedRoles = allowRoles.concat(resourceRoles);

  if (userHasAdminOrClinicRole(user) || userHasIDTRole(user)) {
    // The user has an admin or clinic role so verify that they are active, trained and
    // have permission to access the requested resource. If not redirect them to
    // the unauthorized page:

    // Redirect deactivated users, with the exception of clinic owners who must be allowed
    // to access payment UI:
    if (!allowDeactivated
      && !checkUserActive(user)
      && !userHasRoleMatchingOneOfDescriptions(user, allowRolesForDeactivatedUser)
    ) {
      return {
        path: RedirectPaths.deactivated.link
      };
    }

    // Redirect untrained users:
    if (!allowUntrained && (userHasRoleRequiringTraining(user) && !checkUserTrainingExpiry(user))) {
      return {
        path: RedirectPaths.untrained.link
      };
    }

    // Verify permissions and redirect if insufficient:
    if (!userHasRoleMatchingOneOfDescriptions(user, allAllowedRoles)) {
      return {
        path: RedirectPaths.unauthorized.link
      };
    }
  } else {
    if (userHasRoleMatchingOneOfDescriptions(user, allAllowedRoles)) {
      return null;
    }

    if (userHasRoleMatchingOneOfDescriptions(user, [
      RoleDescriptions.Leader,
      RoleDescriptions.Player,
      RoleDescriptions.Guardian
    ]) && !allowNonClinicUser) {
      return {
        path: RedirectPaths.mobileApp.link
      };
    }

    if (userHasRoleMatchingOneOfDescriptions(user, NoResourceTypeClinicUserRoles)
      && !allowNonClinicUser
    ) {
      return {
        path: RedirectPaths.unassociated.link
      };
    }
  }

  return null;
};

const getRegistrationRedirectRoute = () => {
  const { id, token } = getCurrentRegistrationSession() || {};

  if (!id || !token) {
    return {
      path: RegistrationPaths.index.link
    };
  }

  return null;  
};

export {
  getRedirectRoute,
  getRegistrationRedirectRoute
};

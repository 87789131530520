import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { Orientation } from '../test-components';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import Card from '../../card';
import { AddNoteButton } from '../../buttons';
import HeaderStrings from '../lang';
import { FormComponent } from '../../../forms';
import Strings from './lang';

class OrientationCard extends Component {
  constructor(props) {
    super(props);

    const { orientation = {} } = props;
    const { 
      sac_standard_assessment_score = 0, 
      what_month = '', 
      what_date = '', 
      what_day = '', 
      what_year = '', 
      what_time = '' 
    } = orientation;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      submitted: false,
      sac_standard_assessment_score,
      sac_notes: orientation.sac_notes || '',
      what_month, 
      what_date, 
      what_day, 
      what_year, 
      what_time 
    };
  }

  render() {
    const {
      what_month, what_date, what_day, what_year, what_time, sac_notes 
    } = this.state;
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card 
          title={HeaderStrings.orientationHeader} 
          actionButtons={(
            <AddNoteButton 
              note={sac_notes}
              title={
                Strings.formatString(Strings.abStractNotesTitle, HeaderStrings.orientationHeader)
              }
              submitButtonTitle={Strings.addNoteButtonText}
              onSave={(notes) => {
                this.setState({ sac_notes: notes });
              }}
            />
          )}
        >
          <div className="baseline-card">
            <div className="card-section">
              <p className="text-muted">{Strings.orientationInfoText}</p>
            </div>
            <Orientation 
              initialState={{ 
                score: this.state.sac_standard_assessment_score,
                what_month, 
                what_date, 
                what_day, 
                what_year, 
                what_time
              }}
              touched={this.state.submitted}
              onUpdate={this.onUpdate}
            />
            <If condition={typeof this.props.onSubmit === 'function'}>
              <Then>
                <div className="card form-footer">
                  <button type="submit" className="btn btn-primary">
                    {this.props.saveButtonText || Strings.saveButtonText}
                  </button>
                </div>
              </Then>
            </If>
          </div>
        </Card>
      </FormComponent>
    );
  }

  onUpdate(values) {
    const { 
      score,
      what_month, 
      what_date, 
      what_day, 
      what_year, 
      what_time  
    } = values;

    this.setState({
      sac_standard_assessment_score: score,
      what_month, 
      what_date, 
      what_day, 
      what_year, 
      what_time
    });
  }

  isValid() {
    const { 
      what_month, what_date, what_day, what_year, what_time 
    } = this.state;
    return what_month !== '' 
      && what_date !== ''
      && what_day !== '' 
      && what_year !== '' 
      && what_time !== '';
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const { 
      sac_standard_assessment_score, 
      sac_notes,
      what_month, 
      what_date, 
      what_day, 
      what_year, 
      what_time 
    } = this.state;

    const attributes = {
      cognitive_assessment_test: {
        sac_standard_assessment_score,
        sac_notes,
        what_month, 
        what_date, 
        what_day, 
        what_year, 
        what_time
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    } 
  }
}

OrientationCard.propTypes = {
  ...BaselineTestCardPropTypes,
  orientation: PropTypes.object
};

export default OrientationCard;

import { jsonApiCreate } from '../../requests/jsonapi';
import { deleteGuardianAuth } from '../actions/guardians-account';
import { CONFIRMATION_URL } from '../../utilities/urls';
import { updateUser, updateUserChildren } from '../actions/users';
import {
  addRegistrationSession,
  getCurrentRegistrationSession,
  switchRegistrationAuthContext
} from '../../utilities/registration-session';

const createAddChildAsync = (guardian = {}, userAttributes = {}) => {
  const options = { path: 'users/add_child', headers: { Authorization: getCurrentRegistrationSession().token } };
  const attributes = { ...userAttributes, confirmation_url: CONFIRMATION_URL };
  return (dispatch) => {
    return jsonApiCreate({ type: 'users', attributes }, options).then(user => {
      addRegistrationSession(user, true);
      dispatch(updateUser(user));
      dispatch(updateUserChildren(guardian.id, user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const guardianChildLoginAsync = (user = {}) => {
  return (dispatch) => {
    switchRegistrationAuthContext(user.id);
    dispatch(updateUser(user));
    return Promise.resolve(user);
  };
};

const deleteGuardianInfoAsync = () => {
  return (dispatch) => {
    dispatch(deleteGuardianAuth());
    return Promise.resolve('');
  };
};

const addExistingChildAsync = (parentId, attributes, userOptions = {}) => {
  const options = { 
    ...userOptions,
    path: 'users/add_child/existing', 
    headers: { Authorization: getCurrentRegistrationSession().token } 
  };
  return dispatch => {
    return jsonApiCreate({ type: 'users', attributes }, options).then(user => {
      addRegistrationSession(user, false);
      dispatch(updateUser(user));
      dispatch(updateUserChildren(parentId, user));
    });
  };
};

export {
  createAddChildAsync,
  guardianChildLoginAsync,
  deleteGuardianInfoAsync,
  addExistingChildAsync
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { BagTagPaths } from '../../paths';
import replaceLinkParams from '../../utilities/replace-link-params';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import Strings from './lang';

const BagTagsTable = ({
  batches = [], 
  sortKeyPath = '',
  onUpdateSortKeyPath = () => {}
}) => (
  <table className="table bag-tags-table">
    <thead>
      <tr>
        <th 
          className={classNames({ highlighted: sortKeyPath === 'created_at' })}
          onClick={() => onUpdateSortKeyPath('created_at')}
        >
          {Strings.dateTableHeadingLabelText}
        </th>
        <th 
          className={classNames({ highlighted: sortKeyPath === 'name' })}
          onClick={() => onUpdateSortKeyPath('name')}
        >
          {Strings.nameTableHeadingLabelText}
        </th>
        <th 
          className={classNames({ highlighted: sortKeyPath === 'size' })}
          onClick={() => onUpdateSortKeyPath('size')}
        >
          {Strings.sizeTableHeadingLabelText}
        </th>
      </tr>
    </thead>
    <tbody>
      {batches.map(batch => (
        <tr key={batch.id}>
          <td>{dayjs(batch.created_at).format(DISPLAY_DATE_FORMAT)}</td>
          <td>
            <Link 
              to={replaceLinkParams(BagTagPaths.batch.link, {
                batchId: batch.id
              })}
            >
              {batch.name}
            </Link>
          </td>
          <td>{batch.size}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

BagTagsTable.propTypes = {
  batches: PropTypes.array.isRequired,
  sortKeyPath: PropTypes.string.isRequired,
  onUpdateSortKeyPath: PropTypes.func.isRequired
};

export default BagTagsTable;

import React from 'react';
import Deactivated from '../components/deactivated';
import Untrained from '../components/untrained';
import Unassociated from '../components/unassociated';
import Unconfirmed from '../components/unconfirmed';
import Unauthorized from '../components/unauthorized';
import MobileApp from '../components/mobile-app';

const RedirectRoutes = [
  {
    path: 'deactivated',
    element: <Deactivated />
  },
  {
    path: 'untrained',
    element: <Untrained />
  },
  {
    path: 'unassociated',
    element: <Unassociated />
  },
  {
    path: 'unconfirmed',
    element: <Unconfirmed />
  },
  {
    path: 'unauthorized',
    element: <Unauthorized />
  },
  {
    path: 'app',
    element: <MobileApp />
  }
];

export default RedirectRoutes;

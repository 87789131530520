import React, { useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import AccessControl from '../access-control';
import { EditButton } from '../buttons';
import ClinicianPhotoCard from '../user-profile-cards/clinician-photo-card';
import EditClinicianPhotoCardForm from '../user-profile-cards/edit-clinician-photo-card-form';
import ClinicianPersonalInfoCard from '../user-profile-cards/clinician-personal-info-card';
import EditClinicianPersonalInfoCardForm from '../user-profile-cards/edit-clinician-personal-info-card-form';
import ClinicianClinicalInfoCard from '../user-profile-cards/clinician-clinical-info-card';
import EditClinicianClinicalInfoCardForm from '../user-profile-cards/edit-clinician-clinical-info-card-form';
import ClinicianContactInfoCard from '../user-profile-cards/clinician-contact-info-card';
import EditClinicianContactInfoCardForm from '../user-profile-cards/edit-clinician-contact-info-card-form';
import UserClinicsInfoCard from '../user-profile-cards/user-clinics-info-card';
import { RoleDescriptions, userHasRoleMatchingOneOfDescriptions } from '../../utilities/user-roles';
import Strings from './lang';

const ShowClinicianProfile = ({
  user = {}, 
  editorRoles = [],
  editPhoto = false,
  editPersonal = false,
  editClinical = false,
  editContact = false,
  onClickPhotoEditButton,
  onClickPersonalEditButton,
  onClickClinicalEditButton,
  onClickContactEditButton,
  onSubmit
}) => {
  const clinics = useMemo(() => (user.clinics || []).filter(Boolean), [user.clinics]);

  return (
    <div className="profile clinician">

      <div className="card-container">
        <div className="card-container-header">

          <h2>{ Strings.photoHeadingText }</h2>

          <AccessControl roles={editorRoles} allowIfClinicDeactivated allowUntrained>
            <EditButton
              editing={editPhoto}
              onClick={onClickPhotoEditButton}
            />
          </AccessControl>

        </div>
        <div className="card-container-body">

          <If condition={editPhoto}>
            <Then>
              <EditClinicianPhotoCardForm
                user={user}
                onSubmit={onSubmit}
              />
            </Then>
            <Else>
              <ClinicianPhotoCard user={user} />
            </Else>
          </If>

        </div>
      </div>

      <div className="card-container">
        <div className="card-container-header">

          <h2>{Strings.personalHeadingText}</h2>

          <AccessControl roles={editorRoles} allowIfClinicDeactivated allowUntrained>
            <EditButton
              editing={editPersonal}
              onClick={onClickPersonalEditButton}
            />
          </AccessControl>

        </div>
        <div className="card-container-body">

          <If condition={editPersonal}>
            <Then>
              <EditClinicianPersonalInfoCardForm
                user={user}
                onSubmit={onSubmit}
              />
            </Then>
            <Else>
              <ClinicianPersonalInfoCard user={user} />
            </Else>
          </If>

        </div>
      </div>

      {userHasRoleMatchingOneOfDescriptions(
        user, 
        [RoleDescriptions.Clinician, RoleDescriptions.Specialist, RoleDescriptions.IDTClinician]
      ) && (
        <div className="card-container">
          <div className="card-container-header">
            <h2>{Strings.clinicalInfoTitle}</h2>

            <AccessControl roles={editorRoles} allowIfClinicDeactivated allowUntrained>
              <EditButton
                editing={editClinical}
                onClick={onClickClinicalEditButton}
              />
            </AccessControl>
          </div>
          <div className="card-container-body">

            <If condition={editClinical}>
              <Then>
                <EditClinicianClinicalInfoCardForm
                  user={user}
                  onSubmit={onSubmit}
                />
              </Then>
              <Else>
                <ClinicianClinicalInfoCard user={user} />
              </Else>
            </If>

          </div>
        </div>
      )}

      <div className="card-container">
        <div className="card-container-header">

          <h2>{Strings.contactHeadingText}</h2>

          <AccessControl roles={editorRoles} allowIfClinicDeactivated allowUntrained>
            <EditButton
              editing={editContact}
              onClick={onClickContactEditButton}
            />
          </AccessControl>

        </div>
        <div className="card-container-body">

          <If condition={editContact}>
            <Then>
              <EditClinicianContactInfoCardForm
                user={user}
                onSubmit={onSubmit}
              />
            </Then>
            <Else>
              <ClinicianContactInfoCard user={user} />
            </Else>
          </If>

        </div>
      </div>

      <If condition={clinics.length > 0}>
        <Then>
          <div className="card-container">
            <div className="card-container-header">
              <h2>{ Strings.clinicsHeadingText }</h2>
            </div>
            <div className="card-container-body">
              {clinics.map(clinic => (
                <UserClinicsInfoCard key={clinic.id} className="underline" clinic={clinic} />
              ))}
            </div>
          </div>
        </Then>
      </If>

    </div>
  );
};

export default ShowClinicianProfile;

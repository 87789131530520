import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Strings from './lang';
import GenderStrings from '../../gender';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../dates/formats';
import { age } from '../../dates';

const InjuryReportHeader = ({
  user = {}, 
  injuryDate = {}, 
  reporter = {}
}) => {
  const person = user.person || {};
  const reporterUser = reporter || {};
  const reporterPerson = reporterUser.person || {};
  const birthdate = dayjs(person.birthday, INPUT_DATE_FORMAT);
  const injuryDateDisplay = dayjs(injuryDate);

  return (
    <section className="test-header injury-report-header">

      <div className="row">
        <div className="col-md-12">
          <h2>{person.first_name} {person.last_name}</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.accountLabelText}:</label>
            <span>{user.account}</span>
          </div>
        </div>

        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.bagtagLabelText}:</label>
            <span>{user.bag_tag}</span>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.ageLabelText}:</label>
            <span>{age(birthdate)}</span>
          </div>
        </div>

        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.birthdateLabelText}:</label>
            <span>{birthdate.format(DISPLAY_DATE_FORMAT)}</span>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.genderLabelText}:</label>
            <span>{GenderStrings[person.gender]}</span>
          </div>
        </div>

        <div className="col-md-6">
          <div className="test-header-row">
            <label>{Strings.injurydateLabelText}:</label>
            <span>{injuryDateDisplay.format(DISPLAY_DATE_FORMAT)}</span>
          </div>
        </div>

      </div>

      <div className="row">
        <div className="col-md-6">
          {reporterUser.id && (
            <div className="test-header-row">
              <label>{Strings.reportedByLabelText}:</label>
              <div>
                <div>{reporterPerson.first_name} {reporterPerson.last_name}</div>
                <a href={`mailto:${reporterUser.email}`}>{reporterUser.email}</a>
                {reporterPerson.phone_number && <a href={`tel:${reporterPerson.phone_number}`}>{reporterPerson.phone_number}</a> }
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

InjuryReportHeader.propTypes = {
  user: PropTypes.object,
  injuryDate: PropTypes.string
};

export default InjuryReportHeader;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormInput, NumberValidator } from '../../forms';
import Strings from './lang';

const BlackhawksPlyometricsStage = ({
  className,
  stageTitle,
  directions,
  touched,
  heartRate,
  symptoms,
  identifier,
  rest,
  onChangeHeartRate,
  onChangeSymptoms
}) => {
  const handleHeartRateChange = useCallback((e) => {
    if (typeof onChangeHeartRate === 'function') {
      onChangeHeartRate(identifier, e);
    }
  }, [onChangeHeartRate, identifier]);

  const handleSymptomsChange = useCallback((e) => {
    if (typeof onChangeSymptoms === 'function') {
      onChangeSymptoms(identifier, e);
    }
  }, [onChangeSymptoms, identifier]);

  return (
    <div className={classNames('blackhawks-plyometrics-stage', className)}>
      <div className="row">
        <div className="col-md-12">
          <h3 className="bold">{stageTitle}</h3>

          <ul className="list-unstyled directions">
            {directions.map((direction, index) => (
              <li key={index}>{direction}</li>
            ))}
          </ul>

          <div className="row">
            <div className="col-md-3">
              <label>
                <span>{Strings.heartRateHeaderText}</span>
                <FormInput
                  className={classNames('form-control', {
                    invalid: touched && heartRate.length > 0 && !NumberValidator.test(heartRate)
                  })}
                  type="number"
                  inputProps={{
                    min: 0,
                    max: 300,
                    value: heartRate,
                    onChange: handleHeartRateChange
                  }}
                />
              </label>
            </div>
            <div className="col-md-9">
              <label>
                <span>{Strings.symptomsHeaderText}</span>
                <FormInput
                  className="form-control"
                  type="textarea"
                  inputProps={{
                    value: symptoms,
                    onChange: handleSymptomsChange
                  }}
                />
              </label>
            </div>
          </div>

          <ul className="list-unstyled rest-direction">
            <li><strong>{rest}</strong></li>
          </ul>

        </div>
      </div>
    </div>
  );
};

BlackhawksPlyometricsStage.propTypes = {
  className: PropTypes.string,
  identifier: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  stageTitle: PropTypes.string.isRequired,
  directions: PropTypes.arrayOf(PropTypes.node).isRequired,
  rest: PropTypes.string.isRequired,
  heartRate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  symptoms: PropTypes.string.isRequired,
  onChangeHeartRate: PropTypes.func,
  onChangeSymptoms: PropTypes.func,
  touched: PropTypes.bool
};

export default BlackhawksPlyometricsStage;

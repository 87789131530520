import { createSelector } from 'reselect';
import { sortOnKeyPath } from '../../utilities/sort-keypath';

const getInjuries = (state) => state.injuries || {};
const getUserId = (_, { userId }) => userId;
const getInjuryId = (_, { injuryId }) => injuryId;

const getStages = (type, objArr) => {
  return objArr.reduce((acc, obj) => {
    return obj.recovery_protocol_stage
      ? [
        ...acc, 
        {
          stageable_type: type,
          stageable_id: obj.id,
          ...obj.recovery_protocol_stage
        }
      ] : acc;
  }, []);
};

const getRecoveryStages = (injury) => {
  const { injury_assessments = [], soap_notes = [] } = injury || {};
  const soapNoteStages = getStages('SoapNote', soap_notes);
  const assessmentStages = getStages('InjuryAssessment', injury_assessments);
  const stages = soapNoteStages.concat(assessmentStages);

  return sortOnKeyPath(stages, 'created_at');
};

const getUserInjuries = createSelector(
  [getInjuries, getUserId],
  (injuries, userId) => {
    return injuries[userId] || {};
  }
);

const getUserInjury = createSelector(
  [getUserInjuries, getInjuryId],
  (injuries, injuryId) => {
    return injuries[injuryId] || {};
  }
);

const getInjuryRecoveryStages = createSelector(
  [getUserInjury], 
  (injury) => getRecoveryStages(injury)
);

const getInjuryRecoveryStage = createSelector(
  [getUserInjury], 
  (injury) => {
    if (injury.recovery_protocol_stage) {
      return injury.recovery_protocol_stage;
    }
    
    const stages = getRecoveryStages(injury);
    
    return stages[stages.length - 1] || {};
  }
);

export default {
  getUserInjuries,
  getUserInjury,
  getInjuryRecoveryStages,
  getInjuryRecoveryStage
};

import {
  jsonApiCreate,
  jsonApiUpdate
} from '../../requests/jsonapi';
import { updateUser } from '../actions/users';
import { addClinicUser } from '../actions/clinic-users';
import { setAuthDataFromUserData, updateCurrentClinicForUser } from '../../tokens';
import { INVITATION_URL } from '../../utilities/urls';

const formatClinicUser = (clinicId, user) => {
  const { 
    id, active, person, roles 
  } = user;
  const roleStrings = roles.reduce((acc, role) => {
    if (role.resource_type === 'Clinic' && String(role.resource_id) === String(clinicId)) {
      return [...acc, role.name];
    }
    return acc;
  }, []);

  return {
    id,
    active,
    person,
    roles: roleStrings
  };
};

const createInviteAsync = (userAttributes = {}) => {
  // Inject the invitation url into all invite create calls:
  const attributes = { ...userAttributes, invitation_url: INVITATION_URL };
  return (dispatch) => {
    return jsonApiCreate({ type: 'users', attributes }, { path: 'users/invitation' }).then(user => {
      const clinicId = userAttributes.clinic_id;
      const clinicUser = formatClinicUser(clinicId, user);
      dispatch(addClinicUser(clinicId, clinicUser));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const acceptInviteAsync = (attributes = {}) => {
  return (dispatch) => {
    return jsonApiUpdate({ type: 'users', attributes }, { path: 'users/invitation/accept' }).then(user => {
      setAuthDataFromUserData(user);
      updateCurrentClinicForUser(user);
      dispatch(updateUser(user));
      return Promise.resolve(user);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createInviteAsync,
  acceptInviteAsync
};

import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import ScoreView from '../../../score-view';
import TimerInput from './timer-input';
import { sortAscending } from '../../../../utilities/sort';
import Strings from './lang';
import Icon from '../../../icon';

const TandemGaitTest = ({
  trials,
  onUpdate
}) => {
  const [activeTimer, setActiveTimer] = useState(null);
  const passedTrials = useMemo(() => (
    trials.filter(t => t.duration !== '' && t.duration >= 0 && t.failed === false)
  ), [trials]);
  const fastestTime = useMemo(() => {
    return (passedTrials.sort(sortAscending.bind(null, 'duration'))[0]?.duration ?? 0) / 1000;
  }, [passedTrials]);
  const average = useMemo(() => {
    if (passedTrials.length === 0) return 0;
    return (passedTrials.reduce((acc, trial) => {
      return acc + trial.duration;
    }, 0) / passedTrials.length) / 1000;
  }, [passedTrials]);

  const handleChange = (index, duration) => {
    const newTrials = [...trials];
    newTrials[index] = {
      ...newTrials[index],
      duration
    };

    onUpdate(newTrials);
  };

  const handleFailedClick = (index) => {
    const newTrials = [...trials];
    newTrials[index] = {
      ...newTrials[index],
      failed: !newTrials[index].failed
    };

    onUpdate(newTrials);
  };

  return (
    <div className="tandem-gait-test">
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.trialColumnTitle}</th>
            <th className="no-action">{Strings.durationColumnTitle}</th>
            <th className="no-action">{Strings.outcomeColumnTitle}</th>
          </tr>
        </thead>
        <tbody>
          {trials.map((trial, index) => (
            <tr key={index}>
              <td>{Strings.formatString(Strings.trialNumberLabel, index + 1)}</td>
              <td>
                <TimerInput
                  value={trial.duration}
                  disabled={activeTimer !== null && activeTimer !== index}
                  onStart={() => setActiveTimer(index)}
                  onStop={() => setActiveTimer(null)}
                  onChange={duration => handleChange(index, duration)}
                />
              </td>
              <td className="text-center">
                <button
                  type="button"
                  disabled={activeTimer !== null}
                  className={classNames('btn btn-sm', trial.failed ? 'btn-danger' : 'btn-grey')}
                  onClick={() => handleFailedClick(index)}
                >
                  <Icon name="ban" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ScoreView
        label={Strings.fastestTimeLabel}
        score={fastestTime.toFixed(2)}
      />
      <ScoreView
        label={Strings.averageTimeLabel}
        score={average.toFixed(2)}
      />
    </div>
  );
};

export default TandemGaitTest;

import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { getCountryName, getCountryRegionName } from '../../utilities/country-regions';
import Strings from './lang';

const PlayerPhysicianInfoCard = ({
  user = {}
}) => {
  const physician_contact = user.physician_contact || {};
  const address = physician_contact.address || {};

  return (
    <div className="card card-show">

      <div className="card-row">
        <label>{ Strings.nameLabelText }:</label>
        <span>{ physician_contact.name }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.addressLabelText }:</label>
        <span>{ address.street_1 }</span>
      </div>

      <If condition={address.street_2 != null && address.street_2.length > 0}>
        <Then>
          <div className="card-row">
            <label />
            <span>{ address.street_2 }</span>
          </div>
        </Then>
      </If>

      <div className="card-row">
        <label>{ Strings.cityLabelText }:</label>
        <span>{ address.city }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.regionLabelText }:</label>
        <span>{ getCountryRegionName(address.country, address.state) }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.countryLabelText }:</label>
        <span>{ getCountryName(address.country) }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.postalCodeLabelText }:</label>
        <span>{ address.postal_code }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.phoneNumberLabelText }:</label>
        <span>{ physician_contact.phone }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.faxNumberLabelText }:</label>
        <span>{ physician_contact.fax }</span>
      </div>

      <div className="card-row">
        <label>{ Strings.emailLabelText }:</label>
        <span>{ physician_contact.email }</span>
      </div>

    </div>
  );
};

PlayerPhysicianInfoCard.propTypes = {
  user: PropTypes.object.isRequired
};

export default PlayerPhysicianInfoCard;

import React, { useCallback, useState } from 'react';
import { RadioInputGroup } from '../../../../../forms';
import { getShortFormMonthsArray } from '../../../../../dates';
import Strings from './lang';
import { CountdownTimer } from '../../../../timer-components';

const months = getShortFormMonthsArray();

const SECONDS = 30;
const S_TO_MS = 1000;
const MAX_DURATION_MS = SECONDS * S_TO_MS;

const MonthsInReverseTimed = ({
  score = '',
  duration = null,
  touched = false,
  onScoreChange = () => {},
  onDurationChange = () => {}
}) => {
  const [completed, setCompleted] = useState(() => score !== '' && duration !== null);

  const onTimerComplete = useCallback(() => {
    onDurationChange(MAX_DURATION_MS);
    
    const dingSound = new Audio('/audio/ding.mp3');

    if (dingSound.canPlayType('audio/mp3')) {
      dingSound.play().catch(() => { });
    }
    
    setCompleted(true);
  }, [onDurationChange]);

  const onTimerStop = useCallback(elapsed => {
    const time = (MAX_DURATION_MS) - elapsed;
    onDurationChange(time);
    setCompleted(true);
  }, [onDurationChange]);

  const onTimerReset = useCallback(() => {
    setCompleted(false);
    onDurationChange(null);
    onScoreChange('');
  }, [onDurationChange, onScoreChange]); 

  return (
    <div className="months-in-reverse-timed">
      <div className="months-view">
        <h4>{months.join(' \u2013 ')}</h4>
      </div>
      <div className="months-in-reverse-container">
        <CountdownTimer
          seconds={SECONDS}
          initialState={completed ? CountdownTimer.STATES.STOPPED : undefined}
          initialTimeRemaining={completed ? MAX_DURATION_MS - duration : undefined}
          className="months-in-reverse-timer"
          btnClassName="btn-sm"
          onCompleted={onTimerComplete}
          onStop={onTimerStop}
          onReset={onTimerReset}
        />
        {completed && (
          <div className="months-answer-input">
            <p>Duration: {(duration / 1000).toFixed(2)}s</p>
            <RadioInputGroup
              titleLabelText={Strings.monthsInReverseScoreLabel}
              radioValues={['1', '0']}
              radioLabels={[Strings.yesAnswerLabel, Strings.noAnswerLabel]}
              initialValue={score?.toString()}
              className="baseline-radio-input"
              onUpdate={onScoreChange}
              inputValid={score !== ''}
              touched={touched}
              messageText={Strings.requiredError}
              messageClassName="alert alert-danger"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthsInReverseTimed;

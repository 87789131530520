import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT, DISPLAY_DATE_TIME_FORMAT } from '../../../dates/formats';
import { ReportTypeStrings } from '../utils';
import Strings from '../lang';

const ActiveClaimsTable = ({ claims = [] }) => {
  if (!claims.length) {
    return (
      <p className="text-muted">
        <i>{Strings.noActiveClaims}</i>
      </p>
    );
  }

  return (
    <table className="table">
      <thead>
        <tr>
          <th className="no-action">{Strings.claimNumberColumn}</th>
          <th className="no-action">{Strings.workerNameColumn}</th>
          <th className="no-action">{Strings.nextAppointmentColumn}</th>
          <th className="no-action">{Strings.nextReportDueColumn}</th>
        </tr>
      </thead>
      <tbody>
        {claims.map(claim => (
          <tr key={claim.id}>
            <td>
              <Link to={claim.id}><strong>{claim.claim_number}</strong></Link>
            </td>
            <td>{claim.worker.first_name} {claim.worker.last_name}</td>
            <td>{claim.next_appointment?.date ? dayjs(claim.next_appointment.date).format(DISPLAY_DATE_TIME_FORMAT) : '--'}</td>
            <td>
              {claim.next_report_due?.date ? `${dayjs(claim.next_report_due.date).format(DISPLAY_DATE_FORMAT)} (${ReportTypeStrings[claim.next_report_due.type]})` : '--'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ActiveClaimsTable;

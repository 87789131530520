import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccessControl from '../access-control';
import { ClinicServicesInput } from '../clinic-services';
import { ImageCropper, ImageCropperOptions } from '../../image-tools';
import { ClinicTypeCategorySelect } from '../clinic-type-category';
import { userHasRoleMatchingOneOfDescriptions, RoleDescriptions, userHasAdminRole } from '../../utilities/user-roles';
import { FormComponent, FormInputGroup } from '../../forms';
import log from '../../utilities/log';
import Strings from './lang';

class EditGeneralInfoCardForm extends Component {
  constructor(props) {
    super(props);

    this.onUpdateName = this.onUpdateValue.bind(this, 'name');
    this.onUpdateBio = this.onUpdateValue.bind(this, 'bio');
    this.onUpdateImpactCode = this.onUpdateValue.bind(this, 'impactCode');
    this.onUpdateServices = this.onUpdateServices.bind(this);
    this.onUpdateCategory = this.onUpdateCategory.bind(this);

    const name = props.clinic.name || '';
    const bio = props.clinic.bio || '';
    const category = props.clinic.category || '';
    const impactCode = props.clinic.impact_code || '';
    const services = props.clinic.services || [];

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      imageFile: {},
      imageDataURI: '',
      name,
      category,
      bio,
      services,
      impactCode
    };
  }

  render() {
    return (
      <FormComponent className="card card-form" onSubmit={this.onSubmit}>

        <ImageCropper 
          className="avatar-image-cropper"
          inputName="avatar-image"
          chooseButtonClassName="btn btn-default"
          cropButtonClassName="btn btn-primary"
          cropperOptions={{
            ...ImageCropperOptions,
            aspectRatio: null /** will default to 'free' aspect ratio */
          }}
          onCrop={(imageFile, imageDataURI) => {
            this.setState({ imageFile, imageDataURI });
          }}
          onFileTypeError={(type, validTypes) => {
            log('Invalid file type', type, validTypes);
          }}
        />

        <FormInputGroup
          className="form-group name-input-group"
          labelText={Strings.nameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.name,
            onChange: this.onUpdateName
          }}
          inputValid={this.state.name.length > 0}
          messageText={Strings.nameValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />
        <AccessControl
          roles={[
            RoleDescriptions.SuperAdmin,
            RoleDescriptions.SalesAdmin
          ]}
        >
          <ClinicTypeCategorySelect
            required
            className="form-group"
            category={this.state.category}
            touched={this.props.touched}
            onChange={this.onUpdateCategory}
          />

          <ClinicServicesInput
            services={this.state.services}
            onUpdate={this.onUpdateServices}
          />
        </AccessControl>

        <FormInputGroup
          className="form-group impact-code-input-group"
          labelText={Strings.impactCodeLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.impactCode,
            readOnly: !userHasRoleMatchingOneOfDescriptions(this.props.user, [
              RoleDescriptions.SuperAdmin,
              RoleDescriptions.SalesAdmin
            ]),
            onChange: this.onUpdateImpactCode
          }}
          inputValid
          required={false}
          touched={this.state.submitted}
        />

        <FormInputGroup 
          inputValid
          required={false}
          className="form-group"
          labelText={Strings.bioLabelText}
          inputType="textarea"
          inputProps={{
            rows: 10,
            className: 'form-control',
            value: this.state.bio,
            onChange: this.onUpdateBio
          }}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.name.length;
  }
  
  onUpdateCategory(category) {
    this.setState({ category });
  }

  onUpdateValue(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onUpdateServices(services) {
    this.setState({ services });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      imageFile,
      imageDataURI,
      name,
      category,
      impactCode,
      services,
      bio
    } = this.state;

    const attributes = {
      photo_base64: imageDataURI || '',
      photo_filename: imageFile.name || '',
      name,
      bio,
      impact_code: impactCode
    };

    if (userHasAdminRole(this.props.user)) {
      attributes.services = services;
      attributes.category = category;
    }

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

EditGeneralInfoCardForm.propTypes = {
  clinic: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EditGeneralInfoCardForm;

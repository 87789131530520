import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMedicalInfoAsync } from '../../../../redux/thunks/medical-info';
import { MedicalInfo } from '../../../medical-info';
import Activity from '../../../activity';
import Card from '../../../card';
import Strings from './lang';
import SharedStrings from '../lang';

const mergeConditions = (userConditions, assessmentConditions) => {
  return userConditions.reduce((conditions, condition) => {
    const assessmentCondition = assessmentConditions.find(item => item.name === condition.name);
    const data = assessmentCondition ? { id: assessmentCondition.id } : {};
    data.name = condition.name;

    if (condition.removed_at && !(assessmentCondition || {}).removed_at) {
      data.removed_at = condition.removed_at;
    }

    return [
      ...conditions,
      data
    ];
  }, []);
};

const buildMedicalInfoAttributes = (userMedical, assessmentMedical) => {
  return {
    conconcussion_ever_diagnosed: userMedical.concussion_ever_diagnosed,
    id: assessmentMedical.id || null,
    concussion_history: userMedical.concussion_history,
    medical_history: userMedical.medical_history,
    medical_conditions_attributes: mergeConditions(
      userMedical.medical_conditions, 
      assessmentMedical.medical_conditions || []
    ),
    medications_attributes: mergeConditions(
      userMedical.medications, 
      assessmentMedical.medications || []
    )
  };
};

class AssessmentMedicalHistoryForm extends Component {
  constructor(props) {
    super(props);

    const { medicalInfo = {}, user = {} } = props;
    const userMedical = medicalInfo[user.id] || {};

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      medical: userMedical,
      activity: false
    };
  }

  componentDidMount() {
    const { medical = {} } = this.state;
    const { user = {} } = this.props;
    if (!medical.id) {
      this.getMedicalHistory(user.id);
    }
  }

  render() {
    return (
      <Card title={Strings.medicalInfoTitle}>
        <div className="card card-form">
          <Activity active={this.state.activity} static>
            <MedicalInfo
              show={false}
              user={this.props.user || {}}
              medical={this.state.medical}
              onSubmit={this.onSubmit}
              onError={this.props.onError}
              submitButtonText={SharedStrings.saveAndContinueButtonText}
            />
          </Activity>
        </div>
      </Card>
    );
  }

  getMedicalHistory(userId) {
    this.setState({ activity: true });

    this.props.getMedicalInfo(userId).then(medical => {
      this.setState({
        medical,
        activity: false
      });
    }).catch(() => {
      this.setState({
        activity: false
      });
    });
  }

  onSubmit(info) {
    const { medicalHistory = {}, assessmentId = 0 } = this.props;

    const attributes = {
      medical_info_attributes: {
        injury_assessment_id: assessmentId,
        ...buildMedicalInfoAttributes(info, medicalHistory || {}),
      }
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

AssessmentMedicalHistoryForm.propTypes = {
  medicalHistory: PropTypes.object,
  assessmentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  user: PropTypes.object,
  onSubmit: PropTypes.func
};

const mapStateToProps = (state) => {
  const { medicalInfo = {} } = state;

  return { medicalInfo };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMedicalInfo: (userId) => {
      return dispatch(getMedicalInfoAsync(userId));
    }
  };
};

const ConnectedAssessmentMedicalHistoryForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentMedicalHistoryForm);

export default ConnectedAssessmentMedicalHistoryForm;

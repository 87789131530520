import React from 'react';
import { CheckboxInputGroup } from '../../forms';
import ClinicServiceKeys from './services';
import Strings from './lang';

const CLINIC_SERVICES = [
  ClinicServiceKeys.BaselineTesting, 
  ClinicServiceKeys.ConcussionTreatment,
  ClinicServiceKeys.ReturnToPlay
];

const ClinicServicesInput = ({
  services = [],
  onUpdate = () => {}
}) => {
  return (
    <div>
      <div><small>{Strings.servicesLabel}</small></div>
      {CLINIC_SERVICES.map(service => (
        <CheckboxInputGroup 
          key={service}
          className="form-group checkbox-inline"
          labelText={Strings[`${service}_label`]}
          inputProps={{
            className: 'form-control',
            checked: services.includes(service),
            onChange: (e) => {
              const { checked } = e.target;
              const newServices = [...services];
              const index = newServices.findIndex(s => s === service);

              if (checked && index < 0) {
                newServices.push(service);
              } else if (!checked && index >= 0) {
                newServices.splice(index, 1);
              }

              onUpdate(newServices.sort());
            }
          }}
          inputValid
        />
      ))}
    </div>
  );
};

export default ClinicServicesInput;

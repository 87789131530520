import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCurrentUserLanguage } from '../../../utilities/localization';
import { FormComponent, EmailInputGroup, PasswordInputGroup } from '../../../forms';
import { PlayerPrivacyConsents } from '../../policy';
import Strings from './lang';

class RegistrationAccountForm extends Component {
  constructor(props) {
    super(props);

    const { role } = this.props;

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      role,
      email: '',
      emailValid: false,
      password: '',
      passwordValid: false,
      terms: false
    };
  }

  render() {
    return (
      <FormComponent className="registration-account-form" onSubmit={this.onSubmit}>
        <EmailInputGroup
          className="form-group"
          labelText={Strings.emailLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.emailInputPlaceholder
          }}
          onUpdate={(email, emailValid) => {
            this.setState({ email, emailValid });
          }}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <PasswordInputGroup
          className="form-group"
          showPasswordScore
          showConfirmInput
          labelText={Strings.passwordLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.passwordInputPlaceholder
          }}
          confirmLabelText={Strings.confirmPasswordLabelText}
          confirmInputProps={{
            name: 'confirm-password',
            className: 'form-control',
            placeholder: Strings.confirmPasswordInputPlaceholder
          }}
          onUpdate={(password, passwordValid) => {
            this.setState({ password, passwordValid });
          }}
          messageText={Strings.passwordErrorText}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <PlayerPrivacyConsents
          onConsentChange={terms => {
            this.setState({ terms });
          }}
          inputClassName="form-control"
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.emailValid
      && this.state.passwordValid
      && this.state.terms;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const { 
      email, password, terms, role 
    } = this.state;
    const language = getCurrentUserLanguage();

    const attributes = {
      role,
      email,
      password,
      tos_accepted: terms,
      language,
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

RegistrationAccountForm.propTypes = {
  role: PropTypes.string.isRequired,
  onSubmit: PropTypes.func
};

export default RegistrationAccountForm;

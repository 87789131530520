import Strings from './lang';
import SportStrings from '../../sports/lang';

const SportSelectorOptions = {
  label: Strings.sportSelectorLabel,
  placeholder: Strings.sportSelectorPlaceholder,
  options: [
    { label: SportStrings.football, value: 'football' },
    { label: SportStrings.aussierules, value: 'afl' },
    { label: SportStrings.baseball, value: 'baseball' },
    { label: SportStrings.basketball, value: 'basketball' },
    { label: SportStrings.boxing, value: 'boxing' },
    { label: SportStrings.cheerleading, value: 'cheerleading' },
    { label: SportStrings.hockey, value: 'hockey' },
    { label: SportStrings.lacrosse, value: 'lacrosse' },
    { label: SportStrings.rugby, value: 'rugby' },
    { label: SportStrings.skiing, value: 'skiing' },
    { label: SportStrings.soccer, value: 'soccer' },
    { label: SportStrings.wrestling, value: 'wrestling' }
  ]
};

const ReturnToSportStages = [{
  value: 'absolute_rest',
  label: Strings.rtsStage1Label,
  bgClass: 'bg-red',
  description: Strings.rtsStage1Description,
  document: 'recovery-protocols/rts/1',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'light_activity',
  label: Strings.rtsStage2Label,
  bgClass: 'bg-red',
  description: Strings.rtsStage2Description,
  document: 'recovery-protocols/rts/2'
}, {
  value: 'light_practice',
  label: Strings.rtsStage3Label,
  bgClass: 'bg-yellow',
  description: Strings.rtsStage3Description,
  document: 'recovery-protocols/rts/3',
  readMoreLabel: Strings.viewSportRestrictionLabel,
  selector: SportSelectorOptions
}, {
  value: 'heavy_practice',
  label: Strings.rtsStage4Label,
  bgClass: 'bg-yellow',
  description: Strings.rtsStage4Description,
  document: 'recovery-protocols/rts/4',
  readMoreLabel: Strings.viewSportRestrictionLabel,
  selector: SportSelectorOptions
}, {
  value: 'full_contact_practice',
  label: Strings.rtsStage5Label,
  bgClass: 'bg-yellow',
  description: Strings.rtsStage5Description,
  document: 'recovery-protocols/rts/5'
}, {
  value: 'full_return',
  label: Strings.rtsStage6Label,
  bgClass: 'bg-green',
  description: Strings.rtsStage6Description,
}];

const ReturnToLearnStages = [{
  value: 'absolute_rest',
  label: Strings.rtlStage1Label,
  bgClass: 'bg-red',
  description: Strings.rtlStage1Description,
  document: 'recovery-protocols/rtl/1',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'light_cognitive_activity',
  label: Strings.rtlStage2Label,
  bgClass: 'bg-red',
  description: Strings.rtlStage2Description,
  document: 'recovery-protocols/rtl/2',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'half_days_work',
  label: Strings.rtlStage3Label,
  bgClass: 'bg-red',
  description: Strings.rtlStage3Description,
  document: 'recovery-protocols/rtl/3',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'full_days_work_with_modifictions',
  label: Strings.rtlStage4Label,
  bgClass: 'bg-red',
  description: Strings.rtlStage4Description,
  document: 'recovery-protocols/rtl/4',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'full_days_work_without_modifications',
  label: Strings.rtlStage5Label,
  bgClass: 'bg-green',
  description: Strings.rtlStage5Description,
}];

const ReturnToWorkStages = [{
  value: 'absolute_rest',
  label: Strings.rtwStage1Label,
  bgClass: 'bg-red',
  description: Strings.rtwStage1Description,
  document: 'recovery-protocols/rtw/1',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'light_cognitive_activity',
  label: Strings.rtwStage2Label,
  bgClass: 'bg-red',
  description: Strings.rtwStage2Description,
  document: 'recovery-protocols/rtw/2',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'half_days_work',
  label: Strings.rtwStage3Label,
  bgClass: 'bg-red',
  description: Strings.rtwStage3Description,
  document: 'recovery-protocols/rtw/3',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'full_days_work_with_modifictions',
  label: Strings.rtwStage4Label,
  bgClass: 'bg-red',
  description: Strings.rtwStage4Description,
  document: 'recovery-protocols/rtw/4',
  readMoreLabel: Strings.viewRestrictionsLabel
}, {
  value: 'full_days_work_without_modifications',
  label: Strings.rtwStage5Label,
  bgClass: 'bg-green',
  description: Strings.rtwStage5Description,
}];

const RecoveryProtocols = {
  rtl: {
    label: Strings.rtlProtocolTitle,
    stages: ReturnToLearnStages
  },
  rts: {
    label: Strings.rtsProtocolTitle,
    stages: ReturnToSportStages
  },
  rtw: {
    label: Strings.rtwProtocolTitle,
    stages: ReturnToWorkStages
  }
};

export default RecoveryProtocols;

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import Strings from './lang';

const RemovedItemsTable = ({
  label,
  nameLabel,
  items = [], 
  onUndo = () => {}
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div>
      <label>{label}</label>
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{nameLabel}</th>
            <th className="no-action">{Strings.removedByTableHeading}</th>
            <th className="no-action">{Strings.removedAtTableHeading}</th>
            <th className="no-action">{Strings.clinicTableHeading}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const person = (item.removal_clinician || {}).person || {};
            const clinic = item.removal_clinic || {};
            const name = `${person.first_name || ''} ${person.last_name || ''}`;
            return (
              <tr key={index}>
                <td>
                  {item.name} {item.new && (
                    <button 
                      type="button" 
                      className="edit-button" 
                      onClick={() => onUndo(index)}
                    >
                      {Strings.undoButtonText}
                    </button>
                  )}
                </td>
                <td>{name}</td>
                <td>{dayjs(item.removed_at).format(DISPLAY_DATE_FORMAT)}</td>
                <td>{clinic.name || ''}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

RemovedItemsTable.propTypes = {
  items: PropTypes.array,
  nameLabel: PropTypes.string,
  onUndo: PropTypes.func
};

export default RemovedItemsTable;

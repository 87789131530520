import React, { useState, useCallback, useRef } from 'react';
import Modal from '../modal';
import Diagnosis from './diagnosis';
import { FormComponent } from '../../forms';
import Strings from './lang';

const DiagnosisForm = React.forwardRef(({
  type = 'acute',
  initialDiagnosis = {},
  onSubmit = () => { },
  onClose = () => {}
}, ref) => {
  const [submitted, setSubmitted] = useState(false);
  const [diagnosis, setDiagnosis] = useState(initialDiagnosis);
  const [isValid, setIsValid] = useState(false);

  const onUpdateDiagnosis = useCallback((diagnosis, valid) => {
    setDiagnosis(diagnosis);
    setIsValid(valid);
  }, []);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (isValid) {
      const attributes = {
        diagnosis: {
          ...diagnosis
        }
      };

      onSubmit(attributes);
    }
  }, [diagnosis, isValid, onSubmit]);

  return (
    <FormComponent ref={ref} onSubmit={handleSubmit}>
      <Diagnosis
        type={type}
        diagnosis={diagnosis}
        submitted={submitted}
        onUpdate={onUpdateDiagnosis}
      />
      <div className="form-footer">
        <button
          type="button"
          className="btn btn-default"
          onClick={onClose}
        >
          {Strings.cancelButtonText}
        </button>
        <button
          type="submit"
          className="btn btn-primary"
        >
          {Strings.saveButtonText}
        </button>
      </div>
    </FormComponent>
  );
});

const DiagnosisModal = ({
  type,
  isOpen,
  diagnosis,
  onSubmit,
  onClose
}) => {
  const formRef = useRef();

  const handleSubmit = useCallback((attributes) => {
    onSubmit(attributes);
    onClose();
  }, [onClose, onSubmit]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <h1>{Strings.diagnosisModalHeaderText}</h1>
      </Modal.Header>
      <Modal.Body>
        <DiagnosisForm
          ref={formRef}
          type={type}
          initialDiagnosis={diagnosis}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DiagnosisModal;

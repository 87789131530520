import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { Lockout } from '../components/lockout';
import Activity from '../components/activity';
import Store from '../redux/store';
import { getCurrentUserId, removeAuthData } from '../tokens';
import { getUserAsync } from '../redux/thunks/users';
import { AlertContainer } from '../components/alert-notifications';
import registerFaviconNotificationWatcher from '../utilities/favicon-notification-watcher';

class AppProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      exemptedPaths: ['/register', '/login', '/']
    };
  }

  componentDidMount() {
    registerFaviconNotificationWatcher();
    this.getCurrentUser();
  }

  render() {
    // Allow the current user to load before rendering content:
    return (
      <If condition={this.state.initialized}>
        <Then>
          <Provider store={Store}>
            <Lockout exemptedPaths={this.state.exemptedPaths}>
              <Outlet />
              <AlertContainer />
            </Lockout>
          </Provider>
        </Then>
        <Else>
          <Activity
            active
            static
            titleComponent={(
              <h1 className="display">
                Complete Concussion Management System
              </h1>
            )}
          />
        </Else>
      </If>
    );
  }

  getCurrentUser() {
    const id = getCurrentUserId();
    if (id) {
      Store.dispatch(getUserAsync(id)).then(() => {
        this.setState({ initialized: true });
      }).catch(e => {
        // Force reauthentication on API error:
        if (e.status) {
          removeAuthData();
        }
        this.setState({ initialized: true });
      });
    } else {
      this.setState({ initialized: true });
    }
  }
}

export default AppProvider;

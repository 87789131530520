import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import classnames from 'classnames';
import { FormInput, FormInputMessage } from '../../forms';
import Strings from './lang';

const BloodPressureInputGroup = ({
  systolic,
  diastolic,
  onUpdateSystolic,
  onUpdateDiastolic,
  touched: _propTouched = false
}) => {
  const [systolicTouched, setSystolicTouched] = useState(_propTouched);
  const [diastolicTouched, setDiastolicTouched] = useState(_propTouched);

  const errors = useMemo(() => {
    const e = [];

    if (systolicTouched && (isNaN(systolic) || systolic <= 0)) {
      e.push(Strings.bpSystolicInputErrorMessage);
    }

    if (diastolicTouched && (isNaN(diastolic) || diastolic <= 0)) {
      e.push(Strings.bpDiastolicInputErrorMessage);
    }

    return e;
  }, [diastolic, diastolicTouched, systolic, systolicTouched]);

  const onSystolicBlur = useCallback(() => {
    setSystolicTouched(true);
  }, []);

  const onDiastolicBlur = useCallback(() => {
    setDiastolicTouched(true);
  }, []);

  useEffect(() => {
    setSystolicTouched(_propTouched);
    setDiastolicTouched(_propTouched);
  }, [_propTouched]);

  return (
    <div
      className={classnames('form-group form-input-group ovs-input-group', {
        invalid: errors.length
      })}
    >
      <label>{Strings.bpInputLabel} <span className="required">*</span></label>
      <div className="input-group">
        <FormInput
          type="number"
          inputProps={{
            className: 'form-control',
            step: 1,
            min: 1,
            placeholder: Strings.bpSystolicLabel,
            value: isNaN(systolic) ? '' : systolic,
            onBlur: onSystolicBlur,
            onChange: (e) => {
              onUpdateSystolic(e.target.valueAsNumber);
            }
          }}
        />
        <span className="input-group-addon">/</span>
        <FormInput
          type="number"
          inputProps={{
            className: 'form-control',
            step: 1,
            min: 1,
            placeholder: Strings.bpDiastolicLabel,
            value: isNaN(diastolic) ? '' : diastolic,
            onBlur: onDiastolicBlur,
            onChange: (e) => {
              onUpdateDiastolic(e.target.valueAsNumber);
            }
          }}
        />
        <span className="input-group-addon">mmHg</span>
      </div>
      {errors.map((error, i) => (
        <FormInputMessage
          visible
          key={i}
          className="alert alert-danger"
          text={error}
        />
      ))}
    </div>
  );
};

export default BloodPressureInputGroup;

import { jsonApiCreate, jsonApiUpdate, jsonApiRead } from '../../requests/jsonapi';
import { updateInjury, updateInjuries } from '../actions/injuries';

const createInjuryAsync = (userId, attributes) => {
  const options = { path: `users/${userId}/injuries` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'injury', attributes }, options).then(results => {
      dispatch(updateInjury(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateInjuryAsync = (userId, injuryId, attributes) => {
  const options = { path: `users/${userId}/injuries/${injuryId}` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'injury', attributes }, options).then(results => {
      dispatch(updateInjury(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getInjuryAsync = (userId, injuryId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'injuries', id: injuryId }, options).then(results => {
      dispatch(updateInjury(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getInjuriesAsync = userId => {
  const options = { path: `users/${userId}/injuries` };
  return (dispatch) => {
    return jsonApiRead({ type: 'injuries' }, options).then(results => {
      dispatch(updateInjuries(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getActiveInjuryAsync = (userId) => {
  const options = { path: `users/${userId}/injuries/active` };
  return (dispatch) => {
    return jsonApiRead({ type: 'injuries' }, options).then(results => {
      if (results?.id) {
        dispatch(updateInjury(userId, results));
        return Promise.resolve(results);
      }
      
      return Promise.resolve(null);
    }).catch(error => {
      Promise.reject(error);
    });
  };
};

export {
  getActiveInjuryAsync,
  createInjuryAsync,
  updateInjuryAsync,
  getInjuryAsync,
  getInjuriesAsync
};

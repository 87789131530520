import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { MarketplacePaths } from '../../../paths';
import { Page } from '../../page';
import { getProductsAsync } from '../../../redux/thunks/products';
import Activity from '../../activity';
import ProductsTable from './products-table';
import ProductFilters from './product-filters';
import { filterParams, getPage } from '../utils';
import { ErrorBanner } from '../../errors';
import Strings from '../lang';

const getQuery = (query = {}) => {
  return {
    name: query.name || '',
    page: getPage(query.page)
  };
};

const AdminMarketplaceProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => getQuery(Object.fromEntries(searchParams)), [searchParams]);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const onFilterChange = useCallback((filters) => {
    const newParams = { ...query, page: 1, ...filters };
    const newQuery = filterParams(newParams);

    setSearchParams(newQuery);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [query, setSearchParams]);

  const onPageChange = useCallback(({ selected }) => {
    const newPage = selected + 1;
    if (newPage !== query.page) {
      onFilterChange({ page: newPage });
    }
  }, [onFilterChange, query.page]);

  const getProducts = useCallback((params) => {
    setLoading(true);

    dispatch(getProductsAsync(params)).then(({ products, pagination }) => {
      setProducts(products);
      setPagination(pagination);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    getProducts(query);
  }, [getProducts, query]);

  return (
    <Page>
      <div className="flex-row align_center justify_space-between">
        <h1 className="display">
          {Strings.adminProductsTitle}
        </h1>
        <Link 
          to={MarketplacePaths.products.create.link} 
          className="btn btn-sm btn-secondary"
        >
          {Strings.createProductLabel}
        </Link>
      </div>
      <ProductFilters 
        name={query.name}
        onChange={onFilterChange}
      />
      <ErrorBanner error={error} />
      <Activity active={loading}>
        {products.length ? (
          <ProductsTable products={products} />
        ) : (
          <p>{Strings.noProductsFoundLabel}</p>
        )}
        {pagination.total_pages > 1 && (
          <div className="pagination-center">
            <ReactPaginate
              pageCount={pagination.total_pages}
              forcePage={pagination.page - 1}
              pageRangeDisplayed={4}
              marginPagesDisplayed={2}
              containerClassName="pagination"
              activeClassName="active"
              onPageChange={onPageChange}
            />
          </div>
        )}
      </Activity>
    </Page>
  );
};

export default AdminMarketplaceProducts;

import React, {
  useEffect, 
  useState, 
  useRef, 
  useCallback 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { useNavigate, useParams } from 'react-router-dom';
import Activity from '../activity';
import SoapPanels from './soap-panels';
import SoapPanelControls from './soap-panel-controls';
import { SoapNoteSelector, UserSelector, InjurySelector } from '../../redux/selectors';
import { getArchivedSoapNotesAsync, unarchiveSoapNoteAsync } from '../../redux/thunks/soap-notes';
import { showAlert } from '../alert-notifications';
import Strings from './lang';
import Icon from '../icon';

const showSuccessMessage = () => {
  showAlert('success', {
    dismissable: true,
    autoDismiss: 2000,
    message: Strings.unarchiveSuccessMessage
  });
};

const showErrorMessage = (error) => {
  showAlert('error', {
    dismissable: true,
    autoDismiss: 4000,
    message: Strings.formatString(
      Strings.unarchivedErrorMessage,
      error.message
    )
  });
};

const Header = ({ 
  onOpen, 
  onClose, 
  controlsDisabled = false,
  onBack 
}) => {
  return (
    <div className="archive-list-header">
      <div className="header-title">
        <button type="button" className="btn-back no-print" onClick={onBack}>
          <Icon name="arrow-left" />
        </button>
        <h2>{Strings.archivedSoapNotesListTitle}</h2>
      </div>
      <SoapPanelControls 
        visible={!controlsDisabled}
        onOpen={onOpen}
        onClose={onClose}
      />
    </div>
  );
};

const SoapNotesArchivedList = () => {
  const dispatch = useDispatch();
  const panelRef = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const [activity, setActivity] = useState(false);
  const user = useSelector(state => UserSelector.getUser(state, params || {}));
  const soapNotes = useSelector(state => (
    SoapNoteSelector.getInjuryArchivedSoapNotes(state, params || {})
  ));
  const injury = useSelector(state => InjurySelector.getUserInjury(state, params || {}));
  const hasNotes = soapNotes.length > 0;
  
  const onUnarchive = useCallback((soap) => {
    setActivity(true);

    dispatch(unarchiveSoapNoteAsync(user.id, injury.id, soap.id)).then(() => {
      setActivity(false);
      showSuccessMessage();
    }).catch((error) => {
      setActivity(false);
      showErrorMessage(error);
    });
  }, [injury.id, dispatch, user.id]);

  useEffect(() => {
    setActivity(true);

    dispatch(getArchivedSoapNotesAsync(user.id, injury.id)).then(() => {
      setActivity(false);
    }).catch(() => {
      setActivity(false);
    });
  }, [dispatch, injury.id, user.id]);

  return (
    <div className="soap-notes-archive-list">
      <Header 
        controlsDisabled={!hasNotes}
        onOpen={() => panelRef.current.openAll()}
        onClose={() => panelRef.current.closeAll()}
        onBack={() => navigate(-1)} 
      />
      <Activity active={activity} static={!hasNotes}>
        <If condition={hasNotes}>
          <Then>
            <SoapPanels
              ref={panelRef}
              sticky={60}
              user={user}
              injury={injury}
              soapNotes={soapNotes}
              clinicId={params.clinicId}
              disablePanels={false}
              onUnarchive={onUnarchive}
            />
          </Then>
          <Else>
            <p className="alert alert-info">
              {Strings.archivedSoapNotesEmptyMessage}
            </p>
          </Else>
        </If>
      </Activity>
    </div>
  );
};

export default SoapNotesArchivedList;

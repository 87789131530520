import React, { useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import Popover from '../popover';
import Icon from '../icon';
import DashboardPreview from '../dashboard/dashboard-preview';
import { DashboardPaths } from '../../paths';
import { useBreakpoint } from '../../hooks';

const breakpoint = 550;
// eslint-disable-next-line no-magic-numbers
const SMALL_OFFSET = [-80, 8];

const DashboardDropdown = () => {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const isSmall = useBreakpoint(breakpoint);

  if (location?.pathname === DashboardPaths.index.link) return null;

  return (
    <Popover
      isOpen={open}
      placement="bottom-start"
      offset={isSmall ? SMALL_OFFSET : undefined}
      content={(
        <DashboardPreview />
      )}
      onClose={() => setOpen(false)}
    >
      <button 
        type="button" 
        className={classnames('dashboard-dropdown-toggle', { open })}
        onClick={() => setOpen(prev => !prev)}
      >
        <Icon prefix="fas" name="grid-round" />
      </button>
    </Popover>
  );
};

export default DashboardDropdown;

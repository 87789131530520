import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { jsonApiRead } from '../../../requests/jsonapi';
import Strings from './lang';
import { AuthenticationPaths, RegistrationPaths } from '../../../paths';
import { Page } from '../../page';
import { useMount } from '../../../hooks';

const RegistrationConfirm = () => {
  const params = useParams();
  const [confirmed, setConfirmed] = useState(false);
  const [activity, setActivity] = useState(true);
  const [error, setError] = useState(null);

  const requestConfirmation = useCallback((token) => {
    setConfirmed(false);
    setActivity(true);
    setError(null);

    const resource = {
      type: 'users',
      attributes: {
        confirmation_token: token
      }
    };

    jsonApiRead(resource, { path: 'users/confirmation' }).then(() => {
      setConfirmed(true);
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, []);

  useMount(() => {
    requestConfirmation(params?.token);
  });

  return (
    <Page className="registration-confirm" hideNavigationContent hideBannerIfClinicDeactivated>
      <div className="row">
        <div className="col-md-12">
          <h1 className="display">{Strings.title}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <If condition={activity}>
            <Then>
              <p className="alert alert-info">{Strings.confirmationPendingText}</p>
            </Then>
            <Else>
              <If condition={confirmed}>
                <Then>
                  <div>
                    <p className="alert alert-success">{Strings.confirmationSuccessText}</p>
                    <ul className="list-unstyled">
                      <li>
                        <Link to={AuthenticationPaths.index.link}>
                          {Strings.authenticationLinkText}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Then>
                <Else>
                  <div>
                    <p className="alert alert-danger">{Strings.confirmationFailedText} &mdash; {error}</p>
                    <ul className="list-unstyled">
                      <li>
                        <Link to={AuthenticationPaths.index.link}>
                          {Strings.authenticationLinkText}
                        </Link>
                      </li>
                      <li>
                        <Link to={RegistrationPaths.confirm.index.link}>
                          {Strings.resendLinkText}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Else>
              </If>
            </Else>
          </If>
        </div>
      </div>
    </Page>
  );
};

export default RegistrationConfirm;

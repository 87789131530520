import React from 'react';
import { Authorization } from '../authorization';
import {
  AdminAndClinicRoleDescriptions,
  IDTRoleDescriptions,
  NoResourceTypeClinicUserRoles,
  RoleDescriptions
} from '../../utilities/user-roles';
import { ClinicUsers } from '../../components/clinic-users';
import { UserProfile } from '../../components/user-profile';
import PageNotFound from '../not-found-page';

const ClinicUsersRoutes = [
  {
    index: true,
    element: (
      <Authorization
        allowRoles={[
          RoleDescriptions.SuperAdmin,
          RoleDescriptions.SalesAdmin
        ]}
        allowRolesMatchingResourceId={[
          RoleDescriptions.ClinicOwner,
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicStaff,
          RoleDescriptions.BaselineTester,
          RoleDescriptions.ClinicFrontDesk,
          RoleDescriptions.IDTClinician,
          RoleDescriptions.IDTFrontDesk
        ]}
        resourceIdParam="clinicId"
      >
        <ClinicUsers />
      </Authorization>
    )
  },
  {
    path: ':userId',
    element: (
      <Authorization
        allowUntrained
        allowRoles={[
          ...AdminAndClinicRoleDescriptions,
          ...IDTRoleDescriptions,
          ...NoResourceTypeClinicUserRoles
        ]}
      >
        <UserProfile />
      </Authorization>
    )
  },
  {
    path: '*',
    element: <PageNotFound />
  }
];

export default ClinicUsersRoutes;

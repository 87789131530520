import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Drawer from '../../drawer';
import Activity from '../../activity';
import LineItemForm from './line-item-form';
import ErrorBanner from '../../errors/error-banner';
import { createInvoiceLineItemAsync, updateInvoiceLineItemAsync } from '../../../redux/thunks/clinic-invoices';
import Strings from '../lang';
import Icon from '../../icon';

const LineItemDrawer = ({
  lineItem = {},
  currency,
  clinicId,
  invoiceId,
  isOpen = false,
  createLineItem,
  updateLineItem,
  onClose
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const updating = !!lineItem.id;

  const handleSubmit = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    const action = lineItem.id 
      ? updateLineItem(clinicId, invoiceId, lineItem.id, attributes)
      : createLineItem(clinicId, invoiceId, attributes);

    action.then(() => {
      requestAnimationFrame(onClose);
      setActivity(false);
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [
    clinicId, 
    createLineItem, 
    invoiceId, 
    lineItem.id, 
    onClose, 
    updateLineItem
  ]);

  return (
    <Drawer
      blur
      isOpen={isOpen}
      position="right"
      overlayDisabled={activity}
      onClose={onClose}
    >
      <div className="line-item-drawer">
        <div className="drawer-close">
          <button 
            type="button" 
            className="close" 
            disabled={activity}
            onClick={onClose}
          >
            <Icon name="xmark" />
          </button>
        </div>
        <div className="line-item-drawer-content">
          <h1 className="display">
            {updating ? Strings.editLineItemTitle : Strings.addLineItemTitle}
          </h1>
          <ErrorBanner error={error} />
          <Activity active={activity}>
            <LineItemForm 
              currency={currency}
              lineItem={lineItem}
              onSubmit={handleSubmit}
            />
          </Activity>
        </div>
      </div>
    </Drawer>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createLineItem: (clinicId, invoiceId, attributes) => {
      return dispatch(createInvoiceLineItemAsync(clinicId, invoiceId, attributes));
    },
    updateLineItem: (clinicId, invoiceId, lineItemId, attributes) => {
      return dispatch(updateInvoiceLineItemAsync(clinicId, invoiceId, lineItemId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(LineItemDrawer);

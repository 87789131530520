import { jsonApiRead } from '../../requests/jsonapi';
import { updateClinicPatients } from '../actions/clinic-patients';

const getClinicPatientsAsync = (clinicId) => {
  return (dispatch) => {
    return jsonApiRead({ type: 'clinics', id: clinicId }, { path: `clinics/${clinicId}/users` }).then(users => {
      dispatch(updateClinicPatients(clinicId, users));
      return Promise.resolve({ clinicId, users });
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  getClinicPatientsAsync
};

import React from 'react';
import {
  RouterProvider,
  createBrowserRouter,
  ScrollRestoration
} from 'react-router-dom';
import AppProvider from './provider';
import { ErrorBoundary } from '../components/errors';
import routes from '../routes';

import 'bootstrap/dist/css/bootstrap.min.css';
// cropperjs is included with react-cropper
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../css/index.css';

const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <ErrorBoundary>
        <ScrollRestoration />
        <AppProvider />
      </ErrorBoundary>
    ),
    children: routes
  }
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
};

export default App;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link as ScrollLink, Element } from 'react-scroll';
import { If, Then } from 'react-if';
import SymptomsInsights from './symptoms';
import Activity from '../activity';
import InjuryOverviewStats from './overview';
import { ErrorBanner } from '../errors';
import { jsonApiRead } from '../../requests/jsonapi';
import { InjurySelector } from '../../redux/selectors';
import { getInjuryAsync } from '../../redux/thunks/injuries';
import Strings from './lang';

const getInjurySymptoms = (userId, injuryId) => {
  return jsonApiRead(
    { type: 'symptoms' },
    { path: `users/${userId}/injuries/${injuryId}/symptoms` }
  );
};

const InjuryInsights = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const injury = useSelector(state => InjurySelector.getUserInjury(state, params));
  const [loading, setLoading] = useState(true);
  const [symptoms, setSymptoms] = useState({});
  const [injuryError, setInjuryError] = useState(null);
  const [symptomsError, setSymptomsError] = useState(null);
  const latestSymptoms = symptoms.injury?.symptoms[(symptoms.injury?.symptoms?.length ?? 0) - 1];

  const getInjuryAndSymptomData = useCallback((userId, injuryId) => {
    setLoading(true);
    setInjuryError(null);
    setSymptomsError(null);

    Promise.allSettled([
      getInjurySymptoms(userId, injuryId),
      dispatch(getInjuryAsync(userId, injuryId))
    ]).then(([symptomRequest, injuryRequest]) => {
      if (symptomRequest.status === 'rejected') {
        setSymptomsError(symptomRequest.reason?.message);
      } else {
        setSymptoms(symptomRequest.value);
      }

      if (injuryRequest.status === 'rejected') {
        setInjuryError(injuryRequest.reason?.message);
      }

      setLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (params.userId && params.injuryId) {
      getInjuryAndSymptomData(params.userId, params.injuryId);
    }
  }, [getInjuryAndSymptomData, params.injuryId, params.userId]);

  return (
    <div className="injury-insights">
      <div className="injury-insights-header">
        <h1>{Strings.title}</h1>
        <div className="flex-row align_center flex-1">
          <ScrollLink
            spy
            smooth
            className="scroll-spy-item"
            to="overview"
            activeClass="active"
            offset={-150}
            duration={500}
          >
            {Strings.overviewTabLabel}
          </ScrollLink>
          <ScrollLink
            spy
            smooth
            className="scroll-spy-item"
            to="symptoms"
            offset={-150}
            duration={500}
          >
            {Strings.symptomsTabLabel}
          </ScrollLink>
        </div>
      </div>
      <Activity active={loading} static>
        <Element id="overview">
          <ErrorBanner error={injuryError} />
          <If condition={!injuryError}>
            <Then>
              <InjuryOverviewStats
                injury={injury}
                latestSymptoms={latestSymptoms?.symptoms}
              />
            </Then>
          </If>
        </Element>
        <Element id="symptoms">
          <h2>Symptoms Insights</h2>
          <ErrorBanner error={symptomsError} />
          <If condition={!symptomsError}>
            <Then>
              <SymptomsInsights symptoms={symptoms} />
            </Then>
          </If>
        </Element>
      </Activity>
    </div>
  );
};

export default InjuryInsights;

import React, { useMemo } from 'react';
import { sortAscending } from '../../../utilities/sort';
import Panel, { usePanel } from '../../panel';
import Icon from '../../icon';
import { getPsychOutcomeDescription, DanaTests } from '../helpers';
import Strings from '../lang';

const standardPHQAnswers = [
  Strings.psychAnswerNotAtAll,
  Strings.psychAnswerSeveralDays,
  Strings.psychAnswerMoreThanHalfDays,
  Strings.psychAnswerNearlyEveryDay
];

const PHQDifficultyAnswers = [
  Strings.psychAnswerNotDifficult,
  Strings.psychAnswerSomewhatDifficult,
  Strings.psychAnswerVeryDifficult,
  Strings.psychAnswerExtremelyDifficult
];

// Same as phq9 except does not contain suicide question (Question 9 in PHQ9)
const PHQ8PsychSurvey = [
  { question: Strings.phqQuestion1, answers: standardPHQAnswers },
  { question: Strings.phqQuestion2, answers: standardPHQAnswers },
  { question: Strings.phqQuestion3, answers: standardPHQAnswers },
  { question: Strings.phqQuestion4, answers: standardPHQAnswers },
  { question: Strings.phqQuestion5, answers: standardPHQAnswers },
  { question: Strings.phqQuestion6, answers: standardPHQAnswers },
  { question: Strings.phqQuestion7, answers: standardPHQAnswers },
  { question: Strings.phqQuestion8, answers: standardPHQAnswers },
  // This is an overall question, it's score is not add to total (scored: false)
  { question: Strings.phqQuestion10, answers: PHQDifficultyAnswers, scored: false }
];

const PHQ9PsychSurvey = [
  { question: Strings.phqQuestion1, answers: standardPHQAnswers },
  { question: Strings.phqQuestion2, answers: standardPHQAnswers },
  { question: Strings.phqQuestion3, answers: standardPHQAnswers },
  { question: Strings.phqQuestion4, answers: standardPHQAnswers },
  { question: Strings.phqQuestion5, answers: standardPHQAnswers },
  { question: Strings.phqQuestion6, answers: standardPHQAnswers },
  { question: Strings.phqQuestion7, answers: standardPHQAnswers },
  { question: Strings.phqQuestion8, answers: standardPHQAnswers },
  { question: Strings.phqQuestion9, answers: standardPHQAnswers },
  // This is an overall question, it's score is not add to total (scored: false)
  { question: Strings.phqQuestion10, answers: PHQDifficultyAnswers, scored: false }
];

const ISIScaleAnswers = [
  Strings.psychAnswerNone,
  Strings.psychAnswerMild,
  Strings.psychAnswerModerate,
  Strings.psychAnswerSevere,
  Strings.psychAnswerVerySevere
];

const ISIScale2Answers = [
  Strings.psychAnswerNotAtAll,
  Strings.psychAnswerALittle,
  Strings.psychAnswerSomewhat,
  Strings.psychAnswerMuch,
  Strings.psychAnswerVeryMuch
];

const ISISatisfactionAnswers = [
  Strings.psychAnswerVerySatisfied,
  Strings.psychAnswerSatisfied,
  Strings.psychAnswerModeratelySatisfied,
  Strings.psychAnswerDissatisfied,
  Strings.psychAnswerVeryDissatisfied
];

const ISIPsychSurvey = [
  { question: Strings.isiQuestion1, answers: ISIScaleAnswers },
  { question: Strings.isiQuestion2, answers: ISIScaleAnswers },
  { question: Strings.isiQuestion3, answers: ISIScaleAnswers },
  { question: Strings.isiQuestion4, answers: ISISatisfactionAnswers },
  { question: Strings.isiQuestion5, answers: ISIScale2Answers },
  { question: Strings.isiQuestion6, answers: ISIScale2Answers },
  { question: Strings.isiQuestion7, answers: ISIScale2Answers }
];

const GAD7AnswerScale = standardPHQAnswers;
const GAD7SummaryAnswerScale = PHQDifficultyAnswers;

const GAD7PsychSurvey = [
  { question: Strings.gad7Question1, answers: GAD7AnswerScale },
  { question: Strings.gad7Question2, answers: GAD7AnswerScale },
  { question: Strings.gad7Question3, answers: GAD7AnswerScale },
  { question: Strings.gad7Question4, answers: GAD7AnswerScale },
  { question: Strings.gad7Question5, answers: GAD7AnswerScale },
  { question: Strings.gad7Question6, answers: GAD7AnswerScale },
  { question: Strings.gad7Question7, answers: GAD7AnswerScale },
  // This is an overall question, it's score is not add to total (scored: false)
  { question: Strings.gad7QuestionSummary, answers: GAD7SummaryAnswerScale, scored: false }
];

const Surveys = {
  [DanaTests.InsomniaSeverityIndex]: ISIPsychSurvey,
  [DanaTests.PatientHealthQuestionnaire8]: PHQ8PsychSurvey,
  [DanaTests.PatientHealthQuestionnaire9]: PHQ9PsychSurvey,
  [DanaTests.GeneralizedAnxietyDisorder7]: GAD7PsychSurvey
};

const PsychPanelsHeading = () => {
  return (
    <div className="section-header">
      <div className="test-stat test-type" />
      <div className="test-stat">
        {Strings.surveyScoreLabel}
      </div>
      <div className="test-stat description">
        {Strings.descriptionLabel}
      </div>
    </div>
  );
};

const TestPanelHeader = ({ test }) => {
  const { collapsed } = usePanel();

  return (
    <div className="dana-test-panel-header">
      <div className="test-stat test-type">
        <Icon name={`chevron-${collapsed ? 'right' : 'down'}`} />
        <div className="psych-label-img">
          {test.test}
        </div>
        <div>{test.test}</div>
      </div>
      <div className="test-stat survey_score">
        {test.survey_score}
      </div>
      <div className="test-stat description">
        {getPsychOutcomeDescription(test.test, test.survey_score)}
      </div>
    </div>
  );
};

const PsychQuestion = ({
  type,
  questionNumber,
  answerIndex
}) => {
  const surveys = Surveys[type] || [];
  const { question, answers = [], scored = true } = surveys[questionNumber - 1] || {};

  return (
    <div className="psych-survey-response">
      <div>
        <strong><i>{questionNumber}. {question}</i></strong>
      </div>
      <p>
        <span>{answers[answerIndex]}</span>
        {scored && (
          <span className="answer-score">&nbsp;&nbsp;({answerIndex})</span>
        )}
      </p>
    </div>
  );
};

const TestDetails = ({ test }) => {
  const trials = useMemo(() => test.trials?.sort?.(sortAscending.bind(null, 'trial')) || [], [test]);

  return (
    <div className="psych-survey-responses">
      {trials.map(({ trial, response }, index) => (
        <PsychQuestion
          key={index}
          type={test.test}
          questionNumber={trial}
          answerIndex={response}
        />
      ))}
    </div>
  );
};

const PsychTestPanel = ({ test }) => {
  return (
    <Panel>
      <Panel.Heading>
        <TestPanelHeader test={test} />
      </Panel.Heading>
      <Panel.Content>
        <TestDetails test={test} />
      </Panel.Content>
    </Panel>
  );
};

const PsychTestDetails = ({ tests = [] }) => {
  if (!tests.length) return null;

  return (
    <div className="dana-details-section">
      <PsychPanelsHeading />
      <Panel.Group
        collapsible
        className="dana-panels"
      >
        {tests.map(test => (
          <PsychTestPanel
            key={test.test_order}
            test={test}
          />
        ))}
      </Panel.Group>
    </div>
  );
};

export default PsychTestDetails;

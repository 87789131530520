import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserSelector } from '../../redux/selectors';
import { userHasRoleSuperiorityOverUser, userHasRoleRequiringTraining } from '../../utilities/user-roles';
import checkUserTrainingExpiry from '../../utilities/user-training-expiry';

export const useUserHierarchyControl = (
  user, 
  { 
    resourceType,
    resourceId,
    allowUntrained = false,
    exceptWhen,
  }
) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  return useMemo(() => {
    const hasUsers = currentUser.id !== undefined && user.id !== undefined;
    const hasSuperiority = userHasRoleSuperiorityOverUser(
      currentUser,
      user,
      resourceType,
      resourceId
    );
    const hasTrainingRole = userHasRoleRequiringTraining(currentUser);
    const isTrained = checkUserTrainingExpiry(currentUser) || !hasTrainingRole || allowUntrained;
    return hasUsers && hasSuperiority && isTrained && (exceptWhen !== true);
  }, [allowUntrained, currentUser, exceptWhen, resourceId, resourceType, user]);
};

const UserHierarchyControl = ({
  user,
  resourceType,
  resourceId,
  allowUntrained = false,
  exceptWhen,
  children
}) => {
  const isAllowed = useUserHierarchyControl(user, {
    resourceType,
    resourceId,
    allowUntrained,
    exceptWhen,
  });

  return isAllowed ? children : null;
};

export default UserHierarchyControl;

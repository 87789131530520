import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useRoutes
} from 'react-router-dom';
import { BaselineStepsContext } from './context';
import BaselineSidebar from './sidebar';
import { BaselineSelector, UserSelector } from '../../../redux/selectors';
import { getBaselineProfile } from '../baseline-utilities/baseline-profile';
import StepRoutes, { StepRoutesConfig } from './steps-routes';
import Icon from '../../icon';
import Strings from './lang';
import Drawer from '../../drawer';
import { usePrevious } from '../../../hooks';
import { DashboardPaths, UserPaths } from '../../../paths';
import StepNotFound from './step-not-found';

const stepPath = (userId, baselineId, step) => {
  return generatePath(
    `${UserPaths.baselines.steps.index.link}/${step}`,
    { userId, baselineId }
  );
};

const getCurrentStep = (path, routes = []) => {
  const index = routes.findIndex(route => path.endsWith(`/${route.path}`));
  const step = routes[index];

  return {
    step: index >= 0 ? step : null,
    index
  };
};

const useStepRoutes = (profile) => {
  const location = useLocation();
  const routes = useMemo(() => (
    StepRoutes.filter(route => profile.includes(route.key))
  ), [profile]);
  const element = useRoutes([
    ...routes,
    { path: '*', element: <StepNotFound /> }
  ]);
  const { step, index } = useMemo(() => {
    return getCurrentStep(location.pathname, routes);
  }, [location.pathname, routes]);

  return {
    element,
    step,
    index
  };
};

const BaselineStepRoutes = () => {
  const params = useParams();
  const prevParams = usePrevious(params);
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = useSelector(state => UserSelector.getUser(state, params));
  const baseline = useSelector(state => BaselineSelector.getBaseline(state, params));
  const profile = useMemo(() => (
    getBaselineProfile(baseline?.test_version)
  ), [baseline?.test_version]);
  const { element, step, index } = useStepRoutes(profile);

  const onComplete = useCallback(() => {
    navigate('complete');
  }, [navigate]);

  const next = useCallback((baseline) => {
    if (!step) return;

    const nextStep = profile[index + 1];
    const nextRoute = StepRoutesConfig[nextStep];

    if (nextRoute) {
      setTimeout(() => {
        navigate(
          stepPath(user.id, baseline?.id ?? params.baselineId, nextRoute.path)
        );
      });
    } else {
      onComplete();
    }
  }, [index, navigate, onComplete, params.baselineId, profile, step, user?.id]);

  const context = useMemo(() => ({
    profile,
    step,
    index,
    next
  }), [index, next, profile, step]);

  useEffect(() => {
    if (params.baselineId === 'new' && !isNaN(prevParams.baselineId)) {
      navigate(
        stepPath(params.userId, prevParams.baselineId, step.path),
        { replace: true }
      );
    }
  }, [
    navigate,
    params.baselineId,
    params.userId,
    prevParams.baselineId,
    step?.path
  ]);

  return (
    <BaselineStepsContext.Provider value={context}>
      <div className="baseline-step-routes">
        <Link to={DashboardPaths.index.link} className="edit-button back-link">
          <Icon name="arrow-left" />&nbsp;&nbsp;{Strings.returnToDashboardLinkText}
        </Link>
        <div className="baseline-title">
          <button
            type="button"
            className="baseline-sidebar-toggle"
            onClick={() => setSidebarOpen(true)}
          >
            <Icon name="list-check" />
          </button>
          <h1 className="display">{Strings.baselineTestPageHeading}</h1>
        </div>
        <div className="baseline-step-layout">
          <div className="baseline-sidebar-section">
            <BaselineSidebar
              user={user}
              profile={profile}
              baseline={baseline}
              currentStep={step}
            />
          </div>
          <div className="baseline-step-content">
            {element}
          </div>
        </div>
      </div>
      <Drawer
        isOpen={sidebarOpen}
        position="left"
        className="baseline-sidebar-drawer"
        onClose={() => setSidebarOpen(false)}
      >
        <BaselineSidebar
          user={user}
          profile={profile}
          baseline={baseline}
          currentStep={step}
          onSelect={() => setSidebarOpen(false)}
        />
        <button
          type="button"
          className="close-button"
          onClick={() => setSidebarOpen(false)}
        >
          <Icon name="xmark" />
        </button>
      </Drawer>
    </BaselineStepsContext.Provider>
  );
};

export default BaselineStepRoutes;

import React from 'react';
import { OrderedSymptomKeys, SymptomStrings } from '../../../symptom-data';
import { sortAscending, sortDescending } from '../../../utilities/sort';
import Strings from '../lang';
import Icon from '../../icon';

const symptomColumnRenderer = (key) => {
  return SymptomStrings[key];
};

const PreviousDiffRow = ({ diff }) => {
  const diffValue = Math.abs(diff);

  if (diff > 0) {
    return (
      <span>
        <Icon prefix="fas" name="caret-up" className="error" />&nbsp;{diffValue}
      </span>
    );
  }

  if (diff < 0) {
    return (
      <span>
        <Icon prefix="fas" name="caret-down" className="success" />&nbsp;{diffValue}
      </span>
    );
  }

  return (
    <span>--</span>
  );
};

const TopSymptomsTableColumns = () => ([
  {
    key: 'symptom',
    label: Strings.symptomColumnTitle,
    render: symptomColumnRenderer
  },
  {
    key: 'level',
    label: Strings.severityColumnTitle
  },
  {
    key: 'diff',
    label: (
      <abbr title={Strings.diffColumnDescription}>
        {Strings.diffAbbrColumnTitle}
      </abbr>
    ),
    render: (diff) => <PreviousDiffRow diff={diff} />
  }
]);

const getLatestDiffToPrevious = (objects, key) => {
  if (objects.length <= 1) return 0;

  const last = objects[objects.length - 1]?.symptoms?.[key] ?? 0;
  const secondLast = objects[objects.length - 2]?.symptoms?.[key] ?? 0;

  if (last === secondLast) return 0;

  return last - secondLast;
};

export const getTopSymptomsTableData = (symptoms, maxVisible) => {
  const data = Object.values(
    OrderedSymptomKeys.reduce((acc, key) => {
      const level = symptoms[symptoms.length - 1]?.symptoms?.[key] ?? 0;

      return level > 0 ? {
        ...acc,
        [key]: {
          symptom: key,
          level,
          diff: getLatestDiffToPrevious(symptoms, key)
        }
      } : acc;
    }, {})
  ).sort((a, b) => {
    if (a.level === b.level) return a.trend < b.trend ? 1 : -1;
    return a.level < b.level ? 1 : -1;
  });

  return {
    columns: TopSymptomsTableColumns(),
    data: maxVisible ? data.slice(0, maxVisible) : data
  };
};

const MostImprovedTableColumns = () => ([
  {
    key: 'symptom',
    label: Strings.symptomColumnTitle,
    render: symptomColumnRenderer
  },
  {
    key: 'diff',
    label: Strings.diffColumnTitle,
    render: (diff) => <PreviousDiffRow diff={diff * -1} />
  }
]);

export const getMostImprovedTableData = (symptoms, maxVisible) => {
  const initial = symptoms.find(obj => obj.type === 'InjuryAssessment');
  const latest = symptoms[symptoms.length - 1];
  let data;

  if (!initial || !latest || (initial.type === latest.type && initial.id === latest.id)) {
    data = [];
  } else {
    data = OrderedSymptomKeys.reduce((acc, key) => {
      const diff = parseInt(initial.symptoms[key], 10) - parseInt(latest.symptoms[key], 10);
      return diff > 0 ? [...acc, { symptom: key, diff }] : acc;
    }, [])
      .sort(sortDescending.bind(null, 'diff'));
  }

  return {
    columns: MostImprovedTableColumns(),
    data: maxVisible ? data.slice(0, maxVisible) : data
  };
};

const MostIncreasedTableColumns = () => ([
  {
    key: 'symptom',
    label: Strings.symptomColumnTitle,
    render: symptomColumnRenderer
  },
  {
    key: 'diff',
    label: Strings.diffColumnTitle,
    render: (diff) => <PreviousDiffRow diff={diff * -1} />
  }
]);

export const getMostIncreasedTableData = (symptoms, maxVisible) => {
  const initial = symptoms.find(obj => obj.type === 'InjuryAssessment');
  const latest = symptoms[symptoms.length - 1];
  let data;

  if (!initial || !latest || (initial.type === latest.type && initial.id === latest.id)) {
    data = [];
  } else {
    data = OrderedSymptomKeys.reduce((acc, key) => {
      const diff = parseInt(initial.symptoms[key], 10) - parseInt(latest.symptoms[key], 10);
      return diff < 0 ? [...acc, { symptom: key, diff }] : acc;
    }, [])
      .sort(sortAscending.bind(null, 'diff'));
  }

  return {
    columns: MostIncreasedTableColumns(),
    data: maxVisible ? data.slice(0, maxVisible) : data
  };
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import replaceLinkParams from '../../utilities/replace-link-params';
import { ClinicPaths, DashboardPaths } from '../../paths';
import Strings from './lang';
import Icon from '../icon';

const PaymentSuccess = ({ clinicId }) => {
  const navigate = useNavigate();

  return (
    <div className="payment-status-view">
      <div className="payment-status-icon">
        <Icon name="circle-check" className="success" />
      </div>
      <h2>{Strings.paymentSuccessTitle}</h2>
      <p 
        className="text-muted text-left"
        dangerouslySetInnerHTML={{
          __html: Strings.paymentSuccessEmailMessage
        }}
      />
      <p>{Strings.paymentSuccessFooterMessage}</p>
      <div className="action-group">
        <button 
          type="button" 
          className="btn btn-primary"
          onClick={() => navigate(DashboardPaths.index.link, { replace: true })}
        >
          {Strings.dashboardLinkText}
        </button>
        <button 
          type="button" 
          className="btn btn-default"
          onClick={() => {
            navigate(replaceLinkParams(ClinicPaths.invoices.index.link, {
              clinicId
            }), { replace: true });
          }}
        >
          {Strings.invoicesLinkText}
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;

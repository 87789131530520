import React, { forwardRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withBaselineStep from './with-baseline-step';
import Card from '../../card';
import Activity from '../../activity';
import { MedicalInfo } from '../../medical-info';
import { ErrorBanner } from '../../errors';
import { getMedicalInfoAsync } from '../../../redux/thunks/medical-info';
import { createBaselineTestAsync } from '../../../redux/thunks/baseline-tests';
import { getCurrentClinicId } from '../../../tokens';
import { useMount } from '../../../hooks';
import { BASELINE_VERSION } from './constants';
import Strings from './lang';

const BaselineMedicalStep = forwardRef((
  {
    user,
    baseline,
    next
  },
  ref
) => {
  const dispatch = useDispatch();
  const medical = useSelector(state => state.medicalInfo[user.id]);
  const [activity, setActivity] = useState(!medical);
  const [error, setError] = useState(null);

  const startBaseline = useCallback(() => {
    if (baseline?.id) return;

    setActivity(true);
    setError(null);

    const attributes = {
      user_id: user.id,
      active: true,
      complete: false,
      clinic_id: getCurrentClinicId(),
      test_version: BASELINE_VERSION
    };

    dispatch(createBaselineTestAsync(user.id, attributes)).then((baseline) => {
      setActivity(false);
      next(baseline);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [baseline?.id, dispatch, next, user.id]);

  const onSubmit = useCallback(() => {
    if (baseline?.id) {
      next();
    } else {
      startBaseline();
    }
  }, [baseline, next, startBaseline]);

  const getMedical = useCallback(() => {
    setActivity(true);
    setError(null);

    dispatch(getMedicalInfoAsync(user.id)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, user.id]);

  useMount(() => {
    if (!medical?.id) {
      getMedical();
    }
  });

  return (
    <Card ref={ref} title={Strings.confirmMedicalTitle}>
      <ErrorBanner error={error} />
      <Activity active={activity} static={!medical?.id}>
        <MedicalInfo
          formClassName="card card-form"
          show={false}
          medical={medical}
          user={user}
          onSubmit={onSubmit}
          submitButtonText={
            baseline?.id
              ? Strings.confirmAndContinueText
              : Strings.submitMedicalButtonText
          }
        />
      </Activity>
    </Card>
  );
});

export default withBaselineStep(BaselineMedicalStep);

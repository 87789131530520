import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { getBlackhawksTestAsync, createBlackhawksTestAsync, updateBlackhawksTestAsync } from '../../redux/thunks/blackhawks';
import BlackhawksTestForm from './blackhawks-test-form';
import BlackhawksStartForm from './blackhawks-start-form';
import { TestHeader } from '../test-header';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import Strings from './lang';

const getTestType = (test) => {
  if (test.full_test === true) {
    return 'full';
  } 

  if (test.full_test === false) {
    return 'modified';
  }

  return null;
};

const blackhawksSelector = (userId, testId) => state => {
  const userTest = state.blackhawks[userId] || {};
  return userTest[testId] || {};
};

const BlackhawksTest = ({
  user = {}, 
  injury = {},
  blackhawksId = null,
  soapId = null,
  currentUser,
  currentClinic,
  onSave,
  className
}) => {
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const test = useSelector(blackhawksSelector(user?.id, blackhawksId));

  const [{ testType, bikeType, maxTension }, setTestAttributes] = useState(() => ({
    testType: getTestType(test),
    bikeType: test?.bike_type,
    maxTension: test?.test_data?.max_tension_levels
  }));

  const updateBlackhawksTest = useCallback((userId, injuryId, blackhawksId, attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateBlackhawksTestAsync(userId, injuryId, blackhawksId, attributes)).then(test => {
      setActivity(false);

      if (onSave) {
        onSave('blackhawks', test);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, onSave]);

  const createBlackhawksTest = useCallback((userId, injuryId, attributes) => {
    const attrs = { ...attributes };

    setActivity(true);
    setError(null);
    
    if (soapId > 0) {
      attrs.soap_note_id = soapId;
    } else {
      attrs.injury_id = injury.id;
    }

    dispatch(createBlackhawksTestAsync(userId, injuryId, attrs)).then(test => {
      setActivity(false);

      if (onSave) {
        onSave('blackhawks', test);
      }
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch, injury.id, onSave, soapId]);

  const saveBlackhawksTest = useCallback((attributes) => {
    if (blackhawksId) {
      updateBlackhawksTest(user?.id, injury?.id, blackhawksId, attributes);
    } else {
      createBlackhawksTest(user?.id, injury?.id, attributes);
    }
  }, [blackhawksId, createBlackhawksTest, injury?.id, updateBlackhawksTest, user?.id]);

  const getBlackhawksTest = useCallback((userId, injuryId, blackhawksId) => {
    setLoading(true);
    setActivity(true);
    setError(null);

    dispatch(getBlackhawksTestAsync(userId, injuryId, blackhawksId)).then(test => {
      setTestAttributes(prev => ({
        ...prev,
        bikeType: test.bike_type,
        testType: getTestType(test),
        maxTension: test.test_data?.max_tension_levels
      }));
      setActivity(false);
      setLoading(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!test?.id && user?.id && injury?.id && blackhawksId) {
      getBlackhawksTest(user?.id, injury?.id, blackhawksId);
    }
  }, [blackhawksId, getBlackhawksTest, injury?.id, test?.id, user?.id]);

  return (
    <div className={className}>
      <If condition={bikeType === null || testType === null}>
        <Then>
          <div className="blackhawks-start">
            <div className="row">
              <div className="col-md-12">
                <h1 className="display">{Strings.startTitle}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <BlackhawksStartForm
                  onSubmit={setTestAttributes}
                />
              </div>
            </div>
          </div>
        </Then>
        <Else>
          <div>
            <div className="row">
              <div className="col-md-12">
                <h1 className="display">{Strings.testTitle}</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <TestHeader
                  user={user}
                  injury={injury}
                  className="blackhawks-test-header"
                  testDate={test.created_at || dayjs()}
                  tester={test.clinic_user || currentUser}
                  clinic={test.clinic || currentClinic}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ErrorBanner error={error} />
                <Activity active={activity} static={loading}>
                  <BlackhawksTestForm
                    blackhawks={{
                      // Pack the initial test and bike types into the test data:
                      full_test: testType === 'full',
                      bike_type: bikeType,
                      max_tension_levels: maxTension,
                      ...test
                    }}
                    onSubmit={saveBlackhawksTest}
                  />
                </Activity>
              </div>
            </div>
          </div>
        </Else>
      </If>
    </div>
  );
};

export default BlackhawksTest;

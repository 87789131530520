import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { ArchiveBaselineModal } from '../baseline-archive';
import AccessControl from '../../access-control';
import Dropdown, { MenuItem, MenuLink } from '../../dropdown';
import { UserPaths } from '../../../paths';
import replaceLinkParams from '../../../utilities/replace-link-params';
import { RoleDescriptions } from '../../../utilities/user-roles';
import Strings from './lang';
import Icon from '../../icon';

const BaselineActionsDropdown = ({
  baselineId,
  userId,
  isContinuable = false,
  onArchive
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      isOpen={open}
      placement="bottom-end"
      trigger={(
        <button 
          type="button" 
          className={classnames('baseline-actions-button', { selected: open })}
          onClick={() => setOpen(prev => !prev)}
        >
          <Icon name="ellipsis-v" />
        </button>
      )}
      onClose={() => setOpen(false)}
    >
      <MenuLink
        type="primary"
        icon={isContinuable ? 'square-caret-right' : 'pen-to-square'}
        to={
          replaceLinkParams(
            isContinuable
              ? UserPaths.baselines.steps.continue.link
              : UserPaths.baselines.edit.link,
            {
              userId,
              baselineId
            }
          )
        }
      >
        {isContinuable ? Strings.continueTest : Strings.editBaseline}
      </MenuLink>
      <AccessControl roles={[RoleDescriptions.Clinician, RoleDescriptions.BaselineTester]}>
        <MenuItem
          type="danger"
          icon="box-archive"
          onSelect={onArchive}
        >
          {Strings.archiveBaselineAction}
        </MenuItem>
      </AccessControl>
    </Dropdown>
  );
};

const BaselineActions = ({
  baselineId,
  userId,
  isContinuable,
  isArchived,
  onArchive,
  onUnarchive
}) => {
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  const handleArchiveClick = useCallback(() => {
    setArchiveModalOpen(true);
  }, []);

  return (
    <>
      <div className="baseline-actions">
        {isArchived ? (
          <button type="button" className="edit-button warning" onClick={onUnarchive}>
            <Icon name="undo" />&nbsp;
            {Strings.unarchiveBaselineAction}
          </button>
        ) : (
          <BaselineActionsDropdown
            baselineId={baselineId}
            userId={userId}
            isContinuable={isContinuable}
            onArchive={handleArchiveClick}
          />
        )}
      </div>
      <ArchiveBaselineModal 
        isOpen={archiveModalOpen}
        onClose={() => setArchiveModalOpen(false)}
        onSubmit={onArchive}
      />
    </>
  );
};

export default BaselineActions;

import React, { useState, useCallback } from 'react';
import { If, Then } from 'react-if';
import { 
  FormComponent, 
  FormInputGroup, 
  RadioInputGroup, 
  CheckboxInputGroup 
} from '../../forms';
import filterBagtagInput from '../../utilities/bagtag-filter';
import { useObjectState } from '../../hooks';
import Strings from './lang';

const initialState = {
  bagTag: '',
  confirmBagTag: '',
  reason: '',
  assign: false,
  terms: false,
};

const isValid = (state, reassign) => {
  const {
    bagTag,
    confirmBagTag,
    reason,
    assign,
    terms
  } = state;

  return bagTag.length > 0
    && confirmBagTag.length > 0
    && bagTag === confirmBagTag
    && (reason.length > 0 || !reassign)
    && (assign || !reassign)
    && (terms || !reassign);
};

const BagtagForm = React.forwardRef(({
  reassign = false,
  onSubmit = () => {},
}, ref) => {
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = useObjectState(initialState);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (isValid(state, reassign)) {
      onSubmit(state.bagTag, state.reason);
    }
  }, [onSubmit, reassign, state]);

  return (
    <FormComponent 
      ref={ref}
      className="bagtag-modal-form"
      onSubmit={handleSubmit}
    >
      <FormInputGroup
        className="form-group bagtag-input-group"
        labelText={Strings.bagTagLabelText}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: state.bagTag,
          onChange: (e) => setState({ bagTag: filterBagtagInput(e.target.value) })
        }}
        inputValid={state.bagTag.length > 0}
        messageText={Strings.bagTagErrorText}
        messageClassName="alert alert-danger"
        required
        touched={submitted}
      />

      <FormInputGroup
        className="form-group confirm-bagtag-input-group"
        labelText={Strings.confirmBagTagLabelText}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: state.confirmBagTag,
          onChange: (e) => setState({ confirmBagTag: filterBagtagInput(e.target.value) })
        }}
        inputValid={state.confirmBagTag.length > 0 && state.confirmBagTag === state.bagTag}
        messageText={Strings.confirmBagTagErrorText}
        messageClassName="alert alert-danger"
        required
        touched={submitted}
      />

      <If condition={reassign}>
        <Then>
          <div className="bagtag-terms">
            <RadioInputGroup
              className="form-group bagtag-reason-input-group"
              titleLabelText={Strings.reasonLabelText}
              radioLabels={[
                Strings.reasonOptionLost,
                Strings.reasonOptionStolen,
                Strings.reasonOptionDamaged,
                Strings.reasonOptionOther
              ]}
              radioValues={['lost', 'stolen', 'damaged', 'other']}
              initialValue={state.reason}
              inputProps={{
                className: 'form-control',
                name: 'bagtag-reason'
              }}
              inputValid={state.reason.length > 0}
              messageText={Strings.reasonErrorText}
              messageClassName="alert alert-danger"
              onUpdate={(value) => setState({ reason: value })}
              touched={submitted}
            />

            <CheckboxInputGroup
              className="form-group bagtag-assign-input-group"
              labelText={Strings.assignLabelText}
              inputProps={{
                className: 'form-control',
                checked: state.assign,
                onChange: (e) => setState({ assign: e.target.checked })
              }}
              inputValid={state.assign}
              messageText={Strings.assignErrorText}
              messageClassName="alert alert-danger"
              required
              touched={submitted}
            />

            <CheckboxInputGroup
              required
              className="form-group bagtag-terms-input-group"
              labelText={Strings.termsLabelText}
              inputProps={{
                className: 'form-control',
                checked: state.terms,
                onChange: (e) => setState({ terms: e.target.checked })
              }}
              inputValid={state.terms}
              messageText={Strings.termsErrorText}
              messageClassName="alert alert-danger"
              touched={submitted}
            />

            <p className="alert alert-info">
              {Strings.terms}
            </p>

          </div>
        </Then>
      </If>
    </FormComponent>
  );
});

export default BagtagForm;

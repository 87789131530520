import { RoleDescriptions, userHasRoleMatchingDescription } from '../../utilities/user-roles';
import checkUserTrainingExpiry from '../../utilities/user-training-expiry';

const getVideosCount = (modules) => {
  return modules.reduce((acc, module) => acc + module.videos.length, 0);
};

export default {
  'physical-therapists': {
    name: 'Complete Concussion Management Training Course for Physical Therapists (PT, DC, AT, OT)',
    img: 'pt-course-graphic.png',
    permitted: (user) => (
      user.active 
        && userHasRoleMatchingDescription(user, RoleDescriptions.Clinician)
        && checkUserTrainingExpiry(user)
    ),
    modules: [{
      name: 'Concussion Neurology, Epidemiology, Prevention & the Amsterdam Consensus',
      module: 1,
      videos: [{
        id: '465416840',
        name: 'Intro & Epidemiology of Concussion',
        hash: '5758ead8ef'
      }, {
        id: '465414431',
        name: 'Neurology Overview',
        hash: '96746b58c9'
      }, {
        id: '838425796',
        name: 'The International Consensus Statement on Concussion in Sport',
        hash: 'cd16f82ece'
      }, {
        id: '465477598',
        name: 'Concussion Prevention & Recovery Optimization',
        hash: '3f8ad9c789'
      }, {
        id: '647007985',
        name: 'The Complete Concussions Program',
        hash: '940876e41c'
      }, {
        id: '646601035',
        name: 'The Concussion Tracker App',
        hash: '94ac0e522f'
      }]
    }, {
      name: 'Pathophysiology of Concussion',
      module: 2,
      videos: [{
        id: '465843077',
        name: 'The \'Neurometabolic Cascade\' of Concussion',
        hash: 'a92eed0f4e'
      }, {
        id: '465840143',
        name: 'Blood Flow & ANS Dysregulation',
        hash: 'f250d29bd8'
      }, {
        id: '465850994',
        name: 'Metabolic Vulnerability, Second Impact Syndrome, & Physiologic Recovery',
        hash: '73d856904e'
      }]
    }, {
      name: 'Acute Neuro-Endocrine Aspects of Concussion & Sleep Dysregulation',
      module: 3,
      videos: [{
        id: '472342321',
        name: 'Acute Immuno-Endocrine Aspects of Concussions',
        hash: '7d98257c3b'
      }, {
        id: '488617145',
        name: 'Overview of Sleep',
        hash: 'ec260848d5'
      }]
    }, {
      name: 'Biomechanics of Concussion, Subconcussive Impacts, and the Cervical Spine',
      module: 4,
      videos: [{
        id: '466267677',
        name: 'Biomechanics of Concussion',
        hash: '6cdef1b8d8'
      }, {
        id: '466264478',
        name: '"Subconcussive" Impacts',
        hash: '705dba5f76'
      }, {
        id: '466260108',
        name: 'Influence of Repeat Concussions',
        hash: 'e45d75083b'
      }, {
        id: '466257996',
        name: 'Wearable Technologies',
        hash: 'd48f6be995'
      }, {
        id: '466275488',
        name: 'Influence of the Cervical Spine on Head Kinematics',
        hash: 'e2a78dcc61'
      }]
    }, {
      name: 'The Most Comprehensive Baseline Testing Protocol',
      module: 5,
      videos: [{
        id: '466635647',
        name: 'Baseline Testing: What Does the Evidence Say?',
        hash: '0077bd4d2d'
      }, {
        id: '468224669',
        name: 'Preparation & Pre-Test - Lecture',
        hash: 'd62cf9687f'
      }, {
        id: '466616421',
        name: 'Preparation & Pre-Test - Testing/Practical Video',
        hash: '120061532a'
      }, {
        id: '468215663',
        name: 'Baseline Testing: Station 1 - Lecture',
        hash: '397f936b22'
      }, {
        id: '466610712',
        name: 'Baseline Testing: Station 1 - Testing/Practical Video',
        hash: '274a021d78'
      }, {
        id: '468961250',
        name: 'Baseline Testing: Station 2 - Lecture',
        hash: '8e8dded553'
      }, {
        id: '466605146',
        name: 'Baseline Testing: Station 2 - Testing/Practical Video',
        hash: '8d867e7065'
      }, {
        id: '468982265',
        name: 'Baseline Testing: Station 3 - Lecture',
        hash: '38312a467d'
      }, {
        id: '234535379',
        name: 'Baseline Testing: Station 3 - How to make a reaction time stick',
        hash: '3d5a05c1cf'
      }, {
        id: '466601629',
        name: 'Baseline Testing: Station 3 - Testing/Practical Video',
        hash: 'e33f91f7a9'
      }]
    }, {
      name: 'Neurocognitive Test Administration, Interpretation, and Evidence',
      module: 6,
      videos: [{
        id: '644850039',
        name: 'DANA Neurocognitive Testing - Lecture',
        hash: '6eaba1f5a3'
      }, {
        id: '644846187',
        name: 'DANA Baseline Test Interpretation',
        hash: 'b7b4c0fc2a'
      }, {
        id: '644842634',
        name: 'DANA Post-Injury Test Interpretation',
        hash: '9d2f92fcfb'
      }]
    }, {
      name: 'Assessment of Acute Concussion',
      module: 7,
      videos: [{
        id: '469937517',
        name: 'On-Field & Sideline Evaluation',
        hash: '042dc75576'
      }, {
        id: '469931410',
        name: 'In-Clinic Evaluation Overview',
        hash: '8b60cb359a'
      }, {
        id: '469923832',
        name: 'Neurological Examination',
        hash: '23528d6c8e'
      }, {
        id: '470280856',
        name: 'Special (Diagnostic) Testing',
        hash: '091b3eaa9a'
      }, {
        id: '469920056',
        name: 'Vestibular OculoMotor Screen (VOMS) Testing',
        hash: '4ee1e7c57c'
      }, {
        id: '280586233',
        name: 'Vestibular OculoMotor Screen (VOMS) Testing - Practical Assessment',
        hash: '7551f00f74'
      }, {
        id: '470267778',
        name: 'Concussion Education & Prognosis',
        hash: '1c5f2d3d77'
      }, {
        id: '368917371',
        name: 'ACUTE Patient Education Drawing Example',
        hash: 'd6c9f81adf'
      }, {
        id: '483800384',
        name: 'Nutrition for Acute Care - Part 1',
        hash: '73f8936b45'
      }, {
        id: '475324348',
        name: 'Nutrition for Acute Care - Part 2',
        hash: '5b966803ff'
      }, {
        id: '475312328',
        name: 'Nutrients for Brain Recovery - Part 1',
        hash: 'e91dc886a9'
      }, {
        id: '504461433',
        name: 'Nutrients for Brain Recovery - Part 2',
        hash: '39491dd688'
      }, {
        id: '475615070',
        name: 'Pharmaceutical Options',
        hash: '442bf25907'
      }]
    }, {
      name: 'Management of Acute Concussion',
      module: 8,
      videos: [{
        id: '470659315',
        name: 'Overview of Acute Management & Return to Activity',
        hash: '4b20f52c78'
      }, {
        id: '470654253',
        name: 'Return to School & Work',
        hash: 'a94067c48f'
      }, {
        id: '470653186',
        name: 'Return to Driving',
        hash: '72a4c3d815'
      }, {
        id: '471395343',
        name: 'Return to Physical Activity & Sport',
        hash: '95ee005598'
      }, {
        id: '471392088',
        name: 'Return to Physical Activity & Sport - Other Considerations',
        hash: '74d70b05fc'
      }, {
        id: '471390711',
        name: 'Summary of Acute Management - Putting it together',
        hash: '5338229f56'
      }]
    }, {
      name: 'Pathophysiology of Post-Concussion Syndrome (PCS)',
      module: 9,
      videos: [{
        id: '482728685',
        name: 'Pathophysiology of Persistent Concussion Symptoms (PCS)',
        hash: 'b9894fa6ff'
      }, {
        id: '482752812',
        name: 'Theory 1: Blood Flow & Autonomic Nervous System Dysregulation',
        hash: '20c5730377'
      }, {
        id: '482749274',
        name: 'Theory 2: Metabolic, Inflammatory, Hormonal',
        hash: 'd59fa730b1'
      }, {
        id: '472843075',
        name: 'Metabolic Aspects of PCS',
        hash: '91868bde27'
      }, {
        id: '473172847',
        name: 'Hormones and HPA Axis in PCS',
        hash: '02941a362a'
      }, {
        id: '482734755',
        name: 'Theory 3: Visual & Vestibular',
        hash: '27b1ab3958'
      }, {
        id: '469120559',
        name: 'Visual Dysfunction in PCS',
        hash: '55a47c5d61'
      }, {
        id: '483743829',
        name: 'Vestibular Dysfunction in PCS',
        hash: '48d5c85a45'
      }, {
        id: '484471404',
        name: 'Theory 4: Cervical Spine Dysfunction',
        hash: '8228b91b9d'
      }, {
        id: '483190945',
        name: 'Theory 5: Mental Health & Psychological Overlay',
        hash: 'd5ef0276f6'
      }]
    }, {
      name: 'Post-Concussion Syndrome Treatment',
      module: 10,
      videos: [{
        id: '486485482',
        name: 'PCS Treatment Overview',
        hash: 'e7fa84cf0d'
      }, {
        id: '486507258',
        name: 'PCS Treatment: Visit 1 - History & Physical Exam',
        hash: 'de7d54aeed'
      }, {
        id: '483757792',
        name: 'Benign Paroxysmal Positional Vertigo (BPPV)',
        hash: '1ec39a65a1'
      }, {
        id: '486490749',
        name: 'PCS Treatment: Visit 1 - Patient Education & Reassurance',
        hash: 'de654a1981'
      }, {
        id: '476477013',
        name: 'Nutrition & Supplementation for PCS',
        hash: 'aee27a5397'
      }, {
        id: '475642300',
        name: 'Supporting the Gut-Brain Axis & HPA Axis',
        hash: 'c76e0381d5'
      }, {
        id: '488170227',
        name: 'PCS Treatment: Visit 2 - Exercise Tolerance Testing',
        hash: '569a7215d9'
      }, {
        id: '488166462',
        name: 'PCS Treatment: Visit 2 - Orthostatic Intolerance (POTS & OH)',
        hash: '3c15d4850a'
      }, {
        id: '489106186',
        name: 'PCS Treatment: Visit 3A - Visual & Vestibular Assessment & Treatment',
        hash: 'a58713c5fd'
      }, {
        id: '489025472',
        name: 'PCS Treatment: Visit 3B - Cervical Spine Assessment & Treatment',
        hash: 'b36c8e81f1'
      }, {
        id: '489487251',
        name: 'PCS Treatment: Ongoing Care & Considerations',
        hash: '51c0297307'
      }, {
        id: '469118012',
        name: 'Assessment & Management of Visual Disorders in PCS',
        hash: '48d97ee427'
      }, {
        id: '484461739',
        name: 'Vestibular System Assessment & Treatment',
        hash: '05079eafb7'
      }]
    }, {
      name: 'Chronic Traumatic Encephalopathy (CTE)',
      module: 11,
      videos: [{
        id: '346433893',
        name: 'Chronic Traumatic Encephalopathy (CTE)',
        hash: 'db6d498579'
      }]
    }, {
      name: 'Impacting Your Community and Your Practice',
      module: 12,
      videos: [{
        id: '647853683',
        name: 'Impacting Your Community & Your Practice',
        hash: '0bc0591318'
      }, {
        id: '559131587',
        name: 'The Concussion Fix Affiliate Program',
        hash: '6de0496e0f'
      }]
    }],
    get videosCount() {
      return getVideosCount(this.modules);
    }
  },
  'ot-slp-cognitive-rehab': {
    name: 'Concussion Management for OT, SLP, and Cognitive Rehabilitation Professionals',
    img: 'ot-course-graphic.png',
    permitted: () => false,
    modules: [{
      name: 'Introduction, Epidemiology, and Concussion Prevention',
      module: 1,
      videos: [{
        id: '673750961',
        name: 'Intro & Epidemiology of Concussion',
        hash: 'a9e920fcd4'
      }, {
        id: '673748477',
        name: 'Concussion Prevention & Recovery Optimization',
        hash: '51d8fb3e3f'
      }, {
        id: '673739166',
        name: 'Concussion Problems & Proposed Solutions',
        hash: '93d6bf5a9a'
      }]
    }, {
      name: 'Pathophysiology of Concussion',
      module: 2,
      videos: [{
        id: '676366772',
        name: 'The \'Neurometabolic Cascade\' of Concussion',
        hash: 'd4135b734f'
      }, {
        id: '676365056',
        name: 'Blood Flow & Autonomic Nervous System Dysregulation',
        hash: '12d87bffc7'
      }, {
        id: '465850994',
        name: 'Vulnerability, Physiologic Recovery & Second Impact Syndrome',
        hash: '73d856904e'
      }]
    }, {
      name: 'Baseline & Neurocognitive Test Administration & Interpretation',
      module: 3,
      videos: [{
        id: '678875922',
        name: 'Baseline Testing Evidence',
        hash: '69cbc6e360'
      }, {
        id: '678882344',
        name: 'CCMI Baseline Battery',
        hash: 'b9eb330ae8'
      }, {
        id: '644846187',
        name: 'DANA Baseline Interpretation',
        hash: 'b7b4c0fc2a'
      }, {
        id: '644842634',
        name: 'DANA Post-Injury Interpretation',
        hash: '9d2f92fcfb'
      }]
    }, {
      name: 'Acute Concussion Assessment & Management',
      module: 4,
      videos: [{
        id: '678880778',
        name: 'Acute Concussion Assesment',
        hash: 'cead8380a1'
      }, {
        id: '469920056',
        name: 'Vestibular Oculomotor Screen (VOMS) Testing',
        hash: '4ee1e7c57c'
      }, {
        id: '280586233',
        name: 'VOMS Practical Assessment',
        hash: '7551f00f74'
      }, {
        id: '678906675',
        name: 'Management of Acute Concussion',
        hash: '2fc0a910b3'
      }]
    }, {
      name: 'Persistent Concussion Symptoms (PCS) & CTE',
      module: 5,
      videos: [{
        id: '678877315',
        name: 'Persistent Concussion Symptoms (PCS)',
        hash: '7c8741c360'
      }, {
        id: '678894814',
        name: 'Chronic Traumatic Encephalopathy (CTE)',
        hash: '9d43e79ff4'
      }]
    }, {
      name: 'Active Rehabilitation',
      module: 6,
      videos: [{
        id: '734483282',
        name: 'Active Rehabilitation',
        hash: '8b5f9606fb'
      }]
    }, {
      name: 'Management of Energy & Fatigue',
      module: 7,
      videos: [{
        id: '726871100',
        name: 'Energy & Fatigue',
        hash: 'c7fe91d521'
      }]
    }, {
      name: 'Sleep',
      module: 8,
      videos: [{
        id: '727760614',
        name: 'Sleep',
        hash: '57e9e5ded2'
      }]
    }, {
      name: 'Cognitive Rehabilitation',
      module: 9,
      videos: [{
        id: '741241527',
        name: 'Cognitive Rehabilitation',
        hash: 'a8462dec5b'
      }, {
        id: '731375189',
        name: 'Memory',
        hash: 'd026b36d43'
      }, {
        id: '741294529',
        name: 'Word Finding & Organization',
        hash: '07ebc4768a'
      }, {
        id: '741287847',
        name: 'Attention & Decision Making',
        hash: 'f52e628341'
      }]
    }, {
      name: 'Return to Work',
      module: 10,
      videos: [{
        id: '728908703',
        name: 'Return to Work - Part 1',
        hash: '2cd9f3c1cb'
      }, {
        id: '728912713',
        name: 'Return to Work - Part 2',
        hash: '9d453a7fe1'
      }]
    }, {
      name: 'Return to School',
      module: 11,
      videos: [{
        id: '729010220',
        name: 'Return to School',
        hash: '14fe3c031d'
      }]
    }, {
      name: 'Ergonomics',
      module: 12,
      videos: [{
        id: '729310976',
        name: 'Ergonomics',
        hash: '014dc419ae'
      }]
    }, {
      name: 'Rest, Relaxation, & Mood',
      module: 13,
      videos: [{
        id: '729732789',
        name: 'Rest, Relaxation, & Mood',
        hash: 'd18523d08f'
      }]
    }, {
      name: 'Physical Symptom Management & Return to Driving',
      module: 14,
      videos: [{
        id: '729711097',
        name: 'Other Common Symptoms',
        hash: 'b3baa49ed8'
      }]
    }],
    get videosCount() {
      return getVideosCount(this.modules);
    }
  },
  'physicians-specialists': {
    name: 'Concussion Management for Physicians & Specialists',
    img: 'physicians-course-graphic.png',
    permitted: () => false,
    modules: [{
      name: 'Introduction, Epidemiology, and Concussion Prevention',
      module: 1,
      videos: [{
        id: '673750961',
        name: 'Intro & Epidemiology of Concussion',
        hash: 'a9e920fcd4'
      }, {
        id: '673748477',
        name: 'Concussion Prevention & Recovery Optimization',
        hash: '51d8fb3e3f'
      }, {
        id: '673739166',
        name: 'Concussion Problems & Proposed Solutions',
        hash: '93d6bf5a9a'
      }]
    }, {
      name: 'Pathophysiology of Concussion',
      module: 2,
      videos: [{
        id: '676366772',
        name: 'The \'Neurometabolic Cascade\' of Concussion',
        hash: 'd4135b734f'
      }, {
        id: '676365056',
        name: 'Blood Flow & Autonomic Nervous System Dysregulation',
        hash: '12d87bffc7'
      }, {
        id: '465850994',
        name: 'Vulnerability, Physiologic Recovery & Second Impact Syndrome',
        hash: '73d856904e'
      }]
    }, {
      name: 'Baseline & Neurocognitive Test Administration & Interpretation',
      module: 3,
      videos: [{
        id: '678875922',
        name: 'Baseline Testing Evidence',
        hash: '69cbc6e360'
      }, {
        id: '678882344',
        name: 'CCMI Baseline Battery',
        hash: 'b9eb330ae8'
      }, {
        id: '644846187',
        name: 'DANA Baseline Interpretation',
        hash: 'b7b4c0fc2a'
      }, {
        id: '644842634',
        name: 'DANA Post-Injury Interpretation',
        hash: '9d2f92fcfb'
      }]
    }, {
      name: 'Acute Concussion Assessment & Management',
      module: 4,
      videos: [{
        id: '678880778',
        name: 'Acute Concussion Assesment',
        hash: 'cead8380a1'
      }, {
        id: '469920056',
        name: 'Vestibular Oculomotor Screen (VOMS) Testing',
        hash: '4ee1e7c57c'
      }, {
        id: '280586233',
        name: 'VOMS Practical Assessment',
        hash: '7551f00f74'
      }, {
        id: '678906675',
        name: 'Management of Acute Concussion',
        hash: '2fc0a910b3'
      }]
    }, {
      name: 'Persistent Concussion Symptoms (PCS) & CTE',
      module: 5,
      videos: [{
        id: '678877315',
        name: 'Persistent Concussion Symptoms (PCS)',
        hash: '7c8741c360'
      }, {
        id: '678894814',
        name: 'Chronic Traumatic Encephalopathy (CTE)',
        hash: '9d43e79ff4'
      }]
    }],
    get videosCount() {
      return getVideosCount(this.modules);
    }
  }
};

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import classnames from 'classnames';
import { FormInput, FormInputMessage } from '../../forms';
import Strings from './lang';

const HeartRateInputGroup = ({
  heartRate,
  onUpdate,
  touched: _propTouched = false
}) => {
  const [touched, setTouched] = useState(_propTouched);

  const hasError = useMemo(() => (
    touched && (isNaN(heartRate) || heartRate <= 0) 
  ), [heartRate, touched]);

  const onBlur = useCallback(() => {
    setTouched(true);
  }, []);

  useEffect(() => {
    setTouched(_propTouched);
  }, [_propTouched]);

  return (
    <div
      className={classnames('form-group form-input-group ovs-input-group', {
        invalid: hasError
      })}
    >
      <label>{Strings.heartRateInputLabel} <span className="required">*</span></label>
      <div className="input-group">
        <FormInput
          type="number"
          inputProps={{
            className: 'form-control',
            step: 1,
            min: 1,
            value: isNaN(heartRate) ? '' : heartRate,
            onBlur,
            onChange: (e) => {
              onUpdate(e.target.valueAsNumber);
            }
          }}
        />
        <span className="input-group-addon">bpm</span>
      </div>
      <FormInputMessage
        visible={hasError}
        className="alert alert-danger"
        text={Strings.heartRateInputErrorMessage}
      />
    </div>
  );
};

export default HeartRateInputGroup;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { KingDevick, KD_THIRD_CARD_AGE_MIN } from '../test-components';
import Card from '../../card';
import { AddNoteButton } from '../../buttons';
import HeaderStrings from '../lang';
import Strings from './lang';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import { age } from '../../../dates';
import { FormComponent, FormInputMessage } from '../../../forms';

class KingDevickCard extends Component {
  constructor(props) {
    super(props);
    const { kingDevick = {}, birthday } = props;
    const { version = 1, cards = [] } = kingDevick;
    const patientAge = age(birthday);

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      version,
      notes: kingDevick.notes || '',
      cards: [...(cards || [])],
      patientAge,
      submitted: false
    };
  }

  render() {
    const { 
      version, cards, patientAge, notes 
    } = this.state;
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card 
          title={HeaderStrings.kingDevickHeader} 
          actionButtons={(
            <AddNoteButton 
              note={notes}
              title={
                Strings.formatString(Strings.abStractNotesTitle, HeaderStrings.kingDevickHeader)
              }
              submitButtonTitle={Strings.addNoteButtonText}
              onSave={(notes) => {
                this.setState({ notes });
              }}
            />
          )}
        >
          <div className="card card-form">
            <p className="text-muted">{Strings.kingDevickInfoText}</p>
            <FormInputMessage
              visible
              text={Strings.kingDevickDoneInfoText}
              className="alert alert-warning"
            />
            <KingDevick
              patientAge={patientAge}
              initialState={{
                cards,
                version
              }}
              onUpdate={this.onUpdate}
              touched={this.state.submitted}
            />
            <If condition={typeof this.props.onSubmit === 'function'}>
              <Then>
                <div className="form-footer">
                  <button type="submit" className="btn btn-primary">
                    {this.props.saveButtonText || Strings.saveButtonText}
                  </button>
                </div>
              </Then>
            </If>
          </div>
        </Card>
      </FormComponent>
    );
  }

  isValid() {
    const { cards, patientAge } = this.state;

    if (cards === undefined) {
      return false;
    }

    for (let i = 0; i < cards.length; i += 1) {
      if (cards[i].milliseconds === 0 || cards[i].errors === '') {
        if (patientAge > KD_THIRD_CARD_AGE_MIN) {
          return false;
        }

        if (i < cards.length - 1) {
          return false;
        }
      }
    }

    return true;
  }

  onUpdate(state) {
    const { version, cards } = state;

    this.setState({
      version,
      cards
    });
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const { version, cards, notes } = this.state;

    const attributes = {
      king_devick_test: {
        version,
        cards,
        notes
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }
}

KingDevickCard.propTypes = {
  ...BaselineTestCardPropTypes,
  kingDevick: PropTypes.object,
  birthday: PropTypes.string
};

export default KingDevickCard;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { InvoiceSelector } from '../../redux/selectors';
import { getCurrentUserId } from '../../tokens';
import { getInvoicesAsync } from '../../redux/thunks/clinic-invoices';
import InvoicesTable from './invoices-table';
import { useMount } from '../../hooks';
import ErrorBanner from '../errors/error-banner';
import Strings from './lang';

const PAID_FILTER_KEY = 'paid';

const PaidInvoices = ({
  invoices,
  clinicId,
  onInvoiceClick,
  getPaidInvoices
}) => {
  const [error, setError] = useState(null);

  useMount(() => {
    if (!invoices.length) {
      getPaidInvoices(clinicId).catch(error => {
        setError(
          Strings.formatString(
            Strings.invoicesLoadErrorMessage,
            error.message || Strings.unknownErrorMessage
          )
        );
      });
    }
  });

  return (
    <div>
      <ErrorBanner error={error} />
      {invoices.length ? (
        <InvoicesTable 
          paid
          invoices={invoices} 
          onInvoiceClick={onInvoiceClick}
        />
      ) : (
        <h2 className="text-center">
          {Strings.emptyPaidInvoicesMessage}
        </h2>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { users } = state;
  const invoices = InvoiceSelector.getPaidInvoices(state, ownProps);
  const currentUser = users[getCurrentUserId()];
  return { invoices, currentUser };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPaidInvoices: (clinicId) => {
      return dispatch(getInvoicesAsync(clinicId, PAID_FILTER_KEY));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaidInvoices);

import { jsonApiCreate, jsonApiUpdate, jsonApiRead } from '../../requests/jsonapi';
import { getCurrentClinicId } from '../../tokens';
import { updateInjuryAssessment } from '../actions/injury-assessments';

const createInjuryAssessmentAsync = (userId, attributes) => {
  const queryParams = { current_clinic_id: getCurrentClinicId() };
  const options = { path: `users/${userId}/injury_assessments`, queryParams };
  return (dispatch) => {
    return jsonApiCreate({ type: 'injury_assessments', attributes }, options).then(results => {
      dispatch(updateInjuryAssessment(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateInjuryAssessmentAsync = (userId, injuryId, assessmentId, attributes) => {
  const queryParams = { current_clinic_id: getCurrentClinicId() };
  const options = { path: `users/${userId}/injuries/${injuryId}/injury_assessments/${assessmentId}`, queryParams };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'injury_assessments', id: assessmentId, attributes }, options).then(results => {
      dispatch(updateInjuryAssessment(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getInjuryAssessmentAsync = (userId, injuryId, assessmentId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/injury_assessments/${assessmentId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'injury_assessments' }, options).then(results => {
      dispatch(updateInjuryAssessment(userId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createInjuryAssessmentAsync,
  updateInjuryAssessmentAsync,
  getInjuryAssessmentAsync
};

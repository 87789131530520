import React from 'react';
import { FormInputGroup } from '../../../forms';
import VideoPlayer from '../../video-player';
import { VideoValidator } from './utils';
import Strings from '../lang';

const VideoUrlInput = ({
  url = '',
  valid = false,
  touched = false,
  onChange = () => {}
}) => {
  return (
    <>
      <FormInputGroup
        required={false}
        className="form-group"
        labelText={Strings.productVideoSectionTitle}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: url,
          placeholder: Strings.productVideoPlaceholder,
          onChange: (e) => {
            const { value } = e.target;
            onChange(value, VideoValidator.test(value));
          }
        }}
        inputValid={url?.length <= 0 || valid}
        messageText={Strings.productVideoErrorMessage}
        messageClassName="alert alert-danger"
        touched={touched}
      />
      {valid && (
        <VideoPlayer
          controls
          url={url}
          style={{ margin: 'auto' }}
        />
      )}
    </>
  );
};

export default VideoUrlInput;

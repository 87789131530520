import React from 'react';
import classNames from 'classnames';
import SelectInputGroup from './select-input-group';
import Strings from './lang';
import GenderStrings from '../../gender';

const GenderInputGroup = (props) => (
  <SelectInputGroup
    {...props}
    className={classNames('gender-input-group', props.className)}
    labelText={props.labelText || Strings.genderLabelText}
    messageText={props.messageText || Strings.genderErrorText}
  >
    <option value="">{Strings.genderNoValueText}</option>
    <option value="male">{GenderStrings.male}</option>
    <option value="female">{GenderStrings.female}</option>
    <option value="unspecified">{GenderStrings.unspecified}</option>
  </SelectInputGroup>
);

GenderInputGroup.propTypes = SelectInputGroup.propTypes;

export default GenderInputGroup;

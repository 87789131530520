import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import { jsonApiCreate } from '../../requests/jsonapi';
import { AuthenticationPaths } from '../../paths';
import { PASSWORD_RESET_URL } from '../../utilities/urls';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import PasswordResetRequestForm from './password-reset-request-form';
import Strings from './lang';

const PasswordResetRequest = () => {
  const location = useLocation();
  const [activity, setActivity] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);

  const {
    nextLocation = AuthenticationPaths.index.link,
    nextLinkText = Strings.authLinkText,
    resetUrl = PASSWORD_RESET_URL
  } = location?.state || {};

  const requestPasswordReset = useCallback((attributes) => {
    setError(null);
    setActivity(true);

    jsonApiCreate({ type: 'passwords', attributes }).then(() => {
      setActivity(false);
      setComplete(true);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, []);

  return (
    <Page className="password-reset-request" hideNavigationBar showHeaderImage>
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <h1 className="display dot">{Strings.resetTitle}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-md-offset-4">
          <ErrorBanner error={error} />
          <If condition={complete}>
            <Then>
              <div className="password-reset-request-success">
                <p className="alert alert-success">
                  {Strings.resetPasswordRequestSuccessMessage}
                </p>
                <Link to={nextLocation}>
                  {nextLinkText}
                </Link>
              </div>
            </Then>
            <Else>
              <Activity active={activity}>
                <PasswordResetRequestForm
                  nextLocation={nextLocation}
                  nextLinkText={nextLinkText}
                  resetUrl={resetUrl}
                  onSubmit={requestPasswordReset}
                />
              </Activity>
            </Else>
          </If>
        </div>
      </div>
    </Page>
  );
};

export default PasswordResetRequest;

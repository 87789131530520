import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { jsonApiRead } from '../../../requests/jsonapi';
import { ImmediateMemory } from '../test-components';
import { getUnusableWordLists } from '../test-components/word-lists';
import Card from '../../card';
import { AddNoteButton } from '../../buttons';
import HeaderStrings from '../lang';
import Strings from './lang';
import { FormComponent } from '../../../forms';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import { DEFAULT_USER_LANGUAGE } from '../../../utilities/localization';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import Languages from '../../../languages';

const MIN_WORD_COUNT = 5;
const MAX_WORD_COUNT = 10;

class ImmediateMemoryCard extends Component {
  constructor(props) {
    super(props);

    const { 
      immediateMemory = {}, 
      language, 
      userBaseline, 
      postInjury, 
      editing = false
    } = props;
    const baselineImmediateMemory = (userBaseline || {}).immediate_memory_test;
    const { word_list = '', scores = [], word_count } = immediateMemory;

    // if this is a post-injury test and the user has a 
    // baseline but the word_count is undefined, default 
    // word_count to 5, else default count to 10. If its 
    // not a post injury default count to the word_count 
    // provided or 10.
    const wordCount = postInjury && baselineImmediateMemory 
      ? (baselineImmediateMemory.word_count || MIN_WORD_COUNT) 
      : word_count || (editing ? MIN_WORD_COUNT : MAX_WORD_COUNT);

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.updateWordCount = this.updateWordCount.bind(this);

    this.state = {
      language: language || DEFAULT_USER_LANGUAGE,
      word_list,
      word_count: wordCount,
      userWordLists: [],
      activity: false,
      scores: [...(scores || [])],
      notes: immediateMemory.notes || '',
      submitted: false
    };
  }

  componentDidMount() {
    const { word_count } = this.state;
    this.getUsedWordLists(parseInt(word_count, 10));
  }
  
  render() {
    const { 
      word_list, word_count, scores, language, notes 
    } = this.state;
    const { postInjury = false } = this.props;

    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card 
          title={HeaderStrings.immediateMemoryHeader} 
          actionButtons={(
            <AddNoteButton 
              note={notes}
              title={
                Strings.formatString(
                  Strings.abStractNotesTitle, 
                  HeaderStrings.immediateMemoryHeader
                )
              }
              submitButtonTitle={Strings.addNoteButtonText}
              onSave={(notes) => {
                this.setState({ notes });
              }}
            />
          )}
        >
          <div className="baseline-card">    
            <div className="card-section">
              <div className="section section-primary">
                <p 
                  dangerouslySetInnerHTML={{
                    __html: Strings.formatString(
                      Strings.immediateMemoryPatientLanguageText, 
                      '<strong>', 
                      Languages[this.state.language], 
                      '</strong>'
                    )
                  }}
                />
              </div>
              <div className="im-info-section">
                <div className="section section-light">
                  <div className="section-title">{Strings.step1LabelText}</div>
                  <p>{Strings.immediateMemoryStep1InfoText}</p>
                </div>
                <div className="section section-light">
                  <div className="section-title">{Strings.step2LabelText}</div>
                  <p>{Strings.immediateMemoryStep2InfoText}</p>
                </div>
              </div>
            </div>

            <ErrorBanner error={this.state.error} />
            
            <Activity active={this.state.activity} style={{ width: '100%' }}>
              <ImmediateMemory
                language={language}
                postInjury={postInjury}
                initialState={{
                  trials: scores,
                  listNumber: word_list,
                  wordCount: word_count
                }}
                usedWordLists={this.state.userWordLists}
                touched={this.state.submitted}
                onUpdateWordCount={this.updateWordCount}
                onUpdate={this.onUpdate}
              />
            </Activity>
            <If condition={typeof this.props.onSubmit === 'function'}>
              <Then>
                <div className="card form-footer">
                  <button type="submit" className="btn btn-primary" disabled={!this.isValid()}>
                    {this.props.saveButtonText || Strings.saveButtonText}
                  </button>
                </div>
              </Then>
            </If>
          </div>
        </Card>
      </FormComponent>
    );
  }

  getUsedWordLists(count) {
    this.setState({
      activity: true,
      error: null
    });

    const { user = {} } = this.props;

    jsonApiRead(
      { type: 'baselines' }, 
      { path: `users/${user.id}/baselines/used_word_lists`, queryParams: { word_count: count } }
    ).then(usedWordLists => {
      const { language, word_list } = this.state;
      this.setState({
        activity: false,
        word_count: count,
        userWordLists: getUnusableWordLists(
          language, 
          usedWordLists.used_word_lists, 
          word_list, 
          count
        )
      });
    }).catch(() => {
      this.setState({
        activity: false,
        error: Strings.usedWordListsFetchError
      });
    });
  }

  updateWordCount(wordCount) {
    this.getUsedWordLists(parseInt(wordCount, 10));
  }

  isValid() {
    const { scores, word_list } = this.state;

    if (scores === undefined) {
      return false;
    }

    if (word_list === '') {
      return false;
    }

    for (let i = 0; i < scores.length; i += 1) {
      if (scores[i] === '') {
        return false;
      }
    }

    return true;
  }

  onUpdate(state) {
    const { listNumber, trials, wordCount } = state;

    this.setState({
      word_list: listNumber,
      scores: trials,
      word_count: parseInt(wordCount, 10),
      submitted: false
    });
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }
  
    const { 
      word_list, word_count, scores, notes 
    } = this.state;

    const attributes = {
      immediate_memory_test: {
        word_list,
        word_count,
        scores,
        notes
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }    
  }
}

ImmediateMemoryCard.propTypes = {
  ...BaselineTestCardPropTypes,
  immediateMemory: PropTypes.object,
  language: PropTypes.string
};

export default ImmediateMemoryCard;

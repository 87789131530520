import React, { useState, useCallback, useMemo } from 'react';
import { FormComponent, NumberInputGroup, FormInputGroup } from '../../forms';
import { getClinicCurrency, Money } from '../../utilities/currency';
import Strings from './lang';

const isValid = (quantity, cost) => {
  return (!isNaN(quantity) && quantity >= 1) 
    && (!isNaN(cost) && cost >= 0);
};

const AddBulkCreditsForm = ({
  clinic,
  disabled = false,
  currency: clinicCurrency,
  onSubmit
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [cost, setCost] = useState(0);
  const currency = useMemo(() => (
    clinicCurrency || getClinicCurrency(clinic)
  ), [clinic, clinicCurrency]);
  const parsedCost = parseFloat(cost) || 0;
  const parsedQuantity = parseInt(quantity, 10) || 0;

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (isValid(parsedQuantity, parsedCost)) {
      onSubmit({
        quantity: parsedQuantity,
        adjusted_cost: parsedCost
      });
    }
  }, [onSubmit, parsedCost, parsedQuantity]);

  const onUpdateCost = useCallback((e) => {
    setCost(e.target.value);
  }, []);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <NumberInputGroup 
        required
        className="form-group"
        labelText={Strings.creditQuantityLabel}
        inputProps={{
          min: 1,
          step: 1,
          value: quantity,
          className: 'form-control'
        }}
        inputValid={!isNaN(parsedQuantity) && parsedQuantity >= 1}
        messageText={Strings.creditQuantityErrorMessage}
        messageClassName="alert alert-danger"
        onUpdate={setQuantity}
        touched={submitted}
      />
      <FormInputGroup 
        required
        inputType="text"
        className="form-group"
        labelText={Strings.formatString(Strings.costAmountLabel, currency)}
        inputProps={{
          className: 'form-control',
          value: cost,
          onChange: onUpdateCost
        }}
        messageText={Strings.costAmountErrorMessage}
        messageClassName="alert alert-danger"
        inputValid={!isNaN(parsedCost) && parseFloat(parsedCost) >= 0}
        touched={submitted}
      />
      <div>
        <strong>
          {Strings.totalCostLabel}:&nbsp;
          {Money.format(parsedCost * parsedQuantity, currency)}
        </strong>
      </div>
      <button 
        type="submit" 
        className="btn btn-primary"
        disabled={disabled || !isValid(parsedQuantity, parsedCost)}
      >
        {Strings.pushCreditsButtonText}
      </button>
    </FormComponent>
  );
};

export default AddBulkCreditsForm;

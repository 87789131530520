import React, { useCallback } from 'react';
import { FormInputGroup, SelectInputGroup } from '../../../forms';
import { ProfessionStrings } from '../../../utilities/clinical-info';
import Icon from '../../icon';
import Strings from '../lang';

const ProviderInputRow = ({
  value = {},
  removeable = false,
  touched = false,
  onRemove,
  onChange
}) => {
  const handleNameChange = useCallback((e) => {
    onChange?.({
      ...(value ?? {}),
      name: e.target.value
    });
  }, [value, onChange]);

  const handleDesignationChange = useCallback((option) => {
    onChange?.({
      ...(value ?? {}),
      designation: option
    });
  }, [onChange, value]);

  return (
    <div className="app-providers-list-row">
      <div className="app-providers-list-input">
        <FormInputGroup
          required
          className="form-group"
          labelText={Strings.providerNameLabel}
          inputType="text"
          inputProps={{
            className: 'form-control',
            name: 'name',
            value: value?.name ?? '',
            onChange: handleNameChange
          }}
          messageText={Strings.providerNameError}
          messageClassName="alert alert-danger"
          inputValid={!!value?.name}
          touched={touched}
        />
      </div>
      <div className="app-providers-list-input">
        <SelectInputGroup
          className="form-group"
          labelText={Strings.providerDesignationLabel}
          inputProps={{
            className: 'form-control',
            name: 'designation',
            value: value?.designation ?? ''
          }}
          required
          messageClassName="alert alert-danger"
          messageText=""
          valid={!!value?.designation}
          touched={touched}
          onUpdate={handleDesignationChange}
        >
          <option value="">-- {Strings.selectDesignationLabel} --</option>
          {Object.keys(ProfessionStrings).map(key => (
            <option key={key} value={key}>{ProfessionStrings[key]}</option>
          ))}
        </SelectInputGroup>
      </div>
      {removeable && (
        <button type="button" className="remove-btn" onClick={onRemove}>
          <Icon name="trash-alt" />
        </button>
      )}
    </div>
  );
};

const ProviderInputList = ({
  touched = false,
  disabled = false,
  providers = [],
  onChange = () => {}
}) => {
  const onAddProvider = useCallback(() => {
    onChange([
      ...providers,
      { name: '', designation: '' }
    ]);
  }, [onChange, providers]);

  const onRemoveProvider = useCallback((index) => {
    const newProviders = [...providers];
    newProviders.splice(index, 1);
    onChange(newProviders);
  }, [onChange, providers]);

  return (
    <div className="appt-providers-list">
      <h3>{Strings.providerListTitle}</h3>
      {providers.map((provider, index) => (
        <ProviderInputRow
          key={index}
          touched={touched}
          value={provider}
          removeable={providers.length > 1}
          disabled={disabled}
          onRemove={() => onRemoveProvider(index)}
          onChange={(value) => {
            const newProviders = [...providers];
            newProviders[index] = value;
            onChange(newProviders);
          }}
        />
      ))}
      <button type="button" className="appt-providers-list-add" onClick={onAddProvider}>
        <Icon name="plus" />&nbsp;
        {Strings.addProviderButton}
      </button>
    </div>
  );
};

export default ProviderInputList;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import ClinicCard from './clinic-card';
import ClinicActions from './clinic-actions';
import { ClinicHoursDisplay } from '../clinic-hours';
import ClinicFeatures from './clinic-features';
import { confirmation } from '../confirmation';
import { 
  getClinicAsync, 
  updateClinicAsync, 
  activateClinicAsync
} from '../../redux/thunks/clinics';
import { getCurrentUserId } from '../../tokens';
import GeneralInfoCard from '../clinic-profile-cards/general-info-card';
import EditGeneralInfoCardForm from '../clinic-profile-cards/edit-general-info-card-form';
import ContactInfoCard from '../clinic-profile-cards/contact-info-card';
import EditContactInfoCardForm from '../clinic-profile-cards/edit-contact-info-card-form';
import AddressInfoCard from '../clinic-profile-cards/address-info-card';
import EditAddressInfoCardForm from '../clinic-profile-cards/edit-address-info-card-form';
import EditTokenCostButton from './edit-clinic-token-cost';
import AccessControl from '../access-control';
import { ClinicServicesTags } from '../clinic-services';
import { 
  RoleNames, 
  RoleResourceTypes, 
  RoleDescriptions, 
  makeRoleDescription, 
  AdminRoleDescriptions 
} from '../../utilities/user-roles';
import { withRouter } from '../../utilities/router-utils';
import { getClinicCurrency } from '../../utilities/currency';
import Strings from './lang';

const makeEditorRoleDescriptions = (clinicId) => ([
  RoleDescriptions.SuperAdmin,
  RoleDescriptions.SalesAdmin,
  makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
]);

class ClinicProfile extends Component {
  constructor(props) {
    super(props);

    this.updateClinic = this.updateClinic.bind(this);
    this.confirmActivateClinic = this.confirmActivateClinic.bind(this);

    this.state = {
      activity: false,
      error: null,
      editGeneral: false,
      editContact: false,
      editAddress: false,
      editBilling: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getClinic(this.props.id);
  }

  render() {
    const { clinic, currentUser } = this.props;
    const { services = [], business_hours = {} } = clinic || {};
    const address = clinic.address || {};
    const billingAddress = clinic.billing_address || {};
    const editorRoles = makeEditorRoleDescriptions(this.props.id);
    const currency = getClinicCurrency(clinic);

    return (
      <Page className="clinic-profile">
        <Activity active={this.state.activity} static={!this.hasLoadedClinic()}>

          <div className="row">
            <div className="col-md-12">
              <h1 className="display">
                {clinic.name}
                <ClinicServicesTags services={services} />
              </h1>
              <AccessControl roles={AdminRoleDescriptions}>
                <>
                  <div>
                    <span className="bold">{Strings.creditsRemainingText}:</span>&nbsp;&nbsp;
                    <span>{clinic.credits_available || 0}</span>
                  </div>  
                  <div>
                    <span className="bold">{Strings.creditPriceLabel}:</span>&nbsp;&nbsp;
                    <span>{clinic.token_cost || Strings.defaultLabel} {currency}</span>&nbsp;&nbsp;
                    <EditTokenCostButton 
                      tokenCost={clinic.token_cost}
                      currency={currency}
                      onSubmit={this.updateClinic}
                    />
                  </div>  
                </>
              </AccessControl>

              <div className="row">
                <div className="col-md-9">
                  {/* Left column */}

                  <div className="row">
                    <div className="col-md-12">
                      <ErrorBanner error={this.state.error} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <ClinicCard
                        title={Strings.generalHeadingText}
                        roles={editorRoles}
                        editing={this.state.editGeneral}
                        onEditToggle={() => {
                          const { editGeneral } = this.state;
                          this.setState({ editGeneral: !editGeneral });
                        }}
                      >
                        <If condition={this.state.editGeneral}>
                          <Then>
                            <EditGeneralInfoCardForm
                              clinic={clinic}
                              user={currentUser}
                              onSubmit={this.updateClinic}
                            />
                          </Then>
                          <Else>
                            <GeneralInfoCard clinic={clinic} />
                          </Else>
                        </If>
                      </ClinicCard>

                      <ClinicCard
                        title={Strings.contactHeadingText}
                        roles={editorRoles}
                        editing={this.state.editContact}
                        onEditToggle={() => {
                          const { editContact } = this.state;
                          this.setState({ editContact: !editContact });
                        }}
                      >
                        <If condition={this.state.editContact}>
                          <Then>
                            <EditContactInfoCardForm
                              clinic={clinic}
                              onSubmit={this.updateClinic}
                            />
                          </Then>
                          <Else>
                            <ContactInfoCard clinic={clinic} />
                          </Else>
                        </If>
                      </ClinicCard>

                      <ClinicCard
                        title={Strings.addressHeadingText}
                        roles={editorRoles}
                        editing={this.state.editAddress}
                        onEditToggle={() => {
                          const { editAddress } = this.state;
                          this.setState({ editAddress: !editAddress });
                        }}
                      >
                        <If condition={this.state.editAddress}>
                          <Then>
                            <EditAddressInfoCardForm
                              address={address}
                              onSubmit={(attributes) => {
                                this.updateClinic({
                                  address_attributes: attributes
                                });
                              }}
                            />
                          </Then>
                          <Else>
                            <AddressInfoCard address={address} />
                          </Else>
                        </If>
                      </ClinicCard>

                      <ClinicCard
                        title={Strings.billingAddressHeadingText}
                        roles={editorRoles}
                        editing={this.state.editBilling}
                        onEditToggle={() => {
                          const { editBilling } = this.state;
                          this.setState({ editBilling: !editBilling });
                        }}
                      >
                        <If condition={this.state.editBilling}>
                          <Then>
                            <EditAddressInfoCardForm
                              address={billingAddress}
                              onSubmit={(attributes) => {
                                this.updateClinic({
                                  billing_address_attributes: attributes
                                });
                              }}
                            />
                          </Then>
                          <Else>
                            <AddressInfoCard address={billingAddress} />
                          </Else>
                        </If>
                      </ClinicCard>

                    </div>
                  </div>

                </div>

                <div className="col-md-3">
                  {/* Right column */}
                  <AccessControl roles={AdminRoleDescriptions}>
                    <ClinicFeatures clinic={clinic} />
                  </AccessControl>
                  <ClinicHoursDisplay
                    hours={business_hours.regular}
                    clinicId={this.props.id}
                    editableRoles={editorRoles}
                  />
                  <ClinicActions
                    clinic={clinic}
                    toggleActivation={this.confirmActivateClinic}
                  />
                </div>

              </div>

            </div>
          </div>

        </Activity>
      </Page>
    );
  }

  hasLoadedClinic() {
    // Determine whether the clinic has fully loaded,
    // based on the presence of the clinic address:
    const { clinic = {} } = this.props;
    return clinic.address != null;
  }

  getClinic(id) {
    this.setState({
      activity: !this.hasLoadedClinic(),
      error: null
    });

    this.props.getClinic(id).then(() => {
      this.setState({ activity: false });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  updateClinic(attributes) {
    this.setState({
      activity: true,
      error: null
    });

    this.props.updateClinic(this.props.id, attributes).then(() => {
      this.setState({
        activity: false,
        editGeneral: false,
        editContact: false,
        editAddress: false,
        editBilling: false
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  activateClinic(active) {
    this.setState({
      activity: true,
      error: null
    });

    this.props.activateClinic(this.props.id, { active }).then(() => {
      this.setState({
        activity: false
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  confirmActivateClinic() {
    const { clinic } = this.props;
    const { active, name } = clinic || {};

    const message = active
      ? Strings.deactivateClinicModalMessage
      : Strings.activateClinicModalMessage;

    const title = active
      ? Strings.deactivateClinicModalTitle
      : Strings.activateClinicModalTitle;
    
    const confirmButtonTitle = active
      ? Strings.deactivateClinicModalConfirmButtonTitle
      : Strings.activateClinicModalConfirmButtonTitle;

    confirmation(Strings.formatString(message, name), {
      title,
      confirmButtonTitle,
      onConfirm: () => {
        this.activateClinic(!active);
      }
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { clinics = {}, users = {} } = state;
  const { params = {} } = ownProps;
  const { clinicId } = params;
  const clinic = clinics[clinicId] || {};
  const currentUser = users[getCurrentUserId()] || {};

  return {
    clinic,
    id: clinicId,
    currentUser
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClinic: id => {
      return dispatch(getClinicAsync(id));
    },
    updateClinic: (id, attributes) => {
      return dispatch(updateClinicAsync(id, attributes));
    },
    activateClinic: (id, attributes) => {
      return dispatch(activateClinicAsync(id, attributes));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClinicProfile)
);

import { createSelector } from 'reselect';
import { getCurrentClinicId } from '../../tokens';
import UserSelector from './users';

const getClinics = (state) => state.clinics;
const getClinicSearch = (state) => state.clinicPlayersSearch;
const getStats = (state) => state.clinicStatistics;
const getPatientsState = (state) => state.clinicPatients;
const getId = (_, { id }) => id;

const getStatistics = createSelector(
  [getId, getStats],
  (id, stats = {}) => {
    return stats[id];
  }
);

const getClinic = createSelector(
  [getId, getClinics],
  (id, clinics = {}) => {
    return clinics[id];
  }
);

const getCurrentClinic = createSelector(
  [getClinics],
  (clinics) => {
    return clinics[getCurrentClinicId()];
  }
);

const getPatients = createSelector(
  [getId, getPatientsState],
  (id, patients = {}) => {
    return patients[id];
  }
);

const getOngoingPatients = createSelector(
  [getPatients],
  (patients) => {
    return patients?.filter?.(patient => !!patient.ongoing_injury_date);
  }
);

const getUninjuredPatients = createSelector(
  [getPatients],
  (patients) => {
    return patients?.filter?.(patient => !patient.ongoing_injury_date);
  }
);

const getClinicianOngoingPatients = createSelector(
  [getOngoingPatients, UserSelector.getCurrentUser],
  (patients = [], user = {}) => {
    return patients?.filter(patient => (
      String(patient.current_treating_clinician_id) === String(user.id)
    ));
  }
);

const getSearchResults = createSelector(
  [getClinicSearch, getId],
  (search, id) => {
    return search[id]?.results;
  }
);

export default {
  getClinic,
  getCurrentClinic,
  getStatistics,
  getPatients,
  getUninjuredPatients,
  getOngoingPatients,
  getSearchResults,
  getClinicianOngoingPatients
};

import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import InvoicesTable from './invoices-table';
import ErrorBanner from '../errors/error-banner';
import AccessControl from '../access-control';
import CreateInvoiceModal from './create-invoice-modal';
import { InvoiceSelector } from '../../redux/selectors';
import { getInvoicesAsync } from '../../redux/thunks/clinic-invoices';
import { getCurrentUserId } from '../../tokens';
import { useMount } from '../../hooks';
import { AdminRoleDescriptions } from '../../utilities/user-roles';
import Strings from './lang';
import Icon from '../icon';

const CreateInvoiceButton = ({ clinicId, onCreate }) => {
  const [open, setOpen] = useState(false);
  const onClose = useCallback((invoice) => {
    setOpen(false);
    if (invoice && invoice.id) onCreate(invoice);
  }, [onCreate]);

  return (
    <AccessControl roles={AdminRoleDescriptions}>
      <>
        <button 
          type="button" 
          className="btn btn-sm btn-primary"
          onClick={() => setOpen(true)}
        >
          <Icon name="plus" />&nbsp;&nbsp;
          {Strings.createInvoiceTitle}
        </button>
        <CreateInvoiceModal 
          isOpen={open}
          clinicId={clinicId}
          onClose={onClose}
        />
      </>
    </AccessControl>
  );
};

const OUTSTANDING_FILTER_KEY = 'outstanding';

const OutstandingInvoices = ({
  invoices = [],
  clinicId,
  onInvoiceClick,
  getOutstandingInvoices
}) => {
  const [error, setError] = useState(null);

  useMount(() => {
    getOutstandingInvoices(clinicId).catch(error => {
      setError(
        Strings.formatString(
          Strings.invoicesLoadErrorMessage,
          error.message || Strings.unknownErrorMessage
        )
      );
    });
  });

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <CreateInvoiceButton 
          clinicId={clinicId} 
          onCreate={onInvoiceClick} 
        />
      </div>
      <ErrorBanner error={error} />
      {invoices.length ? (
        <InvoicesTable 
          invoices={invoices} 
          onInvoiceClick={onInvoiceClick}
        />
      ) : (
        <h2 className="text-center">
          {Strings.emptyOutstandingInvoicesMessage}
        </h2>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { users } = state;
  const invoices = InvoiceSelector.getOutstandingInvoices(state, ownProps);
  const currentUser = users[getCurrentUserId()];
  return { invoices, currentUser };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOutstandingInvoices: (clinicId) => {
      return dispatch(getInvoicesAsync(clinicId, OUTSTANDING_FILTER_KEY));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OutstandingInvoices);

import React, { useCallback, useRef, useState } from 'react';
import classnames from 'classnames';
import Popper from '../popper';
import { useUnmount } from '../../hooks';

const Tooltip = ({
  isOpen = false,
  type,
  enabled = true,
  trigger = 'hover',
  placement,
  offset,
  portal,
  delay = 0,
  className,
  content,
  onClose = () => {},
  children
}) => {
  const timeout = useRef();
  const [open, setOpen] = useState(isOpen);

  const openWithDelay = useCallback(() => {
    if (delay <= 0) {
      setOpen(true);
      return;
    }

    timeout.current = setTimeout(() => {
      if (timeout.current) {
        setOpen(true);
      }

      timeout.current = undefined;
    }, delay);
  }, [delay]);

  const onMouseLeave = useCallback(() => {
    if (timeout.current) {
      timeout.current = clearTimeout(timeout.current);
    }

    setOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  const child = React.cloneElement(React.Children.only(children), {
    ...(trigger === 'hover' ? {
      onMouseEnter: () => enabled && openWithDelay(),
      onMouseLeave: () => enabled && onMouseLeave()
    } : {})
  });

  useUnmount(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  });

  return (
    <Popper
      portal={portal}
      isOpen={isOpen || open}
      placement={placement}
      offset={offset}
      className="tooltip-container"
      onClose={handleClose}
      content={(
        <div className={classnames('tooltip-content', type, className)}>
          {content}
        </div>
      )}
    >
      {child}
    </Popper>
  );
};

export default Tooltip;

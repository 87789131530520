import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { FormComponent } from '../../../../forms';
import Card from '../../../card';
import { Diagnosis, isDiagnosisValid } from '../../../diagnosis';
import FollowUpActionPlan from '../follow-up-action-plan';
import { InitialManagement } from '../initial-management';
import RecoveryStages from '../recovery-stages';
import CommunicationActions from '../communication-actions';
import SharedStrings from '../lang';

class DiagnosisPlanOfManagementForm extends Component {
  constructor(props) {
    super(props);

    const { assessment = {}, type = 'acute' } = props;

    /* ref object to scroll invalid forms into view */
    this.formRefs = {
      diagnosis: React.createRef(),
      follow_up_action_plan: React.createRef(),
      initial_management: React.createRef(),
      recovery_protocol_stage: React.createRef(),
      actions: React.createRef()
    }; 

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateDiagnosis = this.onUpdate.bind(this, 'diagnosis');
    this.onUpdateFollowUp = this.onUpdate.bind(this, 'follow_up_action_plan');
    this.onUpdateInitialManagement = this.onUpdate.bind(this, 'initial_management');
    this.onUpdateRecoveryStages = this.onUpdate.bind(this, 'recovery_protocol_stage');
    this.onUpdateActions = this.onUpdate.bind(this, 'actions');

    const {
      diagnosis = {},
      follow_up_action_plan = {},
      initial_management = {},
      recovery_protocol_stage,
      actions = {}
    } = assessment;

    const {
      rts_stage, rtl_stage, rtw_stage, id
    } = recovery_protocol_stage || {};

    this.state = {
      diagnosis,
      follow_up_action_plan,
      initial_management,
      recovery_protocol_stage: {
        id, rts_stage, rtl_stage, rtw_stage
      },
      actions,
      validStates: {
        diagnosis: isDiagnosisValid(type, diagnosis),
        follow_up_action_plan: false,
        recovery_protocol_stage: !!recovery_protocol_stage,
        initial_management: type !== 'chronic',
        actions: false
      },
      submitted: false
    };
  }

  render() {
    const submitted = (this.props.submitted || this.state.submitted);
    return (
      <div>
        <h2>{SharedStrings.diagnosisAndPlanTitle}</h2>
        <FormComponent onSubmit={this.onSubmit}>
          <Card 
            title={SharedStrings.diagnosisHeader} 
            ref={this.formRefs.diagnosis}
          >
            <Diagnosis
              className="card card-form"
              type={this.props.type}
              diagnosis={this.state.diagnosis}
              submitted={submitted}
              onUpdate={this.onUpdateDiagnosis}
            />
          </Card>

          <Card 
            title={SharedStrings.followUpActionHeader} 
            ref={this.formRefs.follow_up_action_plan}
          >
            <FollowUpActionPlan
              className="card card-form"
              followUpAction={this.state.follow_up_action_plan}
              submitted={submitted}
              onUpdate={this.onUpdateFollowUp}
            />
          </Card>

          <If condition={this.props.type === 'chronic'}>
            <Then>
              <Card 
                title={SharedStrings.initialManagementHeader} 
                ref={this.formRefs.initial_management}
              >
                <InitialManagement
                  className="card card-form initial-management-form"
                  initialManagement={this.state.initial_management}
                  submitted={submitted}
                  onUpdate={this.onUpdateInitialManagement}
                />
              </Card>
            </Then>
          </If>

          <Card 
            title={SharedStrings.recoveryStagesHeader} 
            ref={this.formRefs.recovery_protocol_stage}
          >
            <RecoveryStages
              className="card card-form"
              stages={this.state.recovery_protocol_stage}
              canDischarge={this.props.dischargePermitted}
              submitted={submitted}
              onUpdate={this.onUpdateRecoveryStages}
            />
          </Card>

          <Card 
            title={SharedStrings.communicationActionHeader} 
            ref={this.formRefs.actions}
          >
            <CommunicationActions
              className="card card-form"
              communication={this.state.actions}
              onUpdate={this.onUpdateActions}
            />
          </Card>
          
          <div className="form-footer">
            <button type="submit" className="btn btn-primary">
              {SharedStrings.saveAndContinueButtonText}
            </button>
          </div>
        </FormComponent>
      </div>
    );
  }

  onUpdate(key, attributes, isValid) {
    const { validStates } = this.state;

    validStates[key] = isValid;

    this.setState({
      [key]: { ...attributes },
      validStates
    });
  }

  isValid() {
    const { validStates } = this.state;

    let valid = true;

    Object.keys(validStates).reverse().forEach(key => {
      if (!validStates[key]) {
        valid = false;
        this.formRefs[key].current.scrollIntoView(false);
      }
    });

    return valid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      this.onError();
      return;
    }

    const { 
      diagnosis, 
      follow_up_action_plan, 
      initial_management, 
      recovery_protocol_stage, 
      actions 
    } = this.state;

    const attributes = {
      diagnosis,
      follow_up_action_plan,
      recovery_protocol_stage_attributes: {
        ...recovery_protocol_stage
      },
      actions
    };

    if (this.props.type === 'chronic') {
      attributes.initial_management = initial_management;
    }

    this.props.onSubmit(attributes);
  }

  onError() {
    if (typeof this.props.onError === 'function') {
      this.props.onError();
    }
  }
}

DiagnosisPlanOfManagementForm.propTypes = {
  type: PropTypes.oneOf([
    'acute',
    'chronic'
  ]),
  assessment: PropTypes.object,
  onSubmit: PropTypes.func
};

export default DiagnosisPlanOfManagementForm;

const UPDATE_USER = 'UPDATE_USER';
const UPDATE_USER_POSITION = 'UPDATE_USER_POSITION';
const UPDATE_USER_CHILDREN = 'UPDATE_USER_CHILDREN';

const updateUser = (user) => ({
  type: UPDATE_USER,
  user
});

const updateUserPosition = (userId, position) => ({
  type: UPDATE_USER_POSITION,
  userId,
  position
});

const updateUserChildren = (userId, child) => ({
  type: UPDATE_USER_CHILDREN,
  userId,
  child
});

export {
  UPDATE_USER,
  UPDATE_USER_POSITION,
  UPDATE_USER_CHILDREN,
  updateUser,
  updateUserPosition,
  updateUserChildren
};

import { useRef } from 'react';
import uniqueId from '../utilities/unique-id';

export const useUniqueId = (value = null) => {
  const id = useRef(value);

  if (!id.current) {
    id.current = uniqueId();
  }

  return id.current;
};

import React, { Component } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { If, Then } from 'react-if';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import { getBatchAsync } from '../../redux/thunks/batches';
import { filterOnKeyPath } from '../../utilities/sort-keypath';
import { DISPLAY_DATE_FORMAT } from '../../dates/formats';
import { FormComponent, FormInput } from '../../forms';
import BagTagTable from './bag-tag-table';
import { CSVDownloadLink } from '../csv-exporter';
import { withRouter } from '../../utilities/router-utils';
import Strings from './lang';
import Icon from '../icon';

const BAGTAG_FILTER_KEYPATH = 'number';
const MIN_FILTER_AMOUNT = 10;

const generateCSVBatchFilename = (bagtagName = '', batchNumber = '') => {
  return `batch_${batchNumber}_`.concat(bagtagName.toLowerCase().replace(/\s+/g, '_').replace(/[^\w\s]/gi, ''));
};

class BagTag extends Component {
  constructor(props) {
    super(props);

    const { params = {}, batches = {} } = props;
    const { batchId = 0 } = params;
    const batch = batches[batchId] || [];

    this.onResetFilter = this.onResetFilter.bind(this);
    this.onRequestCSVData = this.onRequestCSVData.bind(this);

    this.state = {
      batchId,
      batch,
      activity: false,
      error: null,
      filterText: '',
      filteredBagTags: batch.bag_tags || []
    };
  }

  componentDidMount() {
    this._getBatch();
  }

  render() {
    const { batch = {}, filteredBagTags } = this.state;
    const { bag_tags = [] } = batch;
    return (
      <Page className="bag-tag">
        <div className="row">
          <div className="col-md-8">
            <h1 className="display">
              {batch.name || ''}
            </h1>
          </div>
        </div>
        
        <ErrorBanner error={this.state.error} />
        <Activity active={this.state.activity} static>
          <div className="row">
            <div className="col-md-8">
              <If condition={batch.batch_number !== undefined && batch.created_at !== undefined}>
                <Then>
                  <div className="bag-tag-batch">
                    <p>
                      <small>
                        {Strings.formatString(
                          Strings.batchMetadataText,
                          batch.batch_number || '',
                          dayjs(batch.created_at).format(DISPLAY_DATE_FORMAT)
                        )}
                      </small>
                    </p>
                    <If condition={bag_tags.length > MIN_FILTER_AMOUNT}>
                      <Then>
                        <FormComponent className="form bagtags-filter-form">
                          <label>
                            <span>{Strings.filterLabelText}</span>
                            <FormInput
                              className="form-control"
                              type="text"
                              inputProps={{
                                value: this.state.filterText,
                                onChange: this.onFilterInput.bind(this)
                              }}
                            />
                            <button type="submit" onClick={this.onResetFilter}>
                              <Icon name="xmark" />
                            </button>
                          </label>
                        </FormComponent>
                      </Then>
                    </If>  
                    <BagTagTable bagTags={filteredBagTags || []} />
                  </div>
                </Then>
              </If>
            </div>
            <div className="col-md-4">
              <div className="quick-actions">
                <h3>{Strings.actionsHeadingText}</h3>
                <div className="quick-actions-content">
                  <ul className="list-unstyled">
                    <li>
                      <CSVDownloadLink 
                        onRequest={this.onRequestCSVData} 
                        filename={generateCSVBatchFilename(batch.name, batch.batch_number)}
                        linkClassName="btn btn-default"
                      >
                        {Strings.exportCSVLinkText}
                      </CSVDownloadLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Activity>
          
      </Page>
    );
  }

  onFilterInput(e) {
    this.filterBagTags(e.target.value);
  }

  onResetFilter() {
    this.filterBagTags();
  }

  filterBagTags(filterText = '') {
    const { batch } = this.state;
    const { bag_tags = [] } = batch;
    const filteredBagTags = filterOnKeyPath(bag_tags, filterText, BAGTAG_FILTER_KEYPATH);
    this.setState({ filterText, filteredBagTags });
  }

  onRequestCSVData() {
    const { batch } = this.state;
    return new Promise((resolve) => {
      const data = (batch.bag_tags || []).map(tag => {
        return [tag.number];
      });

      resolve(data);
    });
  }

  _getBatch() {
    this.setState({
      activity: true,
      error: null
    });

    const { batchId } = this.state;

    this.props.getBatch(batchId).then(batch => {
      this.setState({
        activity: false,
        batch,
        filteredBagTags: batch.bag_tags || []
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }
}

const mapStateToProps = (state) => {
  const { batches = {} } = state;
  return { batches };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBatch: (batchId) => {
      return dispatch(getBatchAsync(batchId));
    }
  };
};

const ConnectedBagTag = connect(
  mapStateToProps,
  mapDispatchToProps
)(BagTag);

const RoutableBagTag = withRouter(ConnectedBagTag);

export default RoutableBagTag;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ImageCropper, ImageCropperOptions } from '../../../image-tools';
import Strings from './lang';
import {
  FormComponent,
  FormInputGroup
} from '../../../forms';
import log from '../../../utilities/log';

class RegistrationAboutLeaderForm extends Component {
  constructor(props) {
    super(props);

    const person = props.user.person || {};
    const { first_name = '', last_name = '' } = person;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateImage = this.onUpdateImage.bind(this);

    this.state = {
      submitted: false,
      firstName: first_name,
      lastName: last_name,
      imageFile: {},
      imageDataURI: ''
    };
  }

  render() {
    return (
      <FormComponent className="registration-about-form leader" onSubmit={this.onSubmit}>

        <ImageCropper 
          className="avatar-image-cropper"
          inputName="avatar-image"
          chooseButtonClassName="btn btn-default"
          cropButtonClassName="btn btn-primary"
          cropperOptions={ImageCropperOptions}
          onCrop={this.onUpdateImage}
          onFileTypeError={(type, validTypes) => {
            log('Invalid file type', type, validTypes);
          }}
        />

        <FormInputGroup
          className="form-group first-name-input-group"
          labelText={Strings.firstNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.firstName,
            onBlur: this.onUpdateInput.bind(this, 'firstName'),
            onChange: this.onUpdateInput.bind(this, 'firstName')
          }}
          inputValid={this.state.firstName.length > 0}
          messageText={Strings.firstNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group last-name-input-group"
          labelText={Strings.lastNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.lastName,
            onBlur: this.onUpdateInput.bind(this, 'lastName'),
            onChange: this.onUpdateInput.bind(this, 'lastName')
          }}
          inputValid={this.state.lastName.length > 0}
          messageText={Strings.lastNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.firstName.length
      && this.state.lastName.length;
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onUpdateImage(file, dataURI) {
    this.setState({
      imageFile: file,
      imageDataURI: dataURI
    });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      firstName,
      lastName,
      imageFile,
      imageDataURI
    } = this.state;

    const attributes = {
      person_attributes: {
        first_name: firstName,
        last_name: lastName,
        photo_base64: imageDataURI || '',
        photo_filename: imageFile.name || ''
      }
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

RegistrationAboutLeaderForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RegistrationAboutLeaderForm;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { createSessionAsync } from '../../redux/thunks/sessions';
import { getCurrentUserId } from '../../tokens';
import { getRememberedUsers, removeRememberedUser } from '../../utilities/email-storage';
import HTTPStatusCodes from '../../utilities/http-status-codes';
import { DashboardPaths, AuthenticationPaths, RegistrationPaths } from '../../paths';
import { Page } from '../page';
import { ErrorBanner, ErrorStrings } from '../errors';
import AuthenticationForm from './authentication-form';
import RememberedUsersAuthentication from './remembered-users-authentication';
import Activity from '../activity';
import { withRouter } from '../../utilities/router-utils';
import Strings from './lang';
import { RoleDescriptions, userHasRoleMatchingOneOfDescriptions } from '../../utilities/user-roles';

/**
 * To Disable any 'Remember Me' functionality (remembered users list and remember me button), 
 * set this variable to false,
 */
const REMEMBER_ME_ENABLED = true;
const CCMI_URL = 'https://completeconcussions.com/';

class Authentication extends Component {
  constructor(props) {
    super(props);

    const rememberedUsers = getRememberedUsers();

    this.onRemoveRememberedUser = this.onRemoveRememberedUser.bind(this);
    this.authenticate = this.authenticate.bind(this);

    this.state = {
      rememberedUsers,
      newLogin: rememberedUsers.length === 0,
      activity: false,
      error: null
    };
  }

  render() {
    return (
      <Page 
        hideNavigationBar
        showHeaderImage
        hideBannerIfClinicDeactivated
        className="authentication"
      >
        <div className="row">
          <div className="col-md-4 col-md-offset-4">
            <h1 className="display dot">{Strings.title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-md-offset-4 patient-registration-info">
            <small>
              {Strings.areYouAPatientText}&nbsp;
              <Link to={RegistrationPaths.index.link} className="semi-bold">
                {Strings.registerHereLinkText}
              </Link>
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-md-offset-4">
            <ErrorBanner error={this.state.error} />
            <Activity active={this.state.activity}>
              <If condition={!this.state.newLogin && REMEMBER_ME_ENABLED}>
                <Then>
                  <RememberedUsersAuthentication 
                    rememberedUsers={this.state.rememberedUsers} 
                    onRemoveUser={this.onRemoveRememberedUser}
                    onAuthenticate={this.authenticate}
                    onNewLogin={() => this.setState({ error: null, newLogin: true })}
                  />
                </Then>
                <Else>
                  <AuthenticationForm
                    displayRememberMe={REMEMBER_ME_ENABLED}
                    onSubmit={this.authenticate}
                  />
                </Else>
              </If>
            </Activity>
            <p className="authentication-sales">
              <small 
                dangerouslySetInnerHTML={{
                  // Set the link tags as strings and inject as HTML to avoid escaing:
                  __html: Strings.formatString(Strings.salesText, `<a href="${CCMI_URL}" target="_blank">`, '</a>')
                }}
              />
            </p>
          </div>
        </div>
      </Page>
    );
  }

  authenticate(attributes, rememberEmail = false) {
    this.setState({
      activity: true,
      error: null
    });

    this.props.authenticate(attributes, { rememberEmail }).then(() => {
      this.setState({ activity: false });
      this.next();
    }).catch(error => {
      let { message } = error;
      if (error.status === HTTPStatusCodes.Unauthorized) {
        message = ErrorStrings.error401Signin;
      }
      
      this.setState({
        activity: false,
        error: message
      });
    });
  }

  onRemoveRememberedUser(user) {
    if (removeRememberedUser(user)) {
      const rememberedUsers = getRememberedUsers();
      this.setState({
        error: null, 
        newLogin: rememberedUsers.length === 0,
        rememberedUsers
      });
    }
  }
  
  next() {
    const { location, router, currentUser } = this.props;
    const { nextPathname } = location?.state || {};

    if (!nextPathname && !this.isProfileComplete()) {
      router.push(RegistrationPaths.about.link, {
        profileIncomplete: true,
        nextPathname: currentUser.clinics?.length > 1
          ? AuthenticationPaths.clinics.link
          : DashboardPaths.index.link
      });
    } else if (currentUser.clinics && currentUser.clinics.length > 1) {
      router.push(AuthenticationPaths.clinics.link, { nextPathname });
    } else if (nextPathname) {
      router.push(nextPathname);
    } else {
      router.push(DashboardPaths.index.link);
    }
  }

  isProfileComplete() {
    const { currentUser } = this.props;
    
    const isClinician = userHasRoleMatchingOneOfDescriptions(
      currentUser, 
      [RoleDescriptions.Clinician, RoleDescriptions.Specialist, RoleDescriptions.IDTClinician]
    );

    if (
      (!currentUser?.person?.first_name || !currentUser?.person?.last_name)
      || (isClinician && !currentUser?.clinical_info?.profession)
    ) {
      return false;
    }

    return true;
  }
}

const mapStateToProps = (state) => {
  const id = getCurrentUserId();
  const { users } = state;
  return {
    currentUser: users[id] || {}
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (attributes, options) => {
      return dispatch(createSessionAsync(attributes, options));
    }
  };
};

const ConnectedAuthentication = connect(
  mapStateToProps,
  mapDispatchToProps
)(Authentication);

const RoutableAuthentication = withRouter(ConnectedAuthentication);

export default RoutableAuthentication;

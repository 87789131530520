import { Component } from 'react';
import PropTypes from 'prop-types';
import { mobileDetector } from '../../utilities/mobile-detector';

class MobileDetector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      platform: null
    };
  }

  componentDidMount() {
    const platform = mobileDetector();
    if (platform) {
      this.setState({
        isMobile: true,
        platform
      });
    }
  }

  render() {
    return this.renderChildren();
  }

  renderChildren() {
    const { children } = this.props;

    return children(this.state.isMobile, this.state.platform);
  }
}

MobileDetector.propTypes = {
  children: PropTypes.func
};

export default MobileDetector;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BaselineSection from './baseline-section';
import { sortAscending } from '../../../utilities/sort';
import Strings from './lang';

const getStats = (trials = []) => {
  const mappedTrials = trials.map((t, i) => ({ ...t, i }));
  const passedTrials = mappedTrials.filter(t => t.duration !== '' && t.duration >= 0 && t.failed === false);
  const fastestIndex = passedTrials.sort(sortAscending.bind(null, 'duration'))[0]?.i;

  let average = 0;
  
  if (passedTrials.length > 0) {
    average = (passedTrials.reduce((acc, trial) => {
      return acc + trial.duration;
    }, 0) / passedTrials.length) / 1000;
  }

  return { average, fastestIndex };
};

const BaselineTandemGait = ({
  data = {},
}) => {
  const {
    notes,
    not_done = false,
    trials = []
  } = data || {};

  const { average, fastestIndex } = useMemo(() => getStats(trials), [trials]);

  return (
    <BaselineSection
      className="baseline-tandem-gait"
      title={Strings.tandemGaitTitle}
      notes={notes}
      sectionCompleted={Object.keys(data || {}).length > 0 && !not_done}
    >
      <table className="table">
        <thead>
          <tr>
            <th className="no-action">{Strings.trialColumnLabel}</th>
            <th className="no-action">{Strings.durationSecondsColumnLabel}</th>
            <th className="no-action">{Strings.passFailColumnLabel}</th>
          </tr>
        </thead>
        <tbody>
          {trials.map((trial, i) => {
            const isFastest = fastestIndex === i;

            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td className={isFastest ? 'success' : null}>
                  {!isNaN(trial.duration) ? (trial.duration / 1000).toFixed(2) : '--'}{isFastest && ` (${Strings.fastestTimeLabel})`}
                </td>
                <td>
                  {trial.failed
                    ? <span className="error">{Strings.failedText}</span>
                    : <span className="success">{Strings.passedText}</span>}
                </td>
              </tr>
            );
          })}
          <tr className="totals">
            <td><strong>{Strings.averageLabelText}</strong></td>
            <td>
              <strong>{average?.toFixed(2)} {Strings.secondsLabel}</strong>
            </td>
            <td>--</td>
          </tr>
        </tbody>
      </table>
    </BaselineSection>
  );
};

BaselineTandemGait.propTypes = {
  data: PropTypes.object.isRequired
};

export default BaselineTandemGait;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FormComponent,
  FormInputGroup
} from '../../forms';

import Strings from './lang';

class BagTagsForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateName = this.onUpdateInput.bind(this, 'name');
    this.onUpdateSize = this.onUpdateInput.bind(this, 'size');

    this.state = {
      submitted: false,
      name: '',
      size: 1
    };
  }

  render() {
    return (
      <FormComponent className="bag-tags-form" onSubmit={this.onSubmit}>

        <FormInputGroup 
          required
          className="form-group bag-tags-name-input-group"
          labelText={Strings.batchNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.name,
            onBlur: this.onUpdateName,
            onChange: this.onUpdateName
          }}
          inputValid={this.state.name.length > 0}
          messageText={Strings.batchNameErrorMessageText}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <FormInputGroup
          required
          className="form-group bag-tags-size-input-group"
          labelText={Strings.batchSizeLabelText}
          inputType="number"
          inputProps={{
            className: 'form-control',
            value: this.state.size,
            onBlur: this.onUpdateSize,
            onChange: this.onUpdateSize
          }}
          inputValid={this.state.size > 0}
          messageText={Strings.batchSizeErrorMessageText}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.generateButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.name.length > 0
      && this.state.size > 0;
  }

  onUpdateInput(field, e) {
    const { value } = e.target;
    this.setState({
      [field]: value
    });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const { name, size } = this.state;
    const attributes = { name, size };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes).then(() => {
        this.setState({
          name: '',
          size: 1,
          submitted: false
        });
      });
    }
  }
}

BagTagsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default BagTagsForm;

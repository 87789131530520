import dayjs from 'dayjs';
import React from 'react';
import { DISPLAY_DATE_FORMAT } from '../../../dates/formats';
import Icon from '../../icon';
import AppointmentStatus from './appointment-status';
import { TreatmentTypeStrings } from '../utils';
import Strings from '../lang';

const AppointmentsTable = ({ claimId, blockId, appointments }) => {
  if (!appointments?.length) {
    return (
      <div className="text-muted">
        {Strings.noAppointmentsAddedMessage}
      </div>
    );
  }
  
  return (
    <table className="idt-appointments-table">
      <tbody>
        {appointments.map(appt => {
          const apptDate = dayjs(appt.appt_at);
          return (
            <tr key={appt.id}>
              <td>
                <div className="bold">
                  {apptDate.format(DISPLAY_DATE_FORMAT)}
                </div>
                <div className="text-muted font-size-semi-small">
                  <Icon name="clock" />&nbsp;
                  {apptDate.format('h:mm A')}
                </div>
              </td>
              <td>
                {appt.providers.map((p, i) => (
                  <div key={i}>{p.name}</div>
                ))}
              </td>
              <td>{TreatmentTypeStrings[appt.treatment_type]}{appt.virtual ? ` (${Strings.virtualApptLabel})` : ''}</td>
              <td>
                <AppointmentStatus
                  claimId={claimId}
                  blockId={blockId}
                  apptId={appt.id}
                  status={appt.status}
                  notes={appt.notes}
                />
              </td>
              <td>
                {appt.status === 'booked' && dayjs().isAfter(apptDate) && (
                  <Icon name="exclamation-triangle" className="error" />
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default AppointmentsTable;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserSelector } from '../../redux/selectors';
import { getCurrentClinicId } from '../../tokens';
import { userHasRoleMatchingOneOfDescriptions, userHasRoleRequiringTraining } from '../../utilities/user-roles';
import checkUserTrainingExpiry from '../../utilities/user-training-expiry';
import checkUserActive from '../../utilities/user-active';

export const useAccessControl = (
  roles, 
  {
    allowDeactivated = false,
    allowUntrained = false,
    allowIfClinicDeactivated = false 
  }
) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);

  const isAllowed = useMemo(() => {
    const currentClinicId = getCurrentClinicId();
    const userClinics = currentUser.clinics || [];
    const currentClinic = userClinics.length
      ? currentUser.clinics.find(clinic => (clinic || {}).id === currentClinicId) || {}
      : {};
    const isClinicDeactivated = currentClinic.active === false;
    const hasAllowedRole = userHasRoleMatchingOneOfDescriptions(currentUser, roles);
    const hasTrainingRole = userHasRoleRequiringTraining(currentUser);
    const isTrained = allowUntrained || !hasTrainingRole || checkUserTrainingExpiry(currentUser);
    const isActive = allowDeactivated || checkUserActive(currentUser);

    return hasAllowedRole
      && isTrained
      && isActive
      && (!isClinicDeactivated || allowIfClinicDeactivated);
  }, [allowDeactivated, allowIfClinicDeactivated, allowUntrained, currentUser, roles]);

  return isAllowed;
};

const AccessControl = ({
  roles = [],
  allowDeactivated = false,
  allowUntrained = false,
  allowIfClinicDeactivated = false,
  fallback = null,
  children
}) => {
  const permitted = useAccessControl(
    roles, 
    { 
      allowDeactivated, 
      allowUntrained, 
      allowIfClinicDeactivated 
    }
  );

  return permitted
    ? <>{children}</>
    : fallback;
};

export default AccessControl;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckboxInputGroup, FormInput } from '../../../../forms';
import Strings from './lang';

class TherapyManagement extends Component {
  render() {
    const { therapy } = this.props;
    return (
      <div className={this.props.className}>
        <label className="bold">{Strings.therapyLabel}</label>
        <CheckboxInputGroup
          className="form-group checkbox-inline"
          labelText={Strings.softTissueLabel}
          inputProps={{
            className: 'form-control',
            checked: therapy.soft_tissue || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'soft_tissue')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group checkbox-inline"
          labelText={Strings.spinalManipulativeLabel}
          inputProps={{
            className: 'form-control',
            checked: therapy.spinal_manipulative || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'spinal_manipulative')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group checkbox-inline"
          labelText={Strings.otherLabel}
          inputProps={{
            className: 'form-control',
            checked: therapy.other || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'other')
          }}
          inputValid
        />
        {therapy.other && (
          <FormInput 
            type="textarea" 
            className="form-control" 
            inputProps={{
              placeholder: Strings.otherTherapyLabel,
              value: therapy.other_therapy || '',
              onChange: this.onChangeInput.bind(this, 'other_therapy')
            }} 
          />
        )}
      </div>
    );
  }

  onCheckBoxUpdate(key, e) {
    const { checked } = e.target;
    this.props.onUpdate(key, checked);
  }

  onChangeInput(key, e) {
    const { value } = e.target;
    this.props.onUpdate(key, value);
  }
}

TherapyManagement.propTypes = {
  therapy: PropTypes.object,
  onUpdate: PropTypes.func
};

export default TherapyManagement;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import BaselineTestCardPropTypes from './baseline-test-card-proptypes';
import { FormComponent } from '../../../forms';
import { ForcePlate } from '../test-components';
import Card from '../../card';
import { AddNoteButton } from '../../buttons';
import HeaderStrings from '../lang';
import Strings from './lang';

class ForcePlateCard extends Component {
  constructor(props) {
    super(props);

    const { forcePlate = {} } = props;
    const { right_foot_cop = '', left_foot_cop = '' } = forcePlate;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdate = this.onUpdate.bind(this);

    this.state = {
      right_foot_cop,
      left_foot_cop,
      notes: forcePlate.notes || '',
      submitted: false,
      isValid: right_foot_cop !== '' && left_foot_cop !== ''
    };
  }

  render() {
    const { notes, submitted, ...rest } = this.state;
    return (
      <FormComponent onSubmit={this.onSubmit}>
        <Card 
          title={HeaderStrings.forcePlateHeader}
          actionButtons={(
            <AddNoteButton 
              note={this.state.notes}
              title={
                Strings.formatString(Strings.abStractNotesTitle, HeaderStrings.forcePlateHeader)
              }
              submitButtonTitle={Strings.addNoteButtonText}
              onSave={(notes) => {
                this.setState({ notes });
              }}
            />
          )}
        >
          <div className="card card-form">
            <p className="text-muted">{Strings.forcePlateInfoText}</p>
            <ForcePlate 
              initialState={{
                ...rest
              }}
              onUpdate={this.onUpdate}
              touched={submitted}
            />
            <If condition={typeof this.props.onSubmit === 'function'}>
              <Then>
                <div className="card form-footer">
                  <button type="submit" className="btn btn-primary">
                    {this.props.saveButtonText || Strings.saveButtonText}
                  </button>
                </div>
              </Then>
            </If>
          </div>
        </Card>
      </FormComponent>
    );
  }

  onUpdate(results, isValid) {
    this.setState({
      ...results,
      isValid
    });
  }

  isValid() {
    const { right_foot_cop, left_foot_cop, isValid } = this.state;
    return right_foot_cop !== '' && left_foot_cop !== '' && isValid;
  }

  getAttributes(validate = true) {
    if (validate) {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return false;
      }
    }

    const { right_foot_cop, left_foot_cop, notes } = this.state;

    const attributes = {
      force_plate_test: {
        right_foot_cop,
        left_foot_cop,
        notes
      }
    };

    return attributes;
  }

  onSubmit() {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ submitted: true });

      if (!this.isValid()) {
        return;
      }

      this.props.onSubmit(this.getAttributes(false));
    }
  }
}

ForcePlateCard.propTypes = {
  ...BaselineTestCardPropTypes,
  forcePlate: PropTypes.object
};

export default ForcePlateCard;

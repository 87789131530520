import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { FormComponent, DateInputGroup } from '../../forms';
import Modal from '../modal';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import { DISPLAY_DATE_FORMAT, INPUT_DATE_FORMAT } from '../../dates/formats';
import { updateInvoiceAsync } from '../../redux/thunks/clinic-invoices';
import Strings from './lang';

// eslint-disable-next-line no-magic-numbers
const getDefaultDueDate = () => dayjs().add(30, 'days').format(INPUT_DATE_FORMAT);

const CloseInvoiceForm = ({ submitting, onSubmit = () => { } }) => {
  const [dueDate, setDueDate] = useState(getDefaultDueDate);
  const [dateValid, setDateValid] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    const attributes = {
      status: 'closed',
      due_date: dueDate
    };

    onSubmit(attributes);
  }, [dueDate, onSubmit]);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <DateInputGroup
        required
        className="form-group"
        labelText={Strings.dueDateLabel}
        inputType="date"
        textInputProps={{
          className: 'form-control'
        }}
        dateInputProps={{
          className: 'form-control'
        }}
        initialValue={dueDate}
        minDate={dayjs()}
        touched={submitted}
        onUpdate={(value, isValid) => {
          setDueDate(value);
          setDateValid(isValid);
        }}
        messageText={Strings.formatString(
          Strings.minDateErrorMessage,
          dayjs().format(DISPLAY_DATE_FORMAT)
        )}
        messageClassName="alert alert-danger"
      />
      <button
        type="submit"
        className="btn btn-primary"
        disabled={submitting || !dateValid}
      >
        {Strings.submitButtonText}
      </button>
    </FormComponent>
  );
};

const CloseInvoiceModal = ({
  isOpen = false,
  clinicId,
  invoiceId,
  updateInvoice,
  onClose = () => { }
}) => {
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = useCallback((attributes) => {
    setActivity(true);

    updateInvoice(clinicId, invoiceId, attributes).then(() => {
      setActivity(false);
      onClose();
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [clinicId, invoiceId, onClose, updateInvoice]);

  return (
    <Modal
      blur
      static={activity}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header onClose={activity ? null : onClose}>
        <h2 className="bold">
          {Strings.closeInvoiceActionLabel}
        </h2>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">
          {Strings.closeInvoiceMessage}
        </p>
        <ErrorBanner error={error} />
        <Activity active={activity}>
          <CloseInvoiceForm
            submitting={activity}
            onSubmit={handleSubmit}
          />
        </Activity>
      </Modal.Body>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateInvoice: (clinicId, invoiceId, attributes) => {
      return dispatch(updateInvoiceAsync(clinicId, invoiceId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(CloseInvoiceModal);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { 
  FormComponent, FormInputGroup, LanguageInputGroup, DateInputGroup 
} from '../../forms';
import { RoleDescriptions } from '../../utilities/user-roles';
import AccessControl from '../access-control';
import Strings from './lang';

class EditTeamLeaderPersonalCardForm extends Component {
  constructor(props) {
    super(props);

    const { user = {} } = this.props;
    const person = user.person || {};
    const trainingDate = user.trained_at || '';

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateTrainingDate = this.onUpdateTrainingDate.bind(this);

    this.state = {
      submitted: false,
      firstName: person.first_name || '',
      lastName: person.last_name || '',
      language: person.language || 'en',
      trainingDate,
      trainingDateValid: dayjs(trainingDate).isValid()
    };
  }

  render() {
    return (
      <FormComponent className="card card-form" onSubmit={this.onSubmit}>
        <FormInputGroup
          className="form-group first-name-input-group"
          labelText={Strings.firstNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.firstName,
            onBlur: this.onUpdateInput.bind(this, 'firstName'),
            onChange: this.onUpdateInput.bind(this, 'firstName')
          }}
          inputValid={this.state.firstName.length > 0}
          messageText={Strings.firstNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group last-name-input-group"
          labelText={Strings.lastNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.lastName,
            onBlur: this.onUpdateInput.bind(this, 'lastName'),
            onChange: this.onUpdateInput.bind(this, 'lastName')
          }}
          inputValid={this.state.lastName.length > 0}
          messageText={Strings.lastNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <LanguageInputGroup
          className="form-group"
          labelText={Strings.languageLabelText}
          inputProps={{
            className: 'form-control',
            value: this.state.language
          }}
          onUpdate={language => {
            this.setState({ language });
          }}
          messageText={Strings.languageValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <AccessControl roles={[RoleDescriptions.SuperAdmin, RoleDescriptions.SalesAdmin]}>
          <DateInputGroup
            className="form-group"
            labelText={Strings.trainingDateLabelText}
            inputType="date"
            textInputProps={{
              className: 'form-control',
              placeholder: 'January 1, 2000'
            }}
            dateInputProps={{
              className: 'form-control'
            }}
            initialValue={this.state.trainingDate}
            onUpdate={this.onUpdateTrainingDate}
            messageText={Strings.trainingDateErrorMessage}
            messageClassName="alert alert-danger"
            required={this.state.trainingDate.length > 0}
            touched={this.state.submitted}
          />
        </AccessControl>

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onUpdateTrainingDate(trainingDate, trainingDateValid) {
    this.setState({
      trainingDate,
      trainingDateValid
    });
  }

  isValid() {
    return this.state.firstName.length > 0 
      && this.state.lastName.length > 0 
      && this.state.language.length 
      && (this.state.trainingDate.length === 0 
        || this.state.trainingDateValid);
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      firstName,
      lastName,
      language,
      trainingDate
    } = this.state;

    const attributes = {
      trained_at: trainingDate,
      person_attributes: {
        first_name: firstName,
        last_name: lastName,
        language
      }
    };

    this.props.onSubmit(attributes);
  }
}

EditTeamLeaderPersonalCardForm.propTypes = {
  user: PropTypes.object
};

export default EditTeamLeaderPersonalCardForm;

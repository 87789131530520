import React from 'react';
import { If, Then, Else } from 'react-if';
import { RegistrationPaths } from '../../paths';
import { ErrorBanner } from '../errors';
import { ClinicSearchControl } from '../clinic-search';
import Strings from './lang';
import Card from '../card';
import SearchStrings from '../clinic-search/lang';
import { AccountRetrievalModalButton } from '../clinic-patients';
import { showAlert } from '../alert-notifications';

const RegisteredStartTestCard = ({
  selectedUser = {},
  cardTitle,
  errorMessage,
  emptySearch,
  activeInjuryErrorText,
  clinicId,
  onSearch,
  onError,
  onClear,
  searchButtonText
}) => {
  const activeInjuryId = selectedUser.active_injury_id || 0;

  return (
    <Card title={cardTitle}>

      <div className="card card-show">

        <div className="card-row">
          <p className="text-muted">{Strings.registeredPatientInfoText}</p>
        </div>

        <If condition={errorMessage !== null}>
          <Then>
            <ErrorBanner error={errorMessage} />
          </Then>
          <Else>

            <If condition={emptySearch === true}>
              <Then>
                <p className="alert alert-info">
                  {SearchStrings.searchResultsEmptyText}
                </p>
              </Then>
              <Else>
                <If condition={activeInjuryId > 0}>
                  <Then>
                    <p className="alert alert-info">
                      {activeInjuryErrorText}
                    </p>
                  </Then>
                </If>
              </Else>
            </If>

          </Else>
        </If>

        <ClinicSearchControl
          className="start-test-search"
          clinicId={clinicId}
          onSearch={onSearch}
          onError={onError}
          onClear={onClear}
          searchButtonText={searchButtonText}
        />

        <div className="card-row">
          <p  
            dangerouslySetInnerHTML={{
              __html: Strings.formatString(
                Strings.newPatientsInfoText,
                `${window.location.hostname}${RegistrationPaths.index.link}`
              )
            }} 
          />
        </div>

        <div className="card-row right">
          <AccountRetrievalModalButton
            className="edit-button"
            clinicId={clinicId}
            onSuccess={(message) => {
              showAlert('success', {
                autoDismiss: 5000,
                dismissable: true,
                message
              });
            }}
          >
            {Strings.forgotAccountLinkText}
          </AccountRetrievalModalButton>
        </div>

      </div>
    </Card>
  );
};

export default RegisteredStartTestCard;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllClinicsStatisticsAsync } from '../../redux/thunks/all-clinics-statistics';
import { Page } from '../page';
import { ErrorBanner } from '../errors';
import Activity from '../activity';
import ClinicStats from './clinic-stats';
import Strings from './lang';

class AllClinicsStats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activity: false,
      error: null
    };
  }

  componentDidMount() {
    this.getAllClinicsStatistics();
  }

  render() {
    return (
      <Page>
        <div className="row">
          <div className="col-md-12">
            <h1 className="display">{Strings.title}</h1>
            <h2>{Strings.allStatsSubtitle}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ErrorBanner error={this.state.error} />
            <Activity active={this.state.activity}>
              <ClinicStats stats={this.props.allClinicsStatistics} showTitle={false} />
            </Activity>
          </div>
        </div>
      </Page>
    );
  }

  getAllClinicsStatistics() {
    this.setState({
      activity: true,
      error: null
    });

    this.props.getAllClinicsStatistics().then(() => {
      this.setState({
        activity: false
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }
}

const mapStateToProps = (state) => {
  const { allClinicsStatistics = {} } = state;
  return { allClinicsStatistics };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllClinicsStatistics: () => {
      return dispatch(getAllClinicsStatisticsAsync());
    }
  };
};

const ConnectedAllClinicsStats = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllClinicsStats);

export default ConnectedAllClinicsStats;

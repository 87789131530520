import React, { useMemo } from 'react';
import { sortAscending } from '../../utilities/sort';
import DanaBatteryDetails from '../dana-tests/battery-details/battery-details';
import Strings from './lang';

const sortTests = (tests = []) => {
  return tests.sort(sortAscending.bind(null, 'test_order'));
};

const DanaBatteryResultsSection = ({ battery }) => {
  const tests = useMemo(() => sortTests(battery?.tests), [battery]);

  if (!battery) return null;

  return (
    <div className="baseline-section">
      <h2 className="bold">{Strings.neurocognitiveTestSectionTitle}</h2>
      <div>
        <DanaBatteryDetails tests={tests} />
      </div>
    </div>
  );
};

export default DanaBatteryResultsSection;

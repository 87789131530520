import React from 'react';
import PropTypes from 'prop-types';
import AccessControl from '../access-control';
import { ClinicTypeCardRow } from '../clinic-type-category';
import { 
  AdminRoleDescriptions, 
  makeRoleDescription, 
  RoleDescriptions, 
  RoleNames, 
  RoleResourceTypes 
} from '../../utilities/user-roles';
import Strings from './lang';
import ClinicServicesCardDisplay from '../clinic-services/clinic-services-card-display';

const makeImpactCodeViewerRoles = (clinicId) => ([
  ...AdminRoleDescriptions,
  makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Clinician, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.Specialist, RoleResourceTypes.Clinic, clinicId),
  makeRoleDescription(RoleNames.ClinicOwner, RoleResourceTypes.Clinic, clinicId)
]);

const GeneralInfoCard = ({
  clinic = {}
}) => (
  <div className="card card-show">
    <div className="card-row image-row">
      <img src={clinic.photo_url} alt="clinic" className="img-responsive" />
    </div>
    <div className="card-row">
      <label>{Strings.nameLabelText}:</label>
      <span>{clinic.name}</span>
    </div>
    <ClinicTypeCardRow category={clinic.category} />
    <AccessControl roles={[RoleDescriptions.SalesAdmin, RoleDescriptions.SuperAdmin]}>
      <ClinicServicesCardDisplay services={clinic.services} />
    </AccessControl>
    <AccessControl roles={makeImpactCodeViewerRoles(clinic.id)}>
      <div className="card-row">
        <label>{Strings.impactCodeLabelText}:</label>
        <span>{clinic.impact_code}</span>
      </div>
    </AccessControl>
    <div className="card-row">
      <label>{Strings.bioLabelText}:</label>
      <span className="multi-line">{clinic.bio || null}</span>
    </div>
  </div>
);

GeneralInfoCard.propTypes = {
  clinic: PropTypes.object.isRequired
};

export default GeneralInfoCard;

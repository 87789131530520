import React, { useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import groupItems from '../../../utilities/group-items';
import ChecklistItem from '../../helpers/checklist-item';
import symptomLabels from '../../symptoms/symptom-labels';
import Strings from './lang';

const NUM_SYMPTOM_GROUPS = 2; // Should be a factor of 12 to fit the grid
const MAX_COLS = 12;

const groupSymptomKeys = (symptoms = {}) => {
  const sortedKeys = Object.keys(symptoms).filter(key => { return key !== 'other'; }).sort();
  return groupItems(sortedKeys, NUM_SYMPTOM_GROUPS);
};

const filterCheckedSymptoms = (symptoms = {}) => {
  return Object.keys(symptoms).reduce((filtered, key) => {
    if (symptoms[key] === true) {
      return { ...filtered, [key]: symptoms[key] };
    }
      
    return filtered;
  }, {});
};

const PostInjuryInitialSymptoms = ({
  symptoms = {}
}) => {
  const groupedKeys = useMemo(() => groupSymptomKeys(filterCheckedSymptoms(symptoms)), [symptoms]);
  const hasSymptoms = useMemo(() => Object.keys(symptoms).length > 0, [symptoms]);

  return (
    <div className="post-injury-section post-injury-initial-symptoms">

      <div className="row">
        <div className="col-md-12">
          <h2 className="bold">{Strings.initialSymptomsTitle} <small>{Strings.initialSymptomsSubtitle}</small></h2>
        </div>
      </div>

      <If condition={hasSymptoms}>
        <Then>
          <div>
            <div className="post-injury-group">
              <div className="row">
                {groupedKeys.map((group, index) => {
                  return (
                    <div key={index} className={`col-md-${MAX_COLS / NUM_SYMPTOM_GROUPS}`}>
                      <ul className="list-unstyled">
                        {group.map(key => {
                          return (
                            <ChecklistItem key={key} checked={symptoms[key] === true}>
                              {symptomLabels[key]}
                            </ChecklistItem>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
            <If condition={!!symptoms.other}>
              <Then>
                <div className="post-injury-group">
                  <div className="row">
                    <div className="col-md-12">
                      <strong>{Strings.otherText}:</strong> {symptoms.other}
                    </div>
                  </div>
                </div>
              </Then>
            </If>
          </div>
        </Then>
        <Else>
          <div className="post-injury-group">
            <div className="row">
              <div className="col-md-12">
                {Strings.noInitialSymptomsProvided}
              </div>
            </div>
          </div>
        </Else>
      </If>
    </div>
  );
};

export default PostInjuryInitialSymptoms;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Icon from '../icon';
import {
  getCurrentUserId,
  getCurrentClinicId,
  setCurrentClinicId
} from '../../tokens';
import Strings from './lang';

class ClinicSelectList extends Component {
  constructor(props) {
    super(props);

    const { currentUser = {} } = props;
    const clinicId = getCurrentClinicId() || 0;
    const clinics = currentUser.clinics || [];

    this.state = {
      clinics,
      clinicId
    };
  }

  render() {
    const clinics = this.filteredClinics();
    if (clinics.length) {
      return (
        <ul className="clinic-select-list">
          {clinics.map(clinic => (
            <li 
              key={clinic.id} 
              className={classNames({
                active: clinic.id === this.state.clinicId
              })}
              onClick={() => this.onSelectClinic(clinic.id)}
            >
              <Icon name="house-medical" fixedWidth className="primary" />
              <span>{clinic.name} {clinic.active === false 
                && <small className="error"><i>{Strings.deactivatedText}</i></small>}
              </span>
            </li>
          ))}
        </ul>
      );
    }
      
    return null;
  }

  filteredClinics() {
    const { clinics, clinicId } = this.state;
    const { excludeCurrentClinic = false } = this.props;

    if (excludeCurrentClinic) {
      return clinics.filter(clinic => {
        return parseInt(clinic.id, 10) !== parseInt(clinicId, 10);
      });
    }
      
    return [...clinics];
  }

  onSelectClinic(clinicId) {
    this.setState({ clinicId });
    setCurrentClinicId(clinicId);

    if (typeof this.props.onSelectClinic === 'function') {
      this.props.onSelectClinic(clinicId);
    }
  }
}

ClinicSelectList.propTypes = {
  excludeCurrentClinic: PropTypes.bool,
  onSelectClinic: PropTypes.func
};

const mapStateToProps = (state) => {
  const id = getCurrentUserId();
  const { users } = state;
  return {
    currentUser: users[id] || {}
  };
};

export default connect(
  mapStateToProps
)(ClinicSelectList);

import React, {
  useState, useCallback, useMemo, useEffect 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { If, Then } from 'react-if';
import { 
  LineItemsTable, 
  getLineItemsSortKey, 
  setLineItemsTableSortKey 
} from './line-items';
import { Page } from '../page';
import Activity from '../activity';
import AccessControl from '../access-control';
import InvoiceActions from './invoice-actions';
import InvoiceHeader from './invoice-header';
import InvoicePrintHeader from './invoice-print-header';
import InvoiceNote from './invoice-note';
import { ErrorBanner } from '../errors';
import { InvoicePaymentBanner } from '../payments';
import { getInvoiceAsync, deleteInvoiceLineItemAsync } from '../../redux/thunks/clinic-invoices';
import { getClinicAsync } from '../../redux/thunks/clinics';
import { InvoiceSelector, UserSelector } from '../../redux/selectors';
import { makeInvoicePaymentRoles } from './utilities';
import { Money } from '../../utilities/currency';
import { useMount } from '../../hooks';
import { confirmation } from '../confirmation';
import { showAlert } from '../alert-notifications';
import Strings from './lang';
import HTTPStatusCodes from '../../utilities/http-status-codes';

const ClinicInvoice = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const invoice = useSelector(state => (
    InvoiceSelector.getClinicInvoice(state, params)
  ));
  const clinic = useSelector(state => (
    state.clinics[params?.clinicId] || {}
  ));

  const [sortKey, setSortKey] = useState(getLineItemsSortKey());
  const [activity, setActivity] = useState(true);
  const [clinicError, setClinicError] = useState(null);
  const [invoiceError, setInvoiceError] = useState(null);
  const { 
    title = '', 
    status = '', 
    amount,
    total,
    owing,
    taxes,
    currency,
    payments,
  } = invoice;
  const invoiceNotFound = !activity && !Object.keys(invoice).length;
  const balance = useMemo(() => Money.unformat(owing, currency), [currency, owing]);

  const loadClinic = useCallback(() => {
    // do not reload the clinic if 
    // it is already provided
    if (clinic && clinic.id) return;

    dispatch(getClinicAsync(params?.clinicId)).catch(error => {
      setClinicError(
        Strings.formatString(
          Strings.clinicLoadErrorMessage,
          error.message || Strings.unknownErrorMessage
        )
      );
    });
  }, [clinic, dispatch, params?.clinicId]);

  const loadInvoice = useCallback(() => {
    if (!params?.clinicId) {
      setActivity(false);
      return;
    }

    setActivity(true);

    dispatch(getInvoiceAsync(params?.clinicId, params?.invoiceId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setInvoiceError(
        Strings.formatString(
          Strings.invoiceLoadErrorMessage,
          error.message || Strings.unknownErrorMessage
        )
      );
    });
  }, [dispatch, params?.clinicId, params?.invoiceId]);

  const onUpdateSortKey = useCallback((sortKey) => {
    setLineItemsTableSortKey(sortKey);
    setSortKey(sortKey);
  }, []);

  const onDeleteLineItem = useCallback((lineItem) => {
    dispatch(deleteInvoiceLineItemAsync(params?.clinicId, params?.invoiceId, lineItem)).then(() => {
      showAlert('success', {
        autoDismiss: 3000,
        dismissable: true,
        message: Strings.deleteLineItemSuccessMessage
      });
    }).catch(error => {
      showAlert('error', {
        autoDismiss: 5000,
        dismissable: true,
        message: Strings.formatString(
          Strings.deleteLineItemErrorMessage,
          error.message
        )
      });
    });
  }, [dispatch, params?.clinicId, params?.invoiceId]);

  // Load the invoice and the clinic
  // only when mounted
  useMount(() => {
    loadClinic();
  });

  useEffect(() => {
    loadInvoice();
  }, [loadInvoice]);

  return (
    <Page status={invoiceNotFound && HTTPStatusCodes.NotFound}>
      <InvoicePrintHeader clinic={clinic} />
      <Activity 
        static 
        titleComponent={(
          <h1>
            {Strings.formatString(
              Strings.invoiceLoadingTitle,
              title || ''
            )}
          </h1>
        )}
        active={activity}
      >
        <div className="row">
          <div className="col-md-12">
            <ErrorBanner error={invoiceError} />
            <ErrorBanner error={clinicError} />
            <div className="invoice-title-row">
              <h1 className="display uppercase">
                {Strings.invoiceTitle}
              </h1>
              <InvoiceActions 
                currency={currency}
                invoiceStatus={status}
                clinicId={params?.clinicId}
                invoiceId={params?.invoiceId}
                balance={balance}
              />
            </div>
            <InvoiceHeader 
              clinic={clinic}
              invoice={invoice}
            />
            <InvoiceNote 
              invoice={invoice}
              clinicId={params?.clinicId}
              currentUser={currentUser}
            />
            <LineItemsTable 
              invoiceStatus={status}
              sortKey={sortKey}
              clinicId={params?.clinicId}
              invoiceId={params?.invoiceId}
              subTotal={amount}
              total={total}
              balance={owing}
              taxes={taxes}
              currency={currency}
              payments={payments}
              onUpdateSortKey={onUpdateSortKey}
              onDeleteItem={(lineItem) => {
                confirmation(Strings.deleteLineItemConfirmMessage, {
                  title: Strings.deleteLineItemConfirmTitle,
                  confirmButtonTitle: Strings.deleteLineItemConfirmButton,
                  onConfirm: () => onDeleteLineItem(lineItem)
                });
              }}
            />
            <If condition={!!status && balance > 0 && status !== 'paid' && !!clinic}>
              <Then>
                <AccessControl 
                  allowUntrained
                  roles={makeInvoicePaymentRoles(params?.clinicId)}
                >
                  <InvoicePaymentBanner 
                    balance={owing}
                    currency={currency}
                    clinicId={params?.clinicId}
                    invoiceId={params?.invoiceId}
                    user={currentUser}
                  />
                </AccessControl>
              </Then>
            </If>
          </div>
        </div>
      </Activity>
    </Page>
  );
};

export default ClinicInvoice;

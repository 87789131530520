import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { If, Then } from 'react-if';
import { Link } from 'react-router-dom';
import { NotificationModal } from '../notifications';
import LogoutLink from '../authentication/logout-link';
import { LockoutLink } from '../lockout';
import { ClinicSelectList } from '../clinic-select';
import { DashboardPaths, ClinicPaths } from '../../paths';
import replaceLinkParams from '../../utilities/replace-link-params';
import NavigationDropdown from './navigation-dropdown';
import NavigationDropdownHeader from './navigation-dropdown-header';
import DemoSiteLoginsButton from './demo-site-logins-button';
import Strings from './lang';
import Icon from '../icon';

const NavigationLoggedInDropdown = ({
  currentUser = {}, 
  currentOrganization = {},
  location = {}
}) => {
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const isCurrentRoute = useCallback((path) => {
    return location.pathname === path;
  }, [location.pathname]);

  const header = useCallback((expanded) => (
    <NavigationDropdownHeader
      user={currentUser}
      organization={currentOrganization}
      expanded={expanded}
    />
  ), [currentOrganization, currentUser]);

  return (
    <>
      <NavigationDropdown header={header}>
        <li className="navigation-dropdown-clinics">
          <ClinicSelectList 
            excludeCurrentClinic 
            onSelectClinic={() => {
              window.location = DashboardPaths.index.link;
            }} 
          />
        </li>

        <If condition={(currentUser.unread_notification_count || 0) > 0}>
          <Then>
            <li>
              <button type="button" onClick={() => setNotificationsOpen(true)}>
                <Icon name="bell" fixedWidth />
                <div className="notification-link">
                  <span>{Strings.notificationsLinkText}</span>
                  <span className="badge">{currentUser.unread_notification_count}</span>
                </div>
              </button>
            </li>
          </Then>
        </If>

        <li 
          className={classnames({
            active: isCurrentRoute(replaceLinkParams(ClinicPaths.userProfile.link, {
              userId: currentUser.id,
              clinicId: currentOrganization.id || 0
            }))
          })}
        >
          <Link 
            to={replaceLinkParams(ClinicPaths.userProfile.link, {
              userId: currentUser.id,
              clinicId: currentOrganization.id || 0
            })}
          >
            <Icon name="gear" fixedWidth />
            {Strings.accountSettingsLinkText}
          </Link>
        </li>

        <li>
          <LockoutLink />
        </li>

        <li>
          <DemoSiteLoginsButton
            user={currentUser}
            clinic={currentOrganization}
          />
        </li>

        <li>
          <LogoutLink className="red" />
        </li>
      </NavigationDropdown>
      <NotificationModal 
        isOpen={notificationsOpen}
        onClose={() => setNotificationsOpen(false)}
      />
    </>
  );  
};

export default NavigationLoggedInDropdown;

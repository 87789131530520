import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CheckboxInputGroup } from '../../../../forms';
import Strings from './lang';

const labels = {
  body: {
    front: Strings.loiFrontLabel, 
    rear: Strings.loiBackLabel,
    left_side: Strings.loiLeftSideLabel,
    right_side: Strings.loiRightSideLabel
  },
  head: {
    right_frontal: Strings.loiRightFrontalLabel,
    left_frontal: Strings.loiLeftFrontalLabel,
    right_temporal: Strings.loiRightTemporalLabel,
    left_temporal: Strings.loiLeftTemporalLabel,
    right_parietal: Strings.loiRightParietalLabel,
    left_parietal: Strings.loiLeftParietalLabel,
    right_occipital: Strings.loiRightOccipitalLabel,
    left_occipital: Strings.loiLeftOccipitalLabel,
    crown: Strings.loiCrownLabel
  }
};

class LocationOfImpactInputGroup extends Component {
  render() {
    return (
      <div className={classnames('location-impact-group', this.props.className)}>
        <label className="bold">{this.props.labelText}</label>
        {this.renderImpactLocationInputs()}
      </div>
    );
  }

  renderImpactLocationInputs() {
    const { locationValues = [], location, onChange = () => {} } = this.props;
    const locationLabels = labels[location];
    return Object.keys(locationValues).map((key, index) => {
      return (
        <CheckboxInputGroup
          key={index}
          className="form-group checkbox-inline"
          labelText={locationLabels[key]}
          inputProps={{
            className: 'form-control',
            checked: locationValues[key],
            onChange: onChange.bind(this, key)
          }}
          inputValid
        />
      );
    });
  }
}

LocationOfImpactInputGroup.propTypes = {
  className: PropTypes.string,
  labelText: PropTypes.string,
  locationValues: PropTypes.object,
  onChange: PropTypes.func
};

export default LocationOfImpactInputGroup;

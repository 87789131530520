import React, { useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import Store from '../../redux/store';
import { UserSelector } from '../../redux/selectors';
import { DashboardPaths } from '../../paths';
import { showSentryFeedbackDialog } from '../../utilities/sentry';
import { AdminAndClinicRoleDescriptions, userHasRoleMatchingOneOfDescriptions } from '../../utilities/user-roles';
import Strings from './lang';
import Icon from '../icon';

const getIsClinicUser = () => {
  const user = UserSelector.getCurrentUser(Store.getState());
  return user 
    ? userHasRoleMatchingOneOfDescriptions(user, AdminAndClinicRoleDescriptions)
    : false;
};

const ErrorView = ({ eventId, resetError }) => {
  const isClinicUser = useMemo(getIsClinicUser, []);

  const onSubmitFeedback = useCallback(() => {
    showSentryFeedbackDialog(eventId, {
      title: Strings.errorFeedbackTitle,
      subtitle: Strings.errorFeedbackSubtitle,
      subtitle2: Strings.errorFeedbackSubtitle2,
      labelSubmit: Strings.errorFeedbackSubmitButton
    });
  }, [eventId]);

  return (
    <div className="error-boundary">
      <h1 className="error bold">
        <Icon name="circle-xmark" /> {Strings.genericTitle}
      </h1>
      <p className="bold">{Strings.errorSubTitle}</p>
      <p className="text-muted">{Strings.errorDescription}</p>
      <div className="actions">
        <div>
          {isClinicUser && (
            <a
              href={DashboardPaths.index.link}
              className="btn btn-sm btn-default"
              style={{ marginRight: 15 }}
            >
              {Strings.actionHome}
            </a>
          )}
          <button
            type="button"
            className="btn btn-sm btn-default"
            onClick={resetError}
          >
            {Strings.actionRefresh}
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={onSubmitFeedback}
          >
            {Strings.actionSubmitFedback}
          </button>
        </div>
      </div>
    </div>
  );
};

const ErrorBoundary = ({ children }) => {
  const fallback = useCallback((props) => <ErrorView {...props} />, []);
  
  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { RadioInputGroup, FormInput, DateInputGroup } from '../../../../forms';
import TherapyManagement from './therapy-management';
import RehabManagement from './rehab-management';
import DateStrings from '../../../../dates/lang';
import Strings from './lang';

const defaultWorkSchool = {
  status: '',
  explanation: ''
};

const defaultExercise = {
  restrictions: '',
  explanation: ''
};

const defaultTherapy = {
  soft_tissue: false,
  spinal_manipulative: false,
  other: false,
  other_therapy: ''
};

const defaultRehab = {
  vestibular: false,
  cervical_spine: false,
  visual_tracking: false,
  sport_specific: false,
  neurocognitive: false,
  explanation: ''
};

class InitialManagement extends Component {
  constructor(props) {
    super(props);

    const { initialManagement = {} } = props;
    const {
      reevaluation_date = '',
      work_school = {},
      exercise = {},
      therapy = {},
      rehab = {}
    } = initialManagement;

    this.onUpdateReEvaluationDate = this.onUpdateReEvaluationDate.bind(this);

    this.state = {
      initial_management: {
        reevaluation_date,
        work_school: { ...defaultWorkSchool, ...work_school },
        exercise: { ...defaultExercise, ...exercise },
        therapy: { ...defaultTherapy, ...therapy },
        rehab: { ...defaultRehab, ...rehab }
      },
      dateValid: reevaluation_date !== ''
    };
  }

  componentDidMount() {
    this._callOnUpdate();
  }

  render() {
    const { initial_management } = this.state;
    return (
      <div className={this.props.className}>
        <div className="initial-management-row">
          <RadioInputGroup
            titleLabelText={Strings.workSchoolLabel}
            titleLabelProps={{
              className: 'bold'
            }}
            className="form-group"
            radioLabels={[Strings.workSchoolFullOptionLabel, Strings.workSchoolModifiedOptionLabel]}
            radioValues={['full', 'modified']}
            initialValue={initial_management.work_school.status}
            inputProps={{
              className: 'form-control'
            }}
            required={false}
            inputValid
            onUpdate={(value) => this.onUpdateValue('work_school', 'status', value)}
          />

          <FormInput 
            type="textarea" 
            className="form-control" 
            inputProps={{
              rows: 5,
              placeholder: Strings.explanationLabel,
              value: initial_management.work_school.explanation,
              onChange: this.onChangeInput.bind(this, 'work_school', 'explanation')
            }} 
          />
        </div>

        <div className="initial-management-row">
          <RadioInputGroup
            titleLabelText={Strings.exerciseLabel}
            titleLabelProps={{
              className: 'bold'
            }}
            className="form-group"
            radioLabels={[Strings.exerciseRestritionsLabel, Strings.exerciseNoRestrictionsLabel]}
            radioValues={['true', 'false']}
            initialValue={initial_management.exercise.restrictions}
            inputProps={{
              className: 'form-control'
            }}
            required={false}
            inputValid
            onUpdate={(value) => this.onUpdateValue('exercise', 'restrictions', value)}
          />

          <FormInput 
            type="textarea" 
            className="form-control" 
            inputProps={{
              rows: 5,
              placeholder: Strings.explanationLabel,
              value: initial_management.exercise.explanation,
              onChange: this.onChangeInput.bind(this, 'exercise', 'explanation')
            }} 
          />
        </div>

        <TherapyManagement
          className="initial-management-row"
          therapy={initial_management.therapy}
          onUpdate={(subkey, value) => this.onUpdateValue('therapy', subkey, value)}
        />

        <RehabManagement
          className="initial-management-row"
          rehab={initial_management.rehab}
          onUpdate={(subkey, value) => this.onUpdateValue('rehab', subkey, value)}
        />

        <div className="initial-management-row">
          <DateInputGroup
            className="form-group"
            labelText={Strings.reEvaluationDateLabel}
            labelProps={{
              className: 'bold'
            }}
            inputType="date"
            textInputProps={{
              className: 'form-control',
              placeholder: DateStrings.placeholder
            }}
            dateInputProps={{
              className: 'form-control'
            }}
            initialValue={initial_management.reevaluation_date}
            minDate={dayjs()}
            onUpdate={this.onUpdateReEvaluationDate}
            messageText={Strings.invalidDateError}
            messageClassName="alert alert-danger"
            required={false}
            touched={this.props.submitted}
          />
        </div>

      </div>
    );
  }

  isValid() {
    const { initial_management } = this.state;

    if (initial_management.reevaluation_date !== '' && !this.state.dateValid) {
      return false;
    }

    return true;
  }

  onChangeInput(key, subKey, e) {
    const { value } = e.target;
    this.onUpdateValue(key, subKey, value);
  }

  onUpdateValue(key, subKey, value) {
    const { initial_management } = this.state;
    initial_management[key][subKey] = value;
    this.setState({
      initial_management
    }, this._callOnUpdate.bind(this));
  }

  onUpdateReEvaluationDate(date, dateValid) {
    const { initial_management } = this.state;
    initial_management.reevaluation_date = date;
    this.setState({
      initial_management,
      dateValid
    }, this._callOnUpdate.bind(this));
  }

  _callOnUpdate() {
    if (typeof this.props.onUpdate === 'function') {
      this.props.onUpdate(this.state.initial_management, this.isValid());
    }
  }
}

InitialManagement.propTypes = {
  initialManagement: PropTypes.object,
  submitted: PropTypes.bool,
  onUpdate: PropTypes.func
};

export default InitialManagement;

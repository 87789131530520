import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import dayjs from 'dayjs';
import { DEFAULT_USER_LANGUAGE } from '../../utilities/localization';
import Strings from './lang';
import AccessControl from '../access-control';
import {
  FormComponent,
  FormInputGroup,
  FormListInputGroup,
  LanguageInputGroup,
  DateInputGroup
} from '../../forms';
import { RoleDescriptions, userHasRoleMatchingDescription, userHasRoleRequiringTraining } from '../../utilities/user-roles';

class EditClinicianPersonalInfoCardForm extends Component {
  constructor(props) {
    super(props);

    const { user = {} } = props;
    const { trained_at } = user;
    const person = user.person || {};
    const firstName = person.first_name || '';
    const lastName = person.last_name || '';
    const credentials = person.credentials || {};
    const bio = person.bio || '';
    const language = person.language || DEFAULT_USER_LANGUAGE;

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateTrainingDate = this.onUpdateTrainingDate.bind(this);

    this.state = {
      submitted: false,
      firstName,
      lastName,
      bio,
      language,
      credentials: credentials.items || [],
      trainingDate: trained_at || '',
      trainingDateValid: dayjs(trained_at).isValid()
    };
  }

  render() {
    return (
      <FormComponent
        autoComplete="off"
        className="card card-form"
        onSubmit={this.onSubmit}
      >
        <FormInputGroup
          className="form-group first-name-input-group"
          labelText={Strings.firstNameLabelText}
          inputType="text"
          inputProps={{
            name: 'first_name',
            autoComplete: 'off',
            className: 'form-control',
            value: this.state.firstName,
            onBlur: this.onUpdateInput.bind(this, 'firstName'),
            onChange: this.onUpdateInput.bind(this, 'firstName')
          }}
          inputValid={this.state.firstName.length > 0}
          messageText={Strings.firstNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group last-name-input-group"
          labelText={Strings.lastNameLabelText}
          inputType="text"
          inputProps={{
            name: 'last_name',
            autoComplete: 'off',
            className: 'form-control',
            value: this.state.lastName,
            onBlur: this.onUpdateInput.bind(this, 'lastName'),
            onChange: this.onUpdateInput.bind(this, 'lastName')
          }}
          inputValid={this.state.lastName.length > 0}
          messageText={Strings.lastNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <LanguageInputGroup
          className="form-group"
          labelText={Strings.languageLabelText}
          inputProps={{
            className: 'form-control',
            name: 'language',
            value: this.state.language
          }}
          onUpdate={language => {
            this.setState({ language });
          }}
          messageText={Strings.languageValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group bio-input-group"
          labelText={Strings.bioLabelText}
          inputType="textarea"
          inputProps={{
            className: 'form-control',
            name: 'bio',
            rows: 5,
            value: this.state.bio,
            onBlur: this.onUpdateInput.bind(this, 'bio'),
            onChange: this.onUpdateInput.bind(this, 'bio')
          }}
          inputValid
        />

        <FormListInputGroup
          className="form-group credentials-input-group"
          inputClassName="form-control"
          labelText={Strings.credentialsInputLabelText}
          inputProps={{
            className: 'form-control',
            name: 'credentials',
            placeholder: Strings.credentialInputPlaceholder,
            initialItems: this.state.credentials,
            onUpdate: this.onUpdateCredentials.bind(this)
          }}
        />

        <If condition={this.showTrainingDate()}>
          <Then>
            <AccessControl roles={[RoleDescriptions.SuperAdmin, RoleDescriptions.SalesAdmin]}>
              <DateInputGroup
                className="form-group"
                labelText={Strings.trainingDateLabelText}
                inputType="date"
                textInputProps={{
                  className: 'form-control',
                  name: 'trained_at',
                  placeholder: 'January 1, 2000'
                }}
                dateInputProps={{
                  className: 'form-control'
                }}
                initialValue={this.state.trainingDate}
                onUpdate={this.onUpdateTrainingDate}
                messageText={Strings.trainingDateErrorMessage}
                messageClassName="alert alert-danger"
                required={this.state.trainingDate.length > 0}
                touched={this.state.submitted}
              />
            </AccessControl>
          </Then>
        </If>

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.firstName.length
      && this.state.lastName.length
      && this.state.language.length
      && (this.state.trainingDate.length === 0 
        || (this.state.trainingDateValid 
            || !this.showTrainingDate()
        )
      );
  }

  showTrainingDate() {
    return userHasRoleRequiringTraining(this.props.user)
      || userHasRoleMatchingDescription(this.props.user, RoleDescriptions.BaselineTester);
  }

  onUpdateTrainingDate(trainingDate, trainingDateValid) {
    this.setState({
      trainingDate,
      trainingDateValid
    });
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onUpdateCredentials(credentials) {
    this.setState({ credentials });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      firstName,
      lastName,
      language,
      bio,
      credentials,
      trainingDate
    } = this.state;

    const attributes = {
      person_attributes: {
        first_name: firstName,
        last_name: lastName,
        language,
        bio,
        credentials: {
          items: credentials
        }
      }
    };

    if (this.showTrainingDate()) {
      attributes.trained_at = trainingDate.length > 0 ? trainingDate : null;
    }

    this.props.onSubmit(attributes);
  }
}

EditClinicianPersonalInfoCardForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EditClinicianPersonalInfoCardForm;

import React, {
  useCallback,
  useState,
  useMemo
} from 'react';
import dayjs from 'dayjs';
import {
  If,
  Then,
  Else,
  Switch,
  Case,
  Default
} from 'react-if';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DateRangeSelector, { initialRange } from '../date-range-selector';
import TypeSelector from './type-selector';
import DanaTestChart from './chart';
import DanaBatteryList from './list';
import ViewSelector, { ViewTypes } from './view-selector';
import {
  groupTestsByKey,
  groupTestsByBattery,
  groupBaselineTestByKey,
  DanaTests,
  filterBatteries
} from './helpers';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import { jsonApiRead } from '../../requests/jsonapi';
import { useMount } from '../../hooks';
import { SummaryReportDownloadButton } from './summary-report';
import { UserSelector } from '../../redux/selectors';
import Strings from './lang';

const getDanaTests = (userId, attributes) => {
  const options = { path: `users/${userId}/dana_batteries/tests`, queryParams: attributes };
  return jsonApiRead({ type: 'dana_tests' }, options);
};

const getDanaTestBaseline = (userId) => {
  const options = { path: `users/${userId}/dana_batteries/tests/baseline` };
  return jsonApiRead({ type: 'dana_tests' }, options);
};

const getAttributes = (range) => {
  const attributes = {
    after: dayjs(range.startDate).format('YYYY-MM-DD')
  };

  if (range.rangeKey === 'custom') {
    attributes.before = dayjs(range.endDate).format('YYYY-MM-DD');
  }

  return attributes;
};

const DanaTestResults = () => {
  const params = useParams();
  const user = useSelector(state => UserSelector.getUser(state, params));
  const [dateRange, setDateRange] = useState(initialRange);
  const [danaTests, setDanaTests] = useState([]);
  const [baseline, setBaseline] = useState({});
  const [testType, setTestType] = useState(DanaTests.SimpleReactionTime);
  const [loading, setLoading] = useState(!danaTests.length);
  const [error, setError] = useState(null);
  const [postInjury, setPostInjury] = useState(null);
  const [view, setView] = useState(ViewTypes.Chart);
  const tests = useMemo(() => (
    groupTestsByKey(danaTests, postInjury)
  ), [danaTests, postInjury]);
  const batteries = useMemo(() => (
    groupTestsByBattery(danaTests)
  ), [danaTests]);
  const filteredBatteries = useMemo(() => (
    filterBatteries(batteries, postInjury)
  ), [batteries, postInjury]);

  const loadTests = useCallback((range) => {
    setLoading(true);
    setError(null);
    const attributes = getAttributes(range);

    getDanaTests(params.userId, attributes).then((tests) => {
      setDanaTests(tests);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setLoading(false);
    });
  }, [params.userId]);

  const onRangeChange = useCallback((range) => {
    setDateRange(range);
    loadTests(range);
  }, [loadTests]);

  useMount(() => {
    loadTests(dateRange);
    getDanaTestBaseline(params.userId).then(baseline => {
      setBaseline(groupBaselineTestByKey(baseline));
    });
  });

  return (
    <div>
      <div className="dana-tests-header">
        <div className="title-container">
          <h1>{Strings.title}</h1>
          <ViewSelector
            value={view}
            onSelect={setView}
          />
        </div>
        <TypeSelector
          value={postInjury}
          onSelect={setPostInjury}
        />
        <div className="flex-row align_center">
          {batteries.length > 0 && (
            <SummaryReportDownloadButton
              user={user}
              batteries={batteries}
              dateRange={dateRange}
            />
          )}
          <DateRangeSelector 
            range={dateRange}
            btnClassName="btn btn-light btn-sm"
            onChange={onRangeChange}
          />
        </div>
      </div>
      <ErrorBanner error={error} />
      <Activity 
        active={loading} 
        static={!danaTests.length}
        titleComponent={<h2>{Strings.loadingMessage} ...</h2>}
      >
        <If condition={batteries.length > 0}>
          <Then>
            <Switch>
              <Case condition={view === ViewTypes.Chart}>
                <DanaTestChart
                  tests={tests}
                  userId={params.userId}
                  baseline={baseline}
                  testType={testType}
                  onTestTypeChange={setTestType}
                />
              </Case>
              <Case condition={view === ViewTypes.List}>
                <DanaBatteryList
                  batteries={filteredBatteries}
                  userId={params.userId}
                />
              </Case>
              <Default>
                {null}
              </Default>
            </Switch>
          </Then>
          <Else>
            <div>{Strings.testsEmptyText}</div>
          </Else>
        </If>
      </Activity>
    </div>
  );
};

export default DanaTestResults;

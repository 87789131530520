import React, { Component } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { getBaselineBeforeDateAsync } from '../../../redux/thunks/baseline-tests';
import { getInjuryAsync } from '../../../redux/thunks/injuries';
import { getUserAsync } from '../../../redux/thunks/users';
import { mergeRepeatBaselines } from '../../../utilities/injury-helpers';
import replaceLinkParams from '../../../utilities/replace-link-params';
import { ClinicPaths } from '../../../paths';
import PostInjuryTestDisplay from './post-injury-test-display';
import { ErrorBanner } from '../../errors';
import Activity from '../../activity';
import { withRouter } from '../../../utilities/router-utils';

// eslint-disable-next-line max-len
// route = /clinics/:clinicId/patients/:userId/injuries/:injuryId/post-injury-tests/:testId/compare/:compareContext
// :compareContext = this is the context of the post-injury test comparing baseline
//                  * if its a number then it should be the result of 
//                    dayjs(test.created_at).valueOf() -> converted to 
//                    date and used to fetch a baseline before that date
//                  * in the future may want to add other contexts such as 'best' baseline

const findRepeatBaselineTest = (baselines, testId) => (baselines || []).find(test => (
  parseInt(test.id, 10) === parseInt(testId, 10)
));

const resolveRepeatBaselineTestId = (baselines, testId) => {
  return testId 
    ? (findRepeatBaselineTest(baselines, testId) || {})
    : ((baselines || [])[0] || {});
};

class PostInjuryTestDisplayContainer extends Component {
  constructor(props) {
    super(props);

    const { injuries = {}, users = {}, params = {} } = props;
    const { injuryId = 0, userId = 0, testId = 0 } = params;

    const userInjuries = injuries[userId] || {};
    const injury = userInjuries[injuryId] || {};
    const user = users[userId] || {};
    const postInjuryTests = mergeRepeatBaselines(injury);
    const test = resolveRepeatBaselineTestId(postInjuryTests, testId);

    this.state = {
      user,
      injury,
      postInjuryTests,
      test,
      injuryLoaded: false,
      baseline: {},
      activity: false,
      error: null
    };
  }

  componentDidMount() {
    const { params = {} } = this.props;
    const { 
      userId = 0, injuryId = 0, testId = 0, compareContext = 0 
    } = params;
    this.getPostInjuryTestData(userId, injuryId, testId, compareContext);
  }

  componentDidUpdate(prevProps) {
    const { params = {} } = this.props;
    const {
      userId = 0, 
      testId = 0, 
      injuryId = 0, 
      compareContext = 0
    } = params;

    if ((userId && userId !== prevProps.params?.userId)
      || (testId && testId !== prevProps.params?.testId)
      || (injuryId && injuryId !== prevProps.params?.injuryId)
    ) {
      this.getPostInjuryTestData(userId, injuryId, testId, compareContext);
    }
  }

  render() {
    return (
      <Activity active={this.state.activity}>
        <div>
          <ErrorBanner error={this.state.error} />
          <PostInjuryTestDisplay
            user={this.state.user}
            injury={this.state.injury}
            test={this.state.test}
            baseline={this.state.baseline}
            allTests={this.state.postInjuryTests}
            showHeader
            showSelector
            onTestSelected={id => {
              const test = this.state.postInjuryTests.find(test => test.id === id) || {};

              const params = { 
                ...this.props.params, 
                testId: test.id || id,
                compareContext: dayjs(test.created_at || dayjs()).valueOf()
              };

              const path = replaceLinkParams(
                ClinicPaths.patientProfile.injuries.postInjuryTest.link, 
                params
              );
              this.props.router.replace(path);
            }}
          />
        </div>
      </Activity>
    );
  }

  getPostInjuryTestData(userId, injuryId, testId, compareContext) {
    this.setState({
      activity: true,
      error: null
    });

    const propsInjuryId = this.props.params.injuryId || 0;
    const propsCompareContext = this.props.params.compareContext || 0;
    const { 
      user, injury, baseline, injuryLoaded 
    } = this.state;

    // do not load the user if we already have the user
    const userRequest = user.id 
      ? Promise.resolve(user) 
      : this.props.getUser(userId);

    // do not reload the injury if we already have it and the injury IDs are the same
    const injuryRequest = injuryLoaded && injuryId === propsInjuryId 
      ? Promise.resolve(injury) 
      : this.props.getInjury(userId, injuryId);
    
    // do not reload the baseline if we already 
    const baselineRequest = baseline.id && compareContext === propsCompareContext 
      ? Promise.resolve(baseline) 
      : this.getComparingBaseline(userId, compareContext);

    Promise.all([
      userRequest,
      injuryRequest,
      baselineRequest
    ]).then(([user = {}, injury = {}, baseline = {}]) => {
      const postInjuryTests = mergeRepeatBaselines(injury);
      const test = resolveRepeatBaselineTestId(postInjuryTests, testId); 

      this.setState({
        user,
        injury,
        baseline,
        postInjuryTests,
        test,
        injuryLoaded: true,
        activity: false
      });
    }).catch(error => {
      this.setState({
        error: error.message,
        activity: false
      });
    });
  }

  getComparingBaseline(userId, compareContext) {
    const dateStamp = parseInt(compareContext, 10);

    if (!isNaN(dateStamp)) {
      return this.props.getComparingBaselineTest(userId, dayjs(dateStamp).format());
    }

    return Promise.resolve({});
  }
}

const mapStateToProps = (state) => {
  const { injuries = {}, users = {} } = state;
  return { injuries, users };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (userId) => {
      return dispatch(getUserAsync(userId));
    },
    getInjury: (userId, injuryId) => {
      return dispatch(getInjuryAsync(userId, injuryId));
    },
    getComparingBaselineTest: (userId, beforeDate) => {
      return dispatch(getBaselineBeforeDateAsync(userId, beforeDate));
    }
  };
};

const ConnectedPostInjuryTestDisplayContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostInjuryTestDisplayContainer);

const RoutablePostInjuryTestDisplayContainer = withRouter(ConnectedPostInjuryTestDisplayContainer);

export default RoutablePostInjuryTestDisplayContainer;

import React, { useCallback, useMemo } from 'react';
import { If, Then, Else } from 'react-if';
import Card from '../card';
import { SymptomLevels } from '../symptoms';
import { OrderedSymptomKeys } from '../../symptom-data';
import Strings from './lang';
import Icon from '../icon';

const SymptomsTransform = {
  in: (symptoms) => {
    return OrderedSymptomKeys.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: {
          level: symptoms[curr] || 0
        }
      };
    }, {});
  },
  out: (symptoms) => {
    return Object.keys(symptoms).reduce((acc, curr) => {
      acc[curr] = symptoms[curr].level;
      return acc;
    }, {});
  }
};

const SymptomsCard = ({
  symptoms = {},
  locked = false,
  onUpdate
}) => {
  const hasSymptoms = useMemo(() => Object.keys(symptoms || {}).length > 0, [symptoms]);
  const scores = useMemo(() => SymptomsTransform.in(symptoms || {}), [symptoms]);

  const onRemove = useCallback(() => {
    onUpdate(null);
  }, [onUpdate]);

  const handleUpdate = useCallback((symptoms) => {
    onUpdate(
      SymptomsTransform.out(symptoms)
    );
  }, [onUpdate]);

  const onAdd = useCallback(() => {
    handleUpdate(scores);
  }, [handleUpdate, scores]);

  return (
    <Card
      title={Strings.symptomsSectionTitle}
      actionButtons={hasSymptoms && !locked ? (
        <button
          type="button"
          className="edit-button warning"
          onClick={onRemove}
        >
          <Icon name="xmark" />
          {Strings.removeSymptomScoreButton}
        </button>
      ) : null}
    >
      <If condition={hasSymptoms}>
        <Then>
          <SymptomLevels
            type="select"
            groupClassName="row"
            inputClassName="col-md-3"
            required={false}
            symptoms={scores}
            onUpdate={handleUpdate}
            touched={false}
          />
        </Then>
        <Else>
          <div className="add-symptoms-view">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={onAdd}
            >
              <Icon name="plus" />&nbsp;&nbsp;
              {Strings.addSymptomScoreButton}
            </button>
          </div>
        </Else>
      </If>
    </Card>
  );
};

export default SymptomsCard;

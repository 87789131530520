import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../page';
import Activity from '../activity';
import ProductForm from './product-form';
import { ErrorBanner } from '../errors';
import {
  createProductAsync,
  getProductAsync,
  updateProductAsync
} from '../../redux/thunks/products';
import replaceLinkParams from '../../utilities/replace-link-params';
import { MarketplacePaths } from '../../paths';
import { showAlert } from '../alert-notifications';
import Strings from './lang';

const productSelector = (slug) => (state) => {
  return Object.values(state.products).find(p => p.slug === slug);
};

const showSuccessMessage = (message) => {
  showAlert('success', {
    dismissable: true,
    autoDismiss: 3000,
    message
  });
};

const MarketplaceProductEdit = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const product = useSelector(productSelector(params.productId));
  const [activity, setActivity] = useState(() => !!params.productId && !product?.id);
  const [loading, setLoading] = useState(activity);
  const [error, setError] = useState(null);

  const getProduct = useCallback((id) => {
    setActivity(true);
    setLoading(true);
    setError(null);

    dispatch(getProductAsync(id)).then(() => {
      setActivity(false);
      setLoading(false);
    }).catch(error => {
      setError(error.message);
      setActivity(false);
      setLoading(false);
    });
  }, [dispatch]);

  const createProduct = useCallback((attributes) => {
    setActivity(true);
    setError(null);

    dispatch(createProductAsync(attributes)).then((product) => {
      setActivity(false);
      navigate(
        replaceLinkParams(
          MarketplacePaths.products.product.index.link, 
          { productId: product.slug }
        )
      );

      showSuccessMessage(Strings.productCreatedMessage);
    }).catch(error => {
      setError(error.message);
      setActivity(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }, [dispatch, navigate]);

  const updateProduct = useCallback((id, attributes) => {
    setActivity(true);
    setError(null);

    dispatch(updateProductAsync(id, attributes)).then(product => {
      setActivity(false);

      if (product.slug !== params.productId) {
        navigate(
          replaceLinkParams(
            MarketplacePaths.products.product.index.link,
            { productId: product.slug }
          ),
          { replace: true }
        );
      } else {
        navigate(-1);
      }

      showSuccessMessage(Strings.productUpdatedMessage);
    }).catch(error => {
      setError(error.message);
      setActivity(false);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }, [dispatch, params.productId, navigate]);

  const onSubmit = useCallback((attributes) => {
    if (product?.id) {
      updateProduct(product.id, attributes);
    } else {
      createProduct(attributes);
    }
  }, [createProduct, product?.id, updateProduct]);

  useEffect(() => {
    if (params.productId) {
      getProduct(params.productId);
    }
  }, [getProduct, params.productId]);

  return (
    <Page>
      <h1 className="display">
        {params.productId ? Strings.editProductLabel : Strings.createProductLabel}
      </h1>
      <ErrorBanner error={error} />
      <Activity active={activity} static={loading}>
        <ProductForm
          product={product}
          onSubmit={onSubmit}
        />
      </Activity>
    </Page>
  );
};

export default MarketplaceProductEdit;

const EN = {
  resetTitle: 'Reset Your Password',
  updateTitle: 'Update Your Password',
  authLinkText: 'Sign In',
  emailLabelText: 'Email',
  emailInputPlaceholder: 'user@example.com',
  passwordLabelText: 'Password',
  passwordInputPlaceholder: 'password123',
  confirmPasswordLabelText: 'Confirm Password',
  confirmPasswordInputPlaceholder: 'password123',
  newPasswordLabelText: 'New Password',
  newPasswordErrorText: 'Passwords must be at least 6 characters long.',
  currentPasswordLabelText: 'Current Password',
  currentPasswordErrorText: 'Please enter your current password',
  resetButtonText: 'Reset Password',
  updateButtonText: 'Update Password',
  updateDoneButtonText: 'Done',
  updatePasswordSuccessMessage: 'Your password has been updated.',
  resetPasswordRequestSuccessMessage: 'Please check your email for a link to reset your password.',
  passwordResetSuccessful: 'Password Successfully Reset'
};

export default EN;

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';
import Activity from '../../activity';
import { ErrorBanner } from '../../errors';
import { useRegistrationContext } from './context';
import { getUserAsync } from '../../../redux/thunks/users';
import { updateRegistrationStep } from '../../../redux/actions/registration';
import { getRegistrationReturning, getCurrentRegistrationSession } from '../../../utilities/registration-session';
import { useMount } from '../../../hooks';

const sessionSelector = (state) => {
  const { users = {} } = state;
  const session = getCurrentRegistrationSession() || {};
  const currentAuthUserId = session.id;
  const currentUser = users[currentAuthUserId] || {};
  const isReturn = getRegistrationReturning();
  return { currentUser, currentUserId: currentAuthUserId, isReturn };
};

const withRegistrationStep = (step) => {
  return (Component) => {
    const RegistrationStep = () => {
      const dispatch = useDispatch();
      const context = useRegistrationContext();
      const { currentUser, currentUserId, isReturn } = useSelector(sessionSelector);
      const [activity, setActivity] = useState(false);
      const [error, setError] = useState(null);

      const loadCurrentUser = useCallback(() => {
        setActivity(true);
        setError(null);

        const options = { headers: { Authorization: getCurrentRegistrationSession()?.token } };

        dispatch(getUserAsync(currentUserId, options)).then(() => {
          setActivity(false);
        }).catch(error => {
          setActivity(false);
          setError(error.message);
        });
      }, [currentUserId, dispatch]);

      useMount(() => {
        dispatch(updateRegistrationStep(step));
      });

      useMount(() => {
        if (!currentUser.id && currentUserId) {
          loadCurrentUser();
        }
      });

      return (
        <div className="registration-step">
          <ErrorBanner error={error} />
          <Activity active={activity} static>
            <Component
              currentUser={currentUser}
              isReturn={isReturn}
              {...context}
            />
          </Activity>
        </div>
      );
    };

    hoistNonReactStatics(RegistrationStep, Component);

    return RegistrationStep;
  };
};

export default withRegistrationStep;

import React, { useCallback, useMemo, useState } from 'react';
import { ImageCropper, ImageCropperOptions } from '../../../image-tools';
import { FormComponent, FormInputGroup, UrlInputGroup } from '../../../forms';
import Editor from '../../../editor';
import { useObjectState } from '../../../hooks';
import Strings from '../lang';

const defaultState = {
  name: '',
  description: '',
  website: '',
  websiteValid: false,
  logoFilename: null,
  logo: null
};

const isValid = (attrs) => {
  return attrs.name.length > 0 
    && attrs.description.length > 0 
    && attrs.websiteValid;
};

const PartnerForm = ({
  initialState = {},
  submitButtonText = Strings.submitButtonLabel,
  onSubmit = () => { }
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [attributes, setAttributes] = useObjectState({ ...defaultState, ...initialState });
  const valid = useMemo(() => isValid(attributes), [attributes]);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (!valid) return;

    onSubmit(attributes);
  }, [attributes, valid, onSubmit]);

  return (
    <FormComponent className="create-partnership-form" onSubmit={handleSubmit}>
      <ImageCropper
        className="avatar-image-cropper"
        inputName="avatar-image"
        chooseButtonClassName="btn btn-default"
        cropButtonClassName="btn btn-primary"
        cropperOptions={ImageCropperOptions}
        onCrop={(logoFilename, logo) => {
          setAttributes({ logoFilename: logoFilename.name, logo });
        }}
      />
      <FormInputGroup
        required
        className="form-group"
        labelText={Strings.partnerNameLabel}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: attributes.name,
          onChange: (e) => setAttributes({ name: e.target.value })
        }}
        messageText={Strings.partnerNameErrorMessage}
        messageClassName="alert alert-danger"
        inputValid={attributes.name.length > 0}
        touched={submitted}
      />
      <UrlInputGroup
        required
        className="form-group"
        labelText={Strings.partnerWebsiteUrlLabel}
        inputProps={{
          className: 'form-control',
          value: attributes.website
        }}
        onUpdate={(website, websiteValid) => {
          setAttributes({ website, websiteValid });
        }}
        messageClassName="alert alert-danger"
        touched={submitted}
      />
      <div className="form-group">
        <label>{Strings.partnerDescriptionLabel} <span className="required">*</span></label>
        <Editor
          value={attributes.description}
          namespace="partnership-description"
          placeholder={Strings.descriptionPlaceholder}
          onChange={(description) => setAttributes({ description })}
        />
      </div>
      <div className="form-footer">
        <button type="submit" className="btn btn-primary" disabled={!valid}>
          {submitButtonText}
        </button>
      </div>
    </FormComponent>
  );
};

export default PartnerForm;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Strings from './lang';
import RoleStrings from '../../role-strings';
import { ClinicPaths } from '../../paths';
import replaceLinkParams from '../../utilities/replace-link-params';

class ClinicUsersTable extends Component {
  constructor(props) {
    super(props);

    this.sortByLastName = this.updateSortKeyPath.bind(this, 'person.last_name');
    this.sortByRoles = this.updateSortKeyPath.bind(this, 'roles');
  }

  render() {
    const { clinic, users, sortKeyPath } = this.props;
    return (
      <table className="table users-table clinic-users-table">
        <thead>
          <tr>
            <th 
              className={classNames({ highlighted: sortKeyPath === 'person.last_name' })}
              onClick={this.sortByLastName}
            >
              {Strings.nameHeadingText}
            </th>
            <th 
              className={classNames({ highlighted: sortKeyPath === 'roles' })}
              onClick={this.sortByRoles}
            >
              {Strings.rolesHeadingText}
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => {
            const { person } = user;

            if (person) {
              const roles = user.roles || [];
              return (
                <tr key={user.id}>
                  <td className="highlighted">
                    <Link 
                      to={replaceLinkParams(ClinicPaths.userProfile.link, {
                        clinicId: clinic.id,
                        userId: user.id
                      })}
                    >
                      {person.first_name} {person.last_name}
                    </Link>
                  </td>
                  <td className="highlighted">
                    {roles.map(role => RoleStrings[role]).join(', ')}
                  </td>
                </tr>
              );
            }
              
            return null;
          })}
        </tbody>
      </table>
    );
  }

  updateSortKeyPath(keyPath) {
    const { onUpdateSortKeyPath } = this.props;
    if (typeof onUpdateSortKeyPath === 'function') {
      onUpdateSortKeyPath(keyPath);
    }
  }
}

ClinicUsersTable.propTypes = {
  clinic: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  sortKeyPath: PropTypes.string.isRequired,
  onUpdateSortKeyPath: PropTypes.func.isRequired
};

export default ClinicUsersTable;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { If, Then, Else } from 'react-if';
import {
  archiveBaselineTestAsync,
  getBaselineTestAsync,
  unarchiveBaselineTestAsync
} from '../../../redux/thunks/baseline-tests';
import { getUserAsync } from '../../../redux/thunks/users';
import { BaselineSelector, UserSelector } from '../../../redux/selectors';
import { DEFAULT_USER_LANGUAGE } from '../../../utilities/localization';
import replaceLinkParams from '../../../utilities/replace-link-params';
import { UserPaths } from '../../../paths';
import { ErrorBanner } from '../../errors';
import { PatientInfoHeader } from '../../test-header';
import Icon from '../../icon';
import Activity from '../../activity';
import BaselineMeta from './baseline-meta';
import { ArchiveBaselineModal, confirmUnarchive } from '../baseline-archive';
import { isBaselineContinuable } from '../baseline-utilities';
import ComparableUserBaselineView from './comparable-user-baseline-view';
import AccessControl from '../../access-control';
import {
  makeRoleDescription,
  RoleDescriptions,
  RoleNames,
  RoleResourceTypes,
  userHasAdminRole
} from '../../../utilities/user-roles';
import Strings from './lang';
import { showAlert } from '../../alert-notifications';

const makeBaselineEditorRoles = (clinicId) => {
  return [
    RoleDescriptions.Clinician,
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicStaff, RoleResourceTypes.Clinic, clinicId),
    makeRoleDescription(RoleNames.ClinicFrontDesk, RoleResourceTypes.Clinic, clinicId)
  ];
};

const makeBaselineArchiveRoles = (clinicId) => {
  return [
    RoleDescriptions.Clinician,
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId)
  ];
};

const makeBaselineUnarchiveRoles = (clinicId) => {
  return [
    RoleDescriptions.Clinician,
    makeRoleDescription(RoleNames.BaselineTester, RoleResourceTypes.Clinic, clinicId)
  ];
};

const ActionButton = ({ loading, userId, test }) => {
  if (loading) return null;

  if (isBaselineContinuable(test)) {
    return (
      <Link
        to={replaceLinkParams(UserPaths.baselines.steps.continue.link, {
          userId,
          baselineId: test?.id || 0
        })}
        className="btn btn-primary btn-sm no-print"
      >
        <Icon name="square-caret-right" />&nbsp;&nbsp;
        {Strings.continueBaselineText}
      </Link>
    );
  }

  return (
    <Link
      to={replaceLinkParams(UserPaths.baselines.edit.link, {
        userId,
        baselineId: test?.id || 0
      })}
      className="btn btn-primary btn-sm no-print"
    >
      <Icon name="pen-to-square" />&nbsp;&nbsp;
      {Strings.editBaselineButtonText}
    </Link>
  );
};

const ArchiveBaselineButton = ({ onSubmit }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        style={{ marginLeft: 10 }}
        className="btn btn-danger btn-sm no-print"
        onClick={() => setModalOpen(true)}
      >
        <Icon name="box-archive" />&nbsp;&nbsp;
        {Strings.archiveTestText}
      </button>
      <ArchiveBaselineModal 
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={onSubmit}
      />
    </>
  );
};

const UserBaselineDetail = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const user = useSelector(state => UserSelector.getUser(state, { userId: params.userId }));
  const test = useSelector(state => (
    BaselineSelector.getBaseline(state, { baselineId: params.testId })
  ));

  const unarchiveBaselineTest = useCallback(() => {
    setActivity(true);
    setError(null);

    dispatch(unarchiveBaselineTestAsync(params.userId, test.id)).then(() => {
      setActivity(false);
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3500,
        message: Strings.unarchivedSuccessMessage,
      });
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch, params.userId, test?.id]);

  const onUnarchiveTest = useCallback(() => {
    confirmUnarchive(unarchiveBaselineTest);
  }, [unarchiveBaselineTest]);

  const archiveBaselineTest = useCallback((reason) => {
    setActivity(true);
    setError(null);

    dispatch(archiveBaselineTestAsync(params.userId, test.id, reason)).then(() => {
      setActivity(false);
      showAlert('success', {
        dismissable: true,
        autoDismiss: 3500,
        message: Strings.archivedSuccessMessage,
      });
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch, params.userId, test?.id]);

  const getBaseline = useCallback((userId, baselineId) => {
    setActivity(true);

    dispatch(getBaselineTestAsync(userId, baselineId)).then(() => {
      setActivity(false);
    }).catch(error => {
      setActivity(false);
      setError(error.message);
    });
  }, [dispatch]);

  const getUser = useCallback((userId) => {
    dispatch(getUserAsync(userId)).catch(error => {
      setError(error.message);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!test?.id && params.testId) {
      getBaseline(params.userId, params.testId);
    }
  }, [getBaseline, params.testId, params.userId, test?.id]);

  useEffect(() => {
    if (!user?.id && params.userId && !userHasAdminRole(currentUser)) {
      getUser(params.userId);
    }
  }, [currentUser, getUser, params.userId, user?.id]);

  return (
    <div className="user-baseline-detail">
      <div className="baseline-details-header flex-row justify_space-between align_center">
        <div className="flex-row align_center">
          <h1 className="display">{Strings.reviewTitle}</h1>
          {!!test?.archived_at && (
            <div className="label label-danger">
              <Icon name="box-archive" />&nbsp;
              {Strings.archivedTagText}
            </div>
          )}
        </div>
        {!!test?.id && (
          <If condition={!!test?.archived_at}>
            <Then>
              <AccessControl roles={makeBaselineUnarchiveRoles(test?.clinic?.id)}>
                <button 
                  type="button" 
                  className="btn btn-warning btn-sm no-print"
                  onClick={onUnarchiveTest}
                >
                  <Icon name="undo" />&nbsp;
                  {Strings.unarchiveTestText}
                </button>
              </AccessControl>
            </Then>
            <Else>
              <div>
                <AccessControl roles={makeBaselineEditorRoles(test?.clinic?.id)}>
                  <ActionButton
                    userId={params.userId}
                    test={test}
                    loading={activity}
                  />
                </AccessControl>
                <AccessControl roles={makeBaselineArchiveRoles(test?.clinic?.id)}>
                  <ArchiveBaselineButton onSubmit={archiveBaselineTest} />
                </AccessControl>
              </div>
            </Else>
          </If>
        )}
      </div>
      <ErrorBanner error={error} />
      
      <div className="user-baseline-content">
        {user?.id && (
          <PatientInfoHeader user={user} />
        )}
        <BaselineMeta
          user={user || {}}
          test={test || {}}
          compare={false}
        />
        <Activity active={activity} static={!user?.id}>
          <ComparableUserBaselineView
            compare={false}
            test={test}
            language={user?.person?.language || DEFAULT_USER_LANGUAGE}
          />
        </Activity>
      </div>
    </div>
  );
};

export default UserBaselineDetail;

import React from 'react';
import { Link } from 'react-router-dom';
import PartnerTag from '../partners/partner-tag';
import AccessControl from '../../access-control';
import Icon from '../../icon';
import replaceLinkParams from '../../../utilities/replace-link-params';
import { MarketplacePaths } from '../../../paths';
import { AdminRoleDescriptions } from '../../../utilities/user-roles';
import Strings from '../lang';

const PriceDiscountView = ({ price, discount }) => {
  if (!discount) {
    return (
      <div className="product-price">
        {price.currency} {price.formatted}
      </div>
    );
  }

  const value = price.value / 100.0;
  const saving = value * (discount / 100.0);
  const currency = new Intl.NumberFormat(
    navigator?.language, 
    { style: 'currency', currency: price.currency, currencyDisplay: 'narrowSymbol' }
  );

  return (
    <div className="product-price discount">
      {price.currency} {currency.format(value - saving)}&nbsp;
      <span className="saving">
        {Strings.formatString(Strings.productPriceSaveLabel, currency.format(saving))}
      </span>
    </div>
  );
};

const ProductCard = ({ product }) => {
  return (
    <Link 
      to={
        replaceLinkParams(
          MarketplacePaths.products.product.index.link, 
          { productId: product.slug }
        )
      } 
      className="product-card"
    >
      <div className="image-container">
        {!!product?.thumbnail_image && (
          <img
            src={product.thumbnail_image}
            loading="lazy"
            alt={product.name}
          />
        )}
      </div>
      <div className="details-container">
        <div className="product-name">
          {product.name}
        </div>
        <PriceDiscountView 
          price={product.price}
          discount={product.discount}
        />
        {!!product.mp_partner && (
          <PartnerTag 
            portal
            partner={product.mp_partner}
          />
        )}
      </div>
      {product.discount > 0 && (
        <div className="discount-badge">
          -{product.discount}%
        </div>
      )}
      {!product.published && (
        <AccessControl roles={AdminRoleDescriptions}>
          <div className="visibility-badge" title="Product not published on Marketplace">
            <Icon name="eye-slash" />
          </div>
        </AccessControl>
      )}
    </Link>
  );
};

export default ProductCard;

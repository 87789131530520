import dayjs from 'dayjs';
import { INPUT_DATE_FORMAT } from '../dates/formats';

export const getAdjustedAssessmentDate = (date) => {
  let adjusted = dayjs(date);

  if (dayjs.utc(date).startOf('day').valueOf() === dayjs.utc(date).valueOf()) {
    adjusted = dayjs(date, INPUT_DATE_FORMAT);
  }

  return adjusted;
};

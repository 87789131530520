import React, { 
  useState, 
  useEffect,
  useCallback
} from 'react';
import { connect } from 'react-redux';
import { SelectInputGroup, FormInputGroup } from '../../../forms';
import { TaxRateSelector } from '../../../redux/selectors';
import { getTaxRatesAsync } from '../../../redux/thunks/taxes';
import { useMount } from '../../../hooks';
import Countries from '../../../utilities/country-regions';
import Strings from '../lang';

const flattenTaxes = (taxes) => {
  return Object.values(taxes).reduce((acc, arr) => {
    return acc.concat(arr);
  }, []);
};

const getSelectedTax = (taxes, code, rate) => {
  const flattened = flattenTaxes(taxes);
  const selected = (flattened.find(tax => (
    tax.code === code && tax.rate === rate
  )) || {}).id;

  if (selected) {
    return selected;
  }

  return code ? 'custom' : '';
};

const TaxSelectInput = ({
  taxes = {},
  selected,
  onUpdate = () => {}
}) => {
  return (
    <SelectInputGroup
      inputValid
      labelText={Strings.taxesAppliedLabel}
      inputProps={{
        className: 'form-control',
        value: selected
      }}
      onUpdate={onUpdate}
    >
      <option value="">{Strings.noTaxesAppliedLabel}</option>
      <option value="custom">{Strings.customTaxLabel}</option>
      {Object.keys(taxes).map(key => {
        const countryData = Countries[key] || {};
        return (
          <optgroup key={key} label={countryData.name || key}>
            {taxes[key].map(tax => (
              <option key={tax.id} value={tax.id}>
                {tax.code} ({tax.rate}%)
              </option>
            ))}
          </optgroup>
        );
      })}
    </SelectInputGroup>
  );
};

const TaxInputFields = ({
  taxCode,
  taxRate,
  onUpdate = () => {}
}) => {
  const handleCodeUpdate = useCallback((e) => {
    const { value } = e.target;
    onUpdate(value, taxRate);
  }, [onUpdate, taxRate]);

  const handleRateUpdate = useCallback((e) => {
    const { value } = e.target;
    onUpdate(taxCode, value);
  }, [onUpdate, taxCode]);

  return (
    <>
      <FormInputGroup 
        inputValid={taxRate > 0 ? !!taxCode : true}
        className="form-group"
        labelText={Strings.taxCodeLabel}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: taxCode || '',
          onChange: handleCodeUpdate
        }}
        messageText={Strings.streetErrorText}
        messageClassName="alert alert-danger"
      />
      <FormInputGroup 
        inputValid={taxRate >= 0}
        className="form-group"
        labelText={Strings.taxRateLabel}
        inputType="text"
        inputProps={{
          className: 'form-control',
          value: taxRate,
          onChange: handleRateUpdate
        }}
        messageText={Strings.streetErrorText}
        messageClassName="alert alert-danger"
      />
    </>
  );
};

const LineItemTaxFields = ({
  taxes = {},
  uniqueTaxes = {},
  taxCode = '',
  taxRate = 0,
  getTaxes,
  onUpdate = () => {}
}) => {
  const [loaded, setLoaded] = useState(!!Object.keys(taxes).length);
  const [selectedTax, setSelectedTax] = useState(getSelectedTax(uniqueTaxes, taxCode, taxRate));

  const handleSelectChange = useCallback((value) => {
    setSelectedTax(value);

    if (value !== 'custom') {
      const taxRate = taxes[value] || {};
      const { code = null, rate = 0 } = taxRate;
      onUpdate(code, rate);
    }
  }, [onUpdate, taxes]);

  useMount(() => {
    if (loaded) return;
    getTaxes().then(() => {
      setLoaded(true);
    }).catch(() => {});
  });

  useEffect(() => {
    setSelectedTax(getSelectedTax(uniqueTaxes, taxCode, taxRate));
    // Only want to update the selected tax if the unique tax rates
    // change, so ignore all other deps (i.e. taxCode, taxRate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueTaxes]);

  if (!loaded) return null;

  return (
    <>
      <TaxSelectInput 
        selected={selectedTax}
        taxes={uniqueTaxes}
        onUpdate={handleSelectChange}
      />
      {selectedTax === 'custom' && (
        <TaxInputFields 
          taxCode={taxCode}
          taxRate={taxRate}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { taxes } = state;
  const uniqueTaxes = TaxRateSelector.getUniqueGroupTaxRates(state);
  return { taxes, uniqueTaxes };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTaxes: () => {
      return dispatch(getTaxRatesAsync());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LineItemTaxFields);

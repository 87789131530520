import { removeAuthData, removeCurrentClinicId } from '../../tokens';
import Store from '../../redux/store';
import { sessionSignout } from '../../redux/actions/session';

// Important: you must bind the component instance to `this`
// and wrap the component with the react-router `withRouter`
// function for the logout action to work.

const logout = () => {
  removeAuthData();
  removeCurrentClinicId();
  Store.dispatch(sessionSignout());
};

export default logout;

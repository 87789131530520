import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { PasswordReset } from '../../components/passwords';
import {
  ChildRegistrationRouteHandler,
  ClinicUserRegistration,
  GuardianAccountShowLists,
  GuardianRegisterChild,
  GuardianRegistrationAbout,
  GuardianRegistrationRouteHandler,
  PlayerRegistrationRouteHandler,
  PlayerRegistrationWrapper,
  RegistrationAboutPlayer,
  RegistrationAccount,
  RegistrationConfirm,
  RegistrationConfirmRequest,
  RegistrationEmergencyContact,
  RegistrationFinish,
  RegistrationLanding,
  RegistrationPhysician
} from '../../components/registration';
import CookieBanner from '../../components/cookie-banner';
import { withGoogleTagManager } from '../../google-tag-manager';
import PageNotFound from '../not-found-page';
import { Authorization } from '../authorization';
import { RoleDescriptions } from '../../utilities/user-roles';
import { getCurrentRegistrationSession } from '../../utilities/registration-session';
import { Error404Page } from '../../components/errors';

const PatientRegistrationWrapper = withGoogleTagManager(({ gtmAvailable = false }) => {
  return (
    <>
      <Outlet />
      {gtmAvailable && (
        <CookieBanner />
      )}
    </>
  );
});

const authorize = () => {
  const { id, token } = getCurrentRegistrationSession() || {};

  if (!id || !token) {
    return { path: '/register' };
  }

  return null;
};

const AuthorizedRegistration = ({ children }) => {
  const redirect = authorize();

  if (redirect) {
    return (
      <Navigate
        replace
        to={redirect.path}
        state={redirect.state}
      />
    );
  }

  return children ?? <Outlet />;
};

const GuardianAboutChild = () => {
  const params = useParams();
  
  if (params?.child === 'add') {
    return <GuardianRegisterChild />;
  }

  return <RegistrationAboutPlayer />;
};

const RegistrationRoutes = [
  {
    path: 'password/reset/:token',
    element: <PasswordReset />
  },
  {
    path: 'confirm/*',
    children: [
      {
        index: true,
        element: <RegistrationConfirmRequest />
      },
      {
        path: ':token',
        element: <RegistrationConfirm />
      },
      {
        path: '*',
        element: <PageNotFound />
      }
    ]
  },
  {
    path: 'about',
    element: (
      <Authorization
        allowUntrained
        allowRoles={[
          RoleDescriptions.Clinician,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicOwner,
          RoleDescriptions.ClinicFrontDesk,
          RoleDescriptions.ClinicStaff,
          RoleDescriptions.IDTClinician,
          RoleDescriptions.IDTFrontDesk
        ]}
      >
        <ClinicUserRegistration />
      </Authorization>
    )
  },
  {
    path: 'finish',
    element: (
      <AuthorizedRegistration>
        <RegistrationFinish />
      </AuthorizedRegistration>
    )
  },
  {
    path: '*',
    element: <PatientRegistrationWrapper />,
    children: [
      {
        index: true,
        element: <RegistrationLanding />
      },
      {
        path: '*',
        element: <PlayerRegistrationWrapper />,
        children: [
          {
            path: 'individual/*',
            element: <PlayerRegistrationRouteHandler />,
            children: [
              {
                index: true,
                element: <RegistrationAccount />
              },
              {
                path: '*',
                element: <AuthorizedRegistration />,
                children: [
                  {
                    path: 'about',
                    element: <RegistrationAboutPlayer />
                  },
                  {
                    path: 'contact',
                    element: <RegistrationEmergencyContact />
                  },
                  {
                    path: 'physician',
                    element: <RegistrationPhysician />
                  },
                  {
                    path: '*',
                    element: <Error404Page />
                  }
                ]
              }
            ]
          },
          {
            path: 'family/*',
            children: [
              {
                path: 'members/:child/*',
                element: (
                  <AuthorizedRegistration>
                    <ChildRegistrationRouteHandler />
                  </AuthorizedRegistration>
                ),
                children: [
                  {
                    index: true,
                    element: <GuardianAboutChild />
                  },
                  {
                    path: 'contact',
                    element: <RegistrationEmergencyContact />
                  },
                  {
                    path: 'physician',
                    element: <RegistrationPhysician />
                  },
                  {
                    path: '*',
                    element: <Error404Page />
                  }
                ]
              },
              {
                path: '*',
                element: <GuardianRegistrationRouteHandler />,
                children: [
                  {
                    index: true,
                    element: <RegistrationAccount />
                  },
                  {
                    path: '*',
                    element: <AuthorizedRegistration />,
                    children: [
                      {
                        path: 'about',
                        element: <GuardianRegistrationAbout />
                      },
                      {
                        path: 'members',
                        element: <GuardianAccountShowLists />
                      },
                      {
                        path: '*',
                        element: <Error404Page />
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: '*',
            element: <PageNotFound />
          }
        ]
      }
    ]
  }
];

export default RegistrationRoutes;

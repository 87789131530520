import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormComponent, PasswordInputGroup } from '../../forms';
import Strings from './lang';

class PasswordResetForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      submitted: false,
      password: '',
      passwordValid: false
    };
  }

  render() {
    return (
      <FormComponent className="password-reset-form" onSubmit={this.onSubmit}>
        <PasswordInputGroup
          className="form-group"
          showPasswordScore
          showConfirmInput
          labelText={Strings.newPasswordLabelText}
          inputProps={{
            className: 'form-control',
            placeholder: Strings.passwordInputPlaceholder
          }}
          confirmLabelText={Strings.confirmPasswordLabelText}
          confirmInputProps={{
            className: 'form-control',
            name: 'confirm-password',
            placeholder: Strings.confirmPasswordInputPlaceholder
          }}
          onUpdate={(password, passwordValid) => {
            this.setState({ password, passwordValid });
          }}
          messageText={Strings.newPasswordErrorText}
          messageClassName="alert alert-danger"
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.resetButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  isValid() {
    return this.state.passwordValid;
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const { token, onSubmit } = this.props;
    const { password } = this.state;

    const attributes = {
      reset_password_token: token,
      password,
      confirm_password: password
    };

    if (typeof onSubmit === 'function') {
      onSubmit(attributes);
    }
  }
}

PasswordResetForm.propTypes = {
  token: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default PasswordResetForm;

import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { TestHeader } from '../test-header';
import { RecoveryStagesView } from '../recovery-stages';
import { DiagnosisView } from '../diagnosis';
import SymptomsDisplay from './symptoms-display';
import Markdown from '../markdown';
import { DISPLAY_DATE_TIME_FORMAT } from '../../dates/formats';
import Strings from './lang';

const RecoveryStagesDisplay = ({ stage }) => {
  if (!stage) {
    return (
      <p className="text-muted">
        {Strings.recoveryStagesNotChangedText}
      </p>
    );
  }

  return (
    <RecoveryStagesView stages={stage} />
  );
};

const SpecialistNotesDisplay = ({
  user,
  injury,
  note
}) => {
  const hasDiagnosis = useMemo(() => (
    Object.keys(note?.diagnosis || {}).length > 0
  ), [note?.diagnosis]);

  return (
    <div className="specialist-note-display">
      <TestHeader
        user={user}
        injury={injury}
        className="soap-notes-header"
        testDate={note.created_at}
        tester={note.clinic_user}
        clinic={note.clinic}
        additionalNode={note.last_updated_by && (
          <div className="row">
            <div className="col-md-6">
              <div className="test-header-row">
                <label>{Strings.lastUpdatedText}:</label>
                <span>{dayjs(note.updated_at).format(DISPLAY_DATE_TIME_FORMAT)}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="test-header-row">
                <label>{Strings.lastUpdatedByText}:</label>
                <span>{note.last_updated_by}</span>
              </div>
            </div>
          </div>
        )}
      />
      {hasDiagnosis && (
        <div>
          <strong>{Strings.diagnosisChangedLabel}:</strong>
          <DiagnosisView diagnosis={note.diagnosis} />
        </div>
      )}
      <div className="note-section-display">
        <h2>{Strings.symptomsSectionTitle}</h2>
        <SymptomsDisplay symptoms={note.symptoms_scores} />
      </div>
      <div className="note-section-display">
        <h2>{Strings.notesSectionTitle}</h2>
        <Markdown>
          {note.notes || ''}
        </Markdown>
      </div>
      <div className="note-section-display">
        <h2>{Strings.recoveryStagesSectionTitle}</h2>
        <RecoveryStagesDisplay stage={note.recovery_protocol_stage} />
      </div>
    </div>
   
  );
};

export default SpecialistNotesDisplay;

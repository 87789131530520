import { sortAscending } from '../../utilities/sort';
import { UPDATE_DANA_BATTERY } from '../actions/dana-batteries';

const updateDanaBatteryReducer = (state = {}, action = {}) => {
  const { userId, battery } = action;
  const { [userId]: userBatteries = {} } = state;
  const { [battery.id]: prevBattery = {} } = userBatteries;

  return {
    ...state,
    [userId]: {
      ...userBatteries,
      [battery.id]: {
        ...prevBattery,
        ...battery,
        tests: (battery.tests || [])
          .sort(sortAscending.bind(null, 'test_order'))
      }
    }
  };
};

const danaBatteries = (state = {}, action = {}) => {
  switch (action.type) {
    case UPDATE_DANA_BATTERY:
      return updateDanaBatteryReducer(state, action);
    default:
      return state;
  }
};

export default danaBatteries;

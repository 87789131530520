import React, { useCallback, useState, useRef } from 'react';
import Modal from '../modal';
import BagtagForm from './bag-tag-form';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import { jsonApiUpdate } from '../../requests/jsonapi';
import Strings from './lang';

const assignBagtag = (user, clinicId, bagtag, reason) => {
  const resource = {
    type: 'bag_tags',
    attributes: {
      user_id: user.id,
      reason
    }
  };

  const queryParams = {
    current_clinic_id: clinicId
  };

  return jsonApiUpdate(resource, { path: `bag_tags/assign/${bagtag}`, queryParams });
};

const BagtagModal = ({
  isOpen,
  user = {},
  clinicId,
  skippable = false,
  onAssigned = () => { },
  onCancel,
  onSkip
}) => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState(null);
  const formRef = useRef(null);

  const handleSubmitClick = useCallback(() => {
    // manually trigger the onSubmit event
    formRef.current.dispatchEvent(new Event('submit'));
  }, []);

  const handleSubmit = useCallback((bagTag, reason) => {
    setActive(true);
    setError(null);

    assignBagtag(user, clinicId, bagTag, reason).then(() => {
      setActive(false);
      onAssigned(bagTag);
    }).catch(error => {
      setActive(false);
      setError(error.message);
    });
  }, [clinicId, onAssigned, user]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      className="bagtag-modal"
      onClose={isOpen ? onCancel : undefined}
    >
      <Modal.Header>
        <h1>{Strings.title}</h1>
        {skippable ? (
          <button
            type="button"
            disabled={active}
            className="btn btn-default btn-edit"
            onClick={onSkip}
          >
            {Strings.skipAssignButtonTitle}
          </button>
        ) : null}
      </Modal.Header>
      <Modal.Body>
        <Activity active={active}>
          <ErrorBanner error={error} />
          <BagtagForm
            ref={formRef}
            reassign={!!user.bag_tag}
            onSubmit={handleSubmit}
          />
        </Activity>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-default"
          disabled={active}
          onClick={onCancel}
        >
          {Strings.cancelButtonTitle}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          disabled={active}
          onClick={handleSubmitClick}
        >
          {Strings.assignButtonTitle}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export const BagtagModalButton = ({
  className,
  user = {},
  clinicId,
  disabled = false,
  skippable = false,
  onAssign = () => { },
  onCancel = () => { },
  onSkip = () => { },
  children
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        className={className}
        onClick={() => setOpen(true)}
      >
        {children}
      </button>
      <BagtagModal
        isOpen={open}
        user={user}
        clinicId={clinicId}
        skippable={skippable}
        onAssigned={(bagTag) => {
          setOpen(false);
          onAssign(bagTag);
        }}
        onCancel={() => {
          setOpen(false);
          onCancel();
        }}
        onSkip={() => {
          setOpen(false);
          onSkip();
        }}
      />
    </>
  );
};

export default BagtagModal;

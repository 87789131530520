import { DRIVE_FOLDER_TYPE } from '../utilities/google-drive';

const DRIVE_API_ENDPOINT = 'https://www.googleapis.com/drive';
const DRIVE_API_VERSION = 'v3';

const queryStringFromObject = (obj = {}) => {
  return Object.keys(obj)
    .map(key => {
      const value = obj[key].toString();
      return `${key}=${encodeURIComponent(value)}`;
    }).join('&');
};

const makeUrl = (path, params = {}) => {
  const base = [DRIVE_API_ENDPOINT, DRIVE_API_VERSION, path].join('/');
  const query = queryStringFromObject(params);
  return `${base}?${query}`;
};

const makeHeaders = (token) => {
  return {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json'
  };
};

const escapeSingleQuote = (text = '') => {
  return text.replace(/'/g, "\\'");
};

const request = (path, auth = {}, params = {}) => {
  const { access_token, api_key } = auth;
  const query = { key: api_key, ...params };
  const endpoint = makeUrl(path, query);
  const headers = makeHeaders(access_token);

  return fetch(endpoint, { headers }).then(res => {
    return res.json();
  }).then(response => {
    if (response.error) {
      return Promise.reject(response.error);
    }
    
    return Promise.resolve(response);
  });
};

const FIELDS = 'files(id, mimeType, name, iconLink, thumbnailLink, webViewLink, shortcutDetails, parents, createdTime, modifiedTime)';

const Drive = (auth = {}) => {
  return {
    folder(folderId) {
      const params = { 
        fields: FIELDS
      };

      if (folderId) {
        params.q = `'${folderId}' in parents`;
      }

      return request('files', auth, params);
    },
    file(fileId) {
      return request(`files/${fileId}`, auth);
    },
    search(text = '') {
      const params = { 
        fields: FIELDS,
        q: `name contains '${escapeSingleQuote(text)}' and mimeType != '${DRIVE_FOLDER_TYPE}' and shortcutDetails.targetMimeType != '${DRIVE_FOLDER_TYPE}'`
      };
      return request('files', auth, params);
    }
  };
};

export default Drive;

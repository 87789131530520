import React, { useMemo } from 'react';
import {
  OrderedSymptomKeys,
  SymptomStrings,
  calculateSymptomsTotals
} from '../../../symptom-data';
import SymptomItem from './symptom-item';
import SymptomsTotalScore from './symptoms-total-score';
import SymptomsTotalCount from './symptoms-total-count';
import Strings from './lang';

const CompareSymptomsList = ({
  leftSymptoms,
  rightSymptoms,
  leftTitleElement, 
  rightTitleElement
}) => {
  const leftTotals = useMemo(() => calculateSymptomsTotals(leftSymptoms), [leftSymptoms]);
  const rightTotals = useMemo(() => calculateSymptomsTotals(rightSymptoms), [rightSymptoms]);

  return (
    <div className="symptoms-list">

      <div className="row">
        <div className="col-md-6">
          {leftTitleElement || (
            <h2 className="bold">{Strings.symptomsTitle}</h2>
          )}
        </div>
        <div className="col-md-6">
          {rightTitleElement || (
            <h2 className="bold">{Strings.symptomsTitle}</h2>
          )}
        </div>
      </div>

      {OrderedSymptomKeys.map(key => {
        const left = leftSymptoms[key];
        const right = rightSymptoms[key];

        return (
          <div key={key} className="row">
            <div className="col-md-6">
              {left !== undefined && (
                <SymptomItem
                  title={SymptomStrings[key]}
                  symptom={left}
                />
              )}
            </div>
            <div className="col-md-6">
              {right !== undefined && (
                <SymptomItem
                  title={SymptomStrings[key]}
                  symptom={right}
                />
              )}
            </div>
          </div>
        );
      })}
      <div className="row">
        <div className="col-md-6">
          <SymptomsTotalScore totals={leftTotals} />
        </div>
        <div className="col-md-6">
          <SymptomsTotalScore totals={rightTotals} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <SymptomsTotalCount totals={leftTotals} />
        </div>
        <div className="col-md-6">
          <SymptomsTotalCount totals={rightTotals} />
        </div>
      </div>
    </div>
  );
};

export default CompareSymptomsList;

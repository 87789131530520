import React, { useCallback, useMemo, useState } from 'react';
import { FormComponent, CheckboxInputGroup } from '../../../forms';
import ProviderInputList from './provider-input-list';
import Icon from '../../icon';
import AppointmentDatetimeInput from './appointment-datetime-input';
import Strings from '../lang';

const InitialAppointmentForm = ({ submitting, minApptDate, onSubmit }) => {
  const [submitted, setSubmitted] = useState(false);
  const [appt, setAppt] = useState({ value: '', valid: false });
  const [virtual, setVirtual] = useState(false);
  const [providers, setProviders] = useState([{ name: '', designation: '' }]);
  const isValid = useMemo(() => {
    return appt.valid && providers.every(provider => !!provider.name && !!provider.designation);
  }, [appt.valid, providers]);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (isValid) {
      onSubmit?.({
        appt: appt.value,
        virtual,
        providers
      });
    }
  }, [appt.value, providers, isValid, onSubmit, virtual]);

  return (
    <FormComponent className="claim-appt-form" onSubmit={handleSubmit}>
      <AppointmentDatetimeInput
        appt={appt}
        minDate={minApptDate}
        disabled={submitting}
        touched={submitted}
        onChange={setAppt}
      />
      <CheckboxInputGroup
        inputValid
        disabled={submitting}
        className="form-group"
        labelText={Strings.virtualApptCheckboxLabel}
        inputProps={{
          className: 'form-control',
          checked: virtual,
          disabled: submitting,
          onChange: e => {
            setVirtual(e.target.checked);
          }
        }}
      />
      <ProviderInputList
        disabled={submitting}
        touched={submitted}
        providers={providers}
        onChange={setProviders}
      />
      <div className="form-footer">
        <button type="submit" className="btn btn-primary" disabled={submitting || !isValid}>
          {submitting && <><Icon spinPulse prefix="fad" name="loader" />&nbsp;</>}
          {Strings.submitButton}
        </button>
      </div>
    </FormComponent>
  );
};

export default InitialAppointmentForm;

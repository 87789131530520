import React from 'react';
import PropTypes from 'prop-types';
import { If, Then } from 'react-if';
import { getCountryName, getCountryRegionName } from '../../utilities/country-regions';
import Strings from './lang';

const AddressInfoCard = ({
  address
}) => (
  <div className="card card-show address-info">

    <div className="card-row">
      <label>{Strings.addressLabelText}:</label>
      <span>{address.street_1}</span>
    </div>

    <If condition={address.street_2 != null && address.street_2.length > 0}>
      <Then>
        <div className="card-row">
          <label />
          <span>{address.street_2}</span>
        </div>
      </Then>
    </If>

    <div className="card-row">
      <label>{Strings.cityLabelText}:</label>
      <span>{address.city}</span>
    </div>

    <div className="card-row">
      <label>{Strings.regionLabelText}:</label>
      <span>{getCountryRegionName(address.country, address.state)}</span>
    </div>

    <div className="card-row">
      <label>{Strings.countryLabelText}:</label>
      <span>{getCountryName(address.country)}</span>
    </div>

    <div className="card-row">
      <label>{Strings.postalCodeLabelText}:</label>
      <span>{address.postal_code}</span>
    </div>

  </div>
);

AddressInfoCard.propTypes = {
  address: PropTypes.object.isRequired
};

export default AddressInfoCard;

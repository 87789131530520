import React from 'react';
import dayjs from 'dayjs';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from 'recharts';
import DotIcon from './dot-icon';
import TestTooltip from './tooltip';
import getReferenceLines from './reference-lines';
import { TestKeyLabels } from '../utils';
import { DISPLAY_DATE_FORMAT } from '../../../dates/formats';

const TEST_COLORS = {
  score: '#D57389',
  feet_together_score: '#85293D',
  tandem_right_score: '#33BFA0',
  tandem_left_score: '#0255A5',
  single_right_score: '#DB64E0',
  single_left_score: '#C03A59'
};

const legendFormatter = (value) => {
  return TestKeyLabels[`${value.toLowerCase()}`] || value;
};

const getLines = (types, baseline) => {
  return types?.map((type) => {
    return (
      <Line
        connectNulls
        key={type}
        type="monotone"
        dataKey={type}
        name={type}
        strokeWidth={2}
        isAnimationActive={false}
        dot={<DotIcon mean={baseline[type]?.value} stdev={baseline[type]?.stddev} />}
        activeDot={{
          r: 7,
          // cursor: 'pointer',
          // onClick: onDotClick
        }}
        stroke={TEST_COLORS[type] || '#0C1826'}
      />
    );
  });
};

const Y_DOMAIN_OFFSET = 5;

const BalanceLineChart = ({ metrics = [], tests = [], baseline = {} }) => {
  return (
    <ResponsiveContainer width="100%" height={450}>
      <LineChart data={tests}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="created_at"
          type="category"
          tickFormatter={(value) => dayjs(value).format(DISPLAY_DATE_FORMAT)}
          allowDuplicatedCategory={false}
          style={{ fontSize: 12 }}
        />
        <YAxis
          dataKey={metrics.length === 1 ? metrics[0] : undefined}
          type="number"
          tickFormatter={v => Math.round(v)}
          domain={([min, max]) => {
            return [
              Math.max(0, min - Y_DOMAIN_OFFSET),
              Math.min(100, max + Y_DOMAIN_OFFSET)
            ];
          }}
        />
        <Legend formatter={legendFormatter} />
        <Tooltip content={<TestTooltip />} />
        {getLines(metrics, baseline)}
        {getReferenceLines(
          metrics.length === 1 ? baseline[metrics[0]] : baseline.score, 
          metrics.length === 1
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default BalanceLineChart;

import React, { useCallback, useState } from 'react';
import Dropdown, { MenuLink, MenuItem } from '../../dropdown';
import Modal from '../../modal';
import { MarketplacePaths } from '../../../paths';
import replaceLinkParams from '../../../utilities/replace-link-params';
import Strings from '../lang';
import Icon from '../../icon';

const ActionModal = ({
  title = '',
  isOpen = false,
  type = 'primary',
  message = null,
  confirmButtonLabel = 'Confirm',
  onClose,
  onConfirm
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        <h2 className={type}>{title}</h2>
      </Modal.Header>
      <Modal.Body>
        {message}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={onClose}>
          Cancel
        </button>
        <button type="button" className={`btn btn-${type}`} onClick={onConfirm}>
          {confirmButtonLabel}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const PublishedStatusModal = ({
  isOpen = false,
  isPublished = true,
  onClose = () => {},
  onConfirm = () => {}
}) => {
  return (
    <ActionModal 
      isOpen={isOpen}
      type={isPublished ? 'warning' : 'primary'}
      title={isPublished ? Strings.unpublishProductLabel : Strings.publishProductLabel}
      message={
        isPublished
          ? Strings.unpublishProductModalMessage
          : Strings.publishProductModalMessage
      }
      confirmButtonLabel={
        isPublished
          ? Strings.unpublishProductModalButton
          : Strings.publishProductModalButton
      }
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};

const AdminActions = ({ product, onPublishChange }) => {
  const [open, setOpen] = useState(false);
  const [publishModalOpen, setPublishModalOpen] = useState(false);

  const handlePublishChange = useCallback(() => {
    setPublishModalOpen(false);
    onPublishChange(!product.published);
  }, [onPublishChange, product.published]);

  if (!product?.id) return null;

  return (
    <div className="admin-actions">
      <Dropdown
        isOpen={open}
        placement="bottom-end"
        trigger={(
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => setOpen(prev => !prev)}
          >
            {Strings.adminProductOptionsButton}&nbsp;&nbsp;
            <Icon name="chevron-down" />
          </button>
        )}
        onClose={() => setOpen(false)}
      >
        <MenuLink
          icon="pen-to-square"
          to={
            replaceLinkParams(
              MarketplacePaths.products.product.edit.link,
              { productId: product.slug }
            )
          }
        >
          {Strings.editProductLabel}
        </MenuLink>
        <MenuItem
          type={product.published ? 'warning' : 'default'}
          icon={product.published ? 'eye-slash' : 'eye'}
          onSelect={() => setPublishModalOpen(true)}
        >
          {product.published ? Strings.unpublishProductLabel : Strings.publishProductLabel}
        </MenuItem>
      </Dropdown>
      <PublishedStatusModal 
        isPublished={product.published}
        isOpen={publishModalOpen}
        onConfirm={handlePublishChange}
        onClose={() => setPublishModalOpen(false)}
      />
    </div>
  );
};

export default AdminActions;

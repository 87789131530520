import React, { useCallback, useMemo, useState } from 'react';
import Modal from '../../../../modal';
import FailureInputItems from '../failure-input-items';
import NumbersInputTable from './numbers-input-table';
import { FormComponent } from '../../../../../forms';
import { MAX_RESPONSES, updateTrialResponses } from '../utils';
import Strings from '../lang';

const generateNumbers = (responses, step, start) => {
  const numbers = [];

  [...new Array(MAX_RESPONSES)].forEach((_, i) => {
    const response = responses[i];
    const prev = responses[i - 1];

    if (i === 0) {
      numbers.push(start);
    } else if (response?.correct) {
      numbers.push(response.value);
    } else if (!response?.correct && (prev?.value >= 0 && prev?.value !== '')) {
      numbers.push(prev.value - step);
    } else if (!response?.correct) {
      numbers.push(numbers[i - 1] - step);
    }
  });

  return numbers;
};

const EditTrailModalBody = ({ trial, step, onUpdate }) => {
  const [failureReasons, setFailureReasons] = useState([...(trial.failed_reasons ?? [])]);
  const [duration, setDuration] = useState(trial.duration);
  const [responses, setResponses] = useState(() => ([...(trial.responses ?? [])]));
  const correctNumbers = useMemo(() => {
    return generateNumbers(responses, step, trial.start);
  }, [responses, step, trial.start]);
  const isValid = useMemo(() => {
    return responses
      .slice(0, -1)
      .every((r) => r.correct !== null && r.value !== '')
      && duration !== '';
  }, [duration, responses]);

  const errors = useMemo(() => {
    return responses.filter(r => r.correct === false)?.length;
  }, [responses]);

  const onUpdateFailureReasons = useCallback((key, checked) => {
    setFailureReasons(prev => {
      const newKeys = [...prev];
      const index = newKeys.findIndex(p => p === key);

      if (checked && index < 0) {
        return [...newKeys, key];
      }

      if (!checked && index >= 0) {
        newKeys.splice(index, 1);
        return newKeys;
      }

      return prev;
    });
  }, []);

  const handleResponseChange = useCallback((index, value) => {
    setResponses(prev => {
      const newValues = JSON.parse(JSON.stringify(prev || {}));

      return updateTrialResponses(
        newValues,
        value,
        index,
        trial.start,
        step
      );
    });
  }, [step, trial.start]);

  const handleSubmit = useCallback(() => {
    const newResponses = responses.filter(r => r.correct !== null);
    onUpdate({
      duration,
      failed: failureReasons.length > 0,
      failed_reasons: failureReasons,
      errors,
      responses: newResponses
    });
  }, [duration, errors, failureReasons, onUpdate, responses]);

  const handleDurationChange = useCallback(value => {
    setDuration(!isNaN(value) ? value * 1000 : '');
  }, []);

  const handleAddResponse = useCallback(() => {
    setResponses(prev => ([
      ...prev,
      { value: '', correct: null }
    ]));
  }, []);

  return (
    <>
      <Modal.Body>
        <FormComponent>
          <NumbersInputTable
            numbers={correctNumbers}
            duration={duration}
            onDurationChange={handleDurationChange}
            errors={errors}
            responses={responses}
            onResponseChange={handleResponseChange}
            onAddResponse={handleAddResponse}
          />
          <FailureInputItems
            reasons={failureReasons}
            onReasonsChange={onUpdateFailureReasons}
          />
        </FormComponent>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          disabled={!isValid}
          className="btn btn-primary"
          onClick={handleSubmit}
        >
          {Strings.updateButton}
        </button>
      </Modal.Footer>
    </>
  );
};

const EditTrialModal = ({
  isOpen = false,
  trial,
  step,
  number,
  onClose,
  onUpdate
}) => {
  return (
    <Modal
      isOpen={isOpen}
      className="dual-task-edit-modal"
      onClose={onClose}
    >
      <Modal.Header onClose={onClose}>
        <h2>{Strings.formatString(Strings.editModalTitle, number)}</h2>
      </Modal.Header>
      <EditTrailModalBody
        trial={trial}
        step={step}
        onUpdate={onUpdate}
      />
    </Modal>
  );
};

export default EditTrialModal;

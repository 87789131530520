import { jsonApiRead, jsonApiCreate, jsonApiUpdate } from '../../requests/jsonapi';
import { updateReferrals, updateReferral } from '../actions/referrals';
import { addInjuryReferral, updateInjuryReferral } from '../actions/injuries';

const getClinicReferralsAsync = (clinicId, open = null) => {
  const options = { path: `clinics/${clinicId}/referrals` };

  return dispatch => {
    return jsonApiRead({ type: 'referrals', attributes: { open } }, options).then((referrals) => {
      dispatch(updateReferrals(referrals));
      return Promise.resolve(referrals);
    });
  };
};

const getInjuryReferralsAsync = (userId, injuryId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/referrals` };

  return dispatch => {
    return jsonApiRead({ type: 'referrals' }, options).then((referrals) => {
      dispatch(updateReferrals(referrals));
      return Promise.resolve(referrals);
    });
  };
};

const createReferralAsync = (attributes) => {
  const options = { path: 'referrals' };

  return dispatch => {
    return jsonApiCreate({ type: 'referrals', attributes }, options).then(referral => {
      dispatch(updateReferral(referral));
      dispatch(addInjuryReferral(referral.user.id, referral.injury_id, referral));

      return Promise.resolve(referral);
    });
  };
};

const getReferralAsync = (referralId) => {
  const options = { path: `referrals/${referralId}` };

  return dispatch => {
    return jsonApiRead({ type: 'referrals' }, options).then(referral => {
      dispatch(updateReferral(referral));
      return Promise.resolve(referral);
    });
  };
};

const createReferralStatusUpdateRequest = (type) => (referralId, attributes) => {
  const options = { path: `referrals/${referralId}/${type}` };

  return dispatch => {
    return jsonApiUpdate({ type: 'referrals', attributes }, options).then(referral => {
      dispatch(updateReferral(referral));
      dispatch(updateInjuryReferral(referral.user.id, referral.injury_id, referral));
      return Promise.resolve(referral);
    });
  };
};

const acceptReferralAsync = createReferralStatusUpdateRequest('accept');
const rejectReferralAsync = createReferralStatusUpdateRequest('reject');
const cancelReferralAsync = createReferralStatusUpdateRequest('cancel');

export {
  getClinicReferralsAsync,
  getInjuryReferralsAsync,
  getReferralAsync,
  createReferralAsync,
  acceptReferralAsync,
  rejectReferralAsync,
  cancelReferralAsync
};

import React, { useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Page } from '../page';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import Tabs from '../tabs';
import OutstandingInvoices from './outstanding-invoices';
import PaidInvoices from './paid-invoices';
import { 
  BulkCreditProducts, 
  AddBulkCreditsForm
} from '../bulk-credits';
import { ClinicPaths } from '../../paths';
import { getClinicAsync } from '../../redux/thunks/clinics';
import { InvoiceSelector, UserSelector } from '../../redux/selectors';
import { userHasAdminRole } from '../../utilities/user-roles';
import replaceLinkParams from '../../utilities/replace-link-params';
import { getClinicCurrency } from '../../utilities/currency';
import { useMount } from '../../hooks';
import { createBulkCreditsAsync } from '../../redux/thunks/clinic-invoices';
import Strings from './lang';

const ClinicInvoices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  
  const dispatch = useDispatch();
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const clinic = useSelector(state => state.clinics[params?.clinicId] || {});
  const totalBalance = useSelector(state => (
    InvoiceSelector.getTotalBalanceOwing(state, { clinicId: params?.clinicId })
  ));

  const [selectedTab, setSelectedTab] = useState(location.state?.tab ?? 'outstanding');
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState(false);
  const [error, setError] = useState(null);
  const currency = useMemo(() => getClinicCurrency(clinic), [clinic]);

  const loadClinic = useCallback(() => {
    if (clinic.id) return;
    setLoading(true);

    dispatch(getClinicAsync(params?.clinicId)).then(() => {
      setLoading(false);
    }).catch(error => {
      setLoading(false);
      setError(error.message);
    });
  }, [clinic.id, dispatch, params?.clinicId]);

  const onInvoiceClick = useCallback((invoice) => {
    navigate(
      replaceLinkParams(ClinicPaths.invoices.details.link, { 
        clinicId: params?.clinicId,
        invoiceId: invoice.id 
      })
    );
  }, [navigate, params?.clinicId]);

  const onAdminCreateBulkCredits = useCallback((attributes) => {
    setActivity(true);

    dispatch(createBulkCreditsAsync(params?.clinicId, attributes)).then(() => {
      setActivity(false);
      setSelectedTab('outstanding');
    }).catch(error => {
      setError(error.message);
      setActivity(false);
    });
  }, [dispatch, params?.clinicId]);

  useMount(() => {
    loadClinic();
  });

  return (
    <Page className="clinic-invoices">
      <h1 className="display">{Strings.invoicesTitle}</h1>
      {userHasAdminRole(currentUser) && (
        <h2>{clinic.name}</h2>
      )}
      <ErrorBanner error={error} />
      <Activity active={loading || activity} static={loading}>
        <div className="row">
          <div className="col-md-12">
            {Strings.balanceLabel}:&nbsp;
            <strong>{totalBalance} {currency}</strong>
          </div>
          {clinic.credits_available >= 0 && (
            <div className="col-md-12">
              {Strings.creditsAvailableLabel}:&nbsp;
              <strong>{clinic.credits_available}</strong>
            </div>
          )}
          <div className="col-md-12">
            <Tabs
              renderOne
              className="invoice-tabs"
              items={[{
                key: 'outstanding',
                label: Strings.outstandingInvoicesTabTitle,
                component: (
                  <OutstandingInvoices 
                    clinicId={params?.clinicId} 
                    onInvoiceClick={onInvoiceClick}
                  />
                )
              }, {
                key: 'paid',
                label: Strings.paidInvoicesTabTitle,
                component: (
                  <PaidInvoices 
                    clinicId={params?.clinicId} 
                    onInvoiceClick={onInvoiceClick}
                  />
                )
              }, {
                key: 'credits',
                label: Strings.bulkCreditsTabTitle,
                hide: userHasAdminRole(currentUser) || !clinic.bulk_tokens_enabled,
                component: (
                  <BulkCreditProducts 
                    clinic={clinic} 
                    onPurchased={() => {
                      setSelectedTab('outstanding');
                    }}
                  />
                )
              }, {
                key: 'push_credits',
                label: Strings.pushCreditsTabLabel,
                hide: !userHasAdminRole(currentUser),
                component: (
                  <div className="row">
                    <div className="col-md-6 col-md-offset-3">
                      <AddBulkCreditsForm 
                        clinic={clinic}
                        onSubmit={onAdminCreateBulkCredits}
                      />
                    </div>
                  </div>
                )
              }]}
              selectedKey={selectedTab}
              onSelectTab={setSelectedTab}
            />
          </div>
        </div>
      </Activity>
    </Page>
  );
};

export default ClinicInvoices;

import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardCard from './dashboard-card';
import { useMount } from '../../hooks';
import Strings from './lang';
import { getCurrentClinicId } from '../../tokens';
import { getClinicBalanceAsync } from '../../redux/thunks/clinic-balance';

const AccountBalanceCard = ({ onClick }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const clinicId = useMemo(() => getCurrentClinicId(), []);
  const balance = useSelector((state) => state.clinicBalance[clinicId]);
  const { overdue_invoice_count = 0, tokens = '--', balance_owing = '--' } = balance || {};

  useMount(() => {
    setError(null);

    dispatch(getClinicBalanceAsync(clinicId)).catch(error => {
      setError(error.message);
    });
  });

  return (
    <DashboardCard
      icon="file-invoice-dollar"
      title={Strings.accountBalanceTitleText}
      rightBadge={overdue_invoice_count ? (
        <small className="error">
          <span className="badge bg-error">{overdue_invoice_count}</span>&nbsp;
          {Strings.overdueInvoiceLabel}
        </small>
      ) : null}
      subtitle={error 
        ? 'Error loading balance' 
        : Strings.formatString(
          Strings.accountBalanceCreditsText,
          tokens
        )}
      secondSubtitle={error || Strings.formatString(
        Strings.accountBalanceBalanceOwingText,
        balance_owing
      )}
      buttonTitle={Strings.cardButtonTitle}
      onClickButton={onClick}
    />
  );
};

export default AccountBalanceCard;

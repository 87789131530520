import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { If, Then, Else } from 'react-if';
import { SelectInputGroup, FormInputMessage } from '../../../../../forms';
import NumberLists from './number-lists';
import ScoreView from '../../../../score-view';
import Icon from '../../../../icon';
import Strings from './lang';

const MAX_CONSECUTIVE_ERRORS = 2;
const MAX_SCORE = 4;

/* eslint-disable no-magic-numbers */
const Numbers = {
  1: [
    [4, 9, 3],
    [3, 8, 1, 4],
    [6, 2, 9, 7, 1],
    [7, 1, 8, 4, 6, 2]
  ],
  2: [
    [6, 2, 9],
    [3, 2, 7, 9],
    [1, 5, 2, 8, 6],
    [5, 3, 9, 1, 4, 8]
  ],
  3: [
    [5, 2, 6],
    [1, 7, 9, 5],
    [3, 8, 5, 2, 7],
    [8, 3, 1, 9, 6, 4]
  ],
  4: [
    [4, 1, 5],
    [4, 9, 6, 8],
    [6, 1, 8, 4, 3],
    [7, 2, 4, 8, 5, 6]
  ]
};
/* eslint-enable no-magic-numbers */

const defaultState = {
  activeIndex: 0,
  activeList: 1,
  number_list: '',
  complete: false,
  consecutiveErrors: 0,
  score: 0,
  previousState: []
};

const buildInitialState = (props) => {
  const { initialScore = 0, initialList = '' } = props;

  let complete = false;
  let consecutiveErrors = 0;
  const score = initialScore;
  const number_list = initialList;
  const activeList = isNaN(parseInt(number_list, 10)) ? 0 : parseInt(number_list, 10);

  if (initialScore > 0) {
    complete = true;
  }

  if (initialScore > 0 && initialScore < MAX_SCORE) {
    consecutiveErrors = MAX_CONSECUTIVE_ERRORS;
  }

  return {
    ...defaultState,
    complete,
    consecutiveErrors,
    score,
    number_list,
    activeList
  };
};

class ConcentrationNumbers extends Component {
  constructor(props) {
    super(props);

    this.onUpdateWordList = this.onUpdateWordList.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleIncorrect = this.handleIncorrect.bind(this);
    this.handleCorrect = this.handleCorrect.bind(this);

    this.state = {
      ...buildInitialState(props)
    }; 
  }

  componentDidMount() {
    this.setState({
      ...buildInitialState(this.props)
    });
  }

  render() {
    return (
      <div className="concentration-numbers">

        <p className="text-muted">
          {Strings.numbersTestInstructionText}
        </p>

        <SelectInputGroup
          className="form-group"
          labelText={Strings.numberListLabel}
          inputProps={{
            className: 'form-control',
            value: this.state.number_list
          }}
          required
          valid={this.state.number_list !== ''}
          messageClassName="alert alert-danger"
          touched={this.props.touched}
          onUpdate={this.onUpdateWordList}
        >
          <option value="">{Strings.defaultListSelectText}</option>
          {Object.keys(Numbers).map(key => {
            return <option key={key} value={key}>{key}</option>;
          })}
        </SelectInputGroup>

        {this.state.number_list !== '' && (
          <div>
            <FormInputMessage
              className="alert alert-danger"
              text={Strings.numbersIncompleteError}
              visible={this.props.touched ? !this.state.complete : false}
            />

            <div className="number-list-wrapper">

              <If condition={this.state.complete}>
                <Then>
                  <div className="text-center">
                    {this.renderCompleteText()}
                  </div>
                </Then>
                <Else>
                  <NumberLists
                    className="number-list-container"
                    numbers={Numbers}
                    activeIndex={this.state.activeIndex}
                    activeList={this.state.activeList}
                  />
                </Else>
              </If>

            </div>

            {this.renderNumbersButtonGroup()}

            <ScoreView 
              label={Strings.numbersScore}
              score={this.state.score}
              outOf={Object.keys(Numbers).length}
            />
          </div>
        )}
        
      </div>
    );
  }

  renderCompleteText() {
    return (
      <p>
        {this.getCompletedReason()}
        &nbsp;
        <span>
          <button type="button" className="edit-button" onClick={this.resetNumbers.bind(this, this.state.number_list)}>
            <Icon name="arrows-rotate" />
            {Strings.reset}
          </button>
        </span>
      </p>
    );
  }

  renderNumbersButtonGroup() {
    return (
      <div className="numbers-btn-group">

        <div className="numbers-btn">
          <button 
            type="button" 
            className="btn btn-default" 
            onClick={this.handleBack} 
            disabled={this.state.previousState.length === 0}
          >
            <Icon name="arrow-left" />
          </button>
        </div>

        <div className="numbers-btn">
          <button 
            type="button" 
            className="btn btn-danger" 
            onClick={this.handleIncorrect}
            disabled={this.state.complete}
          >
            <Icon name="xmark" />
          </button>
        </div>

        <div className="numbers-btn">
          <button 
            type="button" 
            className="btn btn-success" 
            onClick={this.handleCorrect}
            disabled={this.state.complete}
          >
            <Icon name="check" />
          </button>
        </div>

      </div>
    );
  }

  getCompletedReason() {
    const { consecutiveErrors } = this.state;
    let reason = Strings.numbersComplete;
    if (consecutiveErrors >= MAX_CONSECUTIVE_ERRORS) {
      reason = `${reason} ${Strings.numbersCompleteWithErrors}`;
    }

    return reason;
  }

  resetNumbers(numberList) {
    const { onUpdate = () => {} } = this.props;
    let { activeList, previousState } = this.state;
    activeList = isNaN(parseInt(numberList, 10)) ? 0 : parseInt(numberList, 10); 
    previousState = [];

    this.setState({
      ...defaultState,
      activeList,
      number_list: numberList,
      previousState
    });

    onUpdate(numberList, defaultState.score, defaultState.complete);
  }

  onUpdateWordList(value) {
    this.resetNumbers(value);
  }

  handleBack() {
    const { onUpdate = () => {} } = this.props;
    const { previousState, number_list } = this.state;
    const prevState = previousState.pop();

    this.setState({
      ...prevState,
      previousState,
      number_list
    });

    onUpdate(number_list, prevState.score, prevState.complete);
  }

  handleIncorrect() {
    const { onUpdate = () => {} } = this.props;
    const { activeIndex, number_list, score } = this.state;
    let { activeList, consecutiveErrors, complete } = this.state;
    this.addPreviousStateToStack();

    consecutiveErrors += 1;
    /** circular index reference. If list index is 4 then will go to index 1 */
    activeList = ((1 + activeList) % (Object.keys(Numbers).length + 1)) || 1;
    if (consecutiveErrors >= MAX_CONSECUTIVE_ERRORS) {
      complete = true;
    }

    this.setState({
      activeIndex,
      activeList,
      consecutiveErrors,
      complete
    });

    onUpdate(number_list, score, complete);
  }

  handleCorrect() {
    const { onUpdate = () => {} } = this.props;
    const { number_list } = this.state;
    let { 
      activeList, activeIndex, consecutiveErrors, complete, score 
    } = this.state;
    this.addPreviousStateToStack();

    consecutiveErrors = 0;
    activeList = parseInt(number_list, 10);
    activeIndex += 1;
    score += 1;

    if (score >= MAX_SCORE) {
      complete = true;
    }

    this.setState({
      activeIndex,
      activeList,
      complete,
      consecutiveErrors,
      score
    });

    onUpdate(number_list, score, complete);
  }

  addPreviousStateToStack() {
    const { previousState, ...state } = this.state;
    previousState.push({
      ...state
    });

    this.setState({
      previousState
    });
  }
}
/* eslint-disable react/no-unused-prop-types */
ConcentrationNumbers.propTypes = {
  initialScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  numberList: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onUpdate: PropTypes.func
};

export default ConcentrationNumbers;

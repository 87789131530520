import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CheckboxInputGroup, FormInput } from '../../../../forms';
import Strings from './lang';

class RehabManagement extends Component {
  render() {
    const { rehab } = this.props;
    return (
      <div className={this.props.className}>
        <label className="bold">{Strings.rehabLabel}</label>
        <CheckboxInputGroup
          className="form-group checkbox-inline"
          labelText={Strings.vestibularLabel}
          inputProps={{
            className: 'form-control',
            checked: rehab.vestibular || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'vestibular')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group checkbox-inline"
          labelText={Strings.cervicalSpineLabel}
          inputProps={{
            className: 'form-control',
            checked: rehab.cervical_spine || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'cervical_spine')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group checkbox-inline"
          labelText={Strings.visualTrackingLabel}
          inputProps={{
            className: 'form-control',
            checked: rehab.visual_tracking || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'visual_tracking')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group checkbox-inline"
          labelText={Strings.sportSpecificLabel}
          inputProps={{
            className: 'form-control',
            checked: rehab.sport_specific || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'sport_specific')
          }}
          inputValid
        />
        <CheckboxInputGroup
          className="form-group checkbox-inline"
          labelText={Strings.neurocognitiveLabel}
          inputProps={{
            className: 'form-control',
            checked: rehab.neurocognitive || false,
            onChange: this.onCheckBoxUpdate.bind(this, 'neurocognitive')
          }}
          inputValid
        />

        <FormInput 
          type="textarea" 
          className="form-control" 
          inputProps={{
            rows: 5,
            placeholder: Strings.explanationLabel,
            value: rehab.explanation,
            onChange: this.onChangeInput.bind(this, 'explanation')
          }} 
        />
      </div>
    );
  }

  onCheckBoxUpdate(key, e) {
    const { checked } = e.target;
    this.props.onUpdate(key, checked);
  }

  onChangeInput(key, e) {
    const { value } = e.target;
    this.props.onUpdate(key, value);
  }
}

RehabManagement.propTypes = {
  rehab: PropTypes.object,
  onUpdate: PropTypes.func
};

export default RehabManagement;

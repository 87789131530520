import React, { useState, useCallback } from 'react';
import Icon from '../../components/icon';

const ItemList = ({
  items = [],
  onDelete
}) => {
  return (
    <ul>
      {items.map((item, index) => (
        <li key={index}>
          <span>{item}</span>
          <button type="button" onClick={() => onDelete(index)}>
            <Icon name="xmark" />
          </button>
        </li>
      ))}
    </ul>
  );
};

const FormListInput = ({ 
  className,
  placeholder,
  initialItems = [],
  initialInputText = '',
  onUpdate = () => {}
}) => {
  const [items, setItems] = useState(initialItems);
  const [text, setText] = useState(initialInputText);

  const handleTextChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  const onAddItem = useCallback(() => {
    if (text) {
      const newItems = [...items, text];
      setItems(newItems);
      setText('');
  
      onUpdate(newItems);
    }
  }, [items, onUpdate, text]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onAddItem();
    }
  }, [onAddItem]);

  const handleDelete = useCallback((index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    onUpdate(newItems);
  }, [items, onUpdate]);

  return (
    <div className="form-list-input">
      <ItemList 
        items={items}
        onDelete={handleDelete}
      />
      <div className="input-group">
        <input 
          type="text"
          className={className}
          placeholder={placeholder}
          value={text}
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
        />
        <span className="input-group-btn">
          <button 
            type="button"
            className="btn btn-primary"
            disabled={!text}
            onClick={onAddItem}
          >
            <Icon name="plus" />
          </button>
        </span>
      </div>
    </div>
  );
};

export default FormListInput;

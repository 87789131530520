import React, { useState, useCallback, useRef } from 'react';
import Modal from '../modal';
import Strings from './lang';
import { FormComponent, FormInputGroup } from '../../forms';

const isValid = (required, note) => {
  return !required || note.length > 0;
};

const NoteForm = React.forwardRef(({
  initialNote = '',
  required = false,
  noteLabel = Strings.notesLabelText,
  errorText = Strings.notesErrorText,
  cancelButtonTitle,
  submitButtonTitle,
  onSubmit = () => { },
  onClose
}, ref) => {
  const [note, setNote] = useState(initialNote);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);

    if (isValid(required, note)) {
      onSubmit(note);
    }
  }, [note, onSubmit, required]);

  return (
    <FormComponent ref={ref} onSubmit={handleSubmit}>
      <FormInputGroup
        className="form-group notes-text-input-group"
        labelText={noteLabel}
        inputType="textarea"
        inputProps={{
          rows: 5,
          className: 'form-control',
          value: note,
          onChange: (e) => setNote(e.target.value)
        }}
        messageText={errorText}
        messageClassName="alert alert-danger"
        inputValid={isValid(required, note)}
        required={required}
        touched={submitted}
      />
      <div className="form-footer">
        <button type="button" className="btn btn-default" onClick={onClose}>
          {cancelButtonTitle}
        </button>
        <button type="submit" className="btn btn-primary">
          {submitButtonTitle}
        </button>
      </div>
    </FormComponent>
  );
});

const NotesModal = ({
  isOpen,
  title = Strings.title,
  required = false,
  note = '',
  editable = true,
  noteLabel,
  errorText,
  submitButtonTitle = Strings.submitButtonTitle,
  cancelButtonTitle = Strings.cancelButtonTitle,
  onSave = () => { },
  onClose = () => { }
}) => {
  const formRef = useRef();

  const handleSubmit = useCallback((notes) => {
    onSave(notes);
    onClose();
  }, [onClose, onSave]);

  return (
    <Modal
      blur
      isOpen={isOpen}
      static={required}
      onClose={onClose}
      className="notes-modal"
    >
      <Modal.Header>
        <h1>{title}</h1>
      </Modal.Header>
      <Modal.Body>
        {editable ? (
          <NoteForm
            ref={formRef}
            required={required}
            initialNote={note}
            noteLabel={noteLabel}
            errorText={errorText}
            submitButtonTitle={submitButtonTitle}
            cancelButtonTitle={cancelButtonTitle}
            onSubmit={handleSubmit}
            onClose={onClose}
          />
        ) : (
          <p>{note}</p>
        )}
      </Modal.Body>
      {!editable && (
        <Modal.Footer>
          <button type="button" className="btn btn-default" onClick={onClose}>
            {Strings.closeButtonTitle}
          </button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default NotesModal;

export const NotesModalButton = ({
  className,
  title,
  required,
  note,
  editable,
  noteLabel,
  errorText,
  submitButtonTitle,
  cancelButtonTitle,
  onSave,
  children
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button type="button" className={className} onClick={() => setOpen(true)}>
        {children}
      </button>
      <NotesModal
        isOpen={open}
        title={title}
        note={note}
        required={required}
        editable={editable}
        noteLabel={noteLabel}
        errorText={errorText}
        submitButtonTitle={submitButtonTitle}
        cancelButtonTitle={cancelButtonTitle}
        onSave={onSave}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

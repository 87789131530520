import { jsonApiRead, jsonApiCreate, jsonApiUpdate } from '../../requests/jsonapi';
import { getCurrentClinicId } from '../../tokens';
import { updateSoapNote, updateSoapNotes } from '../actions/soap-notes';
import { addInjurySoapNote, removeInjurySoapNote } from '../actions/injuries';

const getSoapNoteAsync = (userId, injuryId, soapId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/soap_notes/${soapId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'soap_notes', id: soapId }, options).then(results => {
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getSoapNotesAsync = (userId, injuryId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/soap_notes` };
  return (dispatch) => {
    return jsonApiRead({ type: 'soap_notes' }, options).then(results => {
      dispatch(updateSoapNotes(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getFullSoapNotesAsync = (userId, injuryId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/soap_notes/full_index` };
  return (dispatch) => {
    return jsonApiRead({ type: 'soap_notes' }, options).then(results => {
      dispatch(updateSoapNotes(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const createSoapNoteAsync = (userId, injuryId, attributes) => {
  const queryParams = { current_clinic_id: getCurrentClinicId() };
  const options = { path: `users/${userId}/injuries/${injuryId}/soap_notes`, queryParams };
  return (dispatch) => {
    return jsonApiCreate({ type: 'soap_notes', attributes }, options).then(results => {
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateSoapNoteAsync = (userId, injuryId, soapId, attributes) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/soap_notes/${soapId}` };
  return (dispatch) => {
    return jsonApiUpdate({ type: 'soap_notes', id: soapId, attributes }, options).then(results => {
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getArchivedSoapNotesAsync = (userId, injuryId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/soap_notes/archived` };
  return dispatch => {
    return jsonApiRead({ type: 'soap_notes' }, options).then(results => {
      dispatch(updateSoapNotes(userId, injuryId, results));
      return Promise.resolve(results);
    });
  };
};

const archiveSoapNoteAsync = (userId, injuryId, soapId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/soap_notes/${soapId}/archive` };
  return dispatch => {
    return jsonApiCreate({ type: 'soap_notes' }, options).then(results => {
      dispatch(removeInjurySoapNote(userId, injuryId, soapId));
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    });
  };
};

const unarchiveSoapNoteAsync = (userId, injuryId, soapId) => {
  const options = { path: `users/${userId}/injuries/${injuryId}/soap_notes/${soapId}/unarchive` };
  return dispatch => {
    return jsonApiCreate({ type: 'soap_notes' }, options).then(results => {
      dispatch(addInjurySoapNote(userId, injuryId, results));
      dispatch(updateSoapNote(userId, injuryId, results));
      return Promise.resolve(results);
    });
  };
};

export {
  getSoapNoteAsync,
  getSoapNotesAsync,
  createSoapNoteAsync,
  updateSoapNoteAsync,
  getFullSoapNotesAsync,
  getArchivedSoapNotesAsync,
  archiveSoapNoteAsync,
  unarchiveSoapNoteAsync
};

import {
  useCallback, 
  useMemo, 
  useRef,
  useState 
} from 'react';
import { usePopper as useReactPopper } from 'react-popper';
import { useClickOutside } from '../../hooks';
import getModifiers from './modifiers';

const usePopper = ({
  placement,
  offset,
  fixed,
  isOpen,
  closeOnClickOutside = true,
  onClose,
}) => {
  const modifiers = useMemo(() => getModifiers(placement, offset), [placement, offset]);
  const target = useRef();
  const popperRef = useRef();
  const [popper, setPopper] = useState();

  const handleSetPopper = useCallback((c) => {
    setPopper(c);
    popperRef.current = c;
  }, [popperRef]);

  const handleClickOutside = useCallback((node) => {
    if (!target.current?.contains?.(node)) {
      onClose();
    }
  }, [onClose]);

  useClickOutside(popperRef, handleClickOutside, isOpen && closeOnClickOutside);

  const { styles, attributes, update } = useReactPopper(target.current, popper, {
    placement,
    strategy: fixed ? 'fixed' : 'absolute',
    modifiers
  });
  
  return [
    { target, popper: handleSetPopper },
    styles, 
    attributes,
    update,
  ];
};

export default usePopper;

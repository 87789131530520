import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SupportNavigationItem from './support-navigation-item';
import replaceLinkParams from '../../utilities/replace-link-params';
import { 
  AdminAndClinicRoleDescriptions, 
  ClinicRoleDescriptions, 
  RoleDescriptions, 
  userHasAdminRole 
} from '../../utilities/user-roles';
import { DashboardPaths, ClinicPaths, MarketplacePaths } from '../../paths';
import AccessControl from '../access-control';
import Strings from './lang';
import { UserSelector } from '../../redux/selectors';

const NavigationContentLoggedIn = ({
  currentOrganization = {}
}) => {
  const currentUser = useSelector(UserSelector.getCurrentUser);
  const isAdmin = useMemo(() => userHasAdminRole(currentUser), [currentUser]);

  return (
    <ul className="navbar-items">
      <li>
        <Link to={DashboardPaths.index.link}>
          {Strings.homeLinkText}
        </Link>
      </li>

      <AccessControl 
        allowIfClinicDeactivated
        roles={[
          RoleDescriptions.ClinicOwner,
          RoleDescriptions.Clinician,
          RoleDescriptions.BaselineTester,
          RoleDescriptions.Specialist,
          RoleDescriptions.ClinicStaff,
          RoleDescriptions.ClinicFrontDesk,
          RoleDescriptions.IDTClinician,
          RoleDescriptions.IDTFrontDesk
        ]}
      >
        <li>
          <Link
            to={replaceLinkParams(ClinicPaths.profile.link, { clinicId: currentOrganization.id })}
          >
            {Strings.manageClinicLinkText}
          </Link>
        </li>
      </AccessControl>

      <AccessControl roles={[RoleDescriptions.SuperAdmin, RoleDescriptions.SalesAdmin]}>
        <li>
          <Link to={ClinicPaths.register.link}>
            {Strings.addClinicLinkText}
          </Link>
        </li>
      </AccessControl>

      <AccessControl roles={AdminAndClinicRoleDescriptions}>
        <li>
          <Link to={isAdmin ? MarketplacePaths.index.link : MarketplacePaths.products.index.link}>
            {Strings.marketplaceLinkText}
          </Link>
        </li>
      </AccessControl>

      <AccessControl 
        allowIfClinicDeactivated
        roles={ClinicRoleDescriptions}
      >
        <SupportNavigationItem />
      </AccessControl>
    </ul>
  );
};

NavigationContentLoggedIn.propTypes = {
  currentOrganization: PropTypes.object
};

export default NavigationContentLoggedIn;

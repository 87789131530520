import { getLocalStorageItem, setLocalStorageItem } from '../../utilities/local-storage';

const BAG_TAGS_TABLE_SORT_KEYPATH_KEY = 'com.completeconcussions.bagtags.sort.key.path';

const getBagTagsTableSortKeyPath = () => getLocalStorageItem(BAG_TAGS_TABLE_SORT_KEYPATH_KEY) || '';
const setBagTagsTableSortKeyPath = (keyPath = '') => setLocalStorageItem(BAG_TAGS_TABLE_SORT_KEYPATH_KEY, keyPath);

export {
  getBagTagsTableSortKeyPath,
  setBagTagsTableSortKeyPath
};

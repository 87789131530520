import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { If, Then } from 'react-if';
import { age, minBirthdate } from '../../../dates';
import { AddressFormInputs } from '../../address-form-inputs';
import convertAddressKeys from '../../../utilities/convert-address-keys';
import sanitizeAddress from '../../../utilities/sanitize-address';
import validateAddress from '../../../utilities/validate-address';
import { INPUT_DATE_FORMAT, DISPLAY_DATE_FORMAT } from '../../../dates/formats';
import { getCurrentUserLanguage, DEFAULT_USER_LANGUAGE } from '../../../utilities/localization';
import Strings from './lang';
import {
  FormComponent,
  FormInputGroup,
  GenderInputGroup,
  DateInputGroup
} from '../../../forms';

const getStateFromProps = (props = {}) => {
  const { user = {} } = props;
  const person = user.person || {};
  const address = sanitizeAddress(user.mailing_address || {});
  const firstName = person.first_name || '';
  const lastName = person.last_name || '';
  const birthdate = person.birthday || '';
  const gender = person.gender || '';
  const genderOther = person.gender_other || '';
  const phone = person.phone_number || '';
  const showOtherGender = genderOther !== '';

  return {
    firstName,
    lastName,
    birthdate,
    gender,
    genderOther,
    showOtherGender,
    address,
    phone,
    addressValid: validateAddress(address)
  };
};

class RegistrationAboutPlayerForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateBirthdate = this.onUpdateBirthdate.bind(this);
    this.onUpdateGender = this.onUpdateGender.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);

    this.state = {
      submitted: false,
      ...getStateFromProps(props)
    };
  }

  componentDidUpdate(prevProps) {
    const { user = {} } = this.props;

    if (user && user.id !== (prevProps.user || {}).id) {
      this.updateAttributes();
    }
  }

  render() {
    const userAge = age(this.state.birthdate);
    const interpretedDOB = dayjs(
      this.state.birthdate, 
      INPUT_DATE_FORMAT
    ).format(DISPLAY_DATE_FORMAT);

    return (
      <FormComponent className="registration-about-form player" onSubmit={this.onSubmit}>

        <FormInputGroup
          className="form-group first-name-input-group"
          labelText={Strings.firstNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.firstName,
            onBlur: this.onUpdateFirstName.bind(this),
            onChange: this.onUpdateFirstName.bind(this)
          }}
          inputValid={this.state.firstName.length > 0}
          messageText={Strings.firstNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <FormInputGroup
          className="form-group last-name-input-group"
          labelText={Strings.lastNameLabelText}
          inputType="text"
          inputProps={{
            className: 'form-control',
            value: this.state.lastName,
            onBlur: this.onUpdateLastName.bind(this),
            onChange: this.onUpdateLastName.bind(this)
          }}
          inputValid={this.state.lastName.length > 0}
          messageText={Strings.lastNameErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <DateInputGroup
          className="form-group birthdate-input-group"
          labelText={Strings.birthdateLabelText}
          inputType="date"
          textInputProps={{
            className: 'form-control',
            placeholder: 'January 1, 2000'
          }}
          dateInputProps={{
            className: 'form-control'
          }}
          initialValue={this.state.birthdate}
          minDate={minBirthdate()}
          onUpdate={this.onUpdateBirthdate}
          messageText={Strings.birthdateValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
          maxDate={dayjs()}
        />

        <If condition={!isNaN(userAge)}>
          <Then>
            <p className="alert alert-info">
              {Strings.formatString(
                Strings.birthdateConfirmationMessage,
                (userAge >= 1) ? userAge : Strings.under1,
                (userAge > 1) ? Strings.years : Strings.year,
                interpretedDOB
              )}
            </p>
          </Then>
        </If>

        <GenderInputGroup
          className="form-group gender-input-group"
          labelText={Strings.genderLabelText}
          inputProps={{
            className: 'form-control',
            value: this.state.gender
          }}
          inputValid={this.state.gender.length > 0}
          onUpdate={this.onUpdateGender}
          messageText={Strings.genderValidationMessage}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />
        <If condition={this.state.showOtherGender === true}>
          <Then>
            <FormInputGroup
              className="form-group"
              labelText={Strings.genderOtherLabelText}
              inputType="text"
              inputProps={{
                className: 'form-control',
                value: this.state.genderOther,
                onBlur: this.onUpdateInput.bind(this, 'genderOther'),
                onChange: this.onUpdateInput.bind(this, 'genderOther')
              }}
              inputValid={this.state.genderOther.length > 0}
              messageText={Strings.genderOtherValidationMessage}
              messageClassName="alert alert-danger"
              touched={this.state.submitted}
            />
          </Then>
        </If>

        <FormInputGroup
          className="form-group phone-input-group"
          labelText={Strings.phoneLabelText}
          type="tel"
          inputProps={{
            className: 'form-control',
            value: this.state.phone,
            onBlur: this.onUpdateInput.bind(this, 'phone'),
            onChange: this.onUpdateInput.bind(this, 'phone')
          }}
          inputValid={this.state.phone.length > 0}
          messageText={Strings.phoneErrorText}
          messageClassName="alert alert-danger"
          required
          touched={this.state.submitted}
        />

        <AddressFormInputs
          address={this.state.address}
          onUpdate={this.onUpdateAddress}
          required
          touched={this.state.submitted}
        />

        <div className="form-footer">
          <button className="btn btn-primary" type="submit">
            {Strings.submitButtonText}
          </button>
        </div>
      </FormComponent>
    );
  }

  updateAttributes() {
    this.setState({
      ...getStateFromProps(this.props)
    });
  }

  isValid() {
    return this.state.firstName.length
      && this.state.lastName.length
      && this.state.birthdate
      && this.state.gender.length
      && this.state.phone.length
      && this.state.addressValid;
  }

  onUpdateFirstName(e) {
    const { value } = e.target;
    this.setState({
      firstName: value
    });
  }

  onUpdateLastName(e) {
    const { value } = e.target;
    this.setState({
      lastName: value
    });
  }

  onUpdateInput(key, e) {
    const { value } = e.target;
    this.setState({
      [key]: value
    });
  }

  onUpdateBirthdate(birthdate) {
    this.setState({
      birthdate
    });
  }

  onUpdateGender(gender) {
    this.setState({
      gender,
      showOtherGender: (gender === 'unspecified')
    });
  }

  onUpdateAddress(address, addressValid) {
    this.setState({
      address,
      addressValid
    });
  }

  onSubmit() {
    this.setState({ submitted: true });

    if (!this.isValid()) {
      return;
    }

    const {
      firstName,
      lastName,
      birthdate,
      gender,
      genderOther,
      address,
      phone
    } = this.state;

    const attributes = {
      person_attributes: {
        first_name: firstName,
        last_name: lastName,
        birthday: dayjs(birthdate).format(INPUT_DATE_FORMAT),
        gender,
        gender_other: genderOther,
        phone_number: phone,
        language: getCurrentUserLanguage() || DEFAULT_USER_LANGUAGE
      },
      mailing_address_attributes: convertAddressKeys(address)
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit(attributes);
    }
  }
}

RegistrationAboutPlayerForm.propTypes = {
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default RegistrationAboutPlayerForm;

import dayjs from 'dayjs';
import { batch } from 'react-redux';
import { jsonApiCreate, jsonApiRead, jsonApiUpdate } from '../../requests/jsonapi';
import { getCurrentClinicId } from '../../tokens';
import { updateBaselineTest } from '../actions/baseline-tests';
import { removeUserBaselineTest, addUserBaselineTest, updateUserBaselineTest } from '../actions/user-baseline-tests';
import { getUserBaselineTestsAsync } from './user-baseline-tests';
import isBaselineExpired from '../../utilities/is-baseline-expired';

const createBaselineTestAsync = (userId, attributes, withImpact = false) => {
  const queryParams = { current_clinic_id: getCurrentClinicId(), with_impact_code: withImpact };
  const options = { path: `users/${userId}/baselines`, queryParams };
  return (dispatch, getState) => {
    return jsonApiCreate({ type: 'baselines', attributes }, options).then(results => {
      batch(() => {
        const { userBaselineTests = [] } = getState();
  
        dispatch(updateBaselineTest(results));
  
        if (userBaselineTests?.[userId]?.length) {
          dispatch(addUserBaselineTest(userId, results));
        }
      });

      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getBaselineTestAsync = (userId, baselineId) => {
  const options = { path: `users/${userId}/baselines/${baselineId}` };
  return (dispatch) => {
    return jsonApiRead({ type: 'baselines' }, options).then(results => {
      dispatch(updateBaselineTest(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const updateBaselineTestAsync = (userId, id, attributes) => {
  const options = { path: `users/${userId}/baselines/${id}` };
  return (dispatch, getState) => {
    return jsonApiUpdate({ type: 'baselines', id, attributes }, options).then(results => {
      batch(() => {
        const { userBaselineTests = [] } = getState();
  
        dispatch(updateBaselineTest(results));
  
        if (userBaselineTests?.[userId]?.length) {
          dispatch(updateUserBaselineTest(userId, results));
        }
      });

      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const getActiveBaselineTestAsync = (userId) => {
  return (dispatch) => {
    return dispatch(getUserBaselineTestsAsync(userId)).then(baselines => {
      const active = baselines.find(baseline => !isBaselineExpired(baseline.created_at));
      if (!active) {
        return Promise.resolve({});
      }

      return dispatch(getBaselineTestAsync(userId, active.id));
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

/** default the beforeDate to the current date */
const getBaselineBeforeDateAsync = (userId, beforeDate = dayjs().format()) => {
  const queryParams = { before_date: beforeDate };
  const options = { path: `/users/${userId}/baselines/comparison`, queryParams };
  
  return (dispatch) => {
    return jsonApiRead({ type: 'baselines' }, options).then(results => {
      dispatch(updateBaselineTest(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const archiveBaselineTestAsync = (userId, baselineId, reason) => {
  const options = { path: `users/${userId}/baselines/${baselineId}/archive` };
  return (dispatch) => {
    return jsonApiCreate({ type: 'baselines', attributes: { reason } }, options).then(results => {
      dispatch(removeUserBaselineTest(userId, baselineId));
      dispatch(updateBaselineTest(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

const unarchiveBaselineTestAsync = (userId, baselineId) => {
  const options = { path: `users/${userId}/baselines/${baselineId}/unarchive` };
  return (dispatch, getState) => {
    return jsonApiCreate({ type: 'baselines' }, options).then(results => {
      const { userBaselineTests } = getState();

      if (userBaselineTests[userId]?.length > 0) {
        dispatch(addUserBaselineTest(userId, results));
      }

      dispatch(updateBaselineTest(results));
      return Promise.resolve(results);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
};

export {
  createBaselineTestAsync,
  getBaselineTestAsync,
  updateBaselineTestAsync,
  getActiveBaselineTestAsync,
  getBaselineBeforeDateAsync,
  archiveBaselineTestAsync,
  unarchiveBaselineTestAsync
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { If, Then, Else } from 'react-if';
import { jsonApiRead, jsonApiCreate } from '../../requests/jsonapi';
import { updatePositionAsync, createPositionsAsync } from '../../redux/thunks/positions';
import { getUserAsync } from '../../redux/thunks/users';
import AccessControl from '../access-control';
import Activity from '../activity';
import { ErrorBanner } from '../errors';
import SportsTeamList from './sports-team-list';
import SportsTeamSearchForm from './sports-team-search-form';
import SportsSelectTeamForm from './sports-select-team-form';
import { groupTeamsByOrganization } from './sports-utilities';
import Strings from './lang';

const generateFormKeys = () => {
  const now = Date.now();
  return {
    searchFormKey: now,
    selectFormKey: now + 1,
    listKey: now + 2
  };
};

const getStateFromProps = (props) => {
  const { user = {} } = props;

  return {
    indexedTeams: [],
    groupedTeams: {},
    suggestedTeams: [],
    sport: '',
    location: {},
    teamInfo: {},
    indexed: false,
    activity: user.id === undefined,
    error: null,
    ...generateFormKeys()
  };
};

class Sports extends Component {
  constructor(props) {
    super(props);

    this.updatePosition = this.updatePosition.bind(this);
    this.leaveTeam = this.leaveTeam.bind(this);
    this.indexTeams = this.indexTeams.bind(this);
    this.searchTeams = this.searchTeams.bind(this);
    this.joinTeam = this.joinTeam.bind(this);
    this.joinSuggestedTeam = this.joinSuggestedTeam.bind(this);

    this.state = {
      ...getStateFromProps(props),
      requestedTeams: []
    };
  }

  render() {
    return (
      <div className="sports">
        <ErrorBanner error={this.state.error} />
        <Activity active={this.state.activity}>
          <div className="sports-content">

            <h2>{Strings.userTeamsListHeadingText}</h2>
            <SportsTeamList
              key={this.state.listKey}
              teams={this.props.user?.teams || []}
              editorRoles={this.props.editorRoles}
              currentUser={this.props.currentUser}
              hideEmptyMessage={this.props.user?.id === undefined}
              onUpdatePosition={this.updatePosition}
              onLeaveTeam={this.leaveTeam}
            />

            {this.props.allowJoinTeam !== false && (
              <AccessControl currentUser={this.props.currentUser} roles={this.props.editorRoles}>
                <div className="sports-form-container">
                  <SportsTeamSearchForm
                    key={this.state.searchFormKey}
                    onSubmit={this.indexTeams}
                  />

                  <If condition={this.state.suggestedTeams.length > 0}>
                    <Then>
                      {this.renderSuggestedTeams()}
                    </Then>
                    <Else>
                      <If condition={this.state.indexed}>
                        <Then>
                          <SportsSelectTeamForm
                            key={this.state.selectFormKey}
                            userId={this.props.user?.id || 0}
                            sport={this.state.sport}
                            location={this.state.location}
                            teams={this.state.groupedTeams}
                            onAddTeam={this.searchTeams}
                            onSelectTeam={this.joinTeam}
                          />
                        </Then>
                      </If>
                    </Else>
                  </If>
                </div>
              </AccessControl>
            )}

          </div>
        </Activity>
      </div>
    );
  }

  renderSuggestedTeams() {
    return (
      <div className="suggested-teams">
        <h2>{Strings.suggestedTeamsHeadingText}</h2>
        <p className="alert alert-info">{Strings.suggestedTeamsMessageText}</p>

        <SportsTeamList
          teams={this.state.suggestedTeams}
          onSelectTeam={this.joinSuggestedTeam}
        />

        <p className="alert alert-info">{Strings.ignoreSuggestionHeadingText}</p>

        <SportsTeamList
          teams={[this.state.teamInfo]}
          onSelectTeam={this.joinTeam}
        />
      </div>
    );
  }

  resetForms() {
    this.setState({
      indexed: false,
      suggestedTeams: [],
      sport: '',
      location: {},
      teamInfo: {},
      ...generateFormKeys()
    });
  }

  indexTeams(attributes) {
    this.setState({
      activity: true,
      error: null
    });

    const { requestOptions = {} } = this.props;
    const { 
      sport, country, state, city 
    } = attributes;
    const location = { country, state, city };

    jsonApiRead({ type: 'teams', attributes }, requestOptions).then(indexedTeams => {
      this.setState({
        activity: false,
        indexed: true,
        groupedTeams: groupTeamsByOrganization(indexedTeams, Strings.noOganizationDefaultLabel),
        sport,
        location
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  searchTeams(attributes) {
    this.setState({
      activity: true,
      error: null
    });

    const { requestOptions = {} } = this.props;
    const { 
      name, level, sport, country, state, city 
    } = attributes;
    const search = { 
      name, level, sport: sport.name, country, state, city
    };

    const resource = {
      type: 'teams',
      attributes: search
    };

    jsonApiRead(resource, { ...requestOptions, path: 'teams/search' }).then(suggestedTeams => {
      this.setState({
        activity: false,
        suggestedTeams,
        teamInfo: attributes
      });

      // If there are no team suggestions, automatically
      // join the team specified by the user:
      if (suggestedTeams.length === 0) {
        this.joinTeam(attributes);
      }
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  joinTeam(attributes) {
    this.setState({
      activity: true,
      error: null
    });

    const resource = {
      type: 'teams',
      attributes
    };

    const { requestOptions = {} } = this.props;

    jsonApiCreate(resource, { ...requestOptions, path: 'teams/join' }).then((team) => {
      const { requestedTeams } = this.state;
      if (attributes.id) {
        this.setState({
          requestedTeams: [
            ...requestedTeams,
            team
          ]
        });
      }

      return this.props.getUser(this.props.user.id);
    }).then(() => {
      this.setState({
        activity: false
      });

      this.resetForms();
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  joinSuggestedTeam(team) {
    const {
      primary_position = '',
      secondary_position = ''
    } = this.state.teamInfo;

    const attributes = {
      team_id: parseInt(team.id, 10),
      user_id: parseInt(this.props.user?.id, 10),
      primary_position,
      secondary_position
    };

    this.joinTeam(attributes);
  }

  leaveTeam(teamId) {
    this.setState({
      activity: true,
      error: null
    });

    const resource = {
      type: 'teams',
      attributes: {
        id: parseInt(teamId, 10),
        user_id: parseInt(this.props.user?.id, 10)
      }
    };

    const { requestOptions = {} } = this.props;

    jsonApiCreate(resource, { ...requestOptions, path: `teams/${teamId}/remove_player` }).then(() => {
      return this.props.getUser(this.props.user.id);
    }).then(() => {
      this.setState({ activity: false });

      this.resetForms();
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  updatePosition(id, attributes, teamId) {
    this.setState({
      activity: true,
      error: null
    });

    const { user = {} } = this.props;

    this.updateOrCreatePosition(user.id, id, attributes, teamId).then(() => {
      return this.props.getUser(user.id);
    }).then(() => {
      this.setState({
        activity: false,
        ...generateFormKeys()
      });
    }).catch(error => {
      this.setState({
        activity: false,
        error: error.message
      });
    });
  }

  updateOrCreatePosition(userId, positionId, attrs, teamId) {
    const attributes = { ...attrs };
    if (!positionId) {
      attributes.user_id = userId;
      attributes.team_id = teamId;
      return this.props.createPosition(userId, attributes);
    } 

    return this.props.updatePosition(userId, positionId, attributes);
  }
}

/* eslint-disable react/no-unused-prop-types */
Sports.propTypes = {
  user: PropTypes.object.isRequired,
  editorRoles: PropTypes.arrayOf(PropTypes.object),
  allowEditing: PropTypes.bool,
  requestOptions: PropTypes.object
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updatePosition: (userId, positionId, attributes) => {
      const { requestOptions = {} } = ownProps;
      return dispatch(updatePositionAsync(userId, positionId, attributes, requestOptions));
    },
    createPosition: (userId, attributes) => {
      const { requestOptions = {} } = ownProps;
      return dispatch(createPositionsAsync(userId, attributes, requestOptions));
    },
    getUser: userId => {
      const { requestOptions = {} } = ownProps;
      return dispatch(getUserAsync(userId, requestOptions));
    }
  };
};

const ConnectedSports = connect(
  null,
  mapDispatchToProps
)(Sports);

export default ConnectedSports;

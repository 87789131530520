import React, { useCallback, useState } from 'react';
import { FormComponent, FormInputGroup } from '../../forms';
import Strings from './lang';

const isValid = (amount, balance) => {
  return !isNaN(amount) && amount > 0.0 && amount <= balance;
};

const RecievePaymentForm = ({
  balance = 0,
  onSubmit = () => {}
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [amount, setAmount] = useState('');
  const [note, setNote] = useState('');
  const valid = isValid(parseFloat(amount), balance);

  const handleSubmit = useCallback(() => {
    setSubmitted(true);
    const payment = parseFloat(amount);

    if (isValid(payment, balance)) {
      onSubmit({
        amount: payment,
        note
      });
    }
  }, [amount, balance, note, onSubmit]);

  const handleNoteChanged = useCallback((e) => {
    setNote(e.target.value);
  }, []);

  const handleAmountChanged = useCallback((e) => {
    setAmount(e.target.value);
  }, []);

  return (
    <FormComponent onSubmit={handleSubmit}>
      <FormInputGroup 
        required
        inputType="text"
        className="form-group"
        labelText={Strings.amountLabel}
        inputProps={{
          className: 'form-control',
          value: amount,
          onChange: handleAmountChanged
        }}
        messageText={Strings.formatString(
          Strings.recievePaymentAmountError,
          balance
        )}
        messageClassName="alert alert-danger"
        inputValid={valid}
        touched={submitted}
      />
      <FormInputGroup 
        inputValid
        inputType="textarea"
        className="form-group"
        labelText={Strings.noteLabel}
        inputProps={{
          className: 'form-control',
          value: note,
          rows: 10,
          onChange: handleNoteChanged
        }}
        touched={submitted}
      />
      <button 
        type="submit" 
        disabled={!valid}
        className="btn btn-primary"
      >
        {Strings.submitButtonText}
      </button>
    </FormComponent>
  );
};

export default RecievePaymentForm;

import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { ErrorBanner } from '../errors';
import { updateUserAsync } from '../../redux/thunks/users';
import Modal from '../modal';
import Icon from '../icon';
import { useIsMounted } from '../../hooks';
import Strings from './lang';

const DeleteBookingLinkModal = ({ 
  isOpen, 
  linkId,
  clinicId, 
  userId, 
  updateUser, 
  onClose 
}) => {
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState(null);
  const mounted = useIsMounted();

  const handleDelete = useCallback(() => {
    setDeleting(true);
    setError(null);

    const attributes = {
      booking_links_attributes: [{
        id: linkId,
        clinic_id: clinicId,
        _destroy: true
      }]
    };

    updateUser(userId, attributes).then(() => {
      if (mounted.current) {
        setDeleting(false);
        onClose();
      }
    }).catch(error => {
      setError(error.message);
      setDeleting(false);
    });
  }, [clinicId, linkId, mounted, onClose, updateUser, userId]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={deleting ? undefined : onClose}
    >
      <Modal.Header>
        <h1>
          <Icon name="circle-exclamation" className="error" />&nbsp;
          {Strings.deleteBookingLinkModalTitle}
        </h1>
      </Modal.Header>
      <Modal.Body>
        <ErrorBanner error={error} />
        <p>{Strings.deleteBookingLinkConfirmMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <button 
          type="button" 
          disabled={deleting}
          className="btn btn-default"
          onClick={onClose}
        >
          {Strings.cancelButtonText}
        </button>
        <button 
          type="button" 
          disabled={deleting}
          className="btn btn-danger" 
          onClick={handleDelete}
        >
          {deleting && <><Icon spinPulse prefix="fad" name="loader" />&nbsp;&nbsp;</>}
          {Strings.deleteButtonText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userId, attributes) => {
      return dispatch(updateUserAsync(userId, attributes));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(DeleteBookingLinkModal);

import React from 'react';
import { isFolder } from '../../utilities/google-drive';

const Thumbnail = ({
  src = null,
  fileName,
  icon = null,
  mimeType = ''
}) => {
  if (src) {
    return (
      <div className="file-thumbnail">
        <img referrerPolicy="no-referrer" src={src} alt={fileName} />
      </div>
    );
  }

  if (!isFolder({ mimeType }) && icon) {
    return (
      <div className="file-thumbnail-placeholder">
        <img referrerPolicy="no-referrer" src={icon} alt={fileName} />
      </div>
    );
  }

  return null;
};

export default Thumbnail;

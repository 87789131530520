import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import AccessControl from '../../access-control/index';
import LineItemDrawer from './line-item-drawer';
import Dropdown, { MenuItem } from '../../dropdown';
import { AdminRoleDescriptions } from '../../../utilities/user-roles';
import Strings from '../lang';
import { LineItemTypes } from '../utilities';
import Icon from '../../icon';

const NonEditableTypes = [LineItemTypes.BulkImpact];

const AdminLineItemActions = ({ 
  visible = true,
  clinicId,
  invoiceId,
  currency,
  lineItem = {},
  onDelete = () => {},
  onFocus,
  onBlur
}) => {
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const canUpdate = !NonEditableTypes.includes(lineItem.type);

  const handleDelete = useCallback(() => {
    setOpen(false);
    onDelete(lineItem);
  }, [lineItem, onDelete]);

  const handleUpdate = useCallback(() => {
    setOpen(false);
    setDrawerOpen(true);
  }, []);

  return visible ? (
    <AccessControl roles={AdminRoleDescriptions}>
      <>
        <Dropdown
          isOpen={open}
          placement="bottom-start"
          trigger={(
            <button 
              type="button"
              onClick={() => setOpen(!open)}
              onFocus={onFocus}
              onBlur={onBlur}
              className={classnames('line-item-action-trigger', { selected: open })}
            >
              <Icon name="ellipsis-v" />
            </button>
          )}
          onClose={() => setOpen(false)}
        >
          {canUpdate && (
            <MenuItem 
              type="primary" 
              icon="pen-to-square"
              onSelect={handleUpdate}
            >
              {Strings.editLineItemLabel}
            </MenuItem>
          )}
          <MenuItem 
            type="danger" 
            icon="trash-can" 
            onSelect={handleDelete}
          >
            {Strings.deleteLineItemLabel}
          </MenuItem>
        </Dropdown>
        <LineItemDrawer 
          lineItem={lineItem}
          clinicId={clinicId}
          invoiceId={invoiceId}
          currency={currency}
          isOpen={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        />
      </>
    </AccessControl>
  ) : null;
};

export default AdminLineItemActions;

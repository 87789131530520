import React, { forwardRef, useMemo } from 'react';
import { DualTaskTestCard } from '../baseline-test-cards';
import { ageAt } from '../../../dates';

const MIN_AGE = 12;

const DualTaskTestStep = forwardRef((
  {
    user,
    baseline,
    onSubmit,
    ...rest
  },
  ref
) => {
  const isChild = useMemo(() => (
    ageAt(user?.person?.birthday, baseline?.created_at) <= MIN_AGE
  ), [baseline?.created_at, user?.person?.birthday]);

  return (
    <DualTaskTestCard
      {...rest}
      ref={ref}
      user={user}
      dualTask={{ ...(baseline?.dual_task_test || {}) }}
      isChild={isChild}
      onSubmit={onSubmit}
    />
  );
});

DualTaskTestStep.hasChanged = (initial, current) => {
  const curTrials = current.trials || [];
  const initialTrials = initial.trials;
  return curTrials.some((trial, i) => (
    trial?.failed !== (initialTrials?.[i]?.failed ?? null)
    || trial?.duration !== (initialTrials?.[i]?.duration ?? '')
    || trial?.errors !== (initialTrials?.[i]?.errors ?? '')
    || trial?.responses?.some((r, j) => (
      r.value !== initialTrials?.[i]?.responses?.[j]?.value
      || r.correct !== initialTrials?.[i]?.responses?.[j]?.correct
    ))
  ));
};

export default DualTaskTestStep;

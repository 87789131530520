import isEmpty from '../../utilities/is-empty';

const isObject = (value) => {
  return typeof value === 'object'
    && value !== null;
};

const serializeData = (key, value) => {
  if (Array.isArray(value)) {
    return value.map(v => {
      return serializeData(`${key}[]`, v);
    });
  }

  if (isObject(value)) {
    return Object.keys(value).map(k => {
      return serializeData(`${key}[${k}]`, value[k]);
    });
  }

  return `${key}=${encodeURIComponent(String(value))}`;
};

const serialize = (obj = {}) => {
  return Object.keys(obj)
    .reduce((acc, key) => {
      const value = obj[key];

      if (isEmpty(value)) return acc;

      const data = serializeData(key, obj[key]);

      return Array.isArray(data)
        // eslint-disable-next-line prefer-spread
        ? [...acc, ...([].concat.apply([], data))]
        : [...acc, data];
    }, [])
    .join('&');
};

export default {
  serialize
};
